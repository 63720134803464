import styled from 'styled-components/macro';

/** Outermost Containers **/
const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const LeftSideContainer = styled.div`
  width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;
`;

const RightSideContainer = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 65px);
  overflow-y: auto;
  width: 50%;
  margin-right: 5px;
  padding: 0 3px;
`;

/** Styles that Control the Flipping Box in the Proc Limit **/
const FlipWrapper = styled.div`
  height: auto;
  width: 100%;
  .mid {
    color: red;
    z-index: 1000;
  }
`;

/** Aka Flipper **/
const FlipInnerContainer = styled.div`
  position: relative;
  transition: 0.6s;
  transform-style: preserve-3d;
`;

/** flip-container **/
const FlipContainer = styled.div`
  height: 130px;
  width: 100%;
  perspective: 1000px;

  &:hover ${FlipInnerContainer} {
    transform: rotateY(180deg);
  }
`;

/** Here are all the styles for the CardFront **/
const CardFrontContainer = styled.div`
  backface-visibility: hidden;
  height: 130px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

/** Here are all the styles for the CardBack **/
const CardBackOuterContainer = styled.div`
  /* ${({ display }) => display || 'none'};
  * > {
    ${({ visibility }) => visibility || 'hidden'};
  }*/
  width: 100%;
  height: 130px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  //width: 100%;
  //height: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  //background: #fff;
  min-height: 115px;
  overflow-y: scroll;
  background: transparent;
  //background: ${({ theme, noBackground }) => {
    //   if (!noBackground) return theme.rgl.gridItem.colors.background;
    // if (!noBackground) return theme.tableStyles.backgroundColor;
    // else return 'none';
  }};
  color: ${({ theme }) => theme.rgl.gridItem.colors.primaryText};
  font-size: 12px;
`;

const CardBackInnerContainer = styled.div`
  backface-visibility: hidden;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  height: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border: ${({ theme }) => `1px solid ${theme.baseColors.whiteAlpha2}`};
  color: ${({ theme }) => theme.baseColors.text};
  //color: #fff !important;
  //background: #fff;
  min-height: 115px;
  overflow-y: scroll;
  font-size: 12px;
  background: transparent;
  /*  align-items: flex-start;
  align-content: flex-start;
  background: ${({ theme }) => theme.SsProcLimitsStyles.colors.background};
  backface-visibility: hidden;
  box-shadow: ${({ theme }) => theme.SsProcLimitsStyles.CardBack.boxShadow};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: auto;
  justify-content: flex-start;
  margin: 5px;
  min-height: 115px;
  overflow: auto;

  left: 0;
  position: absolute;
  top: 0;

  transform: rotateY(180deg);
  width: 100%;*/
`;

const CardBackHeaderContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.SsProcLimitsStyles.colors.headerBackground};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardBackHeaderLine = styled.div`
  font-size: 12px;
  // color: ${({ theme }) => theme.SsProcLimitsStyles.colors.headerText};
`;

const CardBackMidPhaseContainer = styled.div`
  border-bottom: ${({ theme, multiple }) => (multiple ? `1px solid ${theme.baseColors.whiteAlpha2}` : '')};
  display: flex;
  align-items: ${({ multiple }) => (multiple ? 'flex-start' : 'center')};
  height: 100%;
  justify-content: space-around;
  width: 100%;
`;

const CardBackMidPhaseTextContainer = styled.div`
  //align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: ${({ multiple }) => (multiple ? 'auto' : '100%')};
  justify-content: ${({ multiple }) => (multiple ? 'center' : 'flex-start')};
`;

export {
  ContentContainer,
  LeftSideContainer,
  RightSideContainer,
  CardFrontContainer,
  CardBackOuterContainer,
  CardBackInnerContainer,
  FlipWrapper,
  FlipContainer,
  FlipInnerContainer,
  CardBackHeaderContainer,
  CardBackHeaderLine,
  CardBackMidPhaseContainer,
  CardBackMidPhaseTextContainer,
};
