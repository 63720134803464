import React, {useEffect, useReducer} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
/*utils*/
import _cloneDeep from 'lodash/cloneDeep';
import _compact from 'lodash/compact';
import _isNil from 'lodash/isNil';
import _pickBy from 'lodash/pickBy';
import _get from 'lodash/get';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import _isNumber from 'lodash/isNumber';
import _isNaN from 'lodash/isNaN';
import _isNull from 'lodash/isNull';
import _toNumber from 'lodash/toNumber';
import _upperFirst from 'lodash/upperFirst';
import _filter from 'lodash/filter';
import _isArray from 'lodash/isArray';
import _toUpper from 'lodash/toUpper';
import _includes from 'lodash/includes';
import _findIndex from 'lodash/findIndex';
import _round from 'lodash/round';
import _toString from 'lodash/toString';
import _ceil from 'lodash/ceil';
import _debounce from 'lodash/debounce';
import _toPairs from 'lodash/toPairs';
import _chunk from 'lodash/chunk';
import _has from 'lodash/has';
import _set from 'lodash/set';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
/*state management*/
import {autorun} from 'mobx';
import {StoreContext} from 'stores/RootStore';
import {commonService} from 'services';
import {useQuery} from 'react-query';
import axios from 'axios';
import {useHistory, useLocation} from 'react-router-dom';

export {
    _cloneDeep,
    _compact,
    _isNil,
    _pickBy,
    _get,
    _each,
    _find,
    _map,
    _isNumber,
    _isNaN,
    _isNull,
    _toNumber,
    _filter,
    _isArray,
    _toUpper,
    _includes,
    _findIndex,
    _round,
    _toString,
    _ceil,
    _debounce,
    _toPairs,
    _chunk,
    _has,
    _set,
    _upperFirst,
    _isEqual,
    _sortBy,
    _reduce
};

function bufferQuery(query) {
    let bufferedQuery = _cloneDeep(query);
    if (_has(query.params, 'clientID')) {
        _set(bufferedQuery.params, 'clientID', query.clientID);
    }
    if (_has(query.params, 'route')) {
        _set(bufferedQuery.params, 'route', query.route);
    }
    if (_has(query.params, 'orderBy')) {
        _set(bufferedQuery.params, 'orderBy', query.params.orderBy);
    }
    if (_has(query.params, 'exportInfo')) {
        _set(bufferedQuery.params, 'exportInfo', query.params.exportInfo);
    }
    if (_has(query.params, 'filter')) {
        _set(bufferedQuery.params, 'filter', query.params.filter);
    }
    if (_has(query.params, 'excludeFields')) {
        _set(bufferedQuery.params, 'excludeFields', query.params.excludeFields);
    }
    return bufferedQuery;
}

function removeEmptyValues(obj) {
    return _pickBy(obj, (value) => {
        return value !== '' && !_isNil(value) && value !== undefined;
    });
}

export function useLodash() {
    return {
        _cloneDeep,
        _isNil,
        _pickBy,
        _get,
        _find,
        _map,
        _isNumber,
        _isNaN,
        _toNumber,
        _filter,
        _isArray,
        _toUpper,
        _includes,
        _findIndex,
        _round,
        _toString,
        _ceil,
        _debounce,
        _toPairs,
        _chunk,
        _has,
        _set,
    };
}

export async function logout(rootStore) {
    const user = rootStore.userStore?.user;
    // log the user out of the database if there is one
    if (user) {
        try {
            await commonService.logout({
                id: user.id,
            });
        } catch (error) {
            console.error('error', error);
        }
    }
    axios.defaults.headers.common['authorization'] = null;
    window.localStorage.removeItem('eygSessionToken');
    rootStore.resetState();
}

function useGetRootStore() {
    const stores = React.useContext(StoreContext);
    return stores.store;
}

/* Hook to use store in any functional component */

// const useStores = () => React.useContext(StoreContext);
function useInjectStores() {
    const rootStore = React.useContext(StoreContext);
    return useLocalObservable(() => ({
        rootStore,
        dataManagementStore: rootStore.dataManagementStore,
        filterStore: rootStore.filterStore,
        socketStore: rootStore.socketStore,
        ssFormStore: rootStore.ssFormStore,
        uiStore: rootStore.uiStore,
        userStore: rootStore.userStore,
        utilsStore: rootStore.utilsStore,
        widgetStore: rootStore.widgetStore,
    }));
}

export const useCheckAuthorizedRoute = () => {
    const {dataManagementStore, rootStore, uiStore, filterStore, userStore} = useInjectStores();
    const fetchUserData = userStore.fetchUserData,
        getAppData = rootStore.getAppData,
        setCurrentFilter = filterStore.setCurrentFilter,
        setUserMetaData = userStore.setUserMetaData,
        user = userStore.user;

    const history = useHistory();
    const location = useLocation();

    const store = useLocalObservable(() => ({
        loading: true,
        authorized: false,
        error: null,
        setLoading(loading) {
            this.loading = loading;
        },
        setAuthorized(authorized) {
            this.authorized = authorized;
        },
        setError(error) {
            this.error = error;
        },
    }));

    async function checkAuthorized(clientID, route) {
        store.setLoading(true);
        if (!user) {
            let userToken = window.localStorage.getItem('eygSessionToken');
            try {
                // check if the user session is still valid & set user.
                await fetchUserData(userToken);
                await getAppData();
            } catch (error) {
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    const {message} = error?.response?.data;
                    this.error = message;
                    console.error(`Error checking session:`, message);
                    userStore.logout(history, location);
                }
            }
        }
        try {
            let response = await commonService.routeAuthCheck({
                clientID: user.currentClient.id,
                route: location.pathname,
            });

            // if (response.data.data.pass === true) {
            if (response.statusText === 'OK') {
                store.setAuthorized(true);
            } else {
                let userID = _get(user, 'id');
                setUserMetaData(userID, response.data.data.toPath);
                userStore.logout(history, location);
            }
        } catch (error) {
            console.log('error', error);
            // const { message } = error?.response?.data;
            // console.error(`Error checking route aushorization`, message)
            if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                userStore.logout(history, location);
            }
        }
        store.setLoading(false);
    }

    useEffect(() => {
        store.setLoading(uiStore.changingClient);
    }, [uiStore.changingClient]);

    useEffect(() => {
        setCurrentFilter(history, location);
        dataManagementStore.setNewQuery();
        checkAuthorized();
    }, []);

    return {loading: store.loading, authorized: store.authorized};
};

export const useGetWidgetData = ({query, name}, dataPath, enabledCondition, granularityFilter) => {
    const {userStore, dataManagementStore} = useInjectStores();
    const granularity = granularityFilter ? granularityFilter : dataManagementStore?.dateFilter?.granularity;
    const filter = _cloneDeep(removeEmptyValues(dataManagementStore?.newQuery));
    // console.log('user', userStore.user);
    // console.table('filter', filter);

    let post = {
        params: {
            ...filter,
            granularity,
            clientID: _toString(userStore.user.currentClient.id),
        },
    };

    async function fetchData() {
        post = {
            params: {
                ...filter,
                granularity,
                clientID: _toString(userStore.user.currentClient.id),
            },
        };
        // console.log('post', post);
        return await query(post)
            .then((res) => {
                // console.log('name, res', name, res);
                // console.log('res, dataPath', res, dataPath);
                const dataToReturn = _get(res, dataPath);

                return dataToReturn;
                // return _get(res, dataPath);
            })
            .catch((err) => {
                // const { status, statusText } = err?.response;
                // const { message, name, path, success, errors } = err?.response?.data;
                // console.error(`widgetID(${id}), ${err?.response?.data?.message}:`);
                if (err?.response?.data?.errors) console.table(err?.response?.data?.errors);
                if (
                    err?.response?.statusText === 'Unauthorized' ||
                    err?.response?.data?.message === 'Not authorized' ||
                    err?.response?.status === 401 ||
                    err?.response?.data?.message === 'Invalid token'
                ) {
                    userStore.logout();
                }
                throw err;
            });
    }

    return useQuery([name, post], !!enabledCondition ? fetchData : () => {
    }, {
        staleTime: 600000,
        refetchOnWindowFocus: false,
        enabled: !!enabledCondition,
    });
};

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

// const formatPackageData = (values) => {
//   const basicData = values[0];
//   const standardData = values[1];
//   const advancedData = values[2];
//   const professionalData = values[3];

//   const output = [

//     
//     
//     
//     {type: 'header', name: 'Financially Know Your Customer Database (FKYC)'},
//     {type: 'value', name: '(Customer info & analytics)', basic: 'CARTE', standard: 'CARTE', advanced: 'CARTE', professional: 'CARTE', price: '49.95'}
//   ];
  
//   output.push('hello');
//   console.log('COMIING IN', values);
//   return output;
// };

const formatPackageData = (values) => {
  const items = [
    { type: 'header', name: 'Dashboard' },
    { type: 'value', key: 'dshbrd_graph_credit_limit', label: 'Graph & Credit Limit', defaultValue: 'FALSE' },
    { type: 'value', key: 'dshbrd_averages', label: 'Averages', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_dshbrd_avgs', purchased: 'ala_crt_dshbrd_avgs_tf' },
    { type: 'value', key: 'dshbrd_trends', label: 'Trends', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_dshbrd_trends', purchased: 'ala_crt_dshbrd_trends_tf' },
    { type: 'header', name: 'Accounting' },
    { type: 'value', key: 'acctg_journals_accrual', label: 'Journals: Accrual', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_acctg_jnl_accl', purchased: 'ala_crt_acctg_jnl_accl_tf' },
    { type: 'value', key: 'acctg_journals_cash', label: 'Journals: Cash', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_acctg_jnl_cash', purchased: 'ala_crt_acctg_jnl_cash_tf' },
    { type: 'value', key: 'acctg_daily_batches', label: 'Daily Batches', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_acctg_dly_bat', purchased: 'ala_crt_acctg_dly_bat_tf' },
    { type: 'value', key: 'acctg_daily_deposits', label: 'Daily Deposits', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_acctg_dly_dep', purchased: 'ala_crt_acctg_dly_dep_tf' },
    { type: 'value', key: 'acctg_prepaid_billing', label: 'Prepaid Billing', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_acctg_prepd_bill', purchased: 'ala_crt_acctg_prepd_bill_tf' },
    { type: 'value', key: 'acctg_tsys_statements', label: 'TSYS Statements', defaultValue: 'FALSE' },
    { type: 'header', name: 'Tax' },
    { type: 'value', key: 'tax_1099k_analysis', label: '1099-K', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_tax_1099k', purchased: 'ala_crt_tax_1099k_tf' },
    { type: 'header', name: 'Transactions' },
    { type: 'value', key: 'txns_reconciliation', label: 'Reconciliation', defaultValue: 'FALSE' },
    { type: 'value', key: 'txns_analytics', label: 'Analytics', defaultValue: 'FALSE' },
    { type: 'value', name: 'Sales', dataPointsKey: 'txns_sales_details' },
    { type: 'value', name: 'Declines', dataPointsKey: 'txns_declines_details' },
    { type: 'value', name: 'Refunds', dataPointsKey: 'txns_refunds_details' },
    { type: 'value', name: 'Chargebacks', dataPointsKey: 'txns_chargebacks_details' },
    { type: 'value', name: 'Voids & Inquiries', dataPointsKey: 'txns_voids_inquiries' },
    { type: 'header', name: 'Fees' },
    { type: 'value', key: 'fees_by_category', label: 'Fees by Category', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_fees_by_cat', purchased: 'ala_crt_fees_by_cat_tf' },
    { type: 'header', name: 'Opportunity' },
    { type: 'value', key: 'opp_analysis_summary', label: 'Analysis Summary', defaultValue: 'FALSE' },
    {type: 'value', isCustom: true, name: 'Opportunity Details', basic: 'CARTE', standard: 'CARTE', advanced: 'CARTE', professional: 'TRUE', price: `${values[0]['ala_crt_prc_opp_dtl_base']}/mo & ${values[0]['ala_crt_prc_opp_dtl_per_txn']}/txn`, purchased: 'ala_crt_opp_dtl_tf'},
    { type: 'header', name: 'Reports' },
    { type: 'value', isCustom: true, name: 'Choose from List',basic: '1 Report', standard: '3 Reports', advanced: '4 Reports', professional: '5 Reports' },
    { type: 'value', isCustom: true, name: 'Additional Reports', basic: 'CARTE', standard: 'CARTE', advanced: 'CARTE', professional: 'CARTE' },
    { type: 'header', name: 'Archive Database' },
    { type: 'value', key: '', label: '(to stay complaint with federal and state laws.)', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_archive', purchased: 'ala_crt_archive_tf' },
    { type: 'header', name: 'Financially Know Your Customer Database (FKYC)' },
    { type: 'value', key: 'financially_kyc', label: '(Customer info & analytics)', defaultValue: 'CARTE', priceKey: 'ala_crt_prc_fkyc_cust_srch', purchased: 'ala_crt_fkyc_cust_srch_tf' },
  ];

  const packageNames = ['basic', 'standard', 'advanced', 'professional'];

  const createValueEntry = (descriptor) => {
    if (descriptor.isCustom) return descriptor;
    if (descriptor.dataPointsKey) {
      let entry = { type: 'value', name: descriptor.name };
      packageNames.forEach((packageName, idx) => {
        entry[packageName] = `${values[idx][descriptor.dataPointsKey]} data points`;
      });
      return entry;
    }
  
    let entry = { type: 'value', name: descriptor.label, purchased: values[0][descriptor.purchased] };
    packageNames.forEach((packageName, idx) => {
      const dataValue = values[idx][descriptor.key];
      entry[packageName] = dataValue ? 'TRUE' : descriptor.defaultValue;

      if (descriptor.priceKey && packageName === 'professional') {
        entry.price = values[idx][descriptor.priceKey];
      }
    });
    return entry;
  };

  const output = items.map(item => {
    if (item.type === 'header') return item; // If it's a header, just return it as is
    return createValueEntry(item); // Else, create the value entry
  });

  return output;
};

function convertToTitleCase(str) {
  const titleCased = str.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  // Little formatting tweaks
  return titleCased.replace(/\bmid\b/gi, 'MID')
  .replace(/\bet\b/gi, 'ET')
  .replace(/\bcc\b/gi, 'CC')
  .replace(/\btf\b/gi, 'T/F')
  .replace(/\bic\b/gi, 'I/C')
  .replace(/\bid\b/gi, 'ID')
  .replace(/\bmastercard\b/gi, 'MC')
  .replace(/\bmc\b/gi, 'MC')
  .replace(/\bmcc\b/gi, 'MCC')
  .replace(/\bopportunity\b/gi, 'Opp')
  .replace(/\bamex\b/gi, 'AMEX')
  .replace(/\btif\b/gi, 'TIF')
  .replace(/\tsys\b/gi, 'Proc')
  .replace(/\tsys1\b/gi, 'Proc1')
  .replace(/\tsys2\b/gi, 'Proc2')
  .replace(/\cp\b/gi, 'CP')
  .replace(/\cnp\b/gi, 'CNP')
  .replace(/\cvc\b/gi, 'CVC')
  .replace(/\mti\b/gi, 'MTI')
  .replace(/\tcvv2sys\b/gi, 'CVV2')
  .replace(/\avs\b/gi, 'AVS')
  .replace(/\pos\b/gi, 'POS')
  .replace(/\bDt\b/gi, 'Date');
};

function cellStyle(params) {
  if (params.value < 0) {
    return { color: '#FF2B49' };
  } else {
    // return { color: 'rgb(69, 232, 133)' };
  }
}

export function getFileName(userStore, config, filename) {
  console.log("config:",config)
	const currentClientName = _get(userStore, 'user.currentClient.clientName', '').replace(/\s/g, '');
	let endDate = config?.params?.endDt?.slice(0, 10).replace(/\-/g, '/') || '';
	let startDate = config?.params?.startDt?.slice(0, 10).replace(/\-/g, '/') || '';

	return `${filename}_${currentClientName}_${startDate}-${endDate}`;
}

const buildColDef = (utilsStore, col, width = 150) => {
  const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
  const toDateFormatter = utilsStore.toDateFormatter;
  const toPercent = utilsStore.toPercent;
  const toPercentFormatter = utilsStore.toPercentFormatter;
  const toFormatNumber = utilsStore.toFormatNumber;
  if(col === 'count') {
    return {
      headerName: "Count",
      field: "count",
      hide: true,
      suppressToolPanel: true
   }
  }
  switch(true) {
    case col === 'Index':
      return {
        headerName: 'Row',
        field: 'Index',
        pinned: 'left',
        checkboxSelection: true,
        filter: false,
        width,
        headerTooltip: 'Index'
      };
    case (!col.includes('pan') && (col.includes('date') || col.includes('dt'))):
      return {
        headerName: convertToTitleCase(col),
        field: col,
        valueFormatter: toDateFormatter,
        chartDataType: 'series',
        width: 150,
        headerTooltip: convertToTitleCase(col)
      };
    case (col.includes('amount') || col.includes('amt') || col.includes('fee') || col.includes('total') || col.includes('mo_credit_limit') || col.includes('mo_credit_limit') || col.includes('passthru') || col.includes('interchg') || col === 'exps_fr_platfrm' || col === 'gross_prof_fr_platfrm'):
    return {
        headerName: convertToTitleCase(col),
        field: col,
        cellStyle: cellStyle,
        valueFormatter: toCurrencyFormatter,
        type: 'rightAligned',
        chartDataType: 'series',
        width,
        headerTooltip: convertToTitleCase(col)
      };
    case (col.includes('cnt') || col.includes('vol') || col === 'eight_tech_billing' || col === 'agent_comm_rt' || col === 'eight_tech_rt'):
      return {
        headerName: convertToTitleCase(col),
        field: col,
        valueFormatter: (obj) => toFormatNumber(obj.value),
        cellStyle: cellStyle,
        type: 'rightAligned',
        chartDataType: 'series',
        width,
        headerTooltip: convertToTitleCase(col)
      };
    case col === 'ic_tier_rate':
      return {
        headerName: convertToTitleCase(col),
        field: col,
        cellStyle: cellStyle,
        valueFormatter: (obj) => toPercent(obj.value),
        type: 'rightAligned',
        chartDataType: 'series',
        width,
        headerTooltip: convertToTitleCase(col)
      };
    default:
      return {
        headerName: convertToTitleCase(col),
        field: col,
        chartDataType: 'series',
        width,
        headerTooltip: convertToTitleCase(col)
      };
  }
}

export {bufferQuery, removeEmptyValues, useGetRootStore, useInjectStores, formatPackageData, convertToTitleCase, buildColDef};

// export { bufferQuery, removeEmptyValues };

/*
const useGetFavicon = () => {
  let pathToFavicon = '../static/favicon2.ico';
  if (typeof window !== 'undefined') {
    let href = window.location.href;
    if (href.includes('localhost')) {
      pathToFavicon = '../static/favicons/Local/favicon2.ico';
    }
    if (href.includes('dev')) {
      pathToFavicon = '../static/favicons/Dev/favicon2.ico';
    }
    if (href.includes('staging')) {
      pathToFavicon = '../static/favicons/Staging/favicon2.ico';
    }
  }
  return { pathToFavicon };
};

const useGetTitle = () => {
  let title = 'Golden AIT';
  if (typeof window !== 'undefined') {
    let href = window.location.href;
    if (href.includes('localhost')) {
      title = 'Local Golden AIT';
    }
    if (href.includes('dev')) {
      title = 'Dev Golden AIT';
    }
    if (href.includes('staging')) {
      title = 'Staging Golden AIT';
    }
    if (href.includes('demo')) {
      title = 'Demo Golden AIT';
    }
    if (href.includes('app')) {
      title = 'Golden AIT';
    }
  }
  return { title };
};
*/

/*
const useSetRouteData = (whiteLabel = '') => {
  // const router = useRouter();
  const { rootStore, uiStore, filterStore } = useInjectStores();
  const setCurrentRoute = rootStore.setCurrentRoute,
    setCurrentDashboardID = rootStore.setCurrentDashboardID,
    setCurrentFilter = filterStore.setCurrentFilter,
    setWhiteLabel = uiStore.setWhiteLabel;

  useEffect(() => {
    const isServer = typeof window === 'undefined';
    if (!isServer) {
      setWhiteLabel(whiteLabel);
      // let { asPath, query } = router;
      // setCurrentRoute(asPath);
      //
      // setCurrentFilter(asPath);
    }
  }, []);
};
*/

/*
const useAuthRouteCheck = (initialUrl, initialData) => {
  // const router = useRouter();
  const { rootStore, userStore } = useInjectStores();
  const fetchUserData = userStore.fetchUserData,
    getAppData = rootStore.getAppData,
    setUserMetaData = userStore.setUserMetaData,
    user = userStore.user;

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      dispatch({ type: 'FETCH_INIT' });

      try {
        if (!user && typeof window !== 'undefined') {
          let userToken = window.localStorage.getItem('eygSessionToken');
          await fetchUserData(userToken);
          await getAppData();
        }
        let result = await commonService.routeAuthCheck();
        if (!_get(result, 'data.data.pass')) {
          let userID = _get(user, 'id');
          setUserMetaData(userID, result.data.data.toPath);
          return rootStore.userStore.logout();
        }
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result.data.data.pass });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE' });
          // if (_get(error, 'response.status') === 401 && router.pathname !== '/login') {
          //   rootStore.userStore.logout();
          // }
        }
      }
    }

    fetchData();
    // if the component unmounts we want to cancel the request
    return () => {
      didCancel = true;
    };
  }, []);

  return { passAuthCheck: state.data, isLoading: state.isLoading };
};
*/

/*
const useDataApi = (fetchApiData, initialData) => {
  const { userStore } = useInjectStores();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    let disposer = autorun(async () => {
      let didCancel = false;

      async function fetchData() {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const res = await fetchApiData();
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: res.data });
          }
        } catch (error) {
          console.error('error', error);
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
          // if (_get(error, 'response.status') === 401 && router.pathname !== '/login') {
          //   userStore.logout();
          // }
        }
      }

      if (userStore.user) {
        await fetchData();
      }

      return () => {
        didCancel = true;
        disposer();
      };
    });
  });

  return { data: state.data.data, isLoading: state.isLoading };
};
*/

/*
const useWidgetDataApi = (name, id, initialData) => {
  // const router = useRouter();
  const { userStore, dataManagementStore } = useInjectStores();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    let disposer = autorun(async () => {
      let didCancel = false;

      async function fetchData() {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const res = await dataManagementStore.fetchWidgetData(id);
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: res.data.data.widgets[name].data });
          }
        } catch (error) {
          console.log('error', error);
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
          // if (_get(error, 'response.status') === 401 && router.pathname !== '/login') {
          //   userStore.logout();
          // }
        }
      }

      if (dataManagementStore.newQuery) {
        await fetchData();
      }

      return () => {
        didCancel = true;
        disposer();
      };
    });
  });

  return { widgetData: state.data, isLoading: state.isLoading };
};*/

export const useWidgetDataApi = (id, widgetDataPath, initialData) => {
    // const router = useRouter();
    const {userStore, dataManagementStore} = useInjectStores();

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: initialData,
    });

    useEffect(() => {
        let disposer = autorun(async () => {
            let didCancel = false;

            async function fetchData() {
                dispatch({type: 'FETCH_INIT'});
                try {
                    const response = await dataManagementStore.fetchWidgetData(id);
                    if (!didCancel) {
                        dispatch({type: 'FETCH_SUCCESS', payload: _get(response, `${widgetDataPath}`)});
                    }
                } catch (error) {
                    console.error('error', error);
                    if (!didCancel) {
                        dispatch({type: 'FETCH_FAILURE'});
                    }
                    // if (_get(error, 'response.status') === 401 && router.pathname !== '/login') {
                    userStore.logout();
                    // }
                }
            }

            if (dataManagementStore.newQuery) {
                await fetchData();
            }

            return () => {
                didCancel = true;
                disposer();
            };
        });
    }, []);

    return {widgetData: state.data, isLoading: state.isLoading};
};

/*
const useAdminDataApi = () => {
  const { userStore, dataManagementStore } = useInjectStores();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: null,
  });

  useEffect(() => {
    let disposer = autorun(async () => {
      let didCancel = false;

      async function fetchData() {
        dispatch({ type: 'FETCH_INIT' });
        try {
          // const res = await dataManagementStore.fetchWidgetData(id);
          if (!didCancel) {
            // dispatch({ type: 'FETCH_SUCCESS', payload: res.data.data.widgets[name].data });
          }
        } catch (error) {
          console.log('error', error);
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
          // if (_get(error, 'response.status') === 401 && router.pathname !== '/login') {
          //   userStore.logout();
          // }
        }
      }

      if (dataManagementStore.newQuery) {
        await fetchData();
      }

      return () => {
        didCancel = true;
        disposer();
      };
    });
  }, []);

  return { widgetData: state.data, isLoading: state.isLoading };
};
*/

/*function toBuffer(filter) {
  return Buffer.from(JSON.stringify(filter)).toString('base64');
}*/

/*
function bufferQuery(query) {
  let bufferedQuery = _cloneDeep(query);
  if (_has(query.params, 'orderBy')) {
    _set(bufferedQuery.params, 'orderBy', toBuffer(query.params.orderBy));
  }
  if (_has(query.params, 'exportInfo')) {
    _set(bufferedQuery.params, 'exportInfo', toBuffer(query.params.exportInfo));
  }
  if (_has(query.params, 'filter')) {
    _set(bufferedQuery.params, 'filter', toBuffer(query.params.filter));
  }
  if (_has(query.params, 'excludeFields')) {
    _set(bufferedQuery.params, 'excludeFields', toBuffer(query.params.excludeFields));
  }
  return bufferedQuery;
}
*/

/*function removeEmptyValues(passedObj) {
  /!** Here is the replacer function **!/
  const replaceEmptyOrNullValues = function replaceEmptyOrNullValues(key, value) {
    if (value === null || value === undefined || value === '') {
      return undefined;
    }
    return value;
  };

  /!** Here is the function that will actually clean the object that was passed in **!/
  passedObj = JSON.stringify(passedObj, replaceEmptyOrNullValues);
  passedObj = JSON.parse(passedObj);

  return passedObj;
}*/

// function isNilCheck(value) {
//   return !_isNil(value);
// }
/*function removeEmptyValues(obj) {
  return _pickBy(obj, (value, key) => {
    return value !== '' && !_isNil(value);
  });
}*/

/*function useStores() {
}

function useGetRootStore() {
  return stores.store;
}*/

/*function toBuffer(filter) {
  return Buffer.from(JSON.stringify(filter)).toString('base64');
}*/
