import React, { useEffect, useState } from 'react';
import CreateUser from './ManageUsersPage/CreateUser/CreateUser';
import UpdateUser from './ManageUsersPage/UpdateUser/UpdateUser';
import UserHome from './ManageUsersPage/UserHome';
import UserManager from './ManageUsersPage/UserManager';
import AbbreviationPage from '../AbbreviationPage';
import PricingPage from '../Pricing';
import Tabs from './ManageUsersPage/Tabs';
import styled from 'styled-components/macro';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { Route, useRouteMatch } from 'react-router-dom';
import { adminService, commonService, widgetService } from 'services';
import { RouteLoadingSpinner } from 'components/UI';
import { useCheckAuthorizedRoute, _get } from 'utils/utils';
import { useQuery } from 'react-query';

const ManageUsersPage = observer(() => {
  const { path } = useRouteMatch();
  const [tab, setTab] = useState('create');
  const [user, setUser] = useState([])

  const store = useLocalStore(() => ({
    isLoadingclients: false,
    client: {},
    roles: [],
    users: [],
    adminData: {
      countries: null,
      currencies: null,
      dataSources: null,
      pricing_plans: null,
      roles: null,
      state_provs: null,
    },
    setUsers(set, response) {
      const { status, data, error } = response;
      if (status === 'loading') this[`isloading${set}`] = true;
      if (data) {
        this[set] = data.values;
        this[`isloading${set}`] = false;
      }
      if (error) this[`isloading${set}`] = false;
    },
    setClient(set, response) {
      const { status, data, error } = response;
      if (status === 'loading') this[`isloading${set}`] = true;
      if (data) {
        this[set] = data.data;
        this[`isloading${set}`] = false;
      }
      if (error) this[`isloading${set}`] = false;
    }
    // setAdminData(set, response) {
    //   const { status, data, error } = response;
    //   if (status === 'loading') this[`isLoading${set}`] = true;
    //   if (data) {
    //     this[set] = data.data;
    //     this[`isLoading${set}`] = false;
    //   }
    //   if (error) this[`isLoading${set}`] = false;
    // },
  }));

  // const clients = useQuery(['allClients'], adminService.getClients, {
  //   staleTime: 60000,
  //   refetchOnWindowFocus: false,
  // });

  // useEffect(() => {
  //   store.setClients('clients', clients);
  // }, [clients]);

  const client = useQuery(['client'], commonService.fetchUserData, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    store.setClient('client', client);

    async function getUser() {
      const clientData = _get(client, 'data.data.currentClient')
      const userSettings = await adminService.adminUserSettings({clientID: clientData.id})
      store.setUsers('users', userSettings);
    }
    if(!client.isLoading) {
      getUser();
    }
  }, [client]);
  // const users = useQuery(['allUsers'], adminService.adminGetUsers, {
  //   staleTime: 60000,
  //   refetchOnWindowFocus: false,
  // });

  // useEffect(() => {
  //   store.setUsers('users', users);
  // }, [users]);

  // const adminData = useQuery(['adminData'], adminService.getAdminData, {
  //   staleTime: 60000,
  //   refetchOnWindowFocus: false,
  // });

  // useEffect(() => {
  //   store.setAdminData('adminData', adminData);
  // }, [adminData]);

  const { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              {/*<Tabs setTab={setTab} tab={tab} />*/}
              <div className="wrapper">
                <Route path={`/admin/abbreviation`}>
                  <AbbreviationPage />
                </Route>
                <Route path={`/admin/pricing`}>
                  <PricingPage />
                </Route>
                <Route path={`/admin/user/settings`}>
                  <UserHome/>
                </Route>
                <Route path={`/admin/user/add`}>
                  <UserManager isEdit={false} store={store}/>
                  {/* <CreateUser store={store} /> */}
                </Route>
                <Route 
                  path="/admin/user/edit" 
                  render={(props) => <UserManager {...props} isEdit={true} store={store} />} 
                />
                {/* <Route name="editUser" path={`/admin/user/edit`} > */}
                  {/* <UserManager isEdit={true}/> */}
                  {/* <UserHome store={store} /> */}
                  {/* <UpdateUser store={store} /> */}
                {/* </Route> */}
              </div>
            </Wrapper>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  width: calc(100% - 8px);
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 2px;
  .wrapper {
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.6);
  }

  .tabWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2px 5px;
    margin-bottom: 2px;

    grid-template-areas:
      'one one two two three three four four'
      'c1 e1 c2 e2 c3 e3 c4 e4';
    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }
    .tab {
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
`;

const StyledNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1rem;
  height: 20px;
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  font-style: italic;
  padding-right: 10px;
  .note {
    font-size: 1rem;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;

export default ManageUsersPage;
