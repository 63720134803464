import React, { useEffect } from 'react';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { widgetConstants, getAgDefs, initialData } from './resources';
import { Table, LoadingSpinner } from 'components/UI';
import styled from 'styled-components/macro';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { useInjectStores, _get } from 'utils/utils';

const SsResTxns = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { name } = widgetConstants;
  const { dataManagementStore, uiStore, userStore, utilsStore, widgetStore } = useInjectStores();
  const widgetModel = widgetStore.ssResTxnsWidgetModel,
    fetchTransaction = dataManagementStore.fetchTransaction,
    toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal,
    paginatorModel = widgetModel.paginatorModel;

  const { columnDefs, defaultColDef, orderByOptions } = getAgDefs(
    utilsStore.toCurrencyFormatter,
    utilsStore.toDateFormatter,
    fetchTransaction,
    toggleShowTxnDetailModal
  );

  const store = useLocalStore(() => ({
    fileName: 'reserve-txns',
    loading: false,
    widgetData: initialData,
    txnData: [],
    txnCount: 0,
    async getData() {
      store.loading = true;
      try {
        const res = await widgetModel.fetchWidgetData();
        store.widgetData = res.data.data.widgets[name].data;
        store.txnData = store.widgetData.response.map((txn, index) => {
          txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
          return {
            ...txn,
          };
        });
        store.txnCount = store.widgetData.count;
      } catch (error) {
        console.error('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
    columnDefs,
    defaultColDef,
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
  }));

  useEffect(() =>
    autorun(async () => {
      if (store.dataManagementStore.newQuery) {
        paginatorModel.setPageNumber(1);
        await store.getData();
      }
    })
  );

  return (
    <Observer>
      {() => (
        <SSWidgetWrapper height="100%">
          {store.loading && <LoadingSpinner size={'200px'} />}
          {!store.loading && (
            <Observer>
              {() => (
                <Table
                  animateRows
                  columnDefs={store.columnDefs}
                  defaultColDef={store.defaultColDef}
                  fetchTableData={store.getData}
                  loading={store.loading}
                  reactNext
                  rowData={store.txnData}
                  rowSelection="multiple"
                  suppressMenuHide
                  suppressMenu
                  /**export**/
                  initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                  exportInfo={store.widgetModel.exportInfo}
                  /** Paginator-Specific Functions **/
                  txnCount={store.txnCount}
                  paginator={store.paginatorModel}
                  /** OrderBy Properties **/
                  addOrderBy={store.widgetModel.addOrderBy}
                  orderByArray={store.widgetModel.orderByModel}
                  orderByOptions={store.orderByOptions}
                  resetOrderBy={store.widgetModel.resetOrderBy}
                />
              )}
            </Observer>
          )}
        </SSWidgetWrapper>
      )}
    </Observer>
  );
});

const SSWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: ${({ margin }) => (margin ? margin : '0')};
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

export default SsResTxns;
