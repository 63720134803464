import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {commonService, widgetService} from 'services';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useInjectStores, _get } from 'utils/utils';

/*var Delta = Quill.import('delta');
var quill = new Quill('#editor-container', {
  modules: {
    toolbar: true
  },
  placeholder: 'Compose an epic...',
  theme: 'snow'
});

// Store accumulated changes
var change = new Delta();
quill.on('text-change', function(delta) {
  change = change.compose(delta);
});

// Save periodically
setInterval(function() {
  if (change.length() > 0) {
    console.log('Saving changes', change);
    /!*
    Send partial changes
    $.post('/your-endpoint', {
      partial: JSON.stringify(change)
    });

    Send entire document
    $.post('/your-endpoint', {
      doc: JSON.stringify(quill.getContents())
    });
    *!/
    change = new Delta();
  }
}, 5*1000);

// Check for unsaved data
window.onbeforeunload = function() {
  if (change.length() > 0) {
    return 'There are unsaved changes. Are you sure you want to leave?';
  }
}*/

var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
];

const Notes = observer((params) => {
  const { dataManagementStore, uiStore } = useInjectStores();
  const clientID = dataManagementStore.clientID,
    notify = uiStore.notify;
  let uniqTxnID = _get(params, 'data.uniq_txn_id') || _get(params, 'uniqTxnID');
  let modal = _get(params, 'modal', false);
  let midNum = _get(params, 'midNum', false) || _get(params, 'data.mid');

  const [value, setValue] = useState('');

  async function getNote() {
    try {
      const res = await widgetService.detailedNotesGet({uniqTxnID, midNum, clientID})
      const savedNote = _get(res,'data.note', '');
      setValue(savedNote);
    } catch (e) {
      console.error('e', e);
    }
  }

  async function saveNote(value) {
     let note = value
      if (value === undefined) {
        note = '';
      }
    try {
      const res = await widgetService.detailedNotesPost({uniqTxnID, midNum, clientID, note})
      notify({
        duration: 1500,
        group: 'topRight',
        text: `changes saved successfully`,
      });
    } catch (e) {
      console.error('e', e);
    }
  }

  async function clearNote() {
    setValue('');
    await saveNote();
  }

  useEffect(
    () =>
      autorun(async () => {
        if (uniqTxnID && clientID) {
          await getNote();
        }
      }),
    []
  );

  return (
    <Wrapper modal={modal}>
      <div className="footer">
        <button onClick={() => saveNote(value)} className="save">
          Save Changes
        </button>
        &nbsp;
        <button onClick={() => clearNote(value)} className="clear">
          Clear Note
        </button>
      </div>
      <div className="toolbar-space" />
      <ReactQuill theme="snow" value={value} modules={{ toolbar: toolbarOptions }} onChange={setValue} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
  margin: 2px 1px 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
  background: ${({ theme, modal }) => (modal ? theme.rgl.gridItem.colors.background : '')};
  .quill {
    height: 100%;
    min-height: 306px;
    max-height: 306px;
    overflow: scroll;
    .ql-toolbar {
      position: relative;
      top: 0;
      width: 100%;
    }
    .ql-editor {
      min-height: 210px;
      max-height: 210px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
    .ql-picker-label {
      color: ${({ theme }) => theme.colors.primaryText};
    }
    .ql-stroke {
      stroke: ${({ theme }) => theme.colors.primaryText};
    }
    .ql-fill {
      fill: ${({ theme }) => theme.colors.primaryText};
    }
    .ql-container,
    .ql-toolbar {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .footer {
    position: absolute;
    bottom: 6px;
    left: 6px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 1;
    .save {
      background-color: ${({ theme }) => theme.baseColors.colorBlue};
      color: rgba(255, 255, 255);
      border-radius: 3px;
      border: none;
      padding: 3px 5px;
      //width: 80px;
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.baseColors.colorBlueHover};
      }
    }
    .clear {
      background-color: ${({ theme }) => theme.baseColors.colorOrange};
      color: rgba(255, 255, 255);
      border-radius: 3px;
      border: none;
      padding: 3px 5px;
      //width: 80px;
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.baseColors.colorOrangeHover};
      }
    }
    //.save {
    //  position: absolute;
    //  bottom: 4px;
    //  left: 4px;
    //}
  }
`;

export default Notes;
