import React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { DoNotHonor } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import styled from 'styled-components/macro';
import { TableWrapper } from '../../components/Widgets/StyledTable';
import { RouteLoadingSpinner } from 'components/UI';

const doNotHonor = observer(() => {
  const { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <PageWrapper>
              <DoNotHonorInfo />
              <DoNotHonor />
            </PageWrapper>
          ) : (
            <div>You do not have access to this report</div>
          )}
        </>
      )}
    </Observer>
  );
});

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DoNotHonorInfo = () => {
  return (
    <TableWrapper margin={'0px 4px 4px 4px'} padding="5px 15px 5px 15px">
      <StyledNote>
        <span>
          <span className="note">* </span>
          <span className="noteBody">
            The Do Not Honor Report will have different counts and amounts than the Declines details and Opportunity pages due to
            the following:
          </span>
        </span>
        <span className="noteBody">
          <li>Only uncollected orders are displayed on this report</li>
          <li>
            When an order has multiple attempts at collecting the sale, only the original order is brought over (not each
            reattempt)
          </li>
        </span>
      </StyledNote>
    </TableWrapper>
  );
};

const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 10px;
  padding: ${({ padding }) => padding};
  align-items: flex-start;
  font-style: italic;
  .note {
    font-size: 12px;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;

export default doNotHonor;
