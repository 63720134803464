import React from 'react';
import { observer } from 'mobx-react';
import { ThemeTableHeader, GridTemplate } from 'styles/styledComponents';
import styled from 'styled-components/macro';

import { TableHeaderButton } from './StyledResources/StyledTableHeader';
import { Button, Dropdown, Paginator } from 'components';
import { useLocalStore, Observer } from 'mobx-react-lite';
import { useInjectStores } from 'utils/utils';

const exportOptions = [
  { key: 1, text: 'EXCEL', value: 'excel' },
  { key: 2, text: 'CSV', value: 'csv' },
];

const Table = observer((props) => {
  const { uiStore } = useInjectStores();
  const isDemo = uiStore.isDemo;

  const localStore = useLocalStore(() => ({
    showSortPopup: false,
    toggleShowSortPopup() {
      localStore.showSortPopup = !localStore.showSortPopup;
    },
    initiateTxnsExport() {
      if (!isDemo) {
        props.initiateTxnsExport();
      } else {
        localStore.notify({
          group: 'bottomCenter',
          text: 'This account is not authorized to export data',
          type: 'error',
          duration: 5000,
        });
      }
    },
  }));

  return (
    <Observer>
      {() => (
        <Wrapper>
          <ThemeTableHeader>
            <div className="headerGrid orderByExportContainer">
              {props.hasSearch ? (
                <div style={{display: 'flex', alignItems: 'center', paddingTop: '6px'}}>
                  <input style={{height: '35px'}} className="fvGfeV" type="text" placeholder="Batch Number" onChange={(e) => props.store.batchNum = e.target.value}/>
                  <TableHeaderButton
                    style={{margin: '0px'}}
                    onClick={() => props.searchAction()}
                  >
                    Search
                  </TableHeaderButton>
                </div>
              ) : null}
              {props.hasSelect ? (
                <div style={{display: 'flex', alignItems: 'start', paddingTop: '6px', gap: '5px'}}>
                  <Dropdown
                    placeholder="Select Agent"
                    caretRight={10}
                    options={props.store.agentList}
                    onChange={(value) => props.store.selectedAgent = value}
                    value={props.store.selectedAgent}
                    width="200px"
                    border
                  />
                  <TableHeaderButton
                    style={{margin: '0px'}}
                    onClick={() => props.searchAction()}
                  >
                    Search
                  </TableHeaderButton>
                </div>
              ) : null}
              {/*<div className="orderByContainer">
            <div className={'sortBtn stackingEffect'}>
              <SortAlphaDownContainer id="Button" onClick={localStore.toggleShowSortPopup}>
                <SortAlphaDownIcon id="Button" color="#fff" style={{ width: 20, height: 20 }} />
              </SortAlphaDownContainer>
              {localStore.showSortPopup && (
                <Observer>
                  {() => (
                    <PopupMenu
                      show={localStore.showSortPopup}
                      hidePopup={localStore.toggleShowSortPopup}
                      direction="column"
                      top={'45px'}
                      left={0}
                    >
                      <div className="arrowUp" />
                      <div className="addFieldContainer">
                        <button
                          className="addField"
                          onClick={() => {
                            if (props.orderByArray.length < props.orderByOptions.length) {
                              props.addOrderBy();
                            }
                          }}
                        >
                          Add Field
                        </button>
                      </div>
                      {_map(props.orderByArray, (orderBy, index) => {
                        return (
                          <div
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              padding: '2px 0',
                            }}
                            key={index}
                          >
                            {index === 0 && <div className="orderBy">Order by</div>}
                            {index !== 0 && <div className="thenBy">Then by </div>}
                            <Dropdown
                              margin={0}
                              caretRight={10}
                              placeholder="Select"
                              options={props.orderByOptions}
                              onChange={orderBy.setColumn}
                              value={orderBy.column}
                              width={'100%'}
                              // value={_get(_find(orderByOptions, { value: orderBy.column }), 'text', '')}
                              border
                            />
                            {orderBy.sort === 1 && (
                              <IconContainer cursorHover="pointer">
                                <SortAmountUpIcon
                                  style={{ width: 20, height: 20 }}
                                  onClick={() => orderBy.setSort(0)}
                                  color="#DBDBDB"
                                  hoverColor="#45E885"
                                />
                              </IconContainer>
                            )}
                            {orderBy.sort === 0 && (
                              <IconContainer cursorHover="pointer">
                                <SortAmountDownIcon
                                  style={{ width: 20, height: 20 }}
                                  onClick={() => orderBy.setSort(1)}
                                  color="#DBDBDBD"
                                  hoverColor="#45E885"
                                />
                              </IconContainer>
                            )}
                            {props.orderByArray.length > 1 && (
                              <IconContainer cursorHover="pointer">
                                <MinusCircleIcon
                                  onClick={orderBy.remove}
                                  style={{ width: 20, height: 20 }}
                                  color="#DBDBDB"
                                  hoverColor="red"
                                />
                              </IconContainer>
                            )}
                          </div>
                        );
                      })}
                      <div className="applyClearContainer">
                        <button
                          className="applyBtn"
                          onClick={() => {
                            props.paginator.resetPaginator();
                            props.fetchTableData();
                          }}
                        >
                          Apply
                        </button>
                        <button
                          className="clearBtn"
                          onClick={() => {
                            props.resetOrderBy();
                            props.paginator.resetPaginator();
                            props.fetchTableData();
                            localStore.toggleShowSortPopup();
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </PopupMenu>
                  )}
                </Observer>
              )}
            </div>
          </div>*/}
              {/*{this.showExport && (*/}
              <GridTemplate gap="5px" padding="6px 0 0 0" columns="auto auto" area="stateBtns">
                {/* <Button type="primary" padding="0 8px" height={'35px'} onClick={props.store.saveColumnState}>
                  Save Order
                </Button> */}
                {/*<Button type="primary" padding="0 8px" height={'35px'} onClick={props.store.setColumnState}>*/}
                {/*  Restore*/}
                {/*</Button>*/}
                {/* <Button type="delete" padding="0 8px" height={'35px'} onClick={props.store.resetColumnState}>
                  Reset
                </Button> */}
              </GridTemplate>
              {!props.noPaginator ? (
              <div className="paginator">
                <Paginator
                  store={props.store}
                  paginator={props.paginator}
                  loading={props.loading}
                  fetchTableData={props.fetchTableData}
                  txnCount={props.txnCount}
                />
              </div>
              ) : null}
              {!props.noExport ? (
                <div className="export exportTypeContainer">
                <Dropdown
                  className="exportDropdown"
                  caretRight={10}
                  options={exportOptions}
                  onChange={props.exportInfo.setExportType}
                  // onInput={()=>test(props)}
                  value={props.exportInfo.exportType}
                  width="80px"
                  height="33px"
                  margin="0 5px 3px 0"
                  border
                />
                {/*<TableHeaderButton isDemo={isDemo} onClick={props.initiateTxnsExport}>*/}
                <TableHeaderButton
                  isDemo={isDemo}
                  onClick={() => {
                    if (props.exportInfo.exportType === 'csv') {
                      props.store.api.exportDataAsCsv({ fileName: props.store.fileName });
                    } else {
                      props.store.api.exportDataAsExcel({ fileName: props.store.fileName });
                    }
                  }}
                >
                  Export
                </TableHeaderButton>
                {props.hasDownloadAll ? (
                  <TableHeaderButton
                    style={ props.store.downloadingFiles ? {backgroundColor: 'grey', cursor: 'not-allowed'} : null}
                    isDemo={isDemo}
                    disabled={props.store.downloadingFiles}
                    onClick={() => props.store.getAllData()}
                  >
                  Export All
                  </TableHeaderButton>
                ) : null}
              </div>
              ) : null}
            </div>
          </ThemeTableHeader>
        </Wrapper>
      )}
    </Observer>
  );
});

function test(props) {
  if (props.exportInfo.exportType === 'csv') {
    props.store.api.exportDataAsCsv({ fileName: props.store.fileName });
  } else {
    props.store.api.exportDataAsExcel({ fileName: props.store.fileName });
  }
}

const Wrapper = styled.div`
  .headerGrid {
    display: grid;
    align-items: flex-start;
    width: 100%;
    grid-template-areas: 'stateBtns sortBtn paginator export';
    grid-template-columns: auto auto 1fr auto;

    .sortBtn {
      padding-top: 6px;
      grid-area: sortBtn;
    }
    .stateBtns {
      //display: grid;
      //grid-gap: 5px;
      //padding-top: 6px;
      grid-area: stateBtns;
    }
    .paginator {
      padding: 2px 0 3px 0;
      width: 100%;
      grid-area: paginator;
    }
    .export {
      padding-top: 6px;
      display: flex;
      //align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      grid-area: export;
    }
  }

  .stackingEffect {
    position: relative;
  }

  .orderByExportContainer {
    //align-items: center;
    //display: flex;
    //width: 100%;
    //justify-content: space-between;
  }

  .exportTypeContainer {
    display: flex;
    align-items: end;
    justify-self: end;
    .exportBtn {
      border-radius: 5px;
      color: #fff;
      background: #3fa9f5;
      outline: none;
      margin: 0 2px;
      &:hover {
        cursor: pointer;
        background: #3586c8;
      }
    }
  }

  .arrowUp {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -5px;
  }
  .orderBy {
    white-space: nowrap;
    padding-right: 5px;
  }
  .thenBy {
    white-space: nowrap;
    padding-right: 9px;
  }
  .addFieldContainer {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 4px 0;
    width: 330px;
  }
  .applyBtn {
    border-radius: 5px;
    color: #fff;
    background: #3fa9f5;
    outline: none;
    margin: 0 2px;
    &:hover {
      cursor: pointer;
      background: #3586c8;
    }
  }
  .clearBtn {
    border-radius: 5px;
    color: #fff;
    background: #f79514;
    outline: none;
    margin: 0 2px;
    &:hover {
      cursor: pointer;
    }
  }
  .addField {
    display: flex;
    color: #fff;
    border: 1px solid #3fa9f5;
    width: 70px;
    outline: none;
    border-radius: 5px;
    background: #3fa9f5;
    &:hover {
      cursor: pointer;
    }
  }
  .applyClearContainer {
    display: flex;
    justify-content: flex-end;
    margin: 4px 0 0 0;
  }
  .oddRowsStyle {
    background: #ddd;
  }
`;

export default Table;
