import React, {useEffect} from 'react';
import { Details } from './index';
import styled from 'styled-components';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { useInjectStores } from 'utils/utils';
import { applySnapshot } from 'mobx-state-tree';
import {useHistory, useLocation} from "react-router-dom";

const Tax1099k = observer(({ margin }) => {
  const { dataManagementStore, filterStore } = useInjectStores();
  const history = useHistory();
  const location = useLocation();

  const store = useLocalStore(() => ({
    history,
    location,
  }));

  return (
    <Observer>
      {() => (
        <Wrapper margin={margin}>
          <div className="total scroll">
            <Details store={store} />
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  .total {
    grid-area: total;
  }
 
  grid-template-areas: 'total';
  grid-template-columns: 1fr;
  grid-gap: 5px;
  padding-bottom: 4px;
  .scroll {
    overflow: scroll;
    background: ${({theme, noBackground, background}) => {
      if (background) {
        return background;
      }
      if (!noBackground) return theme.rgl.gridItem.colors.background;
      // if (!noBackground) return theme.tableStyles.backgroundColor;
      else return 'none';
    }};
  }
`;

export default Tax1099k;
