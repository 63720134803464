import React, { useEffect } from "react";
import styled from 'styled-components/macro';

const Dialog = ({ isOpen, onClose, children }) => {
	useEffect(() => {
		document.body.style.overflow = isOpen ? "hidden" : "auto";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isOpen]);

	if (!isOpen) {
		return null;
	}

	return (
		<Wrapper>
			<div className="modal-overlay" onClick={onClose}>
				<div
					className="modal-content"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="modal-body">
						{children}
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.modal-content {
		border: 1px solid white;
		border-radius: 5px;
		background-color: white;
		color: black;
		width: 25%;
		padding: 10px;
		display: flex;
		justify-content: center;  
		.modal-body {
			width: 100%;
		}
	}
`;

export default Dialog;
