import React, {useEffect} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {Table} from 'components/UI';
import {getAgDefs, initialData} from './resources';
import {autorun} from 'mobx';
import {useHistory, useLocation} from 'react-router-dom';
import {StoreContext} from 'stores/RootStore';
import {_get, _upperFirst, useInjectStores} from 'utils/utils';
import {widgetService} from 'services';

function cellStyle(params) {
    if (params.value < 0) {
        return {color: '#FF2B49'};
    } else {
        // return { color: 'rgb(69, 232, 133)' };
    }
}

const SsOpportunityTxns = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const rootStore = React.useContext(StoreContext);
    const {dataManagementStore, widgetStore, userStore, utilsStore} = useInjectStores();
    const widgetModel = widgetStore.ssOppTxnsWidgetModel,
        fetchTransaction = rootStore.dataManagementStore.fetchTransaction,
        toggleShowTxnDetailModal = rootStore.uiStore.toggleShowTxnDetailModal,
        toCurrencyFormatter = utilsStore.toCurrencyFormatter,
        toDateFormatter = utilsStore.toDateFormatter,
        paginatorModel = widgetModel.paginatorModel;
    const {columnDefs, defaultColDef, orderByOptions} = getAgDefs(
        utilsStore.toCurrencyFormatter,
        utilsStore.toDateFormatter,
        fetchTransaction,
        toggleShowTxnDetailModal
    );

    const store = useLocalObservable(() => ({
        fileName: 'opportunity-txns',
        loading: false,
        widgetData: initialData,
        rowData: [],
        txnCount: 0,
        async getData() {
            this.loading = true;
            try {
                const res = await widgetModel.fetchWidgetData(widgetService.opptxnA);

                store.widgetData = res.data;
                store.rowData = store.widgetData.values.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn,
                    };
                });
                store.txnCount = store.widgetData.count;
                store.loading = false;
            } catch (error) {
                console.error('error', error);
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    userStore.logout(history, location);
                }
                store.loading = false;
            }
        },
        columnDefs,
        defaultColDef,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        onGridReady(params) {
            this.api = params.api;
            this.gridColumnApi = params.columnApi;
            store.setColumnState();
        },
        api: null,
        gridColumnApi: null,
        saveColumnState() {
            let savedState = store.gridColumnApi.getColumnState();
            savedState = JSON.stringify(savedState);
            if (savedState) {
                window.localStorage.setItem('decl-details-state', savedState);
            }
        },
        setColumnState() {
            let savedState = window.localStorage.getItem('decl-details-state');
            savedState = JSON.parse(savedState);
            if (savedState && store.gridColumnApi) {
                store.gridColumnApi.applyColumnState({state: savedState, applyOrder: true});
            }
        },
        resetColumnState() {
            window.localStorage.removeItem('decl-details-state');
            store.gridColumnApi.resetColumnState();
        },
        get gridOptions() {
            return {
                onGridReady: store.onGridReady,
                onFirstDataRendered(params) {
                    setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
                },
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                enableBrowserTooltips: true,
                tooltipShowDelay: 0,
                reactNext: true,
                rowHeight: 33,
                headerHeight: 33,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: false,
                    filter: true,
                    resizable: true,
                },
                columnDefs: [
                    {
                        headerName: 'Row',
                        field: 'Index',
                        pinned: 'left',
                        checkboxSelection: true,
                        filter: false,
                        width: 140,
                        // cellRenderer: 'agGroupCellRenderer',
                    },

                    {
                        headerName: 'EIGHT ID',
                        field: 'eight_id',
                        enableRowGroup: true,
                        enablePivot: true,
                        chartDataType: 'series',
                        width: 150,
                    },
                    {
                        headerName: 'MID',
                        field: 'mid',
                        enableRowGroup: true,
                        enablePivot: true,
                        chartDataType: 'series',
                        width: 160,
                    },
                    {
                        headerName: 'MID Name',
                        field: 'mid_nm',
                        enableRowGroup: true,
                        enablePivot: true,
                        chartDataType: 'series',
                        width: 150,
                    },
                    {
                      headerName: 'Proc Txn ID',
                      field: 'proc_txn_id',
                      enableRowGroup: true,
                      enablePivot: true,
                      chartDataType: 'series',
                      width: 155,
                    },
                    {
                      headerName: 'Txn Date',
                      field: 'txn_date',
                      valueFormatter: toDateFormatter,
                      enableRowGroup: true,
                      enablePivot: true,
                      chartDataType: 'series',
                      width: 150,
                    },
                    {
                      headerName: 'Txn Day Wk',
                      field: 'txn_day_wk',
                      enableRowGroup: true,
                      enablePivot: true,
                      chartDataType: 'series',
                      width: 150,
                    },
                  {
                    headerName: 'Txn Type Count By CC',
                    field: 'txn_typ_cnt_by_cc',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Txn Type',
                    field: 'txn_type',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Auth Amount',
                    type: 'rightAligned',
                    field: 'auth_amt',
                    enableRowGroup: true,
                    enablePivot: true,
                    valueFormatter: toCurrencyFormatter,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Sttl Amount',
                    field: 'sttl_amt',
                    type: 'rightAligned',
                    enableRowGroup: true,
                    enablePivot: true,
                    valueFormatter: toCurrencyFormatter,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Curr Cd',
                    field: 'curr_cd',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Preventable T/F',
                    field: 'preventable_tf',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Txn Status',
                    field: 'txn_status',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                    {
                    headerName: 'Status Desc',
                    field: 'status_desc',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },{
                    headerName: 'Opp Category',
                    field: 'opp_category',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Opp Succ Ratio',
                    field: 'et_opp_succ_ratio',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Opp Action Desc',
                    field: 'et_opp_action_desc',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Opp Subaction Desc',
                    field: 'et_opp_subactn_desc',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Collect T/F',
                    field: 'collected_tf',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Collected Date',
                    field: 'collected_date',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Collected Amount',
                    field: 'collected_amt',
                    type: 'rightAligned',
                    enableRowGroup: true,
                    enablePivot: true,
                    valueFormatter: toCurrencyFormatter,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'CC Num (Concat)',
                    field: 'cc_num',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'ET Cust ID',
                    field: 'et_cust_id',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'ET Cust Name',
                    field: 'et_cust_nm',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Cust ID',
                    field: 'cust_id',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'GW Cust Name',
                    field: 'gw_cust_nm',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Inv Num',
                    field: 'inv_num',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },
                  {
                    headerName: 'Inv Desc',
                    field: 'inv_desc',
                    enableRowGroup: true,
                    enablePivot: true,
                    chartDataType: 'series',
                    width: 150,
                  },


                ],

                overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; border: 1px solid rgba(255,255,255, .8);">No Data for selected Time Period</span>',
            };
        },

    }));

    useEffect(
        () =>
            autorun(async () => {
                if (dataManagementStore.newQuery) {
                    paginatorModel.setPageNumber(1);
                    await store.getData();
                }
            }),
        []
    );

    return (
        <Observer>
            {() => (
                <Table
                    store={store}
                    fetchTableData={store.getData}
                    rowData={store.rowData}
                    loading={store.loading}
                    /**export**/
                    initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                    exportInfo={store.widgetModel.exportInfo}
                    /** Paginator-Specific Functions **/
                    txnCount={store.txnCount}
                    paginator={store.paginatorModel}
                    /** OrderBy Properties **/
                    addOrderBy={store.widgetModel.addOrderBy}
                    orderByArray={store.widgetModel.orderByModel}
                    orderByOptions={store.orderByOptions}
                    resetOrderBy={store.widgetModel.resetOrderBy}
                />
            )}
        </Observer>
    );
});

export default SsOpportunityTxns;
