import styled from 'styled-components/macro';

export const SelectClientWrapper = styled.div`
  height: 100%;
  padding: 5px 10px 0 0;
  align-items: center;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: ' selectClient .';

  .selectClient {
    grid-area: selectClient;
  }

  .divider {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.text}`};
    grid-area: divider;
  }
`;

export const CreateEditClientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  width: calc(100% - 8px);
  height: 100%;

  background: ${({ noBackground, theme }) => {
    if (noBackground && noBackground === true) return 'none';
    else return theme.tableStyles.backgroundColor;
  }};
  .divider {
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.text}`};
  }
  .selectMidNumber {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
  .header-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px;
    .form-header {
      margin-top: 5px;
      text-align: left;
      font-size: 20px;
      color: ${({ theme }) => theme.colors.text};
    }
  }
  .subResCont {
    display: flex;
    justify-content: center;
    height: 150px;
    margin-top: 10px;
    .submit {
      width: 75px;
      box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
      display: flex;
      height: 30px;
      align-items: center;
      padding: 0 5px;
      justify-content: center;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      margin: 5px 3px;
      background-color: #3fa9f5;
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.baseColors.colorBlueHover};
      }
    }
  }
  .reset {
    width: 75px;
    box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 5px;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    margin: 5px 3px;
    background-color: #f79514;
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.baseColors.colorOrangeHover};
    }
  }
  .clear {
    background-color: ${({ theme }) => theme.buttonStyles.delete};
    width: 75px;
    box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 5px;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    margin: 5px 3px;
    &:hover {
      background-color: ${({ theme }) => theme.buttonStyles.deleteHover};
    }
  }
`;

export const SubmitButton = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background-color: #3fa9f5;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;

export const StyledMidAggrWrapper = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
`;

export const Form = styled.div`
  display: grid;
  width: 1040px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
`;

export const AggrForm = styled.div`
  .divider {
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.text}`};
  }
  display: grid;
  width: 1040px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
`;

export const DeleteButton = styled.div`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ theme }) => theme.buttonStyles.delete};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  margin: ${({ margin }) => margin};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.buttonStyles.deleteHover};
  }
`;
