import React, {useEffect} from 'react';
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
import {Dropdown, Error, LoadingSpinner} from 'components/UI';
import {TableWrapper, TableHeader} from '../StyledTable';
import styled from 'styled-components/macro';
import {AgGridReact} from 'ag-grid-react';
import {TableHeaderButton} from 'components/UI/Table/TableHeader/StyledResources/StyledTableHeader';
import {_get, _toNumber} from 'utils/utils';
import {TableContainer} from "../../../styles/styledComponents";

const blankRow = {coa: '', coa_desc: '', dr: '', cr: ''};
const CashWidget = observer((props) => {

    return (
        <Observer>
            {() => (
                <Wrapper>
                    <TableWrapper width="100%" height={'100%'}>
                        <TableContainer height={'100%'}>
                            <div className="note">
                                <div> Reasons to use Accrual Basis journal entries for accounting:</div>

                                <ol type="A">
                                    <li> Deposit files in the payments industry are extremely limited in data and
                                        provide no details; accordingly, cash-basis entries only show sales with deposits and do not account for undeposited sales or short payments (thus, there are no accounts receivable or knowledge of potential collection opportunities). 
                                    </li>
                                    <li> With no transactional data available, revenues cannot be broken out into
                                        their multiple possible situations such as break-outs between product, shipping amounts, tips, discounts, and/or taxes.  
                                    </li>
                                    <li> All fees, including chargeback penalties, are lumped together by the industry and do not follow the matching principles of accrual accounting... also, leaving you with the inability to determine actual merchant processing ratios.
                                    </li>
                                </ol>
                            </div>
                            <br/>
                        </TableContainer>
                    </TableWrapper>
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  height: 100%;
  font-size: 13px;
  .note {
    padding: 40px 20px;
    line-height: 2em;
  }
  //padding: 0 15px;


  .spacing {
    line-height: 2em;
  }
`;

export default CashWidget;
