import { Input } from '../index';
import { CheckIcon, CircleIcon, CloseIcon } from 'styles/styledComponents';
import React from 'react';
import { observer, Observer, useObserver } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { _map, useInjectStores } from 'utils/utils';

function useStoreData() {
  const { widgetStore } = useInjectStores();
  return useObserver(() => ({
    currentTodos: widgetStore.actionListModel.currentTodos,
    handleKeyDown: widgetStore.actionListModel.handleKeyDown,
    newTodoContent: widgetStore.actionListModel.newTodoContent,
    onChangeTodo: widgetStore.actionListModel.onChangeTodo,
    setFilter: widgetStore.actionListModel.setFilter,
    todoCount: widgetStore.actionListModel.itemsLeft,
    filter: widgetStore.actionListModel.filter,
    clearComplete: widgetStore.actionListModel.clearComplete,
    createActionItems: widgetStore.actionListModel.createActionItems,
    deleteActionItem: widgetStore.actionListModel.deleteActionItem,
    fetchActionItems: widgetStore.actionListModel.fetchActionItems,
  }));
}

// popup on hover, completed by, created by, completed when
const ActionList = observer((params) => {
  const {
    createActionItems,
    currentTodos,
    newTodoContent,
    onChangeTodo,
    setFilter,
    todoCount,
    filter,
    clearCompleted,
    deleteActionItem,
  } = useStoreData();

  /*  const [value, setValue] = useState('');

  async function getTodos(value) {
    try {
      const res = await commonService.getWidgetData({
        filter: { clientID, widgetID: 61, uniqTxnID, note: value, startDt, endDt },
      });
      const savedNote = res.data.data.widgets.getNote.data.results.note;
      setValue(savedNote);
    } catch (e) {
      console.error('e', e);
    }
  }

  async function saveTodos(value) {
    try {
      const res = await commonService.getWidgetData({
        filter: { clientID, widgetID: 62, uniqTxnID, note: value, startDt, endDt },
      });
      console.log('res', res);
    } catch (e) {
      console.error('e', e);
    }
  }

  function clearNote() {
    setValue('');
  }*/

  return (
    <Observer>
      {() => (
        <StyledWrapper>
          <div className="actionItems">
            <h3 className="header">Action Items</h3>
            {/*<button*/}
            {/*  onClick={() => {*/}
            {/*    fetchActionItems();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  get items*/}
            {/*</button>*/}
            <div className={'container'}>
              {/*need to check all*/}
              <Input
                height={'100%'}
                width={'100%'}
                placeholder={'What needs to be done?'}
                onKeyDown={createActionItems}
                // onKeyDown={handleKeyDown}
                value={newTodoContent}
                onChange={onChangeTodo}
                borderRadius={'0px'}
                placeHoldColor="rgba(0, 0, 0, .3)"
              />
              <ul className="todoList">
                {_map(currentTodos, (todo, index) => {
                  return (
                    <ActionItems key={`${index}:${todo.completed}`} className="todo" completed={todo.completed}>
                      <div className="todoContent">
                        <CircleIcon size={30} onClick={() => todo.toggleCompleteTodo(index)} style={{ minWidth: '30px' }} />
                        {todo.completed && (
                          <CheckIcon
                            onClick={() => todo.toggleCompleteTodo(index)}
                            style={{ zIndex: 2, position: 'absolute', left: '28px' }}
                            size={27}
                          />
                        )}
                        <label>{todo.actionItem}</label>
                      </div>
                      <div className="delete">
                        <CloseIcon size={20} onClick={() => deleteActionItem(todo)} />
                      </div>
                    </ActionItems>
                  );
                })}
              </ul>
              <div className="todoFooter">
                <div className="itemsLeft">{todoCount} items left</div>

                <div
                  className="filter"
                  onClick={() => setFilter('all')}
                  style={{ border: filter === 'all' ? '1px solid red' : '' }}
                >
                  All
                </div>
                <div
                  className="filter"
                  onClick={() => setFilter('active')}
                  style={{ border: filter === 'active' ? '1px solid red' : '' }}
                >
                  Active
                </div>
                <div
                  className="filter"
                  onClick={() => setFilter('completed')}
                  style={{ border: filter === 'completed' ? '1px solid red' : '' }}
                >
                  Completed
                </div>
                {/*need to clear completed*/}
                <div className="clearCompleted" onClick={clearCompleted}>
                  Clear Completed
                </div>
              </div>
            </div>
          </div>
        </StyledWrapper>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  color: ${({ theme }) => theme.primaryColor};
  color: rgba(0, 0, 0, 0.3);
  background: ${({ theme }) => theme.rgl.gridItem.colors.background};
  font-family: 'Roboto', 'calibri', sans-serif;
  //width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 5px;
  align-items: center;
  //max-height: 260px;
  overflow-y: auto;

  .header {
    text-align: center;
    color: #fff;
  }
  .actionItems {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0 5px;
    h3 {
      color: #fff;
    }
    .container {
      // border: ${({ theme }) => `1px solid ${theme.colors.primaryText}`};
      border: ${({ theme }) => `1px solid ${theme.rgl.gridItem.colors.primaryText}`};
      border: ${({ theme }) => `1px solid rgba(0, 0, 0, 0.3);`};
      width: 100%;
      .header {
        box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.03);
      }
      .todoList {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-left: 0;
        width: 100%;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        max-height: 164px;
        overflow-y: auto;
      }

      .todoFooter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 100%;
        background: #fff;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        .itemsLeft {
          position: absolute;
          left: 5px;
        }
        .filter {
          border: 1px solid transparent;
          border-radius: 3px;
          margin: 0 5px;
          padding: 0 5px;
          &:hover {
            cursor: pointer;
            border-color: crimson;
          }
        }
        .clearCompleted {
          position: absolute;
          right: 5px;
          &:hover {
            cursor: pointer;
            text-decoration: underline solid black;
          }
        }
      }
    }
  }
`;

const ActionItems = styled.li`
  border-bottom: 1px solid #ededed;
  width: 100%;
  background: #fff;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .delete {
      visibility: visible;
    }
  }

  .todoContent {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  label {
    text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
    padding-left: 5px;
  }
  .delete {
    visibility: hidden;
    padding-right: 10px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export default ActionList;
