import { destroy, types, getSnapshot } from 'mobx-state-tree';
import Moment from 'moment';
import {
  AuthnetApi,
  AuthnetScrape,
  CybersourceScrape,
  NMIScrape,
  USAepayApi,
  USAepayScrape,
  ApiCreds,
  ScrapeCreds,
  GoldstarApi,
} from './CredentialModels';
import React from 'react';
const { maybeNull, array, optional, maybe, string, number, boolean, union } = types;

export const limitsModel = types
  .model('procLimit', {
    id: maybe(number),
    midNumber: maybe(number),
    limitStartDate: optional(string, Moment().startOf('day').toISOString()),
    limitEndDate: optional(string, Moment().startOf('day').toISOString()),
    monthlyProcLimit: optional(number, 0),
    // maxTxnVolume: optional(number, 0),
  })
  .actions((self) => {
    function setLimitStartDate(e) {
      self.limitStartDate = e.target.value;
    }
    function setMonthlyProcLimit(e) {
      self.monthlyProcLimit = parseInt(e.target.value);
    }
    // function setMidTxnLimit(e) {
    //   self.midTxnLimit = parseInt(e.target.value);
    // }
    function setMidNumber(e) {
      self.midNumber = parseInt(e.target.value);
    }
    return {
      setLimitStartDate,
      setMonthlyProcLimit,
      // setMidTxnLimit,
      setMidNumber
    };
  });

/*export const credentialsModel = types
  .model('credentials', {
    creds: maybe(union(AuthnetApi, AuthnetScrape, CybersourceScrape, Everest, Nmi, USAepayApi, USAepayScrape)),
  })
  .actions((self) => {
    function setCreds() {
      let parent = getParent(self);
      /!*   if (parent.aggrID === 5) {
        self.creds = authnetCredsModel.create();
      }
      if (parent.aggrID === 39) {
        self.creds = nmiCredsModel.create();
      }
      if (parent.aggrID === 152) {
        self.creds = dataCapCredsModel.create();
      }*!/
    }
    function setAggrID(aggrID) {
      self.aggrID = aggrID;
    }
    function setAggrWhositID(aggrWhositID) {
      self.aggrWhositID = aggrWhositID;
    }
    function selectAggregator(aggr, option) {
      // if (aggr === 5) {
      //   self.creds = authnetCredsModel.create();
      // }
      // if (aggr === 39) {
      //   self.creds = nmiCredsModel.create();
      // }
      // if (aggr === 152) {
      //   self.creds = dataCapCredsModel.create();
      // }
      self.aggrID = aggr;
    }
    function setDataSourceID(dataSourceID) {
      self.dataSourceID = dataSourceID;
    }
    function setAggrdata() {
      self.aggrID = getRoot(self).mids[0].gwsProcs[0].aggrID;
      self.dataSourceID = getRoot(self).mids[0].gwsProcs[0].dataSourceID;
    }
    return {
      setCreds,
      selectAggregator,
      setAggrdata,
      setAggrID,
      setAggrWhositID,
      setDataSourceID,
    };
  });*/

export const gwsProcsModel = types
  .model('gwsProcsModel', {
    dataSource: maybe(string),
    integration: maybe(string),
    integration_type: maybe(string),
    midNumber: maybe(string),
    // startDate: optional(string, Moment().startOf('day').toISOString()),
    // endDate: optional(string, Moment().startOf('day').toISOString()),
    credentials: optional(
      union(
        GoldstarApi,
        ApiCreds,
        ScrapeCreds,
        CybersourceScrape,
        AuthnetApi,
        AuthnetScrape,
        NMIScrape,
        USAepayApi,
        USAepayScrape
      ),
      () => {
        return GoldstarApi.create();
        // return {};
      }
    ),
  })
  .actions((self) => {
    function setAggrID(aggrID) {
      self.aggrID = aggrID;
    }
    function selectIntegration(integration) {
      self.integration = integration;
      // self.aggrID = aggr;
      // self.dataSourceID = undefined;
      // if (aggr === 148) {
      //   self.dataSourceID = 2;
      //   self.credentials = {};
      // }
    }
    function setDataSourceID(dataSourceID) {
      self.dataSourceID = dataSourceID;

      /*if (self.dataSourceID === 1) {
        self.credentials = ScrapeCreds.create();
      }
      if (self.dataSourceID === 2) {
        self.credentials = ApiCreds.create();
      }*/
      if (self.dataSourceID === 2 && self.aggrID === 148) {
        self.credentials = {};
      }
      if (self.dataSourceID === 1 && self.aggrID === 86) {
        self.credentials = CybersourceScrape.create();
      }
      if (self.dataSourceID === 1 && self.aggrID === 5) {
        self.credentials = AuthnetScrape.create();
      }
      if (self.dataSourceID === 2 && self.aggrID === 5) {
        self.credentials = AuthnetApi.create();
      }
      if (self.dataSourceID === 1 && self.aggrID === 14) {
        self.credentials = CybersourceScrape.create();
      }
      if (self.dataSourceID === 1 && self.aggrID === 86) {
        self.credentials = CybersourceScrape.create();
      }
      if (self.dataSourceID === 1 && self.aggrID === 64) {
        self.credentials = USAepayScrape.create();
      }
      if (self.dataSourceID === 2 && self.aggrID === 64) {
        self.credentials = USAepayApi.create();
      }
      if (self.dataSourceID === 1 && self.aggrID === 39) {
        self.credentials = NMIScrape.create();
      }
    }
    function setEndDt(e) {
      self.endDate = e.target.value;
    }
    function setGrabData() {
      self.grabData = !self.grabData;
    }
    function setMaxTimeToFetch(maxTimeToFetch) {
      self.maxTimeToFetch = maxTimeToFetch;
    }
    function setProcessData() {
      self.processData = !self.processData;
    }
    function setRetrieveHistoricalData() {
      self.retrieveHistoricalData = !self.retrieveHistoricalData;
    }
    function setStartDt(e) {
      self.startDate = e.target.value;
    }
    function setSubAcct(subAcct) {
      self.subAcct = subAcct;
    }
    return {
      selectIntegration,
      setAggrID,
      setDataSourceID,
      setEndDt,
      setGrabData,
      setMaxTimeToFetch,
      setProcessData,
      setRetrieveHistoricalData,
      setStartDt,
      setSubAcct,
    };
  });

export const midModel = types
  .model('mids', {
    id: maybe(number),
    procLimit: optional(array(limitsModel), []),
    gateways: optional(array(gwsProcsModel), []),
    processors: optional(array(gwsProcsModel), []),
    mid: optional(string, ''),
    midMoLimit: optional(string, ''),
    midDescriptor: optional(maybeNull(string), null),
  })
  .actions((self) => {
    function afterCreate() {
      self.initialState = getSnapshot(self);
    }
    function setMidNum(e) {
      // applySnapshot(self, self.initialState);
      self.mid = e.target.value;
    }
    function setMidMoLimit(e) {
      self.midMoLimit = e.target.value;
    }
    function setMidDescriptor(e) {
      self.midDescriptor = e.target.value;
    }
    function addGateway() {
      const newGw = gwsProcsModel.create({
        integration: undefined,
        integration_type: undefined,
        midNumber: undefined,
        // aggrID: undefined,
        dataSource: undefined,
        // aggrWhositID: undefined,
        credentials: undefined,
      });
      self.gateways.push(newGw);
    }
    function addProcessor() {
      const newProc = gwsProcsModel.create();
      self.processors.push(newProc);
    }
    function addCredentials() {
      // const newCreds = credentialsModel.create();
      // self.credentials.unshift(newCreds);
    }
    function remove(item) {
      destroy(item);
    }
    return {
      afterCreate,
      addCredentials,
      setMidNum,
      setMidDescriptor,
      addGateway,
      addProcessor,
      remove,
      setMidMoLimit
    };
  });

export const clientInfoModel = types
  .model('clientInfo', {
    active: optional(maybe(boolean), true),
    selectedClientID: maybe(number),
    id: maybe(number),
    selectedClientMid: maybe(number),
    clientName: optional(maybeNull(string), null),
    functCurrID: optional(string, 'USD'),
    tmzID: optional(number, 303),
    clientContactFname: optional(maybeNull(string), null),
    clientContactLname: optional(maybeNull(string), null),
    addr1: optional(maybeNull(string), null),
    addr2: optional(maybeNull(string), null),
    addr3: optional(maybeNull(string), null),
    city: optional(maybeNull(string), null),
    stProv: optional(maybeNull(string), null),
    zip: optional(maybeNull(string), null),
    country: optional(string, 'USA'),
    clientContactPhone: optional(maybeNull(string), null),
    clientContactEmail: optional(maybeNull(string), null),
    fein: optional(maybeNull(string), null),
    mccSic: optional(maybeNull(string), null),
    /*
    active: true
    addr1: "4801 N UNIVERSITY AVE, ST"
    addr2: null
    addr3: null
    city: "Provo"
    clientContactEmail: "Adam@lovebirdschicken.com"
    clientContactFname: "Adam"
    clientContactLname: "Terry"
    clientName: "Lovebirdschicken.com"
    fein: "831490964"
    id: 47
    mccSic: "5812"
    stProv: "UT"
    zip: "84604"
    */
    /*    clientActive: maybe(boolean),
    selectedClientID: maybe(number),
    id: maybe(number),
    selectedClientMid: maybe(number),
    clientName: optional(maybeNull(string), null),
    functCurrID: optional(string, 'USD'),
    tmzID: optional(number, 303),
    contactFirstName: optional(maybeNull(string), null),
    contactLastName: optional(maybeNull(string), null),
    addr1: optional(maybeNull(string), null),
    addr2: optional(maybeNull(string), null),
    addr3: optional(maybeNull(string), null),
    city: optional(maybeNull(string), null),
    stProv: optional(maybeNull(string), null),
    zip: optional(maybeNull(string), null),
    countryID: optional(string, 'USA'),
    clientContactPhone: optional(maybeNull(string), null),
    clientContactEmail: optional(maybeNull(string), null),
    fein: optional(maybeNull(string), null),
    mccSic: optional(maybeNull(string), null),*/
  })
  .actions((self) => {
    function setClientActive() {
      self.active = !self.active;
    }
    function setSelectedClientId(val) {
      self.selectedClientID = val;
    }
    function setSelectedClientMid(val) {
      self.selectedClientMid = val;
    }
    function setClientName(e) {
      self.clientName = e.target.value;
    }
    function setActive(e) {
      self.active = e.target.value;
    }
    function setFunctCurrID(value) {
      self.functCurrID = value;
    }
    function setTmzID(value) {
      self.tmzID = value;
    }
    function setContactFName(e) {
      self.clientContactFname = e.target.value;
    }
    function setContactLName(e) {
      self.clientContactLname = e.target.value;
    }
    function setAddr1(e) {
      self.addr1 = e.target.value;
    }
    function setAddr2(e) {
      self.addr2 = e.target.value;
    }
    function setAddr3(e) {
      self.addr3 = e.target.value;
    }
    function setCity(e) {
      self.city = e.target.value;
    }
    function setStProv(e) {
      self.stProv = e.target.value;
    }
    function setZip(e) {
      self.zip = e.target.value;
    }
    function setCtry(value) {
      self.country = value;
    }
    function setClientContactPh(e) {
      self.clientContactPhone = e.target.value;
    }
    function setClientContactEmail(e) {
      self.clientContactEmail = e.target.value;
    }
    function setFein(e) {
      self.fein = e.target.value;
    }
    function setMccSic(e) {
      self.mccSic = e.target.value;
    }

    return {
      setClientName,
      setActive,
      setFunctCurrID,
      setTmzID,
      setContactFName,
      setContactLName,
      setAddr1,
      setAddr2,
      setAddr3,
      setCity,
      setStProv,
      setZip,
      setCtry,
      setClientContactPh,
      setClientContactEmail,
      setFein,
      setMccSic,
      setSelectedClientId,
      setSelectedClientMid,
      setClientActive,
    };
  });

export const pricingPlanModel = types
  .model('pricingPlan', {
    pricingPlanID: optional(number, 100),
    startDate: optional(string, Moment().startOf('day').toISOString()),
  })
  .actions((self) => {
    function setPricingPlan(value) {
      self.pricingPlanID = value;
    }
    return {
      setPricingPlan,
    };
  });
