import React, { useEffect, useRef } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import {
  DropdownContainer,
  DropdownOption,
  DropdownWrapper,
  OptionsContainer,
  PlaceholderContainer,
  Placeholder,
} from './StyledMultiSelectDropdown';
import { CaretDownIcon, CaretUpIcon } from 'styles/styledComponents';
import { Observer, useLocalStore } from 'mobx-react-lite';
import { _map } from 'utils/utils';

const CustomMultiSelectDropdown = observer((props) => {
  const node = useRef();
  const store = useLocalStore(() => ({
    flipDropdown: false,
    showItems: false,
    selectedOptionText: '',
    get options() {
      return props.options;
    },
    get selectedOptions() {
      return _map(props.selectedOptions, (toFind) => {
        /** Find an option with a value that matches the option that you are passed in **/
        return store.options.find((option) => option.value === toFind);
      });
    },
    get deleteItem() {
      return props.deleteItem;
    },
    toggleShowItems() {
      store.showItems = !store.showItems;
    },
    setHideItems() {
      store.showItems = false;
    },
  }));

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    store.setHideItems();
  };

  useEffect(() => {
    const disposer = autorun(async () => {
      if (store.showItems) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      disposer();
    };
  });

  useEffect(() => {
    function getChartWidth() {
      const posDrop = node.current;
      if (posDrop && props.options) {
        let distFromBottom = posDrop.getBoundingClientRect().bottom;
        let optionsContainHeight = 35 * props.options.length;
        if (optionsContainHeight >= 260) {
          optionsContainHeight = 260;
        }
        distFromBottom = distFromBottom += optionsContainHeight;
        let bottomViewPort = window.innerHeight;
        store.flipDropdown = distFromBottom > bottomViewPort;
      }
    }
    getChartWidth();
    window.addEventListener('resize', getChartWidth);
    return function cleanup() {
      window.removeEventListener('resize', getChartWidth);
    };
  });

  return (
    <Observer>
      {() => (
        <DropdownWrapper
          ref={node}
          border={props.border}
          height={props.height}
          marginBottom={props.marginBottom}
          show={store.showItems}
          width={props.width}
          margin={props.margin}
          tabIndex={props.tabIndex}
        >
          <DropdownContainer
            flipDropdown={store.flipDropdown}
            margin={props.margin}
            id="drop"
            width={props.width}
            show={store.showItems}
            fontSize={props.fontSize}
          >
            <PlaceholderContainer id={props.id} onClick={store.toggleShowItems}>
              <Placeholder fontSize={props.fontSize} height={props.height} error={props.error || ''}>
                <div
                  style={{
                    maxWidth: '90%',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.placeholder}
                </div>
              </Placeholder>
              {store.showItems && (
                <CaretUpIcon
                  color="black"
                  style={{
                    width: 16,
                    height: 16,
                    position: 'absolute',
                    right: props.caretRight || 5,
                  }}
                />
              )}
              {!store.showItems && (
                <CaretDownIcon
                  color="black"
                  style={{
                    width: 16,
                    height: 16,
                    position: 'absolute',
                    right: props.caretRight || 5,
                  }}
                />
              )}
            </PlaceholderContainer>
            {store.showItems && (
              <OptionsContainer
                show={store.showItems}
                optContTop={props.optContTop}
                optContLeft={props.optContLeft}
                width={props.width}
                border={props.border}
                maxHeight={props.maxHeight}
                flipDropdown={store.flipDropdown}
                height={props.height}
              >
                {props.options.map((option, index) => {
                  return (
                    <DropdownOption
                      onClick={() => props.onChange(option)}
                      fontSize={props.fontSize}
                      show={store.showItems}
                      key={`${option.value + ':' + index}`}
                      padding={props.optionPadding}
                    >
                      <input id={option.text} checked={option.checked} className={'inputCheckbox'} type="checkbox" readOnly />
                      <div>{option.text}</div>
                    </DropdownOption>
                  );
                })}
              </OptionsContainer>
            )}
          </DropdownContainer>
        </DropdownWrapper>
      )}
    </Observer>
  );
});

export default CustomMultiSelectDropdown;
