import React, {useEffect, useState} from 'react';
import {Observer, observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {SsProcLimits, SsStatistics, SsTotals, SsTotalsGraph, TopEight, TrendsGraph, AveragesGraph} from '../components/Widgets';
import {useCheckAuthorizedRoute, useInjectStores} from 'utils/utils';
import {RouteLoadingSpinner, Tabs} from 'components/UI';
import ReactGA from 'react-ga';
import {useRouteMatch, Route} from "react-router-dom";

const Dashboard = observer(() => {
    const {loading, authorized, error} = useCheckAuthorizedRoute();
    const {userStore} = useInjectStores();
    const user = userStore.user;
    useEffect(() => {
        ReactGA.initialize('UA-195070931-1', {
            gaOptions: {
                clientId: user.currentClient.id,
                clientName: user.currentClient.clientName,
                userEmail: user.email,
            },
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
    return (
        <Observer>
            {() => (
                <>
                    {loading ? (
                        <RouteLoadingSpinner size={'400px'}/>
                    ) : authorized ? (
                        <div style={{height: '100%', width: '100%'}}>
                            <StyledWrapper>
                                <div className="tabs">
                                    <Tabs permissions={user.currentClient.permissions}/>
                                </div>
                                <Route exact path={`/dashboard`}>
                                    <StyledDashboard className="dashboardGrid">
                                        <SsTotalsGraph/>
                                        <SsTotals/>
                                        <SsProcLimits/>
                                    </StyledDashboard>
                                </Route>
                                <Route path={`/dashboard/top8`}>
                                    <TopEight/>
                                </Route>
                                <Route path={`/dashboard/trends`}>
                                    <StyledTrends>
                                        <TrendsGraph/>
                                    </StyledTrends>
                                </Route>
                                <Route path={`/dashboard/averages`}>
                                    <StyledAverages>
                                        <AveragesGraph/>
                                    </StyledAverages>
                                </Route>
                            </StyledWrapper>
                        </div>
                    ) : null}
                </>
            )}
        </Observer>
    );
});

const StyledWrapper = styled.div`
  height: 100%;
  margin: 0 4px 0 4px;

  .tabs {
    grid-area: tabs;
    width: 100%;
  }

  .widget {
    margin: 0 3px;
  }

  .tabWrapper {
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }
    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;
      &:hover {
        // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }
      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
`;

const StyledDashboard = styled.div`
  display: grid;
  height: calc(100% - 35px);
  margin: 4px 0 0 0;
  grid-template-areas:
    'SsTotalsGraph'
    'SsTotals'
    'SsProcLimits';
  grid-template-columns: 1fr;
  grid-template-rows: minmax(350px, 1fr) 60px minmax(350px, 1fr);
  grid-gap: 5px;

  @media (max-width: 1280px) {
    height: calc(100% - 35px);
    grid-template-areas:
      'SsTotalsGraph'
      'SsTotals'
      'SsProcLimits';
    grid-template-rows: minmax(350px, 1fr) 60px minmax(auto, 1fr);;
    //grid-template-rows: 350px 60px auto;
    .dashboardGrid {
      padding-bottom: 80px;
    }
  }
  @media (max-width: 830px) {
    grid-template-rows: 350px 125px minmax(350px, 1fr);
    .dashboardGrid {
      padding-bottom: 80px;
    }
  }
  @media (max-width: 576px) {
    grid-template-rows: 250px 190px minmax(350px, 1fr);
    .dashboardGrid {
      padding-bottom: 80px;
    }
  }
`;

const StyledTrends = styled.div`
  height: 100%;
  display: grid;
  margin: 4px 0 0 0;
  grid-template-areas:
    'salesGraph refundGraph'
    'declinesGraph chargebacksGraph';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100% 100%;
  grid-gap: 5px;
  padding-bottom: 20px;
  .salesGraph {
    height: 100%;
    grid-area: salesGraph;
  }
  .refundGraph {
    height: 100%;
    grid-area: refundGraph;
  }
  .declinesGraph {
    height: 100%;
    grid-area: declinesGraph;
  }
  .chargebacksGraph {
    height: 100%;
    grid-area: chargebacksGraph;
  }
`;

const StyledAverages = styled.div`
  height: 100%;
  display: grid;
  margin: 4px 0 0 0;
  grid-template-areas:
    'salesGraph refundGraph'
    'declinesGraph chargebacksGraph';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100% 100%;
  grid-gap: 5px;
  padding-bottom: 20px;
  .salesGraph {
    height: 100%;
    grid-area: salesGraph;
  }
  .refundGraph {
    height: 100%;
    grid-area: refundGraph;
  }
  .declinesGraph {
    height: 100%;
    grid-area: declinesGraph;
  }
  .chargebacksGraph {
    height: 100%;
    grid-area: chargebacksGraph;
  }
`;

export default Dashboard;
