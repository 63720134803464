import React, { useEffect } from 'react';
import {
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow
} from 'styles/styledComponents';
import { RouteLoadingSpinner } from 'components/UI';
import { _get } from 'utils/utils';
import { Observer, useLocalObservable } from 'mobx-react-lite';

const PlanSummary = (props) => {
  const store = useLocalObservable(() => ({
    widgetData: null,
    get planSummary() {
      return _get(this, 'widgetData[\'MERCHANT_STATEMENT_APPLICATION\'][\'CUSTOMER\'][0][\'PLAN_SUMMARY\'][0]');
    },
    get planSummaryTran() {
      return _get(this, "planSummary['PLAN_SUMMARY_TRAN']");
    },
    get planSummaryTotals() {
      return _get(this, "planSummary['PLAN_SUMMARY_TOTALS']");
    },
    setWidgetData(data) {
      this.widgetData = data;
    },
  }));

  useEffect(() => {
    store.setWidgetData(props.store.widgetData);
  }, [props.store.widgetData]);

  return (
    <Observer>
      {() => (
        <div>
          <TableHeader light={true} backgroundColor="#e9eae9">
            Plan Summary
          </TableHeader>
          {props.store.isLoading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Plan Code
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Num of Sales
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Sales
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Num of Credits
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Credits
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Net Sales
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Avg Ticket
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Disc P/I
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Disc %
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Disc Due
                    </HeaderCell>
                  </TableRow>
                  {store.planSummaryTran && store.planSummaryTran.length && (
                    <>
                      {store.planSummaryTran.map((detail, index) => (
                        <TableRow key={`${index} ${detail['PLAN_CODE']}`}>
                          <TableCell light={true}>{detail['PLAN_CODE']}</TableCell>
                          <TableCell light={true}>{detail['NUM_SALES']}</TableCell>
                          <TableCell light={true}>{detail['DOLLAR_SALES']}</TableCell>
                          <TableCell light={true}>{detail['NUM_CREDITS']}</TableCell>
                          <TableCell light={true}>{detail['DOLLAR_CREDITS']}</TableCell>
                          <TableCell light={true}>{detail['NET_SALES']}</TableCell>
                          <TableCell light={true}>{detail['AVG_TKT']}</TableCell>
                          <TableCell light={true}>{detail['DISC_PI']}</TableCell>
                          <TableCell light={true}>{detail['PERCENT']}</TableCell>
                          <TableCell light={true}>{detail['DISCOUNT_DUE']}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                  {store.planSummaryTotals && store.planSummaryTotals.length && (
                    <>
                      {store.planSummaryTotals.map((detail, index) => (
                        <TableRow key={`${index} ${detail['PLAN_CODE']}`}>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['PLAN_CODE']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['NUM_SALES']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DOLLAR_SALES']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['NUM_CREDITS']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DOLLAR_CREDITS']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['NET_SALES']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['AVG_TKT']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DISC_PI']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['PERCENT']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DISCOUNT_DUE']}
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* <TableRow>
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine>{formatNumber(totalCnt)}</TableCell>
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine>{toCurrency(totalAmt)}</TableCell>
                    </TableRow>*/}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}
    </Observer>
  );
};

export default PlanSummary;
