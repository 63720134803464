import React, {useEffect} from 'react';
/** Styled Components Specific to Client Data Mgmt **/
import {Observer, useLocalStore, observer} from 'mobx-react-lite';
import {useInjectStores, _get, _has, _set} from 'utils/utils';
import styled from 'styled-components/macro';
import {autorun} from 'mobx';
import {useHistory, useLocation} from 'react-router-dom';
import {TableHeader, TableWrapper, TableHeaderButton} from 'styles/styledComponents';
import {AgGridReact} from 'ag-grid-react';
import {Dropdown, LoadingSpinner} from 'components/UI';

function getSimpleCellRenderer() {
    function SimpleCellRenderer() {
    }

    SimpleCellRenderer.prototype.init = function (params) {
        var tempDiv = document.createElement('div');
        if (params.node.group) {
            tempDiv.innerHTML = '<span>' + params.value + ' (Mult. Trace Num)- ' + '</span>';
            // '<span style="border-bottom: 1px solid grey; border-left: 1px solid grey; padding: 2px;">' + params.value + '</span>';
        } else {
            tempDiv.innerHTML = '<span>' + params.value + '</span>';
            // '<span><img src="https://flags.fmcdn.net/data/flags/mini/ie.png" style="width: 20px; padding-right: 4px;"/>' +
            // params.value +
            // '</span>';
        }
        this.eGui = tempDiv.firstChild;
    };
    SimpleCellRenderer.prototype.getGui = function () {
        return this.eGui;
    };
    return SimpleCellRenderer;
}

function cellStyleNeg(params) {
    if (params.value && params.value < 0) {
        return {color: '#FF2B49'};
    }
}

const DailyDeposits = observer((props) => {
    const history = useHistory();
    const location = useLocation();
    const {dataManagementStore, userStore, utilsStore} = useInjectStores();
    const toDateFormatterValue = utilsStore.toDateFormatter;
    const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
    const initialDataDeposit = {
        values: [
            {
                /* funded_dt: '',
                 settle_dt: '',
                 federal_batch: 0,
                 trace_num: '',
                 deposit_typ: '',
                 ccd_typ: '',
                 deposit_amt: 0,
                 settled: 0,
                 rejected: 0,
                 fees: 0,
                 disqualified: 0,
                 net_settled: 1000,
                 suspended: 0,
                 susp_release: 0,
                 reserves: 0,
                 resrv_release: 0,
                 advance_held: 0,
                 fed_withhold: 0,
                 st_withhold: 0,
                 ach_total: 0,*/

                ach_tot_amt: 0,
                adv_held_amt: 0,
                batch_num: null,
                ccd_type: "",
                chgbk_case_num: null,
                dep_amt: 0,
                dep_dt: "",
                dep_type: "",
                disqual_amt: 0,
                fed_wh_amt: 0,
                federal_batch: 0,
                fees_amt: 0,
                mid: "",
                net_sttld_amt: 0,
                rej_amt: 0,
                resrv_amt: 0,
                resrv_rel_amt: 0,
                st_wh_amt: 0,
                sttld_amt: 0,
                susp_rel_amt: 0,
                suspd_amt: 0,
                trace_num: "",
                txn_date: "",
            },
        ]
    };
    const store = useLocalStore(() => ({
        isLoadingDeposit: false,
        widgetDataDeposit: initialDataDeposit,

        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            store.exportType = type;
        },
        apiDep: null,
        get treeData() {
            const dictionary = {};
            let id_index = 1;
            let testData = store.widgetDataDeposit;
            for (let i = 0; i < testData.length; i++) {
                let newFSBCheck = `${testData[i].funded_dt}${testData[i].settle_dt}-${testData[i].federal_batch}`;
                let currentDeposit = testData[i];

                if (!_has(dictionary, newFSBCheck)) {
                    _set(dictionary, newFSBCheck, {
                        topLevel: {
                            ...testData[i],
                            topLevel: true,
                            id: id_index,
                            keyPath: [newFSBCheck],
                        },
                        traceNums: [],
                    });
                    id_index += 1;
                } else {
                    if (dictionary[newFSBCheck].traceNums.length === 0) {
                        dictionary[newFSBCheck].traceNums.push({
                            ...dictionary[newFSBCheck].topLevel,
                            keyPath: [newFSBCheck, `Trace#${1}`],
                            id: id_index,
                        });
                        id_index += 1;
                    }
                    ///////// add deposit amount to toplvl
                    dictionary[newFSBCheck].topLevel = {
                        ...dictionary[newFSBCheck].topLevel,
                        /*  trace_num: 'Multiple',
                          deposit_amt: (dictionary[newFSBCheck].topLevel.deposit_amt += currentDeposit.deposit_amt),
                          advance_held: (dictionary[newFSBCheck].topLevel.advance_held += currentDeposit.advance_held),
                          disqualified:(dictionary[newFSBCheck].topLevel.disqualified += currentDeposit.disqualified),
                          fed_withhold: (dictionary[newFSBCheck].topLevel.fed_withhold += currentDeposit.fed_withhold),
                          federal_batch: (dictionary[newFSBCheck].topLevel.federal_batch += currentDeposit.federal_batch),
                          fees: (dictionary[newFSBCheck].topLevel.fees += currentDeposit.fees),
                          net_settled: (dictionary[newFSBCheck].topLevel.net_settled += currentDeposit.net_settled),
                          rejected:  (dictionary[newFSBCheck].topLevel.rejected += currentDeposit.rejected),
                          reserves:  (dictionary[newFSBCheck].topLevel.reserves += currentDeposit.reserves),
                          resrv_release:  (dictionary[newFSBCheck].topLevel.resrv_release += currentDeposit.resrv_release),
                          settled:  (dictionary[newFSBCheck].topLevel.settled += currentDeposit.settled),
                          st_withhold:  (dictionary[newFSBCheck].topLevel.st_withhold += currentDeposit.st_withhold),
                          susp_release:  (dictionary[newFSBCheck].topLevel.susp_release += currentDeposit.susp_release),
                          suspended:  (dictionary[newFSBCheck].topLevel.suspended += currentDeposit.suspended),
              */
                        trace_num: 'Multiple',
                        dep_amt: (dictionary[newFSBCheck].topLevel.dep_amt += currentDeposit.dep_amt),
                        adv_held_amt: (dictionary[newFSBCheck].topLevel.adv_held_amt += currentDeposit.adv_held_amt),
                        disqual_amt: (dictionary[newFSBCheck].topLevel.disqual_amt += currentDeposit.disqual_amt),
                        fed_wh_amt: (dictionary[newFSBCheck].topLevel.fed_wh_amt += currentDeposit.fed_wh_amt),
                        federal_batch: (dictionary[newFSBCheck].topLevel.federal_batch += currentDeposit.federal_batch),
                        fees_amt: (dictionary[newFSBCheck].topLevel.fees_amt += currentDeposit.fees_amt),
                        net_sttld_amt: (dictionary[newFSBCheck].topLevel.net_sttld_amt += currentDeposit.net_sttld_amt),
                        rej_amt: (dictionary[newFSBCheck].topLevel.rej_amt += currentDeposit.rej_amt),
                        resrv_amt: (dictionary[newFSBCheck].topLevel.resrv_amt += currentDeposit.resrv_amt),
                        resrv_rel_amt: (dictionary[newFSBCheck].topLevel.resrv_rel_amt += currentDeposit.resrv_rel_amt),
                        sttld_amt: (dictionary[newFSBCheck].topLevel.sttld_amt += currentDeposit.sttld_amt),
                        st_wh_amt: (dictionary[newFSBCheck].topLevel.st_wh_amt += currentDeposit.st_wh_amt),
                        susp_rel_amt: (dictionary[newFSBCheck].topLevel.susp_rel_amt += currentDeposit.susp_rel_amt),
                        suspd_amt: (dictionary[newFSBCheck].topLevel.suspd_amt += currentDeposit.suspd_amt),
                        ach_tot_amt: (dictionary[newFSBCheck].topLevel.ach_tot_amt += currentDeposit.ach_tot_amt),


                        /*        ach_tot_amt: -10410.37,
                                  adv_held_amt:0,
                                  batch_num:null,
                                  ccd_type:"Fees",
                                  chgbk_case_num:null,
                                  dep_amt:-10410.37,
                                  dep_dt:"2021-08-02T00:00:00Z",
                                  dep_type:"Withdrawal",
                                  disqual_amt:0,
                                  fed_wh_amt:0,
                                  federal_batch:2120001,
                                  fees_amt : -10410.37,
                                  mid : "937500000003285",
                                  net_sttld_amt : -10410.37,
                                  rej_amt : 0,
                                  resrv_amt : 0,
                                  resrv_rel_amt : 0,
                                  st_wh_amt : 0,
                                  sttld_amt : 0,
                                  susp_rel_amt : 0,
                                  suspd_amt : 0,
                                  trace_num : "051403770000102",
                                  txn_date : "2021-07-30T00:00:00Z",*/
                    };
                    ///////////
                    dictionary[newFSBCheck].traceNums.push({
                        ...testData[i],
                        id: id_index,
                        // keyPath: [newFSBCheck, `Trace#${dictionary[newFSBCheck].traceNums.length + 1}-${currentDeposit.federal_batch}`],
                        keyPath: [newFSBCheck, `Trace#${dictionary[newFSBCheck].traceNums.length + 1}`],
                    });
                    id_index += 1;
                }
            }
            let toReturn = [];
            for (const key in dictionary) {
                toReturn = [...toReturn, dictionary[key].topLevel, ...dictionary[key].traceNums];
            }
            return toReturn;
        },

        get gridOptionsDep() {
            return {
                columnDefs: [
                    {
                        headerName: 'Funded Date',
                        field: 'dep_dt',
                        valueFormatter: toDateFormatterValue,
                        cellRenderer: 'agGroupCellRenderer',
                        width: 160,
                        cellRendererParams: {
                            suppressCount: true,
                        },
                    },
                    {
                        headerName: 'Txn Date',
                        valueFormatter: toDateFormatterValue,
                        field: 'txn_date',
                        rowGroup: true,
                        width: 140,
                        cellRendererParams: {
                            suppressCount: true,
                            // checkbox: true,
                            // innerRenderer: 'simpleCellRenderer',
                            // suppressDoubleClickExpand: true,
                            // suppressEnterExpand: true,
                        },
                    },
                    {
                      headerName: 'Batch Num',
                      field: 'batch_num',
                      width: 150,
                      cellRendererParams: {
                        suppressCount: true,
                      },
                    },
                      {
                      headerName: 'Chgbk Case Num',
                      field: 'chgbk_case_num',
                      width: 150,
                      cellRendererParams: {
                        suppressCount: true,
                      },
                    },
                    {
                      headerName: 'Federal Batch',
                      field: 'federal_batch',
                      width: 150,
                      cellRendererParams: {
                        suppressCount: true,
                      },
                    },
                    { headerName: 'Trace Num', field: 'trace_num', cellRenderer: 'agGroupCellRenderer', width: 200 },
                    { headerName: 'Deposit Type', field: 'dep_type', width: 140 },
                    { headerName: 'CCD Type', field: 'ccd_type', width: 140 },
                    {
                      headerName: 'Deposit Amount',
                      field: 'dep_amt',
                      valueFormatter: toCurrencyFormatter,
                      type: 'rightAligned',
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Settled Amount',
                      field: 'sttld_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Rejected Amount',
                      field: 'rej_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    { headerName: 'Fees', field: 'fees_amt', type: 'rightAligned', valueFormatter: toCurrencyFormatter, cellStyle: cellStyleNeg, width: 140 },
                    {
                      headerName: 'Disqualified Amount',
                      field: 'disqual_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Net Settled Amount',
                      field: 'net_sttld_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Suspended Amount',
                      field: 'suspd_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Susp Release Amount',
                      field: 'susp_rel_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Reserves Amount',
                      field: 'resrv_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 160,
                    },
                    {
                      headerName: 'Resrv Release Amount',
                      field: 'resrv_rel_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Advance Held Amount',
                      field: 'adv_held_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'Fed Withhold Amount',
                      field: 'fed_wh_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'St Withhold Amount',
                      field: 'st_wh_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                    {
                      headerName: 'ACH Total Amount',
                      field: 'ach_tot_amt',
                      type: 'rightAligned',
                      valueFormatter: toCurrencyFormatter,
                      cellStyle: cellStyleNeg,
                      width: 180,
                    },
                ],
                components: {simpleCellRenderer: getSimpleCellRenderer()},
                animateRows: true,
                enableBrowserTooltips: true,
                tooltipShowDelay: 0,
                reactNext: true,
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                groupSuppressAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                rowClassRules: {
                    level0: function (params) {
                        return params.data.trace_num === 'multiple' || params.data.isLevel2 === false;
                    },
                },
                groupRemoveSingleChildren: true,
                cellRendererParams: {
                    suppressCount: true, // turn off the row count
                },
                getDataPath: function (data) {
                    return data.keyPath;
                },
                getRowNodeId: function (data) {
                    return data.id;
                },
                masterDetail: true,
                defaultColDef: {
                    sortable: false,
                    resizable: true,
                },
                onGridReady: (params) => {
                    store.apiDep = params.api;
                    store.gridColumnApiDep = params.columnApi;
                },
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);"> Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },
        async getData() {
            store.isLoadingDeposit = true;
            try {
                let res2 = await dataManagementStore.fetchDailyDepositStmtData();
                store.widgetDataDeposit = res2.data.values;
            } catch (error) {
                console.error('err:', error);
                store.widgetDataDeposit = initialDataDeposit;
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    userStore.logout(history, location);
                }
            }
            store.isLoadingDeposit = false;
            if (store.apiDep && !store.isLoadingDeposit) {
                store.apiDep.hideOverlay();
            }
            if (store.apiDep && !store.isLoadingDeposit && store.widgetDataDeposit.length === 0) {
                store.apiDep.showNoRowsOverlay();
            }
        },
    }));

    useEffect(
        () =>
            autorun(async () => {
                if (dataManagementStore.newQuery) {
                    await store.getData();
                }
            }),
        []
    );

    return (
        <Observer>
            {() => (
                <TableWrapper width="100%" height="100%">
                    <TableHeader
                        width={'auto'}
                        justify={'space-between'}
                        backgroundColor={'#041c2f'}
                        warning
                        fontSize="12px"
                        padding={'10px 20px'}
                        textAlign="start"
                    >
                        {store.isLoadingDeposit && <LoadingSpinner size={'200px'}/>}
                        {!store.isLoadingDeposit && (
                            <StyledNote>
                                <span className="note">*</span>
                                <span className="noteBody">
                  The data is filtered by the Funded Date (we do not control the date your bank posts the deposit/withdrawal to
                  your account).
                </span>
                            </StyledNote>
                        )}
                        {!store.isLoadingDeposit && store.widgetDataDeposit && store.widgetDataDeposit.length > 0 && (
                            <div
                                css={`
                                  display: flex;
                                `}
                            >
                                <Dropdown
                                    className="exportDropdown"
                                    caretRight={10}
                                    options={store.exportOptions}
                                    onChange={store.setExportType}
                                    value={store.exportType}
                                    width="80px"
                                    height="25px"
                                    margin="0"
                                    border
                                />
                                <TableHeaderButton
                                    height="27px"
                                    onClick={() => {
                                        if (store.exportType === 'csv') {
                                            store.apiDep.exportDataAsCsv();
                                        } else {
                                            store.apiDep.exportDataAsExcel();
                                        }
                                    }}
                                >
                                    Export
                                </TableHeaderButton>
                            </div>
                        )}
                    </TableHeader>
                    <Wrapper>
                        {!store.isLoadingDeposit && store.widgetDataDeposit && store.widgetDataDeposit.length > 0 && (
                            <div
                                id="myGrid"
                                className={'ag-theme-alpine-dark'}
                                style={{
                                    height: '100%',
                                    minHeight: '275px',
                                    width: '100%',
                                    position: 'relative',
                                }}
                            >
                                <AgGridReact
                                    gridOptions={store.gridOptionsDep}
                                    rowHeight={33}
                                    rowData={store.treeData}
                                    treeData={true}
                                    getDataPath={store.getDataPath}
                                    getRowNodeId={store.getRowNodeId}
                                    onGridReady={store.onGridReady}
                                />
                            </div>
                        )}
                        {!store.isLoadingDeposit && store.widgetDataDeposit && store.widgetDataDeposit.length === 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    minHeight: '400px',
                                    color: '#FFF',
                                }}
                            >
                                No data for this time period
                            </div>
                        )}
                    </Wrapper>
                </TableWrapper>
            )}
        </Observer>
    );
});

export {DailyDeposits};

const Wrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;

    .ag-cell {
      line-height: 30px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .rowTotals {
      border-top-color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
    }

    .ag-row-level-0 {
      background-color: #001329 !important;
    }

    .ag-row-level-1 {
      background-color: #032845 !important;
    }
  }
`;
const StyledNote = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection || 'row'};
  height: 100%;
  justify-content: ${({flexDirection}) => (flexDirection === 'column' ? 'flex-end' : 'flex-start')};
  align-items: center;
  font-size: 10px;
  padding: ${({padding}) => padding};
  font-style: italic;

  .note {
    font-size: 12px;
    color: red;
  }

  .noteBody {
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;
