import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {Collections, GrossValues, NetValues, Opp4} from './index';
import { _cloneDeep, _isNumber, _sortBy, _find, _get, _toNumber, useGetWidgetData, useInjectStores} from 'utils/utils';
import {applySnapshot} from 'mobx-state-tree';
import {widgetService} from 'services';
import {useHistory, useLocation} from "react-router-dom";

const SsOpportunity = observer((props) => {
    const {dataManagementStore, filterStore, utilsStore} = useInjectStores();
    const history = useHistory();
    const location = useLocation();

    const store = useLocalObservable(() => ({
        formatNumber: utilsStore.formatNumber,
        toCurrency: utilsStore.toCurrency,
        isLoadingOpp: false,
        isLoadingOppB: false,

        history,
        location,

        oppA: [],
        oppB: [],
        get oppABD() {
            return [];
        },
        get opp1() {
            if (this.oppA?.length > 0) {
                const filteredOpp1 = [];
                let test = _cloneDeep(this.oppA);
                test.map(opp => {
                    if (opp.opp_category !== 'Rejected Prepaid Fixed by EIGHT' &&  opp.opp_category !== 'Rejected Refunds Fixed by EIGHT') {
                        filteredOpp1.push(opp);
                    }
                })
                return filteredOpp1;
            }
        },
        get opp4() {
            if (this.oppA?.length > 0) {
                let test = {
                    collected_amt:0,
                    collected_count:0,
                    et_opp_succ_ratio:null,
                    likely_collectible:0,
                    opp_category:"Rejected Sales Fixed by EIGHT",
                    opp_count:0,
                    orig_amt:0,
                    priority:98}
                const oppB = _get(_find(this.oppA, {opp_category: 'Rejected Prepaid Fixed by EIGHT'}), test);

                const oppC = _get(_find(this.oppA, {opp_category: 'Rejected Refunds Fixed by EIGHT'}),test);
                return [oppB, oppC];
            }
        },
        get oppABDTotals() {
            let totals = {
                collected_amt: 0,
                collected_count: 0,
                likely_collectible: 0,
                opp_count: 0,
                opp_orig_amt: 0,
            }
            if (!this.isLoadingOpp && this.opp1?.length > 0) {
                // let agg = [...this.oppA, ...this.oppB, ...this.oppD];
                this.opp1.map((value) => {
                    for (const property in totals) {
                        if (_isNumber(totals[property])) {
                            totals[property] += value[property];
                        }
                    }
                });
            }
            return totals;
        },
        setOppA(widgetData) {
            this.error = null;
            const {status, data, error} = widgetData;
            if (status === 'loading') {
                this.isLoadingOpp = true;
                this.isLoadingOppB = true;
            }
            if (error) {
                this.error = error;
                this.isLoadingOpp = false;
            }
            if (data) {
                this.oppA = data.top;
                this.oppB = data.bottom;
                this.isLoadingOpp = false;
                this.isLoadingOppB = false;
            }
        },
        // setOppB(widgetData) {
        //     this.error = null;
        //     const {status, data, error} = widgetData;
        //     if (status === 'loading') this.isLoadingOppB = true;
        //     if (error) {
        //         this.error = error;
        //         this.isLoadingOppB = false;
        //     }
        //     if (data) {
        //         this.oppB = data.values
        //         this.isLoadingOppB = false;
        //     }
        // },
    }));

    const oppA = useGetWidgetData(
        {name: 'oppA', query: widgetService.oppA},
        'data',
        dataManagementStore?.newQuery
    );

    // const oppB = useGetWidgetData(
    //     {name: 'oppB', query: widgetService.oppB},
    //     'data',
    //     dataManagementStore?.newQuery
    // );

    useEffect(() => {
        store.setOppA(oppA);
    }, [oppA]);

    // useEffect(() => {
    //     store.setOppB(oppB);
    // }, [oppB]);


    return (
        <Observer>
            {() => (
                <Wrapper>
                    <div className="grossValues">
                        <GrossValues store={store}/>
                    </div>
                    <div className="collections">
                        <Collections store={store}/>
                    </div>
                    {/*<div className="netValues">*/}
                    {/*    <NetValues store={store}/>*/}
                    {/*</div>*/}
                    <div className="opp4">
                        <Opp4 store={store}/>
                    </div>
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  //margin: 0 4px 5px 5px;

  .grossValues {
    grid-area: grossValues;
    height: 100%;
    width: 100%;
  }

  .collections {
    grid-area: collections;
    height: 100%;
    width: 100%;
  }

  //.netValues {
  //  grid-area: netValues;
  //  height: 100%;
  //  width: 100%;
  //}

  .opp4 {
    grid-area: opp4;
    height: 100%;
    width: 100%;
  }

  grid-template-areas:
    'grossValues collections'
    'opp4 opp4';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  @media (max-width: 1332px) {
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'grossValues'
      'collections'
      'opp4';
    grid-template-columns: 1fr;
  }
`;

export default SsOpportunity;
