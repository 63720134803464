import React, { useEffect, useState } from 'react';
import MerchSettings from './Merchants/MerchSettings';
import MerchAdd from './Merchants/MerchAdd';
import MerchEdit from './Merchants/MerchEdit';
import styled from 'styled-components/macro';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { Route, useRouteMatch } from 'react-router-dom';
import { adminService, commonService, widgetService } from 'services';
import { RouteLoadingSpinner } from 'components/UI';
import { useCheckAuthorizedRoute, _get, useInjectStores } from 'utils/utils';
import { useQuery } from 'react-query';

const MerchantPages = observer(() => {
  const { path } = useRouteMatch();
  const {userStore} = useInjectStores();
  const userRole = _get(userStore, 'user.userRole')
  let { loading, authorized } = useCheckAuthorizedRoute();
  if(userRole === 'User') authorized = false;
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              <div className="wrapper">
                <Route path={`/admin/merch/settings`}>
                  <MerchSettings />
                </Route>
                <Route path={`/admin/merch/add`}>
                  <MerchAdd />
                </Route>
				        <Route path={`/admin/merch/edit`}>
                  <MerchEdit />
                </Route>
              </div>
            </Wrapper>
          ) : (
            <div>You do not have access to this page</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  width: calc(100% - 8px);
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 2px;
  height: 100%;
  .wrapper {
    width: 100%;
    height: 100%;
  }
`;

export default MerchantPages;
