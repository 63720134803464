import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const FourZeroFour = () => {
  return (
    <Wrapper>
      <div className="fourOFour">
        <div>404 Page Not Found</div>
        <Link to={`/login`}>
          <SubItem>Return to login </SubItem>
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background: ${({ theme }) => theme.background};
  .fourOFour {
    font-size: 30px;
    color: ${({ theme }) => theme.colors.text};
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const SubItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 40px;
  color: ${({ theme }) => theme.colors.text};
  align-items: center;
  align-content: center;
  transition: all 1s;
  font-size: 20px;
  text-align: center;
  div > {
    width: 100%;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  div {
    margin-left: 10px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);

    &:active {
      color: #fff;
    }
    &:visited {
      color: #fff;
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

export default FourZeroFour;
