import styled from 'styled-components/macro';

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${(props) => {
    if (props.margin) return `${props.margin}`;
    else if (props.marginLeft || props.marginRight || props.marginTop || props.marginBottom) {
      let marginTop = props.marginTop ? props.marginTop : '0';
      let marginRight = props.marginRight ? props.marginRight : '0';
      let marginBottom = props.marginBottom ? props.marginBottom : '0';
      let marginLeft = props.marginLeft ? props.marginLeft : '0';
      return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
    } else return '5px';
  }};
  box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  background-color: #fff;
  width: 100%;
  height: auto;
`;

const ReportBody = styled.div`
  padding: 5px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ flexGrow }) => flexGrow || 1};
  padding: ${({ padding }) => padding || ''};
  position: relative;
  margin: ${(props) => {
    if (props.margin && !(props.marginLeft || props.marginRight || props.marginTop || props.marginBottom))
      return `${props.margin}`;
    else if (props.marginLeft || props.marginRight || props.marginTop || props.marginBottom) {
      let marginTop = props.marginTop ? props.marginTop : '0';
      let marginRight = props.marginRight ? props.marginRight : '0';
      let marginBottom = props.marginBottom ? props.marginBottom : '0';
      let marginLeft = props.marginLeft ? props.marginLeft : '0';
      return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
    } else return '0';
  }};
  background: ${({ theme, noBackground, background }) => {
    if (background) {
      return background;
    }
    if (!noBackground) return theme.rgl.gridItem.colors.background;
    // if (!noBackground) return theme.tableStyles.backgroundColor;
    else return 'none';
  }};
  width: ${({ width }) => width || 'auto'};
  height: ${(props) => (props.height ? props.height : 'auto')};
  transition: all 500ms;
  &:hover {
    background: ${({ theme, hover }) => (hover ? theme.tableStyles.hoverBackgroundColor : null)};
  }
`;

const TableContainer = styled.div`
  margin: ${({ margin }) => margin || ''};
  padding: ${({ padding }) => padding || ''};
  color: ${({ color, theme }) => color || `var(--font-color-${theme.theme})`};
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || ''};
`;

const TableHeader = styled.div`
  position: relative;
  height: ${({ height }) => height || 'auto'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  width: ${({ width }) => width || '100%'};
  background: transparent;
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  color: ${({ color, theme }) => color || `var(--font-color-${theme.theme})`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || '6px 0 6px 0px'};
  align-items: center;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize};
  border: none;
`;

const RowContainer = styled.div`
  padding: ${(props) => props.padding || 0};
  display: flex;
`;

const StatusContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: hidden;
`;

const Status = styled.div`
  color: ${({ color, theme }) => color || `var(--font-color-${theme.theme})`};
  display: flex;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.primaryText};
  margin: 5px;
`;

const TxnDetail = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
`;

const TxnDetailTitle = styled.div`
  borderbottom: 1px solid #3fa9f5;
  color: #3fa9f5;
  margin: 10px 0 2px 0;
`;

/** Table CSS **/
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const TableBody = styled.tbody`
  width: 100%;
  color: black;
`;
/*const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  display: block;
  height: 275px;
`;
const TableBody = styled.tbody`
  display: block;
  height: 275px;
  width: 100%;
  color: black;
  overflow: auto;
`;*/
const TableRow = styled.tr`
  display: ${({ hide }) => (hide ? 'none' : 'table-row')};
  position: ${({ position }) => position || 'relative'};
  //border-bottom: ${({ borderBottom, theme }) => (borderBottom ? `1px solid ${theme.colors.primaryText}` : '')};
  .category {
    color: ${({ theme, percent }) => {
      if (percent < 2) {
        return theme.rgl.gridItem.colors.primaryText;
      }
      if (percent >= 2 && percent < 4) {
        return theme.baseColors.newYellow;
      }
      if (percent >= 4) {
        return theme.baseColors.colorDanger;
      }
    }};
  }
`;
const HeaderCell = styled.th`
  color: ${({ theme, light }) =>
    light === true ? theme.rgl.gridItem.colors.primaryTextLight : theme.rgl.gridItem.colors.primaryText};
  border: none;
  border-bottom: ${(props) => props.borderBottom};
  font-weight: 400;
  font-size: 14px;
  padding: ${({ padding }) => padding || '5px 8px'};
  text-align: ${(props) => props.textAlign || 'right'};
  border-bottom: ${(props) => props.theme.colors.totalsDivider};
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
`;

const TableCell = styled.td`
  text-align: ${(props) => props.textAlign || 'right'};
  position: ${({ position }) => position || 'relative'};
  top: ${({ top }) => top};
  //font-weight: 400;
  font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
  font-size: 13px;
  color: ${(props) => {
    if (props.negative) {
      return props.theme.colors.negative;
    }
    if (props.light) {
      return props.theme.rgl.gridItem.colors.primaryTextLight;
    }
    return props.theme.rgl.gridItem.colors.primaryText;
  }};
  border-top: ${(props) => (props.showTotalsLine ? props.theme.colors.totalsDivider : props.theme.colors.divider)};

  padding: ${({ padding }) => padding || '5px 8px'};
  max-width: ${({ maxWidth }) => maxWidth || ''};
  min-width: ${({ minWidth }) => minWidth || ''};
  span {
    color: red;
  }
`;

const TableCellSection = styled.span`
  .segmentDesc {
    /*width: calc(100% - 10px);*/
    display: none;
    position: absolute;
    text-align: left;
    z-index: 100;
    background: #fff;
    padding: 5px;
    box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
    //top: 5px;
    margin-top: 10px;
    left: 0;
    white-space: nowrap;
  }
  &:hover {
    color: #3fa9f5;
    cursor: pointer;
    .segmentDesc {
      display: block;
    }
  }
`;

/** Specialty Components **/
const Arrow = styled.div`
  //width: 0;
  //height: 0;
  //border-left: 7px solid transparent;
  //border-right: 7px solid transparent;
  //border-bottom: 7px solid #fff;
  //position: absolute;
  //top: -7px;
`;

const MsgBox = styled.div`
  align-items: center;
  color: ${({ theme }) => (theme.rgl.gridItem.colors.primaryText ? theme.rgl.gridItem.colors.primaryText : 'black')};
  display: flex;
  font-size: 12px;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

const Button = styled.div`
  align-items: center;
  border: 1px solid grey;
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  padding: 0 5px;
  width: auto;
  &:hover {
    cursor: pointer;
  }
`;

export {
  Button,
  ReportWrapper,
  ReportBody,
  TableWrapper,
  TableContainer,
  Table,
  TableBody,
  TableHeader,
  HeaderCell,
  TableCell,
  TableCellSection,
  Arrow,
  TableRow,
  MsgBox,
  RowContainer,
  StatusContainer,
  Status,
  TxnDetail,
  TxnDetailTitle,
};
