import React, { Fragment, useEffect } from 'react';
import {
  ClearIcon,
  Divider,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { Button, LoadingSpinner } from 'components/UI';
import styled from 'styled-components/macro';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { _get, _includes, _round, _toPairs, useInjectStores } from 'utils/utils';

const Collections = observer((props) => {
  const toCurrency = props.store.toCurrency;
  const formatNumber = props.store.formatNumber;
  const { filterStore, utilsStore } = useInjectStores();
  const oppFilter = filterStore.opportunityFilter;
  const getPercentage = utilsStore.getTotalsPercentage;
  return (
    <Observer>
      {() => (
        <Wrapper>
          <TableWrapper height="100%">
            <TableHeader justify={'center'} textAlign={'center'} padding={'6px 6px 6px 10px'}>COLLECTIONS</TableHeader>
            <Divider />
            {props.store.isLoadingOpp && <LoadingSpinner size={'200px'} />}
            {
              <Table>
                <TableBody>
                  {!props.store.isLoadingOpp && (
                    <Fragment>
                      <TableRow>
                        <HeaderCell textAlign={'left'} style={{paddingLeft: '2rem'}}>Count</HeaderCell>
                        <HeaderCell textAlign={'right'} style={{paddingRight: '2rem'}}>Amount</HeaderCell>
                      </TableRow>
                      {props.store.oppA && props.store.oppA.length > 0 && (
                        <Fragment>
                          {props.store.oppA.map(
                            (
                              value,
                              index
                            ) => (
                              <TableRow key={`${value.opp_category}${index}`}>
                                <TableCell style={{paddingLeft: '2rem'}} textAlign={'center'} width={'180px'} >{formatNumber(_get(value,'collected_count', 0) || 0)}</TableCell>
                                <TableCell textAlign={'right'} width={'180px'} style={{paddingRight: '2rem'}}>{toCurrency(_get(value,'collected_amt', 0) || 0)}</TableCell>
                              </TableRow>
                            )
                          )}

                          <TableRow>
                            <TableCell style={{paddingLeft: '2rem'}} textAlign={'center'}  showTotalsLine>{_get(props.store.oppABDTotals, 'collected_count')||0}</TableCell>
                            <TableCell style={{paddingRight: '2rem'}} textAlign={'right'} width={'100%'} showTotalsLine>
                              {toCurrency(_get(props.store.oppABDTotals, 'collected_amt')) || 0}
                            </TableCell>

                          </TableRow>
                          <TableRow>
                            <TableCell style={{borderTop: 'none', paddingLeft: '2rem'}}></TableCell>
                            <TableCell style={{borderTop: 'none', paddingRight: '2rem'}} textAlign={'right'} width={'100%'}>
                              {getPercentage(_get(props.store.oppABDTotals, 'collected_amt'), _get(props.store.oppABDTotals, 'likely_collectible'))}%
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
            }
          </TableWrapper>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 250px;
  height: 100%;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

export default Collections;
