import React from 'react';
import {Observer} from 'mobx-react-lite';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import {useInjectStores} from 'utils/utils';

const MiscData = ({
                      widgetData: {
                          amex_avs_addr_status,
                          amex_avs_cust_nm_status,
                          amex_avs_zip_status,
                          chgbk_case_num,
                          chgbk_revsl_case_num,
                          decline_txn_id,
                          net_chgbk_amt,
                          refund_amt,
                          refund_txn_id,
                          sales_cnt_by_cc,
                          test_txn,
                          tsys_txn_id,
                          txn_avs_stat_v_mc_disc,
                          txn_card_pres_status,
                          txn_cvc_status,
                          txn_entry_type,
                          txn_rewards_card,
                          test_txn_tf,
                          net_chgbk_amount,
                          txn_avs_status_visa_mc,
                          txn_avs_status_discover
                      },
                  }) => {
    const {utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency;
    return (
        <Observer>
            {() => (
                <div style={{display: 'flex', flexDirection: 'column', minHeight: '800px', boxShadow: '0 0 4px rgba(91,91,91,.5)', width: '33.33%', margin: '0 0 4px 0'}}>
                    <TableWrapper height={''} margin={'2px'} padding="0 10px">
                        <TableHeader>MISC DATA</TableHeader>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">Test Transaction</TableCell>
                                        <TableCell>{test_txn_tf}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Sales Count For This CC</TableCell>
                                        <TableCell>{sales_cnt_by_cc}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Decline Txn ID (mapped to)</TableCell>
                                        <TableCell>{decline_txn_id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Refund Txn ID (mapped to)</TableCell>
                                        <TableCell>{refund_txn_id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Refunded Amount</TableCell>
                                        <TableCell>{refund_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Chargeback Case Number</TableCell>
                                        <TableCell>{chgbk_case_num}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Chgbk Reversal Case Number</TableCell>
                                        <TableCell>{chgbk_revsl_case_num}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Net Chargeback Amount</TableCell>
                                        <TableCell>{net_chgbk_amount}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <TableWrapper height={''} margin={'2px'} padding="0 10px">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">CVC Status</TableCell>
                                        <TableCell>{txn_cvc_status}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">AVS Status Visa/MasterCard</TableCell>
                                        <TableCell>{txn_avs_status_visa_mc}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">AVS Status Discover</TableCell>
                                        <TableCell>{txn_avs_status_discover}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">AMEX AVS Zip Status</TableCell>
                                        <TableCell>{amex_avs_zip_status}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">AMEX AVS Address Status</TableCell>
                                        <TableCell>{amex_avs_addr_status}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">AMEX AVS Cust Address Status</TableCell>
                                        <TableCell>{amex_avs_cust_nm_status}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <TableWrapper height={'100%'} margin={'2px'} padding="0 10px">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">Transaction Entry Type</TableCell>
                                        <TableCell>{txn_entry_type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Card Present Status</TableCell>
                                        <TableCell>{txn_card_pres_status}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                </div>
            )}
        </Observer>
    );
};

export default MiscData;
