import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${({ isDownload }) => (isDownload ? -950 : 950)};
  visibility: ${({ isDownload }) => (isDownload ? 'hidden' : 'visible')};
`;

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  padding: ${({ padding }) => padding || '0'};
  //z-index: 970;
  z-index: ${({ isDownload }) => (isDownload ? -970 : 970)};
  visibility: ${({ isDownload }) => (isDownload ? 'hidden' : 'visible')};
`;

const ModalContentWrapper = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ theme }) => theme.backgroundImagePath});
  background: ${({ theme }) => theme.background};
  min-height: 500px;
  min-width: 500px;
  max-height: 94vh;
  max-width: 94vw;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  //z-index: 960;
  z-index: ${({ isDownload }) => (isDownload ? -960 : 960)};
  visibility: ${({ isDownload }) => (isDownload ? 'hidden' : 'visible')};
`;

const PreviousModalContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  top: 50%;
  //width: 95vw;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  min-height: 500px;
  min-width: 500px;
  max-height: 94vh;
  max-width: 94vw;
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ theme }) => theme.backgroundImagePath});
  background: ${({ theme }) => theme.background};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  transform: translate(-50%, -50%);
  padding: ${({ padding }) => padding || '0'};

  z-index: 999;
`;

export { ModalBackground, ModalContent, ModalContentWrapper, PreviousModalContent };

/**

 Transaction Notes: linear-gradient(135deg,#047899 0%,#04618c 19%,#003c6d 56%,#003360 100%)
 Ship/No Ship Modal:  linear-gradient(135deg,#047899 0%,#04618c 19%,#003c6d 56%,#003360 100%)

 **/
