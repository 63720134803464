import React from 'react';
import { UniversityIcon, UnivsersityIconContainer } from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Observer } from 'mobx-react-lite';
const ReservesTotal = (props) => {
  return (
    <Observer>
      {() => (
        <EndWrapper>
          <UnivsersityIconContainer>
            <UniversityIcon color="white" style={{ width: 25, height: 25 }} />
          </UnivsersityIconContainer>
          <div className={'singleValueContent'}>
            <Observer>{() => <span>{props.reserves}</span>}</Observer>
            <div className="title">Reserves</div>
          </div>
        </EndWrapper>
      )}
    </Observer>
  );
};

const EndWrapper = styled.div`
  background: ${({ theme }) => theme.rgl.gridItem.colors.background};
  box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 500ms;
  .singleValueContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title {
    margin-top: 5px;
  }
`;

export default ReservesTotal;
