import React from 'react';

function formatNumber(params) {
  if (typeof params.value !== 'number') {
    return params.value;
  }
  let formatter = new Intl.NumberFormat('eng');

  return formatter.format(params.value);
}

const gridOptsColumnDefs = {
  dashAvsIssues: {
    columnDefs: [
      { headerName: 'Count', field: 'avs_cat_cnt', width: 140, valueFormatter: formatNumber },
      {
        headerName: 'VS & MC Network',
        children: [
          {
            headerName: 'Issue',
            field: 'vs_mc_netwk_avs',
            tooltipField: 'vs_mc_netwk_avs',
            width: 220,
          },
        ],
      },
      {
        headerName: 'Disc Network',
        children: [{ headerName: 'Issue', field: 'ds_netwk_avs', tooltipField: 'ds_netwk_avs', width: 210 }],
      },
      {
        headerName: 'AMEX Network',
        children: [
          { headerName: 'Zipcode', field: 'am_netwk_avs_zip' },
          { headerName: 'Address', field: 'am_netwk_avs_addr', tooltipField: 'am_netwk_avs_addr' },
          { headerName: 'Customer', field: 'am_netwk_avs_cust', tooltipField: 'am_netwk_avs_cust' },
          { headerName: 'Phone', field: 'am_netwk_avs_ph', tooltipField: 'am_netwk_avs_cust' },
          { headerName: 'Email', field: 'am_netwk_avs_email', tooltipField: 'am_netwk_avs_email' },
        ],
      },
    ],
  },
  dashBumpFeeRsn: {
    columnDefs: [
      {
        headerName: 'Count',
        field: 'bump_cat_cnt',
        width: 100,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
        },
      },
      // { headerName: 'Reason 1', field: 'bump1_rsn', flex: 1 },
      { headerName: 'Reason 1', field: 'bump1_rsn', flex: 1 },
      // { headerName: 'Reason 2', field: 'bump2_rsn', flex: 1 },
      {
        headerName: 'Reason 2',
        field: 'bump2_rsn',
        flex: 1,
        rowGroup: true,
        cellRendererParams: {
          suppressCount: true,
          // checkbox: true,
          // innerRenderer: 'simpleCellRenderer',
          // suppressDoubleClickExpand: true,
          // suppressEnterExpand: true,
        },
      },
      { headerName: 'Reason 3', field: 'bump3_rsn', flex: 1 },
    ],
  },
  dashChrgbkRsn: {
    gridOptions: {},
    columnDefs: [
      {
        headerName: 'Count',
        field: 'chgbk_cat_cnt',
        width: 100,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
        },
      },
      { headerName: 'CC Brand Reasons', field: 'cc_brand_rsn', width: 250 },
      { headerName: 'Customer Reason', field: 'cust_rsn', flex: 1 },
    ],
  },
  dashDecl: {
    gridOptions: {},
    columnDefs: [
      { headerName: 'Count', field: 'decl_cat_cnt', width: 100 },
      { headerName: 'Reasons', field: 'decl_resp', flex: 1 },
    ],
  },
  dashErrRsn: {
    gridOptions: {},
    columnDefs: [
      { headerName: 'Count', field: 'err_cat_cnt', width: 100 },
      { headerName: 'TSYS NW Reason', field: 'industry_rsn', flex: 1 },
      { headerName: 'TSYS Internal Reasons', field: 'tsys_internal_rsn', flex: 1 },
      { headerName: 'Industry Reason', field: 'tsys_netwk_err_rsn', flex: 1 },
    ],
  },
  dashRejRsn: {
    gridOptions: {},
    columnDefs: [
      { headerName: 'Count', field: 'avs_cat_cnt', width: 100 },
      { headerName: 'Txn Type', field: 'txn_typ', flex: 1 },
      { headerName: 'Deposit Reason', field: 'dep_rej_desc', flex: 1 },
      { headerName: 'Refund Risk Reason', field: 'ref_risk_desc', flex: 1 },
    ],
  },
  dashRevRsn: {
    gridOptions: {},
    columnDefs: [
      { headerName: 'Count', field: 'rvsl_cat_cnt', width: 100 },
      { headerName: 'Source', field: 'rvsl_src', flex: 1 },
      { headerName: 'Reason', field: 'rvsl_rsn', flex: 1 },
    ],
  },
  dashSkm: {
    gridOptions: {},
    columnDefs: [
      { headerName: 'Count', field: 'skim_cat_cnt', width: 100 },
      { headerName: 'Reason', field: 'skim_crd_ind', flex: 1 },
    ],
  },
};

export { gridOptsColumnDefs };
