import PleaseNote from '../../components/Widgets/WidgetComponentsOther/PleaseNote/PleaseNote.js';
import React from 'react';
import GlJournals from '../../components/Widgets/GlJournals/GlJournals';
import styled from 'styled-components/macro';
import { Observer, observer } from 'mobx-react-lite';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';

const Journals = observer(() => {
  const { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              <div className="journals">
                <GlJournals />
              </div>
            </Wrapper>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  flex-direction: column;
  grid-gap: 5px;
  margin: 0 5px 5px 5px;
  .journals {
    grid-area: journals;
  }
  grid-template-areas:
    'journals';
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto);
`;

export default Journals;
