import React, { Fragment } from 'react';
import { IconInputContainer } from './StyledInput';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';

const CustomInput = observer((props) => {
  const store = useLocalStore(() => ({
    focusInput: false,
  }));

  const localStore = useLocalStore(() => ({
    focus: false,
    setFocus() {
      localStore.focus = true;
    },
    setBlur() {
      localStore.focus = false;
    },
  }));

  return (
    <Observer>
      {() => (
        <Fragment>
          {props.type === 'checkbox' && (
            <FieldWrapper
              search={props.search}
              label={props.label}
              required={props.required}
              labelWidth={props.labelWidth}
              margin={props.margin}
            >
              <div className="field">
                {props.label && (
                  <div className="labelContainer">
                    <label>
                      {props.required && <span className="required">*</span>}
                      {props.label}
                    </label>
                  </div>
                )}
                <input
                  name={props.name}
                  tabIndex={props.tabIndex}
                  onChange={props.onChange}
                  onInput={props.onInput}
                  onBlur={props.onBlur}
                  value={props.value}
                  type={props.type}
                />
              </div>
            </FieldWrapper>
          )}
          {props.icon && (
            <IconInputContainer
              focus={store.focusInput}
              margin={props.margin}
              marginTop={props.marginTop}
              marginRight={props.marginRight}
              marginBottom={props.marginBottom}
              marginLeft={props.marginLeft}
              border={props.border}
              width={props.width}
              height={props.height}
              error={props.error}
              borderRadius={props.borderRadius}
              placeHoldColor={props.placeHoldColor}
              boxShadow={props.boxShadow}
            >
              {props.children}
              <input
                name={props.name}
                tabIndex={props.tabIndex}
                autoComplete={props.autoComplete || 'off'}
                onChange={props.onChange}
                onBlur={() => {
                  store.focusInput = false;
                  if (props.setBlur) {
                    props.setBlur();
                  }
                }}
                onFocus={() => {
                  store.focusInput = true;
                  if (props.setFocus) {
                    props.setFocus();
                  }
                }}
                onKeyDown={props.onKeyDown}
                placeholder={props.placeholder || ''}
                value={props.value || ''}
                type={props.type || 'text'}
                style={{ backgroundColor: '#fff !important', background: 'none' }}
                maxLength={200}
                autoFocus={props.autoFocus}
              />
            </IconInputContainer>
          )}
          {props.type !== 'checkbox' && !props.icon && (
            <FieldWrapper
              search={props.search}
              margin={props.margin}
              marginBottom={props.marginBottom}
              label={props.label}
              required={props.required}
              flexDirection={props.flexDirection}
              labelWidth={props.labelWidth}
              focus={props.focus || localStore.focus}
              border={props.border}
              width={props.width || '100%'}
              height={props.height}
              error={props.error}
              borderRadius={props.borderRadius}
              placeHoldColor={props.placeHoldColor}
            >
              <span className="error">{props.error}</span>
              <div className="field">
                {props.label && (
                  <div className="labelContainer">
                    <label>
                      {props.required && <span className="required">*</span>}
                      {props.label}
                    </label>
                  </div>
                )}
                <div className="inputContainer" onClick={props.toggleShowItems}>
                  <input
                    style={props.disabled ? { backgroundColor: 'lightgray' } : {}}
                    name={props.name}
                    id={props.id}
                    tabIndex={props.tabIndex}
                    autoComplete={props.autoComplete || 'off'}
                    maxLength={props.maxLength}
                    onChange={props.onChange}
                    onBlur={() => {
                      if (props.blur) {
                        props.blur();
                      }
                      localStore.setBlur();
                    }}
                    // pattern={props.pattern}
                    // data-valid-example={props.dataValidExample}
                    disabled={props.disabled}
                    onFocus={props.setFocus || localStore.setFocus}
                    onKeyDown={props.onKeyDown}
                    placeholder={props.placeholder || ''}
                    value={props.value || ''}
                    type={props.type || 'text'}
                    width="100%"
                    autoFocus={props.autoFocus}
                  />
                </div>
                <span>{props.append}</span>
              </div>
            </FieldWrapper>
          )}
        </Fragment>
      )}
    </Observer>
  );
});

/*const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  outline: none;
  background-color: #fff;
  align-items: center;
  overflow-x: hidden;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  margin: ${props => {
    if (props.margin && !(props.marginLeft || props.marginRight || props.marginTop || props.marginBottom))
      return `${props.margin}`;
    else if (props.marginLeft || props.marginRight || props.marginTop || props.marginBottom) {
      let marginTop = props.marginTop || '0';
      let marginRight = props.marginRight || '0';
      let marginBottom = props.marginBottom || '0';
      let marginLeft = props.marginLeft || '0';
      return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
    } else return '0';
  }};
  width: ${props => {
    if (props.type !== 'checkbox') {
      return props.width || '100%';
    } else return '10px';
  }};
  height: ${props => props.height || 'auto'};
  color: ${({ error, theme }) => (error ? 'red' : theme.primaryColor)};

  input {
    border: none;
    font-size: 14px;
    height: 33px;
    outline: none;
    border-radius: 5px;
    text-indent: 9px;
    color: ${({ theme }) => theme.colors.primaryText || 'black'};
    &:focus {
      border: 1px solid ${({ border, theme }) => theme.focusInputBorder};
    }
    width: ${props => {
      if (props.type !== 'checkbox') {
        return props.width ? `calc(${props.width} - 4px)` : 'calc(100% - 4px)';
      } else return '10px';
    }};

    ::placeholder {
      color: ${props => (props.error ? 'red' : props.theme.colors.primaryText)};
    }
  }
`;*/
/*const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  background-color: #fff;
  align-items: center;
  overflow-x: hidden;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  border: ${({ focus, theme }) => (focus ? theme.focusInputBorder : theme.dropdownBorder)};
  margin: ${props => props.margin || '0'}
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  color: ${({ error, theme }) => (error ? 'red' : theme.colors.primaryText)};
  cursor: pointer;
  &:focus {
    background-color: red !important;
  }

  input {
    border: none;
    font-size: 14px;
    height: 33px;
    outline: none;
    border-radius: 5px;
    text-indent: 9px;
    color: ${({ theme }) => theme.colors.primaryText || 'black'};
    &:focus {
      border: 1px solid ${({ border, theme }) => theme.focusInputBorder};
    }
    width: ${props => {
      if (props.type !== 'checkbox') {
        return props.width ? `calc(${props.width} - 4px)` : 'calc(100% - 4px)';
      } else return '10px';
    }};

    ::placeholder {
      color: ${props => (props.error ? 'red' : props.theme.colors.primaryText)};
    }

  }

`;*/
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0 0 3px 0'};
  position: relative;
  width: ${({ width }) => width || '100%'};
  // border: ${({ theme }) => theme.dropdownBorder};
  border-radius: ${({ borderRadius, search }) => {
    if (search) {
      return '0';
    }
    if (borderRadius) {
      return borderRadius;
    }
    return '5px';
  }};
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0 0 3px 0'};
  position: relative;

  .error {
    color: red;
    padding-left: 239px;
  }

  .required {
    color: red;
  }

  .field {
    display: ${({ display }) => display || 'flex'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: center;
    align-items: ${({ flexDirection }) => (flexDirection ? 'start' : 'center')};
    color: ${({ theme }) => theme.colors.text};

    .inputContainer {
      display: flex;
      flex-direction: row;
      outline: none;
      background-color: #fff;
      align-items: center;
      overflow-x: hidden;
      border-radius: ${({ borderRadius, search }) => {
        if (search) {
          return '0';
        }
        if (borderRadius) {
          return borderRadius;
        }
        return '5px';
      }};
      width: ${(props) => props.width || '100%'};
      height: ${(props) => props.height || 'auto'};
      color: ${({ error, theme }) => (error ? 'red' : theme.primaryColor)};
      input {
        border: none;
        font-size: 14px;
        height: 33px;
        outline: none;
        text-align: ${({ search }) => (search ? 'center' : 'start')};
        border-radius: ${({ borderRadius, search }) => {
          if (search) {
            return '0';
          }
          if (borderRadius) {
            return borderRadius;
          }
          return '5px';
        }};
        text-indent: 9px;
        color: ${({ theme }) => theme.primaryColor || 'black'};
        border: ${({ theme }) => theme.dropdownBorder};
        &:focus {
          border: ${({ theme }) => theme.focusInputBorder};
        }

        width: ${(props) => {
          if (props.type !== 'checkbox') {
            if (props.search) {
              return props.width ? props.width : '100%';
            }
            return '100%';
          } else return '10px';
        }};

        ::placeholder {
          color: ${(props) => (props.error ? 'red' : props.theme.primaryColor)};
        }
      }
    }
    .labelContainer {
      width: ${({ labelWidth }) => labelWidth || 'auto'};
      //margin: ${({ required, label }) => (!required ? '0 0 0 4px' : '0 5px 0 0')};
      color: ${({ theme }) => theme.colors.text};
      display: flex;
      label {
        text-align: end;
        width: 100%;
        padding-right: 5px;
      }
    }

    @media (max-width: 490px) {
      display: block;
      flex-direction: column;
      .labelContainer {
        width: 100%;
        label {
          text-align: start;
        }
      }
    }
  }
`;

export default CustomInput;
