import styled from 'styled-components/macro';
import React from 'react';

const SSWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${({ padding }) => padding || ''};
  margin: ${({ margin }) => (margin ? margin : '0')};
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;
/*

function getGridOptions(toCurrency, toDateFormatter, fetchData, showTxnModal) {
  return {
    columnDefs: [
      {
        headerName: 'Notes',
        field: 'details',
        pinned: 'left',
        /!*cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.innerHTML = '<button class="apply">Notes</button>';
          const eButton = eDiv.querySelectorAll('.apply')[0];

          return eButton.addEventListener('click', async () => {
            await fetchData(params.data.display_id, params.data.mid);
            showTxnModal(params.data.display_id, params.rowIndex, true);
          });
        },*!/
        cellRenderer: (params) => {
          const data = params.data,
            button = document.createElement('button');
          button.innerText = 'Open';
          button.className = 'apply';
          button.addEventListener('click', async () => {
            await fetchData(data.display_id, data.mid);
            showTxnModal(data.display_id, params.rowIndex, true);
          });
          return button;
        },
      },
      { headerName: 'Row', field: 'Index', pinned: 'left', checkboxSelection: true },
      { headerName: 'Mid', field: 'mid' },
      { headerName: 'Payment Type', field: 'pymt_type' },
      { headerName: 'Txn Date', field: 'txn_dt' },
      { headerName: 'Settle Date', field: 'sttl_dt' },
      { headerName: 'Funded Date', field: 'fund_dt' },
      { headerName: 'Txn Bucket', field: 'txn_bkt' },
      { headerName: 'Txn Type', field: 'txn_type' },
      { headerName: 'Txn Status', field: 'txn_stat' },
      { headerName: 'Txn Expl', field: 'txn_expl' },
      { headerName: 'Txn Fund Status', field: 'txn_fund_stat' },
      { headerName: 'Txn Amount', field: 'txn_amt' },
      { headerName: 'Funded Amount', field: 'fund_amt' },
      { headerName: 'Txn Currency', field: 'txn_curr' },
      { headerName: 'Total Fees', field: 'tot_fees' },
      { headerName: 'Invoice Number', field: 'invoice_num' },
      { headerName: 'Invoice Desc', field: 'invoice_desc' },
      { headerName: 'Customer', field: 'customer' },
      { headerName: 'CC Bin', field: 'cc_bin' },
      { headerName: 'CC Last 4', field: 'cc_last_4' },
      { headerName: 'Txn ID', field: 'txn_id' },
      { headerName: 'Auth Code', field: 'auth_cd' },
    ],
    defaultColDef: {
      minWidth: 90,
      maxWidth: 300,
      sortable: false,
      resizable: true,
      /!*     cellStyle: function (params) {
        let negativeStyleColumns = [
          'Txn Amt',
          'Funct Amt',
          'Total Fees',
          'Settle Amt',
          'Settle Funct Amt',
          'Submit Amt',
          'Submit Funct Amt',
        ];
        if (_includes(negativeStyleColumns, params.colDef.headerName) && params.value < 0) {
          return { color: 'red', cursor: 'pointer' };
        }
        return { cursor: 'pointer' };
      },*!/
    },
    onGridReady: (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
    },
    onFirstDataRendered: (params) => {
      setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
    },
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    overlayNoRowsTemplate:
      '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No Data for selected Time Period</span>',
  };
}
*/

const initialData = { txns: [], count: 0 };

export { initialData, SSWidgetWrapper };

/*
<AgGridColumn headerName={'Row'} field={'Index'} pinned={'left'} checkboxSelection={true} />
<AgGridColumn headerName={'Mid'} field={'mid'} />
<AgGridColumn headerName={'Payment Type'} field={'pymt_type'} />
<AgGridColumn headerName={'Txn Date'} field={'txn_dt'} />
<AgGridColumn headerName={'Settle Date'} field={'sttl_dt'} />
<AgGridColumn headerName={'Funded Date'} field={'fund_dt'} />
<AgGridColumn headerName={'Txn Bucket'} field={'txn_bkt'} />
<AgGridColumn headerName={'Txn Type'} field={'txn_type'} />
<AgGridColumn headerName={'Txn Status'} field={'txn_stat'} />
<AgGridColumn headerName={'Txn Expl'} field={'txn_expl'} />
<AgGridColumn headerName={'Txn Fund Status'} field={'txn_fund_stat'} />
<AgGridColumn headerName={'Txn Amount'} field={'txn_amt'} />
<AgGridColumn headerName={'Funded Amount'} field={'fund_amt'} />
<AgGridColumn headerName={'Txn Currency'} field={'txn_curr'} />
<AgGridColumn headerName={'Total Fees'} field={'tot_fees'} />
<AgGridColumn headerName={'Invoice Number'} field={'invoice_num'} />
<AgGridColumn headerName={'Invoice Desc'} field={'invoice_desc'} />
<AgGridColumn headerName={'Customer'} field={'customer'} />
<AgGridColumn headerName={'CC Bin'} field={'cc_bin'} />
<AgGridColumn headerName={'CC Last 4'} field={'cc_last_4'} />
<AgGridColumn headerName={'Txn ID'} field={'txn_id'} />
<AgGridColumn headerName={'Auth Code'} field={'auth_cd'} />*/
