import React from 'react';
import styled from 'styled-components/macro';
import { Wrapper } from 'styles/styledComponents';
import { useInjectStores } from 'utils/utils';

const RouteLoadingSpinner = ({ size }) => {
  const { userStore } = useInjectStores();
  return (
    <Wrapper margin={userStore.user ? '0 4px 4px 4px' : ''}>
      <SpinnerContainer>
        <StyledWrapper width={size} height={size}>
          <svg viewBox="0 0 920 250">
            <defs>
              <symbol id="dot">
                <circle cx="50" cy="50" r="25" />
              </symbol>

              <g id="filters">
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 19 -9"
                    result="goo"
                  ></feColorMatrix>
                  <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
                </filter>

                <filter id="glow" filterUnits="userSpaceOnUse" x="-20%" y="-20%" height="140%" width="140%">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="8" />
                </filter>

                <filter id="outer-glow" filterUnits="userSpaceOnUse" x="-50%" y="-50%" height="220%" width="220%">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="30" />
                </filter>
              </g>
            </defs>

            <svg viewBox="0 0 530 250">
              <g className="circles">
                <use className="dot" href="#dot" x="0%" y="75" />
                <use className="dot" href="#dot" x="25%" y="75" />
                <use className="dot" href="#dot" x="50%" y="75" />
                <use className="dot" href="#dot" x="75%" y="75" />
                <use className="dot-light scanner" href="#dot" x="0" y="75" />
              </g>

              <use className="dot-glowing scanner" href="#dot" x="0" y="75" />
            </svg>
          </svg>
        </StyledWrapper>
      </SpinnerContainer>
    </Wrapper>
  );
};
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ containerWidth }) => containerWidth || '100%'};
  height: ${({ containerHeight }) => containerHeight || '100%'};
`;

const StyledWrapper = styled.main`
  //:root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '#000'};
  height: ${({ height }) => height || '#000'};
  --dot-diameter: 50px;

  --starting-dot-x: 0%;
  /* Chrome needs to calculate the endpoint implicitly,
     unlike FF. */
  --ending-dot-x: calc(530px * 0.75);
  --static-dot-color: hsl(204, 100%, 65%);
  --moving-dot-color: hsl(178, 94%, 65%);
  --moving-dot-glow: hsla(178, 94%, 65%, 0.5);

  /* --------------------------------------------------
       Classes
       -------------------------------------------------- */

  .dot {
    fill: var(--static-dot-color);
  }

  .dot-light {
    fill: var(--moving-dot-color);
    filter: url('#glow');
  }

  .dot-glowing {
    fill: var(--moving-dot-glow);
    filter: url('#outer-glow');
  }

  .scanner {
    will-change: transform;
    animation: 2.5s scan1 0s infinite ease-in-out both;
  }

  /* Applies the gooey effect to a container with this class. */
  .circles {
    filter: url('#goo');
  }

  /* --------------------------------------------------
       Animations
       -------------------------------------------------- */

  /* Makes our animated object turn around at the first and last dot.
     * We subtract/add the radius of our dots from the first and last
     * dot, respectively, to create a subtle elastic effect.
     */
  @keyframes scan1 {
    0%,
    100% {
      transform: translateX(calc(var(--starting-dot-x) - (var(--dot-diameter) / 2)));
    }

    50% {
      transform: translateX(calc(var(--ending-dot-x) + (var(--dot-diameter) / 2)));
    }
  }
`;

export default RouteLoadingSpinner;
