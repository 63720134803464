import { destroy, flow, getRoot, getSnapshot, types } from 'mobx-state-tree';
import { commonService } from 'services';
import { ExportInfoModel, OrderByModel, PaginatorModel } from '../CommonFilterModels';
import { _cloneDeep, _compact, _map, removeEmptyValues } from 'utils/utils';
import { saveAs } from 'file-saver';
import Moment from "moment/moment";
import {isHelper} from "babel-plugin-styled-components/lib/utils/detectors";

const { maybe, number, string, boolean, array, optional } = types;

const FilterModel = types
  .model({
    declBktID: maybe(number),
    respCodeID: maybe(number),
    invNum: optional(string, ''),
    origCustName: optional(string, ''),
    respCodeSname: maybe(string),
  })
  .actions((self) => ({
    afterCreate() {},
    resetState() {},
    setCustName(string) {
      self.origCustName = string;
    },
    setInvNum(string) {
      self.invNum = string;
    },
    setRespCodeSname(name) {
      self.respCodeSname = name;
    },
  }));

const SsTxnsModel = types
  .model({
    exportInfo: optional(ExportInfoModel, () => ExportInfoModel.create()),
    filterModel: optional(FilterModel, () => FilterModel.create()),
    orderByModel: optional(array(OrderByModel), [{ column: '', sort: 1 }]),
    paginatorModel: optional(PaginatorModel, () => PaginatorModel.create()),
    isHistoric: optional(boolean, false),
    date: maybe(string),
    // widgetID: maybe(number),
    // widgetName: maybe(string),
    reportName: maybe(string),
  })
  .volatile((self) => ({
    getDetails: null,
  }))
  .views((self) => ({
    get rootStore() {
      return getRoot(self);
    },
    get uiStore() {
      return getRoot(self).uiStore;
    },
    get notify() {
      return self.uiStore.notify;
    },
    /** Query Props **/
    get dateFilter() {
      return self.rootStore.filterStore.dateFilter;
    },
    get clientID() {
      return self.rootStore.userStore.user.currentClient.id;
    },
    get startDt() {
      return self.rootStore.filterStore.dateFilter.formatStartDt;
    },
    get endDt() {
      return self.rootStore.filterStore.dateFilter.formatEndDt;
    },
    get txnBatchNum() {
      return self.rootStore.filterStore.transactionFilter.txnBatchNumber;
    },
    get agentNm() {
      return self.rootStore.filterStore.transactionFilter.agentNm;
    },
    get orderBy() {
      let order = _map(self.orderByModel, (order) => {
        if (order.column) {
          return [order.column, order.sort];
        }
      });
      if (order.length === 0) return [];
      else return _compact(order);
    },
    get currentFilterData() {
      return self.rootStore.filterStore.currentFilterData;
    },
    get exportQuery() {
      let startDt = self.dateFilter.dateType === 'txnDt' ? 'startDt' : 'startStmtDt';
      let endDt = self.dateFilter.dateType === 'txnDt' ? 'endDt' : 'endStmtDt';

      let query = {
        filter: {
          clientID: self.clientID,
          [startDt]: self.startDt,
          [endDt]: self.endDt,
          widgetID: self.widgetID,
          report: self.reportName,
          ...getSnapshot(self.filterModel),
          ...self.currentFilterData,
        },
        exportInfo: {
          ...self.exportInfo,
        },
        orderBy: self.orderBy,
        pageNumber: 0,
      };

      let exportQuery = removeEmptyValues(query);

      return {
        params: {
          ...exportQuery,
        },
      };
    },
    get query() {
      let date = self.date;
      let startDt = self.dateFilter.dateType === 'txnDt' ? 'startDt' : 'startStmtDt';
      let endDt = self.dateFilter.dateType === 'txnDt' ? 'endDt' : 'endStmtDt';

      let buildQuery = {
        clientID: self.clientID,
        [startDt]: self.startDt,
        [endDt]: self.endDt,
        widgetID: self.widgetID,
        report: self.widgetName,
        ...self.filterModel,
        ...self.currentFilterData,
        orderBy: self.orderBy,
        batchNum: self.txnBatchNum,
        agentNm: self.agentNm,
        txnLimit: self.paginatorModel.txnLimit,
        pageNumber: self.paginatorModel.pageNumber,
      };
      // if its historic use the filter date from dropdown.
      if (self.isHistoric && self.date) {
        buildQuery.startDt = date
      }
      return buildQuery;
    },
  }))
  .actions((self) => ({
    /** LifeCycle Hooks **/
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    setDate(date) {
     self.date = date
    },
    setIsHistoric(bool) {
      self.isHistoric = bool;
    },
    fetchWidgetData: flow(function* fetchWidgetData(fetchData, historicExpDate) {
      let query = _cloneDeep(self.query);
      query = removeEmptyValues(query);
      
      const post = {
        params: {
          ...query,
          orderBy: query.orderBy,
          pageNumber: query.pageNumber,
          txnLimit: query.txnLimit,
        },
      };
      return yield fetchData(post);
    }),
    initiateTxnsExport: flow(function* initiateSsTxnsExport() {
      self.notify({
        duration: 1500,
        text: 'Data Export Initialized',
        group: 'topRight',
      });

      let post = self.exportQuery;
      try {
        const response = yield self.getDetails(post);
        if (response.data.dataExported) {
          let data,
            file,
            link = document.createElement('a'),
            failed = false;
          try {
            switch (post.params.exportInfo.exportType) {
              case 'json':
                data = new Blob([JSON.stringify(response.data.data, null, 4)], { type: response.headers['content-type'] });
                file = new File([data], 'data.json', { type: response.headers['content-type'] });
                break;
              case 'csv':
                data = new Blob([response.data.data], { type: response.headers['content-type'] });
                file = new File([data], 'data.csv', { type: response.headers['content-type'] });
                break;
              default:
                throw new Error(`We aren't equipped to handle this export type yet`);
            }

            saveAs(file);
          } catch (e) {
            failed = true;
            console.error(e);

            self.notify({
              duration: 1500,
              group: 'topRight',
              text: `Data Export Failed: ${e.msg}`,
              type: 'error',
            });
          }

          if (!failed) {
            self.notify({
              duration: 1500,
              group: 'topRight',
              text: `Data Export Complete`,
            });
          }
        }
      } catch (error) {
        console.error('Failed to export the data: ', error);
        self.state = 'error';
      }
    }),
    resetOrderBy() {
      destroy(self.orderByModel);
    },
    addOrderBy() {
      self.orderByModel.push({ column: '', sort: 1 });
    },
    remove(item) {
      destroy(item);
    },
  }));

export default SsTxnsModel;
