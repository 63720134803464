import styled from 'styled-components';

const DatePickerPresetButton = styled.div`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  background: transparent;
  &:hover {
    background: rgba(255, 255, 255);
    color: grey !important;
  }
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-image: initial;
  padding: 8px 12px;
  font: inherit inherit inherit inherit inherit inherit inherit inherit;
  overflow: visible;
`;
export { DatePickerPresetButton };
