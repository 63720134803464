import React, { memo } from 'react';
import { SunIcon, MoonIcon } from 'styles/styledComponents';
import styled from 'styled-components';
import { Circle } from 'styled-icons/boxicons-regular/Circle';
const CircleIcon = styled(Circle)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const Toggle = memo(({ value, onChange }) => {
  return (
    <Wrapper>
      <label onClick={onChange} className="switch">
        <div
          style={{
            position: 'absolute',
            zIndex: 999999999,
            marginRight: '16px',
            color: 'rgba(0, 0, 0, .4)'
          }}
        >
          <CircleIcon
            style={{
              color: 'white',
              width: 30,
              height: 30,
              paddingTop: 0,
              zIndex: 2,
              paddingLeft: value === false ? 1 : 26,
              transition: 'all 500ms',
              position: 'absolute',
              left: -2,
              top: -2,
            }}
          ></CircleIcon>
          {/* <SunIcon
            style={{
              width: 20,
              height: 20,
              position: 'absolute',
              left: 4,
              top: 3,
              transition: 'all 1s',
              zIndex: 1,
              color: value === false ? '#C78E2E' : 'grey'
            }}
            color="grey"
          />
          <MoonIcon
            style={{
              width: 16,
              height: 16,
              position: 'absolute',
              left: 30,
              top: 5,
              transition: 'all 1s',
              zIndex: 1,
              color: value === false ? 'grey' : '#C78E2E'
            }}
            color="grey"
          /> */}
        </div>
        <div className="slider" />
      </label>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 69px;
    height: 26px;
    outline: none;
    cursor: pointer;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196f3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    margin-right: 16px;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    transform: translateX(12px);
  }
`;

export default Toggle;
