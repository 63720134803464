import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { adminStore } from 'stores/AdminStore';

const CheckBoxGroup = observer(({parent, group}) => {
	const [checkboxes, setCheckboxes] = useState(group);
	const [groupName, setGroupName] = useState(parent);
	const [isIndeterminate, setIsIndeterminate] = useState(false);
	const parentRef = React.createRef();
	
	useEffect(() => {
		const allSiblingsChecked = checkboxes.every((child) => child.checked);
		const someSiblingsChecked = checkboxes.some((child) => child.checked);
		const allSiblingsNotChecked = checkboxes.every((child) => !child.checked);

		parentRef.current.indeterminate = !allSiblingsChecked && someSiblingsChecked;
		
		setIsIndeterminate(!allSiblingsChecked && someSiblingsChecked)
		
		if(allSiblingsChecked && !groupName.checked){
			setGroupName(prevState => ({
				...prevState,
				checked: true
			}));
		}
		if(allSiblingsNotChecked && groupName.checked){
			setGroupName(prevState => ({
				...prevState,
				checked: false
			}));
		}
	}, [checkboxes, groupName, parentRef]);
	
	const handleCheckboxChange = (e) => {
		const { name, checked, id } = e.target;
		const isParent = id.includes('parent');
		adminStore.updateUserPermissions(name, checked, isParent);
		setCheckboxes((prevState) =>
			prevState.map((checkbox) => {
			if (checkbox.name !== undefined && checkbox.name === name) {
				return { ...checkbox, checked: checked };
			}
				return checkbox;
			})
		);
		
		if (name === groupName.name) {
			setGroupName(prevState => ({
				...prevState,
				checked: checked
			}))
			setCheckboxes((prevState) =>
				prevState.map((checkbox) => {
					if(!checkbox.disabled){
						return { ...checkbox, checked: checked };
					}else {
						return checkbox;
					}
				})
			);
		}
		if (name === groupName.name && isIndeterminate) {
			setCheckboxes((prevState) =>
				prevState.map((checkbox) => {
					if(!checkbox.disabled){
						return { ...checkbox, checked: false };
					}else {
						return checkbox;
					}
				})
			);
			setGroupName(prevState => ({
				...prevState,
				checked: !checked
			}))
		}
	};
	const restrictedForUser = ['Add New Users', 'Administrator Rights', 'Permission to Add Reports', 'Permission to Upgrade']
	
	  return (
		<Wrapper>
		  <ul>
			<li>
				<input
					disabled={groupName.disabled}
					type="checkbox"
					ref={parentRef}
					name={groupName.name}
					id={`${groupName.name}_parent`}
					checked={groupName.checked}
					onChange={handleCheckboxChange}
				/>
				<label htmlFor={groupName.name} style={{textTransform: 'uppercase', borderBottom: 'solid 1px white', color: 'white', fontWeight: 'bold'}}>{groupName.name}</label>
				<ul>
				{
					checkboxes.map((checkbox, index) => {
						if(restrictedForUser.includes(checkbox.name) && adminStore.userObj.role === 'User'){
							checkbox.disabled = true;
							checkbox.checked = false;
						}else if(restrictedForUser.includes(checkbox.name) && adminStore.userObj.role !== 'User'){
							checkbox.disabled = false;
						}
						return (
							<li key={index}>
								<input
									disabled={checkbox.disabled}
									type="checkbox"
									name={checkbox.name}
									id={`${checkbox.name}_${index}`}
									checked={checkbox.checked}
									onChange={handleCheckboxChange}
								/>
								<label htmlFor={checkbox.name}>{checkbox.name}</label>
							</li>
						)
					})
				}
				</ul>
			</li>
		  </ul>
		</Wrapper>
	  );
});


  const Wrapper = styled.div`
	body {
		padding: 20px;
	}
	ul { 
		list-style: none;
	}
	li {
		margin: 10px 0;
	}
  `;

export default CheckBoxGroup;
