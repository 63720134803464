import { useObserver } from 'mobx-react-lite';

export function useProps(props) {
  return useObserver(() => ({
    children: props.children,
    loadingAllClientOptions: props.loadingAllClientOptions,
    index: props.index,
    isProc: props.isProc,
    gwProc: props.gwProc,
    notify: props.notify,
    store: props.store,
    createEdit: props.createEdit,
    clients: props.clients,
    gwOptions: props.gwOptions,
    procOptions: props.procOptions,
    aggrOptions: props.aggrOptions,
    currencyOptions: props.currencyOptions,
    pricingPlanOptions: props.pricingPlanOptions,
    timezoneOptions: props.timezoneOptions,
    allClientOptions: props.allClientOptions,
    countryOptions: props.countryOptions,
    datasourceOptions: props.datasourceOptions,
  }));
}
