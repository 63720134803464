import ReactDOM from 'react-dom';
import React, { Fragment, useEffect } from 'react';
import { ModalBackground, ModalContent, ModalContentWrapper } from './StyledModal';
import { CloseIcon } from 'styles/styledComponents';

const Modal = ({ children, colorIcon, isOpen, toggleModal, closeRight, padding, width, height, isDownload }) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode && event.keyCode === 27) toggleModal();
  };

  return ReactDOM.createPortal(
    <Fragment>
      <ModalBackground isDownload={isDownload} isOpen={isOpen} onClick={toggleModal} />
      <ModalContentWrapper isDownload={isDownload}>
        <CloseIcon
          style={{
            width: 25,
            height: 25,
            position: 'absolute',
            top: 5,
            right: closeRight || 15,
            zIndex: 975,
            cursor: 'pointer',
          }}
          color={colorIcon || 'white'}
          hoverColor={'grey'}
          hoverCursor
          hoverClick
          onClick={toggleModal}
        />
        <ModalContent padding={padding} height={height} width={width} isOpen={isOpen}>
          {children}
        </ModalContent>
      </ModalContentWrapper>
    </Fragment>,
    document.getElementById('modalRoot')
  );
};

export default Modal;
