import styled from 'styled-components/macro';

const TableHeaderButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme, isDemo }) => (isDemo ? 'grey' : theme.colors.primaryBtn)};
  border: none;
  border-radius: 5px;
  color: white;
  height: ${({ height }) => height || '35px'};
  //margin: 0 5px;
  margin: 0 5px 3px 0;
  outline: none;
  padding: 5px;
  // width: ${({ width }) => width || '54px'};
  font-size: 14px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;

export { TableHeaderButton };
