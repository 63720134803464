import React from 'react';
import styled from 'styled-components';
import { StoreContext } from '../../../stores/RootStore';

const LoadingSpinner = ({ color, containerHeight, containerWidth, loading, size }) => {
  const rootStore = React.useContext(StoreContext);

  return (
    <SpinnerContainer>
      <StyledWrapper width={size} height={size}>
        <svg viewBox="0 0 700 700">
          <defs>
            <symbol id="dot">
              <circle cx="30" cy="30" r="30" />
            </symbol>
            <symbol id="moving-dot">
              <circle cx="175" cy="175" r="28" />
            </symbol>
            <g id="filters">
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 19 -9"
                  result="goo"
                />
                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
              </filter>

              <filter id="glow" filterUnits="userSpaceOnUse" x="-20%" y="-20%" height="140%" width="140%">
                <feGaussianBlur in="SourceGraphic" stdDeviation="8" />
              </filter>

              <filter id="outer-glow" filterUnits="userSpaceOnUse" x="-50%" y="-50%" height="220%" width="220%">
                <feGaussianBlur in="SourceGraphic" stdDeviation="30" />
              </filter>
            </g>
          </defs>

          <svg>
            <g className="circles centered">
              <use className="dot" href="#dot" x="106" y="106" />
              <use className="dot" href="#dot" x="0" y="150" />
              <use className="dot" href="#dot" x="-106" y="106" />
              <use className="dot" href="#dot" x="-150" y="0" />
              <use className="dot" href="#dot" x="-106" y="-106" />
              <use className="dot" href="#dot" x="0" y="-150" />
              <use className="dot" href="#dot" x="106" y="-106" />
              <use className="dot" href="#dot" x="150" y="0" />
              <use className="dot-light scanner" href="#moving-dot" x="-150" y="-150" />
            </g>
            <g className="centered">
              <use className="dot-glowing scanner " href="#moving-dot" x="-150" y="-150" />
            </g>
          </svg>
        </svg>
      </StyledWrapper>
    </SpinnerContainer>
  );
};
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ containerWidth }) => containerWidth || '100%'};
  height: ${({ containerHeight }) => containerHeight || '100%'};
`;

const StyledWrapper = styled.main`
  width: ${({ width }) => width || '#000'};
  height: ${({ height }) => height || '#000'};
  display: flex;
  justify-content: center;
  align-items: center;
  --dot-radius:  30px;
  --path-radius: 150px;
  --vp-width:    700px;
  --vp-height:   700px;

  --static-dot-color: hsl(204, 100%, 65%);
  --moving-dot-color: hsl(178, 94%, 65%);

  /* --------------------------------------------------
     Classes
     -------------------------------------------------- */

  .dot {
    fill: var(--static-dot-color);
  }

  .dot-light {
    fill: var(--moving-dot-color);
    filter: url('#glow');
  }

  .dot-glowing {
    fill: var(--moving-dot-color);
    filter: url('#outer-glow');
  }

  .scanner {
    will-change: transform;
    animation: 3s scan 0s infinite linear both;
  }

  .circles {
    filter: url("#goo");
  }

  .centered {
    /* FF lets us use percentages, but Chrome doesn't like that. That
     * means we have to hard-code the dimensions of the viewport.
     */
    transform:
      translate(
        calc((var(--vp-width) * 0.5) - var(--dot-radius)),
        calc((var(--vp-height) * 0.5) - var(--dot-radius))
      );
  }

  @keyframes scan {
    from {
      transform: rotate(0deg) translateY(calc(var(--path-radius) * -1)) rotate(0deg);
    }

    to {
      transform: rotate(360deg) translateY(calc(var(--path-radius) * -1)) rotate(-360deg);
    }
`;

export default LoadingSpinner;
