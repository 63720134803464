import React, {useEffect} from 'react';
import {
    Purchases,
    Returns,
    Reconciliation,
    Fees,
} from './index';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {_get, useGetWidgetData, useInjectStores} from 'utils/utils';
import {applySnapshot} from 'mobx-state-tree';
import {widgetService} from 'services';
import {useLocation, useHistory} from 'react-router-dom';
import {api} from '../../../services/api';

const SsTxnTotals = observer(() => {
    const {dataManagementStore, filterStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency;
    const getTotalsPercentage = utilsStore.getTotalsPercentage;
    const history = useHistory();
    const location = useLocation();

    /*useEffect(() => {
          var paramsString = location.search;
          let params = {}
          if (paramsString) {
            let searchParams = new URLSearchParams(paramsString);
            for (let p of searchParams) {
              params[p[0]] = p[1]
            }
          }
          console.log('params', params);
          store.applyNewFilter({
            category: params.category,
            group: params.group
          })
          // setCurrentFilter(history, location);
        }, [location.search]);
      */
    const store = useLocalObservable(() => ({
        /*props.store.applyNewFilter({
                  category,
                  group,
                });*/
        history,
        location,
        /*applyNewFilter(snapshot) {
          // reset current filter, but don't apply.
          filterStore.resetStateNoApply();
          // set the model
          applySnapshot(filterStore.transactionFilter, snapshot);
          // setting the options for the multi select dropdowns
          // this.filterStore.mapFilterDropdownsToModels();
          // set the pills
          filterStore.applyFilter(history, location);
          // set the newquery for the useEffects to pick up
          dataManagementStore.setNewQuery();
        },*/
        filterStore,
        dataManagementStore,
        isLoadingReconSummaryPurchasesSales: false,
        isLoadingReconSummaryPurchasesDeclines: false,
        isLoadingReconSummaryPurchasesVoids: false,
        isLoadingReconSummaryFees: false,
        isLoadingReconSummaryReturnsSuccessful: false,
        isLoadingReconSummaryReturnsChargebacks: false,
        isLoadingReconSummaryReturnsErrors: false,
        isLoadingReconSummaryReturnsVoids: false,
        /**/
        reconSummaryPurchasesSales: [],
        reconSummaryPurchasesDeclines: [],
        reconSummaryPurchasesVoids: [],
        /**/
        reconSummaryReturnsSuccessful: [],
        reconSummaryReturnsChargebacks: [],
        reconSummaryReturnsErrors: [],
        reconSummaryReturnsVoids: [],
        //
        reconSummaryFees: [],


        get isLoadingPurchases() {
            return (
                this.isLoadingReconSummaryPurchasesSales ||
                this.isLoadingReconSummaryPurchasesDeclines ||
                this.isLoadingReconSummaryPurchasesVoids
            )
        },
        get isLoadingReturns() {
            return (
                this.isLoadingReconSummaryReturnsSuccessful ||
                this.isLoadingReconSummaryReturnsChargebacks ||
                this.isLoadingReconSummaryReturnsErrors ||
                this.isLoadingReconSummaryReturnsVoids
            )
        },
        get isLoadingReconciliation() {
            return this.isLoadingReconSummaryPurchasesSales ||
                this.isLoadingReconSummaryPurchasesDeclines ||
                this.isLoadingReconSummaryPurchasesVoids || this.isLoadingReconSummaryReturnsSuccessful ||
                this.isLoadingReconSummaryReturnsChargebacks ||
                this.isLoadingReconSummaryReturnsErrors ||
                this.isLoadingReconSummaryReturnsVoids ||
                this.isLoadingReconSummaryFees
        },

        setNewQueryTest(newQueryTest) {
            const {status, data, error} = newQueryTest;
            if (status === 'loading') this.isLoadingNewQueryTest = true;
            if (data) {
                this.newQueryTest = data;
                this.isLoadingNewQueryTest = false;
            }
            if (error) this.isLoadingNewQueryTest = false;
        },
        get purchasesTotals() {
            let amt = 0;
            let cnt = 0;
            let unPurch = 0;
            if (!this.isLoadingPurchases) {
                this.reconSummaryPurchasesSales.map((detail, index) => {
                    cnt += _get(detail, 'sale_cat_vol', 0);
                    amt += _get(detail, 'purch_succ_amts', 0);
                });
                this.reconSummaryPurchasesDeclines.map((detail, index) => {
                    cnt += _get(detail, 'decl_cat_vol', 0);
                    amt += _get(detail, 'decl_err_amts', 0);
                    unPurch += _get(detail, 'decl_err_amts', 0);
                });
                this.reconSummaryPurchasesVoids.map((detail, index) => {
                    cnt += _get(detail, 'void_cat_vol', 0);
                    amt += _get(detail, 'void_revsl_amts', 0);
                    unPurch += _get(detail, 'void_revsl_amts', 0);
                });
            }
            return {amt, cnt, unPurch}
        },
        get returnsTotals() {
            let amt = 0;
            let cnt = 0;
            let sucRet = 0;
            if (!this.isLoadingReturns) {
                this.reconSummaryReturnsSuccessful.map((detail, index) => {
                    cnt += _get(detail, 'ref_cat_vol', 0);
                    amt += _get(detail, 'succ_ref_amts', 0);
                    sucRet += _get(detail, 'succ_ref_amts', 0);
                });
                this.reconSummaryReturnsChargebacks.map((detail, index) => {
                    cnt += _get(detail, 'ref_cat_vol', 0) || _get(detail, 'chgbk_cat_vol');
                    amt += _get(detail, 'succ_ref_amts', 0) || _get(detail, 'succ_chgbk_amts');
                    sucRet += _get(detail, 'succ_ref_amts', 0) || _get(detail, 'succ_chgbk_amts');
                });
                this.reconSummaryReturnsErrors.map((detail, index) => {
                    cnt += _get(detail, 'decl_cat_vol', 0);
                    amt += _get(detail, 'decl_err_amts', 0);
                });
                this.reconSummaryReturnsVoids.map((detail, index) => {
                    cnt += _get(detail, 'void_cat_vol', 0);
                    amt += _get(detail, 'void_revsl_amts', 0);
                });

            }
            return {amt, cnt, sucRet}
        },
        get feesTotals() {
            let amt = 0;
            if (!this.isLoadingReconSummaryFees) {
                this.reconSummaryFees.map((detail, index) => {
                    amt += detail.gateway_fees
                    amt += detail.auth_sttlmt_fees
                    amt += detail.risk_assessmt_fees
                    amt += detail.chgbk_penalty_fees
                    amt += detail.mo_stmt_fees
                })
            }
            return {amt}
        },
        get reconTotals() {
            const recon = {};
            if (!this.isLoadingReconciliation) {
                const sp = this.purchasesTotals.amt;
                const up = (this.purchasesTotals.unPurch) * -1;
                const upg = getTotalsPercentage(up, sp);
                const np = sp + up;
                const npg = getTotalsPercentage(np, sp);
                const sr = this.returnsTotals.sucRet;
                const srg = getTotalsPercentage(sr, np);
                const pnor = np + sr;
                const pnorg = getTotalsPercentage(pnor, sp);
                const lf = this.feesTotals.amt * -1;
                const nafd = sr + pnor - lf;
                const nafdg = getTotalsPercentage(nafd, sp);
                recon['sp'] = sp;
                recon['up'] = up;
                recon['upg'] = Math.abs(upg);
                recon['np'] = np;
                recon['npg'] = Math.abs(npg);
                recon['sr'] = sr;
                recon['srg'] = Math.abs(srg);
                recon['pnor'] = pnor;
                recon['pnorg'] = Math.abs(pnorg);
                recon['lf'] = lf;
                recon['nafd'] = nafd;
                recon['nafdg'] = Math.abs(nafdg);
            }
            return recon;
        },
        /*TODO Purchases*/
        setReconSummaryPurchasesSales(reconSummaryPurchasesSales) {
            const {status, data, error} = reconSummaryPurchasesSales;
            if (status === 'loading') this.isLoadingReconSummaryPurchasesSales = true;
            if (data) {
                this.reconSummaryPurchasesSales = data.A;
                this.isLoadingReconSummaryPurchasesSales = false;
            }
            if (error) this.isLoadingPresentedPurchases = false;
        },
        setReconSummaryPurchasesDeclines(reconSummaryPurchasesDeclines) {
            const {status, data, error} = reconSummaryPurchasesDeclines;
            if (status === 'loading') this.isLoadingReconSummaryPurchasesDeclines = true;
            if (data) {
                this.reconSummaryPurchasesDeclines = data.B;
                this.isLoadingReconSummaryPurchasesDeclines = false;
            }
            if (error) this.isLoadingReconSummaryPurchasesDeclines = false;
        },
        setReconSummaryPurchasesVoids(reconSummaryPurchasesVoids) {
            const {status, data, error} = reconSummaryPurchasesVoids;
            if (status === 'loading') this.isLoadingReconSummaryPurchasesVoids = true;
            if (data) {
                this.reconSummaryPurchasesVoids = data.C;
                this.isLoadingReconSummaryPurchasesVoids = false;
            }
            if (error) this.isLoadingReconSummaryPurchasesVoids = false;
        },

        /*TODO Returns*/
        setReconSummaryReturnsSuccessful(reconSummaryReturnsSuccessful) {
            const {status, data, error} = reconSummaryReturnsSuccessful;
            if (status === 'loading') this.isLoadingReconSummaryReturnsSuccessful = true;
            if (data) {
                this.reconSummaryReturnsSuccessful = data.D;
                this.isLoadingReconSummaryReturnsSuccessful = false;
            }
            if (error) this.isLoadingReconSummaryReturnsSuccessful = false;
        },
        setReconSummaryReturnsChargebacks(setReconSummaryReturnsChargebacks) {
            const {status, data, error} = setReconSummaryReturnsChargebacks;
            if (status === 'loading') this.isLoadingReconSummaryReturnsChargebacks = true;
            if (data) {
                this.reconSummaryReturnsChargebacks = data.E;
                this.isLoadingReconSummaryReturnsChargebacks = false;
            }
            if (error) this.isLoadingReconSummaryReturnsChargebacks = false;
        },
        setReconSummaryReturnsErrors(reconSummaryReturnsErrors) {
            const {status, data, error} = reconSummaryReturnsErrors;
            if (status === 'loading') this.isLoadingReconSummaryReturnsErrors = true;
            if (data) {
                this.reconSummaryReturnsErrors = data.F;
                this.isLoadingReconSummaryReturnsErrors = false;
            }
            if (error) this.isLoadingReconSummaryReturnsErrors = false;
        },
        setReconSummaryReturnsVoids(reconSummaryReturnsVoids) {
            const {status, data, error} = reconSummaryReturnsVoids;
            if (status === 'loading') this.isLoadingReconSummaryReturnsVoids = true;
            if (data) {
                this.reconSummaryReturnsVoids = data.G;
                this.isLoadingReconSummaryReturnsVoids = false;
            }
            if (error) this.isLoadingReconSummaryReturnsVoids = false;
        },

        /*TODO Fees*/
        setReconSummaryPurchasesFees(reconSummaryPurchasesFees) {
            const {status, data, error} = reconSummaryPurchasesFees;
            if (status === 'loading') this.isLoadingReconSummaryFees = true;
            if (data) {
                this.reconSummaryFees = data.fees;
                this.isLoadingReconSummaryFees = false;
            }
            if (error) this.isLoadingReconSummaryFees = false;
        },

        /* setWidgetData(widgetData, widgetDataName, loadingWidgetData) {
           const { status, data, error } = widgetData;
           if (status === 'loading') this[loadingWidgetData] = true;
           if (data) {
             this[widgetDataName] = widgetData;
             this[loadingWidgetData] = false;
           }
           if (error) this[loadingWidgetData] = false;
         }*/
    }));

    /*const analyticsSummary = useGetWidgetData(
        { name: 'analyticsSummary', query: widgetService.analyticsSummary },
        'data',
        dataManagementStore?.newQuery
      );*/
    const reconSummaryPurchasesSales = useGetWidgetData(
        {name: 'reconSummaryPurchasesSales', query: widgetService.reconA},
        'data',
        dataManagementStore?.newQuery
    );
    const reconSummaryPurchasesDeclines = useGetWidgetData(
        {name: 'reconSummaryPurchasesDeclines', query: widgetService.reconB},
        'data',
        dataManagementStore?.newQuery
    );
    const reconSummaryPurchasesVoids = useGetWidgetData(
        {name: 'reconSummaryPurchasesVoids', query: widgetService.reconC},
        'data',
        dataManagementStore?.newQuery
    );
    /*TODO Returns*/
    const reconSummaryReturnsSuccessful = useGetWidgetData(
        {name: 'reconSummaryReturnsSuccessful', query: widgetService.reconD},
        'data',
        dataManagementStore?.newQuery
    );
    const reconSummaryReturnsChargebacks = useGetWidgetData(
        {name: 'reconSummaryReturnsChargebacks', query: widgetService.reconE},
        'data',
        dataManagementStore?.newQuery
    );

    const reconSummaryReturnsErrors = useGetWidgetData(
        {name: 'reconSummaryReturnsErrors', query: widgetService.reconF},
        'data',
        dataManagementStore?.newQuery
    );
    const reconSummaryReturnsVoids = useGetWidgetData(
        {name: 'reconSummaryReturnsVoids', query: widgetService.reconG},
        'data',
        dataManagementStore?.newQuery
    );

    /*TODO Fees*/
    const reconSummaryPurchasesFees = useGetWidgetData(
        {name: 'reconSummaryPurchasesFees', query: widgetService.reconH},
        'data',
        dataManagementStore?.newQuery
    );


    /* TODO Purchases*/
    useEffect(() => {
        store.setReconSummaryPurchasesSales(reconSummaryPurchasesSales);
        // store.setWidgetData(reconSummaryPurchasesSales,'reconSummaryPurchasesSales', 'isLoadingReconSummaryPurchasesSales');
    }, [reconSummaryPurchasesSales]);

    useEffect(() => {
        store.setReconSummaryPurchasesDeclines(reconSummaryPurchasesDeclines);
    }, [reconSummaryPurchasesDeclines]);

    useEffect(() => {
        store.setReconSummaryPurchasesVoids(reconSummaryPurchasesVoids);
    }, [reconSummaryPurchasesVoids]);

    /*TODO Returns*/
    useEffect(() => {
        store.setReconSummaryReturnsSuccessful(reconSummaryReturnsSuccessful);
    }, [reconSummaryReturnsSuccessful]);

    useEffect(() => {
        store.setReconSummaryReturnsChargebacks(reconSummaryReturnsChargebacks);
    }, [reconSummaryReturnsChargebacks]);

    useEffect(() => {
        store.setReconSummaryReturnsErrors(reconSummaryReturnsErrors);
    }, [reconSummaryReturnsErrors]);

    useEffect(() => {
        store.setReconSummaryReturnsVoids(reconSummaryReturnsVoids);
    }, [reconSummaryReturnsVoids]);

    /*TODO Fees*/
    useEffect(() => {
        store.setReconSummaryPurchasesFees(reconSummaryPurchasesFees);
    }, [reconSummaryPurchasesFees]);

    /*  useEffect(() => {
        store.setReconSummaryPurchasesDeclines(reconSummaryPurchasesDeclines);
      }, [reconSummaryPurchasesDeclines]);




      */

    return (
        <Observer>
            {() => (
                <Wrapper>
                    <div className="presSale">
                        <Purchases store={store}
                                   succPurch={store.reconSummaryPurchasesSales}
                                   unSuccPurch={[...store.reconSummaryPurchasesDeclines]}
                                   voidPurch={[...store.reconSummaryPurchasesVoids]}

                                   isLoading={store.isLoadingPurchases}/>
                    </div>
                    <div className="presReturn">
                        <Returns store={store}
                                 succRefunds={store.reconSummaryReturnsSuccessful}
                                 succChgbks={store.reconSummaryReturnsChargebacks}
                                 unSuccDeclErr={store.reconSummaryReturnsErrors}
                                 unSuccVoidsRev={store.reconSummaryReturnsVoids}

                                 isLoading={store.isLoadingReturns}/>
                    </div>
                    <div className="unSale">
                        <Observer>
                            {() => (
                                <Fees
                                    store={store}
                                    widgetData={store.reconSummaryFees}
                                    isLoading={store.isLoadingReconSummaryFees}
                                />
                            )}
                        </Observer>
                    </div>
                    <div className="unReturn">
                        <Reconciliation
                            store={store}
                            // widgetData={store.}
                            isLoading={store.isLoadingReconciliation}
                        />
                    </div>
                    {/*
          <div className="mf">
            <Observer>{() => <MerchantFees widgetData={store.merchantFees} isLoading={store.isLoadingMerchantFees} />}</Observer>
          </div>
          <div className="rec">
            <Reconciliation widgetData={store.reconciliation} isLoading={store.isLoadingReconciliation} />
          </div>*/}
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  padding: 0 4px 4px 4px;

  .center {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.text};
  }

  .presSale {
    height: 100%;
    grid-area: presSale;
  }

  .presReturn {
    height: 100%;
    grid-area: presReturn;
  }

  .unSale {
    height: 100%;
    grid-area: unSale;
  }

  .unReturn {
    height: 100%;
    grid-area: unReturn;
  }

  .mf {
    height: 100%;
    grid-area: mf;
  }

  .rec {
    height: 100%;
    grid-area: rec;
  }

  grid-template-areas:
    'presSale unSale'
    'presReturn unReturn';
  grid-template-columns: 1fr 1fr;
  //grid-template-rows: 320px 320px;
  grid-gap: 4px;

  @media (max-width: 1389px) {
    grid-template-areas:
      'presSale unSale'
      'presReturn unReturn';
    //'mf rec';
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-areas:
      'presSale '
      'presReturn'
      'unSale'
      'unReturn ';
    //'mf '
    //'rec';
    grid-template-columns: 1fr;
  }
`;

export default SsTxnTotals;
