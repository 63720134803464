import React, {Fragment, useEffect} from 'react';
import {
    ClearIcon,
    FilterIcon,
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import {Button, LoadingSpinner} from 'components/UI';
import {observer, Observer, useLocalStore} from 'mobx-react-lite';
import {_get, _includes, _toPairs, useInjectStores} from 'utils/utils';
import {autorun} from 'mobx';
import styled from "styled-components/macro";

const Fees = observer(function UncollectedSales(props) {
    const {filterStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency,
        formatNumber = utilsStore.formatNumber,
        getTotalsPercentage = utilsStore.getTotalsPercentage;
    const totalCnt = _get(props.widgetData, 'totals.tot_unsucc_ret_cat_cnt', 0);
    const totalAmt = _get(props.widgetData, 'totals.tot_unsucc_ret_cat_amt', 0);
    const txnFilter = filterStore.transactionFilter;

    const store = useLocalStore(() => ({}));

    useEffect(
        () =>
            autorun(async () => {
                if (store.resetCurrentLocalFilter) {
                    store.currentLocalFilter = '';
                }
                if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== txnFilter.category) {
                    store.currentLocalFilter = txnFilter.category;
                }
            }),
        []
    );

    return (
        <Observer>
            {() => (
                <TableWrapper height="100%">
                    <TableHeader>FEES ASSESSMENT</TableHeader>
                    {props.isLoading && <LoadingSpinner size={'200px'}/>}
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {!props.isLoading && (
                                    <Fragment>
                                        <TableRow>
                                            <HeaderCell textAlign={'left'}>Fee Category</HeaderCell>
                                            <HeaderCell textAlign={'right'}>Amount</HeaderCell>
                                            {/*<HeaderCell>Notes</HeaderCell>*/}
                                        </TableRow>
                                        {props.widgetData?.length > 0 && (
                                            <Fragment>

                                                {props.widgetData.map((detail, index) => (
                                                    <Fragment key={1}>
                                                        <TableRow>
                                                            <TableCell textAlign={'left'}>Gateway</TableCell>
                                                            <TableCell
                                                                textAlign={'right'}>{toCurrency(detail.gateway_fees)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell textAlign={'left'}>Auth & Settlement</TableCell>
                                                            <TableCell
                                                                textAlign={'right'}> {toCurrency(detail.auth_sttlmt_fees)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell textAlign={'left'}>Risk Assessment</TableCell>
                                                            <TableCell
                                                                textAlign={'right'}>{toCurrency(detail.risk_assessmt_fees)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell textAlign={'left'}>Chargeback
                                                                penalties</TableCell>
                                                            <TableCell
                                                                textAlign={'right'}>{toCurrency(detail.chgbk_penalty_fees)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell textAlign={'left'}>Monthly Statement &
                                                                Services</TableCell>
                                                            <TableCell
                                                                textAlign={'right'}>{toCurrency(detail.mo_stmt_fees)}</TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                ))}
                                            </Fragment>
                                        )}
                                        <TableRow>
                                            <TableCell showTotalsLine/>
                                            <TableCell textAlign={'right'}
                                                       showTotalsLine>{toCurrency(props.store.feesTotals.amt)}</TableCell>
                                        </TableRow>
                                    </Fragment>
                                )}

                            </TableBody>
                        </Table>
                        <br/>
                        <div css={`font-size: 12px;
                          padding: 0 10px`}>Notes:
                        </div>
                        <div css={`font-size: 12px;
                          padding: 0 10px; display: flex; gap: 2px;`}>
                            <span>*</span> <p style={{margin: '0px'}}>Gateway fees are usually from third-parties and are unrelated to payment processing and
                            settlement services.</p>
                        </div>
                        <div css={`font-size: 12px;
                          padding: 0 10px; display: flex; gap: 2px;`}>
                            <span>*</span> <p style={{margin: '0px'}}>Most Auth & Settlement fees are created by, controlled by, and paid to Visa, MasterCard,
                            Discover, & AMEX.</p>
                        </div>
                        <div css={`font-size: 12px;
                          padding: 0 10px; display: flex; gap: 2px;`}>
                            <span>*</span> <p style={{margin: '0px'}}>Risk fees occur when your return ratio is too high for the industry’s comfort and/or your
                            MCC industry is monitored by the industry.</p>
                        </div>
                        <div css={`font-size: 12px;
                          padding: 0 10px; display: flex; gap: 2px;`}>
                            <span>*</span> <p style={{margin: '0px'}}>Chargeback penalties occur when your customer disputes your transactions; and are not transactional fees tied to processing.</p>
                        </div>
                        <div css={`font-size: 12px;
                          padding: 0 10px; display: flex; gap: 2px;`}>
                            <span>*</span> <p style={{margin: '0px'}}>Monthly statement fees are non-transactional. They cover required costs by the industry for
                            each open MID you have, such as: PCI compliance, regulatory, tax prep, etc. If you are
                            renting or leasing equipment those costs will typically be in this category.</p>
                        </div>

                        <br/>
                        <StyledNote>
                            <span className="note">*</span>
                            <span className="noteBody">When filtering date range that does not include the end of the month, then fee ratios will be skewed as there are some fees that are not added until the end of the month.
                            </span>
                        </StyledNote>
                    </TableContainer>


                </TableWrapper>
            )}
        </Observer>
    );
});

export default Fees;

const StyledNote = styled.div`
  //display: flex;
  //flex-direction: column;
  height: 100%;
  //justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  padding: 0 10px;

  .note {
    color: red;
  }

  .noteBody {
    font-size: 12px;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;