import styled from 'styled-components/macro';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;
  justify-content: center;
  font-size: 18px;
  transition: all 1s;
  margin: 0 0 4px 0;
  width: 100%;
  a {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

const SortHandle = styled.div`
  cursor: move;
  margin: 0 3px 0 5px;
  color: ${(props) => (props.color ? props.color : props.theme.sidebar.colors.sortHandle)};
`;

const SortableLinkItem = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  transition: background-color, color 0.25s ease;
  background: ${({ active }) => (active ? '#b3d6fc' : '#032845')};
  border-top: 1px solid rgba(251,251,251, .2);
  // border: ${(props) => (props.active ? '1px solid rgba(251,251,251, .2)' : '')};
  //border-top: ${(props) => (props.show ? '1px solid rgba(251,251,251, .2)' : '')};
  
  .disabledLink {
    pointer-events: none;
    div {
      color: rgba(91, 91, 91);
      // color: ${(props) => (props.active ? 'black' : props.theme.sidebar.colors.linkText)};
    }
  }
  &:hover {
    //background: transparent;
    //border: ${(props) => (props.show ? '1px solid rgba(251,251,251, .2)' : '')};
    &:after {
      width: 100%;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #032845;
    transition: background-color 0.25s linear;
    z-index: -1;
  }
  &:after {
    width: 100%;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.63);
  //background: #b3d6fc
  background: ${(props) => (props.active ? '#b3d6fc' : '')};
  font-size: 17px;
  height: 60px;
  justify-content: center;
  width: 100%;
  color: ${(props) => (props.active ? 'black' : props.theme.sidebar.colors.linkText)};
  
`;

const SubItem = styled.div`
  display: flex;
  color: #445f64;
  background: ${(props) => (props.activeLink ? '#b3d6fc' : '')};
  width: 100%;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  align-content: center;
  transition: all 1s;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  div > {
    width: 100%;
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  div {
    margin-left: 10px;
    text-decoration: none;
    //color: rgba(255, 255, 255, 0.8);
    color: ${(props) => {
    return props.activeLink ? 'black' : 'rgba(255, 255, 255, 0.8)';

}};
    &:active {
      //color: #fff;
    }
    &:visited {
      //color: #fff;
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

const SideBarContainerHolder = styled.div`
  flex-direction: column;
  min-width: ${(props) => (props.show ? '200px' : '0')};
  width: ${(props) => (props.show ? '200px' : '0')};
  height: 100vh;
  justify-content: center;
  background: transparent;
  transition: all 1s;
  * > {
    transition: all 1s;
  }
`;

const SortableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.show ? props.height : '0')};
  overflow-y: hidden;
  transition: all 500ms;
  * > {
    transition: all 500ms;
  }
`;

export { SortableListWrapper, SortHandle, Item, SubItem, Label, SideBarContainerHolder, SortableLinkItem };
