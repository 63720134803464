import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import { _includes } from 'utils/utils';
import { filterOption } from '../../utils/filterUtils';
const { string, number, maybe, maybeNull, array } = types;

const CommonFilter = types
  .model({
    custName: maybe(string),
    email: maybe(string),
    clientCustID: maybe(number),
    eygCustID: maybe(number),
    ccBin: maybe(string),
    ccLast4: maybe(string),
    ccBrandID: maybe(number),
    midNum: maybeNull(array(string)),
  })
  .volatile((self) => ({
    initialState: {},
    clientCustIDError: false,
    eygCustIDError: false,
    ccBinError: false,
    ccLast4Error: false,
    ccBrandIDError: false,
    midIDError: false,
    clientMidOptions: [],
  }))
  .views((self) => ({}))
  .actions((self) => {
    function afterCreate() {
      self.initialState = getSnapshot(self);
    }
    function setModel(name, value) {
      self[`${name}Error`] = false;
      self[name] = value;
    }
    function setMids(mids) {
      self.midNum = mids;
    }
    function setClientMidOptions(clientMidOptions) {
      self.clientMidOptions = clientMidOptions;
    }
    function selectClientMids(obj) {
      let clientMidsForModel = [];
      let clientMidOptions = self.clientMidOptions;
      // if one is checked all is not checked
      if (obj.value === 'All') {
        clientMidOptions = clientMidOptions.map((opt) => {
          opt.checked = opt.value === 'All';
          return opt;
        });
        setMids(null);
      } else {
        //toggle option and get all check options
        clientMidOptions = clientMidOptions.map((opt) => {
          if (obj.value === opt.value) {
            opt.checked = !opt.checked;
          }
          if (opt.checked && opt.value !== 'All') {
            clientMidsForModel.push(opt.value);
          }
          return opt;
        });
        // if any checked options make sure all is not checked
        if (clientMidsForModel.length > 0) {
          clientMidOptions = clientMidOptions.map((opt) => {
            if (opt.value === 'All') {
              opt.checked = false;
            }
            return opt;
          });
        }
        // if there aren't any selected option make sure "All are checked"(but not set to the model for the pills)
        if (clientMidsForModel.length === 0) {
          clientMidOptions = clientMidOptions.map((opt) => {
            if (opt.value === 'All') {
              opt.checked = true;
            }
            return opt;
          });
        }
      }
      setMids(clientMidsForModel.length > 0 ? clientMidsForModel : null);
      setClientMidOptions(clientMidOptions);
    }

    function filterClientMids(value) {
      let { optsForModel, mappedOptionsArray } = filterOption(self.clientMidOptions, value);
      setMids(optsForModel.length > 0 ? optsForModel : null);
      setClientMidOptions(mappedOptionsArray);
      setClientOptionsFromModel()
    }
    function setClientOptionsFromModel() {
      let propsToCheck = self.midNum;
      let midNumOptions = self.clientMidOptions;
      if (propsToCheck) {
        midNumOptions = midNumOptions.map((option) => {
          option.checked = _includes(propsToCheck, option.value);
          return option;
        });
      } else {
        midNumOptions = midNumOptions.map((option) => {
          option.checked = option.value === 'All';
          return option;
        });
      }
      setClientMidOptions(midNumOptions);
    }
    function setAmt(name, amt) {
      applySnapshot(self[name], amt);
    }
    function reset(name, value) {
      self[name] = value;
    }
    function setError(name) {
      self[name] = true;
    }
    function resetErrors() {
      self.clientCustIDError = false;
      self.eygCustIDError = false;
      self.ccBinError = false;
      self.ccLast4Error = false;
      self.ccBrandIDError = false;
      self.midIDError = false;
    }
    function resetState() {
      applySnapshot(self, self.initialState);
    }

    return {
      afterCreate,
      setModel,
      setMids,
      setClientMidOptions,
      selectClientMids,
      filterClientMids,
      setClientOptionsFromModel,
      setAmt,
      reset,
      setError,
      resetErrors,
      resetState,
    };
  });

export default CommonFilter;
