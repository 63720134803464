import {flow, getRoot, types} from 'mobx-state-tree';
import {commonService, widgetService} from 'services';
import {_get, _cloneDeep, _isEqual, removeEmptyValues} from 'utils/utils';
import Moment from 'moment';

const DataManagementStore = types
    .model({})
    .volatile((self) => ({
        loadingTxnDetails: false,
        txnDetails: null,
        /**Filter Check**/
        lastQuery: {},
        newQuery: null
    }))
    .views((self) => ({
        /***Store References***/
        get rootStore() {
            return getRoot(self);
        },
        /** views **/
        get currentRoute() {
            return self.rootStore.currentRoute;
        },
        get userID() {
            return self.rootStore.userStore.user.email;
        },
        get clientID() {
            // console.log('self.rootStore.userStore.user', self.rootStore.userStore)
            // let root = getRoot(self);
            // console.log('self.rootStore.userStore.user', self.rootStore.userStore.user.currentClient.clientName)
            return self.rootStore.userStore.user.currentClient.id;
        },
        get currentFilterData() {
            return self.rootStore.filterStore.currentFilterData;
        },
        get dateFilter() {
            return self.rootStore.filterStore.dateFilter;
        },
        get startDt() {
            return self.dateFilter.formatStartDt;
        },
        get endDt() {
            return self.dateFilter.formatEndDt;
        },
        get dataType() {
            return self.rootStore.userStore.dataType;
        },
        get txnQuery() {
            let startDt = self.dateFilter.dateType === 'txnDt' ? 'startDt' : 'startStmtDt';
            let endDt = self.dateFilter.dateType === 'txnDt' ? 'endDt' : 'endStmtDt';
            return {
                [startDt]: self.startDt,
                [endDt]: self.endDt,
                clientID: self.userID,
            };
        },
        get query() {
            let startDt = self.dateFilter.dateType === 'txnDt' ? 'startDt' : 'startStmtDt';
            let endDt = self.dateFilter.dateType === 'txnDt' ? 'endDt' : 'endStmtDt';
            return {
                granularity: self.dateFilter.granularity,
                dataType: self.dataType,
                clientID: self.clientID,
                [startDt]: self.startDt,
                [endDt]: self.endDt,
                ...self.currentFilterData,
            };
        },
        get notify() {
            return self.rootStore.uiStore.notify;
        },
    }))
    .actions((self) => {
        function setNewQuery() {
            let newQuery = _cloneDeep(self.newQuery);
            let currentQuery = _cloneDeep(self.query);
            if (newQuery) {
                newQuery = removeEmptyValues(newQuery);
            }
            currentQuery = removeEmptyValues(currentQuery);
            if (self.userID && !_isEqual(newQuery, currentQuery)) {
                self.newQuery = currentQuery;
            }
        }

        const fetchWidgetData = flow(function* fetchWidgetData(id, granularity) {
            const filter = _cloneDeep(removeEmptyValues(self.newQuery));
            const post = {
                params: {
                    ...filter,
                    granularity,
                },
            };
            return yield commonService.getWidgetData(post);
        });
        const fetchDailyBatches = flow(function* fetchTransactionStmtData() {
            const filter = _cloneDeep(removeEmptyValues(self.newQuery));
            const post = {
                params: {
                    ...filter,
                },
            };
            return yield widgetService.acctDailyBatches(post);
        });
        const fetchDailyDepositStmtData = flow(function* fetchDailyDepositStmtData() {
            const filter = _cloneDeep(removeEmptyValues(self.newQuery));
            const post = {
                params: {
                    ...filter,
                },
            };
            return yield widgetService.acctDailyDeposits(post);
            // return yield widgetService.acctDeposits();
        });
        const fetchTsysStatement = flow(function* fetchTsysStatement(mid, date, id) {
            const filter = _cloneDeep(removeEmptyValues(self.newQuery));
            const post = {
                params: {
                    ...filter,
                    startDt: Moment(date).startOf('month').format(),
                    endDt: Moment(date).endOf('month').format(),
                    mid,
                    stmtID: id,
                },
            };
            return yield widgetService.accountingTsysRawStatement(post);
        });

        const fetchTsysStatementList = flow(function* fetchTsysStatement() {
            const filter = _cloneDeep(removeEmptyValues(self.newQuery));
            const post = {
                params: {
                    ...filter,
                },
            };
            return yield widgetService.accountingTsysListStatement(post);
        });
        const resetTxnDetails = () => {
            self.txnDetails = null;
        }
        const fetchTransaction = flow(function* fetchWidgetData(txnID, getTxn) {
            self.loadingTxnDetails = true;

            let post = {
                txnID,
                startDt: self.startDt,
                endDt: self.endDt,
                clientID: self.clientID,
            };
            try {
                const res = yield getTxn({params: post});
                self.txnDetails = res.data.values[0];
            } catch (e) {
                console.log('error', e);
            }
            self.loadingTxnDetails = false;
        });
        const setTxnDetails = txnDetails => {
            self.txnDetails = txnDetails;
        };
        const fetchUserReports = flow(function* fetchUserReports() {
            const filter = _cloneDeep(removeEmptyValues(self.newQuery));
            const post = {
                params: {
                    ...filter,
                },
            };
            return yield widgetService.userReports(post);
        });
        const setArchiveData = flow(function* setArchiveData() {
            // self.dateFilter.setStartDt(Moment().startOf('year').subtract(2, 'years').toDate());
            // self.dateFilter.setEndDt(Moment().startOf('year').subtract(1, 'years').toDate());
            return yield widgetService.setHistoricalData({clientID: self.clientID});
        });
        const setCurrentData = flow(function* setCurrentData() {
            return yield widgetService.setCurrentData({clientID: self.clientID});
        });
        return {
            setNewQuery,
            fetchWidgetData,
            fetchDailyBatches,
            fetchDailyDepositStmtData,
            fetchTsysStatement,
            fetchTsysStatementList,
            fetchTransaction,
            setTxnDetails,
            resetTxnDetails,
            fetchUserReports,
            setArchiveData,
            setCurrentData
        };
    });

export default DataManagementStore;
