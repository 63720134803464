import React, { Fragment } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { Button, PopupMenu } from 'components/UI';
import { BookmarkIcon } from 'styles/styledComponents';
import { SubmitButton } from '../../StyledFilter';

const SavedPrebuiltFilters = observer((props) => {
  const showPreBuiltFilterPopup = props.showPreBuiltFilterPopup,
    toggleShowPreBuiltFilter = props.toggleShowPreBuiltFilter,
    handleKeyDown = props.handleKeyDown,
    hideFilter = props.hideFilter,
    hidePreBuiltFilterPopup = props.hidePreBuiltFilterPopup,
    applyForcedVoidsFilter = props.applyForcedVoidsFilter;

  return (
    <Observer>
      {() => (
        <Fragment>
          {!hideFilter && (
            <Button
              type="secondary"
              id="filterBtn"
              width="35px"
              height="36px"
              margin="1px 0 0 5px"
              onClick={toggleShowPreBuiltFilter}
            >
              <BookmarkIcon id="filterBtn" style={{ width: 30, height: 33 }} />
            </Button>
          )}
          {showPreBuiltFilterPopup && (
            <PopupMenu
              show={showPreBuiltFilterPopup}
              hidePopup={hidePreBuiltFilterPopup}
              top={'49px'}
              width={'180px'}
              left={202}
              marginTop={30}
              onKeyDown={handleKeyDown}
            >
              <div className="arrowUp" />
              <div className={'filterContainer'}>
                {/*<div className="title">Saved Filters</div>*/}
                {/*<SubmitButton type="submit" onClick={applyForcedVoidsFilter}>
                My Filter
              </SubmitButton>*/}
              </div>
              <div className={'filterContainer'}>
                <div className="title">Prebuilt Filters</div>
                <SubmitButton type="submit" onClick={applyForcedVoidsFilter}>
                  Forced Voids
                </SubmitButton>
                <SubmitButton type="submit" onClick={applyForcedVoidsFilter}>
                  Reversal Voids
                </SubmitButton>
              </div>
            </PopupMenu>
          )}
        </Fragment>
      )}
    </Observer>
  );
});

export default SavedPrebuiltFilters;
