import { _map, _includes } from 'utils/utils';

export function createOptions(options, text, transformText) {
  if (options) {
    return _map(options, (option) => {
      return {
        value: option,
        text: transformText ? transformText(option) : option,
        key: option,
        disable: false,
      };
    });
  }
  return [];
}

export function createAggrOptions(options, text) {
  if (options) {
    // let aggrs = [5, 14, 29, 64, 86, 148, 150, 152, 154];
    let aggrs = [5, 14, 39, 64, 86, 148];
    let filteredList = [];
    let list = _map(options, (option) => {
      if (_includes(aggrs, option.id)) {
        filteredList.push({
          value: option.id,
          text: option[text],
          // text: `${option[text]} (${option.typesname})`,
          key: option.id,
          // aggrWhositID: option.aggrWhositID,
          disable: false,
        });
      }
    });
    return filteredList;
  }
  return [];
}
/*

function useData(props) {
  return useObserver(() => ({
    allClientOptions: createOptions(_get(props, 'clients'), 'clientName'),
    pricingPlanOptions: createOptions(_get(props, 'pricingPlans', 'desc')),
    datasourceOptions: createOptions(_get(props, 'dataSources'), 'sname'),
    rolesOptions: createOptions(_get(props, 'roles'), 'desc'),
    aggrOptions: createaggrOptions(_get(props, 'aggrs'), 'desc'),
    currencyOptions: createOptions(_get(props, 'currency'), 'iso3'),
    users: _get(props, 'users'),
    clients: _get(props, 'clients'),
  }));
}
*/

export const initialData = {
  billDts: [],
  clients: [],
  contTypes: [],
  dataSources: [],
  feeCats: [],
  freqs: [],
  pymtTypes: [],
  roles: [],
  users: [],
};
