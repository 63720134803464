import React, { Fragment, useEffect } from 'react';
import { Error, LoadingSpinner } from 'components/UI';
import styled from 'styled-components/macro';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import {
  Divider,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  WidgetWrapper
} from 'styles/styledComponents';
import { _get, _round, useGetWidgetData, useInjectStores } from 'utils/utils';
import { widgetService } from '../../../services';

let widgetConstants = { name: 'ssStatistics', id: 11 };

const SsStatistics = observer(() => {
  const { dataManagementStore, utilsStore } = useInjectStores();
  const { id } = widgetConstants;
  const toCurrency = utilsStore.toCurrency;

  const store = useLocalObservable(() => ({
    loading: false,
    widgetData: null,
    setWidgetData(widgetData) {
      store.error = null;
      const {status, data, error} = widgetData;
      if (status === 'loading') this.loading = true;
      if (error) {
        this.error = error;
        this.loading = false;
      }
      if (data) {
        store.widgetData = data;
        this.loading = false;
      }
    },
    get toReturn() {
      return (
        this.widgetData?.stats || [
          {
            Chgbk_hist_avg_tkt: 0,
            Chgbk_var_avg_tkt: 0,
            Chgbk_var_ratio: 0,
            Decl_hist_avg_tkt: 0,
            Decl_var_avg_tkt: 0,
            Decl_var_ratio: 0,
            Mid: 0,
            Ref_hist_avg_tkt: 0,
            Ref_var_avg_tkt: 0,
            Ref_var_ratio: 0,
            Rvsl_var_ratio: 0,
            Sale_hist_avg_tkt: 0,
            Sale_var_avg_tkt: 0,
            Sale_var_ratio: 0,
            Salervsl_hist_avg_tkt: 0,
            Salervsl_var_avg_tkt: 0
          }
        ]
      );
    },
    get avgs() {
      return (
        this.widgetData?.average || [
          {
            hist_days_to_fund: 0,
            hist_days_to_settle: 0,
            num_days_to_fund: 0,
            num_days_to_settle: 0
          }
        ]
      );
    },

    get varToHistFund() {
      let variance = _round(100 * _get(this.avgs, 'num_days_to_fund') - 100 * _get(this.avgs, 'hist_days_to_fund'), 2);
      if (variance) {
        if (variance < 1) {
          return `- ${Math.abs(variance)}%`;
        }
        return `+ ${Math.abs(variance)}%`;
      } else {
        return '-';
      }
    },
    get varToHistSettle() {
      let variance = _round(100 * _get(this.avgs, 'num_days_to_settle') - 100 * _get(this.avgs, 'hist_days_to_settle'), 2);
      if (variance) {
        if (variance < 1) {
          return `- ${Math.abs(variance)}%`;
        }
        return `+ ${Math.abs(variance)}%`;
      } else {
        return '-';
      }
    },
  }));

  const statsData = useGetWidgetData(
    { name: 'dashboardStatistics', query: widgetService.dashboardStatistics },
    'data',
    dataManagementStore?.newQuery
  );

  useEffect(() => {
    store.setWidgetData(statsData);
  }, [statsData]);

  return (
    <Observer>
      {() => (
        <Wrapper>
          <WidgetWrapper height="100%">
            <TableHeader>Statistics</TableHeader>
            <Divider />
            {store.loading ? <LoadingSpinner size={'200px'} /> : store.error && <Error error={store.error} />}
            <Table>
              <TableBody>
                {!store.loading && store.widgetData && (
                  <Fragment>
                    <TableRow>
                      <HeaderCell></HeaderCell>
                      <HeaderCell>Date Range Selected</HeaderCell>
                      <HeaderCell>Historic</HeaderCell>
                      <HeaderCell>Var to Hist</HeaderCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg: Sales Approved</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'sale_var_avg_tkt')) || '-'}</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'sale_hist_avg_tkt')) || '-'}</TableCell>
                      <TableCell negative={false}>{_get(store.toReturn, 'sale_var_ratio') || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg: Declines & Errors</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'decl_var_avg_tkt')) || '-'}</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'decl_hist_avg_tkt')) || '-'}</TableCell>
                      <TableCell negative={false}>{_get(store.toReturn, 'decl_var_ratio') || '-'} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg: Cancelled Sales</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'salervsl_var_avg_tkt')) || '-'}</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'salervsl_hist_avg_tkt')) || '-'}</TableCell>
                      <TableCell negative={false}>{_get(store.toReturn, 'rvsl_var_ratio') || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg: Refunds</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'ref_var_avg_tkt')) || '-'}</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'ref_hist_avg_tkt')) || '-'}</TableCell>
                      <TableCell negative={false}>{_get(store.toReturn, 'ref_var_ratio') || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg: Chargebacks</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'chgbk_var_avg_tkt')) || '-'}</TableCell>
                      <TableCell>{toCurrency(_get(store.toReturn, 'chgbk_hist_avg_tkt')) || '-'}</TableCell>
                      <TableCell negative={false}>{_get(store.toReturn, 'chgbk_var_ratio') || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <HeaderCell>&nbsp;</HeaderCell>
                      <HeaderCell>&nbsp;</HeaderCell>
                      <HeaderCell>&nbsp;</HeaderCell>
                      <HeaderCell>&nbsp;</HeaderCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg Days: Auth to Settle</TableCell>
                      <TableCell>{_round(_get(store.avgs, 'num_days_to_settle'), 1) || '-'}</TableCell>
                      <TableCell>{_round(_get(store.avgs, 'hist_days_to_settle'), 1) || '-'}</TableCell>
                      <TableCell negative={false}>{store.varToHistSettle || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Avg Days: Auth to Fund</TableCell>
                      <TableCell>{_round(_get(store.avgs, 'hist_days_to_fund'), 1) || '-'}</TableCell>
                      <TableCell>{_round(_get(store.avgs, 'num_days_to_fund'), 1) || '-'}</TableCell>
                      <TableCell negative={false}>{store.varToHistFund || '-'}</TableCell>
                    </TableRow>
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </WidgetWrapper>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  grid-area: SsStatistics;
  color: ${({ theme }) => theme.colors.primaryText};
  .reportWrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px 5px;
  }
  .spaceBtwn {
    padding: 4px;
    margin: 0px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }
  .row {
    display: flex;
  }
`;

export default SsStatistics;
