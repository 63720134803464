import React from 'react';
import { WidgetBackground } from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

const Tabs = ({permissions}) => {
    const { url } = useRouteMatch();
    const location = useLocation();
    return (
        <div className="tabWrapper">
            {permissions.dshbrd_pg_graph_crlim === "Checked" ? (
              <Link className={`tab one ${!location.pathname.includes('aver') && !location.pathname.includes('top8') && !location.pathname.includes('trends')  ? 'active' : ''}`}  to={`${url}`}>
                Graph
              </Link>
            ) : null}
          {permissions.dshbrd_pg_avgs === "Checked" ? (
            <Link className={`tab two ${location.pathname.includes('averages') ? 'active' : ''}`}  to={`${url}/averages`}>
                Averages
            </Link>
          ) : null}
           {/* <Link className={`tab three ${location.pathname.includes('top8') ? 'active' : ''}`} to={`${url}/top8`}>
                Top 8
            </Link>*/}
          {permissions.dshbrd_pg_trends === "Checked" ? (
            <Link className={`tab four ${location.pathname.includes('trends') ? 'active' : ''}`} to={`${url}/trends`}>
                Trends
            </Link>
          ) : null}
           {/* <div className="three">
                {location.pathname.includes('noShip') && (
                {location.pathname.includes('') && (
                    <WidgetBackground>
                        <StyledNote>
              <span>
                <span className="note">* Note: </span>
                <span className="noteBody">
                  If an order in your fulfillment system is not listed below, then it indicates that no attempt was made in the
                  credit card industry for payment.
                </span>
              </span>
                        </StyledNote>
                    </WidgetBackground>
                )}
            </div>*/}
        </div>
    );
};

export default Tabs;

const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  padding: 0 10px;
  .note {
    color: red;
  }
  .noteBody {
    font-style: italic;
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;
