import React, {useEffect} from 'react';
/** Styled Components Specific to Client Data Mgmt **/
import {Observer, useLocalStore, observer} from 'mobx-react-lite';
import {useInjectStores, _get, _has, _set} from 'utils/utils';
import styled from 'styled-components/macro';
import {autorun} from 'mobx';
import {useHistory, useLocation} from 'react-router-dom';
import {TableHeader, TableWrapper, TableHeaderButton} from 'styles/styledComponents';
import {AgGridReact} from 'ag-grid-react';
import {Dropdown, LoadingSpinner} from 'components/UI';

function getSimpleCellRenderer() {
    function SimpleCellRenderer() {
    }

    SimpleCellRenderer.prototype.init = function (params) {
        var tempDiv = document.createElement('div');
        if (params.node.group) {
            tempDiv.innerHTML = '<span>' + params.value + ' (Mult. Trace Num)- ' + '</span>';
            // '<span style="border-bottom: 1px solid grey; border-left: 1px solid grey; padding: 2px;">' + params.value + '</span>';
        } else {
            tempDiv.innerHTML = '<span>' + params.value + '</span>';
            // '<span><img src="https://flags.fmcdn.net/data/flags/mini/ie.png" style="width: 20px; padding-right: 4px;"/>' +
            // params.value +
            // '</span>';
        }
        this.eGui = tempDiv.firstChild;
    };
    SimpleCellRenderer.prototype.getGui = function () {
        return this.eGui;
    };
    return SimpleCellRenderer;
}

function cellStyleNeg(params) {
    if (params.value && params.value < 0) {
        return {color: '#FF2B49'};
    }
}

const DailyBatches = observer((props) => {
    const history = useHistory();
    const location = useLocation();
    const {dataManagementStore, userStore, utilsStore} = useInjectStores();
    const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
    const toDateFormatterValue = utilsStore.toDateFormatter;
    const initialDataDeposit = {
        values: [
            {
                totals: false,
                batch_dt: "",
                deposit_dt: "",
                batch_num: "",
                batch_seq_num: 0,
                bat_period: "",
                bank_acct_mskd: "",
                dep_type: "",
                batch_type: "",
                batch_card_brand: "",
                brand_level: "",
                batch_amts: 0,
                chgbk_case_num: null,
            },
        ]
    };
    const store = useLocalStore(() => ({
        isLoadingDeposit: false,
        widgetDataDeposit: initialDataDeposit,

        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            store.exportType = type;
        },
        apiDep: null,
        get treeData() {
            const dictionary = {};
            let id_index = 1;
            let testData = store.widgetDataDeposit;
            for (let i = 0; i < testData.length; i++) {
                let newFSBCheck = `${testData[i].batch_dt}$-${testData[i].batch_num}`;
                let currentDeposit = testData[i];

                if (!_has(dictionary, newFSBCheck)) {
                    _set(dictionary, newFSBCheck, {
                        topLevel: {
                            ...testData[i],
                            topLevel: true,
                            id: id_index,
                            keyPath: [newFSBCheck],
                        },
                        batch_num: [],
                    });
                    id_index += 1;
                } else {
                    if (dictionary[newFSBCheck].batch_num.length === 0) {
                        dictionary[newFSBCheck].batch_num.push({
                            ...dictionary[newFSBCheck].topLevel,
                            keyPath: [newFSBCheck, `batch#${1}`],
                            id: id_index,
                        });
                        id_index += 1;
                    }
                    ///////// add deposit amount to toplvl
                    dictionary[newFSBCheck].topLevel = {
                        ...dictionary[newFSBCheck].topLevel,
                        batch_num: 'Multiple',
                        batch_amts: (dictionary[newFSBCheck].topLevel.batch_amts += currentDeposit.batch_amts),
                    };
                    ///////////
                    dictionary[newFSBCheck].batch_num.push({
                        ...testData[i],
                        id: id_index,
                        keyPath: [newFSBCheck, `batch#${dictionary[newFSBCheck].batch_num.length + 1}`],
                    });
                    id_index += 1;
                }
            }
            let toReturn = [];
            for (const key in dictionary) {
                toReturn = [...toReturn, dictionary[key].topLevel, ...dictionary[key].batch_num];
            }
            return toReturn;
        },

        get gridOptionsDep() {
            return {
                columnDefs: [
                    {
                        headerName: 'Batch Date',
                        field: 'batch_dt',
                        valueFormatter: toDateFormatterValue,
                        width: 160,
                        cellRenderer: 'agGroupCellRenderer',
                        cellRendererParams: {
                            suppressCount: true,
                        },
                        // cellRenderer: 'agGroupCellRenderer',
                    },
                    {
                        headerName: 'Deposit Date', field: 'deposit_dt',
                        valueFormatter: toDateFormatterValue,
                        // cellRenderer: 'agGroupCellRenderer',
                        // cellRendererParams: {
                        //     suppressCount: true,
                        // },
                        width: 140
                    },
                    {
                        headerName: 'TSYS Batch',
                        field: 'batch_num',
                        cellRenderer: 'agGroupCellRenderer',
                        width: 160
                    },
                    {headerName: 'Batch Seq Num', field: 'batch_seq_num', width: 160},
                    {headerName: 'Batch Period', field: 'bat_period', width: 120},
                    {headerName: 'Bank Acct', field: 'bank_acct_mskd', width: 140},
                    {
                        headerName: 'Dep Type',
                        field: 'dep_type',
                        // cellStyle: cellStyleNeg,
                        // valueFormatter: toCurrencyFormatterWithUndefined,
                        width: 130,
                    },
                    {
                        headerName: 'Batch Type',
                        field: 'batch_type',
                        // cellStyle: cellStyleNeg,
                        // valueFormatter: toCurrencyFormatterWithUndefined,
                        width: 140,
                    },
                    {
                        headerName: 'Card Brand',
                        field: 'batch_card_brand',
                        // cellStyle: cellStyleNeg,
                        // valueFormatter: toCurrencyFormatterWithUndefined,
                        width: 140,
                    },
                    {
                        headerName: 'Brand Level',
                        field: 'brand_level',
                        // cellStyle: cellStyleNeg,
                        // valueFormatter: toCurrencyFormatterWithUndefined,
                        width: 140,
                    },
                    {
                        headerName: 'Batch Amounts',
                        field: 'batch_amts',
                        valueFormatter: toCurrencyFormatter,
                        cellStyle: cellStyleNeg,
                        type: 'rightAligned',
                        // cellStyle: cellStyleNeg,
                        // valueFormatter: toCurrencyFormatterWithUndefined,
                        width: 140,
                    },
                    {
                        headerName: 'Chgbk Case Num',
                        field: 'chgbk_case_num',
                        // cellStyle: cellStyleNeg,
                        // valueFormatter: toCurrencyFormatterWithUndefined,
                        width: 140,
                    },
                ],
                components: {simpleCellRenderer: getSimpleCellRenderer()},
                animateRows: true,
                enableBrowserTooltips: true,
                tooltipShowDelay: 0,
                reactNext: true,
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                groupSuppressAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                rowClassRules: {
                    level0: function (params) {
                        return params.data.trace_num === 'multiple' || params.data.isLevel2 === false;
                    },
                },
                groupRemoveSingleChildren: true,
                cellRendererParams: {
                    suppressCount: true, // turn off the row count
                },
                getDataPath: function (data) {
                    return data.keyPath;
                },
                getRowNodeId: function (data) {
                    return data.id;
                },
                masterDetail: true,
                defaultColDef: {
                    sortable: false,
                    resizable: true,
                },
                onGridReady: (params) => {
                    store.apiDep = params.api;
                    store.gridColumnApiDep = params.columnApi;
                },
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);"> Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },
        async getData() {
            store.isLoadingDeposit = true;
            try {
                let res = await dataManagementStore.fetchDailyBatches();
                // store.widgetData = res.data.values;
                store.widgetDataDeposit = res.data.values;
            } catch (error) {
                console.error('err:', error);
                store.widgetDataDeposit = initialDataDeposit;
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    userStore.logout(history, location);
                }
            }
            store.isLoadingDeposit = false;
            if (store.apiDep && !store.isLoadingDeposit) {
                store.apiDep.hideOverlay();
            }
            if (store.apiDep && !store.isLoadingDeposit && store.widgetDataDeposit.length === 0) {
                store.apiDep.showNoRowsOverlay();
            }
        },
    }));

    useEffect(
        () =>
            autorun(async () => {
                if (dataManagementStore.newQuery) {
                    await store.getData();
                }
            }),
        []
    );

    const updatedTreeData = store.treeData.map((data) => {
        if(data.batch_num === 'Multiple') {
            return { ...data, brand_level: '-', batch_card_brand: '-' };
        } else {
            return data;
        }
    })
    
    return (
        <Observer>
            {() => (
                <TableWrapper width="100%" height="100%">
                    <TableHeader
                        width={'auto'}
                        justify={'space-between'}
                        backgroundColor={'#041c2f'}
                        warning
                        fontSize="12px"
                        padding={'10px 20px'}
                        textAlign="start"
                    >
                        {store.isLoadingDeposit && <LoadingSpinner size={'200px'}/>}
                        {!store.isLoadingDeposit && (
                            <StyledNote>
                                <span className="note">* </span>
                                <span className="noteBody">
                  The data is filtered by the Batch Date (we do not control the date your bank posts the deposit/withdrawal to
                  your account).
                </span>
                            </StyledNote>
                        )}
                        {!store.isLoadingDeposit && store.widgetDataDeposit && store.widgetDataDeposit.length > 0 && (
                            <div
                                css={`
                                  display: flex;
                                `}
                            >
                                <Dropdown
                                    className="exportDropdown"
                                    caretRight={10}
                                    options={store.exportOptions}
                                    onChange={store.setExportType}
                                    value={store.exportType}
                                    width="80px"
                                    height="25px"
                                    margin="0"
                                    border
                                />
                                <TableHeaderButton
                                    height="27px"
                                    onClick={() => {
                                        if (store.exportType === 'csv') {
                                            store.apiDep.exportDataAsCsv();
                                        } else {
                                            store.apiDep.exportDataAsExcel();
                                        }
                                    }}
                                >
                                    Export
                                </TableHeaderButton>
                            </div>
                        )}
                    </TableHeader>
                    <Wrapper>
                        {!store.isLoadingDeposit && store.widgetDataDeposit && store.widgetDataDeposit.length > 0 && (
                            <div
                                id="myGrid"
                                className={'ag-theme-alpine-dark'}
                                style={{
                                    height: '100%',
                                    minHeight: '275px',
                                    width: '100%',
                                    position: 'relative',
                                }}
                            >
                                <AgGridReact
                                    gridOptions={store.gridOptionsDep}
                                    rowHeight={33}
                                    rowData={updatedTreeData}
                                    treeData={true}
                                    getDataPath={store.getDataPath}
                                    getRowNodeId={store.getRowNodeId}
                                    onGridReady={store.onGridReady}
                                />
                            </div>
                        )}
                        {!store.isLoadingDeposit && store.widgetDataDeposit && store.widgetDataDeposit.length === 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    minHeight: '400px',
                                    color: '#FFF',
                                }}
                            >
                                No data for this time period
                            </div>
                        )}
                    </Wrapper>
                </TableWrapper>
            )}
        </Observer>
    );
});

export {DailyBatches};

const Wrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;

    .ag-cell {
      line-height: 30px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .rowTotals {
      border-top-color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
    }

    .ag-row-level-0 {
      background-color: #001329 !important;
    }

    .ag-row-level-1 {
      background-color: #032845 !important;
    }
  }
`;
const StyledNote = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection || 'row'};
  height: 100%;
  justify-content: ${({flexDirection}) => (flexDirection === 'column' ? 'flex-end' : 'flex-start')};
  align-items: center;
  font-size: 10px;
  padding: ${({padding}) => padding};
  font-style: italic;

  .note {
    font-size: 12px;
    color: red;
  }

  .noteBody {
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;
