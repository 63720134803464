class UserData {
	constructor(user) {
		this.clientID = user.clientID;
		this.userNm = user.firstName + ' ' + user.lastName;
		this.userCellPh = user.phone || '';
		this.usersEmail = user.email;
		this.userRole = user.role;
		this.pricingModelNm = user.currentPackageLevel;
		this.repsAllowedSname = user.permissions.filter(perm => perm.repSname && perm.checked).map(perm => perm.checked ? perm.repSname : '');

		for(let permission of user.permissions) {
			switch(permission.name) {
				case 'Dashboard':
					this.dshbrdTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Graph & Credit Limit':
					this.dshbrdPgGraphCrlim = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Averages':
					this.dshbrdPgAvgs = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Trends':
					this.dshbrdPgTrends = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Add New Users':
					this.userAddUserPermsn = permission.disabled ? 'Unavailable' : (permission.checked && this.userRole !== 'User' ? 'Checked' : 'Unchecked');
					break;
				case 'Permission to Add Reports':
					this.userAddReps = permission.disabled ? 'Unavailable' : (permission.checked && this.userRole !== 'User' ? 'Checked' : 'Unchecked');
					break;
				case 'Permission to Upgrade':
					this.userUpgPermsn = permission.disabled ? 'Unavailable' : (permission.checked && this.userRole !== 'User' ? 'Checked' : 'Unchecked');
					break;
				case 'Accounting':
					this.acctgTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Daily Batches':
					this.acctgPgDlyBat = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Daily Deposits':
					this.acctgPgDlyDep = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Journals: Accrual':
					this.acctgPgJnlAccl = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Journals: Cash':
					this.acctgPgJnlCash = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Prepaid Billing':
					this.acctgPgPrepdBill = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'TSYS Statements':
					this.acctgPgTsysStmt = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				// case 'What is this?':
				// 	this.acctgPgEtStmt = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
				// 	break;
				case 'Transactions':
					this.txnsTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Reconciliation':
					this.txnsPgRecon = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Analytics':
					this.txnsPgAnalyt = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Sales Details':
					this.txnsPgSale = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Decline Details':
					this.txnsPgDecl = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Refund Details':
					this.txnsPgRef = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Chargeback Details':
					this.txnsPgChgbk = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Void Inquiries':
					this.txnsPgVoidinq = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Fees':
					this.feesTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Fees By Category':
					this.feesPgFeeByCat = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Opportunity':
					this.oppTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Analysis Summary':
					this.oppPgSum = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Opportunity Details':
					this.oppPgDtl = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Tax':
					this.taxTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case '1099k Analysis':
					this.taxPg1099k = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Financially Know Your Customer (FKYC) Database':
					this.fkycTab = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Archive Transaction Access (Data older than current year and previous year)':
					this.archiveAccess = permission.disabled ? 'Unavailable' : (permission.checked ? 'Checked' : 'Unchecked');
					break;
				case 'Administration':
					this.adminTab = permission.disabled ? 'Unavailable' : (permission.checked && this.userRole !== 'User' ? 'Checked' : 'Unchecked');
					break;
				
			}
		}
	}
}

export default UserData;