/*const defaultProps = {
  count: 0,
  totalAmt: 0,
  authTxnFees: 0,
  authOthFees: 0,
  dataCapTxnFees: 0,
  excFees: 0,
  debitNetFees: 0,
  ebtFees: 0,
  miscFixedFees: 0,
  intchgFees: 0,
  indPlanFees: 0,
  discFees: 0,
  cardBrndFees: 0
};*/

const defaultReconData = {
  presReturns: {
    ref: { count: 0, percent: 0, totalAmt: 0 },
    rev: { count: 0, percent: 0, totalAmt: 0 },
    chgbk: { count: 0, percent: 0, totalAmt: 0 },
    errRef: { count: 0, percent: 0, totalAmt: 0 },
    expRef: { count: 0, percent: 0, totalAmt: 0 },
    rejRef: { count: 0, percent: 0, totalAmt: 0 },
    adjWdrl: { count: 0, percent: 0, totalAmt: 0 },
    declRef: { count: 0, percent: 0, totalAmt: 0 },
    pendRef: { count: 0, percent: 0, totalAmt: 0 },
    voidRef: { count: 0, percent: 0, totalAmt: 0 },
    chgbkRet: { count: 0, percent: 0, totalAmt: 0 },
    chgbkRev: { count: 0, percent: 0, totalAmt: 0 },
    includes: [
      'adjWdrl',
      'chgbk',
      'chgbkRet',
      'chgbkRev',
      'declRef',
      'errRef',
      'expRef',
      'pendRef',
      'ref',
      'rejRef',
      'rev',
      'voidRef'
    ],
    totalAmt: 0,
    totalCount: 0
  },
  presSales: {
    inq: { count: 0, percent: 0, totalAmt: 0 },
    sale: { count: 0, percent: 0, totalAmt: 0 },
    adjDep: { count: 0, percent: 0, totalAmt: 0 },
    errSale: { count: 0, percent: 0, totalAmt: 0 },
    expSale: { count: 0, percent: 0, totalAmt: 0 },
    rejSale: { count: 0, percent: 0, totalAmt: 0 },
    declSale: { count: 0, percent: 0, totalAmt: 0 },
    includes: ['adjDep', 'declSale', 'errSale', 'expSale', 'inq', 'pendSale', 'rejSale', 'sale', 'voidSale'],
    pendSale: { count: 0, percent: 0, totalAmt: 0 },
    totalAmt: 0,
    voidSale: { count: 0, percent: 0, totalAmt: 0 },
    totalCount: 0
  },
  unsuccReturns: {
    errRef: { count: 0, percent: 0, totalAmt: 0 },
    expRef: { count: 0, percent: 0, totalAmt: 0 },
    rejRef: { count: 0, percent: 0, totalAmt: 0 },
    declRef: { count: 0, percent: 0, totalAmt: 0 },
    pendRef: { count: 0, percent: 0, totalAmt: 0 },
    includes: ['declRef', 'errRef', 'expRef', 'pendRef', 'rejRef'],
    totalAmt: 0,
    totalCount: 0
  },
  unsuccSales: {
    inq: { count: 0, percent: 0, totalAmt: 0 },
    errSale: { count: 0, percent: 0, totalAmt: 0 },
    expSale: { count: 0, percent: 0, totalAmt: 0 },
    rejSale: { count: 0, percent: 0, totalAmt: 0 },
    declSale: { count: 0, percent: 0, totalAmt: 0 },
    includes: ['declSale', 'errSale', 'expSale', 'inq', 'pendSale', 'rejSale'],
    pendSale: { count: 0, percent: 0, totalAmt: 0 },
    totalAmt: 0,
    totalCount: 0
  },
  netCollSales: 0,
  netSuccSales: 0,
  netDep: 0,
  adjDep: {
    count: 0,
    totalAmt: 0,
    desc: 'deposit adjustments',
    includes: 'txnTypeID = 9',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  adjWdrl: {
    count: 0,
    totalAmt: 0,
    desc: 'withdrawal adjustments',
    includes: 'txnTypeID = 12',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  chgbk: {
    count: 0,
    totalAmt: 0,
    desc: 'completed chargebacks (settle, fund)',
    includes: 'txnTypeID = 3 and txnStatusID = [1, 11]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  chgbkRet: {
    count: 0,
    totalAmt: 0,
    desc: 'completed chargeback retrievals (settle, fund)',
    includes: 'txnTypeID = 8 and txnStatusID = [1, 11]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  chgbkRev: {
    count: 0,
    totalAmt: 0,
    desc: 'completed chargeback reversals (settle, fund)',
    includes: 'txnTypeID = 7 and txnStatusID = [1, 11]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  declRef: {
    count: 0,
    totalAmt: 0,
    desc: 'declined refunds',
    includes: 'txnTypeID = 2 and txnStatusID = 2',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  declSale: {
    count: 0,
    totalAmt: 0,
    desc: 'declined sales',
    includes: 'txnTypeID = 1 and txnStatusID = 2',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  errRef: {
    count: 0,
    totalAmt: 0,
    desc: 'errored refunds',
    includes: 'txnTypeID = 2 and txnStatusID = 4',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  errSale: {
    count: 0,
    totalAmt: 0,
    desc: 'errored sales',
    includes: 'txnTypeID = 1 and txnStatusID = 4',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  expRef: {
    count: 0,
    totalAmt: 0,
    desc: 'expired refunds (expired, voided - dead txn, dead)',
    includes: 'txnTypeID = 2 and txnStatusID = [14, 28, 29]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  expSale: {
    count: 0,
    totalAmt: 0,
    desc: 'expired sales (expired, voided - dead txn, dead)',
    includes: 'txnTypeID = 1 and txnStatusID = [14, 28, 29]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  fees: {
    ebtFees: { percent: 0, totalAmt: 0 },
    excFees: { percent: 0, totalAmt: 0 },
    discFees: { percent: 0, totalAmt: 0 },
    totalAmt: 0,
    intchgFees: { percent: 0, totalAmt: 0 },
    authOthFees: { percent: 0, totalAmt: 0 },
    authTxnFees: { percent: 0, totalAmt: 0 },
    indPlanFees: { percent: 0, totalAmt: 0 },
    cardBrndFees: { percent: 0, totalAmt: 0 },
    debitNetFees: { percent: 0, totalAmt: 0 },
    miscFixedFees: { percent: 0, totalAmt: 0 },
    dataCapTxnFees: { percent: 0, totalAmt: 0 }
  },
  inq: {
    count: 0,
    totalAmt: 0,
    desc: 'all inquiries (balance and health care)',
    includes: 'txnTypeID = [10, 11]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  pendRef: {
    count: 0,
    totalAmt: 0,
    desc: 'pending refunds (authorized, pending)',
    includes: 'txnTypeID = 2 and txnStatusID = [7, 12]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  pendSale: {
    count: 0,
    totalAmt: 0,
    desc: 'pending sales (authorized, pending)',
    includes: 'txnTypeID = 1 and txnStatusID = [7, 12]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  ref: {
    count: 0,
    totalAmt: 0,
    desc: 'successful refunds (settle, partial, funded, capture, partial void, complete). also includes ach refund rejected',
    includes: '(txnTypeID = 2 and txnStatusID = 1, 8, 11, 26, 30, 31) or (pymtTypeID = 2 and txnTypeID = 2 and txnStatusID = 13)',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  rejRef: {
    count: 0,
    totalAmt: 0,
    desc: 'rejected refunds (suspended, blocked, rejected)',
    includes: 'txnTypeID = 2 and txnStatusID = [6, 9, 13]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  rejSale: {
    count: 0,
    totalAmt: 0,
    desc: 'rejected sales (suspended, blocked, rejected)',
    includes: 'txnTypeID = 1 and txnStatusID = [6, 9, 13]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  rev: {
    count: 0,
    totalAmt: 0,
    desc: 'all reversals',
    includes: 'txnTypeID = 6',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  sale: {
    count: 0,
    totalAmt: 0,
    desc: 'successful sales (settle, partial, funded, capture, partial void, complete)',
    includes: 'txnTypeID = 1 and txnStatusID = [1, 8, 11, 26, 30, 31]',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  voidRef: {
    count: 0,
    totalAmt: 0,
    desc: 'voided refunds',
    includes: 'txnTypeID = 2 and txnStatusID = 3',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  voidSale: {
    count: 0,
    totalAmt: 0,
    desc: 'voided sales',
    includes: 'txnTypeID = 1 and txnStatusID = 3',
    authTxnFees: 0,
    authOthFees: 0,
    dataCapTxnFees: 0,
    excFees: 0,
    debitNetFees: 0,
    ebtFees: 0,
    miscFixedFees: 0,
    intchgFees: 0,
    indPlanFees: 0,
    discFees: 0,
    cardBrndFees: 0
  },
  calcTotals: {
    netCollSales: 0,
    netDep: 0,
    netSuccSales: 0,
    presReturns: 0,
    presSales: 0,
    unsuccReturns: 0,
    unsuccSales: 0
  }
};

/*const defaultReconData = {
  chargeback: defaultProps,
  chargebackReversal: defaultProps,
  declined: defaultProps,
  error: defaultProps,
  pendingOrUnprocessedTxns: defaultProps,
  refund: defaultProps,
  sale: defaultProps,
  reversals: defaultProps,
  voided: defaultProps,
  fees: {
    totalAuthTxnFees: 0,
    totalAuthOthFees: 0,
    totalDataCapTxnFees: 0,
    totalExcFees: 0,
    totalDebitNetFees: 0,
    totalEbtFees: 0,
    totalMiscFixedFees: 0,
    totalIntChgFees: 0,
    totalIndPlanFees: 0,
    totalDiscFees: 0,
    totalCardBrndFees: 0
  },
  calcTotals: {
    presented: { count: 0, totalAmt: 0 },
    uncollected: { count: 0, percent: { amt: 0, of: 'pres.' }, totalAmt: 0 },
    netCollSales: { count: 0, percent: { amt: 0, of: 'pres.' }, totalAmt: 0 },
    returned: { count: 0, percent: { amt: 0, of: 'net coll.' }, totalAmt: 0 },
    netSuccSales: { count: 0, percent: { amt: 0, of: 'pres.' }, totalAmt: 0 },
    merchantFees: { count: 0, percent: { amt: 0, of: 'net coll.' }, totalAmt: 0 },
    netDep: { count: 0, percent: { amt: 0, of: 'pres.' }, totalAmt: 0 }
  }
};*/

const teaserData = {
  netCollSales: 0,
  netDep: 0,
  netSuccSales: 0,
  presReturns: 0,
  presSales: 0,
  unsuccReturns: 0,
  unsuccSales: 0
};

const initialData = {
  data: {
    actualData: defaultReconData,
    forecastData: defaultReconData
  },
  teaserData: {
    actualData: teaserData,
    forecastData: teaserData
  }
};

export { initialData };
