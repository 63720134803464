import React, { useEffect } from 'react';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { Error, LoadingSpinner, Table } from 'components/UI';
import { SSWidgetWrapper } from '../SsChgbkTxns/resources';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores } from 'utils/utils';
import { useQuery } from 'react-query';
import { Wrapper } from 'styles/styledComponents';
import { widgetService } from 'services';

const orderByOptions = [
  { key: 1, text: 'Category', value: 'declBkt' },
  { key: 2, text: 'Date', value: 'txnDt' },
  { key: 3, text: 'Cust Name', value: 'origCustName' },
  { key: 4, text: 'Inv Num', value: 'invNum' },
];
function cellStyle(params) {
  if (params.value < 0) {
    return { color: '#FF2B49' };
  } else {
    // return { color: 'rgb(69, 232, 133)' };
  }
}

const RptShipTxns = observer(({ margin }) => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.rptShipTxnsWidget,
    toCurrencyFormatter = utilsStore.toCurrencyFormatter,
    paginatorModel = widgetModel.paginatorModel;

  const store = useLocalObservable(() => ({
    fileName: 'ship-txns',
    loading: false,
    rowData: null,
    txnCount: 0,
    api: null,
    gridColumnApi: null,

    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        masterDetail: true,
        reactNext: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        filter: true,
        rowHeight: 33,
        headerHeight: 33,
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: [{ field: 'mid' }],
            defaultColDef: { flex: 1 },
          },
          template:
            '<div style="height: 100%; background-color: transparent; color: "rgba(255,255,255, .8)" padding: 20px; box-sizing: border-box;">' +
            '  <div style="height: 10%; padding: 2px; font-weight: bold;">###### Call Notes  just put the stuff in here</div>' +
            // '  <div ref="eDetailGrid" style="height: 90%;"> can I just put a whole bunch of stuff in here</div>' +
            '</div>',
        },
        columnDefs: [
          { headerName: 'Auth Amount', field: 'auth_amt', valueFormatter: toCurrencyFormatter, width: 130, cellStyle },
          { field: 'category', width: 120 },
          { headerName: 'CC Num', field: 'cc_num', width: 150 },
          { field: 'customer' },
          { headerName: 'Deposit Date', field: 'dep_dt', width: 150 },
          { headerName: 'Funding Status', field: 'funding_status', width: 150 },
          { headerName: 'Order Num', field: 'order_num', width: 140 },
          { headerName: 'Settle Amount', field: 'settle_amt', valueFormatter: toCurrencyFormatter, width: 140, cellStyle },
          { headerName: 'Txn Date', field: 'txn_dt', flex: 1 },
        ],

        defaultColDef: {
          minWidth: 90,
          maxWidth: 300,
          sortable: false,
          filter: true,
          resizable: true,
        },
        onGridReady: (params) => {
          this.api = params.api;
          this.gridColumnApi = params.columnApi;
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
    setRowData(txnsData) {
      const { status, data, error } = txnsData;
      if (status === 'loading') this.loading = true;
      if (error) {
        this.error = error;
        this.loading = false;
      }
      if (data) {
        this.rowData = data.data.results.map((txn, index) => {
          txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
          return {
            ...txn
          };
        });
        this.txnCount = data.data.count;
        this.loading = false;
      }
    },
    async getData() {
      try {
        if (this.api) {
          this.api.showLoadingOverlay();
        }
        const res = await widgetModel.fetchWidgetData(widgetService.shipDetails);
        const accSnsSDetails = res;
        this.rowData = accSnsSDetails.response.map((txn, index) => {
          txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
          return {
            ...txn
          };
        });
        this.txnCount = accSnsSDetails.count;
      } catch (error) {
        console.error('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      if (this.api && this.rowData.length === 0) {
        this.api.showNoRowsOverlay();
      }
    },
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
  }));

  const txnsData = useQuery(
    ['rptNoShipTxns', widgetModel.query],
    !!dataManagementStore.newQuery || !!widgetModel.filterModel.respCodeSname
      ? () => widgetModel.fetchWidgetData(widgetService.shipDetails)
      : () => {
      },
    {
      refetchOnWindowFocus: false,
      enabled: !!dataManagementStore.newQuery || !!widgetModel.filterModel.respCodeSname
    }
  );

  useEffect(() => {
    store.setRowData(txnsData);
  }, [txnsData]);

  useEffect(() => {
    paginatorModel.setPageNumber(1);
  }, [widgetModel.filterModel.respCodeSname]);

  return (
    <Observer>
      {() => (
        <SSWidgetWrapper margin={margin} height="100%">
          {store.loading ? (
            <Wrapper>
              <LoadingSpinner size={'200px'} />
            </Wrapper>
          ) : store.error ? (
            <Error error={store.error} />
          ) : store.rowData ? (
            <Table
              store={store}
              fetchTableData={store.getData}
              rowData={store.rowData}
              loading={store.isLoading}
              /**export**/
              initiateTxnsExport={store.widgetModel.initiateTxnsExport}
              exportInfo={store.widgetModel.exportInfo}
              /** Paginator-Specific Functions **/
              txnCount={store.txnCount}
              paginator={paginatorModel}
              /** OrderBy Properties **/
              addOrderBy={store.widgetModel.addOrderBy}
              orderByArray={store.widgetModel.orderByModel}
              orderByOptions={store.orderByOptions}
              resetOrderBy={store.widgetModel.resetOrderBy}
            />
          ) : null}
        </SSWidgetWrapper>
      )}
    </Observer>
  );
});

export default RptShipTxns;
