import React, {useEffect, useState} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useInjectStores, buildColDef} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table} from 'components/UI';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import styled from 'styled-components/macro';
import {Wrapper} from 'styles/styledComponents';
import {widgetService} from 'services';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';

const IsoCommissionsAgent = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, utilsStore, filterStore} = useInjectStores();

    const widgetModel = widgetStore.transactionsByBatchNum,
        paginatorModel = widgetModel.paginatorModel;

    const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
    const toDateFormatter = utilsStore.toDateFormatter;
    const orderByOptions = [
        {key: 1, text: 'Customer ID', value: 'customer_id'},
        {key: 2, text: 'Customer Name', value: 'customer_name'}
    ];

    const store = useLocalObservable(() => ({
        fileName: 'commissionsAgent',
        loading: false,
        downloadingFiles: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
		selectedAgent: '',
        widgetModel,
		agentList: [],
		columnDefs: [],
        paginatorModel,
        rowData: [],
        txnCount: 0,
        batchNum: '',
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                rowHeight: 33,
                headerHeight: 33,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    resizable: true
                },
                columnDefs: store.columnDefs,
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No commission data found for the selected agent</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                this.rowData = data.data.results.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = data.data.count;
                this.loading = false;
            }
        },
        async getAllData() {
            const startDate = this.dataManagementStore.startDt.slice(0, 10);
            const endDate = this.dataManagementStore.endDt.slice(0, 10);

            const convertToCSV = (data) => {
                const headers = Object.keys(data[0]).join(',') + '\n';
                const rows = data.map(row => Object.values(row).join(',')).join('\n');
                return headers + rows;
              };
              
            const convertToXLSX = (data) => {
                const worksheet = XLSX.utils.json_to_sheet(data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                const wbout = XLSX.write(workbook, {bookType:'xlsx', type: 'binary'});
                return wbout;
              };

            this.agentList = this.agentList;
            this.downloadingFiles = true;
            if(this.agentList.length) {
                const promiseArr = this.agentList.map(async (agent) => {
                    rootStore.filterStore.transactionFilter.setAgentNm(agent.value);
                    return widgetModel.fetchWidgetData(widgetService.isoCommissionsAgent);
                });
                const res = await Promise.all(promiseArr);
                const jsZip = new JSZip();
 
                res.forEach( async ({config, data})  => {
                    const agentNm = config.params.agentNm;
                    if (store.widgetModel.exportInfo.exportType === 'csv') {
                        const csvFileData = convertToCSV(data.values);
                        jsZip.file(`${agentNm}_${startDate}-${endDate}.csv`, csvFileData);
                    } else {
                        const xlsxFileData = convertToXLSX(data.values);
                        jsZip.file(`${agentNm}_${startDate}-${endDate}.xlsx`, xlsxFileData, {binary: true});
                    }
                });

                jsZip.generateAsync({type:"blob"}).then(function(content) {
                    const url = URL.createObjectURL(content);
                    const element = document.createElement('a');
                    element.href = url;
                    element.download = `Agent_Commissions_${startDate}-${endDate}.zip`;
                    document.body.appendChild(element);
                    element.click();
                
                    document.body.removeChild(element);
                    URL.revokeObjectURL(url);
                });
            }
            this.downloadingFiles = false;
        },
        async getData() {
            this.loading = true;
            rootStore.filterStore.transactionFilter.setAgentNm(this.selectedAgent);
            
            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }
                const res = await widgetModel.fetchWidgetData(widgetService.isoCommissionsAgent);

				store.widgetData = res.data;

				if(store.widgetData?.values.length) {
					store.columnDefs = Object.keys(store.widgetData.values[0]).map(key => {
						return buildColDef(utilsStore, key, 150);
					});
	
					store.rowData = store.widgetData.values.map((txn, index) => {
						txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
						return {
							...txn
						};
					});
				}
			
				store.txnCount = store.widgetData.count || store.widgetData?.values.length;
                
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));

	const agentList =  useQuery(
        ['agentList', widgetModel.query],
        !!dataManagementStore.newQuery ? () => widgetModel.fetchWidgetData(widgetService.isoCommissionsAgents) : () => {
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!dataManagementStore.newQuery
        }
    );

	if(agentList.status === 'success') {
		const uniqueAgentNames = [...new Set(agentList.data.data.values.map(agent => agent.agent_1099_nm).filter(agent => agent !== null))];
		store.agentList = uniqueAgentNames.map(agent => {
			return {key: agent, text: agent, value: agent};
		});
	}
    
    useEffect(() => {
        paginatorModel.setPageNumber(1);
    }, [dataManagementStore.newQuery]);

  return (
    <Observer>
      {() => (
        <>
          {
            store.loading ? (
            <Wrapper>
              <LoadingSpinner size={'200px'} />
            </Wrapper>
          ) : store.error ? (
              <div>there was an error</div>
            ) :
            store.rowData ? (
				<TableWrapper>
                    <Table
                        store={store}
                        fetchTableData={store.getData}
                        rowData={store.rowData}
                        loading={store.loading}
                        /**export**/
                        initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                        exportInfo={store.widgetModel.exportInfo}
                        /** Paginator-Specific Functions **/
                        txnCount={store.rowData.length}
                        paginator={paginatorModel}
                        enableBrowserTooltips={true}
                        /** OrderBy Properties **/
                        addOrderBy={store.widgetModel.addOrderBy}
                        orderByArray={store.widgetModel.orderByModel}
                        orderByOptions={store.orderByOptions}
                        resetOrderBy={store.widgetModel.resetOrderBy}
                        hasSelect={true}
                        searchAction={store.getData}
                        hasDownloadAll={true}
                    ></Table>
			    </TableWrapper>
          ) : null}
        </>
      )}
    </Observer>
  );
});

export default IsoCommissionsAgent;

const TableWrapper = styled.div`
height: 98%;
margin-left: 5px;
margin-right: 5px;
`;