import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import TableHeader from './TableHeader/TableHeader';
import { TableWrapper } from 'styles/styledComponents';
import { Observer, observer } from 'mobx-react-lite';
import styled from 'styled-components';
// import { StoreContext } from '../../../stores/RootStore';
import TxnDetailsModal from '../../Widgets/WidgetComponentsOther/TxnDetailModal/TxnDetailModal';
import { useInjectStores } from 'utils/utils';
// import { Wrapper } from 'styles/styledComponents';
import { Error, LoadingSpinner } from '../index';

const Table = observer((props) => {
  const { uiStore } = useInjectStores();
  const showTxnDetailModal = uiStore.showTxnDetailModal;
  return (
    <Observer>
      {() => (
        <StyledWrapper margin="0 0px" height="100%">
          <TableWrapper id="header" width="100%" height="100%">
            <>
              {props.loading ? (
                <LoadingSpinner size={'200px'} />
              ) : props.error ? (
                <Error error={props.error} />
              ) : props.store.rowData ? (
                <>
                  <TableHeader
                    store={props.store}
                    initiateTxnsExport={props.initiateTxnsExport}
                    exportInfo={props.exportInfo}
                    /** OrderBy **/
                    addOrderBy={props.addOrderBy}
                    orderByArray={props.orderByArray}
                    orderByOptions={props.orderByOptions}
                    resetOrderBy={props.resetOrderBy}
                    loading={props.loading}
                    fetchTableData={props.fetchTableData}
                    /*Paginator*/
                    paginator={props.paginator}
                    txnCount={props.txnCount}
                    hasSearch={props.hasSearch}
                    hasSelect={props.hasSelect}
                    noPaginator={props.noPaginator}
                    noExport={props.noExport}
                    hasDownloadAll={props.hasDownloadAll}
                    searchSetter={props.searchSetter}
                    searchAction={props.searchAction}
                  />
                  <GridWrapper>
                    <div
                      id="myGrid"
                      className={'ag-theme-alpine-dark'}
                      style={{
                        height: '100%',
                        minHeight: '600px',
                        // minHeight: '500px',
                        // width: 'calc(100% + 1px)',
                        position: 'relative',
                        borderRadius: '5px',
                      }}
                    >
                      {/**react next is for react16 use**/}

                      <AgGridReact
                        react="next"
                        animateRows
                        masterDetail={true}
                        detailRowHeight={props.store.detailRowHeight}
                        detailCellRenderer={props.store.detailCellRenderer}
                        frameworkComponents={props.store.frameworkComponents}
                        detailCellRendererParams={props.store.detailCellRendererParams}
                        rowData={props.store.rowData}
                        gridOptions={props.store.gridOptions}
                        popupParent={document.getElementById('myGrid')}
                      />
                    </div>
                  </GridWrapper>
                </>
              ) : null}
            </>
          </TableWrapper>
          {showTxnDetailModal && <TxnDetailsModal rowData={props.store.rowData} />}
        </StyledWrapper>
      )}
    </Observer>
  );
});

const GridWrapper = styled.div`
  height: 100%;
  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    --ag-control-panel-background-color: #041c2f;
    --ag-subheader-background-color: #032845;

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }
    .ag-header-row {
      height: 30px;
    }
    .topLevel {
      background-color: #001329 !important;
    }
    .secondLevel {
      background-color: #032845 !important;
    }
    .ag-group-value {
      margin: auto;
    }
    .apply {
      background-color: #3fa9f5;
      color: rgba(255, 255, 255);
      border-radius: 3px;
      border: none;
      width: 80px;
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.baseColors.colorBlueHover};
      }
    }
  }
`;

const StyledWrapper = styled.div`
  height: 100%;
  margin: ${({ margin }) => margin || '0 4px'};
`;

export default Table;
