import React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Checkbox = observer((props) => {
  return (
    <Observer>
      {() => (
        <FieldWrapper width={props.width}>
          <Field margin={props.margin} required={props.required} labelWidth={props.labelWidth}>
            {!props.labelRight && (
              <LabelContainer labelWidth={props.labelWidth}>
                <label onClick={props.onChange}>
                  {props.required && <span className="required">*</span>}
                  {props.label}
                </label>
              </LabelContainer>
            )}
            <input
              tabIndex={props.tabIndex}
              onChange={props.onChange}
              onInput={props.onInput}
              onBlur={props.onBlur}
              value={props.value}
              checked={props.checked}
              readOnly={props.readOnly}
              // width={props.width || '100%'}
              type="checkbox"
            />
            {props.labelRight && (
              <LabelRightContainer labelWidth={props.labelWidth}>
                <label onClick={props.onChange}>
                  {props.required && <span className="required">*</span>}
                  {props.label}
                </label>
              </LabelRightContainer>
            )}
          </Field>

          <span className="error">{props.error}</span>
        </FieldWrapper>
      )}
    </Observer>
  );
});
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  position: relative;
  width: ${({ width }) => width || 'auto'};
  input {
    cursor: pointer;
  }
  .error {
    color: red;
    padding-left: 239px;
  }
  .required {
    color: red;
  }
`;
const LabelContainer = styled.div`
  width: ${({ labelWidth }) => labelWidth || '200px'};
  margin: ${({ required }) => (!required ? '0 0 0 4px' : '0 5px 0 0')};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  label {
    text-align: end;
    width: 100%;
    padding-right: 5px;
    cursor: pointer;
  }
`;
const LabelRightContainer = styled.div`
  width: ${({ labelWidth }) => labelWidth || '200px'};
  margin: ${({ required }) => (!required ? '0 0 0 4px' : '0 5px 0 0')};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  label {
    width: 100%;
    padding-right: 5px;
    cursor: pointer;
  }
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
  @media (max-width: 490px) {
    display: block;
  }
`;

export default Checkbox;
