import React, {Fragment} from 'react';
/* state management */
import {observer, Observer} from 'mobx-react-lite';
/* styles */
import styled from 'styled-components/macro';
/* components */
import {Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
// import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
/* utils */
import Moment from 'moment';
import {useInjectStores, _includes, _cloneDeep, _find, _get, _toUpper} from 'utils/utils';

import {DefaultTooltipContent} from 'recharts/lib/component/DefaultTooltipContent';
import { toCurrency } from 'utils/currencyFilter';
// import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const options = [
    {key: 1, text: 'All', value: 'All', checked: false},
    {key: 2, text: 'Sales', value: 'Sales', checked: false},
    {key: 3, text: 'Declines', value: 'Declines', checked: false},
    {key: 4, text: 'Returns', value: 'Returns', checked: false},
    {key: 5, text: 'Reserves', value: 'Reserves', checked: false},
];

export let defaultOptions = [
    {key: 1, text: 'All', value: 'All', checked: true},
    {key: 2, text: 'Sales', value: 'Sales', checked: false},
    {key: 3, text: 'Declines', value: 'Declines', checked: false},
    {key: 4, text: 'Returns', value: 'Returns', checked: false},
    {key: 5, text: 'Reserves', value: 'Reserves', checked: false},
];

export let defaultNoReserveOpt = [
    {key: 1, text: 'All', value: 'All', checked: true},
    {key: 2, text: 'Sales', value: 'Sales', checked: false},
    {key: 3, text: 'Declines', value: 'Declines', checked: false},
    {key: 4, text: 'Returns', value: 'Returns', checked: false},
];

const initialData = {
    sales: [{totalAmt: 0}],
    declines: [{totalAmt: 0}],
    returns: [{totalAmt: 0}],
    reserves: [{totalAmt: 0}],
};

export const CustomTooltip = observer((props) => {
    // payload[0] doesn't exist when tooltip isn't visible
    const payload = _cloneDeep(props.payload);

    if (DefaultTooltipContent) {
        if (props.payload && props.payload[0] != null) {
            const payload = props.payload;

            const newPayload = [];

            const sales = _find(payload, {dataKey: 'sales'});
            const declines = _find(payload, {dataKey: 'declines'});
            const returns = _find(payload, {dataKey: 'returns'});
            // const reserves = _find(payload, {dataKey: 'reserves'});  change to chargebacks

            const salesTrendLine = _find(payload, {dataKey: 'SalesTrendline'});
            const declinesTrendline = _find(payload, {dataKey: 'DeclinesTrendline'});
            const returnsTrendLine = _find(payload, {dataKey: 'ReturnsTrendline'});
            // const reservesTrendLine = _find(payload, {dataKey: 'ReservesTrendline'}); change to chargebacks

            if (sales) {
                sales['name'] = 'Successful';
                sales.value = `${props.toCurrency(sales.value)} / ${sales.payload.sale_cnt} txn`;
                newPayload.push(sales);
                if (_includes(props.show, 'Sales') && salesTrendLine) {
                    salesTrendLine['value'] = props.toCurrency(_get(salesTrendLine, 'value', 0));
                    newPayload.push(salesTrendLine);
                }
            }

            if (declines) {
                declines.value = `${props.toCurrency(declines.value)} / ${declines.payload.decl_cnt} txn`;
                newPayload.push(declines);
                if (_includes(props.show, 'Declines') && declinesTrendline) {
                    declinesTrendline['value'] = props.toCurrency(_get(declinesTrendline, 'value'), 0);
                    newPayload.push(declinesTrendline);
                }
            }

           /* if (refund) {
                const returnsHold = {...returns};
                returnsHold['name'] = 'Refund';
                returnsHold['value'] = `${props.toCurrency(_get(refundHold, 'value'), 0)} / ${refundHold.payload.returnsCount} txn`;
                newPayload.push(refundHold);
                if (_includes(props.show, 'Refund') && refundTrendLine) {
                    returnsTrendLine['value'] = props.toCurrency(_get(refundTrendLine, 'value'), 0);
                    newPayload.push(refundTrendLine);
                }
            }*/

           /* if (chargeback) {
                reserves.value = `${props.toCurrency(reserves.value)} / ${reserves.payload.ChargebackCount} txn`;
                newPayload.push(reserves);
                if (_includes(props.show, 'Chargeback') && chargebackTrendLine) {
                    reservesTrendLine['value'] = props.toCurrency(_get(chargebackTrendLine, 'value'), 0);
                    newPayload.push(chargebackTrendLine);
                }
            }*/
            // we render the default, but with our new payload
            return <DefaultTooltipContent {...props} payload={newPayload}/>;
        }

        // we just render the default
        return <DefaultTooltipContent {...props} />;
    }
});

const formatter = (value) => {
    // if (value < 1000) return value;
    let suffix = '';
    let decimals = 0;
    if (value < 0) {
        if (value <= -1000 && value > -1000000) {
            suffix = 'K';
            value = value / 1000;
            if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
        } else if (value <= -1000000 && value > -1000000000) {
            suffix = 'M';
            value = value / 1000000;
            if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
        }
    } else {
        if (value >= 1000 && value < 1000000) {
            suffix = 'K';
            value = value / 1000;
            if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
        } else if (value >= 1000000 && value < 1000000000) {
            suffix = 'M';
            value = value / 1000000;
            if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
        }
    }

    let formatter = new Intl.NumberFormat('eng', {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });

    let formatted = formatter.format(value);

    if (suffix) return `${formatted}${suffix}`;
    else return formatted;
};

const CustomizedAxisTick = observer((props) => {
    const {dataManagementStore} = useInjectStores();
    const applyNewDateRange = dataManagementStore.dateFilter.applyNewDateRange;
    const setSelectedInput = dataManagementStore.dateFilter.setSelectedInput,
        granularity = dataManagementStore.dateFilter.granularity,
        setStartDt = dataManagementStore.dateFilter.setStartDt,
        setEndDt = dataManagementStore.dateFilter.setEndDt;

    const {payload} = props;

    function setDate() {
        const dateBucket = props.data.dateBucket;
        const adjustment = dateBucket === 'days' ? 'hours' : 'days';
        if (dateBucket !== 'hours') {
            const {startDate, endDate} = props.data.data[payload.index];
            const startDt = Moment(startDate).add(1, 'days').startOf(granularity);
            const endDt = Moment(endDate).subtract(1, adjustment).endOf(granularity);
            setStartDt(startDt);
            setEndDt(endDt);
            setSelectedInput('');
            applyNewDateRange();
        }
    }

    // const payload = _cloneDeep(props.payload);
    // if (props.payload[0] != null) {
    //   const newPayload = [];

    return (
        <g transform={`translate(${props.x},${props.y})`}>
            <text
                className={props.data.dateBucket !== 'hours' ? 'xAxisTick' : ''}
                onClick={setDate}
                x={0}
                y={0}
                dy={12}
                dx={0}
                cursor="pointer"
                textAnchor="middle"
                fill={props.data.gridItem.colors.primaryText}
            >
                {typeof props.payload.value === 'number' ? props.payload.value + 1 : props.payload.value}
            </text>
        </g>
    );
});

const ComposedCharts = observer((props) => {
    return (
        <Observer>
            {() => (
                <ResponsiveContainer height="100%" width="100%">
                    <ComposedChart data={props.data} margin={{top: 20, right: 15, left: 0, bottom: 15}}>
                        {props.dateTypeNum && (
                            <XAxis
                                interval={0}
                                tick={<CustomizedAxisTick data={props}/>}
                                label={{
                                    value: "Monthly Period",
                                    fontSize: 13,
                                    fill: props.gridItem.colors.primaryText,
                                    position: 'insideBottom',
                                    offset: -8,
                                }}
                            />
                        )}
                        }
                        {!props.dateTypeNum && (
                            <XAxis
                                // type="category"
                                dataKey="dtWord"
                                tick={<CustomizedAxisTick data={props}/>}
                                label={{
                                    value: _toUpper(props.dateBucket),
                                    fontSize: 13,
                                    fill: props.gridItem.colors.primaryText,
                                    position: 'insideBottom',
                                    offset: -8,
                                }}
                            />
                        )}
                        <YAxis tickFormatter={formatter} tick={{fill: props.gridItem.colors.primaryText}}/> }} />
                        <CartesianGrid stroke={props.gridItem.colors.grid} id="cGrid"/>
                        <Tooltip



                            contentStyle={{backgroundColor: props.gridItem.colors.toolTipBackground}}
                            content={toCurrency()}
                            labelStyle={{color: props.gridItem.colors.primaryText}}
                            labelFormatter={(label) => typeof label === 'number' ? label += 1 : label}
                        />
                        <defs>
                            <linearGradient id="declines" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#3fa9f5" stopOpacity={props.gridItem.colors.startOpacity}/>
                                <stop offset="95%" stopColor="#3fa9f5" stopOpacity={props.gridItem.colors.stopOpacity}/>
                            </linearGradient>
                            <linearGradient id="sales" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#45E885" stopOpacity={props.gridItem.colors.startOpacity}/>
                                <stop offset="95%" stopColor="#45E885" stopOpacity={props.gridItem.colors.stopOpacity}/>
                            </linearGradient>
                            <linearGradient id="returns" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#fccc1a" stopOpacity={props.gridItem.colors.startOpacity}/>
                                <stop offset="95%" stopColor="#fccc1a" stopOpacity={props.gridItem.colors.stopOpacity}/>
                            </linearGradient>
                            <linearGradient id="chgbk" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FF2B49" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#FF2B49" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <Legend
                            wrapperStyle={{
                                height: 10,
                                top: 5,
                                color: props.gridItem.colors.primaryText,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            payload={props.legendPayload}
                            content={() => ssTotalsGraphLegend(props.show)}
                            verticalAlign="top"
                            align="center"
                        />
                        {(_includes(props.show, 'All') || _includes(props.show, 'Sales')) && (
                            <Bar
                                name="Sales"
                                type="monotone"
                                dataKey={props.dataKey}
                                fillOpacity={1}
                                fill="url(#sales)"
                                legendColor={props.gridItem.colors.primaryText}
                                stroke="#45E885"
                            />
                        )}
                        {(_includes(props.show, 'All') || _includes(props.show, 'Declines')) && (
                            <Bar
                                name="Declined"
                                type="monotone"
                                dataKey={props.dataKey}
                                fillOpacity={1}
                                fill="url(#declines)"
                                legendColor={props.gridItem.colors.primaryText}
                                stroke="#3fa9f5"
                            />
                        )}
                        {(_includes(props.show, 'All') || _includes(props.show, 'Returns')) && (
                            <Bar
                                name="Refund"
                                type="monotone"
                                dataKey={props.dataKey}
                                fillOpacity={1}
                                fill="url(#returns)"
                                legendColor={props.gridItem.colors.primaryText}
                                stroke="#fccc1a"
                            />
                        )}
                        {(_includes(props.show, 'All') || _includes(props.show, 'Chargebacks')) && (
                            <Bar
                                name="Chargeback"
                                isAnimationActive={false}
                                type="monotone"
                                dataKey={props.dataKey}
                                fillOpacity={1}
                                fill="url(#chgbk)"
                                legendColor={props.gridItem.colors.primaryText}
                                stroke="#FF2B49"
                            />
                        )}
                        {_includes(props.show, 'Sales') && (
                            <Line
                                name="S. Trend"
                                type="monotone"
                                legendColor={props.gridItem.colors.primaryText}
                                dataKey="SalesTrendline"
                                stroke="#45E885"
                            />
                        )}
                        {_includes(props.show, 'Declines') && (
                            <Line
                                name="D. Trend"
                                type="monotone"
                                legendColor={props.gridItem.colors.primaryText}
                                dataKey="DeclinesTrendline"
                                stroke="#3fa9f5"
                            />
                        )}
                        {_includes(props.show, 'Returns') && (
                            <Line
                                name="Ref. Trend"
                                type="monotone"
                                legendColor={props.gridItem.colors.primaryText}
                                dataKey="ReturnsTrendline"
                                stroke="#fccc1a"
                            />
                        )}
                        {_includes(props.show, 'Chargebacks') && (
                            <Line
                                name="Chgbk. Trend"
                                type="monotone"
                                legendColor={props.gridItem.colors.primaryText}
                                dataKey="ChbgkTrendline"
                                stroke="#ff2b49"
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </Observer>
    );
});

const Item = styled.div`
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
  padding: 5px;

  .filledCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 3px 0 0;
  }

  .borderCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 3px 0 0;
  }

  .d {
    background-color: #3fa9f5;
  }

  .dt {
    border-color: #3fa9f5;
  }

  .s {
    background-color: #45e885;
  }

  .st {
    border-color: #45e885;
  }

  .rs {
    background-color: #fccc1a;
  }

  .rst {
    border-color: #fccc1a;
  }

  .r {
    background-color: #ff2b49;
  }

  .rt {
    border-color: #ff2b49;
  }
`;

const ssTotalsGraphLegend = (payload, show) => {
    if (payload) {
        return (
            <Fragment>
                {payload === 'Sales' && (
                    <Item>
                        <div className="filledCircle s"/>
                        Sales
                    </Item>
                )}
                {payload === 'Declines' && (
                    <Item>
                        <div className="filledCircle d"/>
                        Declines
                    </Item>
                )}
                {payload === 'Returns' && (
                    <Item>
                        <div className="filledCircle rs"/>
                        Returns
                    </Item>
                )}
                {payload === 'Chargebacks' && (
                    <Item>
                        <div className="filledCircle r"/>
                        Chargebacks
                    </Item>
                )}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                {payload.map((load, index) => (
                    <Item key={index}>
                        {load.type === 'line' && (
                            <div
                                className="borderCircle"
                                style={{
                                    width: 7,
                                    height: 7,
                                    color: load.color,
                                    background: '#fff',
                                    border: `2px solid ${load.color}`
                                }}
                            />
                        )}
                        {load.type === 'circle' && <div className="filledCircle" style={{background: load.color}}/>}
                        {load.value}
                    </Item>
                ))}
            </Fragment>
        );
    }
};

export {initialData, options, ssTotalsGraphLegend, ComposedCharts};
