import React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { TxnByBatchNumber } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';

const txnByBatchNumber = observer(() => {
  let { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <div style={{ margin: '0 4px', height: '100%' }}>
              <TxnByBatchNumber />
            </div>
          ) : (
            <div>You do not have access to this report</div>
          )}
        </>
      )}
    </Observer>
  );
});

export default txnByBatchNumber;
