import React, {useEffect} from 'react';
import {Error, LoadingSpinner} from 'components/UI';
import styled from 'styled-components/macro';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {
    BarChart,
    Tooltip,
    Bar,
    Cell,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    LabelList,
    XAxis,
    YAxis,
} from 'recharts';

import { renderCustomizedLabel, CustomizedDot, Notes, SalesChart, ChargebacksChart, DeclinesChart, RefundsChart, TotalsChart } from './resources'
import {WidgetWrapper} from 'styles/styledComponents';
import {_round, useGetWidgetData, useInjectStores, _get} from 'utils/utils';
import {widgetService} from '../../../services';

const SsStatistics = observer(() => {
    const {dataManagementStore, uiStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency;

    const store = useLocalObservable(() => ({
        loading: false,
        widgetData: [],
        setWidgetData(widgetData) {
            store.error = null;
            const {status, data, error} = widgetData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                store.widgetData = data.values;
                this.loading = false;
            }
        },
        get toReturn() {
            return (
                this.widgetData?.stats || [
                    {
                        chgbk_amt_avg_hist: 0,
                        chgbk_avg: 0,
                        decl_amt_avg_hist: 0,
                        decl_avg: 0,
                        decl_var_ratio: "",
                        mid: "",
                        ref_amt_avg_hist: 0,
                        ref_avg: 0,
                        ref_var_ratio: "",
                        sale_amt_avg_hist: 0,
                        sales_avg: 0,
                        sales_var_ratio: "",
                    },
                ]
            );
        },
        get avgs() {
            return (
                this.widgetData?.average || [
                    {
                        hist_days_to_fund: 0,
                        hist_days_to_settle: 0,
                        num_days_to_fund: 0,
                        num_days_to_settle: 0,
                    },
                ]
            );
        },

        get varToHistFund() {
            let variance = _round(100 * _get(this.avgs, 'num_days_to_fund') - 100 * _get(this.avgs, 'hist_days_to_fund'), 2);
            if (variance) {
                if (variance < 1) {
                    return `- ${Math.abs(variance)}%`;
                }
                return `+ ${Math.abs(variance)}%`;
            } else {
                return '-';
            }
        },
        get varToHistSettle() {
            let variance = _round(100 * _get(this.avgs, 'num_days_to_settle') - 100 * _get(this.avgs, 'hist_days_to_settle'), 2);
            if (variance) {
                if (variance < 1) {
                    return `- ${Math.abs(variance)}%`;
                }
                return `+ ${Math.abs(variance)}%`;
            } else {
                return '-';
            }
        },
    }));


    const statsData = useGetWidgetData(
        {name: 'dashboardStatistics', query: widgetService.dashboardAverages},
        'data',
        dataManagementStore?.newQuery
    );

    useEffect(() => {
        store.setWidgetData(statsData);
    }, [statsData]);

    return (
        <Observer>
            {() => (
                <>
                    <StyledWrapper>
                        <div
                            css={`
                              grid-area: sales;
                            `}
                        >
                            <WidgetWrapper id="cGrid">
                                <div/>
                                {store.loading ? (<LoadingSpinner size={'200px'}/>) : store.error ? (<Error error={store.error}/>) : store.widgetData?.length > 0 ? (
                                    <SalesChart
                                        gridItem={uiStore.currentTheme.rgl.gridItem}
                                        label={store.label}
                                        show={'Sales'}
                                        dateBucket={store.granularity}
                                        data={store.widgetData}
                                        dateTypeNum={store.dateTypeNum}
                                        legendPayload={store.legendPayload}
                                        toCurrency={toCurrency}
                                    />
                                ) : (
                                    <div className="noData">No data for this time period</div>
                                )}
                            </WidgetWrapper>
                        </div>
                        <div
                            css={`
                              grid-area: declines;
                            `}
                        >
                            <WidgetWrapper id="cGrid">
                                {store.loading ? (
                                    <LoadingSpinner size={'200px'}/>
                                ) : store.error ? (
                                    <Error error={store.error}/>
                                ) : store.widgetData?.length > 0? (
                                    <RefundsChart
                                        gridItem={uiStore.currentTheme.rgl.gridItem}
                                        label={store.label}
                                        show={'Declines'}
                                        dateBucket={store.granularity}
                                        data={store.widgetData}
                                        dateTypeNum={store.dateTypeNum}
                                        legendPayload={store.legendPayload}
                                        toCurrency={toCurrency}
                                    />
                                ) : (
                                    <div className="noData">No data for this time period</div>
                                )}
                            </WidgetWrapper>
                        </div>
                        <div
                            css={`
                              grid-area: refunds;
                            `}
                        >
                            <WidgetWrapper id="cGrid">
                                {store.loading ? (
                                    <LoadingSpinner size={'200px'}/>
                                ) : store.error ? (
                                    <Error error={store.error}/>
                                ) : store.widgetData?.length > 0 ? (
                                    <DeclinesChart
                                        gridItem={uiStore.currentTheme.rgl.gridItem}
                                        label={store.label}
                                        show={'Returns'}
                                        dateBucket={store.granularity}
                                        data={store.widgetData}
                                        dateTypeNum={store.dateTypeNum}
                                        legendPayload={store.legendPayload}
                                        toCurrency={toCurrency}
                                    />
                                ) : (
                                    <div className="noData">No data for this time period</div>
                                )}
                            </WidgetWrapper>
                        </div>
                        <div
                            css={`
                              grid-area: chargebacks;
                            `}
                        >
                            <WidgetWrapper id="cGrid">
                                {store.loading ? (
                                    <LoadingSpinner size={'200px'}/>
                                ) : store.error ? (
                                    <Error error={store.error}/>
                                ) : store.widgetData?.length > 0 ? (
                                    <ChargebacksChart
                                        gridItem={uiStore.currentTheme.rgl.gridItem}
                                        label={store.label}
                                        show={'Chargebacks'}
                                        dateBucket={store.granularity}
                                        data={store.widgetData}
                                        dateTypeNum={store.dateTypeNum}
                                        legendPayload={store.legendPayload}
                                        toCurrency={toCurrency}
                                    />
                                ) : (
                                    <div className="noData">No data for this time period</div>
                                )}
                            </WidgetWrapper>
                        </div>
                        <div
                            css={`
                              grid-area: totals;
                            `}
                        >
                            <WidgetWrapper id="cGrid">
                                <div/>
                                {store.loading ? (
                                    <LoadingSpinner size={'200px'}/>
                                ) : store.error ? (
                                    <Error error={store.error}/>
                                ) : store.widgetData?.length > 0 ? (
                                    <TotalsChart
                                        gridItem={uiStore.currentTheme.rgl.gridItem}
                                        label={store.label}
                                        show={'Totals'}
                                        dateBucket={store.granularity}
                                        data={store.widgetData}
                                        dateTypeNum={store.dateTypeNum}
                                        legendPayload={store.legendPayload}
                                        toCurrency={toCurrency}
                                    />
                                ) : (
                                    <div className="noData">No data for this time period</div>
                                )}
                            </WidgetWrapper>
                        </div>
                        <div
                            css={`
                              grid-area: notes;
                            `}
                        >
                            <WidgetWrapper id="cGrid">
                                <div/>
                                <Notes/>
                            </WidgetWrapper>
                        </div>
                    </StyledWrapper>
                </>
            )}
        </Observer>
    );
});
const StyledWrapper = styled.div`
      display: grid;
      height: calc(100% - 35px);
      //margin: 0 5px 0 5px;
      //height: 100%;
      //margin: 0 0 10px 0;
      margin: 0 0 20px 0;
      grid-template-areas:
      'sales declines'
      'refunds chargebacks'
      'totals notes';
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 5px;

      //padding-bottom: 20px;

      .salesGraph {
        height: 100%;
        grid-area: salesGraph;
      }

      .refundGraph {
        height: 100%;
        grid-area: refundGraph;
      }

      .declinesGraph {
        height: 100%;
        grid-area: declinesGraph;
      }

      .chargebacksGraph {
        height: 100%;
        grid-area: chargebacksGraph;
      }

      .totalsGraph {
        height: 100%;
        grid-area: totalsGraph;
      }

      .notes {
        height: 100%;
        grid-area: totalsGraph;
      }

      position: relative;
      width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};

      .center {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .checkIcon {
        display: flex;
        position: relative;
        align-items: center;
      }

      .my-tooltip > .tooltip-inner {
        background-color: red;
      }

      #tooltip-top > .tooltip-inner {
        background-color: #fff;
        color: #000;
        border: 1px solid #062e56;
      }

      #tooltip-top > .tooltip-arrow {
        border-top: 5px solid #062e56;
      }

      .noData {
        margin: auto;
      }

      .header {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
  
  .noteWrapper {
    padding: 5px;
  }

      .noteHeader {
        font-weight: bold;
        text-decoration: underline;
        margin-left: 2px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
      }

      .noteContent {
        margin-top: 2px;
        margin-left: 10px;
        font-size: 12px;
      }

      .xAxisTick {
        &:hover {
          fill: ${({theme}) => theme.baseColors.colorBlue};
        }
      }
    `;

export default SsStatistics;
