import React, {useState} from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { IsoCommissions, IsoCommissionsPayout, IsoCommissionsAgent } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';
import {Wrapper} from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Route, Link, useRouteMatch, useLocation } from 'react-router-dom';


const commissions = observer(() => {
  let { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <PageWrapper style={{ margin: '0 4px', height: '98%' }}>
              <div className="tabs">
                <Tabs />
              </div>
              <Route exact path={`/iso/commissions`}>
                <IsoCommissions/>
              </Route>
              <Route exact path={`/iso/commissions/payout`}>
                <IsoCommissionsPayout/>
              </Route>
              <Route exact path={`/iso/commissions/agent`}>
                <IsoCommissionsAgent/>
              </Route>
            </PageWrapper>
          ) : (
            <Wrapper style={{width: '99%', margin: '0px 5px'}}>
                <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    You are not authorized to view this page.
                </div>
            </Wrapper>
          )}
        </>
      )}
    </Observer>
  );
});

const Tabs = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  return (
      <div className="tabWrapper">
          <Link className={`tab one ${location.pathname === '/iso/commissions' ? 'active' : ''}`} to={{pathname: '/iso/commissions'}} >
              Report
          </Link>
          <Link className={`tab two ${location.pathname === '/iso/commissions/payout' ? 'active' : ''}`}  to={{pathname: '/iso/commissions/payout'}}>
              Payout
          </Link>
          <Link className={`tab three ${location.pathname === '/iso/commissions/agent' ? 'active' : ''}`}  to={{pathname: '/iso/commissions/agent'}}>
              Agent
          </Link>
      </div>
  );
};

const PageWrapper = styled.div`
  // height: 96%;
  // flex-direction: column;
  // margin: 0 4px;

  // .content {
  //   height: 100%;
  //   width: 100%;
  //   grid-area: content;
  // }

  // grid-template-areas: 
  //         'tabs'
  //          'content';
  // grid-template-rows: auto 1fr;
  // grid-template-columns: 1fr;
  // grid-gap: 5px;


  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;
    margin-bottom: 4px;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }

    .test {
      margin-left: 5px;
    }
  }

`;

export default commissions;
