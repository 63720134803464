import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import {Observer, observer} from 'mobx-react-lite';
import {useCheckAuthorizedRoute, useInjectStores} from 'utils/utils';
import {RouteLoadingSpinner} from 'components/UI';
import ReactGA from 'react-ga';
import {Link, Route, useLocation, useRouteMatch} from "react-router-dom";
import Tax1099kWidget from "../../components/Widgets/Tax1099k/Tax1099k";

const Tax1099k = observer(() => {
    const {loading, authorized} = useCheckAuthorizedRoute();
    const {userStore} = useInjectStores();
    const user = userStore.user;
    useEffect(() => {
        ReactGA.initialize('UA-195070931-1', {
            gaOptions: {
                clientId: user.currentClient.id,
                clientName: user.currentClient.clientName,
                userEmail: user.email
            },
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
    return (
        <Observer>
            {() => (
                <>
                    {loading ? (
                        <RouteLoadingSpinner size={'400px'}/>
                    ) : authorized ? (
                        <div style={{height: '100%', width: '100%'}}>
                            <Wrapper>
                                <div className="content">
                                    <Tax1099kWidget className="txns"/>
                                </div>
                            </Wrapper>
                        </div>
                    ) : (
                        <div>error</div>
                    )}
                </>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  height: 100%;
  flex-direction: column;
  margin: 0 4px;

  .content {
    height: 100%;
    width: 100%;
    grid-area: content;
  }

  grid-template-areas: 
           'content';
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 5px;
`;

export default Tax1099k;
