import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import { Dropdown, Input } from 'components/UI';
import React from 'react';
import styled from 'styled-components/macro';
import { _get } from 'utils/utils';
import { Credentials } from './Credentials';
import { Grid } from 'styles/styledComponents';

export const MidAggrForms = observer((props) => {
  // const { isProc, gwProc, gwOptions, procOptions, children } = useProps(props);

  const labelWidth = '210px';
  const apiScrape = [
    {
      value: 1,
      text: 'Scrape',
      key: 1,
    },
    {
      value: 2,
      text: 'API',
      key: 2,
    },
  ];
  const scrape = [
    {
      value: 1,
      text: 'Scrape',
      key: 1,
    },
  ];

  const store = useLocalStore(() => ({
    /*get dataSourceOptions() {
      //usaEpay
      if (props.gwProc.aggrID === 64) {
        return apiScrape;
      }
      //authnet
      if (props.gwProc.aggrID === 5) {
        return apiScrape;
      }
      //cybersource
      if (props.gwProc.aggrID === 86) {
        return scrape;
      }
      if (props.gwProc.aggrID === 14) {
        return scrape;
      }
      // nmi
      if (props.gwProc.aggrID === 39) {
        return scrape;
      }
      // everest
      if (props.gwProc.aggrID === 154) {
        return scrape;
      }
      return [];*/
    // },
  }));

  return (
    <Observer>
      {() => (
        <Grid margin={'0 0 10px 0'} width={'1040px'} cmin={'350px'} cmax={'1fr'}>
          <div style={{ position: 'relative' }}>
            {props.children}
            <Observer className="aggregator">
              {() => (
                <Dropdown
                  tabIndex={20}
                  placeholder={props.isProc ? 'Select Processor' : 'Select Gateway'}
                  value={props.gwProc.integration}
                  onChange={props.gwProc.selectIntegration}
                  loading={props.isProc ? props.store?.procOptions?.length === 0 : props.store?.gwOptions?.length === 0}
                  options={props.isProc ? props.store?.procOptions : props.store?.gwOptions}
                  required
                  label={props.isProc ? 'Select Processor' : 'Select Gateway'}
                  labelWidth={labelWidth}
                  search
                />
              )}
            </Observer>
            {/*{props.gwProc.aggrID && props.gwProc.aggrID !== 148 && (*/}
            {/* <Observer className="dataSource">
              {() => (
                <Dropdown
                  tabIndex={19}
                  placeholder="Select Datasource"
                  value={props.gwProc.dataSource}
                  onChange={props.gwProc.setDataSourceID}
                  options={store.dataSourceOptions}
                  label="Select Datasource"
                  labelWidth={labelWidth}
                  required
                  search
                />
              )}
            </Observer>*/}
            {/*)}*/}
            {/*<Observer className="startDt">
              {() => (
                <Input
                  tabIndex={24}
                  placeholder="Enter start date"
                  value={_get(props.gwProc, 'startDate')}
                  onChange={props.gwProc.setStartDt}
                  required
                  label="Start Date"
                  labelWidth={labelWidth}
                />
              )}
            </Observer>*/}
            {/* <Observer className="endDt">
              {() => (
                <Input
                  tabIndex={25}
                  placeholder="Enter end date (YYYY-MM-DD)"
                  value={_get(props.gwProc, 'endDate')}
                  onChange={props.gwProc.setEndDt}
                  required
                  label="End Date"
                  labelWidth={labelWidth}
                />
              )}
            </Observer>*/}
          </div>
          <div style={{ position: 'relative' }}>
            <Credentials gwProc={props.gwProc} />
          </div>
        </Grid>
      )}
    </Observer>
  );
});

export const ProcGateway = styled.div`
  display: flex;
  justify-content: center;
  width: 1040px;
  margin-top: 20px;
  .divider {
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.text}`};
  }
`;
