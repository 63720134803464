import { _filter } from 'utils/utils';

function commonFilterProps(store, ccBrandIds) {
  return {
    custName: {
      get: store.commonFilterModel.custName,
      set: (e) => store.commonFilterModel.setModel('custName', e.target.value),
      reset: () => store.commonFilterModel.reset('custName'),
    },
    email: {
      get: store.commonFilterModel.email,
      set: (e) => store.commonFilterModel.setModel('email', e.target.value),
      reset: () => store.commonFilterModel.reset('email'),
    },
    clientCustID: {
      get: store.commonFilterModel.clientCustID,
      set: (e) => store.validateNumber(store.commonFilterModel, 'clientCustID', e.target.value),
      reset: () => store.commonFilterModel.reset('clientCustID'),
      error: store.commonFilterModel.clientCustIDError,
      blur: store.filterStore.resetErrors,
    },
    eygCustID: {
      get: store.commonFilterModel.eygCustID,
      set: (e) => store.validateNumber(store.commonFilterModel, 'eygCustID', e.target.value),
      reset: () => store.commonFilterModel.reset('eygCustID'),
      error: store.commonFilterModel.eygCustIDError,
      blur: store.filterStore.resetErrors,
    },
    ccBin: {
      get: store.commonFilterModel.ccBin,
      set: (e) => store.commonFilterModel.setModel('ccBin', e.target.value),
      reset: () => store.commonFilterModel.reset('ccBin'),
      error: store.commonFilterModel.ccBinError,
      blur: store.filterStore.resetErrors,
    },
    ccLast4: {
      get: store.commonFilterModel.ccLast4,
      set: (e) => store.commonFilterModel.setModel('ccLast4', e.target.value),
      reset: () => store.commonFilterModel.reset('ccLast4'),
      error: store.commonFilterModel.ccLast4Error,
      blur: store.filterStore.resetErrors,
    },
    ccBrandID: {
      options: ccBrandIds,
      get: store.commonFilterModel.ccBrandID,
      set: (value) => store.validateNumber(store.commonFilterModel, 'ccBrandID', value),
      reset: () => store.commonFilterModel.reset('ccBrandID'),
      error: store.commonFilterModel.ccBrandIDError,
      blur: store.filterStore.resetErrors,
    },
    midNum: {
      options: store.commonFilterModel.clientMidOptions,
      get: store.commonFilterModel.clientMids,
      set: (value) => store.commonFilterModel.selectClientMids(value),
      reset: (value) => store.commonFilterModel.filterClientMids(value),
      error: store.commonFilterModel.midIDError,
      blur: store.filterStore.resetErrors,
    },
  };
}

function txnFilterProps(store) {
  return {
    txnTypeID: {
      options: store.transactionFilterModel.txnTypeOptions,
      get: store.transactionFilterModel.txnTypeID,
      set: (value) => store.transactionFilterModel.onChangeTxnType(value),
      reset: (value) => store.transactionFilterModel.filterTxnType(value),
      error: store.transactionFilterModel.txnTypeIDError,
      blur: store.filterStore.resetErrors,
    },
    txnStatusIDL: {
      options: store.transactionFilterModel.txnStatusIDLOptions,
      get: store.transactionFilterModel.txnStatusIDL,
      set: (value) => store.transactionFilterModel.onChangeTxnStatusIDL(value),
      reset: (value) => store.transactionFilterModel.filterTxnStatusIDL(value),
      error: store.transactionFilterModel.txnStatusIDLError,
      blur: store.filterStore.resetErrors,
    },
    category: {
      get: store.transactionFilterModel.category,
      set: (e) => store.transactionFilterModel.setModel('category', e.target.value),
      reset: () => store.transactionFilterModel.reset('category'),
      error: store.transactionFilterModel.categoryError,
      blur: store.filterStore.resetErrors,
    },
    group: {
      get: store.transactionFilterModel.group,
      set: (e) => store.transactionFilterModel.setModel('group', e.target.value),
      reset: () => store.transactionFilterModel.reset('group'),
      error: store.transactionFilterModel.groupError,
      blur: store.filterStore.resetErrors,
    },
    invNum: {
      get: store.transactionFilterModel.invNum,
      set: (e) => store.transactionFilterModel.setModel('invNum', e.target.value),
      reset: () => store.transactionFilterModel.reset('invNum'),
      error: store.transactionFilterModel.invNumError,
      blur: store.filterStore.resetErrors,
    },
    origCustName: {
      get: store.transactionFilterModel.origCustName,
      set: (e) => store.transactionFilterModel.setModel('origCustName', e.target.value),
      reset: () => store.transactionFilterModel.reset('origCustName'),
      error: store.transactionFilterModel.origCustNameError,
      blur: store.filterStore.resetErrors,
    },
  };
}

function opportunityFilterProps(store) {
  return {
    collected: {
      get: store.opportunityFilterModel.collected,
      set: (e) => store.opportunityFilterModel.setModel('collected', e.target.value),
      reset: () => store.opportunityFilterModel.reset('collected'),
      error: store.opportunityFilterModel.Error,
      blur: store.filterStore.resetErrors,
    },
    category: {
      get: store.opportunityFilterModel.category,
      set: (e) => store.opportunityFilterModel.setModel('category', e.target.value),
      reset: () => store.opportunityFilterModel.reset('category'),
      error: store.opportunityFilterModel.categoryError,
      blur: store.filterStore.resetErrors,
    },
  };
}

function declineFilterProps(store, declBkt, txnTypes, declRsn) {
  return {
    declBktID: {
      options: declBkt,
      get: store.declineFilterModel.declBktID,
      set: (value) => store.validateNumber(store.declineFilterModel, 'declBktID', value),
      error: store.declineFilterModel.declBktIDError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('declBktID'),
    },
    txnTypeID: {
      options: _filter(txnTypes, (option) => {
        return option.text === 'Refund' || option.text === 'Sale';
      }),
      get: store.declineFilterModel.txnTypeID,
      set: (value) => store.validateNumber(store.declineFilterModel, 'txnTypeID', value),
      error: store.declineFilterModel.txnTypeIDError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('txnTypeID'),
    },
    respCodeID: {
      options: declRsn,
      get: store.declineFilterModel.respCodeID,
      set: (value) => store.validateNumber(store.declineFilterModel, 'respCodeID', value),
      error: store.declineFilterModel.respCodeIDError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('respCodeID'),
    },
    invNum: {
      get: store.declineFilterModel.invNum,
      set: (e) => store.declineFilterModel.setModel('invNum', e.target.value),
      error: store.declineFilterModel.invNumError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('invNum'),
    },
    origCustName: {
      get: store.declineFilterModel.origCustName,
      set: (e) => store.declineFilterModel.setModel('origCustName', e.target.value),
      error: store.declineFilterModel.origCustNameError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('origCustName'),
    },
    denialBkt: {
      get: store.declineFilterModel.denialBkt,
      set: (e) => store.declineFilterModel.setModel('denialBkt', e.target.value),
      error: store.declineFilterModel.denialBktError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('denialBkt'),
    },
    preventable: {
      get: store.declineFilterModel.preventable,
      set: (e) => store.declineFilterModel.setModel('preventable', e.target.value),
      error: store.declineFilterModel.preventableError,
      blur: store.filterStore.resetErrors,
      reset: () => store.declineFilterModel.reset('preventable'),
    },
  };
}

function chargebackFilterProps(store, txnTypes, chgbkRsn) {
  return {
    txnStatusIDL: {
      options: txnTypes,
      get: store.chargebackFilterModel.txnStatusIDL,
      set: (value) => store.chargebackFilterModel.setModel('txnStatusIDL', value),
      error: store.chargebackFilterModel.txnStatusIDL,
      blur: store.filterStore.resetErrors,
      reset: () => store.chargebackFilterModel.reset('txnStatusIDL'),
    },
    chgbkCodeID: {
      options: chgbkRsn,
      get: store.chargebackFilterModel.chgbkCodeID,
      set: (value) => store.chargebackFilterModel.setModel('chgbkCodeID', value),
      error: store.chargebackFilterModel.chgbkCodeID,
      blur: store.filterStore.resetErrors,
      reset: () => store.chargebackFilterModel.reset('chgbkCodeID'),
    },
  };
}

export { chargebackFilterProps, commonFilterProps, declineFilterProps, txnFilterProps, opportunityFilterProps };
