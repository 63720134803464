import React, { useEffect } from 'react';
import { _get, _includes, _toPairs, useInjectStores } from 'utils/utils';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import {  LoadingSpinner, Dropdown } from 'components/UI';
import {
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { widgetService } from 'services';
import { autorun } from 'mobx';
import styled from "styled-components/macro";
import Moment from "moment/moment";


const IsoPassThroughLossesSummary = observer(() => {
  const { dataManagementStore, utilsStore, widgetStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel;
  const toDateFormatter = utilsStore.toDateFormatter;
  const toCurrencyFormatter = utilsStore.toCurrencyFormatter;


  const store = useLocalObservable(() => ({
    isLoading: false,
    widgetData: null,
    error: {},  
    async getData() {
      this.isLoading = true;
      widgetModel.setIsHistoric(true);
      try {
        const res = await widgetModel.fetchWidgetData(widgetService.isoPassThroughLossesSummaryMonthly);
        this.widgetData = res.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
      widgetModel.setIsHistoric(false);
    },
    get dropdownOptions() {
      if (this.widgetData?.history?.length > 0) {
          return this.widgetData?.history?.map(({fee_dt_eom}, index) => {
              return {
                  key: index,
                  text: `${Moment(fee_dt_eom).format('YYYY')}: ${Moment(fee_dt_eom).format('MMMM')}`,
                  value: fee_dt_eom
              };
          });
      }
      return [];
    },
    selectOption(val) {
      this.selectedOption = val;
      widgetModel.setDate(val);
      this.getData();
    },  
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          await store.getData();
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <TableWrapper height={'98%'}>
          <div  style={{
              width: 'calc(100% - 40px)',
              margin: '3px 0',
              alignItems: 'center',
              display: 'flex'
          }}>
              <Dropdown
                  id="historical"
                  options={store.dropdownOptions}
                  value={widgetModel.date}
                  onChange={store.selectOption}
                  placeholder="Select Month"
                  width={'140px'}
                  height={'20px'}
              />
          </div>
          {store.isLoading && <LoadingSpinner size={'200px'} />}
          <TableContainer width={'99%'} margin="0 10px">
            <Table style={{width: '100%'}}>
              <TableBody>
                {!store.isLoading && (
                  <>
                    <TableRow style={{borderBottom: '2px solid rgba(255,255,255,.3)'}}>
                      <HeaderCell style={{textAlign: 'center', fontWeight: 'bold' }}>ET Platform Name</HeaderCell>
                      <HeaderCell style={{textAlign: 'center', fontWeight: 'bold' }}>Fee Date EOM</HeaderCell>
                      <HeaderCell style={{textAlign: 'left', fontWeight: 'bold' }}>Fee Category Desc</HeaderCell>
                      <HeaderCell style={{textAlign: 'left', fontWeight: 'bold' }}>Pass Thru Desc</HeaderCell>
                      <HeaderCell style={{textAlign: 'center', fontWeight: 'bold' }}>Card Brand</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Passthru Income</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Passthru Expense</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Passthru Loss</HeaderCell>
                    </TableRow>
                    {store.widgetData?.values?.length > 0 ? (
                      <>
                        {store.widgetData.values.map((detail, index) => {
							const lastRow = index === store.widgetData.values.length - 1 ? true : false;
                          	return (
								<TableRow key={`summary-${index}`}>
									<TableCell style={{textAlign: 'center' }} showTotalsLine={lastRow}>{_get(detail, 'et_platfrm_nm')}</TableCell>
                  <TableCell style={{textAlign: 'center' }} showTotalsLine={lastRow}>{toDateFormatter({value: _get(detail, 'fee_dt_eom')})}</TableCell>
									<TableCell style={{textAlign: 'left' }} showTotalsLine={lastRow}>{_get(detail, 'fee_cat_desc')}</TableCell>
                  <TableCell style={{textAlign: 'left' }} showTotalsLine={lastRow}>{_get(detail, 'pass_thru_desc')}</TableCell>
									<TableCell style={{textAlign: 'center' }} showTotalsLine={lastRow}>{_get(detail, 'card_brnd')}</TableCell>
									<TableCell showTotalsLine={lastRow}>{toCurrencyFormatter({value : _get(detail, 'passthru_income')})}</TableCell>
									<TableCell showTotalsLine={lastRow}>{toCurrencyFormatter({value : _get(detail, 'passthru_expense')})}</TableCell>
									<TableCell showTotalsLine={lastRow}>{toCurrencyFormatter({value : _get(detail, 'passthru_loss')})}</TableCell>
								</TableRow>
							);
                        })}
                      </>
                    ) : (
						<TableRow>
							<TableCell colSpan={8} style={{textAlign: 'center', fontWeight: 'bold', paddingTop: '100px'}}>No data available for the selected time period</TableCell>
						</TableRow>
					)}
                  </>
                )}
              </TableBody>
            </Table>
            {/* <div css={`font-size: 12px; padding: 0 10px; margin-top: 200px`}>
              We report fees once the industry assesses and calculates them. The industry calculates fees in batches and NOT by individual transaction!
            </div>
            <br/>
            <div css={`font-size: 12px; padding: 0 10px;`}>
              {`When attempting to calculate the rate of merchanting fees one should exclude penalties and fixed fees (such as statement fees, PCI compliance fees, and equipment fees). Also, fees should be divided by the gross total of all transactions (sales, refunds, and declines) and not by net deposits; because all transactions have fees.`}
            </div>
            <br/>
            <div css={`font-size: 12px; padding: 0 10px;`}>
              {`Fee ratios will vary on a monthly basis depending on your mix of debit card vs credit card transactions.`}
            </div>
            <br/>

            <StyledNote>
              <span className="note">*</span>
              <span className="noteBody"> See the "Fee Notes" section for important facts and information about fees.</span>
            </StyledNote> */}
          </TableContainer>
        </TableWrapper>
      )}
    </Observer>
  );
});

export default IsoPassThroughLossesSummary;

// const StyledNote = styled.div`
//   //display: flex;
//   //flex-direction: column;
//   height: 100%;
//   //justify-content: flex-end;
//   align-items: center;
//   //font-size: 10px;
//   padding: 0 10px;
//   padding-bottom: 2rem;
//  font-size: 12px;
//   .note {
//     font-size: 12px;
//     color: red;
//   }

//   .noteBody {
//     font-size: 12px;
//     //font-style: italic;
//     color: ${({theme}) => theme.baseColors.whiteAlpha8};
//   }
// `;