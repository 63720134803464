import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';

const { string, maybe } = types;

const OpportunityFilter = types
  .model({
    collected: maybe(string),
    category: maybe(string),
  })
  .volatile((self) => ({
    initialState: {},
    collectedError: false,
    categoryError: false,
  }))
  .views((self) => ({}))
  .actions((self) => {
    function afterCreate() {
      self.initialState = getSnapshot(self);
    }
    function setModel(name, value) {
      self[`${name}Error`] = false;
      self[name] = value;
    }
    function setCategory(category) {
      self.category = category;
    }
    function setCollected(collected) {
      self.collected = collected;
    }
    function mapTxnDropdownsWithModel() {
      checkTxnStatusIDLOptFromModel();
      checkTxnTypeOptFromModel();
    }
    function checkTxnStatusIDLOptFromModel() {
      // checkOptionsFromModel(self.txnStatusIDL, self.txnStatusIDLOptions, setTxnStatusIDLOptions);
    }
    function checkTxnTypeOptFromModel() {
      // checkOptionsFromModel(self.txnTypeID, self.txnTypeOptions, setTxnTypeOptions);
    }

    // Select all but don't use any value (need this for not showing the pills)
    // Select all use all values (pills) - deselect all only when using the values is the applicable
    // Select all always check all boxes
    // If any box is checked besides the all all is not checked
    // always set options based on whats in the model.

    function reset(name, value) {
      self[name] = value;
    }
    function setError(name) {
      self[name] = true;
    }
    function resetErrors() {
      self.collectedError = false;
      self.categoryError = false;
    }
    function resetState() {
      applySnapshot(self, self.initialState);
    }
    return {
      afterCreate,
      setModel,
      setCollected,
      setCategory,
      mapTxnDropdownsWithModel,
      reset,
      setError,
      resetErrors,
      resetState,
    };
  });

export default OpportunityFilter;
