import React from 'react';
import styled from 'styled-components/macro';
import { _get } from 'utils/utils';

const Error = ({ error }) => {
  // const status = _get(error, 'response.status');
  // const statusText = _get(error, 'response.statusText');
  // const message = _get(error, 'response.data.message');
  // const name = _get(error, 'response.data.name', '');
  // const path = _get(error, 'response.data.path', '');
  // const success = _get(error, 'response.data.success', '');
  const errors = _get(error, 'response.data.messages', []);
  const config = _get(error, 'response.config');
  const { status, statusText } = error?.response;
  let errorText = '';
  const errorMessage = error?.response?.data?.message;
  const generateAuthError = () => {
    return (
      <ErrorWrapper>
        <div className={'container'}>
          <p style={{color: 'red', fontSize: '1.5rem'}}>Unauthorized</p>
          <p>You are not authorized to view this page</p>
        </div>
      </ErrorWrapper>
    )
  }

  const generateDefaultErrorMessage = () => {
  
    return (
      <ErrorWrapper>
        <div className={'container'}>
          <div className={'message'}>Endpoint: {config.url}</div>
          <div>
            Error Code {status}: {statusText}
          </div>

          <div className="errors">
            {errors?.length > 0 && (
              <>
                {errors?.map((error, index) => (
                  <div
                    css={`
                      margin: 8px;
                    `}
                    key={error}
                  >
                    {index + 1}. {error}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </ErrorWrapper>
    )
  }

  return (
    status === 400 && errorMessage === 'Unauthorized' ? generateAuthError() : generateDefaultErrorMessage()
  );
};

const ErrorWrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  .container {
    width: auto;
    height: auto;
    .message {
      text-align: center;
      margin-bottom: 8px;
    }
    .errors {
    }
  }
`;

export default Error;
