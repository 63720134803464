import React, { useEffect, useState } from 'react';
import { useLocalStore, Observer, observer } from 'mobx-react-lite';
import { Input, Dropdown, Checkbox, LoadingSpinner, Button } from 'components/UI';
import styled from 'styled-components/macro';
import {adminStore} from 'stores/AdminStore';
import {useInjectStores, _get} from 'utils/utils';



const MerchForm = observer(({isEdit, merchData}) => {
	const {userStore, uiStore} = useInjectStores();
	const clientId = _get(userStore, 'user.currentClient.id');
	const userRole = _get(userStore, 'user.userRole');
	const isSuperAdmin = userRole === 'Eight' || userRole === 'SAdmin';
	const [mids, setMids] = useState([]);
	const [loading, setLoading] = useState(false);
	const notify = uiStore.notify;
	const cleanObj = {
		pricingModel: '',
		adminName:'',
		name: '', 
		isCorporate: false, 
		corporateName:'', 
		adminEmail: '', 
		adminPhone: '' ,
		adminPassword: '',
		merchId: '',
		gateway: true,
		gatewayName: '',
		creditLimit: 0,
		mid: '',
		gatewayUsername: '',
		gatewayPassword: '',
		gatewayKey: '',
		gatewayExpDate: ''
	};

	useEffect(() => {
		const grabMidData = async () => {
			const data = await adminStore.getMerchantAdd(clientId);
			const formattedMids = data.map(mid => {
				return {
					text: mid.et_merch_nm_dsply + ` - ${mid.mid}`,
					value: mid.et_merch_nm_dsply + ` - ${mid.mid}`
				};
			});
			if(isEdit) {
				formattedMids.push({
					text: merchData.displayName + ` - ${merchData.mid}`,
					value: merchData.displayName + ` - ${merchData.mid}`
				})
			}
			setMids(formattedMids);
		};

		grabMidData();
	}, []);

	const [merch, setMerch] = useState(isEdit ? merchData : cleanObj);

	const [errors, setErrors] = useState({});

	const validate = () => {
		let localErrors = {};
		if (!merch.name.trim()) {
			localErrors.name = "Merchant Name is required";
		}
		if (!merch.mid.trim()) {
			localErrors.mid = "MID is required";
		}
		if (!merch.pricingModel.trim()) {
			localErrors.pricingModel = "Pricing Model is required";
		}
		if (!merch.adminName.trim()) {
			localErrors.adminName = "Admin Name is required";
		}
		if (!merch.adminEmail.trim()) {
			localErrors.adminEmail = "Admin Email is required";
		}
		if (!merch.adminPassword?.trim() && !isEdit) {
			localErrors.adminPassword = "Admin Password is required";
		}
		
		return localErrors;
    };

	const handleChange = (event) => {
		setMerch({
			...merch,
			[event.target.name]: event.target.value
		});
	};

	const handleMerchChange = (option) => {
		const [name, mid] = option.split('-');
		setMerch({
			...merch,
			mid: mid,
			merchId: option,
			name: name
		});
	};

	const handleGatewayNameChange = (option) => {
		setMerch({
			...merch,
			gatewayName: option
		});
	};

	const handleCorporateChange = () => {
		setMerch({
			...merch,
			isCorporate: !merch.isCorporate
		});
	};

	const handlePricingModelChange = (option) => {
		setMerch({
			...merch,
			pricingModel: option
		});
	};

	const submitMerchCreateForm = async () => {
		const localErrors = validate();
		if (Object.keys(localErrors).length > 0) {
            setErrors(localErrors);
        } else {
            setErrors({});
			const formattedData = {
				clientID: clientId,
				pricingModelNm: merch.pricingModel, 
				userName: merch.adminName, 
				merchName: merch.name, 
				corpModelTf: merch.isCorporate, 
				parentCoNm: merch.corporateName,
				userEmail: merch.adminEmail, 
				userCellPh: merch.adminPhone,
				password: merch.adminPassword, 
				mid: merch.mid,
				gwTf: merch.gateway, 
				gwNm: merch.gatewayName, 
				moCrLimit: merch.creditLimit,
				gwUsername: merch.gatewayUsername,
				gwPassword: merch.gatewayPassword,
				gwTxnkey: merch.gatewayKey,
				gwExpiration: merch.gatewayExpDate
			};
			setLoading(true);
			const response = await adminStore.createMerchant(formattedData);
			if(response) {
				notify({
					duration: 1500,
					group: 'topRight',
					text: `Successfully Created Merchant!`
				});
				setMerch(cleanObj);
				setLoading(false);
			}else {
				notify({
					type: 'error',
					duration: 3000,
					group: 'topRight',
					text: `Error Creating Merchant! Check all fields and try again`
				});
				setLoading(false);
			}
        }
	};

	const submitMerchEditForm = async () => {
		const localErrors = validate();
		if (Object.keys(localErrors).length > 0) {
            setErrors(localErrors);
        } else {
            setErrors({});
			const formattedData = {
				clientID: clientId,
				etPricingModel: merch.pricingModel, 
				userName: merch.adminName, 
				merchName: merch.name, 
				corpModelTf: merch.isCorporate, 
				parentCoNm: merch.corporateName,
				usersEmail: merch.adminEmail, 
				userCellPh: merch.adminPhone,
				adminPassword: merch.adminPassword, 
				mid: merch.mid,
				gwTf: merch.gateway, 
				gwNm: merch.gatewayName, 
				moCrLimit: merch.creditLimit,
				gwUsername: merch.gatewayUsername,
				gwPassword: merch.gatewayPassword,
				gwTxnkey: merch.gatewayKey,
				gwExpiration: merch.gatewayExpDate
			};
			setLoading(true);
			const response = await adminStore.editMerchant(formattedData);
			if(response) {
				notify({
					duration: 1500,
					group: 'topRight',
					text: `Successfully Updated Merchant!`
				});
				setLoading(false);
			}else {
				notify({
					type: 'error',
					duration: 3000,
					group: 'topRight',
					text: `Error Updating Merchant!`
				});
				setLoading(false);
			}
		}
	};

	const handleSubmit = () => {
		if(isEdit) {
			submitMerchEditForm();
		} else {
			submitMerchCreateForm();
		}
	};

	return (
			
			<Wrapper>
				{!loading ? (
				<form className="form-wrapper">
					<div className="input-container">
					<h3>MID Info:</h3>
					<div style={{display: 'flex', flexDirection: 'column', pointerEvents: !isEdit ? '' : 'none'}}>
						{errors.mid ? <span style={{color: 'red', textAlign: 'center'}}>{errors.mid}</span> : null }
						<Dropdown
							onChange={handleMerchChange}
							value={merch.merchId}
							name="merchId"
							options={mids}
							border
							required
							label="MID:"
							tabIndex={1}
							width={'100%'}
							disabled={isEdit ? true : false}
						/>
					</div>
					<Input
						disabled
						onChange={handleChange}
						value={merch.name}
						name="name"
						border
						required
						error={errors.name && errors.name}
						label="Merchant Name:"
						tabIndex={2}
						width={'100%'}
					/>
					<Input
						onChange={handleChange}
						value={merch.displayName}
						name="displayName"
						border
						label="Merchant Display Name:"
						tabIndex={3}
						width={'100%'}
					/>
					<Input
						type="number"
						onChange={handleChange}
						value={merch.creditLimit}
						name="creditLimit"
						border
						label="Monthly Credit Limit:"
						tabIndex={4}
						width={'100%'}
						disabled={!isSuperAdmin}
					/>
					<h3>MID Admin Info:</h3>
					<Input
						onChange={handleChange}
						value={merch.adminName}
						name="adminName"
						border
						required
						error={errors.adminName && errors.adminName}
						label="MID Admin Name:"
						tabIndex={8}
						width={'100%'}
						disabled={!isSuperAdmin}
					/>
					<Input
						onChange={handleChange}
						value={merch.adminEmail}
						name="adminEmail"
						border
						required
						error={errors.adminEmail && errors.adminEmail}
						label="MID Admin Email:"
						tabIndex={9}
						width={'100%'}
						disabled={!isSuperAdmin}
					/>
					<Input
						onChange={handleChange}
						value={merch.adminPhone}
						name="adminPhone"
						border
						label="MID Admin Cell Phone:"
						tabIndex={10}
						width={'100%'}
						disabled={!isSuperAdmin}
					/>
					<Input
						onChange={handleChange}
						value={merch.adminPassword}
						type="password"
						name="adminPassword"
						border
						required={isEdit ? false : true}
						error={errors.adminPassword && errors.adminPassword}
						label="MID Admin Password:"
						tabIndex={11}
						width={'100%'}
						disabled={!isSuperAdmin}
					/>
					<h3>MID View:</h3>
					<Checkbox
						checked={merch.isCorporate}
						onChange={handleCorporateChange}
						label="Corporate View:"
						tabIndex={12}
						disabled={!isSuperAdmin}
					/>
					{merch.isCorporate ? (
						<Input
						onChange={handleChange}
						value={merch.corporateName}
						name="corporateName"
						border
						label="Corportate (Multiple MIDs combined)"
						tabIndex={13}
						width={'100%'}
						disabled={!isSuperAdmin}
					/>) : null }
					<p><span style={{color: 'red'}}>*</span> There should be an Admin for each MID</p>
					<p>When an Admin is added to Corporate View, then the email has to be different than the on set up for a single MID.</p>
					</div>
					<div className="input-container">
					<h3>Pricing:</h3>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						{errors.pricingModel ? <span style={{color: 'red', textAlign: 'center'}}>{errors.pricingModel}</span> : null }
						<Dropdown
							onChange={handlePricingModelChange}
							value={merch.pricingModel}
							name="pricingModel"
							options={[
								{value: 'Basic', text: 'Basic'}, 
								{value: 'Standard', text: 'Standard'},
								{value: 'Advanced', text: 'Advanced'},
								{value: 'Professional', text: 'Professional'}
							]}
							border
							required
							error={errors.pricingModel && errors.pricingModel}
							label="Pricing Model:"
							tabIndex={14}
							width={'100%'}
						/>
					</div>
					<h3>Gateway Info:</h3>
					<Dropdown
						onChange={handleGatewayNameChange}
						value={merch.gatewayName}
						name="gatewayName"
						options={[
							{value: 'Authorize.net', text: 'Authorize.net'}, 
							{value: 'Cybersource', text: 'Cybersource'},
							{value: 'Everest', text: 'Everest'},
							{value: 'NMI', text: 'NMI'},
							{value: 'Paytrace', text: 'Paytrace'},
							{value: 'USAePay', text: 'USAePay'},
							{value: 'Other', text: 'Other'}
						]}
						border
						label="Gateway Name:"
						tabIndex={6}
						width={'100%'}
					/>
					{merch.gatewayName === 'Other' ? (<Input
						onChange={handleChange}
						value={merch.gatewayOther}
						name="gatewayOther"
						border
						label="Gateway Other:"
						tabIndex={7}
						width={'100%'}
					/>) : null}
					<Input
						onChange={handleChange}
						value={merch.gatewayUsername}
						name="gatewayUsername"
						border
						label="Username:"
						tabIndex={15}
						width={'100%'}
					/>
					<Input
						onChange={handleChange}
						value={merch.gatewayPassword}
						type="password"
						name="gatewayPassword"
						border
						label="Password:"
						tabIndex={16}
						width={'100%'}
					/>
					<Input
						onChange={handleChange}
						value={merch.gatewayKey}
						name="gatewayKey"
						border
						label="Transaction Key:"
						tabIndex={17}
						width={'100%'}
					/>
					<div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '5px'}}>
						<label>PW Expiration Date:</label>
						<input style={{width: '100%', maxWidth: '350px', padding: '8px 0px', border: 'solid 1px white', borderRadius: '5px'}} name="gatewayExpDate" onChange={handleChange} type="date" value={merch.gatewayExpDate}/>
					</div>
					<div className="action-section">
						<Button type="submit" className="add-user-btn" onClick={() => handleSubmit()}>
							Submit
						</Button>
					</div>
					</div>
				</form>
				): <LoadingSpinner size={'400px'} />}
			</Wrapper>
	);
})

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	h3 {
		color: ${({ theme }) => theme.colors.primaryText};
		border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
	}
	.form-wrapper {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 5rem;
		.input-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: start;
		}
	}

	.field {
		display: flex;
		justify-content: end !important;
		padding-bottom: 1rem;
		.labelContainer {
			text-align: left;
			min-width: 7rem;
		}
		.inputContainer {
			max-width: 350px;
		}
		.styledWrapper {
			max-width: 350px;
		}
	}

	.action-section {
		display: flex;
		justify-content: end;
		padding-top: 1rem;
		.add-user-btn {
			background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			color: white;
			padding: 1rem 0rem;
			width: 25%
		}
		.cancel-btn {
			background-color: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%;
			text-align: center;
			border-radius: 5px;
		}
		.add-user-btn:hover {
			filter: brightness(85%);
		}
		.add-user-btn:active {
			transform: translateY(2px);
		}
		.package-btn {
			background-color: white;
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%
		}
		.package-btn:hover {
			filter: brightness(85%);
		}
		.package-btn:active {
			transform: translateY(2px);
		}
	}
`;

export default MerchForm;