import React, { useEffect } from 'react';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { useGetWidgetData, useInjectStores } from 'utils/utils';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components/macro';
import { WidgetHeader, WidgetWrapper } from 'styles/styledComponents';
import { LoadingSpinner } from 'components/UI';
import { widgetService } from '../../../../services';

const RptShipTotals = observer(() => {
  const { dataManagementStore, utilsStore } = useInjectStores();
  const toCurrencyFormatter = utilsStore.toCurrencyFormatter;

  const store = useLocalObservable(() => ({
    rowData: [],
    isLoading: false,
    api: null,
    gridColumnApi: null,

    get gridOptions() {
      return {
        enableBrowserTooltips: true,
        tooltipShowDelay: 0,
        reactNext: true,
        enableCharts: true,
        enableRangeSelection: true,
        masterDetail: true,
        rowHeight: 33,
        headerHeight: 33,
        onGridReady: (params) => {
          this.api = params.api;
          this.gridColumnApi = params.columnApi;
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);"> Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
        columnDefs: [
          { headerName: 'Auth Amt', field: 'auth_amt', valueFormatter: toCurrencyFormatter },
          { headerName: 'Category', field: 'category' },
          { headerName: 'Count', field: 'count' },
          { headerName: 'Funding Status', field: 'funding_status' },
          { headerName: 'Settle Amount', field: 'settle_amt', valueFormatter: toCurrencyFormatter },
        ],
        defaultColDef: {
          flex: 1,
          minWidth: 90,
          sortable: false,
          resizable: true,
        },
      };
    },
    setRowData(rowData) {
      const { status, data, error } = rowData;
      if (status === 'loading') this.isLoading = true;
      if (error) this.isLoading = false;
      if (data) {
        this.rowData = data;
        this.isLoading = false;
      }
    },
  }));

  const shipData = useGetWidgetData({
    query: widgetService.shipSummary,
    name: 'ship'
  }, 'data', !!dataManagementStore.newQuery);

  useEffect(() => {
    store.setRowData(shipData);
  }, [shipData]);

  return (
    <Observer>
      {() => (
        <StyledWrapper>
          <WidgetWrapper>
            <WidgetHeader>
              <span className="good">Yes - Ok to Ship</span>
            </WidgetHeader>
            <div
              id="myGrid"
              className={'ag-theme-alpine-dark'}
              style={{
                height: '100%',
                minHeight: '275px',
                width: '100%',
                position: 'relative',
                borderRadius: '5px',
              }}
            >
              {store.isLoading ? (
                <LoadingSpinner size={'200px'} />
              ) : shipData.error ? (
                <div>{shipData.error}</div>
              ) : store.rowData ? (
                <AgGridReact rowData={store.rowData} defaultColDef={store.defaultColDef} gridOptions={store.gridOptions} />
              ) : null}
            </div>
          </WidgetWrapper>
        </StyledWrapper>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;

  .ag-theme-alpine-dark {
    --ag-header-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha9};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #001329;
    --ag-header-background-color: #001329;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    --ag-row-border-color: rgba(255, 255, 255, 0.1);
    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }
    .ag-header-row {
      height: 30px;
    }
  }
  .good {
    color: ${({ theme }) => theme.baseColors.colorGreen};
  }
`;

export default RptShipTotals;
