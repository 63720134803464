import React, { useEffect } from 'react';
import { CaretDownIcon, CaretUpIcon } from 'styles/styledComponents';
import { Input, PopupMenu } from 'components/UI';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import { Item, Logout, PopUpContent, PopUpContentWrapper, SubItem, SubItemWrapper, ViewNameWrapper } from './StyledHeader';
import styled from 'styled-components/macro';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../../../images/GoldenAit/eightLogoSilver.svg';
import { _get, _map, useInjectStores } from 'utils/utils';
import { QueryClient, QueryCache } from 'react-query';
const queryClient = new QueryClient();
const queryCache = new QueryCache();
const Header = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { filterStore, userStore, uiStore } = useInjectStores();
  const clientName = userStore.user.currentClient.clientName;

  const store = useLocalStore(() => ({
    pageTitle: '',
    filter: '',
    showPopupMenu: false,
    showClients: false,
    showName: true,
    setFilter: (e) => {
      store.filter = e.target.value;
    },
    toggleShowClients() {
      store.showClients = true;
    },
    toggleShowName(e) {
      
      if (e.altKey) {
        store.showName = !store.showName
      }
    },

    toggleHideClients() {
      store.showClients = false;
      store.filter = '';
    },
    toggleShowPopupMenu() {
      store.showPopupMenu = !store.showPopupMenu;
      if (!store.showPopupMenu) {
        store.showClients = false;
        store.filter = '';
      }
    },
    get clientOptions() {
      const clients = _get(userStore, 'user.clients');
      let options = [];
      let filterText = store.filter.toLowerCase();
      for (let i = 0; i < clients.length; i++) {
        let txtValue = clients[i].clientName;
        if (txtValue.toLowerCase().indexOf(filterText) > -1) {
          options.push(clients[i]);
        }
      }
      return options;
    },
    get sidebarOpen() {
      return uiStore.showSidebar;
    },
    toggleDarkMode() {
      uiStore.toggleDarkMode();
    },
    get user() {
      return _get(userStore, 'user');
    },
    get userName() {
      let name = _get(userStore, 'user.firstName', '');
      if (name) {
        return `${_get(userStore, 'user.firstName', '')} ${_get(userStore, 'user.lastName[0]', '')}`;
      } else {
        return '';
      }
    },
    async logout() {
      queryClient.clear();
      queryCache.clear();
      await userStore.logout(history, location);
    },
    get clientInfo() {
      return _get(userStore, 'user.clients');
    },
    getPageTitle(path) {
      if (path.includes('dashboard')) {
        return 'Dashboard';
      }
      if (path.includes('opportunity')) {
        return 'Opportunity';
      }
      if (path.includes('transactions')) {
        return 'Transactions';
      }
      if (path.includes('declines')) {
        return 'Declines';
      }
      if (path.includes('chargebacks')) {
        return 'Chargebacks';
      }
      if (path.includes('reserve')) {
        return 'Reserves';
      }
      if (path.includes('journals')) {
        return 'Journals';
      }
      if (path.includes('statement')) {
        return 'Statement';
      }
      if(path.includes('prepaidBilling')) {
        return 'Prepaid Billing';
      }
      if (path.includes('fulfillment')) {
        return 'Fulfillment Report';
      }
      if (path.includes('admin/users/create')) {
        return 'Admin: Create User';
      }
      if (path.includes('admin/users/edit')) {
        return 'Admin: Edit User';
      }
      if (path.includes('admin/clients')) {
        return 'Admin Clients';
      }
      if (path.includes('clientAdmin/users')) {
        return 'Client Admin Users';
      }
      if (path.includes('fees/notes')) {
        return 'Fee Notes';
      }
      if (path.includes('fees/details')) {
        return 'Fee By Category';
      }
      if (path.includes('tax/1099-K')) {
        return '1099-K';
      }
      if (path.includes('admin/abbreviation')) {
        return 'Abbreviation List';
      }
      if (path.includes('admin/merch/settings')) {
        return 'Merchant Settings';
      }
      if (path.includes('admin/merch/add')) {
        return 'Merchant Add';
      }
      if (path.includes('admin/merch/edit')) {
        return 'Merchant Edit';
      }
      if (path.includes('admin/user/settings')) {
        return 'User Settings';
      }
      if (path.includes('admin/user/add')) {
        return 'User Add';
      }
      if (path.includes('admin/user/edit')) {
        return 'User Edit';
      }
      if (path.includes('admin/pricing')) {
        return 'Pricing';
      }
      if (path.includes('report/my-reports')) {
        return 'My Reports';
      }
      if (path.includes('report/all-reports')) {
        return 'All Reports';
      }
      if (path.includes('report/rep01')) {
        return 'Expired Cards (Current Month)';
      }
      if (path.includes('report/rep02')) {
        return 'Expired Cards (Historic Months)';
      }
      if (path.includes('report/rep03')) {
        return 'Do Not Honor (DNH) Details';
      }
      if (path.includes('report/rep04')) {
        return 'Transactions by Batch Number';
      }
      if (path.includes('report/rep05')) {
        return 'Chargeback Abuse by Card';
      }
      if (path.includes('report/rep06')) {
        return 'Refund Abuse by Card';
      }
      if (path.includes('report/rep07')) {
        return 'AVS Failures by Card';
      }
      if (path.includes('report/rep08')) {
        return 'Lost & Stolen Card List';
      }
      if (path.includes('report/rep09')) {
        return 'Invalid & Fraud Card List';
      }
      if (path.includes('report/rep10')) {
        return 'Gift & Prepaid Card List';
      }
      if (path.includes('report/rep12')) {
        return 'Banks on OFAC List';
      }
      if (path.includes('report/rep13')) {
        return 'Banks Suspended or Sanctioned';
      }
      if (path.includes('report/rep14')) {
        return 'Decline Analysis by Card';
      }
      if (path.includes('report/rep15')) {
        return 'BIN Attack Fraud';
      }
      if (path.includes('iso/commissions')) {
        return 'Commissions';
      }
      if (path.includes('iso/profit-loss')) {
        return 'Profit/Loss by MID';
      }
      if (path.includes('iso/pass-through-losses')) {
        return 'Pass-Through Losses';
      }
      if (path.includes('iso/bank-revenue-request')) {
        return 'Bank Revenue Request';
      }
      if (path.includes('iso/journals')) {
        return 'Journals';
      }
    },
  }));

  useEffect(() => {
    store.pageTitle = store.getPageTitle(location.pathname);
  }, [location.pathname]);

  return (
    <Observer>
      {() => (
        <StyledWrapper >
          <div className="one" >
            <img className="logo" src={logo}  onClick={store.toggleShowName} css={`
            z-index: 1
            `}/>
          </div>
          <ViewNameWrapper fontSize={'20px'} className="title">
            {store.pageTitle || ''}
          </ViewNameWrapper>
          {store.showName && <ViewNameWrapper className="two">{clientName}&nbsp;-&nbsp;</ViewNameWrapper>}
          <Logout className="three">
            <div id="logout" onClick={store.toggleShowPopupMenu}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  whiteSpace: 'no-wrap',
                }}
              >
                <div id="logout" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                  {store.userName}
                </div>
                {store.showPopupMenu && <CaretUpIcon />}
                {!store.showPopupMenu && <CaretDownIcon />}
              </div>
            </div>
            {store.showPopupMenu && (
              <PopupMenu
                hidePopup={store.toggleShowPopupMenu}
                top="30px"
                right="0px"
                padding="0px"
                show={store.showPopupMenu}
                width={'155px'}
              >
                <PopUpContent>
                  <PopUpContentWrapper>
                    <Item
                      onMouseEnter={store.toggleShowClients}
                      onMouseLeave={store.toggleHideClients}
                      onClick={store.toggleShowPopupMenu}
                    >
                      Client Info
                    </Item>
                    {store.showClients && (
                      <PopupMenu
                        className={'innerPopup'}
                        onMouseEnter={store.toggleShowClients}
                        hidePopup={store.toggleShowPopupMenu}
                        show={store.showClients}
                        top="0px"
                        right="150px"
                        padding="0px"
                        width={'155px'}
                        maxHeight={'500px'}
                      >
                        <Input id="search" search placeholder="Search Clients" value={store.filter} onChange={store.setFilter} />
                        {_map(store.clientOptions, (client, index) => {
                          return (
                            <SubItemWrapper key={index}>
                              <div className="subItem" />
                              <SubItem
                                onClick={() => {
                                  uiStore.setChangingClient();
                                  store.toggleShowPopupMenu();
                                  filterStore.resetState();
                                  filterStore.applyFilter();
                                  userStore.setSelectedClient(client.id, history, location);
                                }}
                              >
                                {client.clientName}
                              </SubItem>
                            </SubItemWrapper>
                          );
                        })}
                      </PopupMenu>
                    )}

                    <Item onClick={store.logout}>Logout</Item>
                  </PopUpContentWrapper>
                </PopUpContent>
              </PopupMenu>
            )}
          </Logout>
        </StyledWrapper>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  display: grid;
  position: fixed;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);

  .one {
    grid-area: one;
    //width: 200px;
    margin-left: 5px;
    justify-content: center;
    display: flex;
    width: 100%;

    .logo {
      width: 88px;
      //width: 100%;
      height: auto;
      justify-self: center;
      object-fit: contain;
    }
  }
  .two {
    display: flex;
    grid-area: two;
    justify-content: flex-end;
  }
  .three {
    grid-area: three;
    padding-right: 30px;
  }

  grid-template-areas: 'one two three ';
  grid-template-columns: 200px 1fr 150px;
`;

export default Header;
