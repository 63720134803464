import React, { Fragment } from 'react';
import { LoadingSpinner } from 'components/UI';
// import { Purchases, Reconciliation, Returns, Fees, UnsuccessfulSales } from '../../../ReconSummary';
// import MerchantFees from './MerchantFees/MerchantFees';
import styled from 'styled-components';
import { Observer } from 'mobx-react-lite';

const ActualForecastData = (props) => {
  return (
    <Observer>
      {() => (
        <Wrapper>
          {props.store.loading && <LoadingSpinner size={'200px'} />}
          {!props.store.loading && props.store.widgetData && (
            <Fragment>
              {/*<div className="sOneCard">
            <Purchases store={props.store} />
          </div>*/}

              {/*<div className="sOneCard">{<Returns store={props.store} />}</div>

          <div className="sOneCard">
            <Fees store={props.store} />
          </div>

          <div className="sOneCard">{<UnsuccessfulSales store={props.store} />}</div>

          <div className="sOneCard">{<MerchantFees store={props.store} />}</div>

          <div className="sOneCard">
            <Reconciliation store={props.store}/>
          </div>*/}
            </Fragment>
          )}
        </Wrapper>
      )}
    </Observer>
  );
};

const Wrapper = styled.div`
  .sOneCard {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin: 3px 0;
  }
`;

export default ActualForecastData;
