import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme, noBackground, background }) => {
    if (background) {
      return background;
    }
    if (!noBackground) return theme.rgl.gridItem.colors.background;
    else return 'none';
  }};
  width: 100%;
  height: 100%;
  margin: ${({ margin }) => margin};
`;

const LoginWrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
`;

const Button = styled.div`
  align-items: center;
  border: 1px solid grey;
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  padding: 0 5px;
  width: auto;
  &:hover {
    cursor: pointer;
  }
`;

const MsgBox = styled.div`
  align-items: center;
  color: ${({ theme }) => (theme.rgl.gridItem.colors.primaryText ? theme.rgl.gridItem.colors.primaryText : 'black')};
  display: flex;
  font-size: 12px;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

export { Button, MsgBox, LoginWrapper, Wrapper };
