import React from 'react';
import Input from 'components/UI/Input/Input';
import styled from 'styled-components/macro';
import { observer, Observer } from 'mobx-react-lite';
import { MultiSelectDropdown } from 'components/UI';

const TransactionFilter = observer((props) => {
  const invNum = props.transactionFilterProps.invNum,
    origCustName = props.transactionFilterProps.origCustName,
    txnStatusIDL = props.transactionFilterProps.txnStatusIDL,
    txnTypeID = props.transactionFilterProps.txnTypeID;

  return (
    <Observer>
      {() => (
        <Wrapper>
          <FilterTitle>Transaction Filters</FilterTitle>
          <div className="filterBody">
            <Observer>
              {() => (
                <div className="fieldContainer">
                  <MultiSelectDropdown
                    border
                    height={'35px'}
                    optContTop={'25px'}
                    optionPadding="0px"
                    left={'9px'}
                    onChange={txnTypeID.set}
                    options={txnTypeID.options}
                    placeholder={'Txn Type'}
                    margin={'3px 0 3px 0'}
                    tabIndex={8}
                  />
                </div>
              )}
            </Observer>
            <Observer>
              {() => (
                <div className="fieldContainer">
                  <MultiSelectDropdown
                    border
                    height={'35px'}
                    optContTop={'25px'}
                    optionPadding="0px"
                    left={'9px'}
                    onChange={txnStatusIDL.set}
                    options={txnStatusIDL.options}
                    placeholder={'Txn Status'}
                    margin={'0 0 3px 0'}
                    tabIndex={9}
                  />
                </div>
              )}
            </Observer>
            <Observer>
              {() => (
                <div className={'fieldContainer'}>
                  {invNum.get && <label>Inv Num</label>}
                  <Input
                    border
                    marginBottom={'3px'}
                    placeholder={`Inv Num`}
                    value={invNum.get || ''}
                    onChange={invNum.set}
                    onBlur={invNum.blur}
                    error={invNum.error}
                    tabIndex={10}
                    margin={'0 0 3px 0'}
                  />
                  {invNum.error && <div>must be a number </div>}
                </div>
              )}
            </Observer>
            <Observer>
              {() => (
                <div className={'fieldContainer'}>
                  {origCustName.get && <label>Original Name</label>}
                  <Input
                    placeholder={`Original Name`}
                    value={origCustName.get || ''}
                    onChange={origCustName.set}
                    onBlur={origCustName.blur}
                    error={origCustName.error}
                    border
                    margin={'0 0 3px 0'}
                    tabIndex={11}
                  />
                  {origCustName.error && <div>must be a number</div>}
                </div>
              )}
            </Observer>
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  width: 100%;
  color: ${({ theme }) => theme.primaryColor};
  .filterBody {
    padding-right: 2px;
    .fieldContainer {
      outline: none;
      display: flex;
      flex-direction: column;
      margin: 0 1px 0 3px;

      label {
        font-size: 11px;
        margin-left: 2px;
        padding: 3px 0 1px 0;
      }
    }
  }
`;
const FilterTitle = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 7px 0;
  font-weight: bold;
  border-left: 1px solid #fff;
  background-color: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
`;

export default TransactionFilter;
