import React, { Fragment } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { DeleteIcon } from 'styles/styledComponents';
import { Pill } from '../../StyledFilter';
import { _get, _isArray, _find, _toString } from 'utils/utils';

const Pills = observer((props) => {
  const cFilter = props.cFilter,
    sFilter = props.sFilter,
    commonFilterProps = props.commonFilterProps,
    fetchFilteredData = props.fetchFilteredData,
    applyFilter = props.applyFilter,
    rootStore = props.rootStore,
    store = props.store,
    secondaryFilterProps = props.secondaryFilterProps;

  function getMappedDataOptions(name, value) {
    switch (name) {
      case 'ccBrandID':
        let brandIds = rootStore.ccBrandIds;
        let found = _find(brandIds, { value: value });
        return _get(found, 'text');
      case 'chgbkCodeID':
        let chgbkCodeIDs = rootStore.chgbkCodes;
        let foundchgbkID = _find(chgbkCodeIDs, { value: value });
        return _get(foundchgbkID, 'text');
      case 'declBktID':
        let declBkt = rootStore.declBkt;
        let foundbkt = _find(declBkt, { value: value });
        return _get(foundbkt, 'text');
      case 'midNum':
        let mids = store.commonFilterModel.clientMidOptions;
        let foundMid = _find(mids, { value: _toString(value) });
        return _get(foundMid, 'text');
      case 'respCodeID':
        let declRsn = rootStore.declRsn;
        let foundRsn = _find(declRsn, { value: value });
        return _get(foundRsn, 'text');
      /* case 'txnTypeID':
        let txnTypes = store.transactionFilterModel.txnTypeOptions;
        let foundType = _find(txnTypes, { value: value });
        return _get(foundType, 'text');
      case 'txnStatusIDL':
        let txnStatuses = store.transactionFilterModel.txnStatusIDLOptions;
        let foundStatus = _find(txnStatuses, { value: value });
        return _get(foundStatus, 'text');*/
      default:
        return value;
    }
  }

  return (
    <Fragment>
      <Observer>
        {() => (
          <Fragment>
            {Object.keys(cFilter).map(function (keyName, keyIndex) {
              if (_isArray(cFilter[keyName])) {
                return cFilter[keyName].map((value) => (
                  <Pill key={keyName + value + keyIndex}>
                    <div className={'description'}>{getMappedDataOptions(keyName, value)}</div>
                    <DeleteIcon
                      onClick={() => {
                        commonFilterProps[keyName].reset(value);
                        applyFilter();
                        fetchFilteredData();
                      }}
                      color="#808080"
                      style={{ width: 15, height: 15 }}
                    />
                  </Pill>
                ));
              } else {
                return (
                  <Pill key={keyName + keyIndex}>
                    <div className={'description'}>{getMappedDataOptions(keyName, cFilter[keyName])}</div>
                    <DeleteIcon
                      onClick={() => {
                        commonFilterProps[keyName].reset();
                        applyFilter();
                        fetchFilteredData();
                      }}
                      color="#808080"
                      style={{ width: 15, height: 15 }}
                    />
                  </Pill>
                );
              }
            })}
          </Fragment>
        )}
      </Observer>
      <Observer>
        {() => (
          <Fragment>
            {Object.keys(sFilter).map(function (keyName, keyIndex) {
              if (_isArray(sFilter[keyName])) {
                return sFilter[keyName].map((value) => (
                  <Pill key={keyName + value + keyIndex}>
                    <div className={'description'}>{getMappedDataOptions(keyName, value)}</div>
                    <DeleteIcon
                      onClick={() => {
                        secondaryFilterProps[keyName].reset(value);
                        applyFilter();
                        fetchFilteredData();
                      }}
                      color="#808080"
                      style={{ width: 15, height: 15 }}
                    />
                  </Pill>
                ));
              } else {
                return (
                  <Pill key={keyName + keyIndex}>
                    <div className={'description'}>{getMappedDataOptions(keyName, sFilter[keyName])}</div>
                    <DeleteIcon
                      onClick={() => {
                        secondaryFilterProps[keyName].reset();
                        applyFilter();
                        fetchFilteredData();
                      }}
                      color="#808080"
                      style={{ width: 15, height: 15 }}
                    />
                  </Pill>
                );
              }
            })}
          </Fragment>
        )}
      </Observer>
    </Fragment>
  );
});

export default Pills;
