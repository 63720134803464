import React, { Fragment, useEffect } from 'react';
import { LoadingSpinner } from 'components/UI';
import {
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper
} from 'styles/styledComponents';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { initialData, widgetConstants } from './resources';
import { autorun } from 'mobx';
import { _get } from 'utils/utils';
import { StoreContext } from 'stores/RootStore';
import { useHistory, useLocation } from 'react-router-dom';

const SsResTotals = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { name, id } = widgetConstants;
  const rootStore = React.useContext(StoreContext);
  const toCurrency = rootStore.utilsStore.toCurrency,
    dataManagementStore = rootStore.dataManagementStore;

  const store = useLocalStore(() => ({
    loading: false,
    widgetData: initialData,
    async getData() {
      store.loading = true;
      try {
        const res = await dataManagementStore.fetchWidgetData(id);
        store.widgetData = res.data.data.widgets[name].data;
      } catch (error) {
        console.error('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          rootStore.userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
    toCurrency,
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          await store.getData();
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <Fragment>
          {store.loading && <LoadingSpinner size={'200px'} />}
          {!store.loading && store.widgetData.length > 0 && (
            <StyledWrapper resTotals={store.widgetData.length > 0}>
              {store.widgetData.map((mid, index) => {
                return (
                  <TableWrapper height="200px" key={`${mid.midNum}:${index}`}>
                    <TableHeader>{`${mid.proc} - ${mid.midNum}`}</TableHeader>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <HeaderCell />
                            <HeaderCell>Amount</HeaderCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Running Held Balance</TableCell>
                            <TableCell>{store.toCurrency(mid.totalResAmt)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Released</TableCell>
                            <TableCell>{store.toCurrency(mid.totalResRelAmt)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell showTotalsLine>Total</TableCell>
                            <TableCell showTotalsLine>{store.toCurrency(mid.totalResAmt - mid.totalResRelAmt || 0)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableWrapper>
                );
              })}
            </StyledWrapper>
          )}
        </Fragment>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 200px;
  grid-gap: 5px;

  margin: ${({ resTotals }) => {
    return resTotals ? '0 5px' : '0';
  }};
  @media (max-width: 830px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default SsResTotals;
