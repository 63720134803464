import React from 'react';
import { Notes } from './index';
import styled from 'styled-components';
import { Observer, observer } from 'mobx-react-lite';

const FeesNotes = observer(({ margin }) => {

  return (
    <Observer>
      {() => (
        <Wrapper margin={margin}>
          <div className="total">
            <Notes />
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  .notes {
    grid-area: notes;
  }
 
  grid-template-areas: 'notes';
  grid-template-columns: 1fr;
  grid-gap: 5px;
  padding-bottom: 4px;
`;

export default FeesNotes;
