import React, {useState} from 'react';
import { TableHeader, TableHeaderButton } from 'styles/styledComponents';
import { downloadPDF } from '../resources';
import { Observer, observer } from 'mobx-react-lite';
import {LoadingSpinner} from 'components/UI';

const TableStmtHeader = observer((props) => {
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <Observer>
      {() => (
        <div id="headerContent">
          <TableHeader
            justify=""
            backgroundColor={'#041c2f'}
            width="auto"
            fontSize="12px"
            padding={'10px 20px'}
            textAlign="start"
          >
            <span style={{ display: 'flex' }}>
              <span style={{ color: 'red', fontStyle: 'italic' }}>CAUTION: &nbsp;</span>
              <span style={{ fontStyle: 'italic', fontSize: '10px', display: 'flex', flexDirection: 'column' }}>
                <div>This is an industry statement. It has not been created by EIGHT and we do not endorse this statement.</div>
              </span>
            </span>
            <ul style={{ fontStyle: 'italic', fontSize: '10px' }}>
              <li>It is not based on the calendar month.</li>
              <li>It usually does not contain an accounting of all transactions.</li>
              <li>It does not follow GAAP or accounting standards.</li>
            </ul>
            <div style={{ fontStyle: 'italic', fontSize: '10px' }}>
              We recommend that you reconcile and balance your accounting books using our proprietary EIGHT statements.
            </div>

            <div style={{ display: 'flex', position: 'absolute', right: 0, top: 80 }}>
              {isDownloading ? 
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <LoadingSpinner size={'50px'}/>
                  <p style={{color: 'black', marginTop: '0px', paddingRight: '5px'}}>Downloading... This may take some time</p>
                </div>
                : 
                <TableHeaderButton height="40px" width="117px" onClick={async () => await downloadPDF(props.store, props.pageRef, props.tableRef, setIsDownloading)}>
                  Download PDF
                </TableHeaderButton>
              }
            </div>
          </TableHeader>
        </div>
      )}
    </Observer>
  );
});

export default TableStmtHeader;
