import {applySnapshot, destroy, flow, getSnapshot, types} from 'mobx-state-tree';
import {clientInfoModel, midModel, limitsModel, gwsProcsModel, pricingPlanModel} from './models';
import {adminService, commonService} from 'services/service';
import {_get} from 'utils/utils';
import axios from 'axios';
import Moment from "moment";

const {array, optional} = types;

export const createClientStore = types
        .model('createClientFormStore', {
            clientInfo: optional(clientInfoModel, () => clientInfoModel.create()),
            mids: optional(array(midModel), [
                midModel.create({
                    processors: [gwsProcsModel.create()],
                    gateways: [gwsProcsModel.create()],
                    procLimit: [limitsModel.create()],
                }),
            ]),
            pricingPlan: optional(pricingPlanModel, () => pricingPlanModel.create()),
        })
        .volatile((self) => ({
            loading: false,
            selectedClient: null,
            clients: [],
        }))
        .views((self) => ({
            get postCreateClientInfo() {
                return {
                    ...self.clientInfo,
                };
            },
        }))
        .actions((self) => {
            function afterCreate() {
                self.initialState = getSnapshot(self);
            }

            function setClients(clients) {
                self.clients = clients;
            }

            function resetForm() {
                applySnapshot(self, self.initialState);
            }

            function addMid() {
                self.mids.push(
                    midModel.create({
                        processors: [gwsProcsModel.create()],
                        gateways: [gwsProcsModel.create()],
                        procLimit: [limitsModel.create()],
                    })
                );
            }

            function remove(item) {
                destroy(item);
            }

            /*const createClientMid = flow(function* createClient(notify, location, history) {

            })*/
            const createClientError = flow(function* clientError(error, notify, location, history) {
                const messages = _get(error, 'response.data.messages') || [_get(error, 'response.data.message', 'Error creating client')];
                messages.map(message => {
                    notify({
                        duration: 9000,
                        group: 'topRight',
                        text: message,
                        type: 'error'
                    });
                })
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    try {
                        yield commonService.logout({
                            id: _get(self, 'user.id'),
                        });
                    } catch (error) {
                        console.error('error', error);
                    }
                    if (!location.pathname.includes('/login')) {
                        history.push('/login');
                    }
                    axios.defaults.headers.common['authorization'] = null;
                    window.localStorage.removeItem('eygSessionToken');
                }
                console.error(error);
            });

            const createMid = flow(function* createMid(midnum, newClientId, procLimits, notify, location, history) {
                try {
                    let ok = yield adminService.createMid({
                        // clientID: newClientId,
                        clientID: newClientId.toString(),
                        midNum: midnum,
                        startDt: Moment().startOf('day').toISOString()
                    });
                    let newProcLimits = yield createProcLimit(midnum, procLimits, notify, location, history)

                } catch (error) {
                    yield createClientError(error, notify, location, history);
                }
            });

            const createProcLimit = flow(function* createProcLimit(midnum, procLimits, notify, location, history) {
                procLimits && procLimits.length && procLimits.map(async (limit) => {
                    const {
                        limitEndDate,
                        limitStartDate,
                        monthlyProcLimit
                    } = limit;
                    try {
                        const procLimitRes = await adminService.createProcLimit({
                            midNum: midnum,
                            startDt: limitStartDate,
                            endDt: limitEndDate,
                            monthlyProcLimit: monthlyProcLimit
                        });
                        notify({
                            duration: 3000,
                            group: 'topRight',
                            text: 'Successfully created mid processor limit',
                        });
                    } catch (error) {
                        const messages = _get(error, 'response.data.messages') || [_get(error, 'response.data.message')] || [_get(error, 'response.data', 'Error creating client')];
                        messages.map(message => {
                            notify({
                                duration: 9000,
                                group: 'topRight',
                                text: message,
                                type: 'error'
                            });
                        })
                        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                            try {
                                await commonService.logout({
                                    id: _get(self, 'user.id'),
                                });
                            } catch (error) {
                                console.error('error', error);
                            }
                            if (!location.pathname.includes('/login')) {
                                history.push('/login');
                            }
                            axios.defaults.headers.common['authorization'] = null;
                            window.localStorage.removeItem('eygSessionToken');
                        }
                    }
                });
            });

            const createClient = flow(function* createClient(notify, location, history) {
                self.loading = true;
                let newClientId;

                let post = self.postCreateClientInfo;

                //step 1: create client
                try {
                    const client = yield adminService.createClient(post);
                    newClientId = client.data.id;
                    notify({
                        duration: 3000,
                        group: 'topRight',
                        text: 'Successfully created client',
                    });
                } catch (error) {
                    yield createClientError(error, notify, location, history);
                    return;
                }

                //step 2: create client mid
                 self.mids.map(async ({mid, processors, gateways, procLimit}) => {
                    // self.mids.map(async (mid) => {
                    //create mid
                    // try {
                    const newMid = await createMid(mid, newClientId, procLimit, notify, location, history);
                    // return {mid: procLimit}
                    /* if (newMid) {
                         notify({
                             duration: 3000,
                             group: 'topRight',
                             text: 'Successfully created client mid',
                         });
                         console.log('newMid', newMid);
                     } else {
                         return
                     }*/


                });
                // if (ok.length) {
                //     const newProcLimit = yield createProcLimit(procs, notify, location, history)
                // }

                /*  procs && procs.length && procs.map(async (mid) => {
                      console.log('mid inside procs', mid);
                      const {
                          limitEndDate,
                          limitStartDate,
                          midNumber,
                          monthlyProcLimit
                      } = mid;
                      console.log('mid proc step', midNumber, monthlyProcLimit);
                      await createProcLimit(limitEndDate, limitStartDate, mid, monthlyProcLimit, notify, location, history)
                  });*/


                // } catch (error) {
                //     console.log('error', error);
                //     await createClientError(error, notify, location, history);
                //     return;
                // }
                //step 3: create client mid procLimit

                /*   try {
                       const procLimitRes = await adminService.createProcLimit({
                           midNumber: procLimit.midNum,
                           startDt: procLimit.startDt,
                           endDt: procLimit.endDt,
                           monthlyProcLimit: procLimit.monthlyProcLimit
                       });
                   } catch(e) {
                       await createClientError(e, notify, location, history)
                   }*/
            });

            /*processors.length && processors.map(async integration => {
                try {
                    const integrationRes = await adminService.createIntegration({
                        midNumber: integration.midNum,
                        integration: integration.integration,
                        integrationType: integration.integrationType,
                        dataSource: integration.dataSource,
                        credentials: {}
                    });
                } catch (e) {
                    await createClientError(e, notify, location, history)
                }
            })

            gateways.length && gateways.map(async integration => {
                try {
                    const integrationRes = await adminService.createIntegration({
                        midNumber: integration.midNum,
                        integration: integration.integration,
                        integrationType: integration.integrationType,
                        dataSource: integration.dataSource,
                        credentials: {}
                    });
                } catch (e) {
                    await createClientError(e, notify, location, history)
                }
            })*/
            // });
            // self.loading = true;
// notify({
//   duration: 3000,
//   group: 'topRight',
//   text: 'Successfully created client',
// });
// resetForm();
// self.loading = false;

            /*const createClientError = flow(function* createClientError(error, notify, location, history) {
                notify({
                    duration: 3000,
                    group: 'topRight',
                    text: 'Error Creating Client',
                });
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    try {
                        yield commonService.logout({
                            id: _get(self, 'user.id'),
                        });
                    } catch (error) {
                        console.error('error', error);
                    }
                    if (!location.pathname.includes('/login')) {
                        history.push('/login');
                    }
                    axios.defaults.headers.common['authorization'] = null;
                    window.localStorage.removeItem('eygSessionToken');
                }
            })*/
            // }
            // )
// ;

            return {
                createClientError,
                addMid,
                remove,
                afterCreate,
                createClient,
                setClients,
                resetForm,
            };
        })
;
