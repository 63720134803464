const toCurrency = (value, language, currency, decimals, shorten) => {
  if (typeof value !== 'number') {
    return value;
  }

  let suffix = '';

  if (shorten) {
    if (value >= 1000 && value < 1000000) {
      suffix = 'K';
      value = value / 1000;
      if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
      else decimals = 0;
    } else if (value >= 1000000 && value < 1000000000) {
      suffix = 'M';
      value = value / 1000000;
      if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
      else decimals = 0;
    }
  }

  let formatter = new Intl.NumberFormat(language || 'eng', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  let formatted = formatter.format(value);

  if (shorten && suffix) return `${formatted}${suffix}`;
  else return formatted;
};

const toCommaDecimal = (value, language, maxDecimals, minDecimals) => {
  if (typeof value !== 'number') {
    return value;
  }

  let maxDecimalsHolder = maxDecimals || 2;
  let minDecimalsHolder = minDecimals || 0;

  if (minDecimalsHolder > maxDecimalsHolder) maxDecimalsHolder = minDecimalsHolder;

  let formatter = new Intl.NumberFormat(language || 'eng', {
    style: 'decimal',
    maximumFractionDigits: maxDecimalsHolder,
    minimumFractionDigits: minDecimalsHolder,
  });
  if (value) return formatter.format(value);
  else return formatter.format(0);
};

const toPercent = (value, language, currency, decimals, shorten) => {
  if (typeof value !== 'number') {
    return value;
  }

  let formatter = new Intl.NumberFormat(language || 'eng', {
    style: 'percent',
    minimumFractionDigits: decimals || 2,
    maximumFractionDigits: decimals || 2,
  });

  return formatter.format(value);
};

export { toCurrency, toCommaDecimal, toPercent };
