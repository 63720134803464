import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
const { string, number, maybe, boolean } = types;

const DeclineFilter = types
  .model({
    declBktID: maybe(number),
    txnTypeID: maybe(number),
    respCodeID: maybe(number),
    invNum: maybe(string),
    origCustName: maybe(string),
    denialBkt: maybe(string),
    preventable: maybe(string),
  })
  .volatile((self) => ({
    initialState: {},
    declBktIDError: false,
    txnTypeIDError: false,
    respCodeIDError: false,
    invNumError: false,
    origCustNameError: false,
    denialBktError: false,
    preventableError: false,
  }))
  .actions((self) => ({
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    setModel(name, value) {
      self[`${name}Error`] = false;
      self[name] = value;
    },
    reset(name, value) {
      self[name] = value;
    },
    setError(name) {
      self[name] = true;
    },
    resetErrors() {
      self.declBktIDError = false;
      self.txnTypeIDError = false;
      self.respCodeIDError = false;
      self.invNumError = false;
      self.origCustNameError = false;
      self.declBktError = false;
      self.preventableError = false;
    },
    resetState() {
      applySnapshot(self, self.initialState);
    },
  }));

export default DeclineFilter;
