import React, {Fragment, useState} from 'react';
import {
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import {LoadingSpinner} from 'components/UI';
import {observer, Observer, useLocalStore, useObserver} from 'mobx-react-lite';
import Moment from 'moment';
import {_get, _round, useInjectStores} from 'utils/utils';
import styled from "styled-components/macro";

export function useProps(widgetData, isLoading, toCurrency, getTotalsPercentage) {
    const {
        presentedPurchases,
        unsuccessfulPurchases,
        presentedReturns,
        unsuccessfulReturns,
        g8TxnReconAdj,
        merchantFees,
    } = widgetData;

    /*R1*/
    const r1 = _get(presentedPurchases, 'totals.tot_sale_cat_amt', 0);
    /*R2*/
    const r2 = _get(unsuccessfulPurchases, 'totals.tot_unsucc_sale_cat_amt', 0);
    /*Calc 1: R2 - R1 */
    const calc1 = r1 - r2; /* net successful purchases */
    /*R3*/
    const r3 = _get(presentedReturns, 'totals.tot_ret_cat_amt', 0);
    /*R4*/
    const r4 = _get(unsuccessfulReturns, 'totals.tot_unsucc_ret_cat_amt', 0);
    /*Purchases net of returns totals*/
    const calc2 = calc1 - (r3 * -1 + r4 * -1); /* purchases net of returns */
    /*Purchases net of returns %*/
    const purchasesNetOfReturns = getTotalsPercentage(calc2, calc1);
    /*R5*/
    const r5 = _get(g8TxnReconAdj, 'deposit.total', 0);
    /*R6*/
    const r6 = _get(g8TxnReconAdj, 'withdrawal.total', -0);
    /*Calc 3: calc 2 - (R6 + R5)*/
    const calc3 = calc2 - (r6 * -1 + r5 * -1); /* net totals */
    // const netTotalsBeforeFees = getTotalsPercentage(calc3, calc1);
    const netTotalsBeforeFees = _round(
        purchasesNetOfReturns - (getTotalsPercentage(r5, r1) * -1 + getTotalsPercentage(r6, r1) * -1),
        2
    );
    /*R7*/
    const r7 = _get(merchantFees, 'values.totFees', 0);
    const calc4 = calc3 - r7 * -1;
    const netAvailableDep = getTotalsPercentage(calc4, r1);

    let merchantFeesPercent = getTotalsPercentage(Math.abs(r7), (Math.abs(r1) + Math.abs(r3)));
    // ((Math.abs(r1) + Math.abs(r3)) / Math.abs(r7), 2);

    return useObserver(() => ({
        isLoading,
        /*R1*/
        presentedPurchases: {total: r1},
        /*R2*/
        unsuccessfulPurchases: {total: r2, percent: getTotalsPercentage(r2, r1)},
        /*Calc 1: R2 - R1*/
        netSuccessfulPurchases: {total: calc1, percent: getTotalsPercentage(calc1, r1)},
        /*R3*/
        presentedReturns: {total: r3, percent: getTotalsPercentage(r3, calc1)},
        /*R4*/
        unsuccessfulReturns: {total: r4, percent: getTotalsPercentage(r4, calc1)},
        /*Calc 2: Calc1 - (R3 + R4) */
        purchasesNetOfReturns: {total: calc2, percent: purchasesNetOfReturns},
        // netTotalsBeforeFees: { total: calc2, percent: getTotalsPercentage(calc2, r1) },
        /*R5*/
        depositAdjustments: {total: r5, percent: getTotalsPercentage(r5, r1)},
        /*R6*/
        withdrawalAdjustments: {total: r6, percent: getTotalsPercentage(r6, r1)},
        /*Calc 3: calc 2 - (R6 + R5)*/
        // netTotalsBeforeFees: { total: calc3, percent: getTotalsPercentage(calc3, r1) },
        netTotalsBeforeFees: {total: calc3, percent: netTotalsBeforeFees},
        /*R7*/
        // merchantFees: { total: r7, percent: getTotalsPercentage(r7, r1) },
        merchantFees: {total: r7, percent: getTotalsPercentage(Math.abs(r7), (Math.abs(r1) + Math.abs(r3)))},
        /*Calc 4: calc 3 - R7*/
        // netAvailableDep: { total: calc4, percent: getTotalsPercentage(calc4, r1) },
        netAvailableDep: {total: calc4, percent: netAvailableDep},
    }));
}

const Reconciliation = observer((props) => {
    const {dataManagementStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency;
    /* const {
       isLoading,
       presentedPurchases,
       /!*R2*!/
       unsuccessfulPurchases,
       /!*Calc 1: R2 - R1*!/
       netSuccessfulPurchases,
       /!*R3*!/
       presentedReturns,
       /!*R4*!/
       unsuccessfulReturns,
       /!*Calc 2: Calc1 - (R3 + R4) *!/
       purchasesNetOfReturns,
       /!*R5*!/
       depositAdjustments,
       /!*R6*!/
       withdrawalAdjustments,
       /!*Calc 3: calc 2 - (R6 + R5)*!/
       netTotalsBeforeFees,
       /!*R7*!/
       merchantFees,
       /!*Calc 4: calc 3 - R7*!/
       netAvailableDep,
     } = useProps(props.widgetData, props.isLoading, utilsStore.toCurrency, utilsStore.getTotalsPercentage);*/
     const [returnNote, setReturnNote] = useState('');
     function colorReturns(value) {
        if(value < 3) {
            setReturnNote('Under 3%');
            return (
                <span style={{color: 'green'}}>{value}%</span>
            )
        }else if (value >= 3 && value <= 5) {
            setReturnNote('Above 3% Under 5%');
            return (
                <span style={{color: 'yellow'}}>{value}%</span>
            )
        }else {
            setReturnNote('Above 5%');
            return (
                <span style={{color: 'red'}}>{value}%</span>
            )
        }
     }
    const isLoading = false;

    const store = useLocalStore(() => ({
        get startDate() {
            const startDt = _get(dataManagementStore.newQuery, 'startDt');
            return startDt ? Moment(startDt).format('M/DD/YYYY') : '';
        },
        get endDate() {
            const endDt = _get(dataManagementStore.newQuery, 'endDt');
            return endDt ? Moment(endDt).format('M/DD/YYYY') : '';
        },
    }));

    return (
        <Observer>
            {() => (
                <TableWrapper height="100%">
                    <TableHeader>
                        DEPOSIT RECONCILIATION
                    </TableHeader>
                    {props.isLoading ? <LoadingSpinner size={'200px'}/> :
                     <TableContainer>
                        <Table>
                            <TableBody>
                                    <Fragment>
                                        <TableRow>
                                            <HeaderCell textAlign={'left'} style={{width: '185px'}}>Recon Category</HeaderCell>
                                            <HeaderCell style={{width: '80px'}}>Amount</HeaderCell>
                                            <HeaderCell style={{width: '100px'}}>% to Gross Purchases</HeaderCell>
                                            <HeaderCell>Notes</HeaderCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell textAlign={'left'}>Presented Purchases</TableCell>
                                            <TableCell>{toCurrency(props.store.reconTotals.sp)}</TableCell>
                                            <TableCell>{}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell textAlign={'left'}>Unsuccessful Purchases</TableCell>
                                            <TableCell
                                                negative={props.store.reconTotals.up < 0}>{toCurrency(props.store.reconTotals.up)}</TableCell>
                                            <TableCell>{props.store.reconTotals.upg}%</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell bold showTotalsLine textAlign={'left'}>
                                                Net Purchases
                                            </TableCell>
                                            <TableCell bold negative={props.store.reconTotals.np < 0} showTotalsLine>
                                                {toCurrency(props.store.reconTotals.np)}
                                            </TableCell>

                                            <TableCell bold showTotalsLine>{props.store.reconTotals.npg}%</TableCell>

                                            <TableCell bold showTotalsLine>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell textAlign={'left'}>
                                                Submitted Returns (Successful)
                                            </TableCell>
                                            <TableCell negative={props.store.reconTotals.sr < 0}>
                                                {toCurrency(props.store.reconTotals.sr)}
                                            </TableCell>

                                            <TableCell>{colorReturns(props.store.reconTotals.srg)}</TableCell>

                                            <TableCell>
                                            {`Industry allowance for returns is <= 5.0%`}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell bold showTotalsLine textAlign={'left'}>
                                                Purchases Net of Returns
                                            </TableCell>
                                            <TableCell bold showTotalsLine negative={props.store.reconTotals.pnor < 0}>
                                                {toCurrency(props.store.reconTotals.pnor)}
                                            </TableCell>

                                            <TableCell bold showTotalsLine>{props.store.reconTotals.pnorg}%</TableCell>

                                            <TableCell bold showTotalsLine>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell textAlign={'left'}>
                                                Less Fees
                                            </TableCell>
                                            <TableCell negative={props.store.reconTotals.lf < 0}>
                                                {toCurrency(props.store.reconTotals.lf)}
                                            </TableCell>

                                            <TableCell></TableCell>

                                            <TableCell>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell bold showTotalsLine textAlign={'left'}>
                                                Net Available for Deposit
                                            </TableCell>
                                            <TableCell bold showTotalsLine negative={props.store.reconTotals.nafd < 0}>
                                                {toCurrency(props.store.reconTotals.nafd)}
                                            </TableCell>
                                            <TableCell bold showTotalsLine>{props.store.reconTotals.nafdg}%
                                            </TableCell>
                                            <TableCell bold showTotalsLine>
                                            </TableCell>
                
                                        </TableRow>
                                    </Fragment>
                            </TableBody>
                        </Table>
                         <br/>
                         <br/>
                         {/* <div css={`font-size: 12px; padding: 0 10px`}>
                             {`Note: Industry allowance for both refunds & chargebacks is <= 5.0%`}
                         </div> */}
                         <StyledNote>
                             <span className="note">*</span>
                             <span className="noteBody">Your depository bank may charge you fees that are unrelated to auth and settlement files. Their fees are not included in this balancing.
                            </span>
                         </StyledNote>
                    </TableContainer>
                    }
                </TableWrapper>
            )}
        </Observer>
    );
});

export default Reconciliation;
const StyledNote = styled.div`
  //display: flex;
  //flex-direction: column;
//   height: 100%;
  //justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  padding: 10px;

  .note {
    color: red;
  }

  .noteBody {
    font-size: 12px;
    padding-bottom: 1rem;
    font-style: italic;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;