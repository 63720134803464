import styled from 'styled-components/macro';

const DatesContainer = styled.div`
  display: flex;
  height: 100%;
  min-width: 94px;
  flex-direction: column;
  margin: 5px 5px 0 5px;
  font-size: 13px;
  justify-content: flex-start;
  color: ${({ theme }) => (theme ? theme.colors.text : 'black')};
  .filterDates {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${({ theme }) => (theme ? theme.colors.text : 'black')};
  }
`;

const Pill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  margin: 2px;
  padding: 0 2px 0 5px;
  border-radius: 9px;
  background-color: #e7e8e7;
  color: #666666;

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(150px - 9px);
  }
  svg {
    padding-bottom: 1px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const SubmitButton = styled.div`
  display: flex;
  height: 20px;
  width: auto;
  border: ${({ border }) => (border ? '1px solid #fff' : '')};
  align-items: center;
  padding: 0px 5px;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  background: ${({ theme }) => theme.colors.primaryBtn};
  color: #fff;
  margin: ${({ margin }) => margin || '5px 2px'};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;

const ClearButton = styled.div`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  display: flex;
  border: ${({ border }) => (border ? '1px solid #fff' : '')};
  align-items: center;
  padding: 0px 5px;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  background: ${({ theme }) => theme.colors.secondaryBtn};
  color: #fff;
  margin: ${({ margin }) => margin || '5px 2px'};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorOrangeHover};
  }
`;

const FilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '35px'};
  height: ${({ height }) => height || '36px'};
  margin: ${({ margin, marginTop, marginRight, marginBottom, marginLeft }) => {
    if (margin || margin === 0) {
      return margin;
    } else if (marginTop || marginRight || marginBottom || marginLeft) {
      let Top = '0px';
      let Right = '0px';
      let Bottom = '0px';
      let Left = '0px';
      if (marginTop) Top = marginTop;
      if (marginRight) Right = marginRight;
      if (marginBottom) Bottom = marginBottom;
      if (marginLeft) Left = marginLeft;
      return `${Top} ${Right} ${Bottom} ${Left}`;
    } else return `2px 0 0 5px`;
  }};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.icons.colors.filterBtnBackground};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.icons.colors.filterBtnHoverBackground};
  }
`;

export { DatesContainer, FilterButton, Pill, SubmitButton, ClearButton };
