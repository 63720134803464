import React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { IsoBankRevenue } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';
import {Wrapper} from 'styles/styledComponents';

const bankRevenue = observer(() => {
  let { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <div style={{ margin: '0 4px', height: '100%' }}>
              <IsoBankRevenue />
            </div>
          ) : (
            <Wrapper style={{width: '99%', margin: '0px 5px'}}>
                <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    You are not authorized to view this page.
                </div>
            </Wrapper>
          )}
        </>
      )}
    </Observer>
  );
});

export default bankRevenue;
