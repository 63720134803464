import React from 'react';
import { _get } from 'utils/utils';
// import logo from 'images/GoldenAit/stmtGoldStar.png';
import logo from 'images/GoldenAit/eightLogoSilverTop.svg';

const StmtHeader = (props) => (
  <div
    className="header"
    id="header"
    style={{
      padding: '0 55px',
      paddingTop: '20px',
      color: '#000000',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    }}
  >
    <div
      className="top-side"
      style={{
        fontSize: '14px',
        display: 'flex',
      }}
    >
      <div className="left-side" style={{ width: '100%' }}>
        <img className="logo" width="200px" height="auto" src={logo} alt="logo" />
        <br />
        <br />
        <div className="name">EIGHT PROCESSING</div>
        {/*<div className="name">{_get(props.store, 'customer["REMIT_NAME"][0]')}</div>*/}
        <div className="street">{_get(props.store, 'customer["REMIT_ADDR1"][0]')}</div>
        <div className="room">{_get(props.store, 'customer["REMIT_ADDR2"][0]')}</div>
        <div className="area">{_get(props.store, 'customer["REMIT_ADDR3"][0]')}</div>
        <br />
        <br />
      </div>
      <div
        className="right-side"
        style={{
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        <div
          className="month"
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <span className="label">Processing Month:</span>
          <span
            className="value"
            style={{
              marginLeft: '59px',
            }}
          >
            {_get(props.store, 'customer["PROCESSING_MONTH"][0]')}
          </span>
        </div>
        <br />
        <div
          className="association-number"
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <span className="label">Association Number:</span>
          <span
            className="value"
            style={{
              marginLeft: '48px',
            }}
          >
            {_get(props.store, 'customer["ASSOCIATION_NUM"][0]')}
          </span>
        </div>
        <br />
        <div
          className="merchant-number"
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <span className="label">Merchant Number:</span>
          <span
            className="value"
            style={{
              marginLeft: '60px',
            }}
          >
            {_get(props.store, 'isDemo') ? 888800000008888 : _get(props.store, 'customer["MERCHANT_NUM"][0]')}
          </span>
        </div>
        <br />
        <div
          className="router-number"
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <span className="label">Routing Number:</span>
          <span
            className="value"
            style={{
              marginLeft: '72px',
            }}
          >
            {_get(props.store, 'customer["ROUTING_NUM"][0]')}
          </span>
        </div>
        <br />
        <div
          className="deposit-account-number"
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <span className="label">Deposit Account Number:</span>
          <span
            className="value"
            style={{
              marginLeft: '20px',
            }}
          >
            {_get(props.store, 'customer["DEPOSIT_ACCT_NUM"][0]')}
          </span>
        </div>
        <br />
      </div>
    </div>

    <div
      className="bottom-side"
      style={{
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <div
        className="left-side"
        style={{
          width: '100%',
        }}
      >
        <div
          className="customer"
          style={{
            width: '100%',
          }}
        >
          <div className="client-name">{_get(props.store, 'customer["MAILING_NAME"][0]')}</div>
          {/*<div className="bus-name">{_get(props.store, 'customer["PROCESSING_MONTH"][0]')}</div>*/}
          <div className="client-street">{_get(props.store, 'customer["MAILING_ADDR2"][0]')}</div>
          <div className="area">{_get(props.store, 'customer["MAILING_ADDR3"][0]')}</div>
        </div>
      </div>
      <div
        className="right-side"
        style={{
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {_get(props.store, 'customer["AMOUNT_DEDUCTED"][0]') && (
          <>
            <div className="amount-deducted">Amount Deducted</div>
            <div className="amount">{_get(props.store, 'customer["AMOUNT_DEDUCTED"][0]')}</div>
          </>
        )}
        {_get(props.store, 'customer["AMOUNT_CREDITED"][0]') && (
          <>
            <div className="amount-deducted">Amount Credited</div>
            <div className="amount">{_get(props.store, 'customer["AMOUNT_CREDITED"][0]')}</div>
          </>
        )}
      </div>
    </div>
    <br />
    <br />
  </div>
);

export default StmtHeader;
