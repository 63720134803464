import { _includes } from 'utils/utils';

function checkAll(optionsArray) {
  let optsForModel = [];
  let mappedOptionsArray = optionsArray.map((opt) => {
    opt.checked = true;
    if (opt.value !== 'All') {
      optsForModel.push(opt.value);
    }
    return opt;
  });
  return {
    optsForModel,
    mappedOptionsArray,
  };
}
function unCheckAll(optionsArray) {
  let optsForModel = [];
  let mappedOptionsArray = optionsArray.map((opt) => {
    opt.checked = false;
    return opt;
  });
  return {
    optsForModel,
    mappedOptionsArray,
  };
}

function onChangeMultiSelect(obj, optionsArray, setModel, setOptions) {
  let propsForModel = [];
  let updatedOptions = [];
  //toggle passed option and get all check options
  if (obj.value === 'All') {
    const { optsForModel, mappedOptionsArray } = obj.checked ? unCheckAll(optionsArray) : checkAll(optionsArray);
    propsForModel = optsForModel;
    updatedOptions = mappedOptionsArray;
  } else {
    const { optsForModel, mappedOptionsArray } = toggleSingleOption(optionsArray, obj);
    propsForModel = optsForModel;
    updatedOptions = mappedOptionsArray;
  }
  setModel(propsForModel.length > 0 ? propsForModel : null);
  setOptions(updatedOptions);
}

function toggleSingleOption(optionsArray, opt) {
  let optsForModel = [];
  let mappedOptionsArray = optionsArray.map((option) => {
    //toggle selected option
    if (option.value === opt.value) {
      option.checked = !option.checked;
    }
    //all checked options go into model
    if (option.checked && option.value !== 'All') {
      optsForModel.push(option.value);
    }
    return option;
  });
  return {
    optsForModel,
    mappedOptionsArray,
  };
}

/*function checkOneOrAll(optionsArray, opt) {
  let optsForModel = [];
  let mappedOptionsArray = optionsArray.map(option => {
    //toggle selected option
    if (option.value === opt.value) {
      option.checked = !option.checked;
    }
    //all checked options go into model
    if (option.checked && option.value !== 'All') {
      optsForModel.push(option.value);
    }
    return option;
  });
  return {
    optsForModel,
    mappedOptionsArray
  };
}*/

function filterOption(optionsArray, optionValue) {
  let optsForModel = [];
  let mappedOptionsArray = optionsArray.map((opt) => {
    //toggle selected option
    if (opt.value === optionValue) {
      opt.checked = false;
    }
    //all checked options go into model
    if (opt.checked && opt.value !== 'All') {
      optsForModel.push(opt.value);
    }
    return opt;
  });
  return {
    optsForModel,
    mappedOptionsArray,
  };
}

function checkOptionsFromModel(modelValues = [], optionsArray, setOptions, autoSelectAll) {
  // check the statuses that are in the model
  let updatedOptions = [];
  updatedOptions = optionsArray.map((option) => {
    option.checked = _includes(modelValues, option.value);
    return option;
  });
  if (autoSelectAll && updatedOptions.length === 0) {
    updatedOptions = checkAll(optionsArray);
  }
  setOptions(updatedOptions);
}

export { checkAll, checkOptionsFromModel, unCheckAll, filterOption, toggleSingleOption, onChangeMultiSelect };
