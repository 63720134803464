import { applySnapshot, flow, getRoot, getSnapshot, types } from 'mobx-state-tree';
import { _map, _find, _includes } from 'utils/utils';
import { toJS } from 'mobx';
import { adminService } from 'services';
const { array, boolean, number, maybe, optional, string } = types;

function validID(id) {
  if (!isNaN(id) && id > 0) return true;
  else return false;
}

const UpdateUserForm = types
  .model({
    active: maybe(boolean),
    clients: optional(
      array(
        types
          .model('clientUserArray', {
            id: maybe(number),
            roles: optional(array(maybe(string)), []),
          })
          .actions((self) => ({
            setClientID(num) {
              self.id = num;
            },
            addRole(id) {
              if (self.roles.indexOf(id) === -1) self.roles.push(id);
            },
            deleteRole(id) {
              let index = self.roles.indexOf(id);
              if (index > -1) self.roles.splice(index, 1);
            },
          }))
      ),
      []
    ),
    email: optional(string, ''),
    emailTypeID: maybe(number),
    firstName: optional(string, ''),
    id: maybe(number),
    lastName: optional(string, ''),
    password: optional(string, ''),
  })
  .volatile((self) => ({
    initialState: {},
    savedClients: [],
    initialUserClients: [],
  }))
  .views((self) => ({
    get clientOptions() {
      return getRoot(self).adminStore.clients;
    },
  }))
  .actions((self) => ({
    /** LifeCycle Hooks **/
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    resetState() {
      applySnapshot(self, self.initialState);
    },
    setInitialClientUserRoles(clientRoles) {
      self.initialUserClients = clientRoles;
    },
    addClient(id) {
      self.clients.unshift({ id, roles: undefined });
    },
    /** Setters **/
    setID(id) {
      if (validID(id)) self.id = id;
    },
    setFirstName(e) {
      self.firstName = e.target.value;
    },
    setLastName(e) {
      self.lastName = e.target.value;
    },
    setEmail(e) {
      self.email = e.target.value;
    },
    setEmailType(type) {
      self.emailTypeID = type;
    },
    setPassword(password) {
      self.password = password;
    },
    setActive(active) {
      self.active = active;
    },
    setClients(clientID) {
      if (self.clients.indexOf(clientID) === -1 && validID(clientID)) self.clients.push(clientID);
    },
    setInitialClients(clients) {
      if (clients.length && clients.length > 0) {
        _map(clients, (client) => {
          let roles = client.assignedRoles.map((role) => role.id);
          self.clients.push({ id: client.id, roles });
        });
      }
    },

    /** Deleter for the selectedClients array **/
    deleteClientRoleObject(index) {
      self.clients.splice(index, 1);
    },
    removeNullClients() {
      _map(self.clients, (client, index) => {
        if (!client.id) {
          self.deleteClientRoleObject(index);
        }
      });
    },
    submitUpdatedUser: flow(function* (clients) {
      const responseObj = {
        errFields: [],
        serverErrMsg: [],
        successMsg: [],
      };

      /** Step 1 - Get a SnapShot of the Values and Place the complete Client Data onto the newUserData **/
      let updatedUserData = getSnapshot(self);

      let clientsToFind = toJS(self.clients);
      let completeClientData = [];
      for (let i = 0; i < clientsToFind.length; i++) {
        let currentClientToFind = clientsToFind[i];
        let foundClient = clients.find((client) => client.id === currentClientToFind.id);
        foundClient.roles = currentClientToFind.roles;
        completeClientData.push(foundClient);
      }

      /*if (responseObj.errFields.length > 0) {
        return responseObj;
      }*/
      let post = {
        ...updatedUserData,
        clients: completeClientData,
      };

      /** Step 4 - Send the Updated User to the Server **/
      let userResponse;
      let deleteUserFromClients = { status: 200 };
      let addUserToClientsResponse = { status: 200 };
      let userClientRolesToDeleteResponse = { status: 200 };
      let userClientRolesToCreateResponse = { status: 200 };

      const userClientsToDelete = [];
      const userClientsToAdd = [];
      const userClientRolesToDelete = [];
      const userClientRolesToCreate = [];

      try {
        // check if we need to remove clients
        // remove all client roles after removing client
        // check if we need to add clients
        // add client roles after adding client
        // check if we need to add/remove roles to exisiting clients
        // remove roles
        // add roles

        // find clients and client roles to delete
        self.initialUserClients.map((client) => {
          let keepClient = _find(self.initialUserClients, { id: client.id });
          if (!keepClient) {
            const client = { clientID: keepClient.id, email: self.email };
            userClientsToDelete.push(client);
            keepClient.roles.map((roleName) => {
              userClientRolesToDelete.push({ roleName, email: self.email, clientID: keepClient.id });
            });
          }
        });
        // find new clients and client roles to add
        self.clients.map((client) => {
          let findClient = _find(self.initialUserClients, { id: client.id });
          if (!findClient) {
            const newClient = { clientID: client.id, email: self.email };
            userClientsToAdd.push(newClient);
            client.roles.map((roleName) => {
              userClientRolesToCreate.push({ roleName, email: self.email, clientID: client.id });
            });
          } else {
            // find roles to delete
            findClient.roles.map((role) => {
              let currentClientRoles = toJS(client);
              // if we find the role on the initialUserClientRoles do nothing
              let findRole = _includes(currentClientRoles.roles, role);
              // if we don't find it add it
              if (!findRole) {
                const deleteRole = { roleName: role, clientID: client.id, email: self.email };
                userClientRolesToDelete.push(deleteRole);
              }
            });
            // these are clients we are keeping
            // for this client find the initial client and compare roles
            client.roles.map((role) => {
              // if we find the role on the initialUserClientRoles do nothing
              let findRole = _includes(findClient.roles, role);
              // if we don't find it add it
              if (!findRole) {
                const newRole = { roleName: role, clientID: client.id, email: self.email };
                userClientRolesToCreate.push(newRole);
              }
            });
          }
        });

        userResponse = yield adminService.adminUpdateUser(post);

        if (userClientsToDelete.length && userResponse.status === 200) {
          deleteUserFromClients = yield adminService.deleteUserFromClients(userClientsToDelete);
        }
        if (userClientsToAdd.length && deleteUserFromClients.status === 200) {
          addUserToClientsResponse = yield adminService.adminAddUserToClients(userClientsToAdd);
        }
        if (userClientRolesToDelete.length && addUserToClientsResponse.status === 200) {
          userClientRolesToDeleteResponse = yield adminService.adminDeleteUserClientRoles(userClientRolesToDelete);
        }
        if (userClientRolesToCreate.length && userClientRolesToDeleteResponse.status === 200) {
          userClientRolesToCreateResponse = yield adminService.adminAddUserClientRoles(userClientRolesToCreate);
        }
        if (userClientRolesToDeleteResponse.status === 200) {
          // userResponse.successMsg.push(userResponse);
        }
      } catch (e) {
        console.log('e', e);
        // responseObj.serverErrMsg.push(e.response.data.message);
      }

      /** Step 5 - Based on the Response Received from the Server Handle Errors Gracefully **/
      return responseObj;
    }),
  }));

export default UpdateUserForm;
