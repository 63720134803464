import styled from 'styled-components/macro';

const InputWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ noMargin }) => (noMargin ? 'none' : '5px')};
  label {
    color: ${({ theme }) => theme.colors.primaryText};
    margin: 3px 0;
    font-size: 14px;
  }
`;

const UpdateUserContainer = styled.div`
  padding: 5px 5px 0px;
  width: 50%;
  overflow-y: auto;
`;

const UpdateUserTableHeader = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.tableStyles.color};
  background-color: ${({ theme }) => theme.tableStyles.tableHeaderBackgroundColor};
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
`;

export { FormRow, InputWrapper, UpdateUserContainer, UpdateUserTableHeader };
