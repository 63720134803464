import styled from 'styled-components/macro';
import { _includes } from 'utils/utils';

const SSWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: ${({ margin }) => (margin ? margin : '0')};
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

function getAgDefs(toCurrency, toDateFormatter) {
  return {
    orderByOptions: [
      { key: 1, text: 'Cust Name', value: 'custName' },
      { key: 2, text: 'Invoice Num', value: 'invNum' },
      { key: 3, text: 'Txn Type', value: 'txnType' },
      { key: 4, text: 'Txn Status', value: 'txnStatus' },
      { key: 5, text: 'Txn Date', value: 'txnDt' },
    ],
    columnDefs: [
      { headerName: 'Details', field: 'details', pinned: 'left' },
      { headerName: 'Pymt Type', field: 'Pymt Type' },
      { headerName: 'Row', field: 'index', pinned: 'left', checkboxSelection: true },
      { headerName: 'Txn Date', field: 'Txn Dt', valueFormatter: toDateFormatter },
      { headerName: 'Txn Type', field: 'Txn Type' },
      { headerName: 'Txn Status', field: 'Txn Status' },
      { headerName: 'Inv Num', field: 'Inv Num' },
      { headerName: 'Customer', field: 'Customer' },
      { headerName: 'Txn Name', field: 'Orig Cust Name' },

      { headerName: 'Submit Amount', field: 'Submit Amt', type: 'rightAligned', valueFormatter: toCurrency },
      { headerName: 'Txn Curr', field: 'Txn Curr' },
      { headerName: 'Exch Rt', field: 'Exch Rt' },
      { headerName: 'Funct Curr', field: 'Funct Curr' },
      { headerName: 'Submit Funct Amount', field: 'Submit Funct Amt', valueFormatter: toCurrency },
      { headerName: 'Settle Amount', field: 'Settle Amt', valueFormatter: toCurrency },
      { headerName: 'Settle Funct Amount', field: 'Settle Funct Amt', valueFormatter: toCurrency },
      { headerName: 'Total Fees', field: 'Total Fees', valueFormatter: toCurrency },

      { headerName: 'CC Bin', field: 'CC Bin' },
      { headerName: 'CC Last 4', field: 'CC Last 4' },
      { headerName: 'CC Exp Date', field: 'CC Exp Dt' },
      { headerName: 'CC Brand', field: 'CC Brand' },
      { headerName: 'Ach Acct Num', field: 'Ach Acct Num' },
      { headerName: 'Ach Acct Type', field: 'Ach Acct Type' },
      { headerName: 'Txn ID', field: 'Txn ID' },
      { headerName: 'Txn Refr ID', field: 'Txn Refr ID' },
      { headerName: 'Auth Code', field: 'Auth Code' },
      { headerName: 'AVS Desc', field: 'AVS Desc' },
      { headerName: 'CV Desc', field: 'CV Desc' },
      { headerName: 'MID', field: 'MID' },
      { headerName: 'Gateway', field: 'Gateway' },
      { headerName: 'Gw Batch', field: 'Gw Batch' },
      { headerName: 'Processor', field: 'Processor' },
      { headerName: 'Proc Batch', field: 'Proc Batch' },
      { headerName: 'Preventable', field: 'Preventable' },
      { headerName: 'Opportunity', field: 'Opportunity' },
    ],
    negativeStyleColumns: ['Txn Amt', 'Funct Amt', 'Total Fees'],
    defaultColDef: {
      minWidth: 90,
      maxWidth: 300,
      sortable: false,
      resizable: true,
      enableRangeSelection:true,
      onCellClicked: async function (params) {
        const {
          column: { colId },
          columnApi: { fetchData, showTxnModal },
          data: { id: txnID },
          value,
        } = params;

        function updateClipboard(newClip) {
          navigator.clipboard.writeText(newClip).then(
            function () {},
            function () {}
          );
        }

        if (colId !== 'details' && colId !== 'index') updateClipboard(value);
        if (colId === 'details') {
          await fetchData(txnID);
          showTxnModal();
        }
      },
      cellStyle: function (params) {
        let negativeStyleColumns = ['Txn Amt', 'Funct Amt', 'Total Fees', 'Settle Amt', 'Settle Funct Amt', 'Submit Funct Amt'];
        if (_includes(negativeStyleColumns, params.colDef.headerName) && params.value < 0) {
          return { color: 'red', cursor: 'pointer' };
        }
        return { cursor: 'pointer' };
      },
    },
  };
}

export { SSWidgetWrapper, getAgDefs };
