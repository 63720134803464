import { applySnapshot, flow, getRoot, getSnapshot, types } from 'mobx-state-tree';
import { adminService } from 'services';
import { _map, _findKey } from 'utils/utils';
const { array, boolean, number, maybe, optional, string } = types;

function validID(id) {
  if (!isNaN(id) && id > 0) return true;
  else return false;
}
function validEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const CreateUserForm = types
  .model({
    active: optional(boolean, true),
    clients: optional(
      array(
        types
          .model('clientUserArray', {
            id: maybe(number),
            roles: optional(array(maybe(string)), []),
          })
          .actions((self) => ({
            setClientID(num) {
              self.id = num;
            },
            addRole(id) {
              if (self.roles.indexOf(id) === -1) self.roles.push(id);
            },
            deleteRole(id) {
              let index = self.roles.indexOf(id);
              if (index > -1) self.roles.splice(index, 1);
            },
          }))
      ),
      []
    ),
    email: optional(string, ''),
    firstName: optional(string, ''),
    lastName: optional(string, ''),
    superadmin: optional(boolean, false),
    password: optional(string, ''),
    validated: optional(boolean, false),
  })
  .volatile((self) => ({
    initialState: {},
  }))
  .views((self) => ({
    get clientOptions() {
      return getRoot(self).adminStore.clients;
    },
  }))
  .actions((self) => ({
    /** LifeCycle Hooks **/
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    resetState() {
      applySnapshot(self, self.initialState);
    },
    addClient(id) {
      self.clients.unshift({ id, roles: undefined });
    },
    removeNullClients() {
      _map(self.clients, (client, index) => {
        if (!client.id) {
          self.deleteClientRoleObject(index);
        }
      });
    },
    /** Setters **/
    setCreateFirstName(name) {
      self.firstName = name;
    },
    setCreateLastName(name) {
      self.lastName = name;
    },
    setCreateEmail(email) {
      self.email = email;
    },
    setCreateEmailType(type) {
      self.emailTypeID = type;
    },
    setCreatePassword(password) {
      self.password = password;
    },
    setCreateRoleType(roleTypeID) {
      self.roleTypeID = roleTypeID;
    },
    setCreateActive(active) {
      self.active = active;
    },
    setCreateSuperadmin(superadmin) {
      self.superadmin = superadmin;
    },
    setCreateValidated(validated) {
      self.validated = validated;
    },
    setCreateClients(clientID) {
      if (self.clients.indexOf(clientID) === -1 && validID(clientID)) self.clients.push(clientID);
    },
    /** Deleter for the Client Role Object array **/
    deleteClientRoleObject(index) {
      self.clients.splice(index, 1);
    },
    submitNewUser: function () {
      let post = {
        active: self.active,
        password: self.password,
        email: self.email,
        firstName: self.firstName,
        lastName: self.lastName,
        superadmin: self.superadmin,
        validated: self.validated,
      };
      return adminService.adminCreateUser(post);
    },
    addUserToClients: function (clients) {
      let clientsToFind = self.clients;
      let completeClientData = [];
      for (let i = 0; i < clientsToFind.length; i++) {
        let currentClientToFind = clientsToFind[i];
        let foundClient = clients.find((client) => client.id === currentClientToFind.id);
        completeClientData.push({ clientID: foundClient.id, email: self.email });
      }
      return adminService.adminAddUserToClients(completeClientData);
    },
    addUserClientRoles: function (clients) {
      let clientsToFind = self.clients;
      let completeClientData = [];
      for (let i = 0; i < clientsToFind.length; i++) {
        let currentClientToFind = clientsToFind[i];
        let foundClient = clients.find((client) => client.id === currentClientToFind.id);
        if (foundClient) {
          foundClient.roles = currentClientToFind.roles;
          currentClientToFind.roles.map((roleName) => {
            completeClientData.push({
              roleName,
              email: self.email,
              clientID: foundClient.id,
            });
          });
        }
      }
      return adminService.adminAddUserClientRoles(completeClientData);
    },
    addDeleteRoles: function addDeleteRoles() {
      /*let clientsToFind = self.clients;
      let completeClientData = [];
      for (let i = 0; i < clientsToFind.length; i++) {
        let currentClientToFind = clientsToFind[i];
        let foundClient = clients.find((client) => client.id === currentClientToFind.id);
        if (foundClient) {
          foundClient.roles = currentClientToFind.roles;
          currentClientToFind.roles.map((roleName) => {
            completeClientData.push({
              roleName,
              email: self.email,
              clientID: foundClient.id,
            });
          });
        }
      }*/
    },
  }));

export default CreateUserForm;
