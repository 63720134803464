import React, {useEffect} from 'react';
import {WidgetHeader, WidgetWrapper} from 'styles/styledComponents';
import {Dropdown, Error, LoadingSpinner } from 'components/UI';
import styled from 'styled-components/macro';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useGetWidgetData, useInjectStores} from 'utils/utils';
import {AgGridReact} from 'ag-grid-react';
import {gridOptsColumnDefs} from './resources';
import {widgetService} from '../../../services';


const AvsReasons = observer(() => {
    const {dataManagementStore} = useInjectStores();

    const store = useLocalObservable(() => ({
        selectedTopicAvs: 1,
        selectTopicAvs(val) {
            this.selectedTopicAvs = val;
        },
        topicsAvs: [
            {key: 1, text: 'AVS Issues', value: 1},
            {key: 2, text: 'Error Reasons', value: 2},
        ],
        dashAvsIssues: [],
        dashErrRsn: [],
        fetchDashAvsIssues: false,
        fetchDashErrRsn: false,
        setDashAvsIssues(data) {
            this.fetchDashAvsIssues = false;
            this.dashAvsIssues = data;
        },
        setDashErrRsn(data) {
            this.fetchDashErrRsn = false;
            this.dashErrRsn = data;
        },
        error: null,
        api: null,
        gridColumnApi: null,
        gridOptions: {
            rowHeight: 33,
            headerHeight: 33,
            animateRows: true,
            enableBrowserTooltips: true,
            tooltipShowDelay: 0,
            reactNext: true,
            enableCharts: true,
            enableRangeSelection: true,
            groupMultiAutoColumn: true,
            groupSuppressAutoColumn: true,
            treeData: true,
            rowClassRules: {
                secondLevel: function (params) {
                    return params.data.secondLevel;
                },
                topLevel: function (params) {
                    return params.data.topLevel;
                },
            },
            groupRemoveSingleChildren: true,
            getDataPath: function (data) {
                return data.keyPath;
            },
            getRowNodeId: function (data) {
                return data.id;
            },
            defaultColDef: {
                sortable: false,
                resizable: true,
            },
        },
        onGridReady(params) {
            this.api = params.api;
            this.gridColumnApi = params.columnApi;
        },
        onFirstDataRendered: (params) => {
        },
        // detailCellRenderer: 'myDetailCellRenderer',
        detailRowHeight: 70,
        get treeDataDashAvsIssues() {
            let rowData = [];
            if (this.dashAvsIssues?.length) {
                for (let i = 0; i < this.dashAvsIssues.length; i++) {
                    const current = this.dashAvsIssues[i];
                    rowData.push({
                        ...current,
                        keyPath: [`${current.avs_cat_cnt}${current.vs_mc_netwk_avs}${current.ds_netwk_avs}`],
                        id: i + 1,
                    });
                }
            }
            return rowData;
        },
        get treeDataDashErrRsn() {
            let rowData = [];
            if (this.dashErrRsn?.length) {
                let data = this.dashErrRsn;
                for (let i = 0; i < data.length; i++) {
                    const current = data[i];
                    rowData.push({
                        ...current,
                        keyPath: [`${current.industry_rsn}${current.tsys_internal_rsn}${current.tsys_netwk_err_rsn}`],
                        id: i + 1,
                    });
                }
            }
            return rowData;
        },
        get colDefsAvsRowData() {
            switch (this.selectedTopicAvs) {
                case 1:
                    return {
                        rowData: this.treeDataDashAvsIssues,
                        columnDefs: gridOptsColumnDefs.dashAvsIssues.columnDefs,
                    };
                case 2:
                    return {
                        rowData: this.treeDataDashErrRsn,
                        columnDefs: gridOptsColumnDefs.dashErrRsn.columnDefs,
                    };
                default:
                    return {
                        rowData: [],
                        columnDefs: {},
                    };
            }
        },
        setFetchData(fetch) {
            this.fetchData = fetch;
        },
        setFetchDashAvsIssues(fetch) {
            this.fetchDashAvsIssues = fetch;
        },
        setFetchDashErrRsn(fetch) {
            this.fetchDashErrRsn = fetch;
        },
    }));

    const dashAvsData = useGetWidgetData(
        {name: 'dashboardAvsIssues', query: widgetService.dashboardAvsIssues},
        'data',
        store.fetchDashAvsIssues
    );
    const dashErrRsn = useGetWidgetData(
        {name: 'dashboardErrorReason', query: widgetService.dashboardErrorReason},
        'data',
        store.fetchDashErrRsn
    );

    useEffect(() => {
        store.setDashAvsIssues(dashAvsData.data);
    }, [dashAvsData.data]);
    useEffect(() => {
        store.setDashErrRsn(dashErrRsn.data);
    }, [dashErrRsn.data]);

    useEffect(() => {
        store.setFetchDashAvsIssues(true);
        store.setFetchDashErrRsn(true);
    }, [dataManagementStore?.newQuery]);

    return (
        <WidgetWrapper position='relative' height="100%">
            <div style={{display: 'flex', alignItems: 'center', height: 40}}>
                <div style={{position: 'absolute', left: 5}}>
                    <Dropdown
                        height={'25px'}
                        options={store.topicsAvs}
                        onChange={store.selectTopicAvs}
                        placeholder="Granularity"
                        toggleDisable={store.toggleDisable}
                        value={store.selectedTopicAvs}
                        labelWidth={'0px'}
                        width={'175px'}
                        margin={'0 2px 0 6px'}
                        border
                    />
                </div>
                <WidgetHeader>AVS</WidgetHeader>
            </div>
            <div></div>
            {/*<Divider />*/}
            <GridWrapper>
                {dashAvsData.status === 'loading' || dashErrRsn.status === 'loading' ? (
                    <LoadingSpinner size={'200px'}/>
                ) : dashAvsData.status === 'error' ? (
                    <Error error={dashAvsData.error}/>
                ) : dashErrRsn.status === 'error' ? (
                    <Error error={dashErrRsn.error}/>
                ) : (
                    <div
                        id="myGrid"
                        className={'ag-theme-alpine-dark'}
                        style={{
                            height: '100%',
                            minHeight: '300px',
                            width: '100%',
                            position: 'relative',
                            borderRadius: '5px',
                        }}
                    >
                        <AgGridReact
                            onGridReady={store.onGridReady}
                            rowData={store.colDefsAvsRowData.rowData}
                            columnDefs={store.colDefsAvsRowData.columnDefs}
                            gridOptions={store.gridOptions}
                            popupParent={document.getElementById('myGrid')}
                        />
                    </div>
                )}
            </GridWrapper>
        </WidgetWrapper>
    );
});

const GridWrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);

    .ag-header-row {
      height: 30px;
    }

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .topLevel {
      background-color: #001329 !important;
    }

    .secondLevel {
      background-color: #032845 !important;
    }
  }

  grid-area: SsTotalsGraph;
  position: relative;
    // width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .noData {
    margin: auto;
  }
`;

export default AvsReasons;
