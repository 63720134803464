import React, {useEffect} from 'react';
/* state management */
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
/* styles */
import styled from 'styled-components/macro';
import {
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    WidgetBody,
    WidgetWrapper
} from 'styles/styledComponents';
/* utils */
import {toCommaDecimal} from 'utils/currencyFilter';
import {_find, _findIndex, _get, _toNumber, useGetWidgetData, useInjectStores} from 'utils/utils';
/* component */
import {LoadingSpinner} from 'components/UI';
import {initialData} from './resources';
import {ChbgkChart} from './';
import {widgetService} from 'services';

const SsChgbkTotals = observer(() => {
    const {dataManagementStore, uiStore, userStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency;
    const formatNumber = utilsStore.formatNumber;

    const volumeformatter = (value) => {
        if (value === '0') {
            return `. ${value}% volume`;
        } else {
            return `${value}% volume`;
        }
    };

    const salesformatter = (value) => {
        if (value === '0') {
            return `.  ${value}% sales`;
        }
        return `${value}% sales`;
    };

    const getMidColorText = (volCat, amtCat) => {
        if (volCat === 'Danger' || amtCat === 'Danger') {
            return {
                text: 'Danger',
                color: '#FF2B49',
            };
        } else if (volCat === 'Warning' || amtCat === 'Warning') {
            return {
                text: 'Warning',
                color: '#F6EC4C',
            };
        } else {
            return {
                text: 'Ok',
                color: '#45E885',
            };
        }
    };

    const getColor = (cat) => {
        if (cat === 'Danger') {
            return '#FF2B49';
        } else if (cat === 'Warning') {
            return '#F6EC4C';
        } else {
            return '#45E885';
        }
    };

    const store = useLocalObservable(() => ({
        isLoading: false,
        widgetData: initialData,
        mids: [],
        mid: undefined,
        midOptions: [],
        selectMid(mid) {
            store.mid = mid;
        },
        get isDemo() {
            return _get(userStore,'user.demo', false);
        },
        get refactoredData() {
            if (this.widgetData?.values) {
                let details = this.widgetData.values;
                return [
                    {
                        category: 'Retrievals',
                        CBT01: _find(details, {hdr_typ_order: 'CBT01', cat_order: 'CB01'}),
                        CBT02: _find(details, {hdr_typ_order: 'CBT02', cat_order: 'CB01'}),
                        CBT03: _find(details, {hdr_typ_order: 'CBT03', cat_order: 'CB01'}),
                        CBT04: _find(details, {hdr_typ_order: 'CBT04', cat_order: 'CB01'}),
                        CBT05: _find(details, {hdr_typ_order: 'CBT05', cat_order: 'CB01'})
                    },
                    {
                        category: 'Fraud',
                        CBT01: _find(details, {hdr_typ_order: 'CBT01', cat_order: 'CB02'}),
                        CBT02: _find(details, {hdr_typ_order: 'CBT02', cat_order: 'CB02'}),
                        CBT03: _find(details, {hdr_typ_order: 'CBT03', cat_order: 'CB02'}),
                        CBT04: _find(details, {hdr_typ_order: 'CBT04', cat_order: 'CB02'}),
                        CBT05: _find(details, {hdr_typ_order: 'CBT05', cat_order: 'CB02'})
                    },
                    {
                        category: 'Cardholder Disputes',
                        CBT01: _find(details, {hdr_typ_order: 'CBT01', cat_order: 'CB03'}),
                        CBT02: _find(details, {hdr_typ_order: 'CBT02', cat_order: 'CB03'}),
                        CBT03: _find(details, {hdr_typ_order: 'CBT03', cat_order: 'CB03'}),
                        CBT04: _find(details, {hdr_typ_order: 'CBT04', cat_order: 'CB03'}),
                        CBT05: _find(details, {hdr_typ_order: 'CBT05', cat_order: 'CB03'})
                    },
                    {
                        category: 'Authorization Issues',
                        CBT01: _find(details, {hdr_typ_order: 'CBT01', cat_order: 'CB04'}),
                        CBT02: _find(details, {hdr_typ_order: 'CBT02', cat_order: 'CB04'}),
                        CBT03: _find(details, {hdr_typ_order: 'CBT03', cat_order: 'CB04'}),
                        CBT04: _find(details, {hdr_typ_order: 'CBT04', cat_order: 'CB04'}),
                        CBT05: _find(details, {hdr_typ_order: 'CBT05', cat_order: 'CB04'})
                    },
                    {
                        category: 'Processing Errors',
                        CBT01: _find(details, {hdr_typ_order: 'CBT01', cat_order: 'CB05'}),
                        CBT02: _find(details, {hdr_typ_order: 'CBT02', cat_order: 'CB05'}),
                        CBT03: _find(details, {hdr_typ_order: 'CBT03', cat_order: 'CB05'}),
                        CBT04: _find(details, {hdr_typ_order: 'CBT04', cat_order: 'CB05'}),
                        CBT05: _find(details, {hdr_typ_order: 'CBT05', cat_order: 'CB05'})
                    }
                ];
            }
        },
        get totals() {
            return {
                CBT01: _find(this.widgetData.totals, {hdr_typ_order: 'CBT01'}),
                CBT02: _find(this.widgetData.totals, {hdr_typ_order: 'CBT02'}),
                CBT03: _find(this.widgetData.totals, {hdr_typ_order: 'CBT03'}),
                CBT04: _find(this.widgetData.totals, {hdr_typ_order: 'CBT04'}),
                CBT05: _find(this.widgetData.totals, {hdr_typ_order: 'CBT05'}),
            };
        },
        getRatios() {
            return this.widgetData.ratios;
        },
        get ssChgbkTotals() {
            let ratios = this.widgetData.ratios;
            // if (this.widgetData) {
            // let { chgbk_amt_cat, chgbk_amt_ratio, chgbk_vol_cat, chgbk_vol_ratio, mid } = _get(
            //   this.widgetData,
            //   'ratios[0]',
            //   initialData
            // );
            /*const amtLabel = salesformatter(toCommaDecimal(chgbk_amt_ratio, 2));
              const volLabel = volumeformatter(toCommaDecimal(chgbk_vol_ratio, 2));
              const midHealth = getMidColorText(chgbk_vol_cat, chgbk_amt_cat);
              let volHealthColor = getColor(chgbk_vol_cat);
              let amtHealthColor = getColor(chgbk_amt_cat);
              if (_get(this.widgetData, 'ratios', []).length === 0) {
                return [
                  {
                    midNum: mid,
                    customDomain: false,
                    chgbk_amt_cat,
                    chgbk_amt_ratio,
                    chgbk_vol_cat,
                    chgbk_vol_ratio,
                    volHealthColor,
                    amtHealthColor,
                    midHealth,
                    data: {
                      amtLabel,
                      volLabel,
                      midHealth,
                      amt: chgbk_amt_ratio,
                      amtMax: chgbk_amt_ratio > 5 ? 5 : chgbk_amt_ratio || 2,
                      vol: chgbk_vol_ratio,
                      volMax: chgbk_vol_ratio > 5 ? 5 : chgbk_vol_ratio || 2,
                    },
                  },
                ];
              }*/

            if (this.widgetData?.ratios.length && this.widgetData.ratios[0].mid) {
                return ratios.map((ratio, index) => {
                    let {chgbk_amt_cat, chgbk_amt_ratio, chgbk_vol_cat, chgbk_vol_ratio, mid} = ratio;
                    const amtLabel = salesformatter(toCommaDecimal(chgbk_amt_ratio, 2));
                    const volLabel = volumeformatter(toCommaDecimal(chgbk_vol_ratio, 2));
                    const midHealth = getMidColorText(chgbk_vol_cat, chgbk_amt_cat);
                    let volHealthColor = getColor(chgbk_vol_cat);
                    let amtHealthColor = getColor(chgbk_amt_cat);
                    return [
                        {
                            mid,
                            customDomain: false,
                            chgbk_amt_cat,
                            chgbk_amt_ratio,
                            chgbk_vol_cat,
                            chgbk_vol_ratio,
                            volHealthColor,
                            amtHealthColor,
                            midHealth,
                            data: {
                                amtLabel,
                                volLabel,
                                midHealth,
                                amt: chgbk_amt_ratio,
                                amtMax: chgbk_amt_ratio > 5 ? 5 : chgbk_amt_ratio || 2,
                                vol: chgbk_vol_ratio,
                                volMax: chgbk_vol_ratio > 5 ? 5 : chgbk_vol_ratio || 2
                            }
                        }
                    ];
                });
            }
            // }
        },

        setG8ChrgbkSummary(g8ChrgbkSummary) {
            const {status, data, error} = g8ChrgbkSummary;
            if (status === 'loading') this.isLoading = true;
            if (error) this.isLoading = false;
            if (data) {
                // const { details, totals, ratios } = data;
                const {values, totals, ratios} = data;
                this.widgetData = data;
                values.map((data) => {
                    const updateIndex = _findIndex(this.widgetData.values, {
                        hdr_typ_order: data['hdr_typ_order'],
                        cat_order: data['cat_order']
                    });
                    this.widgetData.values[updateIndex] = data;
                });
                totals.map((data) => {
                    const updateIndex = _findIndex(this.widgetData.totals, {
                        hdr_typ_order: data['hdr_typ_order']
                    });
                    this.widgetData.totals[updateIndex] = data;
                });
                this.widgetData.ratios = ratios;
                this.isLoading = false;
            }
        },
    }));

    const g8ChrgbkSummary = useGetWidgetData(
        {name: 'chargebackSummary', query: widgetService.chargebackSummary},
        'data',
        dataManagementStore?.newQuery
    );

    useEffect(() => {
        store.setG8ChrgbkSummary(g8ChrgbkSummary);
    }, [g8ChrgbkSummary]);

    useEffect(() => {
        const mids = userStore.user.currentClient.mids.map((mid) => {
            return {
                key: mid,
                text: mid,
                value: mid
            };
        });
        store.mid = mids[0].value;
        store.midOptions = mids;
    }, []);

    return (
        <Observer>
            {() => (
                <StyledWrapper>
                    {store.isLoading && <WidgetWrapper>
                        <LoadingSpinner size={'200px'}/> </WidgetWrapper>}
                    {store.ssChgbkTotals?.length > 0 && <ChbgkChart store={store}/>}

                    {/*<ChbgkChart store={store} />*/}
                    <WidgetWrapper overflow="scroll" className="chgbks" position="relative">
                        <WidgetBody height="300px">
                            <TableHeader>NEW CASES</TableHeader>
                            {store.isLoading && <LoadingSpinner size={'200px'}/>}
                            {!store.isLoading && (
                                <div style={{display: 'flex'}}>
                                    <TableContainer maxWidth="135px" margin={'0 0 0 0'} padding={'0 0 0 0'}>
                                        <TableHeader fontSize={'15px'}>&nbsp;</TableHeader>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <HeaderCell
                                                        css={`
                                                          min-width: 119px;
                                                        `}
                                                    >
                                                        Category
                                                    </HeaderCell>
                                                </TableRow>
                                                {store.refactoredData.map((newCase) => (
                                                    <TableRow index={newCase.category}>
                                                        <TableCell>{_get(newCase, 'CBT01.category')}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell showTotalsLine>&nbsp;</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer margin={'0 0 0 0'} padding={'0 0 0 0'}>
                                        <TableHeader fontSize={'15px'}>Retrievals</TableHeader>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <HeaderCell>Count</HeaderCell>
                                                    <HeaderCell>Amount</HeaderCell>
                                                    <HeaderCell>Fees</HeaderCell>
                                                </TableRow>
                                                {store.refactoredData.map((newCase) => (
                                                    <TableRow index={newCase.category}>
                                                        <TableCell>{formatNumber(_toNumber(_get(newCase, 'CBT01.count')))}</TableCell>
                                                        <TableCell>{toCurrency(_get(newCase, 'CBT01.amount'))}</TableCell>
                                                        <TableCell>{toCurrency(_get(newCase, 'CBT01.fees'))}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell showTotalsLine>
                                                        {formatNumber(_toNumber(_get(store.totals, 'CBT01.tot_count', 0)))}
                                                    </TableCell>
                                                    <TableCell
                                                        showTotalsLine>{toCurrency(_get(store.totals, 'CBT01.tot_amount', 0))}</TableCell>
                                                    <TableCell
                                                        showTotalsLine>{toCurrency(_get(store.totals, 'CBT01.tot_fees', 0))}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer margin={'0 25px 0 25px'} padding={'0 0 0 0'}>
                                        <TableHeader fontSize={'15px'}>Chargebacks</TableHeader>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <HeaderCell showTotalsLine>Count</HeaderCell>
                                                    <HeaderCell showTotalsLine>Amount</HeaderCell>
                                                    <HeaderCell showTotalsLine>Fees</HeaderCell>
                                                </TableRow>
                                                {store.refactoredData.map((newCase) => (
                                                    <TableRow index={newCase.category}>
                                                        <TableCell>{toCommaDecimal(_toNumber(_get(newCase, 'CBT02.count')))}</TableCell>
                                                        <TableCell>{toCurrency(_get(newCase, 'CBT02.amount'))}</TableCell>
                                                        <TableCell>{toCurrency(_get(newCase, 'CBT02.fees'))}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell showTotalsLine>
                                                        {toCommaDecimal(_toNumber(_get(store.totals, 'CBT02.tot_count', 0)))}
                                                    </TableCell>
                                                    <TableCell
                                                        showTotalsLine>{toCurrency(_get(store.totals, 'CBT02.tot_amount', 0))}</TableCell>
                                                    <TableCell
                                                        showTotalsLine>{toCurrency(_get(store.totals, 'CBT02.tot_fees', 0))}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer margin={'0 0 0 0'} padding={'0 0 0 0'}>
                                        <TableHeader fontSize={'15px'}>Chargeback Reversals</TableHeader>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <HeaderCell>Count</HeaderCell>
                                                    <HeaderCell>Amount</HeaderCell>
                                                    <HeaderCell>Fees</HeaderCell>
                                                </TableRow>
                                                {store.refactoredData.map((newCase) => (
                                                    <TableRow index={newCase.category}>
                                                        <TableCell>{toCommaDecimal(_toNumber(_get(newCase, 'CBT03.count')))}</TableCell>
                                                        <TableCell>{toCurrency(_get(newCase, 'CBT03.amount'))}</TableCell>
                                                        <TableCell>{toCurrency(_get(newCase, 'CBT03.fees'))}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell showTotalsLine>
                                                        {toCommaDecimal(_toNumber(_get(store.totals, 'CBT03.tot_count', 0)))}
                                                    </TableCell>
                                                    <TableCell
                                                        showTotalsLine>{toCurrency(_get(store.totals, 'CBT03.tot_amount', 0))}</TableCell>
                                                    <TableCell
                                                        showTotalsLine>{toCurrency(_get(store.totals, 'CBT03.tot_fees', 0))}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </WidgetBody>
                    </WidgetWrapper>
                </StyledWrapper>
            )}
        </Observer>
    );
}
);

const StyledWrapper = styled.div`
display: grid;
grid-template-areas: 'chart details';
grid-template-columns: 350px 1fr;
grid-gap: 5px;

margin: 0 5px 5px 5px;
@media (max-width: 830px)
{
    grid - template - columns
:
    repeat(1, 1
    fr
)
    ;
}
`;

export default SsChgbkTotals;
