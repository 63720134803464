import React from 'react';
import { Input } from 'components/UI';
import styled from 'styled-components/macro';
import { Observer, useLocalStore } from 'mobx-react-lite';
import { commonService } from 'services';
import { useLocation, useHistory } from 'react-router-dom';
import { removeEmptyValues, useInjectStores, _get } from 'utils/utils';

const labelWidth = '210px';

export const AuthnetApi = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async testNewCreds() {
      store.loading = true;
      try {
        const post = removeEmptyValues(gwProc);
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));
  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Api Key"
            value={gwProc.credentials.ApiKey}
            onChange={gwProc.credentials.setApiKey}
            required
            label="API Key"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Api Id"
            value={gwProc.credentials.ApiId}
            onChange={gwProc.credentials.setApiId}
            required
            label="API Id"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

export const AuthnetScrape = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async testNewCreds() {
      store.loading = true;
      const post = removeEmptyValues(gwProc);
      try {
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));

  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Username"
            value={gwProc.credentials.Username}
            onChange={gwProc.credentials.setUsername}
            required
            label="Username"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Password"
            value={gwProc.credentials.Password}
            onChange={gwProc.credentials.setPassword}
            required
            label="Password"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex', margin: '0 0 4px 0' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

export const CybersourceScrapeGw = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async getData() {
      store.loading = true;
      const post = removeEmptyValues(gwProc);
      try {
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));

  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Org Id"
            value={gwProc.credentials.OrgId}
            onChange={gwProc.credentials.setOrgId}
            required
            label="Org Id"
            labelWidth={labelWidth}
          />
          <Input
            tabIndex={26}
            placeholder="Enter Username"
            value={gwProc.credentials.Username}
            onChange={gwProc.credentials.setUsername}
            required
            label="Username"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Password"
            value={gwProc.credentials.Password}
            onChange={gwProc.credentials.setPassword}
            required
            label="Password"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

export const CybersourceScrapeProc = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async getData() {
      store.loading = true;
      const post = removeEmptyValues(gwProc);
      try {
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));

  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Org Id"
            value={gwProc.credentials.OrgId}
            onChange={gwProc.credentials.setOrgId}
            required
            label="Org Id"
            labelWidth={labelWidth}
          />
          <Input
            tabIndex={26}
            placeholder="Enter Username"
            value={gwProc.credentials.Username}
            onChange={gwProc.credentials.setUsername}
            required
            label="Username"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Password"
            value={gwProc.credentials.Password}
            onChange={gwProc.credentials.setPassword}
            required
            label="Password"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

export const NMIScrape = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async testNewCreds() {
      store.loading = true;
      const post = removeEmptyValues(gwProc);
      try {
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));
  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Username"
            value={gwProc.credentials.Username}
            onChange={gwProc.credentials.setUsername}
            required
            label="Username"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Password"
            value={gwProc.credentials.Password}
            onChange={gwProc.credentials.setPassword}
            required
            label="Password"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

export const USAepayScrape = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async testNewCreds() {
      store.loading = true;
      const post = removeEmptyValues(gwProc);
      try {
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));
  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Username"
            value={gwProc.credentials.Username}
            onChange={gwProc.credentials.setUsername}
            required
            label="Username"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Password"
            value={gwProc.credentials.Password}
            onChange={gwProc.credentials.setPassword}
            required
            label="Password"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

export const USAepayApi = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useInjectStores();
  const gwProc = props.gwProc;

  const store = useLocalStore(() => ({
    async testNewCreds() {
      store.loading = true;
      const post = removeEmptyValues(gwProc);
      try {
        await commonService.testNewCredentials(post);
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));
  return (
    <Observer>
      {() => (
        <>
          <Input
            tabIndex={26}
            placeholder="Enter Api Key"
            value={gwProc.credentials.ApiKey}
            onChange={gwProc.credentials.setApiKey}
            required
            label="API Key"
            labelWidth={labelWidth}
          />
          <div></div>
          <Input
            tabIndex={27}
            placeholder="Enter Api Pin"
            value={gwProc.credentials.ApiPin}
            onChange={gwProc.credentials.setApiPin}
            required
            label="API Pin"
            labelWidth={labelWidth}
          />
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <div style={{ width: '145px' }} />
            <SubmitButton width="100px" className="submit" onClick={store.testNewCreds}>
              Test Creds
            </SubmitButton>
          </div>
        </>
      )}
    </Observer>
  );
};

const SubmitButton = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background-color: #3fa9f5;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;
