import React, { Fragment, useEffect } from 'react';
import { Button, LoadingSpinner } from 'components/UI';
import styled from 'styled-components/macro';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { _get, _includes, _toPairs, useInjectStores } from 'utils/utils';
import {
  ClearIcon,
  Divider,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { autorun } from 'mobx';

const NetValues = observer((props) => {
  const toCurrency = props.store.toCurrency;
  const formatNumber = props.store.formatNumber;
  const { filterStore } = useInjectStores();
  const oppFilter = filterStore.opportunityFilter;

  const store = useLocalStore(() => ({



  }));

  useEffect(
    () =>
      autorun(async () => {
        if (store.resetCurrentLocalFilter) {
          store.currentLocalFilter = '';
        }
        if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== oppFilter.category) {
          store.currentLocalFilter = oppFilter.category;
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <Wrapper margin={props.margin}>
          <TableWrapper height="100%">
            <TableHeader justify={'left'} textAlign={'left'} padding={'6px 6px 6px 10px'}>NET REMAINING</TableHeader>
            <Divider />
            {props.store.isLoadingOpp && <LoadingSpinner size={'200px'} />}
            <Table width="auto">
              <TableBody>
                {!props.store.isLoadingOpp && (
                  <Fragment>
                    <TableRow>
                      <HeaderCell minWidth={'180px'} textAlign={'left'}>Remaining Opportunity</HeaderCell>
                      <HeaderCell border={'none !important'} width={'100%'}/>
                    </TableRow>
                    {props.store.opp1 && props.store.opp1.length > 0 && (
                      <Fragment>
                        {props.store.opp1.map(
                          (value, index) => (
                            <TableRow key={`${value.opp_category}${index}`}>

                              <TableCell textAlign={'left'} width={'180px'}>
                                {toCurrency(_get(value,'likely_collectible') || 0)}
                              </TableCell>
                              <TableCell border={'none'} width={'100%'}/>
                            </TableRow>
                          )
                        )}
                        <TableRow>
                          <TableCell  minWidth={'180px'} textAlign={'left'} showTotalsLine>
                            {toCurrency(props.store.oppABDTotals.likely_collectible)}
                          </TableCell>
                          <TableCell border={'none'} width={'100%'}/>
                        </TableRow>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </TableBody>
            </Table>

          </TableWrapper>

        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

export default NetValues;
