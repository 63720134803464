import React, { Fragment, useEffect } from 'react';
import { autorun } from 'mobx';
import {
  HeaderCell,
  MsgBox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper
} from '../StyledTable';
import { LoadingSpinner } from 'components/UI';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import { StoreContext } from 'stores/RootStore';
import { _get } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import ShipNoShipModal from '../WidgetComponentsOther/ShipNoShipModal/shipNoShipModal';
import { widgetService } from 'services';

const widgetConstants = { name: 'rptShipNoShipTotals', id: 18 };

const initialData = {
  ship: { count: 0, totalAmt: 0 },
  noShip: { count: 0, totalAmt: 0 }
};

const RptShipNoShipTotals = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { name, id } = widgetConstants;
  const rootStore = React.useContext(StoreContext),
    toCurrency = rootStore.utilsStore.toCurrency,
    uiStore = rootStore.uiStore,
    dataManagementStore = rootStore.dataManagementStore;
  const store = useLocalStore(() => ({
    loading: false,
    widgetData: initialData,
    toCurrency,
    async getData() {
      store.loading = true;
      try {
        const res = await widgetService.noShipSummary();
        store.widgetData = res.data.data.widgets[name].data.data;
      } catch (error) {
        store.widgetData = initialData;
        console.log('err', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          rootStore.userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));

  useEffect(() =>
    autorun(async () => {
      if (dataManagementStore.newQuery) {
        await store.getData();
      }
    })
  );

  return (
    <Observer>
      {() => (
        <Fragment>
          <TableWrapper
            hover
            onClick={uiStore.toggleShowShipNoShipReportModal}
            flexGrow={'0'}
            style={{ cursor: 'pointer', margin: '0px 5px 5px', width: '50%' }}
          >
            <TableHeader>Ship/No Ship Report</TableHeader>
            {store.loading && <LoadingSpinner size={'200px'} />}
            <TableContainer>
              <Table>
                {!store.loading && (
                  <TableBody>
                    <TableRow>
                      <HeaderCell></HeaderCell>
                      <HeaderCell>Count</HeaderCell>
                      <HeaderCell>Amount</HeaderCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship</TableCell>
                      <TableCell>{store.widgetData.ship.count}</TableCell>
                      <TableCell>{toCurrency(store.widgetData.ship.totalAmt)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>No Ship</TableCell>
                      <TableCell>{store.widgetData.noShip.count}</TableCell>
                      <TableCell>{toCurrency(store.widgetData.noShip.totalAmt)}</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {!store.loading &&
              !store.widgetData.ship.count &&
              !store.widgetData.ship.totalAmt &&
              !store.widgetData.noShip.count &&
              !store.widgetData.noShip.totalAmt && <MsgBox>No Data Available For This DateRange</MsgBox>}
          </TableWrapper>

          {uiStore.showShipNoShipReportModal && (
            <ShipNoShipModal
              showShipNoShipModal={uiStore.showShipNoShipReportModal}
              toggleShowShipNoShipModal={uiStore.toggleShowShipNoShipReportModal}
              width={'90vw'}
            />
          )}
        </Fragment>
      )}
    </Observer>
  );
});

export default RptShipNoShipTotals;
