import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import RptNoShipTxns from '../../RptNoShipTxns/RptNoShipTxns';
import RptShipTxns from '../../RptShipTxns/RptShipTxns';
import { ReportWrapper } from 'styles/styledComponents';
import { LoadingSpinner, Modal } from 'components/UI';
import RptNoShipTotals from './RptNoShipTotals/RptNoShipTotals';
import RptShipTotals from './RptShipTotals/RptShipTotals';
import {
  ModalContainer,
  HeaderWrapper,
  Header,
  HeaderTitle,
  HeaderInfo,
  ModalContentContainer,
  Side,
  TotalsWrapper,
} from './StyledShipNoShipModal';
import { _get } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { StoreContext } from 'stores/RootStore';

const height = '20px';

/** Widget Constants specific to this modal **/
const widgetConstants = { name: 'rptShipNoShipTotals', id: 18 };

const ShipNoShipModal = observer(({ toggleShowShipNoShipModal, showShipNoShipModal }) => {
  const history = useHistory();
  const location = useLocation();
  const { id, name } = widgetConstants;
  const rootStore = React.useContext(StoreContext);
  let noShipTxnsWidget = rootStore.widgetStore.rptNoShipTxnsWidget;

  const toCurrency = rootStore.utilsStore.toCurrency,
    setRespCodeSname = noShipTxnsWidget.filterModel.setRespCodeSname,
    noShipReason = _get(rootStore.widgetStore, 'rptNoShipTxnsWidget.filterModel.respCodeSname'),
    setPageNumber = _get(rootStore.widgetStore.rptNoShipTxnsWidget.paginatorModel, 'setPageNumber', () => {}),
    dataManagementStore = rootStore.dataManagementStore;

  const initialData = { ship: { count: 0, totalAmt: 0, subs: [] }, noShip: { count: 0, totalAmt: 0, subs: [] } };

  const localStore = useLocalStore(() => ({
    /** General Use Variables **/
    loading: false,
    widgetData: initialData,

    async getData() {
      localStore.loading = true;
      try {
        const res = await dataManagementStore.fetchWidgetData(id);
        localStore.widgetData = res.data.data.widgets[name].data.data;
        if (localStore.widgetData) {
          localStore.formatNoShipData();
          localStore.formatShipData();
        }
        localStore.loading = false;
      } catch (error) {
        console.log('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          rootStore.userStore.logout(history, location);
        }
        localStore.loading = false;
      }
    },

    formatShipData() {
      let newRowArray = [];

      localStore.shipData.forEach((txnType) => {
        newRowArray.push({
          txnType: txnType.txnType,
          category: '',
          declineReason: '',
          displayCategories: txnType.displayCategories,
          count: txnType.count,
          total: txnType.totalAmt,
          type: txnType.type,
        });
        txnType.subs.forEach((category) => {
          if (!category.declBkt) category.declBkt = 'Unknown';
          if (!category.displayReasons && category.displayReasons !== false) category.displayReasons = false;
          newRowArray.push({
            txnType: txnType.txnType,
            category: category.declBkt,
            declineReason: '',
            displayCategories: txnType.displayCategories,
            displayReasons: category.displayReasons,
            count: category.count,
            total: category.totalAmt,
            type: category.type,
          });
          category.subs.forEach((reason) => {
            if (!reason.declRsn) reason.declRsn = 'Unknown';
            newRowArray.push({
              txnType: txnType.txnType,
              category: category.declBkt,
              declineReason: reason.declRsn,
              displayReasons: category.displayReasons,
              count: reason.count,
              total: reason.totalAmt,
              type: reason.type,
            });
          });
        });
      });

      localStore.formattedShipData = newRowArray;
    },
    formatNoShipData() {
      let newRowArray = [];

      localStore.noShipData.forEach((txnType) => {
        newRowArray.push({
          txnType: txnType.txnType,
          category: '',
          declineReason: '',
          displayCategories: txnType.displayCategories,
          count: txnType.count,
          total: txnType.totalAmt,
          type: txnType.type,
        });
        txnType.subs.forEach((category) => {
          if (!category.declBkt) category.declBkt = 'Unknown';
          if (!category.displayReasons && category.displayReasons !== false) category.displayReasons = false;
          newRowArray.push({
            txnType: txnType.txnType,
            category: category.declBkt,
            declineReason: '',
            displayCategories: txnType.displayCategories,
            displayReasons: category.displayReasons,
            count: category.count,
            total: category.totalAmt,
            type: category.type,
          });
          category.subs.forEach((reason) => {
            if (!reason.declRsn) reason.declRsn = 'Unknown';
            newRowArray.push({
              txnType: txnType.txnType,
              category: category.declBkt,
              declineReason: reason.declRsn,
              displayReasons: category.displayReasons,
              count: reason.count,
              total: reason.totalAmt,
              type: reason.type,
            });
          });
        });
      });

      localStore.formattedNoShipData = newRowArray;
    },
    get shipData() {
      return localStore.widgetData.ship.subs;
    },
    /** Store Functions and Data Getters **/
    get ShipNoShipTotalsData() {
      return _get(localStore, 'widgetData');
    },
    /** Properties to Pass into Multiple Components **/
    filterTableRows(totals) {
      return totals.filter((txn) => {
        return (
          txn.type === 'txnType' ||
          (txn.type === 'category' && txn.displayCategories) ||
          (txn.type === 'reason' && txn.displayCategories && txn.displayReasons)
        );
      });
    },
    getClassName(passedTxn, startingValues) {
      /** Create the starting className string **/
      let className = '';
      className += startingValues.join(' ');
      /** if it's a txnType or category append the classes that will change the background color **/
      if (passedTxn.type === 'txnType') className += ' txnType';
      else if (passedTxn.type === 'category') className += ' category';
      else className += ' normal';

      return className;
    },

    /** RptShipTotals Properties to Pass **/
    formattedShipData: [],
    get shipCount() {
      return localStore.widgetData.ship.count;
    },
    get shipTotalAmount() {
      return toCurrency(localStore.widgetData.ship.totalAmt);
    },
    toggleShipTxnsDisplay(passedTxn) {
      if (passedTxn.type === 'txnType') {
        localStore.formattedShipData.forEach((txn) => {
          if (txn.txnType === passedTxn.txnType) {
            txn.displayCategories = !txn.displayCategories;
          }
          if (!txn.displayCategories) {
            txn.displayReasons = false;
          }
        });
      }
      if (passedTxn.type === 'category') {
        localStore.formattedShipData.forEach((txn) => {
          if (txn.txnType === passedTxn.txnType && txn.category === passedTxn.category) {
            txn.displayReasons = !txn.displayReasons;
          }
        });
      }
    },

    /** RptNoShipTotals Properties to Pass **/
    formattedNoShipData: [],
    get noShipCount() {
      return localStore.widgetData.noShip.count;
    },
    get noShipTotalAmount() {
      return toCurrency(localStore.widgetData.noShip.totalAmt);
    },
    get noShipData() {
      return localStore.widgetData.noShip.subs;
    },
    toggleNoShipTxnsDisplay(passedTxn) {
      if (passedTxn.type === 'txnType') {
        localStore.formattedNoShipData.forEach((txn) => {
          if (txn.txnType === passedTxn.txnType) {
            txn.displayCategories = !txn.displayCategories;
          }
          if (!txn.displayCategories) {
            txn.displayReasons = false;
          }
        });
      }
      if (passedTxn.type === 'category') {
        localStore.formattedNoShipData.forEach((txn) => {
          if (txn.txnType === passedTxn.txnType && txn.category === passedTxn.category) {
            txn.displayReasons = !txn.displayReasons;
          }
        });
      }
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          await localStore.getData();
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <StyledWrapper>
          <Modal height={'95vh'} width={'94vw'} toggleModal={toggleShowShipNoShipModal} isOpen={showShipNoShipModal}>
            <ModalContainer margin={'0 0 30px 0'}>
              <HeaderWrapper>
                <Header>
                  <HeaderTitle>Ship, No-Ship Report</HeaderTitle>
                  <HeaderInfo>
                    <span>Note</span>: If an order in your fulfillment system is not listed below, then it indicates that no
                    attempt was made in the credit card industry for payment.
                  </HeaderInfo>
                </Header>
              </HeaderWrapper>

              <ModalContentContainer>
                <Side>
                  <TotalsWrapper>
                    {localStore.loading && (
                      <ReportWrapper height={'100%'}>
                        <LoadingSpinner size={'200px'} />
                      </ReportWrapper>
                    )}
                    {!localStore.loading && (
                      <ReportWrapper height={'100%'}>
                        <RptNoShipTotals
                          noShipReason={noShipReason}
                          setRespCodeSname={setRespCodeSname}
                          setPageNumber={setPageNumber}
                          filterTableRows={localStore.filterTableRows}
                          formattedNoShipData={localStore.formattedNoShipData}
                          getClassName={localStore.getClassName}
                          height={height}
                          toggleNoShipTxnsDisplay={localStore.toggleNoShipTxnsDisplay}
                          noShipCount={localStore.noShipCount}
                          noShipTotalAmount={localStore.noShipTotalAmount}
                        />
                      </ReportWrapper>
                    )}
                  </TotalsWrapper>
                  <RptNoShipTxns margin={'2px 4px 2px 4px'} />
                </Side>

                <Side>
                  <TotalsWrapper>
                    {localStore.loading && (
                      <ReportWrapper height={'100%'}>
                        <LoadingSpinner size={'200px'} />
                      </ReportWrapper>
                    )}
                    {!localStore.loading && (
                      <ReportWrapper height={'100%'}>
                        <RptShipTotals
                          filterTableRows={localStore.filterTableRows}
                          formattedShipData={localStore.formattedShipData}
                          getClassName={localStore.getClassName}
                          height={height}
                          toggleShipTxnsDisplay={localStore.toggleShipTxnsDisplay}
                          shipCount={localStore.shipCount}
                          shipTotalAmount={localStore.shipTotalAmount}
                        />
                      </ReportWrapper>
                    )}
                  </TotalsWrapper>

                  <RptShipTxns margin={'2px 4px 2px 4px'} />
                </Side>
              </ModalContentContainer>
            </ModalContainer>
          </Modal>
        </StyledWrapper>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  .widget {
    margin: 0 3px;
  }
`;

export default ShipNoShipModal;
