import React, { useEffect } from 'react';
import {
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow
} from 'styles/styledComponents';
import { RouteLoadingSpinner } from 'components/UI';
import { _get } from 'utils/utils';
import { Observer, useLocalObservable } from 'mobx-react-lite';

const ResFund = (props) => {
  const store = useLocalObservable(() => ({
    widgetData: null,
    get transactionSection() {
      return _get(this, 'widgetData[\'MERCHANT_STATEMENT_APPLICATION\'][\'CUSTOMER\'][0][\'TRANSACTION_SECTION\'][0]');
    },
    get resFundTran() {
      return _get(this.transactionSection, "['RESFUND_TRAN']");
    },
    get resfundTranTotals() {
      return _get(this.transactionSection, "['RESFUND_TRAN']");
    },
    setWidgetData(data) {
      this.widgetData = data;
    },
  }));

  useEffect(() => {
    store.setWidgetData(props.store.widgetData);
  }, [props.store.widgetData]);

  return (
    <Observer>
      {() => (
        <div>
          <TableHeader light={true} backgroundColor="#e9eae9">
            Reserve Funding
          </TableHeader>

          {props.store.isLoading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Day
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Reserve
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Release
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Reserve Balance
                    </HeaderCell>
                    <HeaderCell borderBottom="" light={true}/>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Day
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Reserve
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Release
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Reserve Balance
                    </HeaderCell>
                  </TableRow>
                  {store.resFundTran && store.resFundTran.length > 0 && (
                    <>
                      {store.resFundTran.map((detail, index) => (
                        <TableRow key={index}>
                          <TableCell alignContent="left" light={true}>
                            {detail['DAY']}
                          </TableCell>
                          <TableCell alignContent="left" light={true}>
                            {detail['AMOUNT_RES']}
                          </TableCell>
                          <TableCell alignContent="left" light={true}>
                            {detail['AMOUNT_REL']}
                          </TableCell>
                          <TableCell light={true}>{detail['RESERVE_BAL']}</TableCell>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell light={true}/>
                        </TableRow>
                      ))}
                    </>
                  )}
                  {store.resfundTranTotals && store.resfundTranTotals.length > 0 && (
                    <>
                      {store.resfundTranTotals.map((detail, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {index === 0 ? 'Totals' : ''}
                          </TableCell>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell alignContent="left" light={true}/>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['AMOUNT_RES']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['AMOUNT_REL']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['RESERVE_BAL']}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}
    </Observer>
  );
};

export default ResFund;
