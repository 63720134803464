import styled from 'styled-components/macro';

const ViewName = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.2em;
  transition: all 1s;
  color: ${({ theme }) => theme.header.colors.viewName};
`;
const Logout = styled.div`
  color: ${({ theme }) => theme.header.colors.logout};
  position: relative;
  display: flex;
`;

const PopUpContent = styled.div`
  padding: 0;
`;
const PopUpContentWrapper = styled.div`
  align-items: center;
  color: rgb(91, 91, 91);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;
const Item = styled.div`
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  color: rgb(91, 91, 91);
  display: flex;
  height: 25px;
  justify-content: center;
  width: 100%;
  a {
    text-decoration: none;
    color: rgb(91, 91, 91);
  }
  &:hover {
    cursor: pointer;
  }
`;
const SubItemWrapper = styled.div`
  border-bottom: 1px solid #ddd;
  color: rgb(91, 91, 91);
  padding: 0 5px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }
`;
const SubItem = styled.div`
  align-items: center;
  display: flex;
  font-size: small;
  min-height: 32px;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const ViewNameWrapper = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  color: rgba(255, 255, 255);
  font-size: ${({ fontSize }) => fontSize};
  align-items: center;
`;
const User = styled.div`
  white-space: nowrap;
  cursor: pointer;
`;

export { Logout, ViewName, PopUpContent, PopUpContentWrapper, Item, SubItemWrapper, SubItem, ViewNameWrapper, User };
