import React, {useEffect} from 'react';
import SsChgbkTotals from 'components/Widgets/SsChgbkTotals/SsChgbkTotals';
import SsChgbkTxns from 'components/Widgets/SsChgbkTxns/SsChgbkTxns';
import styled from 'styled-components/macro';
import {Observer, observer} from 'mobx-react-lite';
import {TableContainer, TableHeader} from '../../components/Widgets/StyledTable';
import {WidgetWrapper} from 'styles/styledComponents';
import {useCheckAuthorizedRoute, useInjectStores} from 'utils/utils';
import {RouteLoadingSpinner} from 'components/UI';
import ReactGA from 'react-ga';
import {Link, Route, useLocation, useRouteMatch} from "react-router-dom";
import ChargebacksSummary from "../../components/Widgets/ChargebacksSummary/ChargebacksSummary";

const Chargebacks = observer(() => {
    const {loading, authorized} = useCheckAuthorizedRoute();
    const {userStore} = useInjectStores();
    const user = userStore.user;
    useEffect(() => {
        ReactGA.initialize('UA-195070931-1', {
            gaOptions: {
                clientId: user.currentClient.id,
                clientName: user.currentClient.clientName,
                userEmail: user.email
            },
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
    return (
        <Observer>
            {() => (
                <>
                    {loading ? (
                        <RouteLoadingSpinner size={'400px'}/>
                    ) : authorized ? (
                        <Wrapper>
                            <div className="tabs">
                                <Tabs/>
                            </div>
                            <div className="content">
                              <Route exact path={`/transactions/chargebacks/summary`}>
                                  <ChargebackInfo className="links"/>
                                  <ChargebacksSummary className="txns"/>
                                  {/*<SsChgbkTxns className="txns"/>*/}
                              </Route>
                              <Route exact path={`/transactions/chargebacks`}>
                                  <SsChgbkTxns className="txns"/>
                              </Route>
                            </div>
                        </Wrapper>
                    ) : (
                        <div>error</div>
                    )}
                </>
            )}
        </Observer>
    );
});

const Tabs = () => {
    const {url} = useRouteMatch();
    const location = useLocation();
    return (
        <div className="tabWrapper">
            {/*<Link
                className={`tab one ${location.pathname.includes('analytics')
                && !location.pathname.includes('details')
                && !location.pathname.includes('notes') ? 'active' : ''}`}
                to={`${url}`}>
                Graphs
            </Link>*/}
            <Link
                className={`tab one ${location.pathname.includes('summary') ? 'active' : ''}`}
                to={`${url}/summary`}>
                Summary
            </Link>
            <Link
                className={`tab two ${location.pathname.includes('chargebacks')
                && !location.pathname.includes('summary')
                    ? 'active' : ''}`}
                to={`${url}`}>
                Details
            </Link>
        </div>
    );
};

const Wrapper = styled.div`
  height: 96%;
  flex-direction: column;
  margin: 0 4px;

  .content {
    height: 100%;
    width: 100%;
    grid-area: content;
  }

  grid-template-areas: 
          'tabs'
           'content';
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-gap: 5px;


  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;
    margin-bottom: 4px;

    grid-template-areas: 'one two';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }

    .test {
      margin-left: 5px;
    }
  }

`;

const ChargebackInfo = () => {
    const Wrapper = styled.div`
      font-size: 12px;
      margin: 0 0 4px 0;
      // margin: ${({margin}) => margin};

      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.8);

        &:active {
          color: rgba(255, 255, 255, 0.8);
        }

        &:visited {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .chgbkLinks {
        //display: flex;
        justify-content: center;
        display: grid;

        .link {
          grid-area: link;
          display: flex;
          justify-content: flex-end;
        }

        .fax {
          grid-area: fax;
          display: flex;
          justify-content: center;
          min-width: 150px;
        }

        .email {
          grid-area: email;
          display: flex;
          justify-content: flex-start;
        }

        grid-template-areas: 'link fax email';
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        //grid-gap: 5px;

        @media (max-width: 835px) {
          .link {
            justify-content: center;
          }

          .email {
            justify-content: center;
          }

          grid-template-areas:
          'link '
          'fax'
          'email ';
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }
      }

      .info {
        width: 100%;
        text-align: center;
        margin: 4px 0;
      }

      .evidence {
        //font-size: 14px;
        text-align: center;
      }

      .blue {
        color: ${({theme}) => theme.baseColors.colorBlue};
      }
    `;

    return (
        <Wrapper margin={'0 5px 4px 5px'}>
            <WidgetWrapper height={'100%'}>
                <TableHeader fontSize="14px" padding="4px 0 4px 0">
                    Submit Chargeback Evidence To:
                </TableHeader>
                <TableContainer margin={'0 0 5px 0'} padding="0px 0 2px 0">
                    <div className="chgbkLinks">
                        <a className="link" rel="noopener noreferrer" target="_blank"
                           href="https://merlinkresponse.com/">
                            Link:&nbsp;
                            <span
                                css={`
                                  color: ${({theme}) => theme.baseColors.colorBlue};
                                `}
                                className="blue"
                            >
                https://merlinkresponse.com
              </span>
                        </a>
                        <div className="fax">
                            Fax:&nbsp;
                            <span
                                css={`
                                  color: ${({theme}) => theme.baseColors.colorBlue};
                                `}
                                className="blue"
                            >
                706-644-5210{' '}
              </span>
                            &nbsp;
                        </div>
                        <a className="email" rel="noopener noreferrer" target="_blank"
                           href="mailto:service@goldenAIT.com">
                            Email:&nbsp;
                            <span
                                css={`
                                  color: ${({theme}) => theme.baseColors.colorBlue};
                                `}
                                className="blue"
                            >
                support@eight.tech
              </span>
                        </a>
                    </div>
                    <div>
                        <div className="info">Include your chargeback case number at the top of every page</div>
                        <div className="evidence">
                            *Evidence examples include signed sales receipt, invoice, email correspondence with
                            customer, signed POD, pictures,
                            terms and conditions, AVS/ CVV2 match, etc.
                        </div>
                    </div>
                </TableContainer>
            </WidgetWrapper>
        </Wrapper>
    );
};

export default Chargebacks;
