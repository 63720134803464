import React from 'react';
import {Observer} from 'mobx-react-lite';
import {Dropdown, Input} from 'components/UI';

export function ClientInfoForm(props) {
    // const countryOptions = props.countryOptions;
    // const timezoneOptions = props.timezoneOptions;
    // const currencyOptions = props.currencyOptions;
    // const pricingPlanOptions = props.pricingPlanOptions;
    const labelWidth = '210px';
    const margin = '0 0 3px 0';

    return (
        <Observer>
            {() => (
                <React.Fragment>
                    <Observer className="clientName">
                        {() => (
                            <Input
                                tabIndex={1}
                                placeholder="Enter client name"
                                value={props.clientModel.clientInfo.clientName}
                                onChange={props.clientModel.clientInfo.setClientName}
                                required
                                label="Client Name"
                                labelWidth={labelWidth}
                                margin={margin}
                            />
                        )}
                    </Observer>

                    {/*   <Observer className="activeClient">
        {() => (
          <div className="acContainer">
            <Checkbox
              required
              checked={store.clientInfo.clientActive}
              onChange={store.clientInfo.setClientActive}
              label="Active"
              labelWidth={'129px'}
              margin={margin}
            />
          </div>
        )}
      </Observer>*/}

                    <Observer className="contactFName">
                        {() => (
                            <Input
                                tabIndex={2}
                                placeholder="Enter first name"
                                value={props.clientModel.clientInfo.clientContactFname}
                                onChange={props.clientModel.clientInfo.setContactFName}
                                required
                                label="First Name"
                                labelWidth={labelWidth}
                                margin={margin}
                            />
                        )}
                    </Observer>

                    <div className="contactLName">
                        <Input
                            tabIndex={3}
                            value={props.clientModel.clientInfo.clientContactLname}
                            onChange={props.clientModel.clientInfo.setContactLName}
                            required
                            placeholder="Enter Last Name"
                            label="Last Name"
                            margin={margin}
                            labelWidth={labelWidth}
                        />
                    </div>

                    <div className="contactEmail">
                        <Input
                            tabIndex={4}
                            placeholder="Enter client contact email"
                            value={props.clientModel.clientInfo.clientContactEmail}
                            onChange={props.clientModel.clientInfo.setClientContactEmail}
                            required
                            label="Contact Email"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>
                    <div className="addr1">
                        <Input
                            tabIndex={6}
                            placeholder="Enter addr1"
                            value={props.clientModel.clientInfo.addr1}
                            onChange={props.clientModel.clientInfo.setAddr1}
                            required
                            label="Address 1"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>
                    <div className="contactPhone">
                        <Input
                            tabIndex={5}
                            placeholder="Enter phone number"
                            value={props.clientModel.clientInfo.clientContactPhone}
                            onChange={props.clientModel.clientInfo.setClientContactPh}
                            required
                            label="Phone Number"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>

                    <div className="addr2">
                        <Input
                            tabIndex={7}
                            placeholder="Enter addr2"
                            value={props.clientModel.clientInfo.addr2}
                            onChange={props.clientModel.clientInfo.setAddr2}
                            label="Address 2"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>


                    <div className="stProv">
                        <Input
                            tabIndex={10}
                            placeholder="Enter st/prov"
                            value={props.clientModel.clientInfo.stProv}
                            onChange={props.clientModel.clientInfo.setStProv}
                            required
                            label="State / Province"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>

                    <div className="addr3">
                        <Input
                            tabIndex={9}
                            placeholder="Enter addr3"
                            value={props.clientModel.clientInfo.addr3}
                            onChange={props.clientModel.clientInfo.setAddr3}
                            label="Address 3"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>

                    <div className="city">
                        <Input
                            tabIndex={8}
                            placeholder="Enter city"
                            value={props.clientModel.clientInfo.city}
                            onChange={props.clientModel.clientInfo.setCity}
                            required
                            label="City"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>


                    <div className="ctryID">
                        <Dropdown
                            tabIndex={12}
                            placeholder="Enter Country"
                            value={props.clientModel.clientInfo.country}
                            onChange={props.clientModel.clientInfo.setCtry}
                            options={props.store.countryOptions}
                            required
                            label="Country"
                            labelWidth={labelWidth}
                            margin={margin}
                            search
                        />
                    </div>


                    <div className="zip">
                        <Input
                            tabIndex={11}
                            placeholder="Enter ZIP"
                            value={props.clientModel.clientInfo.zip}
                            onChange={props.clientModel.clientInfo.setZip}
                            required
                            label="Zip Code"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>

                    {/* <div className="tmzID">
            <Dropdown
              tabIndex={13}
              placeholder="Select Timezone"
              value={props.clientModel.clientInfo.tmzID}
              options={props.store.timezoneOptions}
              onChange={props.clientModel.clientInfo.setTmzID}
              label="Select Timezone"
              labelWidth={labelWidth}
              required
              margin={margin}
              search
            />
          </div>*/}
                    <div className="fein">
                        <Input
                            tabIndex={14}
                            placeholder="Enter FEIN"
                            value={props.clientModel.clientInfo.fein}
                            onChange={props.clientModel.clientInfo.setFein}
                            required
                            label="FEIN"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>
                    <div className="mccSic">
                        <Input
                            tabIndex={15}
                            placeholder="Enter MCC"
                            value={props.clientModel.clientInfo.mccSic}
                            onChange={props.clientModel.clientInfo.setMccSic}
                            required
                            label="MCC"
                            labelWidth={labelWidth}
                            margin={margin}
                        />
                    </div>
                    <Observer className="functCurrID">
                        {() => (
                            <Dropdown
                                tabIndex={16}
                                placeholder="Select Currency"
                                onChange={props.clientModel.clientInfo.setFunctCurrID}
                                value={props.clientModel.clientInfo.functCurrID}
                                options={props.store.currencyOptions}
                                label="Select Funct Curr"
                                labelWidth={labelWidth}
                                required
                                margin={margin}
                                search
                            />
                        )}
                    </Observer>
                    <Dropdown
                        tabIndex={17}
                        placeholder="Select Pricing Plan"
                        label={'Pricing Plan'}
                        onChange={props.clientModel.pricingPlan.setPricingPlan}
                        value={props.clientModel.pricingPlan.pricingPlanID}
                        options={props.store.pricingPlanOptions}
                        labelWidth={labelWidth}
                        margin={margin}
                        required
                    />
                </React.Fragment>
            )}
        </Observer>
    );
}
