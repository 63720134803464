import React, { Fragment, useEffect, useState } from 'react';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { adminService } from 'services';
import { Button, Input, Dropdown, Checkbox, LoadingSpinner } from 'components/UI';
import CheckBoxGroup from './CheckboxGroup.js';
import Modal from './Modal.js';
import Dialog from './Dialog.js';
import { ArrowCircleLeftIcon } from 'styles/styledComponents';
import { Redirect, Link, useHistory } from 'react-router-dom';
import {adminStore} from 'stores/AdminStore';
import {_get, useInjectStores} from 'utils/utils';
import PricingPage from '../../../Pricing';
import { event } from 'react-ga';

const UserManager = observer(({match, isEdit, store}) => {
	const history = useHistory();
	const userRoleOptions = [
		{
			text: 'User', value: 'User'
		}
	];
	const [user, setUser] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		phone: '',
		role: '',
		permissions: [],
		isValidEmail: true
	});
	const {dataManagementStore, userStore, uiStore} = useInjectStores();
	const [changePassword, setChangePassword] = useState(false);
	const notify = uiStore.notify;
	const [isModalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	if(userStore.user.userRole === 'Admin' || userStore.user.userRole === 'Eight') {
		userRoleOptions.unshift({
			text: 'Manager', value: 'Manager'
		});
	};
	if(userStore.user.superAdmin && !adminStore.users.some(user => user.role === 'Admin')) {
		userRoleOptions.unshift({
			text: 'Admin', value: 'Admin'
		});
	}
	const userRole = _get(userStore.user, 'userRole');
	const [errors, setErrors] = useState({});
	const [newPassword, setNewPassword] = useState('');

	const validate = () => {
		let localErrors = {};
		if (!user.firstName.trim()) {
			localErrors.firstName = "First Name is required!";
		}

		if (!user.lastName.trim()) {
			localErrors.lastName = "Last Name is required!";
		}

		if (!user.email.trim() || !/\S+@\S+\.\S+/.test(user.email)) {
			localErrors.email = "Valid email is required!";
		}

		if(!isEdit) {
			if (!user.password.trim()) {
				localErrors.password = "Password is required!";
			} else if (user.password.length < 8) {
				localErrors.password = "Password must be at least 8 characters long!";
			} else if (!/[A-Z]/.test(user.password)) {
				localErrors.password = "Password must contain at least one uppercase letter!";
			} else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(user.password)) {
				localErrors.password = "Password must contain at least one special character!";
			}
		}
		
		if (!user.role.trim()) {
			localErrors.role = "Role is required!";
		}

		return localErrors;
    };

	const handleNewPassChange = (event) => {
		setNewPassword(event.target.value);
	};

	const validateNewPassword = () => {
		let passwordError = {};
		if (!newPassword.trim()) {
			passwordError.newPassword = "Password is required!";
		} else if (newPassword.length < 8) {
			passwordError.newPassword = "Password must be at least 8 characters long!";
		} else if (!/[A-Z]/.test(newPassword)) {
			passwordError.newPassword = "Password must contain at least one uppercase letter!";
		} else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(newPassword)) {
			passwordError.newPassword = "Password must contain at least one special character!";
		}
		return passwordError;
	}
	
	if(!Object.keys(adminStore.userToEdit).length && isEdit) {
		return <Redirect to="/admin/user/settings" />;
	}

	adminStore.setAuthenticatedUser(userStore.user);

	if(isEdit) {
		useEffect(() => {
			const clientId = dataManagementStore.clientID;
			async function getUserData() {
				await adminStore.getUserByID({ clientID: clientId, userEmail: adminStore.userToEdit.username });
				setUser({...adminStore.userObj, isValidEmail: true});
				setLoading(false);
			};
			
			getUserData();
		}, [dataManagementStore.clientID]);
	} else {
		useEffect(() => {
			const clientId = dataManagementStore.clientID;
			async function getUserData() {
				await adminStore.updatePricing();
				await userStore.fetchPricingData();
				await adminStore.getUserTemplate({ clientID: clientId, checkEmail: '' });
				setUser({...adminStore.userObj, isValidEmail: true});
				setLoading(false);
			};
	
			getUserData();
		}, [dataManagementStore.clientID]);
	}
	

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};


	const handleChange = (event) => {
		setUser({
			...user,
			[event.target.name]: event.target.value
		});
		adminStore.updateUserValues(event.target.name, event.target.value);
	};

	const validateEmail = async () => {
		if(!isEdit){
			const isValidEmail = await adminStore.validateEmail({clientID: user.clientID, checkEmail: user.email});
			setUser({...user, isValidEmail: isValidEmail});
		}
	};

	const handleDropdownChange = (option) => {
		setUser(prevUser => ({
			...prevUser,
			role: option
		  }));
		adminStore.updateUserValues('role', option);
	};

	const submitNewPassword = async () => {
		const passwordError = validateNewPassword();
		if (Object.keys(passwordError).length > 0) {
            setErrors(passwordError);
        } else {
			setChangePassword(false);
			setLoading(true);
			const response = await adminStore.updateUserPassword(user.clientID, user.email, newPassword);
			setNewPassword('');
			setLoading(false);
			setErrors({});
			if(response.status === 200) {
				notify({
					duration: 1500,
					group: 'topRight',
					text: `Successfully Updated Password!`
				});
			}else {
				notify({
					type: 'error',
					duration: 1500,
					group: 'topRight',
					text: `Unable to update password, please try again later.`
				});
			}
		}
	}


	const handleSubmit = async (event) => {
		event.preventDefault();
		const localErrors = validate();
		if (Object.keys(localErrors).length > 0) {
            setErrors(localErrors);
        } else {
            setErrors({});
			setLoading(true);
			if(isEdit){
				const response = await adminStore.updateUser();
				if(response.status === 200) {
					notify({
						duration: 1500,
						group: 'topRight',
						text: `Successfully Updated User!`
					});
				}else {
					notify({
						type: 'error',
						duration: 1500,
						group: 'topRight',
						text: `Unable to update user, please try again later.`
					});
				}
				setUser({...adminStore.userObj, isValidEmail: true})
			}else {
				const response = await adminStore.createUser();
				if(response.status === 200) {
					notify({
						duration: 1500,
						group: 'topRight',
						text: `Successfully Created User!`
					});
					history.push("/admin/user/settings");
					return;
				}else {
					notify({
						type: 'error',
						duration: 1500,
						group: 'topRight',
						text: `Unable to create user, please try again later.`
					});
				}
			}
			setLoading(false);
        }
	};
	
	return (
		<Wrapper>
			{/* <div className="header-create">
				<Link to={`/admin/user/settings`} className="back-btn">
					<ArrowCircleLeftIcon style={{width: '2rem'}}/>
					<h4>Back</h4>
				</Link>
			</div> */}
			{loading ? 
				<LoadingSpinner size={'400px'} /> : 
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<form className="form-wrapper">
						<div className="header-create" style={{display: 'flex', justifyContent: 'space-between'}}>
							<Link to={`/admin/user/settings`} className="back-btn">
								<ArrowCircleLeftIcon style={{width: '2rem'}}/>
								<h4>Back</h4>
							</Link>
							<div className="action-section-top" style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
								<Button type="button" className="package-btn" onClick={handleOpenModal}>
									Packages
								</Button>
								
								<Button type="submit" className="add-user-btn" onClick={handleSubmit}>
									Submit
								</Button>
							</div>
						</div>
						<h3>Personal Info:</h3>
						<div style={{display: 'flex'}}>
							<Input
								onChange={handleChange}
								value={user.firstName}
								name="firstName"
								disabled={userRole === 'User'}
								border
								required
								error={errors.firstName && errors.firstName}
								label="First Name:"
								tabIndex={1}
								width={'50%'}
							/>
							<Input
								onChange={handleChange}
								value={user.lastName}
								disabled={userRole === 'User'}
								name="lastName"
								border
								required
								error={errors.lastName && errors.lastName}
								label="Last Name:"
								tabIndex={2}
								width={'50%'}
							/>
						</div>
						<div style={{display: 'flex'}}>
							<div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
								{!user.isValidEmail ? <span style={{color: 'red', textAlign: 'center'}}>Invalid Email: An account with this email already exists</span> : null }
								<Input
									type="email"
									onChange={handleChange}
									blur={validateEmail}
									value={user.email}
									disabled={userRole === 'User'}
									name="email"
									border
									required
									error={errors.email && errors.email}
									label="User's Email:"
									tabIndex={3}
									width={'100%'}
								/>
							</div>
							{!isEdit ? 
								<Input
									onChange={handleChange}
									value={user.password}
									name="password"
									border
									required
									error={errors.password && errors.password}
									label="Password:"
									type="password"
									tabIndex={4}
									width={'50%'}
								/>
								:
								<div style={{display: 'flex', justifyContent: 'end', width: '50%', alignItems: 'start', paddingBottom: '1rem'}}>
									{userRole !== 'User' ? <Button type="submit" className="package-btn" onClick={() => setChangePassword(true)}>
										Change Password
									</Button> : null }
								</div>
							}
							
						</div>
						<div style={{display: 'flex'}}>
							<Input
								onChange={handleChange}
								value={user.phone}
								disabled={userRole === 'User'}
								name="phone"
								border
								label="Cell Phone:"
								tabIndex={5}
								width={'50%'}
							/>
							<div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
								{errors.role ? <span style={{color: 'red', textAlign: 'center'}}>{errors.role}</span> : null }
								<Dropdown 
									label="User's Role:"
									options={userRoleOptions}
									disabled={userRole === 'User'}
									value={user.role}
									onChange={handleDropdownChange}
									border
									required
									tabIndex={6}
								/>
							</div>
						</div>
						<h3>Permissions:</h3>
						{user.permissions?.length ?
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ display: 'grid', gap: '10px' }}>
									{user.permissions.length ? user.permissions.map((permObj) => {
										if (permObj.parent.column !== 1) return null;
										
										return <CheckBoxGroup key={permObj.parent.name} parent={permObj.parent} group={permObj.group} />;
									}) : null}
								</div>
								<div style={{ display: 'grid', gap: '10px' }}>
									{user.permissions.length ? user.permissions.map((permObj) => {
										if (permObj.parent.column !== 2) return null;
										return <CheckBoxGroup key={permObj.parent.name} parent={permObj.parent} group={permObj.group} />;
									}) : null}
								</div>
								<div style={{ display: 'grid', gap: '10px' }}>
									{user.permissions.length ? user.permissions.map((permObj) => {
										if (permObj.parent.column !== 3) return null;
										return <CheckBoxGroup key={permObj.parent.name} parent={permObj.parent} group={permObj.group} />;
									}) : null}
								</div>
							</div>
						: null}
						<h3>Notes:</h3>
						<p>You may upgrade your package level to get additional features or you may select individual features and add/upgrade them a la carte.</p>
						<div className="action-section">
							
							<Button type="button" className="package-btn" onClick={handleOpenModal}>
								Packages
							</Button>
							
							<Button type="submit" className="add-user-btn" onClick={handleSubmit}>
								Submit
							</Button>
						</div>
					</form>
				</div>
			}
			<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
				<PricingPage/>
			</Modal>
			<Dialog isOpen={changePassword} onClose={() => setChangePassword(false)}>
				<p style={{fontWeight: 'bold'}}>Change Password</p>
				{errors && <span style={{color: 'red', textAlign: 'center'}}>{errors.newPassword}</span>}
				<div style={{display: 'flex', gap: '1rem', width: '100%'}}>
					<label style={{width: '30%'}}>New Password:</label>
					<input style={{width: '70%'}} error={errors.newPassword && errors.newPassword} type="password" value={newPassword} onChange={handleNewPassChange}/>
				</div>
				<div>
					<ul style={{paddingLeft: '20px', color: 'rgba(0, 0, 0, 0.5)'}}>
						<li>Must be at least 8 characters long</li>
						<li>{`Must include at least one uppercase letter (A-Z)`}</li>
						<li>{`Must include at least one lowercase letter (a-z)`}</li>
						<li>Must include at least one number</li>
						<li>{`Must include at least one non-alphanumberic character (!@#$%^&*()_+-={}[]|)`}</li>
					</ul>
				</div>
				<div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
					<Button type="submit" className="add-user-btn" onClick={submitNewPassword}>
						Submit
					</Button>
				</div>
			</Dialog>
			
		</Wrapper>
	)
});

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	padding-bottom: 3rem;
	color: ${({ theme }) => theme.colors.primaryText};
	.add-user-btn {
		background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
		color: white;
		width: 25%;
		padding: 0.5rem 0rem;
	}
	.package-btn {
		background-color: white;
		color: ${({ theme }) => theme.baseColors.blueFocusBorder};
		padding: 1rem 0rem;
		width: 25%
	}
	.header-create {
		padding-left: 1rem;
		.back-btn {
			color: white;
			display: flex;
			justify-content: start;
			align-items: center;
			gap: 1rem;
			border-radius: 10px;
			width: 25%;
		}
		.back-btn:hover {
			filter: brightness(85%);
		}
		.back-btn:active {
			transform: translateY(2px);
		}
	}
	h3 {
		color: ${({ theme }) => theme.colors.primaryText};
		border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
	}
	.form-wrapper {
		width: 100%
	}

	.field {
		display: flex;
		justify-content: start;
		padding-bottom: 1rem;
		.labelContainer {
			text-align: left;
			min-width: 7rem;
		}
		.inputContainer {
			width: 100%;
		}
		.styledWrapper {
			width: 100%;
		}
	}

	.action-section {
		display: flex;
		justify-content: space-between;
		padding-top: 1rem;
		.add-user-btn {
			background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			color: white;
			padding: 1rem 0rem;
			width: 25%
		}
		.cancel-btn {
			background-color: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%;
			text-align: center;
			border-radius: 5px;
		}
		.add-user-btn:hover {
			filter: brightness(85%);
		}
		.add-user-btn:active {
			transform: translateY(2px);
		}
		.package-btn {
			background-color: white;
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%
		}
		.package-btn:hover {
			filter: brightness(85%);
		}
		.package-btn:active {
			transform: translateY(2px);
		}
	}

	.action-section-top {
		display: flex;
		justify-content: space-between;
		padding-top: 1rem;
		width: 75%;
		.add-user-btn {
			background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			color: white;
			padding: 1rem 0rem;
			width: 12%
		}
		.cancel-btn {
			background-color: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%;
			text-align: center;
			border-radius: 5px;
		}
		.add-user-btn:hover {
			filter: brightness(85%);
		}
		.add-user-btn:active {
			transform: translateY(2px);
		}
		.package-btn {
			background-color: white;
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 12%
		}
		.package-btn:hover {
			filter: brightness(85%);
		}
		.package-btn:active {
			transform: translateY(2px);
		}
	}
`;

export default UserManager;