import { applySnapshot, getRoot, types, getSnapshot } from 'mobx-state-tree';
import { _get } from 'utils/utils';
import Moment from 'moment';

const DateFilterModel = types
  .model({})
  .volatile((self) => ({
    startDt: {},
    endDt: {},
    type: 'UTC',
    dtFmt: 'YYYY-MM-DD hh:mm:ss a',
    currentPeriod: 'months',
    periodToShow: 'Month',
    selectedInputShow: '',
    initialDate: false,
    initialState: null,
    selectedInput: '',
    granularity: 'days',
    dataType: window.sessionStorage.getItem('dataType') ?? 'current',
    dateType: 'txnDt'
  }))
  .views((self) => ({
    get rootStore() {
      return getRoot(self);
    },
    get userStore() {
      return self.rootStore.userStore;
    },
    get dataManagementStore() {
      return self.rootStore.dataManagementStore;
    },
    get user() {
      return self.userStore.user;
    },
    get userTz() {
      return _get(self, 'user.lastClientViewed.genTmz.tmzName', 'UTC');
    },
    get formatStartDt() {
      return self.startDt.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    },
    get formatEndDt() {
      return self.endDt.endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    },
  }))
  .actions((self) => {
    function afterCreate() {
      self.initialState = getSnapshot(self);
      if(self.dataType === 'archive') {
        // USE COMMENTS IF WE WANT TO DEFAULT TO DEC BEFORE ARCHIVE
        // self.startDt =  Moment().month() === 0 ? Moment().startOf('month').subtract(2, 'years').subtract(1, 'months') : Moment().startOf('month').subtract(2, 'years');
        self.startDt = Moment().month(11).startOf('month').subtract(2, 'years');
        self.endDt = Moment().month(11).endOf('month').subtract(2, 'years');
        // if (Moment().startOf('month')) {
        //   self.endDt = Moment().month(11).startOf('day').subtract(2, 'years');
        //   // self.endDt = Moment().month() === 0 ? Moment().subtract(3, 'years').month(11).endOf('month') : Moment().startOf('day').subtract(2, 'years');
        // } else {
        //   self.endDt = Moment().month(11).endOf('month').subtract(2, 'years');
        //   // self.endDt = Moment().month() === 0 ? Moment().subtract(3, 'years').month(11).endOf('month') : Moment().subtract(1, 'days').endOf('day').subtract(2, 'years');
        // }
      }else {
        self.startDt = Moment().startOf('month');
        if (Moment().startOf('month')) {
          self.endDt = Moment().startOf('day');
        } else {
          self.endDt = Moment().subtract(1, 'days').endOf('day');
        }
      }
    }
    function resetState() {
      setInitialDate();
      applySnapshot(self, self.initialState);
    }
    function setTxnDtType(dateType) {
      self.dateType = dateType;
    }
    function setGranularity(granularity) {
      self.granularity = granularity;
    }
    function showPeriod(period) {
      self.periodToShow = period;
    }
    function setInitialDate() {
      setThisMonth();
      self.initialDate = true;
    }
    function setGranularityAfterSelect() {
      // let startDt = Moment(store.newQuery.params.filter.startDt);
      let startDt = Moment(self.startDt);
      // let endDt = Moment(store.newQuery.params.filter.endDt);
      let endDt = Moment(self.endDt);
      let diffDays = endDt.diff(startDt, 'days');
      let diffWeeks = endDt.diff(startDt, 'weeks');
      //exactly six months does not give weeks back
      // if (diffDays <= 2) {
      //   if (self.granularity !== 'hours') setGranularity('hours');
      //   return null;
      // }
      if (diffDays >= 0 && diffWeeks <= 4) {
        if (self.granularity !== 'days') setGranularity('days');
        return null;
      }
      if (diffWeeks > 4 && diffWeeks <= 53) {
        if (self.granularity !== 'week') setGranularity('week');
        return null;
      }
      if (diffWeeks > 53 && diffWeeks < 104) {
        if (self.granularity !== 'month') setGranularity('month');
        return null;
      }
      // if (diffWeeks >= 104 && diffWeeks <= 120) {
      if (diffWeeks >= 104) {
        if (self.granularity !== 'month') setGranularity('month');
        return null;
      }
      // if (diffWeeks > 104) {
      //   if (self.granularity !== 'year') setGranularity('year');
      //   return null;
      // }
    }

    function setStartDt(newDate) {
      let newDateTimeStamp = Moment(newDate).startOf('day').toDate().getTime();

      if (!self.endDt) self.startDt = Moment(newDate).startOf('day');
      else {
        /** compare the start and end dates. if the endDate is before the startDate, then set the newDate to the start of the endDate's day **/
        let endDateTimeStamp = self.endDt.toDate().getTime();
        let test = newDateTimeStamp > endDateTimeStamp;
        if (test) {
          self.startDt = Moment(newDate).startOf('day');
          self.endDt = Moment(newDate).endOf('day');
        } else self.startDt = Moment(newDate).startOf('day');
        // if (test) self.startDt = Moment(self.endDt, self.userTz).startOf('day');
        // else self.startDt = Moment(newDate, self.userTz).startOf('day');
      }
    }
    function setEndDt(newDate) {
      let newDateTimeStamp = Moment(newDate).endOf('day').toDate().getTime();

      if (!self.startDt) self.endDt = Moment(newDate).endOf('day');
      else {
        /** compare the start and end dates. if the startDt is after the endDate, then set the newDate to the start of the endDate's day **/
        let startDtTimeStamp = self.startDt.toDate().getTime();
        let test = startDtTimeStamp > newDateTimeStamp;
        if (test) {
          self.startDt = Moment(newDate).startOf('day');
          self.endDt = Moment(newDate).endOf('day');
        } else self.endDt = Moment(newDate).endOf('day');
        // if (test) self.endDt = Moment(self.startDt, self.userTz).endOf('day');
        // else self.endDt = Moment(newDate, self.userTz).endOf('day');
      }
    }
    function applyNewDateRange() {
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function applyNewDataType() {
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    /**Set Date Periods**/
    function setDateForwardOnePeriod() {
      setSelectedInput('');
      let sdt = self.startDt.clone();
      let edt = self.endDt.clone();
      if (self.currentPeriod === 'custom') {
        const daysInPeriod = Moment(edt).diff(self.startDt, 'days') + 1; //include startDt
        self.startDt = Moment(self.startDt).clone().startOf('day').add(daysInPeriod, 'days');

        self.endDt = Moment(self.endDt).clone().endOf('day').add(daysInPeriod, 'days');
      } else {
        self.startDt = Moment(sdt).startOf(self.currentPeriod).add(1, self.currentPeriod);
        self.endDt = Moment(self.startDt).clone().endOf(self.currentPeriod);
      }
      // applyNewDateRange();
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setDateBackOnePeriod() {
      setSelectedInput('');
      let sdt = self.startDt.clone();
      let edt = self.endDt.clone();
      if (self.currentPeriod === 'custom') {
        const daysInPeriod = Moment(self.endDt).diff(self.startDt, 'days') + 1; //include startDt
        self.startDt = Moment(sdt).subtract(daysInPeriod, 'days');
        self.endDt = Moment(edt).subtract(daysInPeriod, 'days');
      } else {
        self.startDt = Moment(sdt).subtract(1, self.currentPeriod).startOf(self.currentPeriod);
        self.endDt = Moment(self.startDt).clone().endOf(self.currentPeriod);
      }
      // applyNewDateRange();
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setCurrentPeriod(period) {
      self.currentPeriod = period;
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setPeriodDay() {
      self.currentPeriod = 'days';
      self.startDt = Moment(self.startDt).clone().startOf('day');
      self.endDt = Moment(self.startDt).clone().endOf('day');
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setPeriodWeek() {
      self.currentPeriod = 'weeks';
      self.startDt = Moment(self.startDt).clone().startOf('week');
      self.endDt = Moment(self.startDt).clone().endOf('week');
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setPeriodMonth() {
      self.currentPeriod = 'months';
      self.startDt = Moment(self.startDt).startOf('month');
      self.endDt = Moment(self.startDt).endOf('month');
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setPeriodYear() {
      self.currentPeriod = 'years';
      self.startDt = Moment(self.startDt).startOf('year');
      self.endDt = Moment(self.startDt).endOf('year');
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    function setPeriodCustom() {
      self.currentPeriod = 'custom';
      self.startDt = Moment(self.startDt).startOf('day');
      self.endDt = Moment(self.endDt).endOf('day');
      // self.dataManagementStore.setNewQuery();
      // setGranularityAfterSelect();
    }
    /** Pre Selected Dates**/
    function setMonthToDate() {
      self.startDt = Moment().startOf('month');
      if (Moment().startOf('month')) {
        self.endDt = Moment().startOf('day');
      } else {
        self.endDt = Moment().subtract(1, 'days').endOf('day');
      }
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setYesterday() {
      self.startDt = Moment().subtract(1, 'days').startOf('day');
      self.endDt = Moment().subtract(1, 'days').endOf('day');
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setThisMonth() {
      self.startDt = Moment().startOf('month');
      self.endDt = Moment().endOf('month');
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setYearToDate() {
      self.startDt = Moment().startOf('year');
      self.endDt = Moment().endOf('day');
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setLastWeek() {
      self.startDt = Moment().subtract(1, 'weeks').startOf('week');
      self.endDt = Moment().subtract(1, 'weeks').endOf('week');
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setLastMonth() {
      self.startDt = Moment().subtract(1, 'months').startOf('month');
      self.endDt = Moment().subtract(1, 'months').endOf('month');
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setLastYear() {
      self.startDt = Moment().subtract(1, 'years').startOf('year');
      self.endDt = Moment().subtract(1, 'years').endOf('year');
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setPreviousYearX(yearToSet) {
      self.startDt = Moment(`${yearToSet}-01-01`);
      self.endDt = Moment(`${yearToSet}-12-31`);
      self.dataManagementStore.setNewQuery();
      setGranularityAfterSelect();
    }
    function setSelectedInput(passed) {
      self.selectedInput = passed;
      // setGranularityAfterSelect();
    }
    function setDataType(dataType) {
      self.dataType = dataType;
    }
    return {
      afterCreate,
      resetState,
      setGranularity,
      showPeriod,
      setInitialDate,
      applyNewDateRange,
      applyNewDataType,
      setStartDt,
      setEndDt,
      setTxnDtType,
      setDateForwardOnePeriod,
      setDateBackOnePeriod,
      setCurrentPeriod,
      setMonthToDate,
      setPeriodDay,
      setPeriodWeek,
      setPeriodMonth,
      setPeriodYear,
      setPeriodCustom,
      setYesterday,
      setThisMonth,
      setYearToDate,
      setLastWeek,
      setLastMonth,
      setLastYear,
      setSelectedInput,
      setDataType,
      setPreviousYearX
    };
  });

export default DateFilterModel;
