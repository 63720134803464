import styled from 'styled-components/macro';
import { User } from 'styled-icons/fa-solid/User';
import { Email } from 'styled-icons/material/Email';

const LoginPageWrapper = styled.div`
  position: fixed;
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: ${({ theme }) => {
    return theme.background;
  }};
`;

/*const Logo = styled.div`
  height: ${({ theme }) => theme.loginLogo.height};
  width: ${({ theme }) => theme.loginLogo.width};
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ theme }) => theme.loginLogo.logoPath});
`;*/

const UserIcon = styled(User)``;
const EmailIcon = styled(Email)``;
export { UserIcon, EmailIcon, LoginPageWrapper };
