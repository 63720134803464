import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Dropdown } from 'components/UI';
import { CreateEditClientWrapper, DeleteButton, SubmitButton } from '../styles';
import { ClientInfoForm } from './Partials/ClientInfoForm';
import { Mid } from './Partials/Mid.jsx';
import { applySnapshot } from 'mobx-state-tree';
import { removeEmptyValues } from 'utils/utils';
import { adminService } from 'services/service';
import { Grid, GridTemplate } from 'styles/styledComponents';
import { editClientStore } from './stores/EditClientStore';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

const editClientModel = editClientStore.create();

export const EditClientForm = observer((props) => {
  const history = useHistory();

  const labelWidth = '210px';

  const localStore = useLocalStore(() => ({
    loading: false,
    selectedMid: null,
    midOptions: [],
    mids: [],
    async setMid(mid) {
      try {
        const integrationsRes = await adminService.getIntegrations({ midNum: mid });
        const integrations = integrationsRes.data;
        const procLimitsRes = await adminService.getProcLimits({ midNum: mid });
        const procLimits = procLimitsRes.data;
        // localStore.mid = localStore.midOptions[0].value;
        // const integrations = await adminService.getIntegrations({ midNum: localStore.mid });
        editClientModel.setMids(localStore.mid, integrations, procLimits);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getClient(id) {
      if (!localStore.loading) {
        try {
          localStore.loading = true;
          const res = await adminService.getClient({ clientID: id });
          let client = res.data;
          client = removeEmptyValues(client);
          const clientMidsRes = await adminService.getClientMids({ clientID: id });
          let clientMids = clientMidsRes.data;
          client.mids = clientMids.map((mid) => {
            return removeEmptyValues(mid);
          });
          // const mids = client.mids;

          applySnapshot(editClientModel, {
            clientInfo: {
              addr1: client.addr1,
              addr2: client.addr2,
              addr3: client.addr3,
              city: client.city,
              clientActive: client.clientActive,
              clientContactEmail: client.clientContactEmail,
              clientContactPhone: client.clientContactPhone,
              clientName: client.clientName,
              clientContactFname: client.clientContactFname,
              clientContactLname: client.clientContactLname,
              country: client.country,
              fein: client.fein,
              functCurrID: client.functCurrID,
              id: client.id,
              mccSic: client.mccSic,
              selectedClientID: client.selectedClientID,
              selectedClientMid: client.selectedClientMid,
              stProv: client.stProv,
              // tmzID: client.tmzID,
              zip: client.zip,
            },
            pricingPlan: client.pricingPlan,
          });
          editClientModel.saveMidState();
          localStore.mids = client.mids;
          localStore.midOptions = client.mids.map((mid) => {
            return {
              text: mid.midNum,
              value: mid.midNum,
              key: mid.midNum,
            };
          });
        } catch (e) {
          console.log('e', e);
          history.push('/login');
        }
        localStore.loading = false;
      }
    },
  }));
  useEffect(() => {
    return editClientModel.resetForm();
  }, []);
  return (
    <Observer>
      {() => (
        <CreateEditClientWrapper>
          <StyledNote alignItems="flex-end">
            <span>
              <span className="note">* </span>
              <span className="noteBody">Data is real time; based on industry's ability to process and provide info</span>
            </span>
          </StyledNote>
          <div style={{ width: '100%', margin: '0 auto', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <br />
            <div style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
              <GridTemplate
                width={'100%'}
                maxWidth={'1040px'}
                justifyContent={'center'}
                columns={'1fr minmax(auto, 520px) 1fr'}
                rows={'1fr'}
              >
                <div />
                <Dropdown
                  search
                  placeholder={'Select Client'}
                  options={props.store.allClientOptions}
                  value={editClientModel.clientInfo.id}
                  loading={props.store.allClientOptions.length === 0}
                  onChange={localStore.getClient}
                  label="Select Client"
                  labelWidth={labelWidth}
                />
                <div />
              </GridTemplate>
            </div>
            <br />
            <React.Fragment>
              {editClientModel.clientInfo.id && (
                <React.Fragment>
                  <div className="header-wrapper">
                    <div className="form-header">Client Info</div>
                  </div>
                  <div style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
                    <Grid width={'1040px'} cmin={'350px'} cmax={'520px'}>
                      <ClientInfoForm props={props} store={props.store} clientModel={editClientModel} />
                    </Grid>
                  </div>
                  <div className="header-wrapper" style={{ alignItems: 'center', flexDirection: 'column' }}>
                    <div className="form-header">MIDs</div>
                    <SubmitButton width="100px" className="submit" onClick={() => editClientModel.addMid()}>
                      Add MID
                    </SubmitButton>
                    &nbsp;
                  </div>
                  <div style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
                    <GridTemplate
                      width={'100%'}
                      maxWidth={'1040px'}
                      justifyContent={'center'}
                      columns={'1fr minmax(auto, 520px) 1fr'}
                      rows={'1fr'}
                      media={'@media'}
                    >
                      <div />
                      <Dropdown
                        search
                        placeholder={'Select MID'}
                        options={localStore.midOptions}
                        value={localStore.mid}
                        onChange={(mid) => {
                          editClientModel.resetMidState();
                          localStore.mid = mid;
                          localStore.setMid(mid);
                        }}
                        label="Select MID"
                        labelWidth={labelWidth}
                      />
                    </GridTemplate>
                  </div>
                  {editClientModel.mids.map((mid, index) => (
                    <div key={index}>
                      {localStore.mid && localStore.mid === mid.initialState.mid && (
                        <Mid clientModel={editClientModel} store={props.store} mid={mid} />
                      )}
                    </div>
                  ))}
                  {editClientModel.newMids.map((mid, index) => (
                    <div key={index}>
                      <Mid clientModel={editClientModel} mid={mid} store={props.store} editMid />
                    </div>
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
            {editClientModel.clientInfo.id && (
              <>
                <div className="header-wrapper">
                  <SubmitButton width="100px" className="submit" onClick={() => editClientModel.submitEditClient(props.notify)}>
                    Submit Form
                  </SubmitButton>
                  <DeleteButton width="100px" margin="0 0 0 5px" onClick={editClientModel.resetForm}>
                    Clear Form
                  </DeleteButton>
                </div>
                <div className="header-wrapper">
                  <div className="form-header">&nbsp;</div>
                </div>
              </>
            )}
          </div>
        </CreateEditClientWrapper>
      )}
    </Observer>
  );
});

const StyledNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1rem;
  height: 20px;
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding-right: 10px;
  font-style: italic;
  .note {
    font-size: 1rem;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;