import { types, getRoot, flow } from 'mobx-state-tree';
import { _get } from 'utils/utils';
import {commonService, widgetService} from 'services';
const { maybe, number, string, optional, frozen } = types;
const defaultProps = {
  count: 0,
  totalAmt: 0,
  chgbkFees: 0,
  gwFees: 0,
  moStmtFees: 0,
  brandFees: 0,
  intchgFees: 0,
  reserves: 0,
};

const defaultFees = {
  totalChgbkFees: 0,
  totalFees: 0,
  totalGwFees: 0,
  totalMoStmtFees: 0,
  totalBrandFees: 0,
  totalIntchgFees: 0,
  totalReserves: 0,
};
const widgetDataState = {
  chargeback: defaultProps,
  chargebackReversal: defaultProps,
  declined: defaultProps,
  error: defaultProps,
  expiredRefunds: defaultProps,
  expiredSales: defaultProps,
  fees: defaultFees,
  pendingOrUnprocessedTxns: defaultProps,
  sale: defaultProps,
  refund: defaultProps,
  pendingRefunds: defaultProps,
  reversals: defaultProps,
  voided: defaultProps,
  voidedRefunds: defaultProps,
};

const SsTxnsModel = types
  .model({
    widgetData: frozen(widgetDataState),
    widgetID: maybe(number),
    widgetName: maybe(string),
    state: optional(types.enumeration('State', ['pending', 'done', 'error']), 'done'),
  })
  .views((self) => ({
    get newQuery() {
      return getRoot(self).dataManagementStore.newQuery;
    },
  }))
  .actions((self) => ({
    fetchWidgetData: flow(function* fetchWidgetData({ name, id }) {
      self.state = 'pending';
      try {
        const post = self.newQuery;
        post.params.filter['widgetID'] = id;
        const widgetData = yield widgetService.sales2(post);
        self.widgetData = widgetData.data.data.widgets[name].data;
        self.state = 'done';
      } catch (error) {
        console.error('Failed to fetch widget data', error);
        self.errorMessage = _get(error, 'response.data.message');
        self.errors = error.response.data.errors;
        console.error('self.errors', self.errors[0]);
        console.error('self.errorMessage', self.errorMessage);
        self.state = 'error';
      }
    }),
  }));

export default SsTxnsModel;
