import { types } from 'mobx-state-tree';
const { optional, string } = types;

export const ApiCreds = types
  .model('authnetApi', {
    ApiId: optional(string, ''),
    ApiKey: optional(string, ''),
  })
  .actions((self) => {
    function setApiKey(e) {
      self.ApiKey = e.target.value;
    }
    function setApiId(e) {
      self.ApiId = e.target.value;
    }
    return {
      setApiId,
      setApiKey,
    };
  });

export const ScrapeCreds = types
  .model('authnetScrape', {
    Username: optional(string, ''),
    Password: optional(string, ''),
  })
  .actions((self) => {
    function setUsername(e) {
      self.Username = e.target.value;
    }
    function setPassword(e) {
      self.Password = e.target.value;
    }
    return {
      setUsername,
      setPassword,
    };
  });

//aggrID: 5 dsID: 2
export const AuthnetApi = types
  .model('authnetApi', {
    ApiId: optional(string, ''),
    ApiKey: optional(string, ''),
  })
  .actions((self) => {
    function setApiKey(e) {
      self.ApiKey = e.target.value;
    }
    function setApiId(e) {
      self.ApiId = e.target.value;
    }
    return {
      setApiId,
      setApiKey,
    };
  });

//aggrID: 5 dsID: 1
export const AuthnetScrape = types
  .model('authnetScrape', {
    Username: optional(string, ''),
    Password: optional(string, ''),
  })
  .actions((self) => {
    function setUsername(e) {
      self.Username = e.target.value;
    }
    function setPassword(e) {
      self.Password = e.target.value;
    }
    return {
      setUsername,
      setPassword,
    };
  });

//aggrID: 86 dsID: 1
export const CybersourceScrape = types
  .model('cyberSourceScrape', {
    OrgId: optional(string, ''),
    Username: optional(string, ''),
    Password: optional(string, ''),
  })
  .actions((self) => {
    function setOrgId(e) {
      self.OrgId = e.target.value;
    }
    function setUsername(e) {
      self.Username = e.target.value;
    }
    function setPassword(e) {
      self.Password = e.target.value;
    }
    return {
      setOrgId,
      setUsername,
      setPassword,
    };
  });

//AggrID: 152,
/*export const DataCapCredsModel = types
  .model('dataCapCreds', {
    transactionKey: optional(string, ''),
  })
  .actions((self) => {
    function setTransactionKey(e) {
      self.transactionKey = e.target.value;
    }
    return {
      setTransactionKey,
    };
  });*/

export const EverestScrape = types
  .model('everestScrape', {
    Username: optional(string, ''),
    Password: optional(string, ''),
  })
  .actions((self) => {
    function setUsername(e) {
      self.Username = e.target.value;
    }
    function setPassword(e) {
      self.Password = e.target.value;
    }
    return {
      setUsername,
      setPassword,
    };
  });

//
export const NMIScrape = types
  .model('nmiScrape', {
    Username: optional(string, ''),
    Password: optional(string, ''),
  })
  .actions((self) => {
    function setUsername(e) {
      self.Username = e.target.value;
    }
    function setPassword(e) {
      self.Password = e.target.value;
    }
    return { setUsername, setPassword };
  });

export const USAepayScrape = types
  .model('usaEpayScrape', {
    Username: optional(string, ''),
    Password: optional(string, ''),
  })
  .actions((self) => {
    function setUsername(e) {
      self.Username = e.target.value;
    }
    function setPassword(e) {
      self.Password = e.target.value;
    }
    return { setUsername, setPassword };
  });

export const USAepayApi = types
  .model('usaEpayApi', {
    ApiKey: optional(string, ''),
    ApiPin: optional(string, ''),
  })
  .actions((self) => {
    function setApiKey(e) {
      self.ApiKey = e.target.value;
    }
    function setApiPin(e) {
      self.ApiPin = e.target.value;
    }
    return { setApiKey, setApiPin };
  });

export const GoldstarApi = types.model('goldstarApi', {});
