import React, { Fragment } from 'react';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { ChargebackFilter, CommonFilter, DeclineFilter, TransactionFilter } from '../index';
import { Button, PopupMenu } from 'components/UI';
import { FilterIcon } from 'styles/styledComponents';
import { useLocation } from 'react-router-dom';

const FilterBtnPopup = observer((props) => {
  const location = useLocation();
  const localStore = useLocalStore(() => ({
    between: false,
    amtField: '',
    selectOperator: (operator) => {
      if (operator === '>=<=') {
        localStore.between = true;
      }
    },
    selectGreater: (name, val) => {
      props.filterStore.commonFilter.setAmt(name, val);
    },
  }));
  return (
    <Observer>
      {() => (
        <Fragment>
          <Button
            // type="secondary"
            type="primary"
            width="35px"
            height="36px"
            margin="1px 0 0 5px"
            id="filterBtn"
            onClick={props.store.toggleShowFilter}
          >
            <FilterIcon id="filterBtn" color="#fff" style={{ width: 30, height: 33 }} />
          </Button>
          {props.store.showFilterPopup && (
            <Observer>
              {() => (
                <PopupMenu
                  show={props.store.showFilterPopup}
                  hidePopup={props.store.hideFilterPopup}
                  top={'49px'}
                  left={200}
                  marginTop={30}
                  padding="0 0 0 0"
                  // onKeyDown={handleKeyDown}
                  cleanup={props.cleanup}
                >
                  <div className="arrowUpFilter" />
                  <div className={'filterContainer'}>
                    <div className="filterWrapper">
                      <Observer>{() => <CommonFilter commonFilterProps={props.store.commonFilterProps} />}</Observer>
                     {/* {location.pathname.includes('/processing/transactions') && (
                        <Observer>
                          {() => <TransactionFilter transactionFilterProps={props.store.transactionFilterProps} />}
                        </Observer>
                      )}*/}
                     {/* {location.pathname.includes('/processing/declines') && (
                        <DeclineFilter declineFilterProps={props.store.declineFilterProps} />
                      )}*/}
                     {/* {location.pathname.includes('/processing/chargebacks') && (
                        <ChargebackFilter chargebackFilterProps={props.store.chargebackFilterProps} />
                      )}*/}
                    </div>
                    {/*  <AmtFilterSection>
                  <Dropdown
                    border
                    options={[
                      { value: 'amt', text: 'Submit Amt' },
                      { value: 'amtS', text: 'Submit Amt' }
                    ]}
                    placeholder="Select Amt Field"
                  />
                  <Dropdown
                    border
                    onChange={val => localStore.selectOperator(val)}
                    options={[
                      { value: 'lte', text: '<=' },
                      { value: 'gte', text: '>=' },
                      { value: '>=<=', text: '>= <=' }
                    ]}
                    placeholder="Operator"
                  />
                  <Input border onchange={() => {}} placeholder="amt" />
                  {localStore.between && (
                    <div>
                      AND <Input onChange={() => {}} placeholder="amt" />
                    </div>
                  )}
                </AmtFilterSection>*/}
                    <div className={'submitContainer'}>
                      <Button
                        type="primary"
                        primary
                        margin="3px"
                        padding="3px"
                        onClick={() => {
                          props.store.applyFilter();
                          props.store.toggleShowFilter();
                          props.store.fetchFilteredData();
                          props.store.filterStore.mapFilterDropdownsToModels();
                          // props.filterStore.transactionFilter.selectTxnTypeFromModel();
                          // props.filterStore.transactionFilter.checkTxnStatusIDLOptFromModel();
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        type="clear"
                        margin="3px"
                        padding="3px"
                        onClick={() => {
                          props.store.filterStore.resetState();
                          props.store.toggleShowFilter();
                          props.store.fetchFilteredData();
                          props.store.filterStore.mapFilterDropdownsToModels();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </PopupMenu>
              )}
            </Observer>
          )}
        </Fragment>
      )}
    </Observer>
  );
});

export default FilterBtnPopup;
