import React, { useEffect } from 'react';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { TableWrapper, TableHeader, TableHeaderButton, Table, TableBody, TableCell, TableRow } from 'styles/styledComponents';
import { AgGridReact } from 'ag-grid-react';
import { Dropdown, LoadingSpinner, Error } from 'components/UI';
import { _get, _toNumber } from 'utils/utils';

const DepositsWidget = observer((props) => {
  const store = useLocalObservable(() => ({
    fileName: 'depositsJournalAccrual',
    loading: false,
    widgetData: null,
    exportType: 'excel',
    crTotals: 0,
    drTotals: 0,
    get glJournalAccrual() {
      if (this.widgetData) {
        let accrual = this.widgetData.deposits;
        const sortedAccrual = [];

        accrual.map(({ gl_acct_desc, amt_by_gl_acct }, index) => {
          // get the totals
          amt_by_gl_acct > 0 ? (store.drTotals += amt_by_gl_acct) : (store.crTotals += amt_by_gl_acct);

          amt_by_gl_acct > 0
            ? sortedAccrual.push({
                gl_acct_desc,
                dr: amt_by_gl_acct,
                cr: 0,
              })
            : sortedAccrual.push({
                gl_acct_desc,
                dr: 0,
                cr: amt_by_gl_acct,
              });
          if (index === accrual.length - 1) {
            sortedAccrual.push({
              gl_acct_desc: 'TOTAL',
              dr: store.drTotals,
              cr: store.crTotals,
            });
          }
        });
        store.drTotals = 0;
        store.crTotals = 0;
        return sortedAccrual;
      }
      return null;
    },
    api: null,
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,

        rowSelection: 'multiple',

        groupMultiAutoColumn: true,
        enableBrowserTooltips: true,
        tooltipShowDelay: 0,
        reactNext: true,
        rowHeight: 33,
        headerHeight: 33,
        defaultColDef: {
          sortable: false,
          resizable: true,
        },
        columnDefs: [
          {
            headerName: 'GL ACCT: DESCRIPTION',
            field: 'gl_acct_desc',
            flex: 1,
          },
          {
            headerName: 'DR',
            field: 'dr',
            valueFormatter: props.store.toCurrencyFormatter,
            cellStyle: { display: 'flex', 'justify-content': 'end' },
            width: 130,
            aggFunc: 'sum',
          },
          {
            headerName: 'CR',
            field: 'cr',
            valueFormatter: props.store.toCurrencyFormatter,
            cellStyle: { display: 'flex', 'justify-content': 'end' },
            width: 135,
            aggFunc: 'sum',
          },
          // {
          //   headerName: 'Txn Amount',
          //   field: 'txn_amt',
          //   cellStyle: cellStyle,
          //   valueFormatter: toCurrencyFormatter,
          //   aggFunc: 'sum',
          //   chartDataType: 'series',
          //   width: 140,
          // },
        ],
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);"> Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
    setExportType: (type) => {
      store.exportType = type;
    },
    onGridReady: (params) => {
      store.api = params.api;
    },
    setStatus(status) {
      this.status = status;
    },
    setData(data) {
      this.widgetData = data;
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex === params.api.getDisplayedRowCount() - 1) {
      return { borderTop: '1px solid rgba(255, 255, 255, 0.5)', backgroundColor: 'rgb(4, 28, 47)', fontWeight: 'bold' };
    }
    return null;
  };

  useEffect(() => {
    if (props.data) {
      store.setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    store.setStatus(props.status);
  }, [props.status]);

  return (
    <Observer>
      {() => (
        <TableWrapper height="100%">
          <TableHeaderWrapper padding="6px">
            <TableHeader height="auto" padding="0" margin="auto">
              Deposits Journal - Accrual
            </TableHeader>
            <Dropdown
              className="exportDropdown"
              caretRight={10}
              options={props.store.exportOptions}
              onChange={store.setExportType}
              value={store.exportType}
              width="80px"
              height="25px"
              margin="0"
              border
            />
            <TableHeaderButton
              height="27px"
              onClick={() => {
                if (store.exportType === 'csv') {
                  store.api.exportDataAsCsv({ fileName: store.fileName });
                } else {
                  store.api.exportDataAsExcel({ fileName: store.fileName });
                }
              }}
            >
              Export
            </TableHeaderButton>
          </TableHeaderWrapper>
          <Wrapper>
            <>
              {store.status === 'loading' ? (
                <LoadingSpinner size={'200px'} />
              ) : store.status === 'error' ? (
                <Error error={props.error} />
              ) : (
                <div
                  id="myGrid"
                  className={'ag-theme-alpine-dark'}
                  style={{
                    height: '100%',
                    minHeight: '275px',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <AgGridReact
                    rowData={store.glJournalAccrual}
                    gridOptions={store.gridOptions}
                    onGridReady={store.onGridReady}
                    getRowStyle={getRowStyle}
                  />
                </div>
              )}
            </>
          </Wrapper>
          {/*  <Table>
            <TableBody>
            <TableRow>
                <TableCell>{}</TableCell>
                <TableCell>{store.drTotals}</TableCell>
                <TableCell>{store.crTotals}</TableCell>
            </TableRow>
            </TableBody>
        </Table>*/}
        </TableWrapper>
      )}
    </Observer>
  );
});

const TableHeaderWrapper = styled.div`
  padding: ${({ padding }) => padding || ''};
  display: flex;
`;

const Wrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: transparent;
    --ag-odd-row-background-color: transparent;
    --ag-border-color: rgba(255, 255, 255, 0.5);
    --ag-header-background-color: #041c2f;

    .ag-header-row {
      height: 30px;
    }

    .ag-cell {
      line-height: 30px;
      font-size: 12px;
    }

    .ag-header-cell-label {
      display: flex;
      justify-content: center;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }
  }
`;

export default DepositsWidget;
