import styled from 'styled-components/macro';

const Grid = styled.div`
  display: grid;
  width: ${({ width }) => width};
  grid-gap: ${({ gap }) => gap};
  margin: ${({ margin }) => margin};
  justify-content: ${({ justify }) => justify || 'center'};
  grid-template-columns: ${({ cmin, cmax }) => `repeat(auto-fill, minmax(${cmin}, ${cmax}))`};
`;

const GridTemplate = styled.div`
  display: grid;
  grid-gap: ${({ gap }) => gap};
  grid-template-rows: ${({ rows }) => rows || ''};
  grid-template-columns: ${({ columns }) => columns || ''};
  justify-content: ${({ justify }) => justify || 'center'};

  height: ${({ height }) => height};
  width: ${({ width }) => width};
  max-height: ${({ maxWidth }) => maxWidth};
  max-width: ${({ maxWidth }) => maxWidth};

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

export { Grid, GridTemplate };
