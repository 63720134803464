/*
 * Find some icons here:
 * https://styled-icons.js.org/?s=
 */
import styled from 'styled-components/macro';
import { Add } from 'styled-icons/material/Add';
import { AddCircleOutline } from 'styled-icons/material/AddCircleOutline';
import { AngleUp } from 'styled-icons/fa-solid/AngleUp';
import { AngleDown } from 'styled-icons/fa-solid/AngleDown';
import { Bars } from 'styled-icons/fa-solid/Bars';
import { Bookmark3 } from 'styled-icons/remix-line/Bookmark3';
import { Calendar } from 'styled-icons/octicons/Calendar';
import { Cancel } from 'styled-icons/typicons/Cancel';
import { CaretDown } from 'styled-icons/boxicons-regular/CaretDown';
import { CaretUp } from 'styled-icons/boxicons-regular/CaretUp';
// import { Circle } from 'styled-icons/boxicons-regular/Circle';
import { Check } from 'styled-icons/boxicons-regular/Check';
import { Circle } from 'styled-icons/feather/Circle';
import { ChevronLeft } from 'styled-icons/boxicons-regular/ChevronLeft';
import { ChevronRight } from 'styled-icons/boxicons-regular/ChevronRight';
import { ChevronsLeft } from 'styled-icons/boxicons-regular/ChevronsLeft';
import { ChevronsRight } from 'styled-icons/boxicons-regular/ChevronsRight';
import { Clear } from 'styled-icons/material/Clear';
import { Close } from 'styled-icons/evil/Close';
import { CreditCard } from 'styled-icons/fa-regular/CreditCard';
import { Delete } from 'styled-icons/typicons/Delete';
// import { DeleteOutline } from 'styled-icons/typicons/DeleteOutline';
import { DragHandle } from 'styled-icons/material/DragHandle';
import { Edit } from 'styled-icons/feather/Edit';
import { Filter } from 'styled-icons/feather/Filter';
import { ArrowCircleLeft } from 'styled-icons/fa-solid/ArrowCircleLeft';
import { LockAlt } from 'styled-icons/boxicons-solid/LockAlt';
import { MinusCircle } from 'styled-icons/boxicons-solid/MinusCircle';
import { Minus } from 'styled-icons/boxicons-regular/Minus';
import { Moon } from 'styled-icons/fa-solid/Moon';
// import { Save } from 'styled-icons/feather/Save';
import { Save } from 'styled-icons/boxicons-regular/Save';
import { ShoppingCart } from 'styled-icons/fa-solid/ShoppingCart';
import { SortAlphaDown } from 'styled-icons/fa-solid/SortAlphaDown';
import { SortAmountDown } from 'styled-icons/fa-solid/SortAmountDown';
import { SortAmountUp } from 'styled-icons/fa-solid/SortAmountUp';
import { Sun } from 'styled-icons/fa-solid/Sun';
import { Warning } from 'styled-icons/material/Warning';
import { University } from 'styled-icons/fa-solid/University';
import { User } from 'styled-icons/boxicons-solid/User';
import { LinkExternal } from 'styled-icons/boxicons-regular/LinkExternal';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

const AddIcon = styled(Add)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const AngleDownIcon = styled(AngleDown)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const AngleUpIcon = styled(AngleUp)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ArrowCircleLeftIcon = styled(ArrowCircleLeft)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const BookmarkIcon = styled(Bookmark3)`
  color: ${({ color }) => color || '#fff'};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CalendarIcon = styled(Calendar)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CancelIcon = styled(Cancel)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CaretDownIcon = styled(CaretDown)`
  color: ${(props) => (props.color ? props.color : props.theme.sidebar.colors.linkIconText)};
  width: ${({ width }) => width || '20px'};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CaretUpIcon = styled(CaretUp)`
  color: ${(props) => (props.color ? props.color : props.theme.sidebar.colors.linkIconText)};
  width: ${({ width }) => width || '20px'};
  stroke: ${(props) => (props.stroke ? props.stroke : props.theme.sidebar.colors.linkIconText)};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CircleIcon = styled(Circle)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CheckIcon = styled(Check)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ChevronLeftIcon = styled(ChevronLeft)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ChevronsLeftIcon = styled(ChevronsLeft)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ChevronRightIcon = styled(ChevronRight)`
  color: ${({ color }) => color};
  height: ${({ height }) => height};
  transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all 0.25s;
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ChevronsRightIcon = styled(ChevronsRight)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CloseIcon = styled(Close)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ClearIcon = styled(Clear)`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const DeleteIcon = styled(Delete)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
    cursor: ${({ hoverCursor }) => (hoverCursor ? 'pointer' : 'auto')};
  }
`;

const AddCircleOutlineIcon = styled(AddCircleOutline)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
    cursor: ${({ hoverCursor }) => (hoverCursor ? 'pointer' : 'auto')};
  }
`;

const DragHandleIcon = styled(DragHandle)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const CreditCardIcon = styled(CreditCard)`
  color: ${(props) => props.color || '#fff'};
  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;
const EditIcon = styled(Edit)`
  color: ${({ color }) => color};
  color: ${({ theme }) => theme.icons.colors.creditCard};
  color: #161922;
  //color: rgba(0,0,0, 0.8) !important;
  color: ${({ theme }) => theme.icons.colors.txnTotalsIcon};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
/*const EqualizerIcon = styled(Equalizer)`
  stroke-width: 2;
  color: #fff;
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;*/
const FilterIcon = styled(Filter)`
  stroke-width: 2;
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
  //color: ${(props) => (props.theme.color ? props.theme.color : props.theme.icons.colors.filterBtnColor)};
`;
const LockIcon = styled(LockAlt)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const MenuBarsIcon = styled(Bars)`
  color: ${(props) => (props.theme.color ? props.theme.color : props.theme.sidebar.colors.menuBtn)};
  &:hover {
    //color: ${(props) => (props.theme.color ? props.theme.color : props.theme.sidebar.colors.hoverMenuBtn)};
  }
`;
const MinusIcon = styled(Minus)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const MinusCircleIcon = styled(MinusCircle)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const MoonIcon = styled(Moon)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const SaveIcon = styled(Save)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const ShoppingCartIcon = styled(ShoppingCart)`
  color: ${({ theme }) => theme.icons.colors.cart};
  color: ${({ theme }) => theme.icons.colors.txnTotalsIcon};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const SortAlphaDownIcon = styled(SortAlphaDown)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const SortAmountUpIcon = styled(SortAmountUp)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const SortAmountDownIcon = styled(SortAmountDown)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const SunIcon = styled(Sun)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const UniversityIcon = styled(University)`
  //color: ${({ color }) => color};
  color: ${({ theme }) => theme.icons.colors.university};
  color: #161922;
  //color: rgba(0,0,0, 0.8) !important;
  color: ${({ theme }) => theme.icons.colors.txnTotalsIcon};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;
const UserIcon = styled(User)`
  color: ${({ color }) => color};
  color: #161922;
  color: rgba(0, 0, 0, 0.8) !important;
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const LinkExternalIcon = styled(LinkExternal)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const ArrowDropDownIcon = styled(ArrowDropDown)`
  color: ${({ color }) => color};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const WarningIcon = styled(Warning)`
  color: ${({ color }) => color};
  color: #161922;
  //color: rgba(0,0,0, 0.8) !important;
  color: ${({ theme }) => theme.icons.colors.txnTotalsIcon};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const ShoppingCartIconContainer = styled.div`
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0.95;
  border-radius: 2px;
  background: ${({ theme }) => theme.icons.colors.warningAlpha};
  // background: ${({ theme }) => theme.icons.colors.warning};
  //border: ${({ theme }) => `1px solid rgba(69,231,134, .3)`}};
border: ${({ theme }) => `1px solid rgba(255, 255, 255, .1)`}};
  //rgba(69,231,134, .9
`;
const UnivsersityIconContainer = styled.div`
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0.9;
  background-color: ${({ theme }) => theme.icons.colors.university};
`;
const CreditCardContainer = styled.div`
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0.9;
  border-radius: 2px;
  background: ${(props) => props.theme.icons.colors.creditCardAlpha};
  // background: ${(props) => props.theme.icons.colors.creditCard};
  // border: ${({ theme }) => `1px solid rgba(246, 236, 75, .3)`}};
  border: ${({ theme }) => `1px solid rgba(255, 255, 255, .1)`}};
`;
const WarningContainer = styled.div`
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0.95;
  border-radius: 2px;
  border: ${({ theme }) => `1px solid rgba(255, 255, 255, .1)`}};
  background: ${({ theme }) => theme.icons.colors.cartAlpha};

`;

const IconContainer = styled.div`
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  &:hover {
    cursor: ${(props) => props.cursorHover};
  }
`;

const SortAlphaDownContainer = styled.div`
  border-radius: 4px;
  height: 30px;
  width: 40px;
  padding: 2px;
  background: #3fa9f5;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;

/*const WindowCloseIcon = styled(WindowClose)`
  color: ${({ color }) => color || '#fff'};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;*/

export {
  AddCircleOutlineIcon,
  AddIcon,
  AngleDownIcon,
  AngleUpIcon,
  ArrowCircleLeftIcon,
  BookmarkIcon,
  CalendarIcon,
  CancelIcon,
  CaretDownIcon,
  CaretUpIcon,
  CircleIcon,
  // CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronsLeftIcon,
  ChevronRightIcon,
  ChevronsRightIcon,
  ClearIcon,
  CloseIcon,
  CreditCardIcon,
  CreditCardContainer,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
  // EqualizerIcon,
  FilterIcon,
  IconContainer,
  LockIcon,
  MenuBarsIcon,
  MinusIcon,
  MinusCircleIcon,
  MoonIcon,
  SaveIcon,
  ShoppingCartIcon,
  ShoppingCartIconContainer,
  SortAlphaDownIcon,
  SortAlphaDownContainer,
  SortAmountDownIcon,
  SortAmountUpIcon,
  SunIcon,
  UniversityIcon,
  UnivsersityIconContainer,
  UserIcon,
  WarningIcon,
  WarningContainer,
  LinkExternalIcon,
  ArrowDropDownIcon
  // WindowCloseIcon
};
