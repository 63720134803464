import React from 'react';
/* state management */
import { observer, Observer } from 'mobx-react-lite';
/* styles */
import styled from 'styled-components/macro';
import { WidgetBody, WidgetWrapper } from 'styles/styledComponents';
/* utils */
import { _get, useInjectStores } from 'utils/utils';
/* component */
import { Dropdown } from 'components/UI';
import { HalfRadialBarChart } from './resources';

const ChgbkChart = observer((props) => {
  const { uiStore, utilsStore } = useInjectStores();
  const formatNumber = utilsStore.formatNumber;

  const MidHealthLabel = styled.div`
    color: ${({ midHealthColor }) => midHealthColor};
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 40px;
  `;

  return (
    <Observer>
      {() => (
        <div
          css={`
            grid-area: chart;
          `}
        >
          <WidgetWrapper position="relative">
            <>
              <div
                css={`
                  position: absolute;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  top: 10px;
                  z-index: 1;
                `}
              >
                {props.store.midOptions?.length === 1 ? (
                  <div
                    css={`
                      padding-top: 15px;
                    `}
                  >
                    {props.store.midOptions[0]?.value}
                  </div>
                ) : props.store.isDemo ? (
                    <div
                        css={`
                      padding-top: 15px;
                    `}
                    >
                    888800000008888
                    </div>) : (
                    <Dropdown
                        search
                        width="150px"
                        placeholder={'Select Mid'}
                        options={props.store.midOptions}
                        value={props.store.mid}
                        onChange={(mid) => props.store.selectMid(mid)}
                        label=""
                    />
                )}
              </div>
              {/*{props.store.ssChgbkTotals &&*/}
              {props.store.ssChgbkTotals.map((mid, index) => (
                <>
                  {mid[0].mid === props.store.mid && (
                    <WidgetBody key={index} height="300px">
                      <Observer>
                        {() => (
                          <HalfRadialBarChart
                            key={index}
                            barGap={4}
                            ticks={[0.0, 1.3, 2.5, 3.8, 5.0]}
                            innerRadius="50%"
                            outerRadius="90%"
                            midHealth={mid[0].midHealth}
                            volHealthColor={mid[0].volHealthColor}
                            amtHealthColor={mid[0].amtHealthColor}
                            theme={_get(uiStore.currentTheme, 'primaryTickColor')}
                            details={{
                              midHealth: mid[0].midHealth.text,
                              midHealthColor: mid[0].midHealth.color,
                              amt: `${formatNumber(mid[0].data.amt, '', 2)}%`,
                              vol: `${formatNumber(mid[0].data.vol, '', 2)}%`
                            }}
                            data={[mid[0].data]}
                          />
                        )}
                      </Observer>
                      <MidHealthLabel midHealthColor={mid[0].midHealth.color}>{mid[0].midHealth.text}</MidHealthLabel>
                      <div
                        css={`
                          position: absolute;
                          top: 48px;
                          width: 100%;
                          text-align: center;
                          margin: 10px 0;
                        `}
                      >
                        MID Chargeback Health
                      </div>
                    </WidgetBody>
                  )}
                </>
              ))}
            </>
          </WidgetWrapper>
        </div>
      )}
    </Observer>
  );
});

export default ChgbkChart;
