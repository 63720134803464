import styled from 'styled-components/macro';

const DropdownContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  flex-direction: column;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: ${(props) => (props.show ? '0px' : '4px')};
  border-bottom-right-radius: ${(props) => (props.show ? '0px' : '4px')};
  background-color: #fff;
  overflow-x: hidden;
  &:hover {
    cursor: pointer;
  }
`;

const DropdownOption = styled.div`
  z-index: 2;
  height: 35px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  background: ${({ disabled }) => (disabled ? '#dbdbdb' : '#fff')};
  text-align: center;
  align-items: center;
  padding-left: 9px;
  border-top: 1px solid #fafafa;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};

  div {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: ${(props) => (props.disabled ? 'white' : '#f8f8f8;')};
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  border: ${({ border, theme }) => (border ? theme.dropdownBorder : '')};
  border-bottom-left-radius: ${({ show }) => (show ? '0px' : '5px')};
  border-bottom-right-radius: ${({ show }) => (show ? '0px' : '5px')};
  background: #fff;
`;

const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  i {
    border: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const SelectedOptionInput = styled.input`
  align-items: center;
  display: flex;
  justify-content: center;

  border: 0;
  padding-left: 9px;
  background-color: white;
  color: black;
  height: ${(props) => (props.height ? props.height : '35px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
`;

const SelectedOption = styled.div`
  align-items: center;
  background-color: white;
  color: ${(props) => (props.error ? 'red' : props.theme.primaryColor)};
  display: flex;
  height: ${(props) => (props.height ? props.height : '35px')};
  overflow: hidden;
  padding-left: 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
`;

const OptionsContainer = styled.div`
  position: absolute;
  width: ${({ width }) => width || '100%'};
  width: ${({ width, border }) => (border ? width || '100%' : width ? `calc(${width} - 2px)` : `calc(100% - 2px)`)};
  left: ${({ left, border }) => (border ? left || ' -1px' : left || '0px')};
  top: ${({ top }) => top || '33px'};
  color: ${({ error, theme }) => (error ? '#9f3a38' : theme.primaryColor)};
  z-index: 5;
  max-height: 260px;
  overflow-y: auto;
  border: ${({ show }) => (show ? '1px solid #e2e2e2' : '1px solid transparent')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export {
  DropdownWrapper,
  DropdownContainer,
  DropdownOption,
  OptionsContainer,
  SelectedOptionInput,
  SelectedOption,
  SelectedOptionContainer,
};
