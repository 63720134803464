import React from 'react';
import { toCommaDecimal } from 'utils/currencyFilter';
import { LabelList, PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;
  return (
    <g>
      {/*<circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />*/}
      {/*<text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">*/}
      <text>
        {/*{value.split(' ')[1]}*/}
        okokokokok
      </text>
    </g>
  );
};
const salesformatter = (value) => {
  if (value === '0') {
    return `.  ${value}% sales`;
  }
  return `${value}% sales`;
};

const volumeformatter = (value) => {
  if (value === '0') {
    return `. ${value}% volume`;
  } else {
    return `${value}% volume`;
  }
};

function formatAmtValue(value) {
  return salesformatter(toCommaDecimal(value, 2));
}

function formatVolValue(value) {
  return volumeformatter(toCommaDecimal(value, 2));
}

const widgetConstants = { name: 'ssChgbkTotals', id: 3 };
const HalfRadialBarChart = ({
  volHealthColor,
  amtHealthColor,
  barCategoryGap,
  barGap,
  barSize,
  data,
  theme,
  innerRadius,
  outerRadius,
  ticks,
}) => (
  <ResponsiveContainer height="100%" width="100%">
    <RadialBarChart
      startAngle={180}
      endAngle={0}
      cx="50%"
      cy="75%"
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      barSize={barSize}
      barCategoryGap={barCategoryGap}
      barGap={barGap}
      data={data}
    >
      <PolarAngleAxis type="number" orientation="inner" ticks={ticks} datakey="amtMax" tick={{ fill: theme }} />
      <RadialBar stroke={volHealthColor} fill={volHealthColor} background clockWise dataKey={'vol'} isAnimationActive={true}>
        <LabelList datakey={'volLabel'} formatter={formatVolValue} position={'insideStart'} />
      </RadialBar>
      <RadialBar stroke={amtHealthColor} fill={amtHealthColor} background clockWise dataKey={'amt'} isAnimationActive={true}>
        <LabelList dataKey={'amt'} position={'insideStart'} formatter={formatAmtValue} />
      </RadialBar>
    </RadialBarChart>
  </ResponsiveContainer>
);

const initialData = {
  ratios: [
    // {
    //   chgbk_amt_cat: 'Ok',
    //   chgbk_amt_ratio: 0,
    //   chgbk_vol_cat: 'Ok',
    //   chgbk_vol_ratio: 0,
    // },
  ],
  values: [
    {
      amount: '$0.00',
      cat_order: 'CB01',
      category: 'Retrievals',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT01',
      hdr_type: 'Retrievals'
    },
    {
      amount: '$0.00',
      cat_order: 'CB01',
      category: 'Retrievals',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT02',
      hdr_type: 'Retrievals',
    },
    {
      amount: '$0.00',
      cat_order: 'CB01',
      category: 'Retrievals',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT03',
      hdr_type: 'Retrievals',
    },
    //Fraud
    {
      amount: '$0.00',
      cat_order: 'CB02',
      category: 'Fraud',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT01',
      hdr_type: 'Chargeback',
    },
    {
      amount: '$0.00',
      cat_order: 'CB02',
      category: 'Fraud',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT02',
      hdr_type: 'Chargeback',
    },
    {
      amount: '$0.00',
      cat_order: 'CB02',
      category: 'Fraud',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT03',
      hdr_type: 'Chargeback',
    },

    //  cardholder disputes
    {
      amount: '$0.00',
      cat_order: 'CB03',
      category: 'Cardholder Disputes',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT01',
      hdr_type: 'Chargeback',
    },
    {
      amount: '$0.00',
      cat_order: 'CB03',
      category: 'Cardholder Disputes',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT02',
      hdr_type: 'Chargeback',
    },
    {
      amount: '$0.00',
      cat_order: 'CB03',
      category: 'Cardholder Disputes',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT03',
      hdr_type: 'Chargeback',
    },
    //  authorization Issues
    {
      amount: '$0.00',
      cat_order: 'CB04',
      category: 'Authorization Issues',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT01',
      hdr_type: 'Retrieval',
    },
    {
      amount: '$0.00',
      cat_order: 'CB04',
      category: 'Authorization Issues',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT02',
      hdr_type: 'Chargeback',
    },
    {
      amount: '$0.00',
      cat_order: 'CB04',
      category: 'Authorization Issues',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT03',
      hdr_type: 'Chargeback Reversal',
    },
    // processing errors
    {
      amount: '$0.00',
      cat_order: 'CB05',
      category: 'Processing Errors',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT01',
      hdr_type: 'Retrieval',
    },
    {
      amount: '$0.00',
      cat_order: 'CB05',
      category: 'Processing Errors',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT02',
      hdr_type: 'Chargeback',
    },
    {
      amount: '$0.00',
      cat_order: 'CB05',
      category: 'Processing Errors',
      count: '0',
      fees: '$0.00',
      hdr_typ_order: 'CBT03',
      hdr_type: 'Chargeback Reversal',
    },
  ],
  totals: [
    {
      hdr_typ_order: 'CBT01',
      hdr_type: 'Retrieval',
      tot_count: '0',
      tot_amount: '$0.00',
      tot_fees: '$0.00',
    },
    {
      hdr_typ_order: 'CBT02',
      hdr_type: 'Chargeback',
      tot_count: '0',
      tot_amount: '$0.00',
      tot_fees: '$0.00',
    },
    {
      hdr_typ_order: 'CBT03',
      hdr_type: 'Chargeback Reversal',
      tot_count: '0',
      tot_amount: '$0.00',
      tot_fees: '$0.00',
    },
  ],
};

export { widgetConstants, initialData, HalfRadialBarChart };
