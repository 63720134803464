import React, {useEffect} from 'react';
import { Details } from './index';
import styled from 'styled-components';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { useInjectStores } from 'utils/utils';
import { applySnapshot } from 'mobx-state-tree';
import {useHistory, useLocation} from "react-router-dom";

const FeesDetails = observer(({ margin }) => {
  const { dataManagementStore, filterStore } = useInjectStores();
  const history = useHistory();
  const location = useLocation();
  const cFilter = filterStore.appliedCommonFilters,
    sFilter = filterStore.appliedSecondaryFilters;

  const store = useLocalStore(() => ({
    history,
    location,
    cFilter,
    sFilter,
    applyNewFilter(snapshot) {
      // reset current filter, but don't apply.
      filterStore.resetStateNoApply();
      // set the model
      applySnapshot(filterStore.declineFilter, snapshot);
      // setting the options for the multi select dropdowns
      // store.filterStore.mapFilterDropdownsToModels();
      // set the pills
      filterStore.applyFilter(history, location);
      // set the newquery for the useEffects to pick up
      dataManagementStore.setNewQuery();
    },
  }));

  return (
    <Observer>
      {() => (
        <Wrapper margin={margin}>
          <div className="total">
            <Details store={store} />
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  .total {
    grid-area: total;
  }
 
  grid-template-areas: 'total';
  grid-template-columns: 1fr;
  grid-gap: 5px;
  padding-bottom: 4px;
`;

export default FeesDetails;
