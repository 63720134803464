import styled from 'styled-components/macro';
const SSWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
`;

export { SSWidgetWrapper };
