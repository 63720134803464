import React from 'react';
import { WarningContainer, WarningIcon, WidgetWrapper } from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Observer } from 'mobx-react-lite';
import { LoadingSpinner } from 'components/UI';

const DeclinesTotal = (props) => {
  return (
    <WidgetWrapper>
      <Observer>
        {() => (
          <>
            {props.store.loading ? (
              <LoadingSpinner size={'80px'}/>
            ) : props.store.error ? (
              <div>Error: {props.store.error.message}</div>
            ) : (
              <TotalWrapper>
                <WarningContainer>
                  <WarningIcon color="white" style={{ width: 25, height: 25 }}/>
                </WarningContainer>
                <div className={'singleValueContent'}>
                  <div>{props.store.toCurrency(props.store.widgetData?.decl_err_tot) || ''}</div>
                  <div className="title">Declines & Errors</div>
                </div>
              </TotalWrapper>
            )}
          </>
        )}
      </Observer>
    </WidgetWrapper>
  );
};

const TotalWrapper = styled.div`
  box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 500ms;
  .singleValueContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title {
    margin-top: 5px;
  }
`;

export default DeclinesTotal;
