import React, { useEffect } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Notes, Table } from 'components/UI';
import { initialData } from './resources';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores } from 'utils/utils';
import { widgetService } from 'services';

const Prepaid = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel,
    fetchTransaction = dataManagementStore.fetchTransaction,
    toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal,
    toCurrencyFormatter = utilsStore.toCurrencyFormatter,
    toDateFormatter = utilsStore.toDateFormatter,
    paginatorModel = widgetModel.paginatorModel;

  const orderByOptions = [
    { key: 1, text: 'Cust Name', value: 'custName' },
    { key: 2, text: 'Invoice Num', value: 'invNum' },
    { key: 3, text: 'Txn Type', value: 'txnType' },
    { key: 4, text: 'Txn Status', value: 'txnStatus' },
    { key: 5, text: 'Txn Date', value: 'txnDt' },
  ];

  function cellStyle(params) {
    if (params.value < 0) {
      return { color: '#FF2B49' };
    } else {
      // return { color: 'rgb(69, 232, 133)' };
    }
  }

  const store = useLocalStore(() => ({
    fileName: 'prepaid',
    storageKey: 'txn-prepaid-state',
    loading: false,
    widgetData: initialData,
    rowData: [],
    txnCount: 0,
    async getData() {
      store.loading = true;
      try {
        // const res = await widgetModel.fetchWidgetData(widgetService.transactionDetails);
        const res = await widgetModel.fetchWidgetData(widgetService.acctPrepaid);

        store.widgetData = res.data;
        store.rowData = store.widgetData.values.map((txn, index) => {
          txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
          return {
            ...txn,
          };
        });
        store.txnCount = store.widgetData.count;
      } catch (error) {
        console.error('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
    api: null,
    columnApi: null,
    detailRowHeight: 310,
    // detailCellRenderer: 'myDetailCellRenderer',
    // frameworkComponents: { myDetailCellRenderer: Notes },
    /*detailCellRendererParams: (params) => ({
      detailGridOptions: {
        columnDefs: [{ field: 'Index' }],
      },
      getDetailRowData(params) {
        params.successCallback(params.data.callRecords);
      },
    }),*/
    saveColumnState() {
      let savedState = store.columnApi.getColumnState();
      savedState = JSON.stringify(savedState);
      if (savedState) {
        window.localStorage.setItem('txn-details-state', savedState);
      }
    },
    setColumnState() {
      let savedState = window.localStorage.getItem('txn-details-state');
      savedState = JSON.parse(savedState);
      if (savedState && store.columnApi) {
        store.columnApi.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    resetColumnState() {
      window.localStorage.removeItem('txn-details-state');
      store.columnApi.resetColumnState();
    },
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        rowHeight: 33,
        headerHeight: 33,
        sideBar: {
          position: 'left',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
        },
        defaultColDef: {
          defaultColDef: { flex: 1 },
          minWidth: 90,
          maxWidth: 300,
          sortable: false,
          filter: true,
          resizable: true,
        },
        columnDefs: [
          {
            headerName: 'EIGHT ID',
            field: 'eight_id',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 160,
          },
          // {
          //   headerName: 'MID',
          //   field: 'mid',
          //   enableRowGroup: true,
          //   enablePivot: true,
          //   chartDataType: 'series',
          //   width: 150,
          // },
          // {
          //   headerName: 'MID NM',
          //   field: 'mid_nm',
          //   enableRowGroup: true,
          //   enablePivot: true,
          //   chartDataType: 'series',
          //   width: 150,
          // },
          {
            headerName: 'Src Eight ID',
            field: 'src_eight_id',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'Proc Txn ID',
            field: 'proc_txn_id',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 155,
          },
          {
            headerName: 'Anniv Date',
            field: 'anniv_dt',
            enableRowGroup: true,
            valueFormatter: toDateFormatter,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
            {
            headerName: 'Prepd End Date',
            field: 'prepd_end_dt',
            enableRowGroup: true,
            valueFormatter: toDateFormatter,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'Billing Day',
            field: 'billing_day',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },

          {
            headerName: 'Prepd Term',
            field: 'prepd_term',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'Mo Alloc Amount',
            field: 'mo_alloc_amt',
            enableRowGroup: true,
            enablePivot: true,
            type: 'rightAligned',
            valueFormatter: toCurrencyFormatter,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'Txn Count by CC',
            field: 'txn_cnt_by_cc',
            cellStyle: cellStyle,
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'CC Num',
            field: 'cc_num_concat',
            cellStyle: cellStyle,
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'ET Cust ID',
            field: 'et_cust_id',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'ET Cust Name',
            field: 'et_cust_nm',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'Cust ID',
            field: 'cust_id',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'GW Cust Name',
            field: 'gw_cust_nm',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'PO Num',
            field: 'po_num',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'Inv Num',
            field: 'inv_num',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'SKU Num',
            field: 'sku_num',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
          {
            headerName: 'SKU Desc',
            field: 'sku_desc',
            enableRowGroup: true,
            enablePivot: true,
            chartDataType: 'series',
            width: 150,
          },
        ],

        onGridReady(params) {
          store.api = params.api;
          store.columnApi = params.columnApi;
          store.setColumnState();
        },
        onFirstDataRendered(params) {
          setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          paginatorModel.setPageNumber(1);
          await store.getData();
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <TableWrapper>

        <Table
          store={store}
          fetchTableData={store.getData}
          rowData={store.rowData}
          loading={store.loading}
          /**export**/
          initiateTxnsExport={store.widgetModel.initiateTxnsExport}
          exportInfo={store.widgetModel.exportInfo}
          /** Paginator-Specific Functions **/
          txnCount={store.txnCount}
          paginator={paginatorModel}
          /** OrderBy Properties **/
          addOrderBy={store.widgetModel.addOrderBy}
          orderByArray={store.widgetModel.orderByModel}
          orderByOptions={store.orderByOptions}
          resetOrderBy={store.widgetModel.resetOrderBy}
          />
          
          </TableWrapper>
      )}
    </Observer>
  );
});



export default Prepaid;

const TableWrapper = styled.div`
height: 100%;
margin-left: 5px;
margin-right: 5px;
`