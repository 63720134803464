import React, { useState, useEffect } from 'react';
import { CreateClientForm } from './Forms/CreateClientForm';
import { EditClientForm } from './Forms/EditClientForm';
import Tabs from './Forms/Tabs';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { useInjectStores, _get, _upperFirst, _map } from 'utils/utils';
import commonService, { adminService } from 'services/service';
import styled from 'styled-components/macro';
import { Route, useRouteMatch } from 'react-router-dom';
import { createClientStore } from './Forms/stores/CreateClientStore';
import { createAggrOptions, createOptions } from './Forms/stores/store';
import { useQuery } from 'react-query';

const createStore = createClientStore.create();

const AdminClientManagement = observer((props) => {
  const { path } = useRouteMatch();
  const [tab, setTab] = useState('create');
  const { uiStore } = useInjectStores();
  const notify = uiStore.notify;

  const [createEdit, setCreateEdit] = useState('create');
  const store = useLocalObservable(() => ({
    loadingAdminData: false,
    loadingGwData: false,
    loadingProcessorData: false,
    loadingAppData: false,
    loadingClients: false,
    adminData: null,
    gwData: [],
    procData: [],
    appData: [],
    integrationsData: [],
    clients: [],
    get isLoading() {
      return this.loadingAppData || this.loadingAdminData || this.loadingGwData || this.loadingProcessorData;
    },
    get allClientOptions() {
      const clients = _get(this, 'clients', []);
      return clients.map((client) => ({ text: client.clientName, key: client.id, value: client.id }));
    },
    get datasourceOptions() {
      return createOptions(_get(this.adminData, 'dataSources'), 'sname', _upperFirst);
    },
    get rolesOptions() {
      return createOptions(this.adminData, 'roles');
    },
    get gwOptions() {
      const gateways = [
        'Authnet',
        'DataCap',
        'NMI',
        'Paysafe - NMI White label',
        'Cybersource',
        'USAepay',
        'Everest',
        'Goldstar GW',
      ];
      return gateways.map((gw) => ({ text: gw, key: gw, value: gw }));
    },
    get procOptions() {
      const processors = ['Paysafe', 'Cybersource', 'Goldstar'];
      return processors.map((proc) => ({ text: proc, key: proc, value: proc }));
    },
    get users() {
      return _get(this.adminData, 'users');
    },

    get aggrs() {
      return _map(_get(this, 'appData.aggrs'), (agg, index) => {
        return { key: index, text: agg.sname, value: agg.id };
      });
    },
    get clientMidsOptions() {
      return _map(_get(this, 'userStore.user.lastClientViewed.Mids'), (mid, index) => {
        return { key: index, text: mid.midNum, value: mid.id };
      });
    },
    get clientMidNumsOptions() {
      return _map(_get(this, 'userStore.user.lastClientViewed.Mids'), (mid, index) => {
        return { key: index, text: mid.midNum, value: mid.midNum };
      });
    },
    get countryOptions() {
      return _map(_get(this.adminData, 'countries'), (country, index) => {
        return { key: country, text: country, value: country };
      });
    },
    get currencyOptions() {
      return _map(_get(this.adminData, 'currencies'), (currency, index) => {
        return { key: currency, text: currency, value: currency };
      });
    },
    get pricingPlanOptions() {
      return _map(_get(this.adminData, 'pricing_plans'), (plan, index) => {
        return { key: index, text: 'Everything', value: 100 };
      });
    },
    get timezoneOptions() {
      return _map(_get(this, 'appData.timezones'), (zone, index) => {
        return { key: index, text: zone.tmzName, value: zone.id };
      });
    },
    setAdminData(adminData) {
      const { status, data, error } = adminData;
      if (status === 'loading') this.loadingAdminData = true;
      if (error) this.loadingAdminData = false;
      if (data) {
        this.adminData = adminData?.data?.data;
        this.loadingAdminData = false;
      }
    },
    setGateways(gateways) {
      const { status, data, error } = gateways;
      if (status === 'loading') this.loadingGwData = true;
      if (error) this.loadingGwData = false;
      if (data) {
        this.gwData = gateways?.data?.data;
        this.loadingGwData = false;
      }
    },
    setProcessors(processors) {
      const { status, data, error } = processors;
      if (status === 'loading') this.loadingProcessorData = true;
      if (error) this.loadingProcessorData = false;
      if (data) {
        this.procData = processors?.data?.data;
        this.loadingProcessorData = false;
      }
    },
    setAppData(appData) {
      const { status, data, error } = appData;
      if (status === 'loading') this.loadingAppData = true;
      if (error) this.loadingAppData = false;
      if (data) {
        this.appData = appData?.data;
        this.loadingAppData = false;
      }
    },
    setClients(res) {
      const { status, data, error } = res;
      if (status === 'loading') this.loadingClients = true;
      if (error) this.loadingClients = false;
      if (data) {
        this.clients = res?.data?.data;
        this.loadingClients = false;
      }
    },
    /* setIntegrationsData(integrationsData) {
      const { status, data, error } = integrationsData;
      if (status === 'loading') this.loadingAppData = true;
      if (error) this.loadingAppData = false;
      if (data) {
        this.integrationsData = integrationsData?.data;
        this.loadingAppData = false;
      }
    },*/
  }));

  const gateways = useQuery(['getGatewayList'], adminService.getGateways, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const processors = useQuery(['getProcessorsList'], adminService.getProcessors, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const adminData = useQuery(['getAdminData'], adminService.getAdminData, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const clients = useQuery(['getClients'], adminService.getClients, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  // const appData = useQuery(['getAppData'], commonService.getAppData, {
  //   staleTime: 60000,
  //   refetchOnWindowFocus: false,
  // });
  /* const integrationsData = useQuery(['getIntegrations'], () => adminService.getIntegrations(), {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });*/

  useEffect(() => {
    store.setGateways(gateways);
  }, [gateways]);
  useEffect(() => {
    store.setProcessors(processors);
  }, [processors]);
  // TODO Get this from react query cache
  useEffect(() => {
    store.setAdminData(adminData);
  }, [adminData]);
  useEffect(() => {
    store.setClients(clients);
  }, [clients]);
  /*useEffect(() => {
    store.setIntegrationsData(integrationsData);
  }, [integrationsData]);*/
  // useeffect(() => {
  //   store.setappdata(appdata);
  // }, [appdata]);

  return (
    <Observer>
      {() => (
        <Wrapper>
          {/*<Tabs createEdit={createEdit} setCreateEdit={setCreateEdit} setTab={setTab} tab={tab} />*/}
          <Observer>
            {() => (
              <Route path={`/admin/client/create`}>
                <CreateClientForm notify={notify} store={store} clientModel={createStore} />
              </Route>
            )}
          </Observer>
          <Observer>
            {() => (
              <Route path={`/admin/client/edit`}>
                <EditClientForm store={store} notify={notify} />
              </Route>
            )}
          </Observer>
        </Wrapper>
      )}
    </Observer>
  );
});

export default AdminClientManagement;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 2px;
  .wrapper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .tabWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2px 5px;
    margin-bottom: 2px;
    grid-template-areas:
      'one one two two three three four four'
      'c1 e1 c2 e2 c3 e3 c4 e4';
    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }

    .btn {
      cursor: pointer;
    }
    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }
    .tab {
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
`;

const StyledNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1rem;
  height: 20px;
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding-right: 10px;
  font-style: italic;
  .note {
    font-size: 1rem;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;
