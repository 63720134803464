import React, {useEffect} from 'react';
import {Observer, observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
// import SsTxnTotals from 'components/Widgets/ReconSummary/SsTxnTotals';
import PrePaid from 'components/Widgets/PrePaid/Prepaid';
import {useCheckAuthorizedRoute, useInjectStores} from 'utils/utils';
import {RouteLoadingSpinner} from 'components/UI';
import ProcTabs from 'components/UI/Tabs/procTabs';
import ReactGA from 'react-ga';
import {Route} from 'react-router-dom';

const PrepaidBilling = observer(() => {
    const {loading, authorized, error} = useCheckAuthorizedRoute();
    const {userStore} = useInjectStores();
    const user = userStore.user;
    useEffect(() => {
        ReactGA.initialize('UA-195070931-1', {
            gaOptions: {
                clientId: user.currentClient.id,
                clientName: user.currentClient.clientName,
                userEmail: user.email,
            },
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
    return (
        <Observer>
            {() => (
                <>
                    {/*{loading ? (*/}
                    {/*  <RouteLoadingSpinner size={'400px'} />*/}
                    {/*) : authorized ? (*/}
                    {/*  <div style={{ height: '100%', width: '100%' }}>*/}
                    <Wrapper>
                        <PrePaid/>
                    </Wrapper>
                    {/*</div>*/}
                    {/*) : (*/}
                    {/*  <div>error</div>*/}
                    {/*)}*/}
                </>
            )}
        </Observer>
    );
});

export default PrepaidBilling;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  //margin: 0 4px;
`;
