import { commonService } from 'services';
import { DataManagementStore, FilterStore, UserStore, UiStore, UtilsStore, WidgetStore } from './index';
import { _get, _map } from 'utils/utils';
import { types, applySnapshot, flow, getSnapshot } from 'mobx-state-tree';
import React from 'react';
const { optional } = types;

const RootStore = types
  .model({
    dataManagementStore: optional(DataManagementStore, () => DataManagementStore.create()),
    filterStore: optional(FilterStore, () => FilterStore.create({ orderBy: [{ column: '', sort: 1 }] })),
    uiStore: optional(UiStore, () => UiStore.create()),
    userStore: optional(UserStore, () => UserStore.create()),
    utilsStore: optional(UtilsStore, () => UtilsStore.create()),
    widgetStore: optional(WidgetStore, () => WidgetStore.create()),
  })
  .volatile((self) => ({
    appData: {},
    currentDashboardID: null,
    initialState: {},
  }))
  .views((self) => ({
    get aggrs() {
      return _map(_get(self, 'appData.aggrs'), (agg, index) => {
        return { key: index, text: agg.sname, value: agg.id };
      });
    },
    get ccBrandIds() {
      return _map(_get(self, 'appData.ccBrands'), (brand, index) => {
        return { key: index, text: brand, value: brand };
      });
    },
    get chgbkRsn() {
      return _map(_get(self, 'appData.chgbkCodes'), (rsn, index) => {
        return { key: index, text: rsn.rsnDesc, value: rsn.ids };
      });
    },
    get ccLevels() {
      return _map(_get(self, 'appData.ccLevels'), (level, index) => {
        return { key: index, text: level.sname, value: level.id };
      });
    },
    get clientMids() {
      return _map(_get(self, 'userStore.user.currentClient.mids'), (mid, index) => {
        return { key: mid, text: mid, value: mid};
      });
    },
    get clientMidNums() {
      return _map(_get(self, 'userStore.user.currentClient.mids'), (mid, index) => {
        return { key: mid, text: mid, value: mid};
      });
    },
    get countries() {
      return _map(_get(self, 'appData.countries'), (country, index) => {
        return { key: index, text: country.iso3, value: country.id };
      });
    },
    get chgbkCodes() {
      return _map(_get(self, 'appData.chgbkCodes'), (chgbkCode, index) => {
        return { key: index, text: chgbkCode.rsnDesc, value: chgbkCode.ids };
      });
    },
    get currencies() {
      return _map(_get(self, 'appData.currencies'), (currency, index) => {
        return { key: index, text: currency.iso3, value: currency.id };
      });
    },
    get declBkt() {
      return _map(_get(self, 'appData.declBkts'), (bkt, index) => {
        return { key: index, text: bkt.sname, value: bkt.id };
      });
    },
    get declRsn() {
      return _map(_get(self, 'appData.respCodes'), (code, index) => {
        return { key: index, text: code.sname, value: code.id };
      });
    },
    get domIntlCats() {
      return _map(_get(self, 'appData.domIntlCats'), (dom, index) => {
        return { key: index, text: dom.sname, value: dom.id };
      });
    },
    get emailTypes() {
      return _map(_get(self, 'appData.emailTypes'), (type, index) => {
        return { key: index, text: type.sname, value: type.id };
      });
    },
    get pricingPlans() {
      return _map(_get(self, 'appData.pricingPlans'), (plan, index) => {
        return { key: index, text: plan.desc, value: plan.id };
      });
    },
    get timezones() {
      return _map(_get(self, 'appData.timezones'), (zone, index) => {
        return { key: index, text: zone.tmzName, value: zone.id };
      });
    },
    get txnStatuses() {
      return _map(_get(self, 'appData.txnStatuses'), (status, index) => {
        return { key: index, text: status.desc, value: status.id };
      });
    },
    get txnTypes() {
      return _map(_get(self, 'appData.txnTypes'), (type, index) => {
        return { key: index, text: type.desc, value: type.id };
      });
    },
  }))
  .actions((self) => {
    function afterCreate() {
      self.initialState = getSnapshot(self);
    }
    function resetState() {
      applySnapshot(self, self.initialState);
    }
    function setReferer(referer) {
      if (referer) {
        self.referer = referer;
      }
    }
    const getAppData = flow(function* () {
      try {
        const appDataResponse = yield commonService.getAppData({});
        self.appData = appDataResponse.data.data;
      } catch (error) {
        console.error('Failed to fetch dashboard data', error);
      }
    });
    return {
      afterCreate,
      resetState,
      setReferer,
      getAppData,
    };
  });
/* Store helpers */
export const StoreContext = React.createContext();

export const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};

export default RootStore;
