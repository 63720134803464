import React, { useEffect } from 'react';
import {
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow
} from 'styles/styledComponents';
import { RouteLoadingSpinner } from 'components/UI';
import { _get } from 'utils/utils';
import { Observer, useLocalObservable } from 'mobx-react-lite';

const Adjustments = (props) => {
  const store = useLocalObservable(() => ({
    widgetData: null,
    get transactionSection() {
      return _get(this, 'widgetData[\'MERCHANT_STATEMENT_APPLICATION\'][\'CUSTOMER\'][0][\'TRANSACTION_SECTION\'][0]');
    },
    get adjustmentTran() {
      return _get(this.transactionSection, "['ADJUSTMENT_TRAN']");
    },
    get adjustmentTotals() {
      return _get(this.transactionSection, "['ADJUSTMENT_TOTALS']");
    },
    setWidgetData(data) {
      this.widgetData = data;
    },
  }));

  useEffect(() => {
    store.setWidgetData(props.store.widgetData);
  }, [props.store.widgetData]);

  return (
    <Observer>
      {() => (
        <div>
          <TableHeader light={true} backgroundColor="#e9eae9">
            ADJUSTMENTS
          </TableHeader>
          {props.store.isLoading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Day
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Reference Number
                    </HeaderCell>
                    {/* <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Case Number
                    </HeaderCell>*/}
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Tran Code
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Plan Code
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Num of Sales
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Sales
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Amount of Credits
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Disc Paid
                    </HeaderCell>
                    <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                      Net Deposit
                    </HeaderCell>
                  </TableRow>
                  {store.adjustmentTran && store.adjustmentTran.length > 0 && (
                    <>
                      {store.adjustmentTran.map((detail, index) => (
                        <TableRow key={index}>
                          <TableCell alignContent="left" light={true}>
                            {detail['DAY'][0]}
                          </TableCell>
                          <TableCell alignContent="left" light={true}>
                            {detail['REF_NUMBER']}
                          </TableCell>
                          {/*<TableCell alignContent="left" light={true}>*/}
                          {/*  {detail['CASE_NUM']}*/}
                          {/*</TableCell>*/}
                          <TableCell light={true}>{detail['TRAN_TYPE']}</TableCell>
                          <TableCell light={true}>{detail['PLAN_TYPE']}</TableCell>
                          <TableCell light={true}>{detail['NUM_SALES']}</TableCell>
                          <TableCell light={true}>{detail['DOLLAR_SALES']}</TableCell>
                          <TableCell light={true}>{detail['DOLLAR_CREDITS']}</TableCell>
                          <TableCell light={true}>{detail['DISC_PAID']}</TableCell>
                          <TableCell light={true}>{detail['NET_DEPOSIT']}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                  {store.adjustmentTotals && store.adjustmentTotals.length > 0 && (
                    <>
                      {store.adjustmentTotals.map((detail, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {index === 0 ? 'Chargeback Totals' : ''}
                          </TableCell>
                          <TableCell light={true}>{}</TableCell>
                          <TableCell light={true}>{}</TableCell>
                          <TableCell light={true}>{}</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['NUM_SALES']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DOLLAR_SALES']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DOLLAR_CREDITS']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['DISC_PAID']}
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} light={true}>
                            {detail['NET_DEPOSIT']}
                          </TableCell>
                        </TableRow>
                      ))}

                      {/* <TableRow>
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine>{formatNumber(totalCnt)}</TableCell>
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine />
                      <TableCell light={true} showTotalsLine>{toCurrency(totalAmt)}</TableCell>
                    </TableRow>*/}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}
    </Observer>
  );
};

export default Adjustments;
