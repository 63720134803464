import React, { useEffect } from 'react';
// import _debounce from 'lodash/debounce';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { _map, _ceil } from 'utils/utils';
import {
  // AddIcon,
  ChevronLeftIcon,
  ChevronsLeftIcon,
  ChevronRightIcon,
  ChevronsRightIcon,
  // MinusIcon
} from 'styles/styledComponents';
import styled from 'styled-components/macro';

const Paginator = observer((props) => {
  const fetchTableData = props.fetchTableData,
    loading = props.loading,
    paginator = props.paginator,
    height = props.height,
    width = props.width,
    txnCount = props.txnCount;
  useEffect(() => {
    if (paginator.pageNumber > localStore.totalPages) {
      let newPageNumber = localStore.totalPages === 0 ? 1 : localStore.totalPages;
      paginatorSetPage(newPageNumber);
    }
  }, [txnCount]);

  // const debounceFetchData = _debounce(fetchTableData, 1000, { leading: false, trailing: true });

  const localStore = useLocalStore(() => ({
    get totalPages() {
      return _ceil(txnCount / paginator.txnLimit) || 0;
    },
    get showUptoFivePages() {
      let currentPage = paginator.pageNumber;
      let totalPages = localStore.totalPages;
      let pages = [];
      if (totalPages > 5) {
        if (currentPage <= 3) {
          return [1, 2, 3, 4, 5];
        }
        if (currentPage >= totalPages - 3) {
          return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        } else {
          return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
        }
      }
      for (let i = 0; i < totalPages; i++) {
        pages.push(i + 1);
      }
      return pages;
    },

    get canGoLeft() {
      return paginator.pageNumber > 1;
    },
    get canGoRight() {
      return paginator.pageNumber < localStore.totalPages;
    },
    get displayStartIndex() {
      if (txnCount === 0) return 0;
      else return (paginator.pageNumber - 1) * paginator.txnLimit + 1;
    },
    get displayEndIndex() {
      if (txnCount === 0) return 0;
      else {
        let maximumPageEnd = localStore.displayStartIndex + paginator.txnLimit - 1;
        return maximumPageEnd > txnCount ? txnCount : maximumPageEnd;
      }
    },
    get realStartIndex() {
      return (paginator.pageNumber - 1) * paginator.txnLimit;
    },
  }));

  /*  async function paginatorIncrementTxnLimit() {
      if (paginator.txnLimit < 200 && !loading) {
        paginator.incrementTxnLimit();
        await debounceFetchData();
      }
    }
    async function paginatorDecrementTxnLimit() {
      if (paginator.txnLimit > 50 && !loading) {
        paginator.decrementTxnLimit();
        await debounceFetchData();
      }
    }
    async function dropdownChangeTxnLimit(limit) {
      paginator.setPageNumber(Math.floor(localStore.realStartIndex / limit) + 1);
      paginator.setTxnLimit(limit);
      await fetchTableData();
    }*/
  async function paginatorIncrementPageNumber() {
    if (paginator.pageNumber < localStore.totalPages) {
      paginator.incrementPageNumber();
      await fetchTableData();
    }
  }
  async function paginatorDecrementPageNumber() {
    if (paginator.pageNumber > 1) {
      paginator.decrementPageNumber();
      await fetchTableData();
    }
  }
  async function paginatorSetFirstPage() {
    if (paginator.pageNumber > 1 && !loading) {
      paginator.setPageNumber(1);
      await fetchTableData();
    }
  }
  async function paginatorSetLastPage() {
    if (paginator.pageNumber < localStore.totalPages + 1 && !loading) {
      paginator.setPageNumber(localStore.totalPages);
      await fetchTableData();
    }
  }
  async function paginatorSetPage(page) {
    if (page < localStore.totalPages + 1 && page > 0) {
      paginator.setPageNumber(page);
      await fetchTableData();
    }
  }

  return (
    <Observer>
      {() => (
        <PaginatorWrapper height={height}>
          <div className="paginatorInnerWrapper">
            <PaginatorArrowLeft width={width} onClick={paginatorSetFirstPage} endLeft canGoLeft={localStore.canGoLeft}>
              <ChevronsLeftIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowLeft>

            <PaginatorArrowLeft width={width} onClick={paginatorDecrementPageNumber} canGoLeft={localStore.canGoLeft}>
              <ChevronLeftIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowLeft>

            {_map(localStore.showUptoFivePages, (page, index) => {
              return (
                <PaginatorPageNumIcon
                  key={index}
                  width={width}
                  currentPage={paginator.pageNumber === page}
                  onClick={() => {
                    paginatorSetPage(page);
                  }}
                >
                  {page}
                </PaginatorPageNumIcon>
              );
            })}

            <PaginatorArrowRight width={width} onClick={paginatorIncrementPageNumber} canGoRight={localStore.canGoRight}>
              <ChevronRightIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowRight>

            <PaginatorArrowRight width={width} onClick={paginatorSetLastPage} canGoRight={localStore.canGoRight} endRight>
              <ChevronsRightIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowRight>
          </div>
        </PaginatorWrapper>
      )}
    </Observer>
  );
});

const PaginatorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: ${({ height }) => height || '32px'};
  .paginatorInnerWrapper {
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    height: 100%;
    // height: ${({ height }) => height || '32px'}
    margin: 5px;
    max-width: 425px;
  }
  .paginatorAddDrop {
    width: 160px;
  }
`;

const PaginatorPageNumIcon = styled.div`
  align-items: center;
  background-color: ${({ currentPage }) => (currentPage === true ? '#3FA9F5' : '#fff')};
  border: ${({ currentPage }) => (currentPage === true ? '1px solid #A8A8A8' : '1px solid #DBDBDB')};
  color: ${({ currentPage }) => (currentPage === true ? '#fff' : 'rgb(91,91,91)')};
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  height: 100%;
  width: ${({ width }) => width || '45px'};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
    color: ${({ theme }) => theme.colors.primaryText};
  }
`;

const PaginatorArrowLeft = styled.div`
  align-items: center;
  background-color: #f0eff0;
  border-bottom-left-radius: ${({ endLeft }) => (endLeft ? '5px' : 0)};
  border: 1px solid #dbdbdb;
  border-top-left-radius: ${({ endLeft }) => (endLeft ? '5px' : 0)};
  display: flex;
  height: 100%;
  justify-content: center;
  width: ${({ width }) => width || '45px'};
  &:hover {
    cursor: ${({ canGoLeft }) => (canGoLeft ? 'pointer' : 'not-allowed')};
    background: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
  }
`;

const PaginatorArrowRight = styled.div`
  align-items: center;
  border: 1px solid #dbdbdb;
  border-bottom-right-radius: ${({ endRight }) => (endRight ? '5px' : 0)};
  background-color: #f0eff0;
  border-top-right-radius: ${({ endRight }) => (endRight ? '5px' : 0)};
  display: flex;
  height: 100%;
  justify-content: center;
  width: ${({ width }) => width || '45px'};
  &:hover {
    cursor: ${({ canGoRight }) => (canGoRight ? 'pointer' : 'not-allowed')};
    background: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
  }
`;

export default Paginator;
