import React, { useEffect } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Table } from 'components/UI';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores } from 'utils/utils';
import { widgetService } from 'services';
import {adminStore} from 'stores/AdminStore';
import { Link } from 'react-router-dom';


const MerchSettings = observer(() => {
  	const history = useHistory();
  	const location = useLocation();
  	const { widgetStore, dataManagementStore, userStore, utilsStore } = useInjectStores();
  	const widgetModel = widgetStore.ssTxnsWidgetModel, paginatorModel = widgetModel.paginatorModel;
	const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
  	const initialData = { values: [{txns: [], count: 0}] };

	const store = useLocalStore(() => ({
		fileName: 'merchants',
		storageKey: 'merchants',
		loading: false,
		setMerchToEdit: (merchId) => {
			adminStore.setMerchToEdit(merchId);
		},
		columnDefs: [
			{
				headerName: 'MID',
				field: 'mid',
				chartDataType: 'series',
				headerTooltip: 'MID'
			},
			{
				headerName: 'Merch Name',
				field: 'merch_nm',
				chartDataType: 'series',
				headerTooltip: 'Merch Name'
			},
			{
				headerName: 'Monthly Credit Limit',
				field: 'mo_cr_limit',
				valueFormatter: toCurrencyFormatter,
				type: 'rightAligned',
				chartDataType: 'series',
				headerTooltip: 'Monthly Credit Limit'
			},
			{
				headerName: 'Gateway T/F',
				field: 'gw_tf',
				type: 'rightAligned',
				chartDataType: 'series',
				headerTooltip: 'Gateway T/F'
			},
			{
				headerName: 'Gateway Name',
				field: 'gw_nm',
				chartDataType: 'series',
				headerTooltip: 'Gateway Name'
			},
			{
				headerName: '',
				field: '',
				filter: false,
				pinned: 'left',
				width: 100,
				cellRenderer: ({data}) => {
					const editBtn = document.createElement('button');
					editBtn.innerText = 'Edit';
					editBtn.className = 'apply';
					editBtn.style.width = '100%';
					editBtn.addEventListener('click', async (e) => {
						store.setMerchToEdit(data.mid);
						history.push(`/admin/merch/edit`);
					});

					return editBtn;
				}
			},
		],
		widgetData: initialData,
		rowData: [],
		txnCount: 0,
		async getData() {
			store.loading = true;
			try {
				const res = await widgetModel.fetchWidgetData(widgetService.adminGetMerchSettings);

				store.widgetData = res.data;
				store.cleanData = res.data;

				store.rowData = store.widgetData.values.map((txn, index) => {
					txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
					return {
						...txn
					};
				});
				store.txnCount = store.widgetData.count || store.widgetData.values.length;
			} catch (error) {
				if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
				userStore.logout(history, location);
				}
			}
			store.loading = false;
		},
		dataManagementStore,
		widgetModel,
		paginatorModel,
		api: null,
		columnApi: null,
		get gridOptions() {
			return {
				enableCharts: true,
				enableRangeSelection: true,
				groupMultiAutoColumn: true,
				rowSelection: 'multiple',
				rowHeight: 33,
				headerHeight: 33,
				sideBar: {
					position: 'left',
					toolPanels: [
						{
						id: 'columns',
						labelDefault: 'Columns',
						labelKey: 'columns',
						iconKey: 'columns',
						toolPanel: 'agColumnsToolPanel',
						},
						{
						id: 'filters',
						labelDefault: 'Filters',
						labelKey: 'filters',
						iconKey: 'filter',
						toolPanel: 'agFiltersToolPanel',
						}
					]
				},
				defaultColDef: {
					sortable: true,
					filter: true,
					resizable: true,
					suppressMenu: true
				},
				columnDefs: store.columnDefs,
				onGridReady(params) {
					store.api = params.api;
					store.columnApi = params.columnApi;
				},
				overlayLoadingTemplate:
				'<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
				overlayNoRowsTemplate:
				'<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
			};
		}
	}));

	useEffect(
		() =>
		autorun(async () => {
			if (dataManagementStore.newQuery) {
			paginatorModel.setPageNumber(1);
			await store.getData();
			}
		}),
		[]
	);
  
	return (
		<Observer>
		{() => (
			<TableWrapper>
				<div className="header-create">
 				<div className="options">
 					<Link className="add-merch-btn" to={`/admin/merch/add`}>
 						Add Merchant
 					</Link>
				</div>
			</div>
			<Table
				store={store}
				fetchTableData={store.getData}
				rowData={store.rowData}
				loading={store.loading}
				/**export**/
				initiateTxnsExport={store.widgetModel.initiateTxnsExport}
				exportInfo={store.widgetModel.exportInfo}
				/** Paginator-Specific Functions **/
				txnCount={store.txnCount}
				paginator={false}
				enableBrowserTooltips={true}
				/** OrderBy Properties **/
				addOrderBy={store.widgetModel.addOrderBy}
				orderByArray={store.widgetModel.orderByModel}
				orderByOptions={store.orderByOptions}
				resetOrderBy={store.widgetModel.resetOrderBy}
				noPaginator={true}
				noExport={true}
			/>
			</TableWrapper>
		)}
		</Observer>
	);
});



export default MerchSettings;

const TableWrapper = styled.div`
	height: 100%;
	margin-left: 5px;
	margin-right: 5px;
	background-color: #041c2f;
	.options {
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 4rem;
	}
	.headerGrid {
		display: none;
	}
	.orderByExportContainer {
		display: none;
	}
	.header-create {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: start;
		padding: 1rem;
		.add-merch-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			color: white;
			width: 15rem;
			height: 37px;
			text-align: center;
			border-radius: 5px;
		}
		.add-merch-btn:active {
			color: black;
			transform: translateY(2px);
		}
	}

	.icon-button {
		width: 2rem;
		cursor: pointer;
	}
	  
	.icon-button:active {
		color: black;
		transform: translateY(2px);
	}
`;
