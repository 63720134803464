import React, { useEffect } from 'react';
import { LoginPageWrapper } from './login/StyledLogin';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useInjectStores } from 'utils/utils';

const Landing = observer(() => {
  const history = useHistory();
  const { userStore } = useInjectStores();

  useEffect(() => {
    if (userStore.user) {
      history.push('/dashboard');
    }
  }, [userStore.user]);

  return <LoginPageWrapper />;
});

export default Landing;
