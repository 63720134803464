import {_get} from 'utils/utils';

class UserView {
	constructor(data, authenticatedUser) {
		const canGivePermissions = _get(authenticatedUser, 'currentClient.permissions');
		this.firstName = data.users_name.split(' ')[0] || ''; 
		this.lastName = data.users_name.split(' ')[1] || '';
		this.email = data.user_email || '';
		this.phone = data.user_cell_ph || '';
		this.role = data.user_role;
		this.password = '';
		this.currentPackageLevel = data.current_package_level;
		this.adminRights = data.administrator_rights;
		this.permissions = [
			{
				parent: {
					column: 1,
					name: 'Dashboard',
					checked: data.dashboard === 'Checked' ? true : false,
					disabled: (data.dashboard === 'Unavailable' || canGivePermissions.dshbrd_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: 'Graph & Credit Limit',
						checked: data.dshbrd_graph_credit_limit === 'Checked' ? true : false,
						disabled: (data.dshbrd_graph_credit_limit === 'Unavailable' || canGivePermissions.dshbrd_pg_graph_crlim === "Unchecked") ? true : false
					},
					{
						name: 'Averages',
						checked: data.dshbrd_averages === 'Checked' ? true : false,
						disabled: (data.dshbrd_averages === 'Unavailable' || canGivePermissions.dshbrd_pg_avgs === "Unchecked") ? true : false
					},
					{
						name: 'Trends',
						checked: data.dshbrd_trends === 'Checked' ? true : false,
						disabled: (data.dshbrd_trends === 'Unavailable' || canGivePermissions.dshbrd_pg_trends === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 2,
					name: 'Fees',
					checked: data.fees === 'Checked' ? true : false,
					disabled:(data.fees === 'Unavailable' || canGivePermissions.fees_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: 'Fees By Category',
						checked: data.fees_by_category === 'Checked' ? true : false,
						disabled: (data.fees_by_category === 'Unavailable' || canGivePermissions.fees_pg_fee_by_cat === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 1,
					name: 'Accounting',
					checked: data.accounting === 'Checked' ? true : false,
					disabled: (data.accounting === 'Unavailable' || canGivePermissions.acctg_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: 'Daily Batches',
						checked: data.acctg_daily_batches === 'Checked' ? true : false,
						disabled: (data.acctg_daily_batches === 'Unavailable' || canGivePermissions.acctg_pg_dly_bat === "Unchecked") ? true : false
					},
					{
						name: 'Daily Deposits',
						checked: data.acctg_daily_deposits === 'Checked' ? true : false,
						disabled: (data.acctg_daily_deposits === 'Unavailable' || canGivePermissions.acctg_pg_dly_dep === "Unchecked") ? true : false
					},
					{
						name: 'Journals: Accrual',
						checked: data.acctg_journals_accrual === 'Checked' ? true : false,
						disabled: (data.acctg_journals_accrual === 'Unavailable' || canGivePermissions.acctg_pg_jnl_accl === "Unchecked") ? true : false
					},
					{
						name: 'Journals: Cash',
						checked: data.acctg_journals_cash === 'Checked' ? true : false,
						disabled: (data.acctg_journals_cash === 'Unavailable' || canGivePermissions.acctg_pg_jnl_cash === "Unchecked") ? true : false
					},
					{
						name: 'Prepaid Billing',
						checked: data.acctg_prepaid_billing === 'Checked' ? true : false,
						disabled: (data.acctg_prepaid_billing === 'Unavailable' || canGivePermissions.acctg_pg_prepd_bill === "Unchecked") ? true : false
					},
					{
						name: 'TSYS Statements',
						checked: data.acctg_tsys_statements === 'Checked' ? true : false,
						disabled: (data.acctg_tsys_statements === 'Unavailable' || canGivePermissions.acctg_pg_tsys_stmt === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 3,
					name: 'Reports',
					checked: data.reports === 'Checked' ? true : false,
					disabled: (data.reports === 'Unavailable' || canGivePermissions.reps_tab === "Unchecked") ? true : false
				},
				group: data.allReports.map((report, index) => {
					const reportName = report.rep_sname;
					const possibleReports = ['Expired Cards (Current Month)', 'Expired Cards (Historic Months)', 'Do Not Honor (DNH) Details'];
					return {
						name: report.rep_full_nm,
						checked: data[reportName] && possibleReports.includes(report.rep_full_nm) ? true : false,
						// checked: data[reportName],
						disabled: !possibleReports.includes(report.rep_full_nm) ? true : false,
						// disabled: !data.currentReports.some(obj => obj.rep_full_nm === report.rep_full_nm),
						repSname: report.rep_sname
					};
				})
			},
			{
				parent: {
					column: 1,
					name: 'Transactions',
					checked: data.transactions === 'Checked' ? true : false,
					disabled: (data.transactions === 'Unavailable' || canGivePermissions.txns_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: 'Analytics',
						checked: data.txns_analytics === 'Checked' ? true : false,
						disabled: (data.txns_analytics === 'Unavailable' || canGivePermissions.txns_pg_analyt === "Unchecked") ? true : false
					},
					{
						name: 'Chargeback Details',
						checked: data.txns_chargebacks_details === 'Checked' ? true : false,
						disabled: (data.txns_chargebacks_details === 'Unavailable' || canGivePermissions.txns_pg_chgbk === "Unchecked") ? true : false
					},
					{
						name: 'Decline Details',
						checked: data.txns_declines_details === 'Checked' ? true : false,
						disabled: (data.txns_declines_details === 'Unavailable' || canGivePermissions.txns_pg_decl === "Unchecked") ? true : false
					},
					{
						name: 'Reconciliation',
						checked: data.txns_reconciliation === 'Checked' ? true : false,
						disabled: (data.txns_reconciliation === 'Unavailable' || canGivePermissions.txns_pg_recon === "Unchecked") ? true : false
					},
					{
						name: 'Refund Details',
						checked: data.txns_refunds_details === 'Checked' ? true : false,
						disabled: (data.txns_refunds_details === 'Unavailable' || canGivePermissions.txns_pg_ref === "Unchecked") ? true : false
					},
					{
						name: 'Sales Details',
						checked: data.txns_sales_details === 'Checked' ? true : false,
						disabled: (data.txns_sales_details === 'Unavailable' || canGivePermissions.txns_pg_sale === "Unchecked") ? true : false
					},
					{
						name: 'Void Inquiries',
						checked: data.txns_voids_inquiries === 'Checked' ? true : false,
						disabled: (data.txns_voids_inquiries === 'Unavailable' || canGivePermissions.txns_pg_voidinq === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 2,
					name: 'Opportunity',
					checked: data.opportunity === 'Checked' ? true : false,
					disabled: (data.opportunity === 'Unavailable' || canGivePermissions.opp_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: 'Analysis Summary',
						checked: data.opp_analysis_summary === 'Checked' ? true : false,
						disabled: (data.opp_analysis_summary === 'Unavailable' || canGivePermissions.opp_pg_sum === "Unchecked") ? true : false
					},
					{
						name: 'Opportunity Details',
						checked: data.opportunity_details === 'Checked' ? true : false,
						disabled: (data.opportunity_details === 'Unavailable' || canGivePermissions.opp_pg_dtl === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 2,
					name: 'Tax',
					checked: data.tax === 'Checked' ? true : false,
					disabled: (data.tax === 'Unavailable' || canGivePermissions.tax_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: '1099k Analysis',
						checked: data.tax_1099k_analysis === 'Checked' ? true : false,
						disabled: (data.tax_1099k_analysis === 'Unavailable' || canGivePermissions.tax_pg_1099k === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 2,
					name: 'Administration',
					checked: data.administration === 'Checked' ? true : false,
					disabled: (data.administration === 'Unavailable' || canGivePermissions.admin_tab === "Unchecked") ? true : false
				},
				group: [
					{
						name: 'Administrator Rights',
						checked: data.administration === 'Checked' ? true : false,
						disabled: (data.administration === 'Unavailable' || canGivePermissions.admin_tab === "Unchecked") ? true : false
					},
					{
						name: 'Add New Users',
						checked: data.admin_add_new_user === 'Checked' ? true : false,
						disabled: (data.admin_add_new_user === 'Unavailable' || canGivePermissions.user_add_user_permsn === "Unchecked") ? true : false
					},
					{
						name: 'Permission to Add Reports',
						checked: data.admin_permission_add_reports === 'Checked' ? true : false,
						disabled: (data.admin_permission_add_reports === 'Unavailable' || canGivePermissions.user_add_reps === "Unchecked") ? true : false
					},
					{
						name: 'Permission to Upgrade',
						checked: data.admin_permission_upgrade === 'Checked' ? true : false,
						disabled: (data.admin_permission_upgrade === 'Unavailable' || canGivePermissions.user_upg_permsn === "Unchecked") ? true : false
					}
				]
			},
			{
				parent: {
					column: 3,
					name: 'Additional',
					checked: false,
					disabled: false
				},
				group: [
					{
						name: 'Archive Transaction Access (Data older than current year and previous year)',
						checked: data.archive_txn_access === 'Checked' ? true : false,
						disabled: (data.archive_txn_access === 'Unavailable' || canGivePermissions.archive_access === "Unchecked") ? true : false
					},
					{
						name: 'Financially Know Your Customer (FKYC) Database',
						checked: data.financially_kyc === 'Checked' ? true : false,
						disabled: (data.financially_kyc === 'Unavailable' || canGivePermissions.fkyc_tab === "Unchecked") ? true : false
					}
				]
			}
		];
	}
};

export default UserView;