import React, { Fragment, useEffect, useState } from 'react';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { adminService } from 'services';
import styled from 'styled-components/macro';
import { Table, Button, LoadingSpinner, Modal, Input } from 'components/UI';
import { EditIcon, DeleteIcon, CaretDownIcon, CaretUpIcon } from 'styles/styledComponents';
import { Link } from 'react-router-dom';
import {adminStore} from 'stores/AdminStore';
import { _get, useInjectStores } from 'utils/utils';
import Dialog from '../UserManager/Dialog';



const UserHome = observer(() => {
	const [confirmDelete, setConfirmDelete] = useState([]);
	const [sortColumn, setSortColumn] = useState(null);
	const [sortDirection, setSortDirection] = useState(null);
    const {dataManagementStore, userStore, uiStore} = useInjectStores();
	const [loading, setLoading] = useState(true);
	const [selected, setSelected] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [users, setUsers] = useState([]);
	const notify = uiStore.notify;

	const canAddUser = _get(userStore.user, 'currentClient.permissions.user_add_user_permsn');

	useEffect(() => {
		const clientId = dataManagementStore.clientID;
		const getAllUsers = async () => {
			await adminStore.fetchUsers(clientId);
			setUsers(adminStore.users);
			setLoading(false);
		};

		getAllUsers();
	}, []);

	useEffect(() => {
		if (searchTerm) {
			setUsers(
				adminStore.users.filter((user) =>
					{
						if(user.name) {
							return user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.username.toLowerCase().includes(searchTerm.toLowerCase());
						}
					})
			);
		} else {
			setUsers(adminStore.users);
		}
	}, [searchTerm, adminStore.users]);

	useEffect(() => {
		if(selected) {
		  const tmp = [...adminStore.users];
		  const filtered = tmp.filter((user) =>
		  {
			  if(user.name) {
				  return user.name[0].toLowerCase().includes(selected.toLowerCase()) || user.username[0].toLowerCase().includes(selected.toLowerCase());
			  }
		  })
		  setUsers(filtered);
		}else {
			setUsers(adminStore.users);
		}
	}, [selected]);

	const toggleSort = (columnName) => {
		if (sortColumn === columnName) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(columnName);
			setSortDirection('asc');
		}
	};

	const setUserToEdit = (username) => {
		adminStore.setUserToEdit(username);
	};

	const confirmDeleteAction = async (username) => {
		setLoading(true);
		const {status} = await adminStore.deleteUser(dataManagementStore.clientID, username);
		setConfirmDelete([false, '']);
		if(status === 200) {
			notify({
				duration: 1500,
				group: 'topRight',
				text: `User has been deleted!`
			});
		}else {
			notify({
				type: 'error',
				duration: 3000,
				group: 'topRight',
				text: `Error trying to delete user!`
			});
		}
		await adminStore.fetchUsers(dataManagementStore.clientID);
		setUsers(adminStore.users);
		setLoading(false);
	};

	let sortedUsers = [];
	if(users.length) {
		const tmp = [...users];
		sortedUsers = tmp.sort((a, b) => {
			if (sortColumn === 'email') {
				const aValue = a.username.toLowerCase();
				const bValue = b.username.toLowerCase();
				return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
			} else if (sortColumn === 'name') {
				const aValue = a.name?.toLowerCase();
				const bValue = b.name?.toLowerCase();
				return sortDirection === 'asc' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
			} else if (sortColumn === 'roles') {
				const aValue = a.role?.toLowerCase();
				const bValue = b.role?.toLowerCase();
				return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
			} else {
				return 0;
			}
		});
	}

	return (
		<Wrapper>
			{loading ? 
		<LoadingSpinner size={'400px'} /> : (
			<>
				<div className="header-create">
					<div className="options">
						{canAddUser === "Checked" ? (
						<Link className="add-user-btn" to={`/admin/user/add`}>
							Add User
						</Link>) : null}
						<Input
							width="20rem"
							tabIndex={1}
							placeholder={'Search...'}
							value={searchTerm}
							border
							onChange={(event) => setSearchTerm(event.target.value)}
						/>
						<div style={{display: 'flex', paddingLeft: '0.5rem', paddingBottom: '1rem', paddingTop: '1rem'}}>
						{'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter, idx) => {
							return (
							<span style={{fontSize: '1.5rem', padding: '0 0.5rem'}} className={selected === letter ? 'selected' : ''} key={idx} onClick={() => setSelected(selected !== letter ? letter : null)}>{letter}</span>
							);
						})}
						</div>
					</div>
				</div>
				<div className="table-wrapper">
					{
					sortedUsers ? 
					(<table>
						<thead>
							<tr>
								<th onClick={() => toggleSort('email')}>Username {sortColumn === 'email' && sortDirection === 'desc' ? <CaretUpIcon/> : <CaretDownIcon />}</th>
								<th onClick={() => toggleSort('name')}>Name {sortColumn === 'name' && sortDirection === 'desc' ? <CaretUpIcon/> : <CaretDownIcon />}</th>
								<th onClick={() => toggleSort('roles')}>Role {sortColumn === 'roles' && sortDirection === 'desc' ? <CaretUpIcon/> : <CaretDownIcon />}</th>
								<th style={{textAlign: 'center'}}>Can Add Users</th>
								<th style={{textAlign: 'center'}}>Can Upgrade</th>
								<th style={{textAlign: 'center'}}>Can Add Reports</th>
								<th style={{textAlign: 'center'}}>Edit</th>
								<th style={{textAlign: 'center'}}>Delete</th>
							</tr>
						</thead>
						<tbody>
							{sortedUsers.map(user => (
								user.name ? (
									<tr key={user.username}>
										<td>{user.username}</td>
										<td>{user.name}</td>
										<td>{user.role}</td>
										<td style={{textAlign: 'center'}}>{user.can_add_users}</td>
										<td style={{textAlign: 'center'}}>{user.can_upgrade}</td>
										<td style={{textAlign: 'center'}}>{user.can_add_reports}</td>
										<td style={{textAlign: 'center'}}>
											<Link to={`/admin/user/edit`} onClick={() => setUserToEdit(user.username)}>
												<EditIcon className="icon-button" />
											</Link>
										</td>
										<td style={{textAlign: 'center'}}>
											<span onClick={() => setConfirmDelete([true, user.username])}>
												<DeleteIcon className="icon-button"/>
											</span>
										</td>
									</tr>
								) : null
							))}
						</tbody>
					</table>)
					:
					<LoadingSpinner size={'200px'}/>
					}
				</div>
				<h4 className="note-section-header">Notes:</h4>
				<p className="note-section">Users that do not have a role as "Admin" may only add new users and upgrade, if given permission, in the modules they have been assigned to.</p>
				<Dialog isOpen={confirmDelete[0]} onClose={() => setConfirmDelete([false, ''])}>
					<p style={{fontWeight: 'bold'}}>Are you sure you want to remove {confirmDelete[1]}?</p>
					<div style={{width: '100%', display: 'flex', justifyContent: 'end', gap: '5px'}}>
						<Button className="cancel-btn" onClick={() => setConfirmDelete([false, ''])}>
							<span>Cancel</span>
						</Button>
						<Button className="add-user-btn" onClick={() => confirmDeleteAction(confirmDelete[1])}>
							<span>Confirm</span>
						</Button>
					</div>
				</Dialog>
			</>
			)}
		</Wrapper>
	)
});

const Wrapper = styled.div`
	min-height: 85vh;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	.options {
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 4rem;
	}
	.add-user-btn {
		background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
		color: white;
		width: 25%;
		padding: 0.5rem 0rem;
	}
	.cancel-btn {
		color: black;
		width: 25%;
		padding: 0.5rem 0rem;
	}
	color: ${({ theme }) => theme.colors.primaryText};
	.header-create {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: start;
		padding: 1rem;
		.add-user-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			color: white;
			width: 15rem;
			height: 37px;
			text-align: center;
			border-radius: 5px;
		}
		.add-user-btn:active {
			color: black;
			transform: translateY(2px);
		}
	}
	.table-wrapper {
		padding: 1rem;
		max-height: 800px;
		overflow: auto;
		table {
			width: 100%;
			border-collapse: collapse; 
		}
		tr {
			width: 100%;
			color: ${({ theme }) => theme.colors.primaryText};
		}
		th {
			text-align: left;
			border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
		}
		td {
			text-align: left;
		}
	}
	h3 {
		color: ${({ theme }) => theme.colors.primaryText};
		border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
	}

	.icon-button {
		width: 2rem;
		cursor: pointer;
	}
	  
	.icon-button:active {
		color: black;
		transform: translateY(2px);
	}

	.note-section {
		padding-left: 1rem;
	}

	.note-section-header {
		width: 10%;
		margin-left: 1rem;
		margin-bottom: 0;
		border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
	}
	.selected {
		border-bottom: solid 1px white;
		font-weight: bold;
	}
`;

export default UserHome;