import React from 'react';
import ClientsPage from './Clients/AdminClientManagement';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { Observer, observer } from 'mobx-react-lite';
import { RouteLoadingSpinner } from 'components/UI';

const Clients = observer(() => {
  const { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => <>{loading ? <RouteLoadingSpinner size={'400px'} /> : authorized ? <ClientsPage /> : <div>error</div>}</>}
    </Observer>
  );
});

export default Clients;
