import React, {Fragment, useEffect} from 'react';
import {
    ClearIcon,
    FilterIcon,
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import {Button, LoadingSpinner} from 'components/UI';
import {observer, Observer, useLocalStore} from 'mobx-react-lite';
import {_get, _includes, _toPairs, useInjectStores} from 'utils/utils';
import {autorun} from 'mobx';

const Purchases = observer((props) => {
    const {filterStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency,
        getTotalsPercentage = utilsStore.getTotalsPercentage,
        formatNumber = utilsStore.formatNumber;
    const txnFilter = filterStore.transactionFilter;
    // const totalCnt = _get(props.widgetData, 'totals.tot_sale_cat_cnt', 0);
    // const totalAmt = _get(props.widgetData, 'totals.tot_sale_cat_amt', 0);

    const store = useLocalStore(() => ({
        totalAmt: 0,
        totalCnt: 0,

        setAmt(total) {
           this.totalAmt += total;
        },
        setCnt(total) {
            this.totalCnt += total;
        }


    }));

    /*useEffect(() => {
        // store.setTotals(props.succPurch);

       props.succPurch.map((detail, index) => {
            store.setAmt(_get(detail, 'sale_cat_vol', 0));
            store.setCnt(_get(detail, 'purch_succ_amts', 0));
        })
    }, [props.succPurch]);*/


    useEffect(
        () =>
            autorun(async () => {
                if (store.resetCurrentLocalFilter) {
                    store.currentLocalFilter = '';
                }
                if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== txnFilter.category) {
                    store.currentLocalFilter = txnFilter.category;
                }
            }),
        []
    );

    return (
        <Observer>
            {() => (
                <TableWrapper height="100%">
                    <TableHeader>PURCHASES</TableHeader>
                    {props.isLoading && <LoadingSpinner size={'200px'}/>}
                    {!props.isLoading && (
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {!props.isLoading && (
                                        <Fragment>
                                            <TableRow>
                                                <HeaderCell textAlign={'left'}>Type</HeaderCell>
                                                <HeaderCell textAlign={'left'}>Category</HeaderCell>
                                                <HeaderCell>Count</HeaderCell>
                                                <HeaderCell>Count%</HeaderCell>
                                                <HeaderCell>Amount</HeaderCell>
                                                <HeaderCell>Amount%</HeaderCell>
                                            </TableRow>
                                            {props.succPurch?.length > 0 && (
                                                <Fragment>
                                                    {props.succPurch.map((detail, index) => (
                                                        <TableRow key={detail.recon_cat}>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat === 'Sales' ? detail.recon_typ : ''}</TableCell>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                            <TableCell>{formatNumber(detail.sale_cat_vol)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.sale_cat_vol, props.store.purchasesTotals.cnt)}</TableCell>
                                                            <TableCell>{toCurrency(detail.purch_succ_amts)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.purch_succ_amts, props.store.purchasesTotals.amt)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </Fragment>
                                            )}
                                            {props.unSuccPurch?.length > 0 && (
                                                <Fragment>
                                                    {props.unSuccPurch.map((detail, index) => (
                                                        <TableRow key={detail.recon_typ}>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat === 'Declines & Errors' ? detail.recon_typ : ''}</TableCell>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                            <TableCell>{formatNumber(detail.decl_cat_vol)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.decl_cat_vol, props.store.purchasesTotals.cnt)}</TableCell>
                                                            <TableCell>{toCurrency(detail.decl_err_amts)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.decl_err_amts, props.store.purchasesTotals.amt)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </Fragment>
                                            )}
                                            {props.voidPurch?.length > 0 && (
                                                <Fragment>
                                                    {props.voidPurch.map((detail, index) => (
                                                        <TableRow key={detail.recon_typ}>
                                                            <TableCell textAlign={'left'}></TableCell>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                            <TableCell>{formatNumber(detail.void_cat_vol)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.void_cat_vol, props.store.purchasesTotals.cnt)}</TableCell>
                                                            <TableCell>{toCurrency(detail.void_revsl_amts)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.void_revsl_amts, props.store.purchasesTotals.amt)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </Fragment>
                                            )}
                                            <TableRow>
                                                <TableCell showTotalsLine/>
                                                <TableCell showTotalsLine/>
                                                <TableCell showTotalsLine>{formatNumber(props.store.purchasesTotals.cnt)}</TableCell>
                                                <TableCell showTotalsLine/>
                                                <TableCell
                                                    showTotalsLine>{toCurrency(props.store.purchasesTotals.amt)}</TableCell>
                                                <TableCell showTotalsLine/>
                                            </TableRow>
                                        </Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </TableWrapper>
            )}
        </Observer>
    );
});

export default Purchases;
