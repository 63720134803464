import React from 'react';
import { _get } from 'utils/utils';
import styled from 'styled-components/macro';
import { centerColumn } from 'styles/styledComponents';

const TextLink = styled.a`
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
`;

function Item({ padding, display, justifyContent, children }) {
  return (
    <ItemWrapper
      style={{
        '--padding': padding,
        '--display': display,
        '--justify-content': justifyContent,
      }}
    >
      {children}
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  padding: var(--padding, 3px 0);
  display: var(--display, flex);
  justify-content: var(--justify-content, space-between);
`;

const DueBoxWrapper = styled.div`
  ...${centerColumn};
  padding: 20px;
  border: 1px solid black;
  margin: 20px auto;
  min-width: 300px;
  max-width: 800px;
`;

const DueBox = (props) => (
  <DueBoxWrapper>
    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["MIN_DISC_DUE"][0]'
    ) && (
      <Item>
        <span>Min Discount Due:</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["MIN_DISC_DUE"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(props.store, 'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["DISC_DUE"][0]') && (
      <Item>
        <span>Discount Due:</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["DISC_DUE"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["DISC_PAID"][0]'
    ) && (
      <Item>
        <span>Discount Paid</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["DISC_PAID"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["NET_DISC_DUE"][0]'
    ) && (
      <Item>
        <span>Net Discount Due</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["NET_DISC_DUE"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(props.store, 'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["FEES_DUE"][0]') && (
      <Item>
        <span>Fees Due</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["FEES_DUE"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["FEES_PAID"][0]'
    ) && (
      <Item>
        <span>Fees Paid:</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["FEES_PAID"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["NET_FEES_DUE"][0]'
    ) && (
      <Item>
        <span>Net Fees Due:</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["NET_FEES_DUE"][0]'
          )}
        </span>
      </Item>
    )}
    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["AMT_DEDUCTED"][0]'
    ) && (
      <Item>
        <span>Amount Deducted:</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["AMT_DEDUCTED"][0]'
          )}
        </span>
      </Item>
    )}

    {_get(
      props.store,
      'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["AMT_CREDITED"][0]'
    ) && (
      <Item>
        <span>Amount Credited:</span>
        <span>
          {_get(
            props.store,
            'widgetData["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["TOTALS_BOX_SECTION"][0]["AMT_CREDITED"][0]'
          )}
        </span>
      </Item>
    )}
  </DueBoxWrapper>
);

export default DueBox;
