import {Observer, observer} from "mobx-react-lite";
import {_get} from "../../../utils/utils";
import {
    Bar,
    BarChart, Cell,
    ComposedChart,
    LabelList,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis
} from "recharts";
import React from "react";

const initialData = {
    avgs: [
      {
        hist_days_to_fund: 0,
        hist_days_to_settle: 0,
        num_days_to_fund: 0,
        num_days_to_settle: 0,
      },
    ],
    toReturn: [
      {
        chgbk_hist_avg_tkt: 0,
        chgbk_var_avg_tkt: 0,
        chgbk_var_ratio: 0,
        decl_hist_avg_tkt: 0,
        decl_var_avg_tkt: 0,
        decl_var_ratio: 0,
        mid: 0,
        ref_hist_avg_tkt: 0,
        ref_var_avg_tkt: 0,
        ref_var_ratio: 0,
        rvsl_var_ratio: 0,
        sale_hist_avg_tkt: 0,
        sale_var_avg_tkt: 0,
        sale_var_ratio: 0,
        salervsl_hist_avg_tkt: 0,
        salervsl_var_avg_tkt: 0,
      },
    ],
  };

export const renderCustomizedLabel = (props) => {
  const {x, y, width, value, id} = props;
  const radius = 10;

  let fill;
  switch (id) {
    case '1-0':
      fill = '#45e885';
      return (
          <g>
            <text x={x + width / 2} y={y - radius} fill={fill} textAnchor="middle"
                  dominantBaseline="middle">
              {props.toCurrency(value, 2)}
            </text>
          </g>
      );
    case '2-0':
      fill = '#fccc1a';
      return (
          <g>
            <text x={x + width / 2} y={y - radius} fill={fill} textAnchor="middle"
                  dominantBaseline="middle">
              {props.toCurrency(value, 2)}
            </text>
          </g>
      );
    case '3-0':
      fill = '#3fa9f5';
      return (
          <g>
            <text x={x + width / 2} y={y - radius} fill={fill} textAnchor="middle"
                  dominantBaseline="middle">
              {props.toCurrency(value, 2)}
            </text>
          </g>
      );
    case '4-0':
      fill = '#ff2b49';
      return (
          <g>
            <text x={x + width / 2} y={y - radius} fill={fill} textAnchor="middle"
                  dominantBaseline="middle">
              {props.toCurrency(value, 2)}
            </text>
          </g>
      );
    default:
      fill = '#ff2b49';
      return (
          <g>
            <text x={x + width / 2} y={y - radius} fill={fill} textAnchor="middle"
                  dominantBaseline="middle">
              {props.toCurrency(value, 2)}
            </text>
          </g>
      );
  }
};

export const CustomizedDot = (props) => {
  const {x, y, width, value, id} = props;
  return (
      <svg></svg>
  )

};

/*const SalesChartOrig = observer((props) => {
    const data = [props.data.stats];
    return (
        <Observer>
            {() => (
                <ResponsiveContainer height="100%" width="100%">
                    <ComposedChart
                        // width={500}
                        // height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#45E885" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#45E885" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey={toCurrency(data[0].sale_var_avg_tkt, 2)} scale="band" stroke={'rgba(255,255,255, .8)'} />
                        <YAxis stroke={'rgba(255,255,255, .8)'} />
                        {/!*{/!*<Tooltip />*!/}*!/}
                        <Legend stroke={'rgba(255,255,255, .8)'} />
                        <Line dot={<CustomizedDot/>} dataKey="sale_hist_avg_tkt" name="Avg Prepaid" stroke="rgba(255,255,255, .8)" />
                        <Bar dataKey="sale_var_avg_tkt" name="Prepaid" barSize={200} fill="url(#colorGreen)">
                            <LabelList dataKey="sale_var_avg_tkt" content={renderCustomizedLabel} id="1" />
                        </Bar>
                        <ReferenceLine
                            y={data[0].sale_hist_avg_tkt}
                            stroke="rgba(255,255,255, .8)"
                            label={{
                                position: 'insideBottomRight',
                                value: 'Avg '.concat(toCurrency(data[0].sale_hist_avg_tkt, 2)),
                                fill: 'rgba(255,255,255, .8)',
                            }}
                        ></ReferenceLine>
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </Observer>
    );
});*/

export const SalesChart = observer(function salesChart(props) {
  // const data = [props.data.stats];
  const data = props.data;
  const salesAvg = props.toCurrency(props.data[0].sale_amt_avg_hist, 2);
  // const salesAvg = _get(props,'data.sales_avg', 0);

  return (
      <Observer>
        {() => (
            <ResponsiveContainer height="100%" width="100%">
              {/*sale_amt_avg_hist: 0,*/}
              {/*sales_avg: 0,*/}
              {/*sales_var_ratio: "",*/}
              <ComposedChart
                  // width={500}
                  // height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
              >
                <defs>
                  <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#45E885" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#45E885" stopOpacity={0}/>
                  </linearGradient>
                </defs>

                {/*<XAxis dataKey={toCurrency(sale_amt_avg_hist, 2)} scale="band"*/}
                <XAxis dataKey={'sales_avgs'} scale="band"
                       stroke={'rgba(255,255,255, .8)'}/>

                <YAxis stroke={'rgba(255,255,255, .8)'}/>
                {/*<Tooltip />*/}
                <Legend stroke={'rgba(255,255,255, .8)'}/>
                <Line dot={<CustomizedDot/>} dataKey="sale_amt_avg_hist" name="Avg Sales"
                      stroke="rgba(255,255,255, .8)"/>
                  {/*<Line type="monotone" dataKey="sale_amt_avg_hist" stroke="#ff7300" />*/}
                <Bar dataKey="sales_avg" name="Sales" barSize={200} fill="url(#colorGreen)">
                  <LabelList toCurrency={props.toCurrency} dataKey="sales_avg" content={renderCustomizedLabel} id="1"/>
                </Bar>
                <ReferenceLine
                    // y={'sale_amt_avg_hist'}
                    y={data[0].sale_amt_avg_hist}
                    stroke="rgba(255,255,255, .8)"
                    strokeDasharray="3 3"
                    label={{
                      position: 'insideBottomRight',
                        value: 'Historic Avg '.concat(props.toCurrency(data[0].sale_amt_avg_hist, 2)),
                      fill: 'rgba(255,255,255, .8)',
                    }}
                ></ReferenceLine>
                 {/* <ReferenceLine
                      // y={'sale_amt_avg_hist'}
                      y={data[0].sale_amt_avg_hist}
                      stroke="rgba(255,255,255, .8)"
                      label={{
                          position: 'insideTopRight',
                          value: 'Avg '.concat(props.toCurrency(data[0].sale_amt_avg_hist, 2)),
                          fill: 'rgba(255,255,255, .8)',
                      }}
                  ></ReferenceLine>*/}
              </ComposedChart>
            </ResponsiveContainer>
        )}
      </Observer>
  );
});

export const RefundsChart = observer((props) => {

  const data = props.data;
  const refAvg = props.toCurrency(_get(props,'data[0].ref_amt_avg_hist', 0), 2);
  return (
      <Observer>
        {() => (
            <ResponsiveContainer height="100%" width="100%">
              <ComposedChart
                  // width={500}
                  // height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
              >
                <defs>
                  <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="15%" stopColor="#fccc1a" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#fccc1a" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey={'refs_avg'} scale="band"
                       stroke={'rgba(255,255,255, .8)'}/>
                <YAxis stroke={'rgba(255,255,255, .8)'}/>
                {/*<Tooltip />*/}
                <Legend stroke={'rgba(255,255,255, .8)'}/>
                <Line dataKey="ref_amt_avg_hist" dot={<CustomizedDot/>} name="Avg Refunds"
                      stroke="rgba(255,255,255, .8)" color="#1a1a00"/>
                <Bar dataKey="ref_avg" name="Refunds" barSize={200} fill="url(#colorYellow)">
                  <LabelList toCurrency={props.toCurrency}  dataKey="ref_avg" content={renderCustomizedLabel} id="2"/>
                </Bar>
                <ReferenceLine
                    y={data[0].ref_amt_avg_hist}
                    stroke="rgba(255,255,255, .8)"
                    strokeDasharray="3 3"
                    label={{
                      position: 'insideBottomRight',
                      value: `Historic Avg ${refAvg}`,
                      fill: 'rgba(255,255,255, .8)',
                    }}
                />
              </ComposedChart>
            </ResponsiveContainer>
        )}
      </Observer>
  );
});

export const DeclinesChart = observer((props) => {
  const data = props.data;
  const declAvg = props.toCurrency(_get(props,'data[0].decl_amt_avg_hist', 0), 2);
  return (
      <Observer>
        {() => (
            <ResponsiveContainer height="100%" width="100%">
              <ComposedChart
                  // width={500}
                  // height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
              >
                <defs>
                  <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="15%" stopColor="#3fa9f5" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#3fa9f5" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey={'decls_avg'} scale="band"
                       stroke={'rgba(255,255,255, .8)'}/>
                <YAxis stroke={'rgba(255,255,255, .8)'}/>
                {/*<Tooltip />*/}
                <Legend stroke={'rgba(255,255,255, .8)'}/>
                <Line dataKey="decl_amt_avg_hist" dot={<CustomizedDot/>} name="Avg Declines"
                      stroke="rgba(255,255,255, .8)"/>
                <Bar dataKey="decl_avg" name="Declines" barSize={200} fill="url(#colorBlue)">
                  <LabelList toCurrency={props.toCurrency}  dataKey="decl_avg" content={renderCustomizedLabel} id="3"/>
                </Bar>
                <ReferenceLine
                    y={data[0].decl_amt_avg_hist}
                    stroke="rgba(255,255,255, .8)"
                    strokeDasharray="3 3"
                    label={{
                      position: 'insideBottomRight',
                      value: `Historic Avg ${declAvg}`,
                      fill: 'rgba(255,255,255, .8)',
                    }}
                />
              </ComposedChart>
            </ResponsiveContainer>
        )}
      </Observer>
  );
});

export const ChargebacksChart = observer((props) => {
  const data = props.data;
  const chgbkAvg = props.toCurrency(_get(props,'data[0].chgbk_amt_avg_hist', 0), 2);
  return (
      <Observer>
        {() => (
            <ResponsiveContainer height="100%" width="100%">
              <ComposedChart
                  // width={500}
                  // height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
              >
                <defs>
                  <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="15%" stopColor="#FF2B49" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#FF2B49" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey={'chgbks_avg'} scale="band"
                       stroke={'rgba(255,255,255, .8)'}/>
                <YAxis stroke={'rgba(255,255,255, .8)'}/>
                {/*<Tooltip/>*/}
                <Legend stroke={`rgba(255, 255, 255, .8)`}/>
                <Line dataKey="chgbk_amt_avg_hist" dot={<CustomizedDot/>} name="Avg Chargebacks"
                      stroke="rgba(255,255,255, .8)"/>
                <Bar dataKey="chgbk_avg" name="Chargebacks" barSize={200} fill="url(#colorRed)">
                  <LabelList toCurrency={props.toCurrency}  dataKey="chgbk_avg" content={renderCustomizedLabel} id="4"/>
                </Bar>
                <ReferenceLine
                    y={data[0].chgbk_amt_avg_hist}
                    stroke="rgba(255,255,255, .8)"
                    strokeDasharray="3 3"
                    fill="rgba(255,255,255, .8)"
                    label={{
                      position: 'insideBottomRight',
                      value: `Historic Avg ${chgbkAvg}`,
                      fill: 'rgba(255,255,255, .8)',
                    }}
                />
              </ComposedChart>
            </ResponsiveContainer>
        )}
      </Observer>
  );
});

export const TotalsChart = observer((props) => {
    const data = props.data;
    const bars = [];

    bars.push({value: data[0].sales_avg, fill: 'url(#colorGreen)', name: 'Sales'});
    bars.push({value: data[0].decl_avg, fill: 'url(#colorBlue)', name: 'Declines'});
    bars.push({value: data[0].ref_avg, fill: 'url(#colorYellow)', name: 'Refunds'});
    bars.push({value: data[0].chgbk_avg, fill: 'url(#colorRed)', name: 'Chargebacks'});
    return (
        <Observer>
            {() => (
                <ResponsiveContainer height="100%" width="100%">
                    <BarChart
                        // width={1200}
                        // height={300}
                        data={bars}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#45E885" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#45E885" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="15%" stopColor="#fccc1a" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#fccc1a" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="15%" stopColor="#3fa9f5" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#3fa9f5" stopOpacity={0}/>
                            </linearGradient>
                          {/*  <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FF2B49" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#FF2B49" stopOpacity={0}/>
                            </linearGradient>*/}
                        </defs>
                        <XAxis dataKey="name" stroke={'rgba(255,255,255, .8)'}/>
                        <YAxis tickCount={8} stroke={'rgba(255,255,255, .8)'}/>
                        <Bar label="value" dataKey="value">
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`}/>
                            ))}
                        </Bar>
                        <ReferenceLine
                            alwaysShow={false}
                            y={data[0].sale_amt_avg_hist}
                            stroke="rgba(255,255,255, .8)"
                            strokeDasharray="3 3"
                            fill="rgba(255,255,255, .8)"
                            label={{
                                position: 'insideBottomRight',
                                value: 'Comparison to Historic Sales Avg '.concat(props.toCurrency(data[0].sale_amt_avg_hist, 2)),
                                fill: 'rgba(255,255,255, .8)',
                            }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Observer>
    );
});

export const Notes = observer((props) => {
  return (
      <Observer>
        {() => (
            <div className={'noteWrapper'} width="100%">
              <>
                <div className="header">NOTES:</div>
                <header className="noteHeader">ABOVE SECTION:</header>
                <div className="noteContent">
                  {' '}
                  * You want the current Sales avg to be equal to or above the historic
                  line...this shows growth.
                </div>
                <div className="noteContent">
                  {' '}
                  * You want the current Declines avg to be below the historic line to show that the avg ticket amount is going down.
                </div>
                {/* <div className="noteContent">sales is going down.</div> */}
                <div className="noteContent">
                  {' '}
                  * You want the current Refunds & Chargebacks avgs to be below the historic line
                  to show less money per ticket is going back to
                </div>
                <div className="noteContent">
                  {' '}
                  customers (this typically shows you have less problems & less fraud
                  from customers).
                  {' '}
                </div>
                  <span style={{paddingBottom: '5px'}}/>
                  <div>
                    {/* TODO Add one line of space here */}
                  </div>
                  <br/>
                <header className="noteHeader">BOTTOM-LEFT SECTION:</header>
                <div className="noteContent">
                  {' '}
                  * It is most common to see Declines, Refunds & Chargebacks with higher avgs than sales (this is not good).
                </div>
                <div className="noteContent">
                  {' '}
                  {/* - For Declines this means when the sales orders go above the avg txn amount then the
                  odds of the person */}
                  * When a transaction amount is higher than your sales avg, then the odds of inability to pay
                </div>
                <div className="noteContent">
                and/or fraud is increased.
                </div>
                {/* <div className="noteContent"> not being able to pay increases.</div> */}
                {/* <div className="noteContent">
                  {' '}
                  - For Voids, Inquiries & Declines this typically shows when sales are above the avg there
                  are higher likelihoods of
                </div> */}
                {/* <div className="noteContent"> issues and/or fraud by customers who take their money back.
                </div> */}
              </>
            </div >
        )}
      </Observer>
  );
});

  
