import React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { CardExp } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';

const cardExpired = observer(() => {
  const { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <div style={{ margin: '0 4px', height: '100%' }}>
              <CardExp />
            </div>
          ) : (
            <div>You do not have access to this report</div>
          )}
        </>
      )}
    </Observer>
  );
});

export default cardExpired;
