import React from 'react';
import {
  AuthnetApi,
  AuthnetScrape,
  CybersourceScrapeGw,
  CybersourceScrapeProc,
  NMIScrape,
  USAepayApi,
  USAepayScrape,
} from './Creds';
import { Observer } from 'mobx-react-lite';
import { useProps } from '../../resources';

export const Credentials = (props) => {
  const { gwProc } = useProps(props);
  // gwProc.addCredentials();
  return (
    <Observer>
      {() => (
        <>
          {gwProc.dataSourceID === 1 && gwProc.aggrID === 5 && <AuthnetScrape gwProc={gwProc} />}
          {gwProc.dataSourceID === 2 && gwProc.aggrID === 5 && <AuthnetApi gwProc={gwProc} />}
          {gwProc.dataSourceID === 1 && gwProc.aggrID === 14 && <CybersourceScrapeGw gwProc={gwProc} />}
          {gwProc.dataSourceID === 1 && gwProc.aggrID === 86 && <CybersourceScrapeProc gwProc={gwProc} />}
          {gwProc.dataSourceID === 1 && gwProc.aggrID === 64 && <USAepayScrape gwProc={gwProc} />}
          {gwProc.dataSourceID === 2 && gwProc.aggrID === 64 && <USAepayApi gwProc={gwProc} />}
          {gwProc.dataSourceID === 1 && gwProc.aggrID === 39 && <NMIScrape gwProc={gwProc} />}
          {/*{gwProc.dataSourceID === 1 && gwProc.aggrID === 154 && <EverestScrape gwProc={gwProc} />}*/}
        </>
      )}
    </Observer>
  );
};
