import { types, getRoot } from 'mobx-state-tree';
import Moment from 'moment';
import { _get } from 'utils/utils';

export const UtilsStore = types
  .model({})
  .views((self) => ({
    get currency() {
      return _get(self.user, 'lastClientViewed.genCurr.iso3');
    },
    get language() {
      return _get(self.user, 'langID');
    },
    get user() {
      let userStore = getRoot(self).userStore;
      return _get(userStore, 'user');
    },
    get userTz() {
      return _get(self.user, 'lastClientViewed.genTmz.tmzName', 'UTC');
    },
  }))
  .actions((self) => {
    function toDateFormatter(params) {
      if (params?.value) {
        return (
            Moment(params.value).utc()
                .format('MM/DD/YYYY')
        );
      }
    }
    function toDateFormatterHours(params) {
      return Moment(params.value).format('M/D/YYYY hh:mm:transactions a');
    }
    function toDateFormatterValue(value) {
      return Moment(value).utc().format('MM/DD/YYYY');
    }
    function toDateFormatterHoursValue(value) {
      return Moment(value).format('M/D/YYYY hh:mm:transactions a');
    }
    function toCurrencyFormatterWithUndefined(params, decimals, shorten) {
      if(params.value === null) return null;
      if (params.value === '') {
        return '';
      }
      let negative = !!(params.value < 0);
      let value = Math.abs(params.value);
      if (typeof value !== 'number') {
        return value;
      }
      let suffix = '';
      if (shorten) {
        if (value >= 1000 && value < 1000000) {
          suffix = 'K';
          value = value / 1000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        } else if (value >= 1000000 && value < 1000000000) {
          suffix = 'M';
          value = value / 1000000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        }
      }
      let formatter = new Intl.NumberFormat(self.language || 'eng', {
        style: 'currency',
        currency: self.currency || 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

      let formatted = formatter.format(value);
      if (shorten && suffix) {
        if (negative) {
          return `(${formatted}${suffix})`;
        }
        return `${formatted}${suffix}`;
      } else {
        if (negative) {
          return `(${formatted})`;
        }
        return formatted;
      }
    }
    function toCurrencyFormatter(params, decimals, shorten) {
      if (isNaN(parseFloat(params.value))) {
        return params.value;
      }
      let negative = !!(params.value < 0);
      let value = Math.abs(params.value);
      let suffix = '';
      if (shorten) {
        if (value >= 1000 && value < 1000000) {
          suffix = 'K';
          value = value / 1000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        } else if (value >= 1000000 && value < 1000000000) {
          suffix = 'M';
          value = value / 1000000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        }
      }
      let formatter = new Intl.NumberFormat(self.language || 'eng', {
        style: 'currency',
        currency: self.currency || 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

      let formatted = formatter.format(value);

      if (shorten && suffix) {
        if (negative) {
          return `(${formatted}${suffix})`;
        }
        return `${formatted}${suffix}`;
      } else {
        if (negative) {
          return `(${formatted})`;
        }
        return formatted;
      }
    }
    function toCurrency(value, decimals, shorten) {
      if (value === 0) {
        value = Math.abs(0);
      }
      if (typeof value !== 'number') {
        return value;
      }

      let suffix = '';

      if (shorten) {
        if (value >= 1000 && value < 1000000) {
          suffix = 'K';
          value = value / 1000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        } else if (value >= 1000000 && value < 1000000000) {
          suffix = 'M';
          value = value / 1000000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        }
      }

      let formatter = new Intl.NumberFormat(self.language || 'eng', {
        style: 'currency',
        currency: self.currency || 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

      let formatted = formatter.format(value);

      if (shorten && suffix) return `${formatted}${suffix}`;
      else return formatted;
    }
    function toFormatNumber(value, decimals, shorten) {
      if (value === 0) {
        value = Math.abs(0);
      }
      if (typeof value !== 'number') {
        return value;
      }

      let suffix = '';

      if (shorten) {
        if (value >= 1000 && value < 1000000) {
          suffix = 'K';
          value = value / 1000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        } else if (value >= 1000000 && value < 1000000000) {
          suffix = 'M';
          value = value / 1000000;
          if (Math.floor((value - Math.floor(value)) * 10)) decimals = 1;
          else decimals = 0;
        }
      }

      let formatter = new Intl.NumberFormat(self.language || 'eng', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      let formatted = formatter.format(value);

      if (shorten && suffix) return `${formatted}${suffix}`;
      else return formatted;
    }
    function toPercent(value, decimals, shorten) {
      if (typeof value !== 'number' && isNaN(parseFloat(value))) {
        return value;
      }else {
        value = parseFloat(value);
      }
      
      let formatter = new Intl.NumberFormat(self.language || 'eng', {
        style: 'percent',
        minimumFractionDigits: decimals || 2,
        maximumFractionDigits: decimals || 2,
      });

      return formatter.format(value);
    }
    function getTotalsPercentage(upper, lower) {
      let percentage = 0;
      percentage = upper && lower ? (upper / lower) * 100 : 0;
      return percentage.toFixed(2);
    }
    function formatNumber(value) {
      if (typeof value !== 'number') {
        return value;
      }
      let formatter = new Intl.NumberFormat(self.language || 'eng');
      return formatter.format(value);
    }
    return {
      formatNumber,
      getTotalsPercentage,
      toFormatNumber,
      toPercent,
      toCurrency,
      toCurrencyFormatter,
      toCurrencyFormatterWithUndefined,
      toDateFormatter,
      toDateFormatterHoursValue,
      toDateFormatterValue,
      toDateFormatterHours,
    };
  });

export default UtilsStore;
