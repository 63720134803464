import React, { useEffect } from "react";
import styled from 'styled-components/macro';
import { CheckIcon } from 'styles/styledComponents';
import { Button, LoadingSpinner } from 'components/UI';

const Modal = ({ isOpen, onClose, children }) => {
	useEffect(() => {
		document.body.style.overflow = isOpen ? "hidden" : "auto";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isOpen]);

	if (!isOpen) {
		return null;
	}

	return (
		<Wrapper>
			<div className="modal-overlay" onClick={onClose}>
				<div
					className="modal-content"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="modal-body">
						{children}
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.modal-content {
		color: black;
		background-color: black;
		padding: 20px;
		border-radius: 4px;
		min-height: 500px;
		max-height: 80vh;
		overflow-y: auto;
		position: relative;
		display: flex;
		justify-content: center;  
	}
`;

export default Modal;
