import React from 'react';
import styled from 'styled-components';

const Button = (props) => {
  return <ButtonContainer {...props}>{props.children}</ButtonContainer>;
};

const ButtonContainer = styled.div`
  box-shadow: ${({ theme }) => (theme ? theme.boxShadow : 'rgba(91, 91, 91, 0.5) 0px 2px 4px')};
  color: ${({ color, theme }) => color || `var(--font-color-${theme.theme})`};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  border-radius: 3px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize || '13px'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  border: ${({ theme, border, borderColor }) => {
    if (border) {
      if (borderColor) {
        return `1px solid ${borderColor}`;
      }
      return `1px solid ${theme.colors.primaryText}`;
    }
    return '';
  }};
  background-color: ${({ theme, type, color }) => {
    if (color) {
      return color;
    }
    switch (type) {
      case 'filter':
        return theme.buttonStyles.filter;
      case 'secondary':
        return theme.buttonStyles.secondaryHover;
      case 'delete':
        return theme.buttonStyles.deleteHover;
      case 'clear':
        return theme.buttonStyles.clear;
      case 'primary':
        return theme.buttonStyles.primary;
      default:
        return '';
    }
  }};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, type, colorHover }) => {
      if (colorHover) {
        return colorHover;
      }
      switch (type) {
        case 'secondary':
          return theme.buttonStyles.secondary;
        case 'delete':
          return theme.buttonStyles.delete;
        case 'clear':
          return theme.buttonStyles.clearHover;
        case 'primary':
          return theme.buttonStyles.primaryHover;
        default:
          return '';
      }
    }};
  }
`;

export default Button;
