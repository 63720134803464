import React, {useEffect, useState, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';

import {observer, Observer, useLocalStore} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { AgGridReact } from 'ag-grid-react';
import {_get, useGetWidgetData, useInjectStores} from 'utils/utils';
import {widgetService} from 'services';
import {LoadingSpinner} from 'components/UI';
import { CaretUpIcon } from 'styles/styledComponents';
import { ArrowDropDownIcon } from 'styles/styledComponents';


import {
    TableWrapper
} from "../../../styles/styledComponents";

const TrendsDetails = observer(() => {
    const {dataManagementStore, utilsStore} = useInjectStores();

    const store = useLocalStore(() => ({
        isLoading: false,
        currentYearWidgetData: [],
        previousYearWidgetData: [],
        currentYear: '',
        previousYear: '',
        error: {},
        setNewQueryTest(newQueryTest) {
            const {status, data, error} = newQueryTest;
            if (status === 'loading') this.isLoading = true;
            if (data) {
                this.currentYearWidgetData = formatData(data.values).filter((data) => data.yr_placement === 'Current');
                this.currentYear = this.currentYearWidgetData[0]?.rep_yr;
                this.previousYearWidgetData = formatData(data.values).filter((data) => data.yr_placement === 'Previous');
                this.previousYear = this.previousYearWidgetData[0]?.rep_yr;
                this.isLoading = false;
            }
            if (error) this.isLoading = false;
        },
    }));

    const newQueryTest = useGetWidgetData(
        {name: 'analyticsDetails2', query: widgetService.analytics2},
        'data',
        dataManagementStore?.newQuery
    );
    
    useEffect(() => {
        store.setNewQueryTest(newQueryTest);
    }, [newQueryTest]);

    const monthOrder = {
        "January": 1,
        "February": 2,
        "March": 3,
        "April": 4,
        "May": 5,
        "June": 6,
        "July": 7,
        "August": 8,
        "September": 9,
        "October": 10,
        "November": 11,
        "December": 12
    };

    const columnDefs1 = useMemo(() => [
        { headerName: 'Txn Category', field: 'txn_cat', rowGroup: true, pinned: 'left', cellClass: 'no-border'},
        { headerName: '', field: 'txn_subcat', rowGroup: true, pinned: 'left', valueFormatter: (params) => params.value.length >= 18 ? params.value.substring(0, 18) + '...' : params.value },
        {
            valueGetter: 'data.rep_mo_nm',
            pivot: true,
            keyCreator: function (params) {
                return params.value.charAt(0) + params.value.slice(1).toLowerCase() + store.currentYear;
            },
            pivotComparator: (valueA, valueB) => {
                return monthOrder[valueA] - monthOrder[valueB];
            }
        },
        { headerName: 'Count', field: 'txn_cnt', width: 70, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: (value) => utilsStore.formatNumber(value.value), cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Ratio', field: 'txn_ratio', width: 70, aggFunc: (params) => params.values.length ? params.values[0] : null, cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Uniq Card', field: 'txn_uniq_card', width: 75, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: (value) => utilsStore.formatNumber(value.value), cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Amount', field: 'txn_amount', width: 110, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: utilsStore.toCurrencyFormatterWithUndefined, cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Amount Avg', field: 'txn_amt_avg', width: 100, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: utilsStore.toCurrencyFormatterWithUndefined, cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} }
    ], []);

    const [columnDefs2, setColumnDefs2] = useState([
        { headerName: 'Txn Category', field: 'txn_cat', rowGroup: true, cellClass: 'no-border'},
        { headerName: '', field: 'txn_subcat', rowGroup: true, pinned: 'left', valueFormatter: (params) => params.value.length >= 18 ? params.value.substring(0, 18) + '...' : params.value },
        {
            valueGetter: 'data.rep_mo_nm',
            pivot: true,
            keyCreator: function (params) {
                return params.value.charAt(0) + params.value.slice(1).toLowerCase() + store.previousYear;
            },
            pivotComparator: (valueA, valueB) => {
                return monthOrder[valueA] - monthOrder[valueB];
            }
        },
        { headerName: 'Count', field: 'txn_cnt', width: 70, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: (value) => utilsStore.formatNumber(value.value), cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Ratio', field: 'txn_ratio', width: 70, aggFunc: (params) => params.values.length ? params.values[0] : null, cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Uniq Card', field: 'txn_uniq_card', width: 75, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: (value) => utilsStore.formatNumber(value.value), cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Amount', field: 'txn_amount', width: 110, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: utilsStore.toCurrencyFormatterWithUndefined, cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} },
        { headerName: 'Amount Avg', field: 'txn_amt_avg', width: 100, aggFunc: (params) => params.values.length ? params.values[0] : null, valueFormatter: utilsStore.toCurrencyFormatterWithUndefined, cellStyle: {display: 'flex', 'justify-content': 'end', margin: '0px 5px', padding:'0px 5px'} }
    ]);

    const defaultColDef = useMemo(() => {
        return {
            width: 110,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            filter: true,
            resizable: false,
            sortable: false,
            suppressMenu: true,
            cellClass: 'no-border'
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            cellRendererParams: (params) => {
                return {
                    suppressCount: true,
                    suppressAggFuncInHeader: true,
                    suppressDoubleClickExpand: true,
                    resizable: false,
                    suppressMenu: true
                };
            },
            pinned: 'left',
            resizable: false,
            suppressMenu: true
        };
    }, []);

    const gridOptions = useMemo(() => {
        return {
            suppressAggFuncInHeader: true,
            rowHeight: 33,
            getRowStyle: lastRowBorder,
            getRowClass: params => {
                if (params.node.key === 'TOTAL UNIQ TXNS') {
                    return 'last-row-total';
                }
            },
            allowContextMenuWithControlKey: false,
            suppressContextMenu:true,
            overlayNoRowsTemplate:
                '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">There is no data to show for the selected year</span>'
        
        };
    }, []);

    function lastRowBorder(params){
        if (params.node.key === 'TOTAL UNIQ TXNS'){
            return {borderTop: '1px solid white', '.ag-icon': { display: 'none !important' }};
        }
        else {
            return {};
        }
    }

    const formatData = (data) => {
        let currentCat = '';
        let currentCatIndex = 0;
        return data.map((data) => {
            if(data.txn_cat && data.txn_cat !== currentCat) {
                currentCat = data.txn_cat;
                currentCatIndex++;
            }
    
            return {
                ...data,
                txn_cat: currentCat
            }
        });
    };

    return (
        <Observer>
            {() => (
                <div>
                    <TableWrapper height={'87vh'}>
                        {store.isLoading ? <LoadingSpinner size={'200px'}/> : (
                            <>
                                <Table id="myGrid" className={'ag-theme-alpine-dark'}>
                                    <AgGridReact
                                        rowData={store.currentYearWidgetData}
                                        columnDefs={columnDefs1}
                                        defaultColDef={defaultColDef}
                                        autoGroupColumnDef={autoGroupColumnDef}
                                        pivotMode={true}
                                        groupHideOpenParents={true}
                                        groupDisplayType={'groupRows'}
                                        animateRows={true}
                                        gridOptions={gridOptions}
                                    />
                                </Table>
                                <Table id="myGrid" className={'ag-theme-alpine-dark'}>
                                    <AgGridReact
                                        rowData={store.previousYearWidgetData}
                                        columnDefs={columnDefs2}
                                        defaultColDef={defaultColDef}
                                        autoGroupColumnDef={autoGroupColumnDef}
                                        pivotMode={true}
                                        groupHideOpenParents={true}
                                        groupDisplayType={'groupRows'}
                                        animateRows={true}
                                        gridOptions={gridOptions}
                                    />
                                </Table>
                            </>
                        )}
                    </TableWrapper> 
                </div>
            )}
        </Observer>
    );
});


export default TrendsDetails;
const Table = styled.div`
    height: 100%;
    margin: 10px 0px;
    font-size: 13px;
    overflow: unset;
    .ag-root-wrapper {
        background-color:  #041c2f !important;
    }
    .ag-row {
        background-color:  #041c2f !important;
    }
    .ag-header {
        background-color:  #041c2f !important;
    }
    .ag-cell {
        border-right: solid 0px !important;
    }
    .ag-header-cell-label {
        flex: none !important;
    }
    .ag-header-group-cell-label {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .last-row-total {
        .ag-icon {
            display: none !important;
        }
    }
    .ag-pinned-left-header {
        border-right: solid 0px !important;
    }
    .ag-cell-focus,.ag-cell-no-focus{
        border:none !important;
    }
    .no-border.ag-cell:focus{
        border:none !important;
        outline: none;
    }
    .ag-header-cell {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
   
    .ag-cell {
        ${() => {
            const shouldStyle = (index) => {
                const relativeIndex = (index - 3) % 10;
                return relativeIndex >= 0 && relativeIndex < 5;
              };
            let styles = '';
            for (let i = 2; i <= 100; i++) {
              if (i === 2) {
                styles += `
                  &[aria-colindex="${i}"] {
                    left: 30px !important;
                    z-index: -10;
                  }
                `;
              } if (shouldStyle(i)) {
                styles += `
                  &[aria-colindex="${i}"] {
                    background-color: #032845;
                    z-index: ${-1000 - i};
                  }
                `;
              }
            }
            return styles;
          }}
    }
    .ag-cell-value {
        z-index: 10;
    }
    .ag-cell-wrapper.ag-row-group {
        align-items: center;
    }
    .ag-cell-value, .ag-group-value {
        align-items: center;
        overflow: unset;
        text-overflow: elipsis;
    }
`;

