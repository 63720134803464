function cellStyle(params) {
  if (params.value < 0) {
    return { color: '#FF2B49' };
  } else {
    // return { color: 'rgb(69, 232, 133)' };
  }
}

export function getColumnDefs(getData, toCurrencyFormatter) {
  return [
    { headerName: 'Statement Date', field: 'date', width: '140px', suppressMenu: true },
    {
      headerName: 'View Statement',
      field: 'date',
      width: '165px',
      cellRenderer(params) {
        const data = params.data,
          button = document.createElement('button');
        button.innerText = 'View';
        button.className = 'apply';
        button.addEventListener('click', async function () {
          await getData(data.mid, data.date, data.id, false);
        });
        return button;
      },
    },
    {
      headerName: 'Download Statement',
      field: 'date',
      width: '195px',
      cellRenderer(params) {
        const data = params.data,
          button = document.createElement('button');
        button.innerText = 'Download';
        button.className = 'apply';
        button.id = data.id;
        button.addEventListener('click', async function () {
          await getData(data.mid, data.date, data.id, true);
        });
        return button;
      },
    },
    {
      headerName: 'Amount Deducted',
      field: 'amt_deducted',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Amount Credited',
      field: 'amt_credited',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Disc Due',
      field: 'disc_due',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Disc Paid',
      field: 'disc_paid',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Fees Due',
      field: 'fees_due',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Fees Paid',
      field: 'fees_paid',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Min Disc Due',
      field: 'min_disc_due',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Net Disc Due',
      field: 'net_disc_due',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
    {
      headerName: 'Net Fees Due',
      field: 'net_fees_due',
      width: '145px',
      type: 'rightAligned',
      cellStyle,
      valueFormatter: toCurrencyFormatter,
    },
  ];
}

export function getGridOptions() {
  return {
    detailRowHeight: 70,
    defaultColDef: {
      sortable: false,
      resizable: true,
    },
    rowHeight: 33,
    headerHeight: 33,
    animateRows: true,
    enableBrowserTooltips: true,
    tooltipShowDelay: 0,
    reactNext: true,
    enableRangeSelection: true,
    groupMultiAutoColumn: true,
    suppressMenu: true,

    overlayLoadingTemplate:
      '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);"> Please wait while your rows are loading</span>',
    overlayNoRowsTemplate:
      '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
  };
}
