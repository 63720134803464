import React, { useEffect } from 'react';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import {
  TableHeader,
  TableWrapper
} from 'styles/styledComponents';
import { widgetService } from 'services';

const Notes = observer((props) => {
  return (
    <Observer>
      {() => (
        <TableWrapper padding={'20px'} height={'100%'}>
          <TableHeader>FEES NOTES:</TableHeader>
           <div>IMPORTANT:</div>
           <ol type={'A'}>
             <li style={{'padding': '3px 0'}}>
               The card brands (Visa, MasterCard, American Express, and Discover) make changes to authorization & settlement fees every April and October.  Usually, the card brands are increasing their fees or adding new fees; however, please note that they may raise fees or
               add new ones any time they choose.  If you are lucky, on rare occasions, fees are decreased.
             </li>
             <li style={{'padding': '3px 0'}}>
               Most fees set by the brands may not be lowered and are the same for all companies, regardless of industry or company size; thus, there is little play room on fees between processors and payments providers, regardless of what they say and/or advertise.  Most
               processors and payments providers will promise to lower a fee or two for you; but what they fail to disclose is that they will then raise rates on fees that you cannot see, audit, and/or reconcile.  They are infamous for hiding fees in a generic category called "Discount."
               There is a lot of misinformation and confusion surrounding the "Discount" fee category.
             </li>
             <li style={{'padding': '3px 0'}}>
               The brands and banks determine "comfort zone" tolerances for risk assessments; and processors, usually have little to no input or say in the matter. Thus, if your company creates risk for the industry you will most likely be assessed higher risk fees and/or reserves.
             </li>
           </ol>
          <br/>
          Fee Category Notes
          <ol>
            <li style={{'padding': '3px 0'}}>
              Most fees are collected daily, but some are end-of-month and are set that way by the industry.  Please beware: not all transactional fees are collected daily when doing balancing or reconciliation, there will be fees that don't get assessed until the end of the month;
              as a result, they will be deducted on the first available bank date following the 1st day of the new month.  Fees are also assessed at different times during the processing of a transaction (auth, settlement, deposit preparation, etc.)
            </li>
            <li style={{'padding': '3px 0'}}>
              Gateway fees are usually assessed and paid to third-party(ies) for POS equipment and/or e-commerce gateways. When gateways do not provide their fee data then we are unable to report that information.
            </li>
            <li style={{'padding': '3px 0'}}>
              Authorization and settlement fees are transactional and include dozens and dozens of fees, most of them required and collected by Visa, MasterCard, American Express, & Discover through categories they call Interchange, Card Brand Fees, and Discount.  The majority
              of these fees stay with Visa, MasterCard, American Express, and Discover…while a small portion of these fees are shared with acquiring and issuing banks; platforms; and others.
            </li>
            <li style={{'padding': '3px 0'}}>
              Risk fees (which may include reserves) are created by your ratio of chargebacks and/or refunds that are out of the industry's "comfort zone."   These fees may be lowered or removed, all together, if or when you cure and/or lower your chargebacks and refunds.
            </li>
            <li style={{'padding': '3px 0'}}>
              Chargeback penalties apply to all retrievals, chargebacks, and chargeback reversals.  These are occurrence based fees and are only assessed when your customer applies for a "chargeback" type transaction.  These should not be used when comparing merchant fees.
            </li>
            <li style={{'padding': '3px 0'}}>
              Monthly fees are non-transactional and are fees that are created by the industry to cover the costs of you having a merchant account.  These fees include service costs, card brand requirements, 1099 and tax reporting, regulatory, PCI compliance, platform statement
              generation, reporting and online access, etc.  * As our client, our fees for EIGHT technology/software are included here.
            </li>
            <li style={{'padding': '3px 0'}}>
              Equipment fees only exist for finance, lease, rental or supplies if you did not out-right purchase your POS equipment.
            </li>
          </ol>
          <div><span style={{color: 'red'}}>*</span> We get asked frequently, which card type is better to take…Debit or Credit?  This usually depends on the dollar amount of the transaction (called the "ticket").
            Debit cards usually have lower basis point rates and higher fees than credit cards.
            So if you have a low ticket transaction, credit cards are usually better than debit cards; and the inverse is true for higher ticket transactions.
          </div>
        </TableWrapper>
      )}
    </Observer>
  );
});

export default Notes;
