import React from 'react';
import {Observer} from 'mobx-react-lite';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import {useInjectStores} from 'utils/utils';

const TxnAmounts = ({
                        widgetData: {
                            auth_amt,
                            batch_num,
                            batch_seq,
                            batch_total,
                            cashback_amt,
                            curr_cd,
                            deposit_reject_amt,
                            freight_amt,
                            gw_batch_num,
                            gw_batch_seq,
                            prod_svc_amt,
                            sttl_amt,
                            submitted_amt,
                            surcharge_fee,
                            surcharge_fee_offset_amt,
                            surcharge_rate,
                            tax_amt,
                            tip_amt,
                            variance_disc_amt,
                        },
                    }) => {
    const {utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency;
    return (
        <Observer>
            {() => (
                <div style={{display: 'flex', flexDirection: 'column', minHeight: '800px', boxShadow: '0 0 4px rgba(91,91,91,.5)', width: '33.33%', margin: '0 0 4px 0'}}>
                    <TableWrapper height={''} margin={'2px'} padding="0 10px">
                        <TableHeader>TRANSACTION AMOUNTS</TableHeader>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">Gateway Batch Number</TableCell>
                                        <TableCell>{gw_batch_num}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Gateway Batch Sequence</TableCell>
                                        <TableCell>{gw_batch_seq}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Processor Batch Num</TableCell>
                                        <TableCell>{batch_num}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Processor Batch Sequence</TableCell>
                                        <TableCell>{batch_seq}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Batch Total</TableCell>
                                        <TableCell>{batch_total}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <TableWrapper height={''} margin={'2px'} padding="0 10px">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">Currency Code</TableCell>
                                        <TableCell>{curr_cd}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Authorized Amount</TableCell>
                                        <TableCell>{auth_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Submitted Amount</TableCell>
                                        <TableCell>{submitted_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Deposit Reject Amount</TableCell>
                                        <TableCell>{deposit_reject_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Settled Amount</TableCell>
                                        <TableCell>{sttl_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Product/Service Amount</TableCell>
                                        <TableCell>{prod_svc_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Variance/Discount Amount</TableCell>
                                        <TableCell>{variance_disc_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Tip Amount</TableCell>
                                        <TableCell>{tip_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Freight Amount</TableCell>
                                        <TableCell>{freight_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Tax Amount</TableCell>
                                        <TableCell>{tax_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Cashback Amount</TableCell>
                                        <TableCell>{cashback_amt}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <TableWrapper  height={'100%'} margin={'2px'} padding="0 10px">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">Surcharge Fee Offset Amount</TableCell>
                                        <TableCell>{surcharge_fee_offset_amt}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Surcharge Rate</TableCell>
                                        <TableCell>{surcharge_rate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Surcharge Fee</TableCell>
                                        <TableCell>{surcharge_fee}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                </div>
            )}
        </Observer>
    );
};

export default TxnAmounts;
