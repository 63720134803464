import React from 'react';
import { MultiSelectDropdown, Input } from 'components/UI';
import styled from 'styled-components/macro';
import { observer, Observer } from 'mobx-react-lite';

const CommonFilter = observer((props) => {
  const custName = props.commonFilterProps.custName,
    email = props.commonFilterProps.email,
    ccBin = props.commonFilterProps.ccBin,
    ccLast4 = props.commonFilterProps.ccLast4,
    midNum = props.commonFilterProps.midNum;
  return (
    <Observer>
      {() => (
        <Wrapper>
          <FilterTitle>Common Filters</FilterTitle>
          <div className="filterBody">
            <div className={'fieldContainer'}>
              {custName.get && <label>Cust Name</label>}
              <Input
                placeholder="Cust Name"
                value={custName.get}
                onChange={custName.set}
                margin={'3px 0 3px 0'}
                border
                tabIndex={1}
              />
            </div>
            <div className={'fieldContainer'}>
              {email.get && <label>Email</label>}
              <Input placeholder="Email" value={email.get || ''} onChange={email.set} margin={'0 0 3px 0'} border tabIndex={2} />
            </div>
            <div className={'fieldContainer'}>
              {ccBin.get && <label>CC Bin</label>}
              <Input
                placeholder="CC Bin"
                onChange={ccBin.set}
                value={ccBin.get || ''}
                onBlur={ccBin.blur}
                error={ccBin.error}
                margin={'0 0 3px 0'}
                border
                tabIndex={3}
              />
              {ccBin.error && <div>must be a number</div>}
            </div>
            <div className={'fieldContainer'}>
              {ccLast4.get && <label>CC Last 4</label>}
              <Input
                placeholder={`CC Last 4`}
                value={ccLast4.get || ''}
                onChange={ccLast4.set}
                onBlur={ccLast4.blur}
                error={ccLast4.error}
                margin={'0 0 3px 0'}
                border
                tabIndex={4}
              />
              {ccLast4.error && <div>must be a number</div>}
            </div>
            {/* <div className="fieldContainer">
          {ccBrandID.get && <label>CC Brand ID</label>}
          <Dropdown
            fontSize={'14px'}
            placeholder="CC Brand ID"
            options={ccBrandID.options || []}
            value={ccBrandID.get || ''}
            onChange={ccBrandID.set}
            margin="0 0px 3px 0px"
            border
            tabIndex={5}
          />
        </div>*/}
            <div className="fieldContainer">
              <MultiSelectDropdown
                border
                height={'35px'}
                optContTop={'25px'}
                optionPadding="0px"
                left={'9px'}
                onChange={midNum.set}
                options={midNum.options}
                placeholder={'Select MIDs'}
                margin="0 0px 3px 0px"
                tabIndex={6}
              />
            </div>
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  width: 100%;
  color: ${({ theme }) => theme.primaryColor};
  .filterBody {
    padding-right: 2px;
    .fieldContainer {
      outline: none;
      display: flex;
      flex-direction: column;
      margin: 0 1px 0 3px;

      label {
        font-size: 11px;
        margin-left: 2px;
        padding: 3px 0 1px 0;
      }
    }
  }
`;
const FilterTitle = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 7px 0;
  //border: 1px solid #fff;
  font-weight: bold;
  //   border-left: 2px solid #fff;
  background-color: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
`;

export default CommonFilter;
