import React, { useEffect } from 'react';
import { DeclineTotals, ReturnedSalesTotals, SalesTotals } from './TotalsWidgets';
import styled from 'styled-components/macro';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { useGetWidgetData, useInjectStores } from 'utils/utils';
import { widgetService } from 'services';

const SsTotals = observer(() => {
  const { dataManagementStore, uiStore,utilsStore} = useInjectStores();

  const showSidebar = uiStore.showSidebar;
  const store = useLocalObservable(() => ({
    widgetData: null,
    loading: false,
    error: null,
    toCurrency: utilsStore.toCurrency,
    setWidgetData(widgetData) {
      this.error = null;
      const { status, data, error } = widgetData;
      if (status === 'loading') this.loading = true;
      if (error) {
        this.error = error;
        this.loading = false;
      }
      if (data) {
        this.widgetData = data.values[0];
        this.loading = false;
      }
    }
  }));

  const fetchTotals = useGetWidgetData(
    { name: 'dashboardTotals', query: widgetService.dashboardTotals },
    'data',
    dataManagementStore?.newQuery
  );

  useEffect(() => {
    store.setWidgetData(fetchTotals);
  }, [fetchTotals]);

  return (
    <Wrapper moveLeft={!showSidebar}>
      <div className="totalsWrapper">
        <Observer>
          {() => (
            <>
              <SalesTotals className="sales" store={store}/>
              <DeclineTotals className="declines" store={store}/>
              <ReturnedSalesTotals className="returned" store={store}/>
              {/*{store.reservesTotals > 0 && <ReservesTotals className="reserves" reserves={store.re} />}*/}
            </>
          )}
        </Observer>
      </div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  color: ${({ theme }) => theme.colors.primaryText};
  grid-area: SsTotals;
  .totalsWrapper {
    height: 100%;
    display: grid;
    .sales {
      grid-area: sales;
    }
    .declines {
      grid-area: declines;
    }
    .returned {
      grid-area: returned;
    }
    .reserves {
      grid-area: reserves;
    }
    grid-template-areas: ${({ showReserves }) => (showReserves ? 'sales declines returned reserves' : 'sales declines returned')};
    grid-template-columns: ${({ showReserves }) => (showReserves ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};
    grid-template-rows: 60px;
    grid-gap: 5px;

    @media (max-width: 830px) {
      grid-template-rows: 60px 60px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: ${({ showReserves }) =>
        showReserves ? `'sales declines' 'returned reserves'` : `'sales declines' 'returned  .'`};
    }

    @media (max-width: 576px) {
      grid-template-rows:${({ showReserves }) => (showReserves ? 'repeat(4, 60px)' : 'repeat(3, 60px)')};
      grid-template-columns: 1fr;
      grid-template-areas:${({ showReserves }) =>
        showReserves
          ? `
        'sales'
        'declines'
        'returned'
        'reserves'`
          : `
        'sales'
        'declines'
        'returned'`}
    }
`;

export default SsTotals;
