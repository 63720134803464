import React, { useEffect, useState } from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Route, useRouteMatch } from 'react-router-dom';
import { Tabs } from './Tabs';
import RptNoShipTotals from './RptNoShipTotals/RptNoShipTotals';
import { RptNoShipTxns, RptShipTxns } from '../../../components/Widgets';
import styled from 'styled-components/macro';
import RptShipTotals from './RptShipTotals/RptShipTotals';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';

const Execs = observer(() => {
  const [tab, setTab] = useState('noShip');
  const { path } = useRouteMatch();
  const { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <div style={{ height: '100%', width: '100%' }}>
              <StyledWrapper tab={tab}>
                {/*<div className="tabs">*/}
                {/*  <Tabs setTab={setTab} tab={tab} />*/}
                {/*</div>*/}
                <Route path={`/report/fulfillment/noship`}>
                  <RptNoShipTotals />
                  <RptNoShipTxns />
                </Route>

                <Route path={`/report/fulfillment/ship`}>
                  <RptShipTotals />
                  <RptShipTxns />
                </Route>
              </StyledWrapper>
            </div>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  //height: 100%;
  width: calc(100% - 7px);
  height: 100%;
  display: grid;
  margin: 0 4px 4px 4px;
  padding-bottom: 189px;
  overflow: hidden;
  .tabs {
    grid-area: tabs;
    width: 100%;
    height: 100%;
  }
  .totals {
    grid-area: totals;
    height: 100%;
  }
  .details {
    grid-area: details;
    //height: calc(100% - 20px);
  }

  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  grid-template-areas:
    'totals'
    'details';
  .widget {
    margin: 0 3px;
  }

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;

    grid-template-areas: 'one two three';
    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }
    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;
      &:hover {
        // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }
      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
`;

export default Execs;
