import React, {useState, useMemo} from 'react';
import {Observer, observer} from 'mobx-react-lite';
import {_get, useInjectStores, useGetWidgetData} from 'utils/utils';
import {LoadingSpinner} from 'components/UI';
import {Wrapper} from 'styles/styledComponents';
import styled from 'styled-components/macro';
import {widgetService} from 'services';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from "react-router-dom";

const MyReports = observer(() => {
    const {dataManagementStore} = useInjectStores();
    const [error, setError] = useState('');
    const history = useHistory();

    const userReports = useGetWidgetData(
        {name: 'fetchUserReports', query: widgetService.userReports},
        'data',
        dataManagementStore?.newQuery
    );

    const fetchAllReports = useGetWidgetData(
        {name: 'fetchAllUserReports', query: widgetService.allUserReports},
        'data',
        dataManagementStore?.newQuery
    );

    const myReports = _get(userReports, 'data.results', []);
    const allReports = _get(fetchAllReports, 'data.results', []);

	const loading = userReports.status === 'loading' || fetchAllReports.status === 'loading';

    const columnDefs = [
        {
            headerName: '',
            field: '',
            filter: false,
            pinned: 'left',
            width: 100,
            style: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
            cellRenderer: ({data}) => {
                const runBtn = document.createElement('button');
                runBtn.innerText = 'Run';
                runBtn.className = 'apply';
                runBtn.addEventListener('click', async () => {
                    history.push(`/report/${data.rep_sname}`);
                });

                const addBtn = document.createElement('button');
                addBtn.innerText = 'Add';
                addBtn.className = 'apply';
                addBtn.style.backgroundColor = 'transparent';
                addBtn.addEventListener('click', async () => {
                    history.push(`/admin/pricing`);
                });

                if(myReports.some((rep) => rep.rep_sname === data.rep_sname)) {
                    return runBtn;
                } else {
                    return addBtn;
                }
            }
        },
        {field: 'rep_sname', headerName: 'Report'},
        {field: 'rep_full_nm', headerName: 'Report Full Name', flex: 1},
        {field: 'rep_desc', headerName: 'Description', flex: 3  }
    ];

    return (
        <Observer>
            {() => (
                <Wrapper>
                    {loading ? (
                        <LoadingSpinner size={'50vh'} />
                    ) : error ? (
                        <div>there was an error</div>
                    ) :
                    <GridWrapper>
                        <div
                            id="myGrid"
                            className={'ag-theme-alpine-dark'}
                            style={{
                                height: '100%',
                                minHeight: '95vh',
                                width: '100%',
                                position: 'relative',
                                borderRadius: '5px',
                            }}
                        >
                            <AgGridReact
                                rowData={allReports}
                                columnDefs={columnDefs}
                            />
                        </div>
                    </GridWrapper>
                    }
                </Wrapper>
            )}
        </Observer>
    );
});

const GridWrapper = styled.div`
  height: 100%;
  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    .ag-header-row {
      height: 30px;
    }
    .ag-cell {
      font-size: 13px;
    }
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }
    .topLevel {
      background-color: #001329 !important;
    }
    .secondLevel {
      background-color: #032845 !important;
    }
  }
  .apply {
    background-color: #3fa9f5;
    color: rgba(255, 255, 255);
    border-radius: 3px;
    border: none;
    width: 60px;
    outline: none;
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.baseColors.colorBlueHover};
    }
  }
`;


export default MyReports;
