import React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import RptReconForecast from '../../components/Widgets/RptReconForecast/RptReconForecast';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';

const Execs = observer(() => {
  const { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => <>{loading ? <RouteLoadingSpinner size={'400px'} /> : authorized ? <RptReconForecast /> : <div>error</div>}</>}
    </Observer>
  );
});
export default Execs;
