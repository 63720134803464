import React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { IsoPassThroughLosses, IsoPassThroughLossesSummary } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';
import {Wrapper} from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Route, Link, useRouteMatch, useLocation } from 'react-router-dom';

const passThroughLosses = observer(() => {
  let { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <PageWrapper style={{ margin: '0 4px', height: '98%' }}>
              <div className="tabs">
                <Tabs />
              </div>
              <Route exact path={`/iso/pass-through-losses`}>
                <IsoPassThroughLossesSummary/>
              </Route>
              <Route exact path={`/iso/pass-through-losses/details`}>
                <IsoPassThroughLosses/>
              </Route>
            </PageWrapper>
          ) : (
            <Wrapper style={{width: '99%', margin: '0px 5px'}}>
                <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    You are not authorized to view this page.
                </div>
            </Wrapper>
          )}
        </>
      )}
    </Observer>
  );
});

const Tabs = () => {
  const location = useLocation();
  return (
      <div className="tabWrapper">
          <Link className={`tab one ${location.pathname === '/iso/pass-through-losses' ? 'active' : ''}`} to={{pathname: '/iso/pass-through-losses'}} >
              Summary
          </Link>
          <Link className={`tab two ${location.pathname === '/iso/pass-through-losses/details' ? 'active' : ''}`}  to={{pathname: '/iso/pass-through-losses/details'}}>
              Details
          </Link>
      </div>
  );
};

const PageWrapper = styled.div`
  .tabWrapper {
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;
    margin-bottom: 4px;

    grid-template-areas: 'one two';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }

    .test {
      margin-left: 5px;
    }
  }

`;

export default passThroughLosses;
