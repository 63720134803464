import React, {useEffect} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useInjectStores, convertToTitleCase, buildColDef} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table} from 'components/UI';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Wrapper} from 'styles/styledComponents';
import {widgetService} from 'services';
import {getFileName} from 'utils/utils';

const CardExpRpt = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, userStore, utilsStore} = useInjectStores();

    const widgetModel = widgetStore.cardExpDetails,
        paginatorModel = widgetModel.paginatorModel;

    const orderByOptions = [
        {key: 1, text: 'Cust Name', value: 'custName'},
        {key: 2, text: 'Invoice Num', value: 'invNum'},
        {key: 3, text: 'Decl Rsn', value: 'respCodeSname'},
        {key: 4, text: 'Txn Date', value: 'txnDt'},
    ];
    const availableReports = _get(userStore, 'user.currentClient.permissions.reps_allowed_sname', '').split(',');
    let authorized = false;
    if(availableReports.includes(location.pathname.split('/').at(-1))) {
        authorized = true;
    }
    
    const store = useLocalObservable(() => ({
        fileName: 'cardExpReport',
        loading: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        rowData: [],
        txnCount: 0,
        columnDefs: [],
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get dropdownOptions() {
            /*if (this.procHistory?.length > 0) {
              return this.procHistory.map(({txn_dt_eom}, index) => {
                return {
                  key: index,
                  text: `${Moment(txn_dt_eom).format('YYYY')}: ${Moment(txn_dt_eom).format('MMMM')}`,
                  value: txn_dt_eom
                };
              });
            }*/
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                groupMultiAutoColumn: true,
                rowHeight: 33,
                headerHeight: 33,
                reactNext: true,
                autoSizeStrategy: {
                    type: 'fitCellContents'
                },
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: false,
                    filter: true,
                    resizable: true,
                    width: 'auto'
                },
                columnDefs: store.columnDefs,
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                const widths = [130, 160, 170, 140, 100, 160, 160];
                store.columnDefs = data?.data?.values.length ? Object.keys(data.data.values[0]).map((key, i) => {
                    return buildColDef(utilsStore, key, widths[i]);
                }) : [];
                store.fileName = getFileName(userStore, data.config, 'cardExpReport');
                this.rowData = data.data.values.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = data.data.count;
                this.loading = false;
            }
        },
        async getData() {
            this.loading = true;
            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }
                const res = await widgetModel.fetchWidgetData(widgetService.reportCardExpired);
                this.widgetData = res.data.data;
                this.rowData = this.widgetData.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = this.widgetData.length;
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));

    const txnsData = authorized ? useQuery(
        ['expiredCard', widgetModel.query],
        !!dataManagementStore.newQuery ? () => widgetModel.fetchWidgetData(widgetService.reportCardExpired) : () => {
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!dataManagementStore.newQuery
        }
    ): null;

    useEffect(() => {
        if(txnsData) {
            store.setRowData(txnsData);
        }
    }, [txnsData]);

    useEffect(() => {
        paginatorModel.setPageNumber(1);
    }, [dataManagementStore.newQuery]);

  return (
    <Observer>
      {() => (
        <>
          {!authorized ? (
            <Wrapper>
                <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    You are not authorized to view this report.
                </div>
            </Wrapper>
          ) :
          store.loading ? (
            <Wrapper>
              <LoadingSpinner size={'200px'} />
            </Wrapper>
          ) : store.error ? (
              <div>there was an error</div>
            ) : // <Error error={store.error} />
            store.rowData ? (
              <Table
                store={store}
                fetchTableData={store.getData}
                rowData={store.rowData}
                loading={store.loading}
                /**export**/
                initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                exportInfo={store.widgetModel.exportInfo}
                /** Paginator-Specific Functions **/
                txnCount={store.rowData.length}
              paginator={paginatorModel}
              /** OrderBy Properties **/
              addOrderBy={store.widgetModel.addOrderBy}
              orderByArray={store.widgetModel.orderByModel}
              orderByOptions={store.orderByOptions}
              resetOrderBy={store.widgetModel.resetOrderBy}
            />
          ) : null}
        </>
      )}
    </Observer>
  );
});

export default CardExpRpt;
