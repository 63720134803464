import { Observer, observer } from 'mobx-react-lite';
import { Button, Input } from 'components/UI';
import React from 'react';
import styled from 'styled-components/macro';
import { ClearIcon, Grid } from 'styles/styledComponents';
import { MidAggrForms } from './ProcGw';
import { SubmitButton } from '../../styles';

const labelWidth = '210px';

export const Mid = observer((props) => {
  return (
    <Observer>
      {() => (
        <div>
          <br />
          <div />
          <div className="header-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div
              className="form-header"
              style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              Details
            </div>
            <div
              className="form-header"
              style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Button
                type="delete"
                width="100px"
                padding="0 8px"
                height="36px"
                onClick={() => props.clientModel.remove(props.mid)}
              >
                Remove MID
              </Button>
            </div>
          </div>
          <div style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
            <Grid width={'1040px'} cmin={'350px'} cmax={'1fr'}>
              <Observer className="midNum">
                {() => (
                  <Input
                    tabIndex={18}
                    placeholder="Set MID Number"
                    value={props.mid.mid}
                    onChange={props.mid.setMidNum}
                    required
                    label="MID Number"
                    labelWidth={labelWidth}
                    disabled={!props.editMid}
                  />
                )}
              </Observer>
              <Observer className="startDt">
                {() => (
                  <Input
                    tabIndex={19}
                    placeholder="Set Start Date"
                    // TODO figure out which ones don't have a procLimit
                    // TODO figure out if we are tracking proclimit (changes not an array right now)
                    value={props.mid.procLimit[0].limitStartDate}
                    onChange={props.mid.procLimit[0].setLimitStartDate}
                    required
                    label="MID Start Date"
                    labelWidth={labelWidth}
                  />
                )}
              </Observer>
              <Observer className="midMoLimit">
                {() => (
                  <Input
                    tabIndex={20}
                    placeholder="Set MID Mo Limit"
                    value={props.mid.procLimit[0].monthlyProcLimit}
                    onChange={props.mid.procLimit[0].setMonthlyProcLimit}
                    required
                    label="MID Mo Limit"
                    labelWidth={labelWidth}
                  />
                )}
              </Observer>
            </Grid>
          </div>
          <br />
          <div />
          <div className="header-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div
              className="form-header"
              style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <div style={{ marginBottom: 4 }}>Processors</div>
              <SubmitButton width="100px" className="submit" onClick={() => props.mid.addProcessor()}>
                Add Processor
              </SubmitButton>
            </div>
          </div>
          {props.mid.processors.map((proc, index) => (
            <div key={index} style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
              <MidAggrForms
                isProc={true}
                store={props.store}
                gwProc={proc}
                // gwOptions={props.store.gwOptions}
                // procOptions={props.store.procOptions}
                // datasourceOptions={props.store.datasourceOptions}
              >
                <Button
                  type="delete"
                  width="30px"
                  height="34px"
                  style={{ position: 'absolute', left: '-35px' }}
                  onClick={() => props.mid.remove(proc)}
                >
                  <ClearIcon
                    id="filterBtn"
                    color="#fff"
                    style={{ width: 30, height: 30 }}
                    onClick={() => props.mid.remove(proc)}
                  />
                </Button>
              </MidAggrForms>
            </div>
          ))}
          <div
            className="header-wrapper"
            style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div
              className="form-header"
              style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <div style={{ marginBottom: 4 }}>Gateways</div>
              <SubmitButton width="100px" className="submit" onClick={() => props.mid.addGateway()}>
                Add Gateway
              </SubmitButton>
            </div>
          </div>

          {props.mid.gateways.map((gw, index) => (
            <div key={index} style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
              <MidAggrForms gwProc={gw} store={props.store}>
                <Button
                  type="delete"
                  style={{ position: 'absolute', left: '-35px' }}
                  width="30px"
                  height="34px"
                  onClick={() => props.mid.remove(gw)}
                >
                  <ClearIcon id="filterBtn" color="#fff" style={{ width: 30, height: 30 }} onClick={() => props.mid.remove(gw)} />
                </Button>
              </MidAggrForms>
            </div>
          ))}
          <div style={{ borderTop: '1px solid rgba(255,255,255, .2', margin: '10px 0' }} />
        </div>
      )}
    </Observer>
  );
});

export const ProcGateway = styled.div`
  display: flex;
  justify-content: center;
  max-width: 600px;
  .divider {
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.text}`};
  }
`;
