import { flow, getRoot, getSnapshot, types } from 'mobx-state-tree';
import { CreateUserForm, UpdateUserForm } from './Forms';
import { _each, _get, _includes, _toNumber } from 'utils/utils';
import { commonService, adminService } from 'services';
import axios from 'axios';

const { optional } = types;

export const UserStore = types
  .model({
    createUserStore: optional(CreateUserForm, () => CreateUserForm.create()),
    updateUserStore: optional(UpdateUserForm, () => UpdateUserForm.create()),
    sessionTokenKey: 'eygSessionToken',
  })
  .volatile((self) => ({
    initialState: null,
    localStorage: null,
    user: null,
    dataType: window.sessionStorage.getItem('dataType') ?? 'current',
    userMetaData: {
      userID: null,
      toPath: undefined,
    },
  }))
  .views((self) => ({
    get rootStore() {
      return getRoot(self);
    },
    get dataManagementStore() {
      return self.rootStore.dataManagementStore;
    },
    get uiStore() {
      return self.rootStore.uiStore;
    },
    get socketStore() {
      return self.rootStore.socketStore;
    },
    get currentRoute() {
      return self.rootStore.currentRoute;
    },
    get lastClientViewedID() {
      return _get(self, 'user.lastClientViewedID');
    },
    /**Permissions**/
    get isDemo() {
      return self.user.isDemo;
    },
    get bottomSideNavModules() {
      return _get(self, 'user.views.bottomSideNav');
    },
    get topSideNavModules() {
      return _get(self, 'user.views.topSideNav.modules');
    },
    get topSideNavDashboards() {
      return _get(self, 'user.views.topSideNav.dashboards');
    },
    get userRoutes() {
      let userViews = [];
      let views = _get(self, 'user.views');
      _each(_get(views, 'topSideNav.modules'), (module) => {
        _each(module.views, (view) => {
          userViews.push(view.path);
        });
      });
      _each(_get(views, 'topSideNav.dashboards'), (view) => {
        userViews.push(view.path);
      });
      _each(_get(views, 'bottomSideNav'), (view) => {
        userViews.push(view.path);
      });
      _each(_get(views, 'other'), (view) => {
        return userViews.push(view.path);
      });
      return userViews;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    setUserMetaData(userID, toPath) {
      self.userMetaData.userID = userID;
      self.userMetaData.toPath = toPath;
    },
    checkUserRoutes() {
      return !!_includes(self.userRoutes, self.currentRoute);
    },
    setLocalStorageItem(window, key, value) {
      if (window && key) {
        return window.localStorage.setItem(key, value);
      }
    },
    removeLocalStorageItem(window, key) {
      if (window && key) {
        return window.localStorage.removeItem(key);
      }
    },
    getLocalStorageItem(window, key) {
      if (window && key) {
        return window.localStorage.getItem(key);
      }
    },
    setUser(user) {
      self.user = user;
    },
    setDataType(dataType) {
      self.dataType = dataType;
    },
    setLocalStorageRef(localStorage) {
      self.localStorage = localStorage;
    },
    updateUser: flow(function* updateUser(put, history, location) {
      try {
        let response = yield commonService.updateUser(put);
        self.user = response.data.data;
        if (self.user.token) {
          return response.data.data;
        }
      } catch (error) {
        console.error('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          self.logout(history, location);
        }
      }
    }),
    setSelectedClient: flow(function* setSelectedClient(clientID, history, location) {
      try {
        let post = { id: _get(self, 'user.id'), clientID };

        let response = yield commonService.changeClient(post);
        let token = _get(response, 'config.headers.authorization');
        //TODO update user replace with new endpoint
        self.user = response.data;
        if (token) {
          // let initialRoute = _get(self, 'user.views.topSideNav.dashboards[0].id');
          // let path = `/dashboard`;
          history.push(`/dashboard`);
          self.uiStore.setCurrentModule('dashboard');
          if (location.pathname.includes('/dashboard')) {
            let response = yield commonService.routeAuthCheck();
            if (!_get(response, 'data.data.pass')) {
              let userID = _get(self.user, 'id');
              self.setUserMetaData(userID, response.data.data.toPath);
              self.logout(history, location);
            }
          }
          self.rootStore.dataManagementStore.setNewQuery();
          self.uiStore.setChangingClient();
          return response.data;
        }
      } catch (error) {
        console.error('error', error);
        self.uiStore.setChangingClient();
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          self.logout(history, location);
        }
      }
    }),
    fetchUserData: flow(function* fetchUserData() {
      try {
        const token = window.localStorage.getItem('eygSessionToken');
        axios.defaults.headers.common['authorization'] = token;
        const response = yield commonService.fetchUserData();
        let user = response.data;
        if (user) {
          self.user = user;
          return user;
        }
      } catch (error) {
        console.error('error', error);
        self.logout();
      }
    }),
    fetchPricingData: flow(function* fetchPricingData() {
      try {
        const response = yield adminService.adminGetPricing({clientID: self.user.currentClient.id})
        let pricing = response.data;
        if (pricing) {
          self.user.pricing = pricing; 
          return pricing;
        }
      } catch (error) {
        console.error('error', error);
      }
    }),
    logout: flow(function* logout() {
      // log the user out of the database if there is one
      if (self.user) {
        try {
          yield commonService.logout({
            id: self.user.id,
          });
        } catch (error) {
          console.error('error', error);
        }
      }
      axios.defaults.headers.common['authorization'] = null;
      window.localStorage.removeItem('eygSessionToken');
      window.sessionStorage.removeItem('dataType');
      self.user = null;
      // self.rootStore.socketStore.closeSocket();
      self.rootStore.resetState();
    }),
  }));

export default UserStore;
