import React, {useEffect, useState} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {_get, useInjectStores, getFileName} from 'utils/utils';
import {Dropdown, LoadingSpinner, Table} from 'components/UI';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Wrapper} from 'styles/styledComponents';
import {widgetService} from 'services';

const TxnByBatchNumber = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {rootStore, dataManagementStore, widgetStore, utilsStore, userStore} = useInjectStores();

    const widgetModel = widgetStore.transactionsByBatchNum,
        paginatorModel = widgetModel.paginatorModel;

    const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
    const toDateFormatter = utilsStore.toDateFormatter;
    const orderByOptions = [
        {key: 1, text: 'Customer ID', value: 'customer_id'},
        {key: 2, text: 'Customer Name', value: 'customer_name'}
    ];

    const availableReports = _get(userStore, 'user.currentClient.permissions.reps_allowed_sname', '').split(',');
    let authorized = false;
    if(availableReports.includes(location.pathname.split('/').at(-1))) {
        authorized = true;
    }

    const store = useLocalObservable(() => ({
        fileName: 'txn-by-batch-number-report',
        loading: false,
        api: null,
        gridColumnApi: null,
        orderByOptions,
        dataManagementStore,
        widgetModel,
        paginatorModel,
        rowData: [],
        txnCount: 0,
        batchNum: '',
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        exportType: 'excel',
        setExportType: (type) => {
            this.exportType = type;
        },
        get gridOptions() {
            return {
                onGridReady: (params) => {
                    this.api = params.api;
                    this.gridColumnApi = params.columnApi;
                },
                enableCharts: true,
                enableRangeSelection: true,
                rowHeight: 33,
                headerHeight: 33,
                sideBar: {
                    position: 'left',
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                        },
                    ],
                },
                defaultColDef: {
                    sortable: true,
                    filter: true,
                    resizable: true
                },
                columnDefs: [
					{
                        headerName: 'Row',
                        field: 'Index',
                        pinned: 'left',
                        checkboxSelection: true,
                        filter: false,
                        width: 85,
                        headerTooltip: 'Index',
                        suppressMenu: true
                    },
					{headerName: 'Batch Num', field: 'batch_num', width: 150, headerTooltip: 'Batch Number'},
                    {headerName: 'Sub Num', field: 'batch_subnum', width: 90, headerTooltip: 'Batch Sub Number'},
                    {headerName: 'Seq Num', field: 'batch_seq_num', width: 90, headerTooltip: 'Batch Seq Number'},
                    {headerName: 'Total Amt', field: 'batch_total_amt', width: 105,headerTooltip: 'Total Amount', valueFormatter: toCurrencyFormatter, cellStyle: {textAlign: 'right'}},
					{headerName: 'Auth Amt', field: 'auth_amount', width: 95, headerTooltip: 'Auth Amount', valueFormatter: toCurrencyFormatter, cellStyle: {textAlign: 'right'}},
                    {headerName: 'Rejected Amt', field: 'reject_amount', width: 95,headerTooltip: 'Rejected Amount', valueFormatter: toCurrencyFormatter, cellStyle: {textAlign: 'right'}},
                    {headerName: 'Settled Amt', field: 'settled_amount', width: 95,headerTooltip: 'Settled Amount', valueFormatter: toCurrencyFormatter, cellStyle: {textAlign: 'right'}},
                    {headerName: 'Txn Dep Status', field: 'txn_dep_status', width: 140, headerTooltip: 'Txn Dep Status'},
					{headerName: 'Txn Date', field: 'txn_date', width: 115, valueFormatter: toDateFormatter, suppressMenu: true, headerTooltip: 'Txn Date'},
					{headerName: 'Settled Date', field: 'sttl_date', width: 115, valueFormatter: toDateFormatter, suppressMenu: true, headerTooltip: 'Settled Date'},
                    {headerName: 'Txn Type', field: 'txn_type', width: 115, headerTooltip: 'Txn Type'},
                    {headerName: 'Txn Status', field: 'txn_status', width: 125, headerTooltip: 'Txn Status'},
                    {headerName: 'CC Number Concat', field: 'cc_num_concat', width: 175, headerTooltip: 'CC Number Concat'},
                    {headerName: 'Txn ID', field: 'txn_id', width: 160, headerTooltip: 'Txn ID'},
					{headerName: 'Customer ID', field: 'customer_id', width: 135, headerTooltip: 'Customer ID'},
					{headerName: 'Customer Name', field: 'customer_name', width: 160, headerTooltip: 'Customer Name'},
					{headerName: 'Gateway Name', field: 'gateway_name', width: 150, headerTooltip: 'Gateway Name'},
					{headerName: 'Gateway Batch Num', field: 'gw_batch_num', width: 125, headerTooltip: 'Gateway Batch Number'},
					{headerName: 'Gateway Batch Seq Num', field: 'gw_batch_seq_num', width: 125, headerTooltip: 'Gateway Batch Seq Number'}
                ],
                overlayLoadingTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
                overlayNoRowsTemplate:
                    '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please enter a valid batch number</span>',
            };
        },

        setRowData(txnsData) {
            const {status, data, error} = txnsData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                this.rowData = data.data.results.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = data.data.count;
                this.loading = false;
            }
        },
        async getData() {
            this.loading = true;
            rootStore.filterStore.transactionFilter.setTxnBatchNumber(this.batchNum);
            
            try {
                if (this.api) {
                    this.api.showLoadingOverlay();
                }
                const res = await widgetModel.fetchWidgetData(widgetService.reportTxnByBatchNum);
                this.widgetData = res.data.results;
                this.fileName = getFileName(userStore, res.config, `txn-by-batch-${this.batchNum}`);
                this.rowData = this.widgetData.map((txn, index) => {
                    txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
                    return {
                        ...txn
                    };
                });
                this.txnCount = res.data.count;
                
            } catch (error) {
                console.error('error', error);
                this.widgetData = [];
                if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                    rootStore.userStore.logout(history, location);
                }
            }
            this.loading = false;
            if (this.api && !this.loading) {
                this.api.hideOverlay();
            }
            if (this.api && !this.loading && this.rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
        },
    }));
    
    useEffect(() => {
        paginatorModel.setPageNumber(1);
    }, [dataManagementStore.newQuery]);

  return (
    <Observer>
      {() => (
        <>
          {!authorized ? (
                <Wrapper>
                    <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        You are not authorized to view this report.
                    </div>
                </Wrapper>
            ) :
            store.loading ? (
            <Wrapper>
              <LoadingSpinner size={'200px'} />
            </Wrapper>
          ) : store.error ? (
              <div>there was an error</div>
            ) : // <Error error={store.error} />
            store.rowData ? (
              <Table
				store={store}
				fetchTableData={store.getData}
				rowData={store.rowData}
				loading={store.loading}
				/**export**/
				initiateTxnsExport={store.widgetModel.initiateTxnsExport}
				exportInfo={store.widgetModel.exportInfo}
				/** Paginator-Specific Functions **/
				txnCount={store.rowData.length}
				paginator={paginatorModel}
                enableBrowserTooltips={true}
				/** OrderBy Properties **/
				addOrderBy={store.widgetModel.addOrderBy}
				orderByArray={store.widgetModel.orderByModel}
				orderByOptions={store.orderByOptions}
				resetOrderBy={store.widgetModel.resetOrderBy}
                hasSearch={true}
                searchAction={store.getData}
            ></Table>
          ) : null}
        </>
      )}
    </Observer>
  );
});

export default TxnByBatchNumber;
