import { types, getSnapshot } from 'mobx-state-tree';
import { ActionListModel, CommonWidgetModel, TableWidgetModel } from './WidgetModels';
import {widgetService} from "../services";
const { optional } = types;

const WidgetStore = types
  .model({
    actionListModel: optional(ActionListModel, () => ActionListModel.create()),
    commonWidgetModel: optional(CommonWidgetModel, () => CommonWidgetModel.create()),
    rptNoShipTxnsWidget: optional(TableWidgetModel, () =>
      TableWidgetModel.create({
        widgetID: 53,
        widgetName: 'accSnsNsDetails',
        report: 'noShipTxns',
      })
    ),
    rptShipTxnsWidget: optional(TableWidgetModel, () =>
      TableWidgetModel.create({
        widgetID: 54,
        widgetName: 'accSnsSDetails',
        report: 'shipTxns',
      })
    ),
    ssChgbkTxnsWidgetModel: optional(TableWidgetModel, () =>
      TableWidgetModel.create({ widgetID: 47, widgetName: 'g8ChrgbkDetails' })
    ),
    ssDeclTxnsWidgetModel: optional(TableWidgetModel, () =>
      TableWidgetModel.create({ widgetID: 44, widgetName: 'g8DeclDetails' })
    ),
    ssOppTxnsWidgetModel: optional(TableWidgetModel, () => TableWidgetModel.create({getDetails: widgetService.opportunityDetails})),
    ssTxnsWidgetModel: optional(TableWidgetModel, () => TableWidgetModel.create({getDetails: widgetService.sales2})),
    cardExpDetails: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportCardExpired })),
    cardExpHistDetails: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportCardExpired, isHistoric: true })),
    transactionsByBatchNum: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportTxnByBatchNum })),
    chargebackAbuse: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportChargebackAbuse })),
    refundAbuse: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportRefundAbuse })),
    avsFailure: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportAvsFailure })),
    lostStolen: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportLostStolen })),
    invalidFraud: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportInvalidFraud })),
    prepaidCard: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportPrepaidCard })),
    ofacBanks: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportOfacBanks })),
    suspendedBanks: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportSuspendedBanks })),
    binAttackFraud: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportBinAttackFraud })),
    declineAnalysis: optional(TableWidgetModel, () => TableWidgetModel.create({ getDetails: widgetService.reportDeclineAnalysis })),
  })
  .volatile((self) => ({
    initialState: {},
  }))
  .actions((self) => ({
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
  }));

export default WidgetStore;
