import React, { Fragment, useEffect } from 'react';
import {
  HeaderCell,
  MsgBox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper
} from 'styles/styledComponents';
import { initialData } from './resources';
import { LoadingSpinner } from 'components/UI';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { _get } from 'utils/utils';
import { StoreContext } from 'stores/RootStore';
import { useHistory, useLocation } from 'react-router-dom';
import ReconForecastModal from '../WidgetComponentsOther/RptReconForecastModal/ReconForecastModal';
import { widgetService } from 'services';

const widgetConstants = { name: 'rptReconForecast', id: 17 };

const RptReconForecast = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { id, name } = widgetConstants;
  const rootStore = React.useContext(StoreContext);

  const dataManagementStore = rootStore.dataManagementStore,
    toCurrency = rootStore.utilsStore.toCurrency,
    uiStore = rootStore.uiStore;
  const store = useLocalStore(() => ({
    loading: false,
    widgetData: initialData,
    toCurrency,
    async getData() {
      store.loading = true;
      try {
        // const res = await dataManagementStore.fetchWidgetData(id);
        const res = await widgetService.transactionRecon;
        store.widgetData = res.data.data.widgets[name].data;
      } catch (error) {
        console.log('err', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          rootStore.userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
  }));

  useEffect(() =>
    autorun(async () => {
      if (dataManagementStore.newQuery) {
        await store.getData();
      }
    })
  );

  return (
    <Observer>
      {() => (
        <Fragment>
          <TableWrapper
            hover
            flexGrow={'0'}
            onClick={uiStore.toggleReconForecastModal}
            style={{ cursor: 'pointer', margin: '0px 5px 5px', width: '50%' }}
          >
            <TableHeader>Reconciliation Forecast Report</TableHeader>
            {store.loading && <LoadingSpinner size={'200px'} />}
            {!store.loading && store.widgetData && (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <HeaderCell></HeaderCell>
                      <HeaderCell>Actual</HeaderCell>
                      <HeaderCell>Forecasted</HeaderCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pres. Sales</TableCell>
                      <TableCell>{toCurrency(store.widgetData.teaserData.actualData.presSales)}</TableCell>
                      <TableCell>{toCurrency(store.widgetData.teaserData.forecastData.presSales)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Unsuc. Sales</TableCell>
                      <TableCell>{toCurrency(store.widgetData.teaserData.actualData.unsuccSales)}</TableCell>
                      <TableCell>{toCurrency(store.widgetData.teaserData.forecastData.unsuccSales)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Unsuc. Returns</TableCell>
                      <TableCell>{toCurrency(store.widgetData.teaserData.actualData.unsuccReturns * -1)}</TableCell>
                      <TableCell>{toCurrency(store.widgetData.teaserData.forecastData.unsuccReturns * -1)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell showTotalsLine>Net Dep. (BYBF)</TableCell>
                      <TableCell showTotalsLine>{toCurrency(store.widgetData.teaserData.actualData.netDep)}</TableCell>
                      <TableCell showTotalsLine>{toCurrency(store.widgetData.teaserData.forecastData.netDep)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!store.loading && !store.widgetData && (
              <MsgBox>
                <div>No Data Available For This DateRange </div>
                <div />
                <br />
              </MsgBox>
            )}
          </TableWrapper>

          {uiStore.showRptReconForecastModal && (
            <ReconForecastModal
              showReconForecastModal={uiStore.showRptReconForecastModal}
              toggleReconForecastModal={uiStore.toggleReconForecastModal}
              store={store}
              width={'90vw'}
            />
          )}
        </Fragment>
      )}
    </Observer>
  );
});

export default RptReconForecast;
