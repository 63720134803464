import React, { Fragment, useEffect, useState } from 'react';
import { _get, _includes, _toPairs, useGetWidgetData, useInjectStores } from 'utils/utils';
import { autorun } from 'mobx';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { Button, LoadingSpinner } from 'components/UI';
import Moment from 'moment';
import {
  ClearIcon,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { widgetService } from 'services';
import styled from 'styled-components/macro';

const Details = observer((props) => {
  const { dataManagementStore, utilsStore, filterStore } = useInjectStores();
  const [currYear, setCurrYear] = useState('');
  const [prevYear, setPrevYear] = useState('');
  const formatNumber = utilsStore.formatNumber,
    toCurrency = utilsStore.toCurrency,
    getTotalsPercentage = utilsStore.getTotalsPercentage;
  const declineFilter = filterStore.declineFilter;

  const store = useLocalObservable(() => ({
    isLoading: false,
    widgetData1: null,
    widgetData2: null,
    error: {},

    get previousYear() {
      let sorted = [];
      let previous = this.widgetData2.map((month) => {
        if (month.year_type === 'Previous') {
          setPrevYear(Moment(month.txn_dt_eom).format('YYYY'));
          sorted.push(month);
        }
      });
      return sorted;
    },
    get currentYear() {
      let sorted = [];
      const monthsArr = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const placeholderObj = {
        chgbk_net_sttl_amt: 0,
        chgbk_net_sttl_cnt: 0,
        chgbk_sttl_ratio_amt: '',
        chgbk_sttl_ratio_cnt: '',
        chgbk_sttl_stat_amt: '',
        chgbk_sttl_stat_cnt: '',
        month_nm: '',
        txn_dt_eom: '',
        year_type: '',
      };
      let previous = this.widgetData1.map((month) => {
        if (month.year_type === 'Current') {
          setCurrYear(Moment(month.txn_dt_eom).format('YYYY'));
          sorted.push(month);
        }
      });
      for (let i = sorted.length + 1; i <= 12; i++) {
        const tmp = { ...placeholderObj };
        tmp.month_nm = monthsArr[i - 1];
        sorted.push(tmp);
      }
      return sorted;
    },
    setNewQueryTest(newQueryTest) {
      const { status, data, error } = newQueryTest;
      if (status === 'loading') this.isLoading = true;
      if (data) {
        this.widgetData1 = data.curr;
        this.widgetData2 = data.prev;
        this.isLoading = false;
      }
      if (error) this.isLoading = false;
    },
    formatNumber,
    toCurrency,
    getTotalsPercentage,
  }));

  const newQueryTest = useGetWidgetData(
    { name: 'summaryFees', query: widgetService.chargebacks3 },
    'data',
    dataManagementStore?.newQuery
  );

  useEffect(() => {
    store.setNewQueryTest(newQueryTest);
  }, [newQueryTest]);

  return (
    <Observer>
      {() => (
        <div>
          <TableWrapper background={'noBackground'} height={'auto'}>
            <br />
            <TableHeader>CURRENT YEAR - {currYear}</TableHeader>
            {store.isLoading && <LoadingSpinner size={'200px'} />}
            <TableContainer display={'flex'} width={'auto'} margin="0 10px">
              {store.widgetData1?.length > 0 &&
                store.currentYear.map((month) => (
                  <div
                    css={`
                      display: flex;
                      flex-direction: column;
                      margin: 0 5px;
                    `}
                  >
                    <TableHeader>{month.month_nm}</TableHeader>
                    <Table>
                      <TableBody>
                        {!store.isLoading && (
                          <>
                            <TableRow>
                              <HeaderCell borderBottom={'2px solid rgba(255,255,255, .6) !important'}>Count</HeaderCell>
                              <HeaderCell borderBottom={'2px solid rgba(255,255,255, .6) !important'}>Amount</HeaderCell>
                            </TableRow>
                            <TableRow key={`1`}>
                              <TableCell>{month.chgbk_net_sttl_cnt}</TableCell>
                              <TableCell>{toCurrency(month.chgbk_net_sttl_amt)}</TableCell>
                            </TableRow>
                            <TableRow key={`2`}>
                              <TableCell>{month.chgbk_sttl_ratio_cnt || 0}</TableCell>
                              <TableCell>{toCurrency(month.chgbk_sttl_ratio_amt) || 0}</TableCell>
                            </TableRow>
                            <TableRow key={`3`}>
                              <TableCell statusColor={month.chgbk_sttl_stat_cnt}>{month.chgbk_sttl_stat_cnt}</TableCell>
                              <TableCell statusColor={month.chgbk_sttl_stat_amt}>{month.chgbk_sttl_stat_amt}</TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ))}
              {store.widgetData1?.length < 1 && <div className="noData">No data for this time period</div>}
            </TableContainer>
          </TableWrapper>
          <div style={{ paddingTop: '2rem' }}></div>
          <TableWrapper background={'noBackground'} height={'auto'}>
            <TableHeader>{store.isLoading ? `PREVIOUS YEAR - ` : `PREVIOUS YEAR - ${prevYear || currYear - 1}`}</TableHeader>
            {store.isLoading && <LoadingSpinner size={'200px'} />}
            <TableContainer display={'flex'} width={'auto'} margin="0 10px">
              {store.widgetData2?.length > 0 &&
                store.previousYear.map((month) => (
                  <div
                    css={`
                      display: flex;
                      flex-direction: column;
                      margin: 0 5px;
                    `}
                  >
                    <TableHeader>{month.month_nm}</TableHeader>
                    <Table>
                      <TableBody>
                        {!store.isLoading && (
                          <>
                            <TableRow>
                              <HeaderCell borderBottom={'2px solid rgba(255,255,255, .6) !important'}>Count</HeaderCell>
                              <HeaderCell borderBottom={'2px solid rgba(255,255,255, .6) !important'}>Amount</HeaderCell>
                            </TableRow>
                            <TableRow key={`1`}>
                              <TableCell>{month.chgbk_net_sttl_cnt}</TableCell>
                              <TableCell>{toCurrency(month.chgbk_net_sttl_amt)}</TableCell>
                            </TableRow>
                            <TableRow key={`2`}>
                              <TableCell>{month.chgbk_sttl_ratio_cnt || 0}</TableCell>
                              <TableCell>{toCurrency(month.chgbk_sttl_ratio_amt) || 0}</TableCell>
                            </TableRow>
                            <TableRow key={`3`}>
                              <TableCell statusColor={month.chgbk_sttl_stat_cnt}>{month.chgbk_sttl_stat_cnt}</TableCell>
                              <TableCell statusColor={month.chgbk_sttl_stat_amt}>{month.chgbk_sttl_stat_amt}</TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ))}
              {store.widgetData2?.length < 1 && <div className="noData">No data for this time period</div>}
            </TableContainer>
          </TableWrapper>
        </div>
      )}
    </Observer>
  );
});

export default Details;
const StyledNote = styled.div`
  //display: flex;
  //flex-direction: column;
  height: 100%;
  //justify-content: flex-end;
  align-items: center;
  //font-size: 10px;
  padding: 0 10px;
  font-size: 12px;

  .note {
    font-size: 12px;
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    text-decoration: underline;
    // text-underline: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }

  .noData {
    margin: auto;
  }

  .noteBody {
    font-size: 12px;
    //font-style: italic;
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;
