import React from 'react';
import { WidgetBackground } from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

export const Tabs = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  return (
    <div className="tabWrapper">
      <Link className={`tab one ${location.pathname.includes('no') ? 'active' : ''}`} to={`${url}/noship`}>
        No Ship
      </Link>

      <Link className={`tab two  ${!location.pathname.includes('no') ? 'active' : ''}`} to={`${url}/ship`}>
        Ship
      </Link>
      <div className="three">
        {location.pathname.includes('noShip') && (
          <WidgetBackground>
            <StyledNote>
              <span>
                <span className="note">* </span>
                <span className="noteBody">
                  If an order in your fulfillment system is not listed below, then it indicates that no attempt was made in the
                  credit card industry for payment.
                </span>
              </span>
            </StyledNote>
          </WidgetBackground>
        )}
      </div>
    </div>
  );
};

const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  padding: 0 10px;
  .note {
    color: red;
  }
  .noteBody {
    font-style: italic;
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;
