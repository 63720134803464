import React, { Fragment } from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { StoreContext } from '../../../../../stores/RootStore';
import { ChevronRightIcon } from 'styles/styledComponents';
import {
  ModalTable,
  ModalTableContainer,
  ShipTableHeader,
  ModalTHead,
  ChevronTH,
  TxnTypeTR,
  CategoryTR,
  ReasonTR,
  TableTotalsTR,
} from '../StyledShipNoShipModalTableResources';

const RptShipTotals = observer(
  ({ filterTableRows, formattedShipData, height, toggleShipTxnsDisplay, shipCount, shipTotalAmount }) => {
    const rootStore = React.useContext(StoreContext),
      toCurrency = rootStore.utilsStore.toCurrency;

    return (
      <Observer>
        {() => (
          <Fragment>
            <ShipTableHeader>Yes - Ok to Ship</ShipTableHeader>
            <ModalTableContainer>
              <ModalTable>
                <ModalTHead>
                  <tr>
                    <ChevronTH />
                    <th>Txn Type</th>
                    <th>Category</th>
                    <th>Reason</th>
                    <th>Count</th>
                    <th>Amount</th>
                  </tr>
                </ModalTHead>
                <tbody>
                  {formattedShipData &&
                    filterTableRows(formattedShipData).map((row, i) => {
                      switch (row.type) {
                        case 'txnType':
                          return (
                            <TxnTypeTR key={i}>
                              <td onClick={() => toggleShipTxnsDisplay(row)}>
                                <ChevronRightIcon
                                  height={height}
                                  open={
                                    (row.type === 'txnType' && row.displayCategories) ||
                                    (row.type === 'category' && row.displayReasons) ||
                                    (row.type === 'reason' && row.displayCategories && row.displayReasons)
                                  }
                                />
                              </td>
                              <td>{row.type === 'txnType' ? row.txnType : ''}</td>
                              <td>{row.type === 'category' ? row.category : ''}</td>
                              <td>{row.type === 'reason' ? row.declineReason : ''}</td>
                              <td>{row.count}</td>
                              <td>{toCurrency(row.total)}</td>
                            </TxnTypeTR>
                          );
                        case 'category':
                          return (
                            <CategoryTR>
                              <td onClick={() => toggleShipTxnsDisplay(row)}>
                                <ChevronRightIcon
                                  height={height}
                                  open={
                                    (row.type === 'txnType' && row.displayCategories) ||
                                    (row.type === 'category' && row.displayReasons) ||
                                    (row.type === 'reason' && row.displayCategories && row.displayReasons)
                                  }
                                />
                              </td>
                              <td>{row.type === 'txnType' ? row.txnType : ''}</td>
                              <td>{row.type === 'category' ? row.category : ''}</td>
                              <td>{row.type === 'reason' ? row.declineReason : ''}</td>
                              <td>{row.count}</td>
                              <td>{toCurrency(row.total)}</td>
                            </CategoryTR>
                          );
                        default:
                          return (
                            <ReasonTR>
                              <td />
                              <td>{row.type === 'txnType' ? row.txnType : ''}</td>
                              <td>{row.type === 'category' ? row.category : ''}</td>
                              <td>{row.type === 'reason' ? row.declineReason : ''}</td>
                              <td>{row.count}</td>
                              <td>{toCurrency(row.total)}</td>
                            </ReasonTR>
                          );
                      }
                    })}

                  <TableTotalsTR>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Totals</td>
                    <td>{shipCount}</td>
                    <td>{shipTotalAmount}</td>
                  </TableTotalsTR>
                </tbody>
              </ModalTable>
            </ModalTableContainer>
          </Fragment>
        )}
      </Observer>
    );
  }
);

export default RptShipTotals;
