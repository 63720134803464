import React from 'react';
import {TableWrapper, TableContainer, TableHeader} from 'styles/styledComponents';
import styled from 'styled-components/macro';

const PleaseNote = () => {
    return (
        <Wrapper>
            <TableWrapper width="100%">
                <TableHeader color={'#FF2B49'}>NOTES</TableHeader>
                <TableContainer>
                    <div className="note " style={{padding: '0 15px'}}>
                        <div
                            css={`
                              grid-area: one;
                              display: flex;
                              gap: 2px;
                            `}
                            className="one spacing"
                        >
                          <span>1.</span><p style={{marginTop: '0px'}}>BEWARE: Reattempt/duplicate and test transactions are included in the journal entries because the CC industry treats them like any other transaction with fees assessments and potential money movements. The app identifies "reattempt" and "test" transactions to help you; ultimately, it is your responsibility to decide how your business accounts for these transactions.</p>
                        </div>
                        <div
                            css={`
                              grid-area: two;
                              display: flex;
                              gap: 2px;
                            `}
                            className="two spacing"
                        >
                            <span>2. </span><p style={{marginTop: '0px'}}>Journal entries will change over time based on a couple of factors:
                            authorization vs settlement dates of the transaction,
                            availability of the data provided by the CC industry, & delays of completing a transaction
                            by the merchant and/or the industry.</p>
                        </div>
                        <div
                            css={`
                              grid-area: three;
                              display: flex;
                              gap: 2px;
                            `}
                            className="three spacing"
                        >
                            <span>3. </span>
                            <p style={{marginTop: '0px'}}>
                            <span>Eight provides true accrual accounting (following the matching principles) & transactions are dated and booked according to GAAP & SEC rules.
                                Due to timing and previous period adjustments, you may need to make adjusting entries to match the timing of our provided journals.</span>
                            {/* <span>Due to #1, you willsneed to look at prior periods, as past </span> */}
                            <span></span>
                            </p>
                        </div>
                        <div
                            css={`
                              grid-area: four;
                              display: flex;
                              gap: 2px;
                            `}
                            className="four spacing"
                        >
                        <span>4. </span><p style={{marginTop: '0px'}}>There are 3 parts to each transaction: the transaction itself, fee assessments, and the movement of funds. These happen in different processes over multiple days.</p>
                            {/* <span> </span>
              <span> Date the fees are assessed on a transaction</span>
              <span>Date the funds for a txn are deposited less fees</span> */}
                        </div>
                        <div
                            css={`
                              grid-area: five;
                              display: flex;
                              gap: 2px;
                            `}
                            className="five spacing"
                        >
                            <span>5. </span><p style={{marginTop: '0px'}}>Based on #3, Eight provides 3 separate accrual journals. This allows for
                            easier reconciliation for
                            those of you using third-party accounting software tied to the authorization data coming
                            from gateways and/or POS terminals; as their limited access to data precludes them from all necessary data, fees, and timing variances.</p>
                        </div>
                        <div
                            css={`
                              grid-area: six;
                              display: flex;
                              gap: 2px;
                            `}
                            className="six spacing"
                        >
                            <span>6. </span>
                            <p style={{marginTop: '0px'}}>
                            Eight does not account for inventory or COGS; as we do not have access to your CRM or order
                            taking systems.
                            </p>
                        </div>
                        <div
                            css={`
                              grid-area: seven;
                            `}
                            className="seven"
                        >
                            <u>PLEASE BE ADVISED</u>
                            <div className="spacing">
                                It is your obligation to follow ASC 606 rules for revenue recognition, consult with your
                                CPA:
                            </div>
                            <ol type="A">
                                <li>Designate the contract</li>
                                <li>Determine the performance obligations - sale of goods and/or services</li>
                                <li>Identify the Price</li>
                                <li>Align the price with the performance obligations</li>
                                <li>Follow revenue recognition when performance obligations have been met</li>
                            </ol>
                        </div>
                    </div>
                    <br/>
                </TableContainer>
            </TableWrapper>
        </Wrapper>
    );
};

export default PleaseNote;

const Wrapper = styled.div`
  height: auto;

  .note {
    display: grid;
    grid-template-areas:
      'one two three'
      'four five six'
      'seven seven seven';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 15px 5px;
    font-size: 13px;

    .spacing {
      line-height: 2em;
    }
  }
`;
