import React, { useEffect } from 'react';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { _get, _has, _set, useGetWidgetData, useInjectStores } from 'utils/utils';
import { widgetService } from 'services';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components/macro';
import { WidgetHeader, WidgetWrapper } from 'styles/styledComponents';
import { LoadingSpinner } from 'components/UI';

const RptNoShipTotals = observer((props) => {
  const { dataManagementStore, utilsStore } = useInjectStores();
  const toCurrencyFormatter = utilsStore.toCurrencyFormatter;

  const store = useLocalObservable(() => ({
    isLoading: false,
    api: null,
    gridColumnApi: null,
    rowData: [],
    get customRowData() {
      const dictionary = {};
      let id_index = 1;
      if (this.rowData) {
        let testData = _get(this, 'rowData', []);

        for (let i = 0; i < testData.length; i++) {
          let top_lvl_key = testData[i].txn_stat;
          let second_lvl_key = testData[i].category;
          let third_lvl_key = testData[i].category_rsn;
          let currentI = testData[i];
          if (!_has(dictionary, top_lvl_key)) {
            _set(dictionary, top_lvl_key, {
              totals_top_lvl: {
                topLevel: true,
                id: (id_index += 1),
                keyPath: [top_lvl_key],
                txn_stat: currentI.txn_stat,
                category: '',
                category_rsn: '',
                auth_amt: currentI.auth_amt,
                count: currentI.count,
              },
            });
          } else {
            //ADD TO TOP LVL TOTALS
            dictionary[top_lvl_key].totals_top_lvl = {
              ...dictionary[top_lvl_key].totals_top_lvl,
              auth_amt: (dictionary[top_lvl_key].totals_top_lvl.auth_amt += currentI.auth_amt),
              count: (dictionary[top_lvl_key].totals_top_lvl.count += currentI.count),
            };
          }
          if (!_has(dictionary, second_lvl_key)) {
            _set(dictionary, second_lvl_key, {
              totals_second_lvl: {
                secondLevel: true,
                id: (id_index += 1),
                keyPath: [top_lvl_key, second_lvl_key],
                category: currentI.category,
                txn_stat: '',
                category_rsn: '',
                auth_amt: currentI.auth_amt,
                count: currentI.count,
              },
              second_lvl_sub_arr: [
                {
                  id: (id_index += 1),
                  keyPath: [top_lvl_key, second_lvl_key, third_lvl_key],
                  ...testData[i],
                  txn_stat: '',
                  category: '',
                },
              ],
            });
            dictionary[second_lvl_key].totals_second_lvl.category_rsn = third_lvl_key;
          } else {
            // ADD TO 2ND LVL TOTALS
            dictionary[second_lvl_key].totals_second_lvl = {
              ...dictionary[second_lvl_key].totals_second_lvl,
              txn_stat: '',
              category_rsn: '',
              auth_amt: (dictionary[second_lvl_key].totals_second_lvl.auth_amt += currentI.auth_amt),
              count: (dictionary[second_lvl_key].totals_second_lvl.count += currentI.count),
            };
            // ADD TO CATEGORY RSN ARRAY
            dictionary[second_lvl_key].second_lvl_sub_arr.push({
              id: (id_index += 1),
              keyPath: [top_lvl_key, second_lvl_key, third_lvl_key],
              ...testData[i],
              txn_stat: '',
              category: '',
            });
            dictionary[
              second_lvl_key
            ].totals_second_lvl.category_rsn = `Multiple (${dictionary[second_lvl_key].second_lvl_sub_arr.length})`;
          }
          id_index += 1;
        }
        let toReturn = [];
        for (const key in dictionary) {
          let topLvl = _get(dictionary[key], 'totals_top_lvl');
          if (topLvl) toReturn.push(topLvl);
          let secondLvl = _get(dictionary[key], 'totals_second_lvl');
          if (secondLvl) toReturn.push(secondLvl);
          let subLvl = _get(dictionary[key], 'second_lvl_sub_arr');
          if (subLvl) toReturn.push(...subLvl);
        }
        return toReturn;
      }
      return [];
    },
    get gridOptions() {
      return {
        animateRows: true,
        cellRendererParams: {
          suppressCount: true, // turn off the row count
        },
        groupSuppressAutoColumn: true,
        groupMultiAutoColumn: true,
        rowHeight: 33,
        headerHeight: 33,
        getDataPath(data) {
          return data.keyPath;
        },
        getRowNodeId(data) {
          return data.id;
        },
        onGridReady(params) {
          this.api = params.api;
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
        columnDefs: [
          {
            headerName: 'Txn Stat',
            field: 'txn_stat',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              suppressCount: true,
            },
          },
          {
            field: 'category',
            tooltipField: 'category',
          },
          {
            headerName: 'Category Reason',
            field: 'category_rsn',
            tooltipField: 'category_rsn',
          },
          {
            field: 'count',
            tooltipField: 'count',
            width: 100,
          },
          {
            headerName: 'Auth Amount',
            field: 'auth_amt',
            type: 'rightAligned',
            valueFormatter: toCurrencyFormatter,
            flex: 1,
          },
        ],
        defaultColDef: {
          flex: 1,
          filter: true,
          sortable: true,
          resizable: true,
          enableBrowserTooltips: true,
          tooltipShowDelay: 0,
        },
      };
    },
    setRowData(rowData) {
      const { status, data, error } = rowData;
      if (status === 'loading') this.isLoading = true;
      if (error) this.isLoading = false;
      if (data) {
        this.rowData = data;
        this.isLoading = false;
      }
    },
  }));

  const accSnsNsSummary = useGetWidgetData(
    { query: widgetService.noShipSummary, name: 'noship' },
    'data',
    dataManagementStore.newQuery
  );

  useEffect(() => {
    store.setRowData(accSnsNsSummary);
  }, [accSnsNsSummary]);

  return (
    <Observer>
      {() => (
        <StyledWrapper>
          <WidgetWrapper>
            <WidgetHeader>
              <span className="warn">No!!! - Do Not Ship</span>
            </WidgetHeader>
            <div
              id="myGrid"
              className={'ag-theme-alpine-dark'}
              style={{
                height: '100%',
                minHeight: '275px',
                width: '100%',
                position: 'relative',
                borderRadius: '5px',
              }}
            >
              {store.isLoading ? (
                <LoadingSpinner size={'200px'} />
              ) : accSnsNsSummary.error ? (
                <div>{accSnsNsSummary.error}</div>
              ) : (
                <AgGridReact
                  // columnDefs={store.columnDefs}
                  gridOptions={store.gridOptions}
                  rowHeight={33}
                  rowData={store.customRowData}
                  treeData={true}
                  onGridReady={store.onGridReady}
                />
              )}
            </div>
          </WidgetWrapper>
        </StyledWrapper>
      )}
    </Observer>
  );
});

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  .center {
  margin: auto

  }
  .ag-theme-alpine-dark {
    /* use theme parameters where possible */
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    --ag-control-panel-background-color: #041c2f;
    --ag-subheader-background-color: #032845;

    .ag-row-level-0 {
      background-color: #041c2f !important;
    }
    .ag-row-level-1 {
      background-color: #032845 !important;
    }
    .ag-row-level-2 {
      background-color: rgb(23, 49, 70); !important;
      //color: rgb(23, 49, 70); !important;
    }
    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }
    .ag-header-row {
      height: 30px;
    }
     .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

  }
  .filter {
    background: ${({ theme }) => theme.baseColors.colorGreenHover};
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    font-weight: bold;
    //background-color: #3fa9f5;
    border-radius: 3px;
    border: none;
    //width: 80px;
    &:hover {
    background-color: ${({ theme }) => theme.baseColors.colorGreen};
      cursor: pointer;
  }

`;
export default RptNoShipTotals;
