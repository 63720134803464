import { types } from 'mobx-state-tree';
import { toast } from 'react-toastify';
import goldenAIT from '../styles/whiteLabels/goldenAit.js';
const { optional, string, number, boolean } = types;

const UiStore = types
  .model('uiStore', {
    showTxnDetailModal: optional(boolean, false),
    showShipNoShipReportModal: optional(boolean, false),
    showRptReconForecastModal: optional(boolean, false),
    changingClient: optional(boolean, false),
    darkMode: optional(boolean, true),
    currentModule: optional(string, ''),
    showSidebar: optional(boolean, true),
    width: optional(number, 701),
    whiteLabel: optional(string, '')
  })
  .volatile((self) => ({
    txnID: null,
    rowIndex: null,
    isChgbk: false,
  }))
  .views((self) => ({
    get currentTheme() {
      if (self.darkMode) {
        return goldenAIT.darkTheme;
      } else {
        return goldenAIT.lightTheme;
      }
    },
  }))
  .actions((self) => {
    function setChangingClient() {
      self.changingClient = !self.changingClient;
    }
    function setWhiteLabel(name) {
      self.whiteLabel = name;
    }
    function notify(notify) {
      let toastObj = {};
      let msg = notify.text ? notify.text : notify.title;
      switch (notify.group) {
        case 'topRight':
          toastObj['position'] = toast.POSITION.TOP_RIGHT;
          break;
        case 'bottomCenter':
          toastObj['position'] = toast.POSITION.BOTTOM_CENTER;
          break;
        default:
          toastObj['position'] = toast.POSITION.TOP_RIGHT;
          break;
      }
      if (notify.duration) toastObj['autoClose'] = typeof notify.duration === 'number' ? notify.duration : 4000;
      else toastObj['autoClose'] = false;
      if (notify.type === 'error') toast.error(msg, toastObj);
      else toast.success(msg, toastObj);
    }
    function toggleShowTxnDetailModal(displayId, rowIndex, isChgbk = false) {
      self.currentTxnID = displayId;
      self.rowIndex = rowIndex;
      self.isChgbk = isChgbk;
      self.showTxnDetailModal = !self.showTxnDetailModal;
    }
    function toggleShowShipNoShipReportModal() {
      self.showShipNoShipReportModal = !self.showShipNoShipReportModal;
    }
    function toggleReconForecastModal() {
      self.showRptReconForecastModal = !self.showRptReconForecastModal;
    }
    function toggleDarkMode() {
      self.darkMode = !self.darkMode;
    }
    function setMobileMode(width) {
      self.width = width;
    }
    function setCurrentModule(item) {
      if (item === self.currentModule) {
        self.currentModule = '';
      } else {
        self.currentModule = item;
      }
    }
    function toggleSidebar() {
      self.showSidebar = !self.showSidebar;
    }
    function toggleHideSidebar() {
      self.showSidebar = false;
    }
    function toggleShowSidebar() {
      self.showSidebar = true;
    }
    return {
      setChangingClient,
      setWhiteLabel,
      notify,
      toggleShowTxnDetailModal,
      toggleShowShipNoShipReportModal,
      toggleReconForecastModal,
      toggleDarkMode,
      setMobileMode,
      setCurrentModule,
      toggleSidebar,
      toggleHideSidebar,
      toggleShowSidebar
    };
  });

export default UiStore;
