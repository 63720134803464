import React from 'react';
import { Dropdown } from 'components/UI';
import styled from 'styled-components/macro';
import { observer, Observer } from 'mobx-react-lite';

const ChargebackFilter = observer((props) => {
  const chgbkCodeID = props.chargebackFilterProps.chgbkCodeID,
    txnStatusIDL = props.chargebackFilterProps.txnStatusIDL;
  return (
    <Observer>
      {() => (
        <Wrapper>
          <FilterTitle>Chargeback Filters</FilterTitle>
          <div className="filterBody">
            <div className="fieldContainer">
              {txnStatusIDL.get && <label>Chgbk Status</label>}
              <Dropdown
                fontSize={'14px'}
                placeholder="Chgbk Status"
                options={txnStatusIDL.options || []}
                value={txnStatusIDL.get || ''}
                onChange={txnStatusIDL.set}
                border
                margin={'3px 0 3px 0'}
                tabIndex={8}
              />
            </div>
            <div className="fieldContainer">
              {chgbkCodeID.get && <label>Chgbk Rsn</label>}
              <Dropdown
                fontSize={'14px'}
                placeholder="Chgbk Rsn"
                options={chgbkCodeID.options || []}
                value={chgbkCodeID.get || ''}
                onChange={chgbkCodeID.set}
                border
                margin={'0 0 3px 0'}
                tabIndex={9}
              />
            </div>
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  width: 100%;
  color: ${({ theme }) => theme.primaryColor};
  .filterBody {
    padding-right: 2px;
    .fieldContainer {
      outline: none;
      display: flex;
      flex-direction: column;
      margin: 0 1px 0 3px;

      label {
        font-size: 11px;
        margin-left: 2px;
        padding: 3px 0 1px 0;
      }
    }
  }
`;
const FilterTitle = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 7px 0;
  font-weight: bold;
  border-left: 1px solid #fff;
  background-color: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
`;

export default ChargebackFilter;
