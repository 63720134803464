import React, { useEffect, useState } from 'react';

import { useLocalStore, Observer, observer } from 'mobx-react-lite';
/*partials & styles*/
import { Input, Dropdown, Checkbox, LoadingSpinner, Button } from 'components/UI';
import { ArrowCircleLeftIcon } from 'styles/styledComponents';
import styled from 'styled-components/macro';
import {adminStore} from 'stores/AdminStore';
import { Redirect, Link, useHistory } from 'react-router-dom';
import MerchForm from '../MerchForm';

const MerchAdd = observer((props) => {
  return (
    <Wrapper>
			<div className="header-create">
				<Link to={`/admin/merch/settings`} className="back-btn">
					<ArrowCircleLeftIcon style={{width: '2rem'}}/>
					<h4>Back</h4>
				</Link>
        		<MerchForm isEdit={false} />
			</div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	padding-bottom: 3rem;
	background: rgba(0, 0, 0, 0.6);
	color: ${({ theme }) => theme.colors.primaryText};
	.header-create {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: start;
		padding-left: 1rem;
		.back-btn {
			color: white;
			display: flex;
			justify-content: start;
			align-items: center;
			gap: 1rem;
			border-radius: 10px;
			width: 25%;
		}
		.back-btn:hover {
			filter: brightness(85%);
		}
		.back-btn:active {
			transform: translateY(2px);
		}
	}
	h3 {
		color: ${({ theme }) => theme.colors.primaryText};
		border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
	}
	.form-wrapper {
		width: 95%;
	}

	.field {
		display: flex;
		justify-content: start;
		padding-bottom: 1rem;
		.labelContainer {
			text-align: left;
			min-width: 7rem;
		}
		.inputContainer {
			width: 100%;
		}
		.styledWrapper {
			width: 100%;
		}
	}

	.action-section {
		display: flex;
		justify-content: end;
		padding-top: 1rem;
		.add-user-btn {
			background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			color: white;
			padding: 1rem 0rem;
			width: 25%
		}
		.cancel-btn {
			background-color: ${({ theme }) => theme.baseColors.white};
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%;
			text-align: center;
			border-radius: 5px;
		}
		.add-user-btn:hover {
			filter: brightness(85%);
		}
		.add-user-btn:active {
			transform: translateY(2px);
		}
		.package-btn {
			background-color: white;
			color: ${({ theme }) => theme.baseColors.blueFocusBorder};
			padding: 1rem 0rem;
			width: 25%
		}
		.package-btn:hover {
			filter: brightness(85%);
		}
		.package-btn:active {
			transform: translateY(2px);
		}
	}
`;

export default MerchAdd;
