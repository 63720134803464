import React, {useEffect} from 'react';
import {WidgetHeader, WidgetWrapper} from 'styles/styledComponents';
import {Dropdown, Error, LoadingSpinner, Notes} from 'components/UI';
import styled from 'styled-components/macro';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {_get, _has, _set, _toNumber, useGetWidgetData, useInjectStores} from 'utils/utils';
import {AgGridReact} from 'ag-grid-react';
import {gridOptsColumnDefs} from './resources';
import {widgetService} from '../../../services';

const ReversalReasons = observer(() => {
    const {dataManagementStore} = useInjectStores();
    const store = useLocalObservable(() => ({
        selectedTopicChargebacks: 1,
        selectTopicChargebacks(val) {
            this.selectedTopicChargebacks = val;
        },
        topicsChargebacks: [
            {key: 1, text: 'Chargeback Reasons', value: 1},
            {key: 2, text: 'Reversal Reasons', value: 2},
        ],
        dashChrgbkRsn: [],
        dashRevRsn: [],
        fetchDashChrgbkRsn: false,
        fetchDashRevRsn: false,
        setDashChrgbkRsn(data) {
            this.fetchDashChrgbkRsn = false;
            this.dashChrgbkRsn = data;
        },
        setDashRevRsn(data) {
            this.fetchDashRevRsn = false;
            this.dashRevRsn = data;
        },
        error: null,
        api: null,
        gridColumnApi: null,
        gridOptions: {
            rowHeight: 33,
            headerHeight: 33,
            animateRows: true,
            enableBrowserTooltips: true,
            tooltipShowDelay: 0,
            reactNext: true,
            enableCharts: true,
            enableRangeSelection: true,
            groupMultiAutoColumn: true,
            groupSuppressAutoColumn: true,
            treeData: true,
            rowClassRules: {
                secondLevel: function (params) {
                    return params.data.secondLevel;
                },
                topLevel: function (params) {
                    return params.data.topLevel;
                },
            },
            groupRemoveSingleChildren: true,
            getDataPath: function (data) {
                return data.keyPath;
            },
            getRowNodeId: function (data) {
                return data.id;
            },
            defaultColDef: {
                sortable: false,
                resizable: true,
            },
        },
        onGridReady(params) {
            this.api = params.api;
            this.gridColumnApi = params.columnApi;
        },
        detailRowHeight: 70,
        get treeDataDashChrgbkRsn() {
            const dictionary = {};
            if (this.dashChrgbkRsn?.length) {
                let id_index = 1;
                let treeData = this.dashChrgbkRsn;

                for (let i = 0; i < treeData.length; i++) {
                    let currentChgbk = treeData[i];
                    // let newLookup = `${currentChgbk.cc_brand_rsn}${currentChgbk.bump2_rsn}`;
                    let newLookup = `${currentChgbk.cc_brand_rsn}`;
                    currentChgbk.chgbk_cat_cnt = _toNumber(_get(currentChgbk, 'chgbk_cat_cnt', 0));
                    if (!_has(dictionary, newLookup)) {
                        _set(dictionary, newLookup, {
                            topLevel: {
                                ...treeData[i],
                                topLevel: true,
                                id: id_index,
                                keyPath: [newLookup],
                            },
                            secondLevel: [],
                        });
                        id_index += 1;
                    } else {
                        // first time getting a duplicate topLevel and push the top level into the bumpRsn array.
                        if (dictionary[newLookup].secondLevel.length === 0) {
                            dictionary[newLookup].secondLevel.push({
                                ...dictionary[newLookup].topLevel,
                                secondLevel: true,
                                keyPath: [newLookup, `secondLevel1${currentChgbk.cust_rsn}`],
                                id: id_index,
                            });
                            id_index += 1;
                        }
                        ///////// add count to toplvl
                        dictionary[newLookup].topLevel = {
                            ...dictionary[newLookup].topLevel,
                            cust_rsn: `Multiple (${dictionary[newLookup].secondLevel.length + 1})`,
                            chgbk_cat_cnt: (dictionary[newLookup].topLevel.chgbk_cat_cnt += currentChgbk.chgbk_cat_cnt),
                        };
                        ///////////
                        dictionary[newLookup].secondLevel.push({
                            ...treeData[i],
                            secondLevel: true,
                            id: id_index,
                            keyPath: [newLookup, `secondLevel${dictionary[newLookup].secondLevel.length + 1}${currentChgbk.cust_rsn}`],
                        });
                        id_index += 1;
                    }
                }
            }
            let rowData = [];
            for (const key in dictionary) {
                rowData = [...rowData, dictionary[key].topLevel, ...dictionary[key].secondLevel];
            }
            return rowData;
        },
        get treeDataDashRevRsn() {
            let rowData = [];
            if (this.dashRevRsn?.length) {
                let data = this.dashRevRsn;
                for (let i = 0; i < data.length; i++) {
                    const current = data[i];
                    rowData.push({
                        ...current,
                        keyPath: [`${current.rvsl_src}${current.rvsl_rsn}`],
                        id: i + 1,
                    });
                }
            }
            return rowData;
        },
        get colDefsChargebacksRowData() {
            switch (this.selectedTopicChargebacks) {
                case 1:
                    return {
                        rowData: this.treeDataDashChrgbkRsn,
                        columnDefs: gridOptsColumnDefs.dashChrgbkRsn.columnDefs,
                    };
                case 2:
                    return {
                        rowData: this.treeDataDashRevRsn,
                        columnDefs: gridOptsColumnDefs.dashRevRsn.columnDefs,
                    };
                default:
                    return {
                        rowData: [],
                        columnDefs: {},
                    };
            }
        },

        setFetchDashChrgbkRsn(fetch) {
            this.fetchDashChrgbkRsn = fetch;
        },
        setFetchDashRevRsn(fetch) {
            this.fetchDashRevRsn = fetch;
        },
    }));

    const dashChrgbkRsn = useGetWidgetData(
        {name: 'dashboardChargebackReason', query: widgetService.dashboardChargebackReason},
        'data',
        store.fetchDashChrgbkRsn
    );
    const dashRevRsn = useGetWidgetData(
        {name: 'dashboardReversalReason', query: widgetService.dashboardReversalReason},
        'data',
        store.fetchDashRevRsn
    );

    useEffect(() => {
        store.setDashChrgbkRsn(dashChrgbkRsn.data);
    }, [dashChrgbkRsn.data]);
    useEffect(() => {
        store.setDashRevRsn(dashRevRsn.data);
    }, [dashRevRsn.data]);

    useEffect(() => {
        store.setFetchDashChrgbkRsn(true);
        store.setFetchDashRevRsn(true);
    }, [dataManagementStore?.newQuery]);

    return (
        <WidgetWrapper position={'relative'} height="100%">
            <div style={{display: 'flex', alignItems: 'center', height: 40}}>
                <div style={{position: 'absolute', left: 5}}>
                    <Dropdown
                        height={'25px'}
                        options={store.topicsChargebacks}
                        onChange={store.selectTopicChargebacks}
                        placeholder="Granularity"
                        toggleDisable={store.toggleDisable}
                        value={store.selectedTopicChargebacks}
                        labelWidth={'0px'}
                        width={'175px'}
                        margin={'0 2px 0 6px'}
                        border
                    />
                </div>
                <WidgetHeader>Chargebacks</WidgetHeader>
            </div>
            <div></div>

            {/*<Divider />*/}
            <GridWrapper>
                {dashChrgbkRsn.status === 'loading' || dashRevRsn.status === 'loading' ? (
                    <LoadingSpinner size={'200px'}/>
                ) : dashChrgbkRsn.status === 'error' ? (
                    <Error error={dashChrgbkRsn.error}/>
                ) : dashRevRsn.status === 'error' ? (
                    <Error error={dashRevRsn.error}/>
                ) : (
                    <div
                        id="myGrid1"
                        className={'ag-theme-alpine-dark'}
                        style={{
                            height: '100%',
                            minHeight: '300px',
                            width: '100%',
                            position: 'relative',
                            borderRadius: '5px',
                        }}
                    >
                        <AgGridReact
                            onGridReady={store.onGridReady}
                            rowData={store.colDefsChargebacksRowData.rowData}
                            columnDefs={store.colDefsChargebacksRowData.columnDefs}
                            gridOptions={store.gridOptions}
                            popupParent={document.getElementById('myGrid1')}
                        />
                    </div>)}
            </GridWrapper>
        </WidgetWrapper>
    );
});

const GridWrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);

    .ag-header-row {
      height: 30px;
    }

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .topLevel {
      background-color: #001329 !important;
    }

    .secondLevel {
      background-color: #032845 !important;
    }
  }

  grid-area: SsTotalsGraph;
  position: relative;
    // width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .noData {
    margin: auto;
  }
`;

export default ReversalReasons;
