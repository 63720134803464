import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from 'styles/styledComponents';
import { RouteLoadingSpinner } from 'components/UI';
// import _get from 'lodash/get';
// import { useInjectStores } from 'utils/utils';
// import { useLocalStore } from 'mobx-react-lite';

const PlanCodes = (props) => {
  // const { utilsStore } = useInjectStores();
  // const store = useLocalStore(() => ({
  //   isLoadingTransaction: false,
  // }));
  // const planSummaryTran = _get(props, 'widgetData[0]["TRANSACTION_SECTION"][0]["CHARGEBACK_TRAN"]', []);
  // const planSummaryTotals = _get(props, 'widgetData[0]["TRANSACTION_SECTION"][0]["CHARGEBACK_TOTALS"]', []);
  return (
    <div height="100%">
      <TableHeader light={true} backgroundColor="#e9eae9">
        {/*<div*/}
        {/*  style={{*/}
        {/*    position: 'absolute',*/}
        {/*    width: '100%',*/}
        {/*    justifyContent: 'center',*/}
        {/*  }}*/}
        {/*>*/}
        PLAN CODES
        {/*</div>*/}
        {/*<div>TRANSACTION CODES</div>*/}
      </TableHeader>
      {props.isLoading && <RouteLoadingSpinner size={'400px'} />}
      <TableContainer>
        <Table>
          <TableBody>
            {!props.isLoading && (
              <>
                <TableRow>
                  <TableCell textAlign="left" light={true}>
                    VS-VISA
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    MC -MASTERCARD
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    DS -DISCOVER
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    JC -JCB
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    DB -DEBIT
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    T -ALL PLANS
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    D -DEPOSIT
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell textAlign="left" light={true}>
                    VL -VISA LARGE TICKET
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    ML -MASTERCARD LARGE TICKET
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    DD -DISCOVER DEBIT
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    EB -EBT
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    PP -PAYPAL
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    1 - PLAN ONE
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    C -CHARGEBACK
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell textAlign="left" light={true}>
                    VD -VISA DEBIT
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    MD -MASTERCARD DEBIT
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    DZ -DISCOVER BUSINESS
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    AM -AMERICAN EXPRESS
                  </TableCell>
                  <TableCell textAlign="left" light={true}></TableCell>
                  <TableCell textAlign="left" light={true}>
                    2 -PLAN TWO
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    A -ADJUSTMENT
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell textAlign="left" light={true}>
                    VB -VISA BUSINESS
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    MB -MASTERCARD BUSINESS
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    DJ -DISCOVER JCB
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    EC -ELECTRONIC CHECK
                  </TableCell>
                  <TableCell textAlign="left" light={true}></TableCell>
                  <TableCell textAlign="left" light={true}>
                    3 -PLAN THREE
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    B -CHARGEBACK REVERSAL
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell textAlign="left" light={true}>
                    V$ -VISA CASH ADVANCE
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    M$ -MASTERCARD CASH ADVANCE
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    D$ -DISCOVER CASH ADV
                  </TableCell>
                  <TableCell textAlign="left" light={true}>
                    AC -ACH as a tran
                  </TableCell>
                  <TableCell textAlign="left" light={true}></TableCell>
                  <TableCell textAlign="left" light={true}></TableCell>
                  <TableCell textAlign="left" light={true}></TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PlanCodes;
