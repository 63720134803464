import React, { Fragment, useEffect } from 'react';
import { Button, LoadingSpinner } from 'components/UI';
import styled from 'styled-components';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import {
  ClearIcon,
  Divider,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { autorun } from 'mobx';
import { _get, _includes, _toNumber, _toPairs, useInjectStores } from 'utils/utils';
import {useHistory, useLocation} from "react-router-dom";

const GrossValues = observer((props) => {
  const toCurrency = props.store.toCurrency;
  const formatNumber = props.store.formatNumber;
  const { filterStore } = useInjectStores();
  const oppFilter = filterStore.opportunityFilter;
  // const setCurrentFilter = filterStore.setCurrentFilter;
  /* useEffect(() => {
     var paramsString = location.search;
     let params = {}
     if (paramsString) {
       let searchParams = new URLSearchParams(paramsString);
       for (let p of searchParams) {
         params[p[0]] = p[1]
       }
     }
     console.log('params', params);
     setCurrentFilter(history, location);
   }, [location.search]);*/
  const store = useLocalStore(() => ({
    currentLocalFilter: '',
  /*  setFilter(category) {
      store.currentLocalFilter = category;
      props.store.applyNewFilter({
        category,
      });
      store.currentFilterData = filterStore.currentFilterData;
    },
    resetFilter() {
      store.currentLocalFilter = '';
      filterStore.resetState(props.store.history, props.store.location);
      props.store.setNewQuery();
    },*/

   /* get resetCurrentLocalFilter() {
      const oppFilterOpts = [
        'VoidsInquiries Rejects',
        'Refund Rejects',
        'Double Returns',
        'VoidsInquiries Reversals',
        'Refund Reversals',
        'Insufficient Funds',
        'Fixable',
        'Resubmittals',
        'Errors',
        'Do Not Honor',
      ];
      const common = _toPairs(_get(filterStore, 'appliedCommonFilters', [])).length;
      const secondary = _toPairs(_get(filterStore, 'appliedSecondaryFilters', [])).length;
      const onlyOne = common + secondary === 1;
      const catIsFromCorrectList = _includes(oppFilterOpts, oppFilter.category);
      const collectedIsNotNullUndefined =
        oppFilter.collected === null || oppFilter.collected === '' || oppFilter.collected === undefined;
      return onlyOne === false || catIsFromCorrectList === false || collectedIsNotNullUndefined === false;
    },*/
  }));
 /* useEffect(
    () =>
      autorun(async () => {
        if (store.resetCurrentLocalFilter) {
          store.currentLocalFilter = '';
        }
        if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== oppFilter.category) {
          store.currentLocalFilter = oppFilter.category;
        }
      }),
    []
  );*/

  return (
    <Observer>
      {() => (
        <Wrapper margin={props.margin}>
          <TableWrapper height="100%">
            <TableHeader>OPPORTUNITY</TableHeader>
           {/* <StyledNote>
              <span className="note">Note: &nbsp;</span>
              <span className="noteBody">ASR is based on the avg of all industries</span>
            </StyledNote>*/}
            <Divider />
            {props.store.isLoadingOpp ? (
              <LoadingSpinner size={'200px'} />
            ) : (
              <Table>
                <TableBody>
                  <Fragment>
                    <TableRow>
                      {/*<HeaderCell />*/}
                      <HeaderCell textAlign={'left'} style={{paddingLeft: '2rem'}}>Category</HeaderCell>
                      <HeaderCell>Count</HeaderCell>
                      <HeaderCell>Amount</HeaderCell>
                      <HeaderCell>Avg Succ Ratio</HeaderCell>
                      <HeaderCell>Likely Collectible</HeaderCell>
                    </TableRow>
                    {props.store.opp1?.length > 0 && (
                      <Fragment>
                        {_get(props.store,'opp1').map(
                          // ({ opp_cat, opp_txn_cnt, opp_gross_amt, opp_succ_ratio, opp_amt_likely }, index) => (
                          (grossValue, index) => (
                              // {
                          //     collected_amt: 0,
                          // collected_count: 0,
                          // et_opp_succ_ratio: "70%",
                          // likely_collectible: 210458.07,
                          // opp_category: "Insufficient Funds",
                          // opp_count: 8213,
                          // orig_amt: 300675.51,
                          // priority: 4
                        // }
                            <TableRow key={`${grossValue.opp_category}${index}`}>

                              <TableCell style={{paddingLeft: '2rem'}} textAlign={'left'}>{index += 1} {grossValue.et_opp_cat}</TableCell>
                              <TableCell>{formatNumber(_toNumber(grossValue.opp_count))}</TableCell>
                              <TableCell>{toCurrency(grossValue.opp_orig_amt)}</TableCell>
                              <TableCell>{grossValue.et_opp_succ_ratio}</TableCell>
                              <TableCell style={{paddingRight: '2rem'}}>{toCurrency(grossValue.likely_collectible)}</TableCell>
                            </TableRow>
                          )
                        )}
                      </Fragment>
                    )}
                    <TableRow>
                      {/*<TableCell showTotalsLine>{}</TableCell>*/}
                      <TableCell style={{paddingLeft: '2rem'}} textAlign={'left'} showTotalsLine>Totals</TableCell>
                      <TableCell showTotalsLine>{formatNumber(props.store.oppABDTotals.opp_count)}</TableCell>
                      <TableCell showTotalsLine>
                        {toCurrency(props.store.oppABDTotals.opp_orig_amt)}
                      </TableCell>
                      <TableCell showTotalsLine>
                        {/*{toCurrency(_get(props.store.oppABDTotals, '')) || 0}*/}
                      </TableCell>
                      <TableCell style={{paddingRight: '2rem'}} showTotalsLine>
                        {toCurrency(_get(props.store.oppABDTotals, 'likely_collectible')) || 0}
                        {/*{t_likely)) || 0}*/}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                </TableBody>
              </Table>
            )}
          </TableWrapper>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 317px;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

const StyledNote = styled.div`
  position: absolute;
  right: 5px;
  top: 16px;
  //fontSize: '11px',
  height: 10px;
  width: 100%;
  justify-content: flex-end;
  text-align: end;
  align-items: center;
  display: flex;
  // flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  //height: 100%;
  // justify-content: ${({ flexDirection }) => (flexDirection === 'column' ? 'flex-end' : 'flex-start')};
  //align-items: center;
  font-size: 10px;
  padding: ${({ padding }) => padding};
  font-style: italic;
  .note {
    font-size: 12px;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;

export default GrossValues;
