import { createGlobalStyle } from 'styled-components/macro';

export const centerColumn = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const cssError = `
  color: var(--color-danger);
  display: flex;
  justify-content: center;
  padding-top: 6px;
`;

const GlobalStyle = createGlobalStyle`
  html {
  --white: #fff;
  --whiteDarker: rgb(255, 255, 255);
  --whiteBorder: rgba(255, 255, 255, 0.3);
  --whiteA1: rgba(255, 255, 255, 0.1);

  --font-color-dark: rgba(255, 255, 255, 0.8);
  --font-color-dropdown: #4d4d4d;
  --font-color-label-dropdown-dark: rgba(255, 255, 255, 0.8);

  --whiteAlpha9: rgba(255, 255, 255, 0.9);
  --whiteAlpha2: rgba(255, 255, 255, 0.2);

  --background-light: #23292d;
  --background-dark: linear-gradient(135deg, #047899 0%, #04618c 19%, #003c6d 56%, #003360 100%);
  --color-blue: #3fa9f5;
  --color-blue-hover: #3586c8;
  --blue-focus-border: #4183C4;

  --color-danger: #FF2B49;
  --color-danger-hover: #d2081e;

  --color-green: rgb(69, 232, 133);
  --color-green-hover: rgb(19, 182, 83);

  --grey-light-border: #e2e2e2;
  --color-grey-light: #f0f0f0;
  --color-grey-medium-light: #dbdbdb;

  --color-grey: #4d4d4d;
  --color-grey-dark: rgb(91,91,91);
  --color-grey-medium: #5b5b5b;

  --color-orange: #f79514;
  --color-orange-hover: #db7914;

  --color-yellow: #F6EC4C;
  --new-yellow: #fccc1a;
  --color-Yellow-hover: #ded021;

  --opaque: rgba(0, 0, 0, 0.1);
  --transparent: transparent;
  --sidebar-width: '200px';
}

toggleTheme() {

}

/*const buttonStyles = {
!** Background Colors **!
secondary: baseColors.colorGreen,
secondaryHover: baseColors.colorGreenHover,
delete: baseColors.colorDanger,
deleteHover: baseColors.colorDangerHover,
primary: baseColors.colorBlue,
primaryHover: baseColors.colorBlueHover,
clear: baseColors.colorOrange,
clearHover: baseColors.colorOrangeHover,
};*/

/*background: 'linear-gradient(135deg, #047899 0%, #04618c 19%, #003c6d 56%, #003360 100%)',
boxShadow: 'rgba(91, 91, 91, 0.5) 0px 2px 4px;',
buttonStyles,
inputBorder: '1px solid #e2e2e2',
focusInputBorder: '1px solid #4183C4',
dropdownBorder: '1px solid #e2e2e2',
focusDropdownBorder: '1px solid #4183C4',
primaryTickColor: 'rgba(255,255,255, .8)',
calendar: {
  colors: {
    selectedStartEndDates: '#43BBDD',
    selectedRange: '#CAE5ED',
    preselectedBtnText: '#FFF',
    preselectedBtnBackground: '#3EA8F5',
    cancelBtn: '#F79514' || 'rgb(247, 149, 20)',
  },
},
colors: {
  background: '#F0F0F0',
  errorWarning: '#ff2b49',
  negative: '#ff2b49',
  border: '#D2D2D2',
  text: 'rgb(255, 255, 255, .8) !important',
  inputText: '#5B5B5B',
  primaryTextLight: '#5B5B5B',
  primaryText: 'rgba(255,255,255, .8)',
  primaryBtn: '#3EA8F5' || 'rgb(62, 168, 245)',
  primaryBtnText: '#FFF',
  secondaryBtn: '#F79514' || 'rgb(247, 149, 20)',
  spinner: '#FFF',
  divider: '1px solid rgba(255,255,255, .1)',
  totalsDivider: '1px solid rgba(255,255,255, .3)',
  filterBorderBottom: '1px solid rgba(255,255,255, .15)',
},
fontFamily: 'Futura PT, sans-serif',
fontSize: '',
header: {
  logoPath: '../../images/GoldenAit/goldenAitLogo.svg',
  colors: {
    logoBackground: '',
    background: '#F0F0F0',
    viewName: '#FFF',
    logout: '#FFF',
  },
},
icons: {
  colors: {
    filterBtnBackground: 'rgb(69, 232, 133)',
    filterBtnHoverBackground: 'rgb(19, 182, 83)',
    filterBtnColor: '#FFF',
    sortAlpha: '#3EA8F5',
    university: '#FF2B49',
    creditCard: '#F6EC4B',
    warning: '#45E786',
    cart: '#3EA8F5',
    user: '',
    lock: '',
    txnTotalsIcon: '#fff',
  },
},
loginLogo: {
  logoPath: Logo,
  height: '52px',
  width: '200px',
},
paginator: {},
primaryColor: 'rgb(91, 91, 91)',
rgl: {
  colors: {
    background: 'transparent',
  },
  gridItem: {
    colors: {
      primaryText: 'rgba(255,255,255, .8)',
      secondaryText: '#5B5B5B',
      background: 'rgba(0,0,0,0.6) !important',
      toolTipBackground: '#041c2f',
      grid: '#263743',
      gridPanelBackground: 'rgb(32, 113, 138)',
      startOpacity: 0.8,
      stopOpacity: 0,
      totalsBackground: 'transparent',
    },
  },
},
sidebar: {
  colors: {
    menuBtn: '#FFF',
    hoverMenuBtn: '#FFF',
    linkText: '#FFF',
    linkIcon: '#FFF',
    hoverLinkIconText: '#3EA8F5',
    linkBackground: '#FFF',
    hoverBackground: '',
    sortHandle: '#445f64',
  },
},
ssTotalsGraph: {
  colors: {
    reserves: '#FF2B49',
    returnedSales: '#F6EC4B',
    declines: '#45E786',
    sales: '#3EA8F5',
  },
},
ssLoadingSpinner: {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
},
tableStyles: {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  hoverBackgroundColor: 'rgba(0,0,0, 0.3)',
  tableBottomBorder: 'rgba(0,0,0, 0.2)',
  categoryTRBackgroundColor: '#5b5b5b',
  color: '#fff',
  manageUserPanelBackgroundColor: 'rgba(0,0,0,0)',
  reasonTRBackgroundColor: '#a1a1a1',
  tableHeaderText: '#FFF',
  txnTypeTRBackgroundColor: '#3ea8f5',
},
SsProcLimitsStyles: {
  colors: {
    border: '#DDD',
    background: '#FFF',
    color: '#5B5B5B',
    headerBackground: '#3FA9F5',
    headerText: '#FFF',
  },
  borders: {
    midLimitBorder: '1px solid #ddd',
  },
  CardBack: {
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.3)',
  },
},
AdminStyles: {
  clientManagement: {
    color: 'white',
    midInfoBorder: '1px solid white',
    panelBackgroundColor: 'rgba(0,0,0,0.4)',
  },
  demoIntegrations: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  ssFormsManagement: {},
  manageUsers: {},
  reports: {},
},
reportsStyles: {
  marketing: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
  },
  fulfillment: {},
  customerService: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
  },
  accounting: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
  },
  execs: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
  },
  customReports: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
  },
},
AGGridStyles: {
  borderStyles: {
    outsideTableBorder: '1px solid rgba(255,255,255, .13)',
    defaultBorder: '1px solid #ddd',
  },
  rowStyles: {
    selectedColor: 'rgba(255, 255, 255, 0.15) !important',
    even: {
      evenBackground: '#173146',
      color: 'rgba(255,255,255, .8)',
    },
    odd: {
      oddBackground: '#042037',
      color: 'rgba(255,255,255, .8)',
    },
    hover: {
      hoverBackground: 'rgba(255,255,255, .15)',
      color: 'rgba(255,255,255,.8)',
    },
    header: {
      borderStyle: '1px solid rgba(255, 255, 255, 0.1)',
      headerBackground: '#042037',
      color: 'rgba(255,255,255,.8)',
    },
  },
  textColors: {
    rowColor: '#FFFFFF',
    headerColor: '#FFFFFF',
  },
  tableStyles: {
    backgroundColor: 'rgba(3, 26, 42, 1)',
  },
},
actionListStyles: {
  border: 'rgba(255, 255, 255, 0.3)',
  text: '#5B5B5B',
},
notesEditorStyles: {
  border: 'rgba(255, 255, 255, 0.3)',
  text: '#5B5B5B',
},
baseColors: { ...baseColors },
},*/

body {
    background: white;
    padding:0;
    margin: 0;
    height: 100%;
}

#__next {
    height: 100%;
}

#content {
    width: 100%;
}

@-webkit-keyframes autofill {
  to {
      color: #000;
      background: transparent;
      font-size: 14px;
  }
}

input {
  font-size: 14px;
  color: black;
}
input::placeholder {
  font-size: 14px;

}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

html {
  font-family: 'Roboto', 'calibri', sans-serif;
}

a {
 text-decoration: none;
}


.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial
}

.public-DraftEditor-content[contenteditable=true] {
  -webkit-user-modify: read-write-plaintext-only
}

.DraftEditor-root {
  position: relative
}

.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: .1px solid transparent;
  position: relative;
  z-index: 1
}

.public-DraftEditor-block {
  position: relative
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9
}

.DraftEditorPlaceholder-hidden {
  display: none
}

.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right
}

.public-DraftStyleDefault-listLTR {
  direction: ltr
}

.public-DraftStyleDefault-listRTL {
  direction: rtl
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1, lower-alpha) ". ";
  counter-increment: ol1
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2, lower-roman) ". ";
  counter-increment: ol2
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4, lower-alpha) ". ";
  counter-increment: ol4
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}



`;

export { GlobalStyle };
