import React from 'react';
import Moment from 'moment';
import { DatePickerPresetButton } from './StyledDatePicker';
import { ChevronRightIcon, ChevronLeftIcon } from 'styles/styledComponents';
import DatePicker from 'react-datepicker';
import { Dropdown } from 'components/UI';
import styled from 'styled-components';
import { Observer, useLocalStore, observer } from 'mobx-react-lite';
import { useInjectStores, _get } from 'utils/utils';

const DatePickers = observer((props) => {
  const { filterStore, userStore } = useInjectStores();
  const lastSevenYears = [];

  for (let i = 0; i < 9; i++) {
      if(i >= 2) {
        lastSevenYears.push(Moment().subtract(i, 'years').format('YYYY'));
      }
  }
  const store = useLocalStore(() => ({
    focusedInput: null,
    startDateCheck: null,
    endDateCheck: null,
    showCustomDateSelect: true,
    get startDate() {
      return Moment(filterStore.dateFilter.startDt).toDate();
    },
    get endDate() {
      return Moment(filterStore.dateFilter.endDt).toDate();
    },
    get userTz() {
      return _get(userStore, 'user.lastClientViewed.genTmz.tmzName', 'UTC');
    },
    get selectedInput() {
      return filterStore.dateFilter.selectedInput;
    },
    toggleCustomDateSelect() {
      store.showCustomDateSelect = !store.showCustomDateSelect;
    },
    setDateBackOnePeriod() {
      filterStore.dateFilter.setDateBackOnePeriod();
    },
    setDateForwardOnePeriod() {
      filterStore.dateFilter.setDateForwardOnePeriod();
    },
    setPeriodDay() {
      filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.showPeriod('Day');
      filterStore.dateFilter.setPeriodDay();
    },
    setPeriodWeek() {
      filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.showPeriod('Week');
      filterStore.dateFilter.setPeriodWeek();
    },
    setPeriodMonth() {
      filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.showPeriod('Month');
      filterStore.dateFilter.setPeriodMonth();
    },
    setPeriodYear() {
      filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.showPeriod('Year');
      filterStore.dateFilter.setPeriodYear();
    },
    setPeriodCustom() {
      filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.showPeriod('Custom');
      filterStore.dateFilter.setPeriodCustom();
    },
    handleChangeStart(startDt) {
      if (store.selectedInput) filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.setStartDt(startDt);
    },
    handleChangeEnd(endDt) {
      if (store.selectedInput) filterStore.dateFilter.setSelectedInput('');
      filterStore.dateFilter.setEndDt(endDt);
    },
  }));

  const ForwardBackwardDates = () => (
    <PresetWrapper>
      <div className={'dPBtnContainer'}>
        <span
          className="submit"
          type="submit"
          onClick={() => {
            filterStore.dateFilter.applyNewDateRange();
            props.toggleShowDatePopup();
          }}
        >
          Apply
        </span>
        <div className="dateNavContainer">
          <div
            onClick={store.setDateBackOnePeriod}
            className="arrowLeftContainer"
            style={{ display: 'flex', border: '1px solid #DBDBDB', cursor: 'pointer' }}
          >
            <ChevronLeftIcon width={20} />
          </div>
          <Observer>
            {() => (
              <div className="item" onMouseOver={store.toggleCustomDateSelect}>
                <div className="datePeriodListContainer">
                  <div className="item" onClick={store.setPeriodCustom}>
                    Custom
                  </div>
                  <div className="item" onClick={store.setPeriodYear}>
                    Year
                  </div>
                  <div className="item" onClick={store.setPeriodMonth}>
                    Month
                  </div>
                  <div className="item" onClick={store.setPeriodWeek}>
                    Week
                  </div>
                  <div className="item" onClick={store.setPeriodDay}>
                    Day
                  </div>
                </div>
                {filterStore.dateFilter.periodToShow}
              </div>
            )}
          </Observer>
          <div onClick={store.setDateForwardOnePeriod} className="arrowRightContainer">
            <ChevronRightIcon width={20} />
          </div>
        </div>
        <button className="cancel" type="submit" onClick={props.toggleShowDatePopup}>
          Cancel
        </button>
      </div>
    </PresetWrapper>
  );

  return (
    <Observer>
      {() => (
        <Wrapper>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  borderRight: '1px solid rgba(255,255,255, .3)',
                  // borderRight: '1px solid rgb(200, 200, 200)',
                  marginRight: '4px',
                }}
              >
                {userStore.dataType === 'current' ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '135px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Dropdown
                        flexDirection={'column'}
                        fontSize={'14px'}
                        placeholder=""
                        options={[
                          { key: 1, text: 'Txn Date', value: 'txnDt' },
                          { key: 2, text: 'Settle Date', value: 'stmtDt' },
                        ]}
                        value={filterStore.dateFilter.dateType}
                        onChange={filterStore.dateFilter.setTxnDtType}
                        height="25px"
                        width="110px"
                        margin={'3px 0 3px 0'}
                        labelFontSize="11px"
                        label={'filter txns by:'}
                      />
                    </div>
                    
                    <DatePickerPresetButton
                      selected={store.selectedInput === 'yesterday'}
                      onClick={() => {
                        filterStore.dateFilter.setSelectedInput('yesterday');
                        filterStore.dateFilter.setYesterday();
                        props.toggleShowDatePopup();
                      }}
                    >
                      Yesterday
                    </DatePickerPresetButton>
                    <DatePickerPresetButton
                        selected={store.selectedInput === 'monthToDate'}
                        onClick={() => {
                          filterStore.dateFilter.setSelectedInput('monthToDate');
                          filterStore.dateFilter.setMonthToDate();
                          props.toggleShowDatePopup();
                        }}
                    >
                      Month To Date
                    </DatePickerPresetButton>
                    <DatePickerPresetButton
                      selected={store.selectedInput === 'thisMonth'}
                      onClick={() => {
                        filterStore.dateFilter.setSelectedInput('thisMonth');
                        filterStore.dateFilter.setThisMonth();
                        props.toggleShowDatePopup();
                      }}
                    >
                      This Month
                    </DatePickerPresetButton>
                    <DatePickerPresetButton
                      selected={store.selectedInput === 'yearToDate'}
                      onClick={() => {
                        filterStore.dateFilter.setSelectedInput('yearToDate');
                        filterStore.dateFilter.setYearToDate();
                        props.toggleShowDatePopup();
                      }}
                    >
                      Year to Date
                    </DatePickerPresetButton>
                    <DatePickerPresetButton
                      selected={store.selectedInput === 'lastWeek'}
                      onClick={() => {
                        filterStore.dateFilter.setSelectedInput('lastWeek');
                        filterStore.dateFilter.setLastWeek();
                        props.toggleShowDatePopup();
                      }}
                    >
                      Last Week
                    </DatePickerPresetButton>
                    <DatePickerPresetButton
                      selected={store.selectedInput === 'lastMonth'}
                      onClick={() => {
                        filterStore.dateFilter.setSelectedInput('lastMonth');
                        filterStore.dateFilter.setLastMonth();
                        props.toggleShowDatePopup();
                      }}
                    >
                      Last Month
                    </DatePickerPresetButton>
                    <DatePickerPresetButton
                      selected={store.selectedInput === 'lastYear'}
                      onClick={() => {
                        filterStore.dateFilter.setSelectedInput('lastYear');
                        filterStore.dateFilter.setLastYear();
                        props.toggleShowDatePopup();
                      }}
                    >
                      Last Year
                    </DatePickerPresetButton>
                  </div>
                ) : 
                (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '135px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Dropdown
                        flexDirection={'column'}
                        fontSize={'14px'}
                        placeholder=""
                        options={[
                          { key: 1, text: 'Txn Date', value: 'txnDt' },
                          { key: 2, text: 'Settle Date', value: 'stmtDt' },
                        ]}
                        value={filterStore.dateFilter.dateType}
                        onChange={filterStore.dateFilter.setTxnDtType}
                        height="25px"
                        width="110px"
                        margin={'3px 0 3px 0'}
                        labelFontSize="11px"
                        label={'filter txns by:'}
                      />
                    </div>
                    

                    {lastSevenYears.length > 0 && lastSevenYears.map((year, index) => (
                      <DatePickerPresetButton
                        key={`btn-${year}`}
                        selected={store.selectedInput === year}
                        onClick={() => {
                          filterStore.dateFilter.setSelectedInput(year);
                          filterStore.dateFilter.setPreviousYearX(year);
                          props.toggleShowDatePopup();
                        }}
                      >
                        Year {year}
                      </DatePickerPresetButton>
                    ))}
                  </div>
                )}
              </div>
              <StartEndCalendars>
                <div className="calendarStart">
                  <label>START</label>
                  <DatePicker
                    minDate={
                      userStore?.dataType === 'archive'
                        ? Moment().startOf('year').subtract(20, 'years').toDate()
                        : Moment().startOf('year').subtract(1, 'years').toDate()
                    }
                    maxDate={
                      userStore?.dataType === 'archive'
                        ? Moment().endOf('year').subtract(2, 'years').toDate()
                        : Moment().endOf('month').subtract(1, 'days').toDate()
                    }
                   
                    showDisabledMonthNavigation
                    inline
                    fixedHeight
                    selectsStart
                    selected={store.startDate}
                    startDate={store.startDate}
                    endDate={store.endDate}
                    onChange={store.handleChangeStart}
                    dayClassName={(date) =>
                      Moment(date).startOf('day').toDate().getTime() === store.startDate.getTime() ? 'startEndDates' : null
                    }
                  />
                </div>
                <div className="calendarEnd">
                  <label>END</label>
                  <DatePicker
                    minDate={
                      userStore?.dataType === 'archive'
                        ? Moment().startOf('year').subtract(20, 'years').toDate()
                        : Moment().startOf('year').subtract(1, 'years').add('1', 'days').toDate()
                    }
                    // Moment().startOf('year').subtract(1, 'years').add('1', 'days').toDate()}
                    maxDate={
                      userStore?.dataType === 'archive'
                        ? Moment().endOf('year').subtract(2, 'years').toDate()
                        : Moment().endOf('month').toDate()
                    }
                    showDisabledMonthNavigation
                    inline
                    fixedHeight
                    selectsEnd
                    selected={store.endDate}
                    startDate={store.startDate}
                    endDate={store.endDate}
                    onChange={store.handleChangeEnd}
                    dayClassName={(date) =>
                      Moment(date).endOf('day').toDate().getTime() === store.endDate.getTime() ? 'startEndDates' : null
                    }
                  />
                </div>
                <div className="forwardBack">
                  <ForwardBackwardDates />
                </div>
              </StartEndCalendars>
            </div>
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const StartEndCalendars = styled.div`
  grid-area: calendars;
  display: grid;
  grid-gap: 1px 5px;
  margin: 10px 5px 5px 5px;

  label {
    color: ${({ theme }) => theme.colors.text};
  }
  .calendarStart {
    grid-area: start;
    width: 100%;
    text-align: center;
  }
  .calendarEnd {
    grid-area: end;
    width: 100%;
    text-align: center;
  }
  .forwardBack {
    grid-area: forwardBack;
  }
  grid-template-areas:
    'start end'
    'forwardBack forwardBack';
`;
const PresetWrapper = styled.div`
  .dPBtnContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .submit {
    box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 5px;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    margin: 5px 2px;
    background-color: #3fa9f5;
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.baseColors.colorBlueHover};
    }
  }
  .cancel {
    box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0px 5px;
    justify-content: center;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    color: #fff;
    margin: 5px 2px;
    background-color: #f79514;
    outline: none;
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.baseColors.colorOrangeHover};
    }
  }
  .datePeriodListContainer {
    display: none;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 42px;
    right: 169px;
  }
  .arrowLeftContainer {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    background: #fff;
  }
  .arrowRightContainer {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    background: #fff;
  }
  .item {
    display: flex;
    justify-content: center;
    border: 1px solid #dbdbdb;
    background: #fff;
    padding: 5px 10px;
    width: 61px;
    height: auto;
    &:hover {
      background-color: #e2e2e2;
      cursor: pointer;
      .datePeriodListContainer {
        display: flex;
      }
    }
  }
  .dateNavContainer {
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
    display: flex;
    justify-content: center;
    margin: 5px;
    width: 125px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-rows: auto;
  //grid-template-columns: 100px 350px;
  grid-template-areas: 'presets calendar';

  //background: ${({ theme }) => theme.background};
  background: #032845;
  //--ag-control-panel-background-color: #041c2f;
  //  --ag-subheader-background-color: #032845;
  //width: 100%;
  //height: 100%;
  .presetContainer {
    width: 100%;
    flex-direction: row;
    display: flex !important;
    justify-content: space-around !important;
  }
  .react-datepicker__month-text--in-range {
    /*border-radius: 0.3rem;*/
    border-radius: 0 !important;
    background-color: #cae5ed !important;
    /*background-color: #216ba5;*/
    /*color: #fff;*/
    color: #000 !important;
  }

  .react-datepicker {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    /*border: 1px solid #aeaeae;*/
    box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
    /*box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);*/
    /*border-radius: 0.3rem;*/
    border-radius: 0 !important;
    display: inline-block;
    position: relative;
  }

  .react-datepicker__header {
    text-align: center;
    /*background-color: #f0f0f0;*/
    /*border-bottom: 1px solid #aeaeae;*/
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }

  .react-datepicker__month {
    /*margin: 0.4rem;*/
    margin: 0;
    text-align: center;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0;
    /*margin: 0.166rem;*/
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    /*border-radius: 0.3rem;*/
    border-radius: 0 !important;
    background-color: #f0f0f0;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    /*border-radius: 0.3rem;*/
    border-radius: 0 !important;
    background-color: #cae5ed;
    /*background-color: #216ba5;*/
    /*color: #fff;*/
    color: #000;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    background-color: #44bbdd !important;
  }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range {
    background-color: #f0f0f0;
    /*background-color: #CAE5ED;*/
    color: #000;
  }

  .startEndDates {
    background-color: #44bbdd !important;
  }

  .react-datepicker button:focus {
    outline: none !important;
  }
`;

export default DatePickers;
