import React, { Fragment } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { StoreContext } from '../../../../../stores/RootStore';
import { ChevronRightIcon, DeleteIcon } from 'styles/styledComponents';
import {
  ModalTable,
  ModalTableContainer,
  NoShipTableHeader,
  ModalTHead,
  ChevronTH,
  TxnTypeTR,
  CategoryTR,
  ReasonTR,
  TableTotalsTR,
} from '../StyledShipNoShipModalTableResources';

const RptNoShipTotals = observer(
  ({
    filterTableRows,
    setRespCodeSname,
    fetchNewData,
    formattedNoShipData,
    height,
    toggleNoShipTxnsDisplay,
    noShipCount,
    noShipTotalAmount,
    noShipReason,
    setPageNumber,
  }) => {
    const rootStore = React.useContext(StoreContext);
    const toCurrency = rootStore.utilsStore;
    return (
      <Observer>
        {() => (
          <Fragment>
            <NoShipTableHeader>No!!! - Do Not Ship </NoShipTableHeader>
            {noShipReason && (
              <NoShipTableHeader>
                Decl Reason: {noShipReason} &nbsp;{' '}
                <DeleteIcon
                  style={{
                    width: 22,
                    height: 22,
                  }}
                  color={'#'}
                  hoverColor={'grey'}
                  hoverCursor
                  hoverClick
                  onClick={() => {
                    setRespCodeSname('');
                    setPageNumber(1);
                    // fetchNewData();
                  }}
                />
              </NoShipTableHeader>
            )}
            <ModalTableContainer>
              <ModalTable>
                <ModalTHead>
                  <tr>
                    <ChevronTH />
                    <th>Txn Type</th>
                    <th>Category</th>
                    <th>Reason</th>
                    <th>Count</th>
                    <th>Amount</th>
                  </tr>
                </ModalTHead>
                <tbody>
                  {formattedNoShipData &&
                    filterTableRows(formattedNoShipData).map((row, i) => {
                      switch (row.type) {
                        case 'txnType':
                          return (
                            <TxnTypeTR key={i}>
                              <td onClick={() => toggleNoShipTxnsDisplay(row)}>
                                <ChevronRightIcon
                                  height={height}
                                  open={
                                    (row.type === 'txnType' && row.displayCategories) ||
                                    (row.type === 'category' && row.displayReasons) ||
                                    (row.type === 'reason' && row.displayCategories && row.displayReasons)
                                  }
                                />
                              </td>
                              <td>{row.type === 'txnType' ? row.txnType : ''}</td>
                              <td>{row.type === 'category' ? row.category : ''}</td>
                              <td>{row.type === 'reason' ? row.declineReason : ''}</td>
                              <td>{row.count}</td>
                              <td>{toCurrency(row.total)}</td>
                            </TxnTypeTR>
                          );
                        case 'category':
                          return (
                            <CategoryTR key={i}>
                              <td onClick={() => toggleNoShipTxnsDisplay(row)}>
                                <ChevronRightIcon
                                  height={height}
                                  open={
                                    (row.type === 'txnType' && row.displayCategories) ||
                                    (row.type === 'category' && row.displayReasons) ||
                                    (row.type === 'reason' && row.displayCategories && row.displayReasons)
                                  }
                                />
                              </td>
                              <td>{row.type === 'txnType' ? row.txnType : ''}</td>
                              <td>{row.type === 'category' ? row.category : ''}</td>
                              <td>{row.type === 'reason' ? row.declineReason : ''}</td>
                              <td>{row.count}</td>
                              <td>{toCurrency(row.total)}</td>
                            </CategoryTR>
                          );
                        default:
                          return (
                            <ReasonTR
                              key={i}
                              onClick={() => {
                                setRespCodeSname(row.declineReason);
                                setPageNumber(1);
                                // fetchNewData();
                              }}
                            >
                              <td />
                              <td>{row.type === 'txnType' ? row.txnType : ''}</td>
                              <td>{row.type === 'category' ? row.category : ''}</td>
                              <td>{row.type === 'reason' ? row.declineReason : ''}</td>
                              <td>{row.count}</td>
                              <td>{toCurrency(row.total)}</td>
                            </ReasonTR>
                          );
                      }
                    })}

                  <TableTotalsTR>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Totals</td>
                    <td>{noShipCount}</td>
                    <td>{noShipTotalAmount}</td>
                  </TableTotalsTR>
                </tbody>
              </ModalTable>
            </ModalTableContainer>
          </Fragment>
        )}
      </Observer>
    );
  }
);

export default RptNoShipTotals;
