import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect } from 'react';
import Moment from 'moment';
import styled from 'styled-components/macro';
import { DatesContainer } from './StyledFilter';
import { FilterBtnPopup, Pills } from './FiltersComponents';
import { CalendarIcon, ClearIcon, MenuBarsIcon } from 'styles/styledComponents';
import { Button, DatePicker, PopupMenu, Toggle } from 'components/UI';
import {
  chargebackFilterProps,
  commonFilterProps,
  declineFilterProps,
  opportunityFilterProps,
  txnFilterProps
} from './FiltersComponents/FilterProps/FilterProps';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import { _get, _isNaN, _isNumber, _toNumber, useInjectStores } from 'utils/utils';
import {useHistory, useLocation} from "react-router-dom";

const Filter = observer((props) => {
  const { filterStore, dataManagementStore, rootStore } = useInjectStores();
  const cFilter = filterStore.appliedCommonFilters,
    sFilter = filterStore.appliedSecondaryFilters;
  const history = useHistory();
  const location = useLocation();

  const store = useLocalStore(() => ({
    showDatePopup: false,
    showFilterPopup: false,
    showPreBuiltFilterPopup: false,
    height: 42,
    pillsContainerHeight: 0,
    savingFilter: false,
    toggleShowFilter() {
      store.showDatePopup = false;
      store.showPreBuiltFilterPopup = false;
      store.showFilterPopup = !store.showFilterPopup;
    },
    toggleShowDatePopup() {
      store.showFilterPopup = false;
      store.showPreBuiltFilterPopup = false;
      store.showDatePopup = !store.showDatePopup;
    },
    toggleShowPreBuiltFilter() {
      store.showDatePopup = false;
      store.showFilterPopup = false;
      store.showPreBuiltFilterPopup = !store.showPreBuiltFilterPopup;
    },
    hidePreBuiltFilterPopup() {
      store.showPreBuiltFilterPopup = false;
    },
    hideDatePopup() {
      store.showDatePopup = false;
    },
    hideFilterPopup() {
      store.showFilterPopup = false;
    },
    applyFilter() {
      rootStore.filterStore.applyFilter();
    },
    fetchFilteredData() {
      rootStore.dataManagementStore.setNewQuery();
    },
    toggleSideBar() {
      rootStore.uiStore.toggleSidebar();
    },
    validateNumber({ setModel, setError }, name, value) {
      let verifiedValue = _toNumber(value);
      if (_isNumber(verifiedValue) && !_isNaN(verifiedValue)) {
        setModel(name, verifiedValue);
      } else {
        let error = `${name}Error`;
        setError(error);
      }
    },
    get filterStore() {
      return rootStore.filterStore;
    },
    get commonFilterModel() {
      return rootStore.filterStore.commonFilter;
    },
    get transactionFilterModel() {
      return rootStore.filterStore.transactionFilter;
    },
    get declineFilterModel() {
      return rootStore.filterStore.declineFilter;
    },
    get chargebackFilterModel() {
      return rootStore.filterStore.chargebackFilter;
    },
    get opportunityFilterModel() {
      return rootStore.filterStore.opportunityFilter;
    },
    get commonFilterProps() {
      return commonFilterProps(store, rootStore.ccBrandIds);
    },
    get transactionFilterProps() {
      return txnFilterProps(store, rootStore.txnTypes, rootStore.txnStatuses);
    },
    get declineFilterProps() {
      return declineFilterProps(store, rootStore.declBkt, rootStore.txnTypes, rootStore.declRsn);
    },
    get chargebackFilterProps() {
      return chargebackFilterProps(store, rootStore.txnTypes, rootStore.chgbkRsn);
    },
    get opportunityFilterProps() {
      return opportunityFilterProps(store);
    },
    get showSidebar() {
      return _get(rootStore, 'uiStore.showSidebar');
    },
    get secondaryFilterProps() {
      return store[`${store.filterStore.secondaryFilterName}Props`];
    },
    get dataType() {
      return _get(rootStore, 'userStore.dataType');
    },

    get startDt() {
      let type = _get(filterStore, 'dateFilter.dateType');
      if (_get(dataManagementStore, 'newQuery')) {
        if (type === 'txnDt') {
          return Moment(_get(dataManagementStore.newQuery, 'startDt')).format('M/DD/YYYY');
        } else {
          return Moment(_get(dataManagementStore.newQuery, 'startStmtDt')).format('M/DD/YYYY');
        }
      }
      return undefined;
    },
    get endDt() {
      let type = _get(filterStore, 'dateFilter.dateType');
      if (_get(dataManagementStore, 'newQuery')) {
        if (type === 'txnDt') {
          return Moment(_get(dataManagementStore.newQuery, 'endDt')).format('M/DD/YYYY');
        } else {
          return Moment(_get(dataManagementStore.newQuery, 'endStmtDt')).format('M/DD/YYYY');
        }
      }
      return undefined;
    },
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        store.applyFilter();
        store.toggleShowFilter();
        store.fetchFilteredData();
      }
    },
    resetState() {
      store.filterStore.resetState();
    },
  }));

  function applyForcedVoidsFilter() {
    rootStore.filterStore.applyForcedVoidsFilter();
    store.applyFilter();
    store.toggleShowPreBuiltFilter();
    store.fetchFilteredData();
  }

  useEffect(() => {
    let clientMidOptions = rootStore.clientMidNums.map((opt, index) => ({
      key: index,
      text: opt.text,
      value: opt.value,
      checked: false,
    }));
    clientMidOptions.unshift({ key: 'All', text: 'All', value: 'All', checked: true });
    store.commonFilterModel.setClientMidOptions(clientMidOptions);
    filterStore.mapFilterDropdownsToModels();
  }, [rootStore.clientMidNums]);

  useEffect(() => {
    let txnTypes = rootStore.txnTypes.map((opt, index) => ({
      key: index,
      text: opt.text,
      value: opt.value,
      checked: false,
    }));
    txnTypes.unshift({ key: 'All', text: 'All', value: 'All', checked: false });
    store.transactionFilterModel.setTxnTypeOptions(txnTypes);
  }, [rootStore.txnTypes]);

  useEffect(() => {
    let txnStatusIDLOptions = rootStore.txnStatuses.map((opt, index) => {
      return {
        key: index,
        text: opt.text,
        value: opt.value,
        checked: false,
      };
    });
    txnStatusIDLOptions.unshift({ key: 'All', text: 'All', value: 'All', checked: false });
    store.transactionFilterModel.setTxnStatusIDLOptions(txnStatusIDLOptions);
  }, [rootStore.txnStatuses]);

  useEffect(() => {
    return function cleanup() {
      rootStore.filterStore.mapFilterDropdownsToModels();
    };
  }, [rootStore.clientMidNums]);

  const setArchiveData = () => {
    dataManagementStore.setArchiveData();
    rootStore.userStore.setDataType('archive');
    rootStore.filterStore.dateFilter.setDataType('archive');
    rootStore.filterStore.dateFilter.afterCreate();
    window.sessionStorage.setItem('dataType', 'archive');
    store.filterStore.dateFilter.applyNewDataType();
  };

  const setCurrentData = () => {
    dataManagementStore.setCurrentData();
    rootStore.userStore.setDataType('current');
    rootStore.filterStore.dateFilter.setDataType('current');
    rootStore.filterStore.dateFilter.afterCreate();
    window.sessionStorage.setItem('dataType', 'current');
    store.filterStore.dateFilter.applyNewDataType();
  };

  return (
    <Observer>
      {() => (
        <FilterContainerHolder moveLeft={rootStore.uiStore.showSidebar}>
          {!rootStore.uiStore.showSidebar && (
            <div
              style={{
                padding: '0 5px',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              className="bars"
              onClick={store.toggleSideBar}
            >
              <MenuBarsIcon style={{ width: 20, height: 20, zIndex: 2 }} />
            </div>
          )}

          <div className={'stackingEffect one'}>
            <Button type="primary" width="36px" height="36px" margin="2px 0 0 5px" onClick={store.toggleShowDatePopup}>
              <CalendarIcon id="filterBtn" color={'#fff'} style={{ width: 33, height: 33, paddingRight: 1 }} />
            </Button>

            {store.showDatePopup && (
              <PopupMenu show={store.showDatePopup} hidePopup={store.hideDatePopup} top={'49px'} marginTop={30} padding={'1px'}>
                <div className="arrowUp" />
                <div className={'filterContainer'}>
                  <DatePicker toggleShowDatePopup={store.toggleShowDatePopup} />
                </div>
              </PopupMenu>
            )}
          </div>
          <DatesContainer className="two">
            <div className="filterDates">
              <div>start: &nbsp;</div>
              <div>{store.startDt}</div>
            </div>
            <div className="filterDates">
              <div>end: &nbsp;</div>
              <div>{store.endDt}</div>
            </div>
          </DatesContainer>

          <div className={'stackingEffect three'}>
            <Observer>
              {() => (
                <FilterBtnPopup
                  store={store}
                  cleanup={() => {
                    rootStore.filterStore.removeUnappliedFilters();
                    rootStore.filterStore.mapFilterDropdownsToModels();
                  }}
                  hideFilter={props.hideFilter}
                  // rootStore={rootStore}
                />
              )}
            </Observer>
          </div>
          <div className={'stackingEffect four'}>
            {/*<Observer>
          {() => (
            <SavedPrebuiltFilters
              hideFilter={props.hideFilter}
              hidePreBuiltFilterPopup={store.hidePreBuiltFilterPopup}
              showPreBuiltFilterPopup={store.showPreBuiltFilterPopup}
              toggleShowPreBuiltFilter={store.toggleShowPreBuiltFilter}
              handleKeyDown={store.handleKeyDown}
              applyForcedVoidsFilter={applyForcedVoidsFilter}
            />
          )}
        </Observer>*/}
          </div>
          <div className={'stackingEffect five'}>
            {!props.hideFilter && (
              <Button
                type="delete"
                width="30px"
                height="36px"
                margin="1px 0 0 5px"
                onClick={() => {
                  store.filterStore.resetState(history, location);
                  store.fetchFilteredData();
                  store.filterStore.mapFilterDropdownsToModels();
                }}
              >
                <ClearIcon id="filterBtn" color="#fff" style={{ width: 30, height: 30 }} />
              </Button>
            )}
          </div>
          <div className={'pillContainer six'}>
            <Observer>
              {() => (
                <Pills
                  rootStore={rootStore}
                  store={store}
                  cFilter={cFilter}
                  sFilter={sFilter}
                  commonFilterProps={store.commonFilterProps}
                  secondaryFilterProps={store.secondaryFilterProps}
                  fetchFilteredData={store.fetchFilteredData}
                  applyFilter={store.applyFilter}
                  getMappedDataOptions={store.getMappedDataOptions}
                />
              )}
            </Observer>
          </div>
          <div className="seven">
            <div className="buttonContainer">
              {/* <Button
                className={store.dataType === 'current' ? 'active toggleButton' : 'toggleButton'}
                onClick={() => {
                  if(store.dataType === 'current') {
                    return;
                  }
                  setCurrentData();
                }}
              >
                Current
              </Button> */}
              <span className={store.dataType === 'archive' ? 'option-text disabled' : 'option-text'}>Current</span>
              <Toggle value={store.dataType === 'archive'} onChange={() => store.dataType === 'archive' ? setCurrentData() : setArchiveData()}/>
              <span style={{marginLeft: '-20px'}} className={store.dataType === 'archive' ? 'option-text' : 'option-text disabled'}>Archive</span>
              {/* <Button
                className={store.dataType === 'archive' ? 'active toggleButton' : 'toggleButton'}
                disabled={store.dataType === 'archive'}
                onClick={() => {
                  if(store.dataType === 'archive') {
                    return;
                  }
                  setArchiveData();
                }}
              >
                Archive
              </Button> */}
            </div>
          </div>
        </FilterContainerHolder>
      )}
    </Observer>
  );
});

const FilterContainerHolder = styled.div`
  .title {
    width: 100%;
    text-align: center;
  }
  z-index: 3;
  .headerDivider {
    width: 100%;
    height: 4px;
    //position: absolute;
    background: ${({ theme, background }) => theme.background};
  }
  margin: 0 5px;
  display: grid;
  padding: 3px 0;
  height: auto;
  .bars {
    grid-area: bars;
  }
  .one {
    grid-area: one;
  }
  .two {
    grid-area: two;
  }
  .three {
    grid-area: three;
  }
  .four {
    grid-area: four;
  }
  .five {
    grid-area: five;
  }
  .six {
    grid-area: six;
  }
  .seven {
    display: flex;
    align-items: center;
    grid-area: seven;
  }
  // background: ${({ theme }) => theme.tableStyles.backgroundColor};
  background: ${({ theme }) => theme.rgl.gridItem.colors.background};
  grid-template-areas: 'bars one two three four five six seven';
  grid-template-columns: auto auto auto auto auto auto 1fr 210px;

  @media (max-width: 800px) {
    grid-template-columns: auto auto auto auto auto 1fr 210px;
    grid-template-areas:
      'bars one two three four five seven'
      'six six six six six six six';
    //grid-template-columns: repeat(auto);
    grid-template-rows: auto auto;
  }

  .filterContainer {
    display: flex;
    flex-direction: column;

    .filterWrapper {
      display: flex;
    }

    .submitContainer {
      display: flex;
      justify-content: flex-end;
      padding: 2px 0;
      background-color: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
    }
  }
  .arrowUpFilter {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    left: 17px;
    border-bottom-color: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
  }
  .arrowUp {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    left: 20px;
  }
  .stackingEffect {
    position: relative;
    z-index: 1;
    height: 100%;
  }
  .pillContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
  }

  .prebuiltFilter {
    width: 100%;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .toggleButton {
    fontFamily: 'Futura PT, sans-serif',
    cursor: pointer;
    border: solid 1px white;
    background-color: white;
    border-radius: 5px;
    color: rgba(0,0,0,.7);
    height: 35px;
    outline: none;
    padding: 0px 5px;
    font-size: 14px;
    &:hover {
      cursor: pointer;
      background: #3586c8;
      border: solid 1px #3586c8;
      color: rgba(255,255,255,.8);
    }
  }
  .active {
    background: #3EA8F5;
    border: solid 1px white;
    color: white;
    &:hover {
      cursor: not-allowed;
      background: #3EA8F5;
      border: solid 1px white;
      color: white;
    }
  }
  .option-text {
    color: white;
  }
  .disabled {
    color: rgba(255,255,255,.4);
  }
`;
export default Filter;
