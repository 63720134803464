import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { useLocalStore, Observer, observer } from 'mobx-react-lite';
import { _get } from 'utils/utils';
import { SSWidgetWrapper, getAgDefs } from './resources';
import { LoadingSpinner, Table } from 'components/UI';
import { useLocation, useHistory } from 'react-router-dom';
import { StoreContext } from 'stores/RootStore';

const widgetConstants = {
  id: 21,
  name: 'glTxns',
};

const initialData = { txns: [], count: 0 };
const GlTxns = observer(() => {
  let location = useLocation();
  let history = useHistory();

  const { name } = widgetConstants;
  const rootStore = React.useContext(StoreContext);
  const widgetModel = rootStore.widgetStore.glTxnsWidgetModel,
    dataManagementStore = rootStore.dataManagementStore,
    utilsStore = rootStore.utilsStore,
    paginatorModel = widgetModel.paginatorModel;

  const { columnDefs, defaultColDef, orderByOptions } = getAgDefs(utilsStore.toCurrencyFormatter, utilsStore.toDateFormatter);

  const store = useLocalStore(() => ({
    fileName: 'gl-txns',
    loading: false,
    widgetData: initialData,
    get txnData() {
      return (store.txnsData = store.widgetData.txns.map((txn, index) => {
        txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
        return {
          ...txn,
        };
      }));
    },
    get txnCount() {
      return store.widgetData.count;
    },
    async getData() {
      store.loading = true;
      try {
        const res = await widgetModel.fetchWidgetData();
        store.widgetData = res.data.data.widgets[name].data;
      } catch (error) {
        console.error('error', error);
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          rootStore.userStore.logout(history, location);
        }
      }
      store.loading = false;
    },
    columnDefs,
    defaultColDef,
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (store.dataManagementStore.newQuery) {
          paginatorModel.setPageNumber(1);
          await store.getData();
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <SSWidgetWrapper>
          {store.loading && <LoadingSpinner size={'200px'} />}
          {!store.loading && store.txnData && (
            <Table
              animateRows
              columnDefs={store.columnDefs}
              defaultColDef={store.defaultColDef}
              fetchTableData={store.getData}
              loading={store.loading}
              reactNext
              rowData={store.txnData}
              rowSelection="multiple"
              suppressMenuHide
              suppressMenu
              /**export**/
              initiateTxnsExport={store.widgetModel.initiateTxnsExport}
              exportInfo={store.widgetModel.exportInfo}
              /** Paginator-Specific Functions **/
              txnCount={store.txnCount}
              paginator={store.paginatorModel}
              /** OrderBy Properties **/
              addOrderBy={store.widgetModel.addOrderBy}
              orderByArray={store.widgetModel.orderByModel}
              orderByOptions={store.orderByOptions}
              resetOrderBy={store.widgetModel.resetOrderBy}
            />
          )}
        </SSWidgetWrapper>
      )}
    </Observer>
  );
});

export default GlTxns;
