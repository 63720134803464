import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle } from './styles/styledComponents/GlobalStyle';
import Store, { StoreProvider } from './stores/RootStore';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';

var mountNode = document.getElementById('root');
const store = Store.create();

const queryClient = new QueryClient();

ReactDOM.render(
  <StoreProvider store={store}>
    <React.Fragment>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      <div id="modalRoot" />
    </React.Fragment>
  </StoreProvider>,
  mountNode
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
