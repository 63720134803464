import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import {
  checkAll,
  checkOptionsFromModel,
  unCheckAll,
  filterOption,
  toggleSingleOption,
  onChangeMultiSelect,
} from '../../utils/filterUtils';
const { string, maybe } = types;

const TransactionFilter = types
  .model({
    category: maybe(string),
    collected: maybe(string),
    group: maybe(string),
    invNum: maybe(string),
    origCustName: maybe(string),
  })
  .volatile((self) => ({
    initialState: {},
    txnTypeIDError: false,
    txnStatusIDLError: false,
    invNumError: false,
    origCustNameError: false,
    categoryError: false,
    collectedError: false,
    groupError: false,
    //multi select options
    txnTypeOptions: [],
    txnStatusIDLOptions: [],
    txnBatchNumber: '',
    agentNm: '',
  }))
  .views((self) => ({}))
  .actions((self) => {
    function afterCreate() {
      self.initialState = getSnapshot(self);
    }
    function setModel(name, value) {
      self[`${name}Error`] = false;
      self[name] = value;
    }
    function setTxnTypeOptions(txnTypeOptions) {
      self.txnTypeOptions = txnTypeOptions;
    }
    function setTxnStatusIDLOptions(txnStatusIDLOptions) {
      self.txnStatusIDLOptions = txnStatusIDLOptions;
    }
    function setTxnStatusIDL(txnStatusIDL) {
      self.txnStatusIDL = txnStatusIDL;
    }
    function setTxnType(txnTypeID) {
      self.txnTypeID = txnTypeID;
    }
    function setCategory(category) {
      self.category = category;
    }
    function setGroup(group) {
      self.group = group;
    }
    function setTxnBatchNumber(batchNum) {
      self.txnBatchNumber = batchNum;
    }
    function setAgentNm(agentNm) {
      self.agentNm = agentNm;
    }
    function onChangeTxnType(obj) {
      onChangeMultiSelect(obj, self.txnTypeOptions, setTxnType, setTxnTypeOptions);
    }
    function filterTxnType(value) {
      let { optsForModel, mappedOptionsArray } = filterOption(self.txnTypeOptions, value);
      setTxnType(optsForModel.length > 0 ? optsForModel : null);
      setTxnTypeOptions(mappedOptionsArray);
    }
    function onChangeTxnStatusIDL(obj) {
      onChangeMultiSelect(obj, self.txnStatusIDLOptions, setTxnStatusIDL, setTxnStatusIDLOptions);
    }
    function mapTxnDropdownsWithModel() {
      checkTxnStatusIDLOptFromModel();
      checkTxnTypeOptFromModel();
    }
    function checkTxnStatusIDLOptFromModel() {
      checkOptionsFromModel(self.txnStatusIDL, self.txnStatusIDLOptions, setTxnStatusIDLOptions);
    }
    function checkTxnTypeOptFromModel() {
      checkOptionsFromModel(self.txnTypeID, self.txnTypeOptions, setTxnTypeOptions);
    }

    // Select all but don't use any value (need this for not showing the pills)
    // Select all use all values (pills) - deselect all only when using the values is the applicable
    // Select all always check all boxes
    // If any box is checked besides the all all is not checked
    // always set options based on whats in the model.

    function selectTxnStatusIDL(obj) {
      let txnStatusesForModel = [];
      let updatedOptions = [];
      if (obj.value === 'All') {
        const { optsForModel, mappedOptionsArray } = obj.checked
          ? checkAll(self.txnStatusIDLOptions)
          : unCheckAll(self.txnStatusIDLOptions);
        obj.checked = !obj.checked;
        txnStatusesForModel = optsForModel;
        updatedOptions = mappedOptionsArray;
      } else {
        const { optsForModel, mappedOptionsArray } = toggleSingleOption(self.txnStatusIDLOptions, obj);
        txnStatusesForModel = optsForModel;
        updatedOptions = mappedOptionsArray;
      }
      setTxnStatusIDL(txnStatusesForModel.length > 0 ? txnStatusesForModel : null);
      setTxnStatusIDLOptions(updatedOptions);
    }

    function filterTxnStatusIDL(value) {
      let { optsForModel, mappedOptionsArray } = filterOption(self.txnStatusIDLOptions, value);
      setTxnStatusIDL(optsForModel.length > 0 ? optsForModel : null);
      setTxnStatusIDLOptions(mappedOptionsArray);
    }
    function reset(name, value) {
      self[name] = value;
    }
    function setError(name) {
      self[name] = true;
    }
    function resetErrors() {
      self.txnTypeIDError = false;
      self.gwIDError = false;
      self.procIDError = false;
      self.invNumError = false;
      self.origCustNameError = false;
    }
    function resetState() {
      applySnapshot(self, self.initialState);
    }
    return {
      setModel,
      setTxnStatusIDL,
      setTxnType,
      setCategory,
      setGroup,
      onChangeTxnType,
      onChangeTxnStatusIDL,
      filterTxnType,
      setTxnTypeOptions,
      setTxnStatusIDLOptions,
      mapTxnDropdownsWithModel,
      selectTxnStatusIDL,
      filterTxnStatusIDL,
      reset,
      setError,
      resetErrors,
      resetState,
      setTxnBatchNumber,
      setAgentNm
    };
  });

export default TransactionFilter;
