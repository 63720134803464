import styled from 'styled-components/macro';
import { _includes } from 'utils/utils';

const SSWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width || 'auto'};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

function getAgDefs(toCurrencyFormatter, toDateFormatter, fetchData, showTxnModal) {
  return {
    orderByOptions: [
      { key: 1, text: 'Cust Name', value: 'custName' },
      { key: 2, text: 'Invoice Num', value: 'invNum' },
      { key: 3, text: 'Decl Rsn', value: 'respCodeSname' },
      { key: 4, text: 'Txn Date', value: 'txnDt' },
    ],
    columnDefs: [
      {
        headerName: 'Details',
        field: 'details',
        pinned: 'left',
        cellRenderer: (params) => {
          const {
            data: { id: txnID },
          } = params;
          var button = document.createElement('button');
          button.innerText = 'Open';
          button.color = 'rgba(255, 255, 255)';

          button.className = 'apply';
          button.addEventListener('click', async (e) => {
            e.preventDefault();
            await fetchData(txnID);
            showTxnModal(txnID, params.rowIndex);
          });
          return button;
        },
      },
      { headerName: 'Row', field: 'Index', pinned: 'left', width: 80, checkboxSelection: true },
      { headerName: 'MID', field: 'mid' },
      { headerName: 'Payment Type', field: 'pymt_type' },
      { headerName: 'Denial Bkt', field: 'denial_bkt' },
      { headerName: 'Denial Rsn', field: 'denial_rsn' },
      { headerName: 'Denail SubRsn', field: 'denial_subrsn' },
      { headerName: 'Txn Date', field: 'txn_dt', valueFormatter: toDateFormatter },
      { headerName: 'Txn Bkt', field: 'txn_bkt' },
      { headerName: 'Txn Type', field: 'txn_type' },
      { headerName: 'Txn Status', field: 'txn_stat' },
      { headerName: 'Txn Amount', field: 'txn_amt', valueFormatter: toCurrencyFormatter },
      { headerName: 'Txn Currency', field: 'txn_curr' },
      { headerName: 'Total Fees', field: 'tot_fees', valueFormatter: toCurrencyFormatter },
      { headerName: 'Invoice Num', field: 'invoice_num' },
      { headerName: 'Invoice Desc', field: 'invoice_desc' },
      { headerName: 'Customer', field: 'customer' },
      { headerName: 'Customer Ph.', field: 'cust_ph' },
      { headerName: 'CC Bin', field: 'cc_bin' },
      { headerName: 'CC Last 4', field: 'cc_last_4' },
      { headerName: 'Opp tf', field: 'opp_tf' },
      { headerName: 'Opp Collect tf', field: 'opp_collect_tf' },
      { headerName: 'Opp Collect dt', field: 'opp_collect_dt', valueFormatter: toDateFormatter },
      { headerName: 'Opp Collect Amt', field: 'opp_collect_amt' },
      { headerName: 'Opp Collect By', field: 'opp_collect_by' },
      { headerName: 'Txn ID', field: 'txn_id' },
    ],
    defaultColDef: {
      minWidth: 90,
      maxWidth: 300,
      resizable: true,
      onCellClicked: async function (params) {
        const {
          column: { colId },
          // columnApi: { fetchData, showTxnModal },
          // data: { id: txnID },
          value,
        } = params;

        function updateClipboard(newClip) {
          navigator.clipboard.writeText(newClip).then(
            function () {},
            function () {}
          );
        }

        if (colId !== 'details' && colId !== 'index') updateClipboard(value);
      },
      cellStyle: function (params) {
        let negativeStyleColumns = [
          'Txn Amt',
          'Funct Amt',
          'Total Fees',
          'Settle Amt',
          'Settle Funct Amt',
          'Submit Amt',
          'Submit Funct Amt',
        ];
        if (_includes(negativeStyleColumns, params.colDef.headerName) && params.value < 0) {
          return { color: 'red', cursor: 'pointer' };
        }
        return { cursor: 'pointer' };
      },
    },
  };
}

const initialData = { txns: [], count: 0 };

export { SSWidgetWrapper, getAgDefs, initialData };
