import React, { useEffect, useState } from 'react';
/** General Styled Components **/
/** Styled Components Specific to Client Data Mgmt **/
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { _find, _get, useInjectStores } from 'utils/utils';
import styled from 'styled-components/macro';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { Statement } from '../TsysStmt';
import { Dropdown, LoadingSpinner, Modal } from 'components/UI';
import { ReportBody, ReportWrapper, WidgetHeader, WidgetWrapper } from 'styles/styledComponents';
import { AgGridReact } from 'ag-grid-react';
import { getColumnDefs, getGridOptions } from '../resources';

const TsysStatement = observer((props) => {
  const { dataManagementStore, userStore, utilsStore } = useInjectStores();
  const clientName = userStore.user.currentClient.clientName;
  const history = useHistory();
  const location = useLocation(),
    toCurrencyFormatter = utilsStore.toCurrencyFormatter;

  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const PdfModal = ({ pdfUrl, onClose }) => {
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ backgroundColor: 'white', padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' }}>
        <button style={{width: '100px'}} onClick={onClose}>Close</button>
          <iframe src={pdfUrl} width="800" height="700" title="TSYS Statement"></iframe>
        </div>
      </div>
    );
  };

  const downloadPDF = (pdfString, date) => {
    const blob = new Blob([pdfString], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `${clientName} TSYS STMT ${date}.pdf`;
    document.body.appendChild(link);
    link.click();
  
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(fileURL);
  };

  const store = useLocalObservable(() => ({
    showStatementModal: false,
    showDownloadModal: false,
    isLoading: false,
    widgetData: null,
    selectedMid: '',
    statementList: [],
    selectedMidStatementDates: [],
    clientName: '',
    downloadId: '',
    get isDemo() {
      return _get(userStore,'user.demo', false);
    },
    get customer() {
      return _get(this, 'widgetData[0].stmt["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]');
    },
    get columnDefs() {
      return getColumnDefs(this.getData, toCurrencyFormatter);
    },
    get gridOptions() {
      return getGridOptions(this.onGridReady);
    },
    setDownloadId(id) {
      this.downloadId = id;
    },
    setLoading(loading) {
      this.isLoading = loading;
    },
    closeModal() {
      this.currentStatement = '';
    },
    setWidgetData(data) {
      this.widgetData = data;
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridColumnApi = params.columnApi;
    },
    toggleShowTxnDetailModal() {
      this.showStatementModal = false;
    },
    selectMid(val) {
      this.selectedMid = val;
      const selected = _find(this.statementList, { value: this.selectedMid });
      this.selectedMidStatementDates = selected.statements;
    },
    setClientName(name) {
      this.clientName = name;
    },
    async openModal(selectedStatement) {
      this.showStatementModal = true;
      await this.getStatementData(selectedStatement);
    },
    async getData(mid, date, id, download) {
      this.isLoading = true;
      this.downloadId = id;
      try {
        let res = await dataManagementStore.fetchTsysStatement(mid, date, id);
        this.widgetData = _get(res, 'data');
        // If the PDF is coming back from TSYS download and return;
        if(this.widgetData && this.widgetData.pdf) {
          if(download) {
            await downloadPDF(this.widgetData.pdf, this.widgetData.prc_mnth);
            this.isLoading = false;
            return;
          } else {
            const blob = new Blob([this.widgetData.pdf], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            setPdfUrl(fileURL);
            setShowModal(true);
            this.isLoading = false;
            return;
          }
        }
        if (download) {
          this.showDownloadModal = true;
          this.showStatementModal = true;
        } else {
          this.showStatementModal = true;
          this.showDownloadModal = false;
        }
      } catch (error) {
        console.error('err:', error);
        if (download) {
          this.showDownloadModal = false;
          this.showStatementModal = false;
        } else {
          this.showStatementModal = false;
        }
        this.widgetData = null;
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      this.isLoading = false;
    },
    async getStatementData() {
      this.isLoading = true;
      try {
        let res = await dataManagementStore.fetchTsysStatementList();
        const list = _get(res, 'data');
        this.statementList = list.map(({ mid, statements }) => {
          return { key: mid, text: mid, value: mid, statements: statements };
        });
        this.selectedMidStatementDates = this.statementList[0].statements;
        this.selectedMid = this.statementList[0].value;
      } catch (error) {
        console.error('err:', error);
        this.statementList = [];
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      this.isLoading = false;
    },
  }));

  useEffect(() => {
    store.setClientName(clientName);
  }, [userStore.user]);

  useEffect(
    () =>
      autorun(async function () {
        if (dataManagementStore.newQuery) {
          await store.getStatementData();
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <Wrapper>
          <WidgetWrapper maxWidth="500px" padding="0 0 40px 0" margin="0 0 10px 0">
            {store.isLoading && <LoadingSpinner size={'200px'} />}
            {!store.isLoading && store.statementList && store.statementList.length > 0 && (
              <>
                <div style={{ display: 'flex', alignItems: 'left', height: 40 }}>
                  <div style={{ position: 'absolute', left: 5, top: 8 }}>
                    {store.isDemo ? '' : (
                    <Dropdown
                      height={'25px'}
                      options={store.statementList}
                      onChange={store.selectMid}
                      placeholder="MID"
                      toggleDisable={store.toggleDisable}
                      value={store.selectedMid}
                      labelWidth={'0'}
                      width={'175px'}
                      margin={'0 2px 0 6px'}
                      border
                    />
                        )}
                  </div>
                  <WidgetHeader><div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}><div>MID: {store.isDemo ? 888800000008888 : store.selectedMid} </div><div><span style={{color: 'red'}}>* </span>{`Details here are taken from your industry statement; which do not adhere to the standard calendar month.`}</div></div></WidgetHeader>
                </div>
                <div></div>

                <GridWrapper>
                  <div
                    id="myGrid"
                    className={'ag-theme-alpine-dark'}
                    style={{
                      height: '100%',
                      minHeight: '300px',
                      width: '100%',
                      position: 'relative',
                      borderRadius: '5px',
                    }}
                  >
                    <AgGridReact
                      onGridReady={store.onGridReady}
                      rowData={store.selectedMidStatementDates}
                      columnDefs={store.columnDefs}
                      gridOptions={store.gridOptions}
                    />
                  </div>
                </GridWrapper>
              </>
            )}
          </WidgetWrapper>
          {store.showStatementModal && (
            <Modal
              closeRight="30px"
              height="100%"
              width={'90vw'}
              toggleModal={store.toggleShowTxnDetailModal}
              isOpen={store.showStatementModal}
              isDownload={store.showDownloadModal}
              colorIcon={'#000'}
              // isOpen={false}
            >
              <ReportWrapper width={'88vw'} padding={'0 20px'} height="100%" noBackground={true}>
                <ReportBody padding="0 5px 5px 5px" height="100%">
                  <Statement store={store} downloadId={store.downloadId} />
                </ReportBody>
              </ReportWrapper>
            </Modal>
          )}
          {showModal && <PdfModal pdfUrl={pdfUrl} onClose={() => setShowModal(false)} />}

        </Wrapper>
      )}
    </Observer>
  );
});

export { TsysStatement };

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  //grid-area: TopEight;
  color: ${({ theme }) => theme.colors.primaryText};
  .reportWrapper {
    display: flex;
    //height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px 5px;
  }
  .spaceBtwn {
    padding: 4px;
    margin: 0 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }
  .row {
    display: flex;
  }
  .apply {
    background-color: #3fa9f5;
    color: rgba(255, 255, 255);
    border-radius: 3px;
    border: none;
    width: 80px;
    outline: none;
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.baseColors.colorBlueHover};
    }
  }
`;

const GridWrapper = styled.div`
  height: 100%;
  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    .ag-header-row {
      height: 30px;
    }
    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }
    .topLevel {
      background-color: #001329 !important;
    }
    .secondLevel {
      background-color: #032845 !important;
    }
  }
  grid-area: SsTotalsGraph;
  position: relative;
  // width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }
  .my-tooltip > .tooltip-inner {
    background-color: red;
  }
  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }
  .noData {
    margin: auto;
  }
  .apply {
    background-color: #3fa9f5;
    color: rgba(255, 255, 255);
    border-radius: 3px;
    border: none;
    width: 80px;
    outline: none;
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.baseColors.colorBlueHover};
    }
  }
`;
