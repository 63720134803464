import React, {useEffect} from 'react';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon,
    ReportBody,
    ReportWrapper,
    TableWrapper,
    RowContainer,
} from 'styles/styledComponents';
import {Modal, LoadingSpinner, Notes} from 'components/UI';
import {useLocalStore, Observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {TxnAmounts, TxnData, CCData, MiscData} from './index';
// import { ActionList } from './components/UI/NotesActionList/ActionList';
// import { Notes } from './components/UI/NotesActionList/Notes';
import {useHistory, useLocation} from 'react-router-dom';
import {useInjectStores, _get, _map, _set, _cloneDeep} from 'utils/utils';

const TxnDetailModal = ({rowData}) => {
    const history = useHistory();
    const location = useLocation();
    const {dataManagementStore, uiStore, userStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency,
        toDateFormatterValue = utilsStore.toDateFormatterValue,
        toDateFormatterHoursValue = utilsStore.toDateFormatterHoursValue,
        showTxnDetailsModal = uiStore.showTxnDetailModal,
        toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal;

        const rowIndex = uiStore.rowIndex;

    const store = useLocalStore(() => ({
        loading: false,
        currentTxnID: '',
        selectedMid: '',
        get isDemo() {
            return _get(userStore, 'user.demo', false);
        },
        get genInfo() {
            let genInfo = _cloneDeep(_get(dataManagementStore, 'txnDetails'));
            if (!_get(genInfo, 'bin_attack_tf')) {
                _set(genInfo, 'bin_attack_tf', '');
            }
            return genInfo;
        },
        get theme() {
            return _get(uiStore, 'currentTheme');
        },
        pageNumber: rowIndex + 1,
        get totalPages() {
            return rowData.length;
        },
        get showUptoFivePages() {
            let currentPage = store.pageNumber;
            let totalPages = store.totalPages;
            let pages = [];
            if (totalPages > 5) {
                if (currentPage <= 3) {
                    return [1, 2, 3, 4, 5];
                }
                if (currentPage >= totalPages - 3) {
                    return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
                } else {
                    return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
                }
            }
            for (let i = 0; i < totalPages; i++) {
                pages.push(i + 1);
            }
            return pages;
        },
        get canGoLeft() {
            return store.pageNumber > 1;
        },
        get canGoRight() {
            return store.pageNumber < store.totalPages;
        },
        get displayStartIndex() {
            if (store.totalPages === 0) return 0;
            else return (store.pageNumber - 1) * store.totalPages + 1;
        },
        get realStartIndex() {
            return (store.pageNumber - 1) * store.totalPages;
        },
        async paginatorIncrementPageNumber() {
            if (store.pageNumber < store.totalPages) {
                store.pageNumber += 1;
                store.currentTxnID = rowData[store.pageNumber - 1].display_id;
                store.selectedMid = rowData[store.pageNumber - 1].mid;
                store.loading = true;
                await dataManagementStore.fetchTransaction(store.currentTxnID, store.selectedMid, history, location);
                store.loading = false;
            }
        },
        async paginatorDecrementPageNumber() {
            if (store.pageNumber > 1) {
                store.pageNumber -= 1;
                store.currentTxnID = rowData[store.pageNumber - 1].display_id;
                store.selectedMid = rowData[store.pageNumber - 1].mid;
                store.loading = true;
                await dataManagementStore.fetchTransaction(store.currentTxnID, store.selectedMid, history, location);
                store.loading = false;
            }
        },
        async paginatorSetFirstPage() {
            if (store.pageNumber > 1 && !store.loading) {
                store.pageNumber = 1;
                store.currentTxnID = rowData[store.pageNumber - 1].display_id;
                store.selectedMid = rowData[store.pageNumber - 1].mid;
                store.loading = true;
                await dataManagementStore.fetchTransaction(store.currentTxnID, store.selectedMid, history, location);
                store.loading = false;
            }
        },
        async paginatorSetLastPage() {
            if (store.pageNumber < store.totalPages && !store.loading) {
                store.pageNumber = rowData.length;
                store.currentTxnID = rowData[store.pageNumber - 1].display_id;
                store.selectedMid = rowData[store.pageNumber - 1].mid;
                store.loading = true;
                await dataManagementStore.fetchTransaction(store.currentTxnID, store.selectedMid, history, location);
                store.loading = false;
            }
        },
        async paginatorSetPage(page) {
            if (page < store.totalPages + 1 && page > 0) {
                store.pageNumber = page;
                store.currentTxnID = rowData[store.pageNumber - 1].display_id;
                store.selectedMid = rowData[store.pageNumber - 1].mid;
                store.loading = true;
                await dataManagementStore.fetchTransaction(store.currentTxnID, store.selectedMid, history, location);
                store.loading = false;
            }
        },
    }));

    useEffect(() => {
            return function cleanup() {
                dataManagementStore.resetTxnDetails();
            }
        },
        []
    );

    return (
        <Observer>
            {() => (
                <Modal closeRight="30px" height="500px" width={'90vw'} toggleModal={toggleShowTxnDetailModal}
                       isOpen={showTxnDetailsModal}>
                    <ReportWrapper width={'88vw'} padding={'0 20px'} height="100%" noBackground={true}>
                        <TxnDetailHeader padding={'0 3px'} fontSize={'24px'}>
                            <div className="txnDetailsTitle">Transaction Details</div>
                            {/* <PaginatorWrapper>
                                <div className="paginatorInnerWrapper">
                                    <PaginatorArrowLeft onClick={store.paginatorSetFirstPage} endLeft
                                                        canGoLeft={store.canGoLeft}>
                                        <ChevronsLeftIcon style={{height: 25, width: 25}} color={'rgb(91, 91, 91)'}/>
                                    </PaginatorArrowLeft>

                                    <PaginatorArrowLeft onClick={store.paginatorDecrementPageNumber}
                                                        canGoLeft={store.canGoLeft}>
                                        <ChevronLeftIcon style={{height: 25, width: 25}} color={'rgb(91, 91, 91)'}/>
                                    </PaginatorArrowLeft>

                                    {_map(store.showUptoFivePages, (page, index) => {
                                        return (
                                            <PaginatorPageNumIcon
                                                key={index}
                                                height="28px"
                                                currentPage={store.pageNumber === page}
                                                onClick={() => {
                                                    store.paginatorSetPage(page);
                                                }}
                                            >
                                                {page}
                                            </PaginatorPageNumIcon>
                                        );
                                    })}

                                    <PaginatorArrowRight onClick={store.paginatorIncrementPageNumber}
                                                         canGoRight={store.canGoRight}>
                                        <ChevronRightIcon style={{height: 25, width: 25}} color={'rgb(91, 91, 91)'}/>
                                    </PaginatorArrowRight>

                                    <PaginatorArrowRight onClick={store.paginatorSetLastPage}
                                                         canGoRight={store.canGoRight} endRight>
                                        <ChevronsRightIcon style={{height: 25, width: 25}} color={'rgb(91, 91, 91)'}/>
                                    </PaginatorArrowRight>
                                </div>
                            </PaginatorWrapper> */}
                        </TxnDetailHeader>
                        {(store.loading || dataManagementStore.loadingTxnDetails) && (
                            <TableWrapper height="100%">
                                <LoadingSpinner size={'200px'}/>
                            </TableWrapper>
                        )}
                        {!store.loading &&
                            !dataManagementStore.loadingTxnDetails &&
                            store.genInfo &&
                            /*store.cardInfo &&
                          store.txnHist &&
                          store.entryInfo &&
                          store.feeInfo &&
                          store.custInfo &&*/ (
                                <ReportBody padding="0 5px 5px 5px" height="100%">
                                    <RowContainer>
                                        <CCData
                                            isDemo={store.isDemo}
                                            widgetData={store.genInfo}
                                        />
                                        <TxnData
                                            widgetData={store.genInfo}
                                            toCurrency={toCurrency}
                                            toDateFormatterValue={toDateFormatterValue}
                                            toDateFormatterHoursValue={toDateFormatterHoursValue}
                                        />
                                        <TxnAmounts widgetData={store.genInfo} toCurrency={toCurrency}/>
                                        <MiscData widgetData={store.genInfo} toCurrency={toCurrency}/>
                                    </RowContainer>
                                    {/*<RowContainer>*/}

                                    {/*</RowContainer>*/}
                                    {/*<RowContainer>*/}
                                    {/*</RowContainer>*/}
                                </ReportBody>
                            )}
                    </ReportWrapper>
                </Modal>
            )}
        </Observer>
    );
};

const TxnDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  margin: 4px 7px 2px 7px;
  color: ${({color, theme}) => color || theme.rgl.gridItem.colors.primaryText};
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: ${({fontSize}) => fontSize};
  border: none;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;

  .paginatorInnerWrapper {
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    height: 32px;
    margin: 5px;
    max-width: 425px;
  }

  .paginatorAddDrop {
    width: 160px;
  }
`;

/*const PaginatorCurrentPage = styled.div`
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0 5px 0 5px;
  margin: auto 5px;
  .paginatorAddSubtract {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 7px;
    &:hover {
      cursor: pointer;
    }
  }
  color: ${(props) => props.theme.baseColors.colorGreyDark};
`;*/

const PaginatorPageNumIcon = styled.div`
  align-items: center;
  background-color: ${({currentPage}) => (currentPage === true ? '#3FA9F5' : '#fff')};
  border: ${({currentPage}) => (currentPage === true ? '1px solid #A8A8A8' : '1px solid #DBDBDB')};
  color: ${({currentPage}) => (currentPage === true ? '#fff' : 'rgb(91,91,91)')};
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  height: ${({height}) => height || '32px'};
  width: 45px;

  &:hover {
    cursor: pointer;
    background: ${({theme}) => theme.baseColors.colorBlueHover};
    color: ${({theme}) => theme.colors.primaryText};
  }
`;

const PaginatorArrowLeft = styled.div`
  align-items: center;
  background-color: #f0eff0;
  border-bottom-left-radius: ${({endLeft}) => (endLeft ? '5px' : 0)};
  border: 1px solid #dbdbdb;
  border-top-left-radius: ${({endLeft}) => (endLeft ? '5px' : 0)};
  display: flex;
  height: 28px;
  justify-content: center;
  width: 45px;

  &:hover {
    cursor: ${({canGoLeft}) => (canGoLeft ? 'pointer' : 'not-allowed')};
    background: ${({theme}) => theme.baseColors.colorGreyMediumLight};
  }
`;

const PaginatorArrowRight = styled.div`
  align-items: center;
  border: 1px solid #dbdbdb;
  border-bottom-right-radius: ${({endRight}) => (endRight ? '5px' : 0)};
  background-color: #f0eff0;
  border-top-right-radius: ${({endRight}) => (endRight ? '5px' : 0)};
  display: flex;
  height: 28px;
  justify-content: center;
  width: 45px;

  &:hover {
    cursor: ${({canGoRight}) => (canGoRight ? 'pointer' : 'not-allowed')};
    background: ${({theme}) => theme.baseColors.colorGreyMediumLight};
  }
`;
export default TxnDetailModal;
