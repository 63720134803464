import React, { useEffect, useRef } from 'react';
import { _get, _find } from 'utils/utils';
import { Observer, useLocalStore } from 'mobx-react-lite';
import {
  DropdownContainer,
  DropdownOption,
  DropdownWrapper,
  OptionsContainer,
  SelectedOptionInput,
  SelectedOptionContainer,
} from './StyledACDropdown';
import { DeleteIcon } from 'styles/styledComponents';

const ACDropdown = ({
  border,
  margin,
  marginBottom,
  height,
  error,
  width,
  fontSize,
  onChange,
  optContTop,
  optContLeft,
  options,
  value,
  placeholder,
}) => {
  const node = useRef();

  const lStore = useLocalStore(() => ({
    showItems: false,
    filteredText: '',
    get selectedOption() {
      return _get(_find(options, { value }), 'text') || placeholder;
    },
    get filteredOptions() {
      if (lStore.filteredText) {
        return options.filter((option) => {
          let thing = option.text;
          return thing.toLowerCase().includes(lStore.filteredText.toLowerCase());
        });
      } else return options;
    },
    handleInput(e) {
      lStore.filteredText = e.target.value;
    },
    toggleShowItems() {
      lStore.showItems = !lStore.showItems;
    },
  }));

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    lStore.hideItems();
  };

  useEffect(() => {
    if (lStore.showItems) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [lStore.showItems, handleClickOutside]);

  return (
    <Observer>
      {() => (
        <DropdownWrapper ref={node} show={lStore.showItems} border={border} width={width} marginBottom={marginBottom}>
          <DropdownContainer
            onClick={lStore.toggleShowItems}
            margin={margin}
            id="drop"
            width={width}
            show={lStore.showItems}
            fontSize={fontSize}
          >
            <SelectedOptionContainer>
              <SelectedOptionInput
                onChange={lStore.handleInput}
                placeholder={lStore.selectedOption.text || placeholder}
                fontSize={fontSize}
                height={height}
                error={error || ''}
                value={lStore.filteredText}
              />
              <DeleteIcon
                color="black"
                style={{
                  width: 16,
                  height: 16,
                  position: 'absolute',
                  right: 5,
                }}
                onClick={() => {
                  lStore.filteredText = '';
                }}
              />
            </SelectedOptionContainer>
            <OptionsContainer show={lStore.showItems} top={optContTop} left={optContLeft} width={width} border={border}>
              {lStore.filteredOptions &&
                lStore.filteredOptions.map((option, index) => {
                  return (
                    <DropdownOption
                      fontSize={fontSize}
                      show={lStore.showItems}
                      key={`${option.value + ':' + index}`}
                      onClick={() => {
                        lStore.filteredText = option.text;
                        onChange(option.value);
                      }}
                    >
                      <div>{option.text}</div>
                    </DropdownOption>
                  );
                })}
            </OptionsContainer>
          </DropdownContainer>
        </DropdownWrapper>
      )}
    </Observer>
  );
};

export default ACDropdown;
