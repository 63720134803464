import React, {useEffect} from 'react';
import {RouteLoadingSpinner, Input} from 'components/UI';
import {LoginWrapper, LockIcon} from 'styles/styledComponents';
import {LoginPageWrapper} from '../login/StyledLogin';
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {useHistory, useParams} from 'react-router-dom';
import {commonService, adminService} from 'services';
import logo from 'images/GoldenAit/eightLogoSilver.svg';
import {useInjectStores, _get, useCheckAuthorizedRoute} from 'utils/utils';

const Logo = styled.div`
  height: ${({theme, height}) => height || theme.loginLogo.height};
  width: ${({theme, width}) => width || theme.loginLogo.width};
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${logo});
`;

const ResetPassword = observer(() => {
    const history = useHistory();
    let {hash} = useParams();
    const {uiStore, userStore} = useInjectStores();
    const user = userStore.user;
    const store = useLocalObservable(() => ({
        validToken: false,
        password: '',
        reEnterPassword: '',
        titleError: '',
        error: '',
        errorMessage: '',
        isLoading: false,
        setBlur: () => {
        },
        setReEnterPassword(e) {
            this.topError = '';
            this.error = '';
            this.reEnterPassword = e.target.value;
        },
        setPassword(e) {
            this.topError = '';
            this.error = '';
            this.password = e.target.value;
        },
        validatePassword() {
            if (this.password.length === 0) {
                this.passwordError = 'Please enter a valid password';
                return false;
            }
            return true;
        },
        async handleResetPassword(event) {
            event.preventDefault();
            if (this.password !== this.reEnterPassword) {
                this.error = 'Passwords do not match';
                return;
            }
            const validPassword = this.validatePassword();
            if (validPassword) {
                this.isLoading = true;
                try {
                    await commonService.resetPasswordPost(hash, {password: this.password});
                    uiStore.notify({
                        text: 'Password has been reset successfully',
                        group: 'topRight',
                        type: 'success',
                    });
                    history.push('/login');
                } catch (e) {
                    console.error('error', _get(e, 'response.data.messages[0]'))
                    this.error = _get(e, 'response.data.messages[0]')
                }
            }
            this.isLoading = false;
        },
        async checkResetPasswordHash(hash) {
            try {
                let res = await commonService.resetPasswordGet({hash});
                store.validToken = true;
            } catch (e) {
                store.validToken = false;
                console.log('e', e.response);
                let message = _get(e, 'response.data.message', '')
                if (message === 'Unauthorized' || message === 'forbidden') {
                    uiStore.notify({
                        duration: 5000,
                        group: 'topRight',
                        text: 'Invalid or Expired Token',
                    });
                }
                history.push('/login');
            }
        },
    }));

    useEffect(() => {
        if (user) {
            userStore.logout();
        }
    }, [user]);

    useEffect(() => {
        store.checkResetPasswordHash(hash);
    }, [hash]);

    return (
        <Observer>
            {() => (
                <StyleWrapper>
                    <LoginPageWrapper>
                        <LoginWrapper flexDirection="column">
                            <Logo/>
                            <div className="loginFormWrapper">
                                {store.isLoading && <RouteLoadingSpinner size={'400px'}/>}
                                {!store.isLoading && store.validToken && (
                                    <form style={{width: '100%'}} onSubmit={store.handleResetPassword}>
                                        <h3 className="login-state-title">Reset password</h3>
                                        <div className="inputIconWrapper">
                                            <label>Password</label>
                                            <Observer>
                                                {() => (
                                                    <Input
                                                        tabIndex={1}
                                                        marginBottom={'0px'}
                                                        width="100%"
                                                        value={store.password}
                                                        onChange={store.setPassword}
                                                        setBlur={store.setBlur}
                                                        autoComplete={'password'}
                                                        type="password"
                                                        icon
                                                        border
                                                        autoFocus={true}
                                                        boxShadow={'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6)'}
                                                    >
                                                        <LockIcon color="#000"
                                                                  style={{width: 20, height: 20, marginLeft: 5}}/>
                                                    </Input>
                                                )}
                                            </Observer>
                                        </div>

                                        <div className="inputIconWrapper">
                                            <label>Re-Enter Password</label>
                                            <Observer>
                                                {() => (
                                                    <Input
                                                        tabIndex={2}
                                                        width="100%"
                                                        value={store.reEnterPassword}
                                                        marginBottom={'0px'}
                                                        onChange={store.setReEnterPassword}
                                                        type="password"
                                                        autoComplete={'current-password'}
                                                        icon
                                                        border
                                                        boxShadow={'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6)'}
                                                    >
                                                        <LockIcon color="#000"
                                                                  style={{width: 20, height: 20, marginLeft: 5}}/>
                                                    </Input>
                                                )}
                                            </Observer>
                                        </div>
                                        <Observer>
                                            {() => (
                                                <SubButton
                                                    width={'auto'}
                                                    disabled={store.password.length === 0 || store.reEnterPassword.length === 0}
                                                    type="submit"
                                                >
                                                    Reset Password
                                                </SubButton>
                                            )}
                                        </Observer>
                                        {store.error && (
                                            <Observer>
                                                {() => (
                                                    <div className="error">
                                                        <div className="bottomError">{store.error}</div>
                                                    </div>
                                                )}
                                            </Observer>
                                        )}
                                    </form>
                                )}
                            </div>
                        </LoginWrapper>
                    </LoginPageWrapper>
                </StyleWrapper>
            )}
        </Observer>
    );
});

const SubButton = styled.button`
  display: flex;
  width: ${({width}) => width || '100%'};
  margin: 20px auto 0 auto;
  height: 30px;
  text-align: center;
  align-items: center;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  justify-content: center;
  border: 1px solid #e2e2e2;
  background-color: ${({disabled, theme}) => {
    return !disabled ? '#fff' : theme.baseColors.colorGreyMediumLight;
  }};
  box-shadow: 0px 2px 4px 0px #bbbbbb;
  color: ${({disabled, theme}) => (disabled ? theme.baseColors.colorGreyDark : '#4183c4')};
    // border: ${({theme}) => `1px solid #bbbbbb`};
  &:hover {
    cursor: pointer;
      //background-color: ${({theme}) => theme.baseColors.colorGreyMediumLight};
    background-color: #e2e2e2;
  }
`;

const StyleWrapper = styled.div`
  .login-state-title {
    width: 100%;
    text-align: center;
    color: ${({theme}) => theme.baseColors.colorGreyDark};
  }

  .login-state {
    width: 100%;
    text-align: center;
    color: #4183c4;
    cursor: pointer;
    padding-top: 15px;
  }

  .loginLabel {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
    color: #fff;
  }

  .inputIconWrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: 6px;
    border-radius: 5px;
    font-weight: normal;

    label {
      font-size: 12px;
      padding: 5px;
    }

    .notValidEmail {
      font-size: 12px;
      color: red;
      margin: 3px;
    }
  }

  .submitLogin {
    display: flex;
    width: 100%;
    height: 30px;
    text-align: center;
    outline: none;
    color: #4183c4;
    font-size: 14px;
    justify-content: center;
    border: 1px solid #e2e2e2;

    &:hover {
      cursor: pointer;
    }
  }

  .loginPage {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .loginFormWrapper {
    display: flex;
    justify-content: center;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 20px;
    background: #fff;
    width: 300px;
    //min-height: 164px;
    min-height: 180px;
  }

  .error {
    margin: 0.5rem 0 0;
    color: ${({theme}) => theme.colors.colorGreyDark};
    background-color: #fdedee;
    border: 1px solid #f8a9ad;
    padding: 5px 10px;

    .topError {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .bottomError {
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size: 14px;
    }
  }

  .error.show {
    display: block;
  }
`;

export default ResetPassword;
