import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const contactUs = () => {
  return (
    <Wrapper>
          <div>If you have questions, please reach out to our great support team as follows</div>
        <br/>
          <div >Call: <a className={'hyperlink'} css={'padding: 0 0 0 12px'} href="tel:385-309-5976">385-309-5976</a></div>
        <br/>
        <div >Email: <a className={'hyperlink'} href="mailto:support@eight.tech">support@eight.tech</a></div>
        <br/>
        <br/>
          <div>Like most tools and resources in life, we tend to use very little of the true potential.</div>
        <br/>
        <br/>
          <div>For those who want to learn more about Eight Tech & this website, please schedule training or online meeting from the following link</div>
        <br/>
          <a target="_blank" className={'hyperlink'} href={'https://calendly.com/supporteighttech'}>https://calendly.com/supporteighttech</a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  background: ${({ theme, noBackground, background }) => {
    if (background) {
      return background;
    }
    if (!noBackground) return theme.rgl.gridItem.colors.background;
    else return 'none';
  }};
  height: 100%;
  margin: 0 5px;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text};
  padding: 40px;
  background: ${({ theme }) => theme.icons.colors.cartAlpha};
  .hyperlink {
    color: ${({ theme }) => theme.baseColors.colorBlue}; 
  }
`;

export default contactUs;
