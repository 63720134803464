import React from 'react';
import {OpportunityDetails, SsOpportunity} from '../components/Widgets';
import {Observer, observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useCheckAuthorizedRoute} from 'utils/utils';
import {TableWrapper} from '../components/Widgets/StyledTable';
import {Link, Route, useLocation, useRouteMatch} from "react-router-dom";
import {RouteLoadingSpinner} from 'components/UI';
// import Tabs from "../components/Widgets/GlJournals/Tabs";

const Opportunity = observer(() => {
    const {loading, authorized} = useCheckAuthorizedRoute();

    return (
        <Observer>
            {() => (
                <>
                    {loading ? (
                        <RouteLoadingSpinner size={'400px'}/>
                    ) : authorized ? (
                        <Wrapper>
                            {/*<div className="tabs">*/}
                            {/*    <Tabs/>*/}
                            {/*</div>*/}
                            <div className="info">
                                <OpportunityInfo/>
                            </div>
                            <div className="bottom">
                                <Route exact path={`/opportunity`}>
                                    <div className={'overview'}>
                                        <SsOpportunity/>
                                    </div>
                                </Route>
                                <Route exact path={`/opportunity/details`}>
                                    {/*<div className="tableWrapper">*/}
                                        <OpportunityDetails/>
                                    {/*</div>*/}
                                </Route>
                            </div>
                        </Wrapper>
                    ) : (
                        <div>error</div>
                    )}
                </>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  height: 100%;
  flex-direction: column;
  padding: 0 5px;
  display: grid;
  grid-template-areas: 
          //'tabs'
          'info'
           'bottom';
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-gap: 5px;

  .bottom {
    height: 100%;
    grid-area: bottom;
  }

  .overview {
    height:100%;
    display: grid;
    grid-area: table;
    grid-template-areas:
           'info'
          'opp';
    grid-template-rows: 1fr;
  }


 /* .tableWrapper {
    margin: 0 4px 4px 4px;
    height: 100%;
  }

  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .tabWrapper {
    margin-left: 4px;
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;
    grid-template-areas: 'one two';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
*/
`;

const OpportunityInfo = () => {
    return (
        <TableWrapper padding="5px 15px 5px 15px">
            <StyledNote>
        <span>
          <span className="note">Caution: </span>
          <span className="noteBody">
            Legally, you should only try to collect on the opportunities where product(s)/service(s) have been delivered.
          </span>
        </span>
            </StyledNote>
        </TableWrapper>
    );
};
/*const Tabs = () => {
    const {url} = useRouteMatch();
    const location = useLocation();
    return (
        <div className="tabWrapper">
            <Link
                className={`tab one ${location.pathname.includes('opportunity') && !location.pathname.includes('details') ? 'active' : ''}`}
                to={`${url}`}>
                Overview
            </Link>
            <Link className={`tab two ${location.pathname.includes('details') ? 'active' : ''}`} to={`${url}/details`}>
                Details
            </Link>
        </div>
    );
};*/
const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  //height: 100%;
  font-size: 10px;
  padding: ${({padding}) => padding};
  align-items: flex-start;
  font-style: italic;

  .note {
    font-size: 12px;
    color: red;
  }

  .noteBody {
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;

export default Opportunity;
