import React, { Fragment } from 'react';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Button, TableWrapper } from 'styles/styledComponents';
import { Input, RouteLoadingSpinner } from 'components/UI';
import { commonService } from 'services';
import { StoreContext } from 'stores/RootStore';
import { useCheckAuthorizedRoute, _get } from 'utils/utils';

const Settings = observer(() => {
  const { userStore, uiStore } = React.useContext(StoreContext);
  const notify = uiStore.notify;
  const localStore = useLocalStore(() => ({
    id: _get(userStore, 'user.id'),
    currentPassword: null,
    updateCurrentPassword(event) {
      localStore.currentPassword = event.target.value;
    },
    newPassword1: null,
    updateNewPassword1(event) {
      localStore.newPassword1 = event.target.value;
    },
    newPassword2: null,
    updateNewPassword2(event) {
      localStore.newPassword2 = event.target.value;
    },

    /** Here are the Properties that Can be Used for Displaying Errors **/
    errorMsg: null,
    successMsg: null,
    async updateUser() {
      localStore.errorMsg = null;
      localStore.successMsg = null;
      let thingToPut = {};
      let failed = false;

      if (
        localStore.currentPassword === null ||
        localStore.newPassword1 === null ||
        localStore.newPassword2 === null ||
        localStore.newPassword1 !== localStore.newPassword2
      ) {
        if (localStore.currentPassword === null) {
          failed = true;
          notify({
            text: 'Please Include Your Current Password',
            group: 'topRight',
            type: 'error',
          });
        }
        if (localStore.newPassword1 === null || localStore.newPassword2 === null) {
          failed = true;
          notify({
            text: 'Please Include a New Value For your Password',
            group: 'topRight',
            type: 'error',
          });
        } else if (localStore.newPassword1 !== localStore.newPassword2) {
          failed = true;
          notify({
            text: 'Your New Passwords Do Not Match',
            group: 'topRight',
            type: 'error',
          });
        }
      } else {
        /** Build the Object we need; id, password, newPassword **/
        thingToPut = {
          id: localStore.id,
          password: localStore.currentPassword,
          newPassword: localStore.newPassword1,
        };

        await commonService
          .updateUser(thingToPut)
          .then((response) => {
            /** Reset the Form **/
            localStore.newPassword1 = null;
            localStore.newPassword2 = null;
            localStore.currentPassword = null;

            notify({
              text: 'Your Password Was Successfully Updated',
              group: 'topRight',
            });
          })
          .catch((error) => {
            notify({
              text: error.response.data.message,
              group: 'topRight',
              type: 'error',
            });
          });
      }
    },
    async testToast() {
      notify({
        text: 'This is a test of toast - without duration',
        group: 'topRight',
      });
    },
    async testToastErr() {
      notify({
        text: 'this is a test of toast err - without duration',
        group: 'topRight',
        type: 'error',
      });
    },
  }));
  /** Here are the different computed and observable properties **/

  const { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Fragment>
              <Wrapper>
                <TableWrapper>
                  <SettingsHeader>Reset Password</SettingsHeader>
                  <SettingsContainer>
                    <SettingsRow>
                      <SettingsRowChild>
                        <Input
                          placeholder="Old password"
                          value={localStore.currentPassword}
                          onChange={localStore.updateCurrentPassword}
                          type={'password'}
                          label="Old*"
                          labelWidth="150px"
                          border
                        />
                      </SettingsRowChild>
                    </SettingsRow>
                    <SettingsRow>
                      <SettingsRowChild>
                        <Input
                          placeholder="New password"
                          value={localStore.newPassword1}
                          onChange={localStore.updateNewPassword1}
                          type={'password'}
                          label="New*"
                          labelWidth="150px"
                          border
                        />
                      </SettingsRowChild>
                    </SettingsRow>
                    <SettingsRow>
                      <SettingsRowChild>
                        <Input
                          placeholder="Confirm new password"
                          value={localStore.newPassword2}
                          onChange={localStore.updateNewPassword2}
                          type={'password'}
                          label="Confirm*"
                          labelWidth="150px"
                          border
                        />
                      </SettingsRowChild>
                    </SettingsRow>
                    <SubmitWrapper>
                      <Button style={{ backgroundColor: '#3fa9f5', color: 'white' }} onClick={localStore.updateUser}>
                        Submit
                      </Button>
                    </SubmitWrapper>
                  </SettingsContainer>
                </TableWrapper>
              </Wrapper>
            </Fragment>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  padding: 0 5px;
  width: 50%;
`;

const SettingsContainer = styled.div`
  align-items: center;
  border-top: ${({ theme }) => theme.colors.divider};
  display: flex;
  justify-content: center;
  margin: 0 5px;
  padding: 8px 0px 5px;
  flex-direction: column;
`;

const SettingsHeader = styled.div`
  padding: 5px 0;
  color: ${({ color, theme }) => color || theme.rgl.gridItem.colors.primaryText};
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SettingsRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 5px;
`;

const SettingsRowChild = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-right: 20px;

  label {
    color: ${({ color, theme }) => color || `var(--font-color-${theme.theme})`};
    font-size: large;
    font-weight: bold;
    width: 175px;
  }
`;

const SubmitWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export default Settings;
