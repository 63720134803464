import { commonService } from 'services';
import { destroy, flow, getParent, getRoot, getSnapshot, types } from 'mobx-state-tree';

const { array, optional, boolean, string } = types;

const ActionItemModel = types
  .model({
    actionItem: optional(string, ''),
    completed: boolean,
  })
  .actions((self) => ({
    toggleCompleteTodo(index) {
      self.completed = !self.completed;
    },
    remove() {
      getParent(self, 2).remove(self);
    },
  }));

const ActionListModel = types
  .model({
    filter: optional(string, 'all'),
    newTodoContent: optional(string, ''),
    todos: array(ActionItemModel),
  })
  .volatile((self) => ({
    initialState: {},
  }))
  .views((self) => ({
    get userID() {
      let root = getRoot(self);
      return root.userStore.user.id;
    },
    get clientID() {
      return getRoot(self).userStore.user.lastClientViewedID;
    },
    get transactionID() {
      return getRoot(self).dataManagementStore.txnDetails.genInfo['Transaction ID'];
    },
    get itemsLeft() {
      return self.todos.filter((todo) => todo.active).length;
    },
    get activeTodos() {
      return self.todos.filter((todo) => todo.active);
    },
    get completedTodos() {
      return self.todos.filter((todo) => todo.completed);
    },
    get currentTodos() {
      switch (self.filter) {
        case 'all':
          return self.todos;
        case 'active':
          return self.todos.filter((todo) => !todo.completed);
        case 'completed':
          return self.todos.filter((todo) => todo.completed);
      }
    },
  }))
  .actions((self) => ({
    /** LifeCycle Hooks **/
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    clearCompleted() {
      self.todos = self.activeTodos;
    },
    onChangeTodo(e) {
      self.newTodoContent = e.target.value;
    },
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        if (self.newTodoContent) {
          self.createActionItems();
        }
      }
    },
    setFilter(filter) {
      self.filter = filter;
    },
    fetchActionItems: flow(function* fetchActionItems(clientID, uniqTxnID, startDt, endDt) {
      try {
        let query = {
          params: {
            filter: {
              widgetID: 65,
              userID: self.userID,
              uniqTxnID,
              clientID,
              startDt,
              endDt,
            },
          },
        };
        const response = yield commonService.getActionItems(query);
        self.todos = response.data.data;
      } catch (error) {
        console.error('Failed to fetch widget data', error);
        self.state = 'error';
      }
    }),
    createActionItems: flow(function* createActionItems(e) {
      self.todos.push(
        ActionItemModel.create({
          actionItem: self.newTodoContent,
          clientID: self.clientID,
          userID: self.userID,
        })
      );
    }),
    /*should we let users update action item content*/
    updateActionItems: flow(function* updateActionItems(actionItem) {
      try {
        const put = getSnapshot(actionItem);
        return yield commonService.updateActionItems(put);
      } catch (error) {
        console.error('Failed to fetch widget data', error);
        self.state = 'error';
      }
    }),
    deleteActionItem: flow(function* deleteActionItem(actionItem) {
      try {
        const response = yield commonService.deleteActionItem(actionItem.id);
        actionItem.remove();
      } catch (error) {
        console.error('Failed to fetch widget data', error);
        self.state = 'error';
      }
    }),
    remove(item) {
      destroy(item);
    },
  }));

export default ActionListModel;
