import React, {useEffect} from 'react';
/* styles */
import styled from 'styled-components/macro';
/* state management */
import {Observer, observer, useLocalStore} from 'mobx-react-lite';
/* utilities */
import Moment from 'moment';
import regression from 'regression';
import {_cloneDeep, _find, _get, useGetWidgetData, useInjectStores} from 'utils/utils';
/* components */
import {Dropdown, Error, LoadingSpinner, MultiSelectDropdown} from 'components/UI';
import {ComposedCharts} from './resources';
import {Square} from 'styled-icons/fa-regular/Square';
import {CheckIcon, WidgetWrapper} from 'styles/styledComponents';
import {widgetService} from 'services/service';

const widgetConstants = {name: 'ssTotalsGraph', id: 12};

const TrendsGraph = observer(() => {
    const {dataManagementStore, uiStore, utilsStore} = useInjectStores();

    const toCurrency = utilsStore.toCurrency,
        showSidebar = uiStore.showSidebar;

    const store = useLocalStore(() => ({
        loading: false,
        selectedChart: 'All',
        dateTypeNum: true,
        widgetData: null,
        error: null,
        granularity: 'days',
        defaultOptions: [
            {key: 1, text: 'All', value: 'All', checked: true},
            {key: 2, text: 'Sales', value: 'Sales', checked: false},
            {key: 3, text: 'Declines', value: 'Declines', checked: false},
            {key: 4, text: 'Returns', value: 'Returns', checked: false},
            {key: 5, text: 'Reserves', value: 'Reserves', checked: false},
        ],
        defaultNoReserveOpt: [
            {key: 1, text: 'All', value: 'All', checked: true},
            {key: 2, text: 'Sales', value: 'Sales', checked: false},
            {key: 3, text: 'Declines', value: 'Declines', checked: false},
            {key: 4, text: 'Returns', value: 'Returns', checked: false},
        ],
        get options() {
            return store.showReserves ? store.defaultOptions : store.defaultNoReserveOpt;
        },
        setGranularity(granularity) {
            if (store.granularity !== granularity) {
                store.granularity = granularity;
            }
        },
        granularityOptions: [
            {key: 1, text: 'Days', value: 'days', disable: false},
            // {key: 2, text: 'Weeks', value: 'week', disable: false},
        ],
        setGranularityOptions() {
            let startDt = Moment(store.newQuery.params.startDt);
            let endDt = Moment(store.newQuery.params.endDt);
            let diffDays = endDt.diff(startDt, 'days');
            let diffWeeks = endDt.diff(startDt, 'weeks');

            store.granularityOptions = [{key: 1, text: 'Days', value: 'days', disable: false}];
        },

        get legendPayload() {
            const legend = [];
            for (let i = 0; i < store.selectedOptions.length; i++) {
                let opt = store.selectedOptions[i];
                if (opt === 'Sales') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#3fa9f5', size: 10, width: 10});
                    legend.push({id: `${opt} Trend`, value: 'S. Trend', type: 'line', color: '#3fa9f5'});
                }
                if (opt === 'Declines') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#45E885'});
                    legend.push({id: `${opt} Trend`, value: 'D. Trend', type: 'line', color: '#45E885'});
                }
                if (opt === 'Returns') {
                    legend.push({id: opt, value: opt, type: 'circle', color: '#fccc1a'});
                    legend.push({id: `${opt} Trend`, value: 'R.S. Trend', type: 'line', color: '#fccc1a'});
                }
                // if (opt === 'Reserves') {
                //   legend.push({ id: opt, value: opt, type: 'circle', color: '#FF2B498' });
                //   legend.push({ id: `${opt} Trend`, value: 'R. Trend', type: 'line', color: '#FF2B498' });
                // }
            }
            return legend;
        },
        // chartWidth: 0,
        /* get ticksPadding() {
              if (store.chartWidth === 0) {
                const chartWidth = document.getElementById('cGrid');
                if (chartWidth) {
                  store.chartWidth = chartWidth.offsetWidth;
                }
              }
              let calcChartWidth = 0;
              const ticksLength = store.ticks.length;
              switch (ticksLength) {
                case 2: {
                  calcChartWidth = 0.88;
                  break;
                }
                case 3: {
                  calcChartWidth = 0.4;
                  break;
                }
                case 4: {
                  calcChartWidth = 0.25;
                  break;
                }
                case 5: {
                  calcChartWidth = 0.8;
                  break;
                }
                case 6: {
                  calcChartWidth = 0.13;
                  break;
                }
                case 7: {
                  calcChartWidth = 0.1;
                  break;
                }
                case ticksLength >= 12: {
                  calcChartWidth = 0.0;
                  break;
                }
                default:
                  calcChartWidth = 0.05;
              }
              let width = store.chartWidth + store.chartWidth * calcChartWidth;
              let ratio = Math.ceil(width / store.ticks.length);
              return -1 * Math.ceil(ratio / 2);
            },*/
        get selectedOptions() {
            const selected = [];
            store.options.map((opt) => {
                if (opt.checked) {
                    selected.push(opt.text);
                }
            });
            return selected;
        },
        onChange(obj) {
            if (obj.text === 'All') {
                store.options.map((opt) => {
                    opt.checked = opt.text === 'All' ? (opt.checked = true) : (opt.checked = false);
                });
            } else {
                let allOption = _find(store.options, {key: 1});
                allOption.checked = false;

                let optionToChange = _find(store.options, {key: obj.key});
                optionToChange.checked = !optionToChange.checked;

                let checkAll = true;
                store.options.map((opt) => {
                    if (opt.checked === true) {
                        checkAll = false;
                    }
                });
                if (checkAll === true) {
                    let allOption = _find(store.options, {key: 1});
                    allOption.checked = true;
                }
            }
        },
        get newQuery() {
            const newQuery = _cloneDeep(dataManagementStore.newQuery);
            if (newQuery) {
                return {params: {...newQuery}};
            }
        },
        /*get showReserves() {
            let showReserves = false;
            let reservesData = _get(store, 'widgetData.data.reserves');
            if (reservesData) {
                reservesData.forEach((datum) => {
                    showReserves = datum.totalAmt > 0;
                });
            }
            return showReserves;
        },*/
        get allDashData() {
            if (_get(store, 'widgetData') && store.widgetData.length > 0) {
                let dataHolder = [];
                let SalesTrendline = [];
                let DeclinesTrendline = [];
                // let ReservesTrendline = [];
                let ReturnsTrendline = [];
                for (let i = 0; i < store.widgetData.length; i++) {
                    let date = Moment(store.widgetData[i].txn_dt, ['YYYY-MM-DD-HH', 'YYYY-MM-DD']);
                    let dtWord;
                    let dtNum;
                    // switch (store.granularity) {
                    // case 'hours':
                    //   dtNum = store.widgetData[i].dt.Hour + 1;
                    //   dtWord = store.widgetData[i].dt.Hour + 1;
                    //   break;
                    // case 'days':
                    dtNum = store.widgetData[i].day_num;
                    dtWord = date.format('dd');
                    // break;
                    // case 'week':
                    //     dtNum = store.widgetData[i].week_num;
                    //     dtWord = store.widgetData[i].week_num;
                    //     break;
                    // case 'month':
                    //     dtNum = store.widgetData[i].month_num;
                    //     dtWord = date.format('MMM');
                    //     break;
                    // default:
                    //     break;
                    // }

                    dataHolder.push({
                        dtWord,
                        dtNum,
                        ...store.widgetData[i],
                    });

                    SalesTrendline.push([i, dataHolder[i]['sale_amt']]);
                    DeclinesTrendline.push([i, dataHolder[i]['decl_amt']]);
                    ReturnsTrendline.push([i, dataHolder[i]['return_amt']]);
                    // if (store.showReserves) {
                    //   ReservesTrendline.push([i, dataHolder[i]['reserves']]);
                    // }
                }

                const salesRegression = regression.linear(SalesTrendline);
                const declinesRegression = regression.linear(DeclinesTrendline);
                const returnsRegression = regression.linear(ReturnsTrendline);
                // let reservesRegression;

                // if (store.showReserves) {
                //     reservesRegression = regression.linear(ReservesTrendline);
                // }

                for (let i = 0; i < dataHolder.length; i++) {
                    dataHolder[i]['SalesTrendline'] = salesRegression.points[i][1];
                    dataHolder[i]['DeclinesTrendline'] = declinesRegression.points[i][1];
                    dataHolder[i]['ReturnsTrendline'] = returnsRegression.points[i][1];
                    // if (store.showReserves) {
                    //   dataHolder[i]['ReservesTrendline'] = reservesRegression.points[i][1];
                    // }
                }

                for (let i = 0; i < dataHolder.length; i++) {
                    if (dataHolder[i].DeclinesTrendline < 0) dataHolder[i].DeclinesTrendline = null;
                    if (dataHolder[i].ReturnsTrendline < 0) dataHolder[i].ReturnsTrendline = null;
                    if (dataHolder[i].SalesTrendline < 0) dataHolder[i].SalesTrendline = null;
                    // if (store.showReserves) {
                    //   if (dataHolder[i].ReservesTrendline < 0) dataHolder[i].ReservesTrendline = null;
                    // }
                }
                return dataHolder;
            }
        },
        get label() {
            return store['ssTotalsGraph.dateBucket'];
        },
        toggleDisable() {
            store.disable = !store.disable;
        },
        handleChange(value) {
            store.selectedChart = value;
        },
        toggleDateFormat() {
            store.dateTypeNum = !store.dateTypeNum;
        },
        setWidgetData(widgetData) {
            store.error = null;
            const {status, data, error} = widgetData;
            if (status === 'loading') this.loading = true;
            if (error) {
                this.error = error;
                this.loading = false;
            }
            if (data) {
                store.widgetData = data.values;
                store.setGranularityOptions();
                this.loading = false;
            }
        },
    }));

    const fetchData = useGetWidgetData(
        {name: 'dashboardTotalTrends', query: widgetService.dashboardTrends},
        'data',
        dataManagementStore?.newQuery
    );

    /* const fetchDataGranularity = useGetWidgetData(
         {name: 'dashboardTotalGraph', query: widgetService.dashboardTrends},
         'data',
         store.granularity,
         store.granularity
     );*/

    useEffect(() => {
        store.setWidgetData(fetchData);
    }, [fetchData]);
    
    /*  useEffect(() => {
          store.setWidgetData(fetchDataGranularity);
      }, [fetchDataGranularity]);*/

    return (
        <Observer>
            {() => (
                <StyledWrapper moveLeft={!showSidebar}>
                    <div
                        css={`
                          grid-area: sales;
                        `}
                    >
                        <WidgetWrapper id="cGrid">
                            <div/>
                            {store.loading ? (
                                <LoadingSpinner size={'200px'}/>
                            ) : store.error ? (
                                <Error error={store.error}/>
                            ) : store.allDashData !== null ? (
                                <ComposedCharts
                                    gridItem={uiStore.currentTheme.rgl.gridItem}
                                    label={store.label}
                                    show={'Sales'}
                                    dateBucket={store.granularity}
                                    data={store.allDashData}
                                    dateTypeNum={store.dateTypeNum}
                                    // ticksPadding={store.ticksPaddking}
                                    legendPayload={store.salesLegendPayload}
                                    toCurrency={toCurrency}
                                />
                            ) : (
                                <div className="noData">No data for this time period</div>
                            )}
                            {/*{!store.loading && !store.allDashData && <div className="noData">No data for this time period</div>}*/}
                        </WidgetWrapper>
                    </div>
                    <div
                        css={`
                          grid-area: refunds;
                        `}
                    >
                        <WidgetWrapper id="cGrid">
                            {store.loading ? (
                                <LoadingSpinner size={'200px'}/>
                            ) : store.error ? (
                                <Error error={store.error}/>
                            ) : store.allDashData !== null ? (
                                <ComposedCharts
                                    gridItem={uiStore.currentTheme.rgl.gridItem}
                                    label={store.label}
                                    show={'Declines'}
                                    dateBucket={store.granularity}
                                    data={store.allDashData}
                                    dateTypeNum={store.dateTypeNum}
                                    // ticksPadding={store.ticksPaddking}
                                    legendPayload={store.legendPayload}
                                    toCurrency={toCurrency}
                                />
                            ) : (
                                <div className="noData">No data for this time period</div>
                            )}
                            {/*{!store.loading && !store.allDashData && <div className="noData">No data for this time period</div>}*/}
                        </WidgetWrapper>
                    </div>
                    <div
                        css={`
                          grid-area: declines;
                        `}
                    >
                        <WidgetWrapper id="cGrid">
                            {store.loading ? (
                                <LoadingSpinner size={'200px'}/>
                            ) : store.error ? (
                                <Error error={store.error}/>
                            ) : store.allDashData !== null ? (
                                <ComposedCharts
                                    gridItem={uiStore.currentTheme.rgl.gridItem}
                                    label={store.label}
                                    show={'Returns'}
                                    dateBucket={store.granularity}
                                    data={store.allDashData}
                                    dateTypeNum={store.dateTypeNum}
                                    // ticksPadding={store.ticksPaddking}
                                    legendPayload={store.legendPayload}
                                    toCurrency={toCurrency}
                                />
                            ) : (
                                <div className="noData">No data for this time period</div>
                            )}
                            {/*{!store.loading && !store.allDashData && <div className="noData">No data for this time period</div>}*/}
                        </WidgetWrapper>
                    </div>
                    <div
                        css={`
                          grid-area: chargebacks;
                        `}
                    >
                        <WidgetWrapper position={'relative'} id="cGrid">
                            <div/>
                            {store.loading ? (
                                <LoadingSpinner size={'200px'}/>
                            ) : store.error ? (
                                <Error error={store.error}/>
                            ) : (
                                <>
                                    {/*<div className={'header'}>*/}
                                    {/*    <div>*/}
                                            {/*<div className="filledCircle r"/>*/}
                                            {/*Reserves*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    <div className="noData">
                                        {/*No data for this time period*/}
                                        <div css={`margin: 0 4rem;`}>
                                            <div>
                                                The following graphs are created based on rolling 12-months of data.
                                            </div>
                                            <div css={`display: flex;
                                              justify-content: center;
                                              flex-direction: column`}>
                                                <p>
                                                    To see various trends by totals, averages, and txn counts for
                                                    previous year and current year please go to Transactions >>
                                                    Analytics Summary >>
                                                    Graphs
                                                </p>
                                                <StyledNote>
                                                    <span className="note">*</span>
                                                    <span className="noteBody">Returns are Refunds & Chargebacks combined</span>
                                                </StyledNote>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </WidgetWrapper>
                    </div>
                </StyledWrapper>
            )}
        </Observer>
    );
});

export default TrendsGraph;

const StyledNote = styled.div`
  display: flex;
  //flex-direction: column;
  height: 100%;
  //justify-content: flex-end;
  align-items: center;
  //font-size: 10px;
  //padding: 0 10px;

  .note {
    color: red;
  }

  .noteBody {
    font-style: italic;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;
const StyledWrapper = styled.div`
  height: calc(100% - 35px);
  display: grid;
  grid-template-areas:
    'sales declines'
    'refunds chargebacks';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5px;
  padding-bottom: 20px;

  position: relative;
  width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};

  .center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .header {
    position: absolute;
    top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      display: flex;
      align-items: center;

      .filledCircle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 3px 0 0;
      }

      .r {
        background-color: #ff2b49;
      }
    }
  }

  .noData {
    margin: auto;
  }

  .xAxisTick {
    &:hover {
      fill: ${({theme}) => theme.baseColors.colorBlue};
    }
  }
`;

const DropdownWrapper = styled.div`
  margin: 7px 0 0 9px;
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 1;

  .toggleDateType {
    margin-left: 10px;
  }

  .dtLabel {
    padding-top: 2px;
    font-size: 18px;
    color: ${({theme}) => theme.colors.text};
  }
`;
