import React, {useEffect} from 'react';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import { _get } from 'utils/utils';
import {useLocation} from "react-router-dom";

const CCData = ({
                    isDemo,
                    widgetData: {
                        bin_lookup_perf,
                        brand_downgrade_rsn1,
                        brand_downgrade_rsn2,
                        business_card,
                        cc_brand,
                        cc_cntry,
                        cc_level,
                        cc_num_concat,
                        cc_subtype,
                        cc_txn_count,
                        cc_type,
                        iss_bank,
                        iss_bank_cntry,
                        prepaid_card,
                        reward_card,
                        skimmed_card_fraud,
                        txn_cc_tier_level,
                        txn_cp_vs_cnp,
                        txn_pers_vs_bus,
                        bin_attack_tf,
                        cc_num
                    },
                }) => {
    const location = useLocation();
    const store = useLocalObservable(() => ({
        showBinAttack: false,
        setBinAttack(show) {
            this.showBinAttack = show;
        }
    }));
    useEffect(() => {
        let showBinAttack = location.pathname === '/transactions/voids' || location.pathname === '/transactions/declines';
        store.setBinAttack(showBinAttack);
    }, [location.pathname]);
    return (
        <Observer>
            {() => (
                <div style={{display: 'flex', flexDirection: 'column', minHeight: '800px', boxShadow: '0 0 4px rgba(91,91,91,.5)', width: '33.33%', margin: '0 0 4px 0'}}>
                    <TableWrapper height={''} margin={'2px'} padding="0 10px">
                        <TableHeader>CC DATA</TableHeader>
                        <TableContainer>
                            <Table>
                                <TableBody>

                                    <TableRow>
                                        <TableCell textAlign="left">CC Num (Concat)</TableCell>
                                        <TableCell>{cc_num ? cc_num : cc_num_concat}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">CC Brand</TableCell>
                                        <TableCell>{cc_brand}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">CC Level</TableCell>
                                        <TableCell>{cc_level}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">CC Type</TableCell>
                                        <TableCell>{cc_type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">CC Subtype</TableCell>
                                        <TableCell>{cc_subtype}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">BIN Prepaid Card</TableCell>
                                        <TableCell>{prepaid_card}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">BIN Business Card</TableCell>
                                        <TableCell>{business_card}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">BIN CC Country</TableCell>
                                        <TableCell>{cc_cntry}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">BIN Lookup Performed</TableCell>
                                        <TableCell>{bin_lookup_perf}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Skimmed Card Fraud</TableCell>
                                        <TableCell>{skimmed_card_fraud}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">CC Txn Count</TableCell>
                                        <TableCell>{cc_txn_count}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <TableWrapper height={''} margin={'2px'} padding="0 10px">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">Issuing Bank</TableCell>
                                        <TableCell>{iss_bank}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Issuing Bank Country</TableCell>
                                        <TableCell>{iss_bank_cntry}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Tier Level</TableCell>
                                        <TableCell>{txn_cc_tier_level}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Tier Personal vs Business</TableCell>
                                        <TableCell>{txn_pers_vs_bus}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Tier Reward Card</TableCell>
                                        <TableCell>{reward_card}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Tier CP vs CNP</TableCell>
                                        <TableCell>{txn_cp_vs_cnp}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">TSYS/Brand Downgrade 1</TableCell>
                                        <TableCell>{brand_downgrade_rsn1}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">TSYS/Brand Downgrade 2</TableCell>
                                        <TableCell>{brand_downgrade_rsn2}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <TableWrapper height={'100%'} margin={'2px'} padding="0 10px">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell textAlign="left">BIN Lookup Performed</TableCell>
                                        <TableCell>{bin_lookup_perf}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell textAlign="left">Skimmed Card Fraud</TableCell>
                                        <TableCell>{skimmed_card_fraud}</TableCell>
                                    </TableRow>
                                    {store.showBinAttack && (
                                        <TableRow>
                                            <TableCell textAlign="left">Bin Attack T/F</TableCell>
                                            <TableCell>{bin_attack_tf}</TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell textAlign="left">CC Txn Count</TableCell>
                                        <TableCell>{cc_txn_count}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                </div>
            )}
        </Observer>
    );
};

export default CCData;
