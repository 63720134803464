import { observer, Observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Grid } from 'styles/styledComponents';
import { CreateEditClientWrapper, DeleteButton, SubmitButton } from '../styles';
import { ClientInfoForm } from './Partials/ClientInfoForm';
import { Mid } from './Partials/Mid';
import styled from 'styled-components/macro';

export const CreateClientForm = observer((props) => {
  useEffect(() => {
    return function cleanup() {
      props.clientModel.resetForm();
    };
  }, []);

  return (
    <Observer>
      {() => (
        <CreateEditClientWrapper>
          <StyledNote alignItems="flex-end">
            <span>
              <span className="note">* </span>
              <span className="noteBody">Data is real time; based on industry's ability to process and provide info</span>
            </span>
          </StyledNote>
          <div style={{ width: '100%', margin: '0 auto', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <div className="header-wrapper">
              <div className="form-header">Client Info</div>
            </div>
            <div style={{ padding: '0 40px', display: 'flex', justifyContent: 'center' }}>
              <Grid width={'1040px'} cmin={'350px'} cmax={'1fr'}>
                <ClientInfoForm props={props} store={props.store} clientModel={props.clientModel} />
              </Grid>
            </div>
            <div className="header-wrapper" style={{ alignItems: 'center', flexDirection: 'column' }}>
              <div className="form-header">MIDs</div>
              <SubmitButton width="100px" className="submit" onClick={() => props.clientModel.addMid()}>
                Add MID
              </SubmitButton>
            </div>
            {props.clientModel.mids.map((mid, index) => (
              <div key={index}>
                <Mid clientModel={props.clientModel} mid={mid} store={props.store} />
              </div>
            ))}
            <div className="header-wrapper">
              <SubmitButton width="100px" className="submit" onClick={() => props.clientModel.createClient(props.notify)}>
                Submit Form
              </SubmitButton>
              <DeleteButton width="100px" margin="0 0 0 5px" onClick={props.clientModel.resetForm}>
                Clear Form
              </DeleteButton>
            </div>
            <div className="header-wrapper">
              <div className="form-header">&nbsp;</div>
            </div>
          </div>
        </CreateEditClientWrapper>
      )}
    </Observer>
  );
});

const StyledNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1rem;
  height: 20px;
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding-right: 10px;
  font-style: italic;
  .note {
    font-size: 1rem;
    color: red;
  }
  .noteBody {
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;