import React, { useEffect } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Notes, Table, Dropdown } from 'components/UI';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores, buildColDef } from 'utils/utils';
import { widgetService } from 'services';
import Moment from "moment/moment";
import {getFileName} from 'utils/utils';

const IsoPassThroughLosses = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel,
    setTxnDetails = dataManagementStore.setTxnDetails,
    toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal,
    paginatorModel = widgetModel.paginatorModel;

	const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
	const toDateFormatter = utilsStore.toDateFormatter;
	const toPercent = utilsStore.toPercent;
	const toPercentFormatter = utilsStore.toPercentFormatter;
	const toFormatNumber = utilsStore.toFormatNumber;

  const orderByOptions = [
    { key: 1, text: 'Cust Name', value: 'custName' },
    { key: 2, text: 'Invoice Num', value: 'invNum' },
    { key: 3, text: 'Txn Type', value: 'txnType' },
    { key: 4, text: 'Txn Status', value: 'txnStatus' },
    { key: 5, text: 'Txn Date', value: 'txnDt' },
  ];

  function cellStyle(params) {
    if (params.value < 0) {
      return { color: '#FF2B49' };
    } else {
      // return { color: 'rgb(69, 232, 133)' };
    }
  }

  const initialData = { txns: [], count: 0 };

  function convertToTitleCase(str) {
	const titleCased = str.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
	// Little formatting tweaks
	return titleCased.replace(/\bmid\b/gi, 'MID')
	.replace(/\bet\b/gi, 'ET')
	.replace(/\bcc\b/gi, 'CC')
	.replace(/\btf\b/gi, 'T/F')
	.replace(/\bic\b/gi, 'I/C')
	.replace(/\bid\b/gi, 'ID')
	.replace(/\bmastercard\b/gi, 'MC')
	.replace(/\bmc\b/gi, 'MC')
	.replace(/\bmcc\b/gi, 'MCC')
	.replace(/\bopportunity\b/gi, 'Opp')
	.replace(/\bamex\b/gi, 'AMEX')
	.replace(/\btif\b/gi, 'TIF')
	.replace(/\tsys\b/gi, 'Proc')
	.replace(/\tsys1\b/gi, 'Proc1')
	.replace(/\tsys2\b/gi, 'Proc2')
	.replace(/\cp\b/gi, 'CP')
	.replace(/\cnp\b/gi, 'CNP')
	.replace(/\cvc\b/gi, 'CVC')
	.replace(/\mti\b/gi, 'MTI')
	.replace(/\tcvv2sys\b/gi, 'CVV2')
	.replace(/\avs\b/gi, 'AVS')
	.replace(/\pos\b/gi, 'POS')
	.replace(/\bDt\b/gi, 'Date');
  };


  const store = useLocalStore(() => ({
    fileName: 'passThroughLosses',
    storageKey: 'txn-details-state',
    loading: false,
    columnDefs: [{
        headerName: convertToTitleCase('et_platfrm_nm'),
        field: 'et_platfrm_nm',
        chartDataType: 'series',
        width: 155,
        headerTooltip: convertToTitleCase('et_platfrm_nm')
      },
      {
        headerName: convertToTitleCase('fee_dt_eom'),
        field: 'fee_dt_eom',
        valueFormatter: toDateFormatter,
        chartDataType: 'series',
        width: 125,
        headerTooltip: convertToTitleCase('fee_dt_eom')
      },
      {
        headerName: convertToTitleCase('mid'),
        field: 'mid',
        chartDataType: 'series',
        width: 160,
        headerTooltip: convertToTitleCase('mid')
      },
	    {
        headerName: convertToTitleCase('merch_nm'),
        field: 'merch_nm',
        chartDataType: 'series',
        width: 220,
        headerTooltip: convertToTitleCase('merch_nm')
      },
      {
        headerName: convertToTitleCase('fee_cat_desc'),
        field: 'fee_cat_desc',
        chartDataType: 'series',
        width: 140,
        headerTooltip: convertToTitleCase('fee_cat_desc')
      },
      {
        headerName: convertToTitleCase('pass_thru_desc'),
        field: 'pass_thru_desc',
        chartDataType: 'series',
        width: 200,
        headerTooltip: convertToTitleCase('pass_thru_desc')
      },
      {
        headerName: convertToTitleCase('card_brnd'),
        field: 'card_brnd',
        chartDataType: 'series',
        width: 105,
        headerTooltip: convertToTitleCase('card_brnd')
      },
      {
        headerName: convertToTitleCase('passthru_income'),
        field: 'passthru_income',
        cellStyle: cellStyle,
        valueFormatter: toCurrencyFormatter,
        type: 'rightAligned',
        chartDataType: 'series',
        width: 145,
        headerTooltip: convertToTitleCase('passthru_income')
      },
      {
        headerName: convertToTitleCase('passthru_expense'),
        field: 'passthru_expense',
        cellStyle: cellStyle,
        valueFormatter: toCurrencyFormatter,
        type: 'rightAligned',
        chartDataType: 'series',
        width: 155,
        headerTooltip: convertToTitleCase('passthru_expense')
      },
	    {
        headerName: convertToTitleCase('passthru_loss'),
        field: 'passthru_loss',
        cellStyle: cellStyle,
        valueFormatter: toCurrencyFormatter,
        type: 'rightAligned',
        chartDataType: 'series',
        width: 130,
        headerTooltip: convertToTitleCase('passthru_loss')
      }
	],
    widgetData: initialData,
    rowData: [],
    txnCount: 0,
    async getData() {
      store.loading = true;
      widgetModel.setIsHistoric(true);
      try {
        const res = await widgetModel.fetchWidgetData(widgetService.isoPassThroughLossesMonthly);

        store.widgetData = res.data;

        store.rowData = store.widgetData.values.map((txn, index) => {
            txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
            return {
                ...txn
            };
        });
        store.fileName = getFileName(userStore, res?.config, 'passThroughLosses');

        store.txnCount = store.widgetData.count || store.widgetData.values.length;
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
      widgetModel.setIsHistoric(false);
    },
    get dropdownOptions() {
      if (this.widgetData?.history?.length > 0) {
          return this.widgetData?.history?.map(({fee_dt_eom}, index) => {
              return {
                  key: index,
                  text: `${Moment(fee_dt_eom).format('YYYY')}: ${Moment(fee_dt_eom).format('MMMM')}`,
                  value: fee_dt_eom
              };
          });
      }
      return [];
    },
    selectOption(val) {
      this.selectedOption = val;
      widgetModel.setDate(val);
      this.getData();
    }, 
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
    api: null,
    columnApi: null,
    saveColumnState() {
      let savedState = store.columnApi.getColumnState();
      savedState = JSON.stringify(savedState);
      if (savedState) {
        window.localStorage.setItem('txn-details-state', savedState);
      }
    },
    setColumnState() {
      let savedState = window.localStorage.getItem('txn-details-state');
      savedState = JSON.parse(savedState);
      if (savedState && store.columnApi) {
        store.columnApi.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    resetColumnState() {
      window.localStorage.removeItem('txn-details-state');
      store.columnApi.resetColumnState();
    },
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        rowSelection: 'multiple',
        rowHeight: 33,
        headerHeight: 33,
        sideBar: {
          position: 'left',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
        },
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          suppressMenu: true
        },
        columnDefs: store.columnDefs,

        onGridReady(params) {
          store.api = params.api;
          store.columnApi = params.columnApi;
          store.setColumnState();
        },
        onFirstDataRendered(params) {
          setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          paginatorModel.setPageNumber(1);
          await store.getData();
        }
      }),
    []
  );
  
  return (
    <Observer>
      {() => (
        <TableWrapper>
          <div  style={{
              width: 'calc(100% - 40px)',
              margin: '3px 0',
              alignItems: 'center',
              display: 'flex'
          }}>
              <Dropdown
                  id="historical"
                  options={store.dropdownOptions}
                  value={widgetModel.date}
                  onChange={store.selectOption}
                  placeholder="Select Month"
                  width={'140px'}
                  height={'20px'}
              />
          </div>
          <Table
            store={store}
            fetchTableData={store.getData}
            rowData={store.rowData}
            loading={store.loading}
            /**export**/
            initiateTxnsExport={store.widgetModel.initiateTxnsExport}
            exportInfo={store.widgetModel.exportInfo}
            /** Paginator-Specific Functions **/
            txnCount={store.txnCount}
            paginator={paginatorModel}
            enableBrowserTooltips={true}
            /** OrderBy Properties **/
            addOrderBy={store.widgetModel.addOrderBy}
            orderByArray={store.widgetModel.orderByModel}
            orderByOptions={store.orderByOptions}
            resetOrderBy={store.widgetModel.resetOrderBy}
          />
        </TableWrapper>
      )}
    </Observer>
  );
});



export default IsoPassThroughLosses;

const TableWrapper = styled.div`
height: 98%;
margin-left: 5px;
margin-right: 5px;
`;