import React, {useEffect, useState} from 'react';
import {Observer, observer} from 'mobx-react-lite';
import {_get, useInjectStores, useGetWidgetData} from 'utils/utils';
import {LoadingSpinner} from 'components/UI';
import {Wrapper} from 'styles/styledComponents';
import styled from 'styled-components/macro';
import {widgetService} from 'services';
import {ChevronsRightIcon} from 'styles/styledComponents';
import { Link } from "react-router-dom";

const AllReports = observer(() => {
    const {rootStore, dataManagementStore, userStore, widgetStore} = useInjectStores();
    const [error, setError] = useState('');

    const allReports = useGetWidgetData(
        {name: 'fetchAllUserReports', query: widgetService.allUserReports},
        'data',
        dataManagementStore?.newQuery
    );

	const userReports = useGetWidgetData(
        {name: 'fetcUserReports', query: widgetService.userReports},
        'data',
        dataManagementStore?.newQuery
    );
    const user = _get(userReports, 'data.results', []);
	const all = _get(allReports, 'data.results', []);

	const loading = allReports.status === 'loading' || userReports.status === 'loading';

    return (
        <Observer>
            {() => (
                <Wrapper>
                    {loading ? (
                        <LoadingSpinner size={'50vh'} />
                    ) : error ? (
                        <div>there was an error</div>
                    ) :
                        <TableWrapper>
                        <table>
                            <thead>
                                <tr>
                                    <th>Report Name</th>
                                    <th>Description</th>
                                    <th>Add To Plan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {all.filter(rep => !user.some(r => r.rep_sname === rep.rep_sname)).map((report, idx) => (
                                    <tr key={idx}>
                                        <td>{report.rep_full_nm}</td>
                                        <td>{report.rep_desc}</td>
                                        <td style={{textAlign: 'right'}}><Link to="/admin/pricing" exact="true" style={{color: '#b3d6fc'}}>Upgrade</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </TableWrapper>
                    }
                </Wrapper>
            )}
        </Observer>
    );
});
const TableWrapper = styled.div`
	height: 80vh;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	color: ${({ theme }) => theme.colors.primaryText};
    table {
        width: 100%;
        border-collapse: collapse; 
    }
    tr {
        width: 100%;
        color: ${({ theme }) => theme.colors.primaryText};
    }
    th {
        text-align: left;
        border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
    }
    td {
        text-align: left;
        a {
            color: ${({ theme }) => theme.colors.primaryText};
        }
    }
`;

export default AllReports;
