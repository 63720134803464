import React from 'react';
import { Dropdown, Input } from 'components/UI';
import { observer, Observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
const DeclineFilter = observer((props) => {
  const declBktID = props.declineFilterProps.declBktID,
    // declRsn = props.declineFilterProps.declRsn,
    txnTypeID = props.declineFilterProps.txnTypeID,
    invNum = props.declineFilterProps.invNum,
    origCustName = props.declineFilterProps.origCustName,
    respCodeID = props.declineFilterProps.respCodeID;
  return (
    <Observer>
      {() => (
        <Wrapper>
          <FilterTitle>Decline Filters</FilterTitle>
          <div className="filterBody">
            <div className={'fieldContainer'}>
              {txnTypeID.get && <label>Txn Type</label>}
              <Dropdown
                fontSize={'14px'}
                placeholder="Txn Type"
                options={txnTypeID.options || []}
                value={txnTypeID.get || ''}
                onChange={txnTypeID.set}
                border
                margin={'3px 0 3px 0'}
                tabIndex={8}
              />
            </div>
            <div className={'fieldContainer'}>
              {declBktID.get && <label>Decl Bkt</label>}
              <Dropdown
                fontSize={'14px'}
                placeholder="Decl Bkt"
                options={declBktID.options || []}
                value={declBktID.get || ''}
                onChange={declBktID.set}
                border
                margin="0 0px 3px 0px"
                tabIndex={9}
              />
            </div>
            <div className={'fieldContainer'}>
              {respCodeID.get && <label>Decl Rsn</label>}
              <Dropdown
                fontSize={'14px'}
                placeholder="Decl Rsn"
                options={respCodeID.options || []}
                value={respCodeID.get || ''}
                onChange={respCodeID.set}
                border
                margin="0 0px 3px 0px"
                tabIndex={10}
              />
            </div>
            <div className={'fieldContainer'}>
              {invNum.get && <label>Invoice #</label>}
              <Input
                placeholder="Invoice #"
                value={invNum.get || ''}
                onChange={invNum.set}
                onBlur={invNum.blur}
                error={invNum.error}
                border
                margin={'0 0 3px 0'}
                tabIndex={11}
              />
            </div>
            <div className={'fieldContainer'}>
              {origCustName.get && <label>Original Name</label>}
              <Input
                placeholder="Original Name"
                value={origCustName.get}
                onChange={origCustName.set}
                onBlur={origCustName.blur}
                error={origCustName.error}
                border
                margin={'0 0 3px 0'}
                tabIndex={11}
              />
            </div>
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  width: 100%;
  color: ${({ theme }) => theme.primaryColor};
  .filterBody {
    padding-right: 2px;
    .fieldContainer {
      outline: none;
      display: flex;
      flex-direction: column;
      margin: 0 1px 0 3px;

      label {
        font-size: 11px;
        margin-left: 2px;
        padding: 3px 0 1px 0;
      }
    }
  }
`;
const FilterTitle = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 7px 0;
  //border: 1px solid #fff;
  font-weight: bold;
  //   border-left: 2px solid #fff;
  background-color: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
`;
export default DeclineFilter;
