import React, { Fragment, useEffect } from 'react';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { applySnapshot } from 'mobx-state-tree';
import { autorun } from 'mobx';
// utils
import { useInjectStores, _get, _map, _find, _toUpper, _upperFirst, _includes, _sortBy } from 'utils/utils';
import { adminService } from 'services';
// components and styles
import styled from 'styled-components/macro';
import { Button, Checkbox, LoadingSpinner, Input, Dropdown } from 'components/UI';
import { FormRow } from './StyledUpdateUser';
import { ClearIcon } from 'styles/styledComponents';

const UpdateUser = observer((props) => {
  const { rootStore, userStore, uiStore } = useInjectStores();
  const appData = rootStore.appData,
    notify = uiStore.notify,
    updateUserStore = userStore.updateUserStore;

  const localStore = useLocalStore(() => ({
    loading: false,
    isLoading: false,
    selectedClientId: 'all',
    filterUsersText: '',
    setUserFilterText(letter) {
      localStore.filterUsersText = letter;
    },
    filterLastNameByLetter: '',
    setUserFilterByLetter(letter) {
      localStore.selectedClientId = 'all';
      localStore.filterLastNameByLetter = letter;
    },
    clientUserOptions: [],
    async setSelectedClientId(val) {
      if (localStore.selectedClientId !== val) {
        localStore.selectedClientId = val;
        updateUserStore.resetState();
      }
      if (val !== 'all') {
        try {
          localStore.loadingUsers = true;
          // let clientUsers = await commonService.getUsersByClient({ clientID: val });
          let clientUsers = await adminService.adminGetClientUsers({ clientID: val });
          localStore.clientUserOptions = _sortBy(clientUsers.data.map(({ email, firstName, lastName}) => {
            return {
              sort: lastName,
              value: email,
              text: `${lastName}, ${firstName} - ${email}`,
              key: email,
            };
          }), 'sort');
        } catch (e) {
          console.log('e', e);
        }
      } else {
        localStore.clientUserOptions = localStore.userOptions;
      }
      localStore.loadingUsers = false;
    },
    /** General Use Variables **/
    generalErrorMessage: '',
    generalSuccessMessage: '',
    showSelectClients: false,
    updateNewPassword: '',
    updatePasswordCopy: '',

    desiredUser: null,

    activeError: '',
    emailError: '',
    emailTypeIDError: '',
    idError: '',
    firstNameError: '',
    lastNameError: '',
    passwordError: '',
    passwordCopyError: '',
    passwordsDontMatchError: '',
    resetErrors() {
      localStore.activeError = '';
      localStore.emailError = '';
      localStore.emailTypeIDError = '';
      localStore.firstNameError = '';
      localStore.lastNameError = '';
      localStore.passwordError = '';
      localStore.passwordCopyError = '';
      localStore.passwordsDontMatchError = '';
    },

    async setUpdateID(email) {
      if (email !== updateUserStore.email) {
        localStore.isLoading = true;
        localStore.idError = '';

        let userResponse = await adminService.adminGetUser({ email });
        let desiredUser = userResponse.data;
        let userClientsResponse = await adminService.adminGetClientsByUser({ email });
        let userClients = userClientsResponse.data;
        updateUserStore.setInitialClientUserRoles(userClients);
        applySnapshot(updateUserStore, {
          firstName: desiredUser.firstName,
          lastName: desiredUser.lastName,
          email: desiredUser.email,
          active: desiredUser.active,
          clients: userClients.map(({ id, roles }) => {
            return {
              id,
              roles,
            };
          }),
        });
        localStore.isLoading = false;
      }
    },
    setUpdateFirstName(name) {
      localStore.firstNameError = '';
      updateUserStore.setUpdateFirstName(name.target.value);
    },
    setUpdateLastName(last) {
      localStore.lastNameError = '';
      updateUserStore.setUpdateLastName(last.target.value);
    },
    setUpdateEmail(email) {
      localStore.emailError = '';
      updateUserStore.setUpdateEmail(email.target.value);
    },
    setUpdateEmailType(type) {
      localStore.emailTypeIDError = '';
      updateUserStore.setUpdateEmailType(type);
    },
    setUpdateActive() {
      localStore.activeError = '';
      let bool = updateUserStore.active;
      bool = !bool;
      updateUserStore.setActive(bool);
    },
    initClientOptions: [],
    get disableSubmitBtn() {
      let clientsWithNoRoles = [];
      if (rootStore.userStore.updateUserStore.clients.length > 0) {
        _map(rootStore.userStore.updateUserStore.clients, (value, index) => {
          if (value.id && value.roles && value.roles.length === 0) {
            clientsWithNoRoles.push(index);
          }
        });
      }
      return clientsWithNoRoles;
    },
    get disabledClientOptions() {
      return _map(localStore.clientRoleObjects, (client) => {
        return client.id;
      });
    },
    get clientOptions() {
      let options = [{ value: 'all', text: 'All Clients', key: 'all' }];
      localStore.initClientOptions.map((option) => {
        options.push({
          ...option,
          disable: _includes(localStore.disabledClientOptions, option.value),
        });
      });
      return options;
    },
    get editClientOptions() {
      const options = [];
      localStore.initClientOptions.map((option) => {
        options.push({
          ...option,
          disable: _includes(localStore.disabledClientOptions, option.value),
        });
      });
      return options;
    },
    get emailOptions() {
      return _map(appData.emailTypes, (option) => ({
        value: option.id,
        text: option.desc,
        key: option.id,
      }));
    },
    get userOptions() {
      if (!localStore.filterUsersText && localStore.selectedClientId !== 'all') {
        return localStore.clientUserOptions;
      }
      // if (localStore.selectedClientId === 'all' && localStore.filterUsersText) {
      if (localStore.filterUsersText) {
        let options = [];

        let filterText = localStore.filterUsersText.toLowerCase();

        let userOptions = localStore.selectedClientId !== 'all' ? localStore.clientUserOptions : props.store.users;
        for (let i = 0; i < userOptions.length; i++) {
          let txtValue =
            localStore.selectedClientId !== 'all'
              ? userOptions[i].text
              : `${userOptions[i].lastName}, ${userOptions[i].firstName} - ${userOptions[i].email}`;

          if (txtValue && txtValue.toLowerCase().indexOf(filterText) > -1) {
            if (localStore.selectedClientId !== 'all') {
              options.push(userOptions[i]);
            } else {
              options.push({
                sort: userOptions[i].lastName,
                value: userOptions[i].email,
                text: `${userOptions[i].lastName}, ${userOptions[i].firstName} - ${userOptions[i].email}`,
                key: `${userOptions[i].email}`,
              });
            }
          }
        }
        return _sortBy(options, 'sort');
      }
      if (localStore.selectedClientId === 'all' && localStore.filterLastNameByLetter) {
        let filteredUserList = [];
        _map(props.store.users, (user) => {
          if (localStore.filterLastNameByLetter && _get(user,'lastName', '').charAt(0).toLowerCase() === localStore.filterLastNameByLetter) {
            filteredUserList.push({
              sort: user.lastName,
              value: user.email,
              text: `${user.lastName}, ${user.firstName} - ${user.email}`,
              key: `${user.email}`,
            });
          }
        });
        return _sortBy(filteredUserList, 'sort');
      }
      return _sortBy(_map(props.store.users, (user) => {
        return {
          sort: user.lastName,
          value: user.email,
          text: `${user.lastName}, ${user.firstName} - ${user.email}`,
          key: `${user.email}`,
        };
      }), 'sort');
    },
    get roleOptions() {
      return [
        { key: 1, text: 'Admin', value: 'admin' },
        { key: 2, text: 'User', value: 'user' },
        // { key: 5, text: 'Demo', value: 5 },
        // { key: 7, text: 'Api', value: 7 },
      ];
    },
    get clientRoleObjects() {
      return _get(updateUserStore, 'clients', []);
    },
    get deleteClientRoleObject() {
      return _get(updateUserStore, 'deleteClientRoleObject');
    },
    get setUpdatePassword() {
      return _get(updateUserStore, 'setPassword');
    },
    get activePlaceholder() {
      return localStore.activeError ? localStore.activeError : 'Is this user active?';
    },
    setCreateActive() {
      localStore.activeError = '';
      let bool = updateUserStore.active;
      bool = !bool;
      updateUserStore.setActive(bool);
    },
    addClient(id) {
      updateUserStore.setUpdateClients(id);
    },
    deleteItem(client) {
      updateUserStore.deleteClient(client.value);
    },
    onChangeForRoleObj(passed, clientRoleObject) {
      localStore.showDisableSubmitWarn = false;
      let test = clientRoleObject.roles.findIndex((roleID) => roleID === passed.value) === -1;
      if (test) clientRoleObject.addRole(passed.value);
      else clientRoleObject.deleteRole(passed.value);
    },
    async submitForm() {
      localStore.loading = true;
      updateUserStore.removeNullClients();
      /** Reset Any General Error Message or General Success Message **/
      localStore.generalSuccessMessage = '';
      localStore.generalErrorMessage = '';

      /** If the two passwords match, then submit a new user; if they don't match, then set errors **/
      if (localStore.updateNewPassword !== localStore.updatePasswordCopy)
        localStore.passwordsDontMatchError = 'Passwords Dont Match';
      else {
        if (localStore.updateNewPassword.length > 0) updateUserStore.setPassword(localStore.updateNewPassword);

        let response = await updateUserStore.submitUpdatedUser(props.store.clients);
        localStore.updateNewPassword = '';
        localStore.updatePasswordCopy = '';
        if (response.errFields) {
          response.errFields.forEach((obj) => {
            this[`${obj.field}Error`] = obj.errMsg;
          });
        }
        if (response.serverErrMsg.length > 0) {
          localStore.generalErrorMessage = response.serverErrMsg[0];
        }
        if (response.successMsg.length > 0) {
          notify({
            group: 'topRight',
            type: 'success',
            text: `${updateUserStore.firstName} ${updateUserStore.lastName} has been updated`,
            duration: 5000,
          });
          updateUserStore.resetState();

          localStore.desiredUser = null;
          localStore.resetErrors();
        }
      }
      localStore.loading = false;
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        localStore.initClientOptions = _sortBy(_map(props.store.clients, (option) => {
          return {
            value: option.id,
            text: option.clientName,
            key: `${option.id}${option.clientName}`,
            disable: false,
          };
        }), 'sort');
      }),
    []
  );

  useEffect(() => {
    return function cleanup() {
      updateUserStore.resetState();
    };
  }, []);

  const labelWidth = '180px';

  return (
    <Observer>
      {() => (
        <>
          {localStore.loading && <LoadingSpinner size={'200px'} />}
          {!localStore.loading && (
            <Wrapper height="100%">
              <div className="innerContainer">
                <div className="user-side">
                  {localStore.generalErrorMessage && (
                    <div className="generalMessageRow error">
                      <h3>{localStore.generalErrorMessage}</h3>
                    </div>
                  )}
                  {localStore.generalSuccessMessage && (
                    <div className="generalMessageRow success">
                      <h3>{localStore.generalSuccessMessage}</h3>
                    </div>
                  )}
                  <FormRow>
                    <Observer>
                      {() => (
                        <Dropdown
                          className={'Dropdown'}
                          options={localStore.clientOptions}
                          onChange={localStore.setSelectedClientId}
                          placeholder={'Select a Client'}
                          value={localStore.selectedClientId}
                          flexDirection={'column'}
                          width={'calc(100% - 40px)'}
                          margin={'4px 0 0 40px'}
                          border
                          label={'Filter Users By Client'}
                          search
                        />
                      )}
                    </Observer>
                  </FormRow>
                  <FormRow>
                    <Input
                      onChange={(e) => localStore.setUserFilterText(e.target.value)}
                      placeholder={'Filter Users'}
                      value={localStore.filterUsersText}
                      flexDirection={'column'}
                      width={'calc(100% - 40px)'}
                      margin={'4px 0 0 40px'}
                      border
                      label={'Filter All Users'}
                    />
                  </FormRow>
                  <FormRow>
                    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', margin: '5px 0' }}>
                      {alphabet.map((letter) => (
                        <div
                          key={letter}
                          style={{
                            padding: '0 2px',
                            borderBottom: localStore.filterLastNameByLetter === letter ? '1px solid #fff' : '',
                          }}
                          onClick={() => localStore.setUserFilterByLetter(letter)}
                        >
                          {letter.toUpperCase()}
                        </div>
                      ))}
                    </div>
                    {localStore.loadingUsers && (
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>...Loading Users</div>
                    )}
                    {!localStore.loadingUsers &&
                      localStore.userOptions &&
                      localStore.userOptions.map((option, index) => (

                        <Observer key={index}>
                          {() => (
                              <>
                            <Checkbox
                              onChange={() => {
                                localStore.setUpdateID(option.value);
                              }}
                              key={index}
                              label={_upperFirst(option.text)}
                              labelRight={true}
                              margin="0 0 0 36px"
                              checked={updateUserStore.email === option.value ? true : false}
                              value={updateUserStore.email === option.value ? true : false}
                              labelWidth={'100%'}
                            />
                              </>
                          )}
                        </Observer>
                      ))}
                  </FormRow>
                  <br />
                </div>
                <div className="client-side">
                  <div className="title">
                    {updateUserStore.id && (
                      <>
                        {_toUpper(updateUserStore.lastName)}, {_toUpper(updateUserStore.firstName)}
                      </>
                    )}
                  </div>
                  {localStore.isLoading && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ display: 'flex', textAlign: 'center' }}>...Loading User</div>
                    </div>
                  )}
                  {updateUserStore.email && !localStore.isLoading && (
                    <Fragment>
                      <FormRow>
                        <Input
                          error={!!localStore.firstNameError}
                          onChange={updateUserStore.setFirstName}
                          placeholder={localStore.firstNameError ? localStore.firstNameError : 'First Name'}
                          value={updateUserStore.firstName}
                          label={'First Name'}
                          labelWidth={labelWidth}
                          margin={'4px 0 0 0'}
                          border
                        />
                      </FormRow>
                      <FormRow>
                        <Input
                          error={!!localStore.lastNameError}
                          onChange={updateUserStore.setLastName}
                          placeholder={localStore.lastNameError ? localStore.lastNameError : 'Last Name'}
                          value={updateUserStore.lastName}
                          label={'Last Name'}
                          labelWidth={labelWidth}
                          border
                        />
                      </FormRow>
                      <FormRow>
                        <Input
                          error={!!localStore.emailError}
                          onChange={updateUserStore.setEmail}
                          placeholder={localStore.emailError ? localStore.emailError : 'Email'}
                          value={updateUserStore.email}
                          label={'User Email'}
                          labelWidth={labelWidth}
                          border
                        />
                      </FormRow>
                      {localStore.passwordsDontMatchError && (
                        <FormRow>
                          <div
                            style={{
                              color: 'red',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <h4>Passwords Don't Match</h4>
                          </div>
                        </FormRow>
                      )}
                      <FormRow>
                        <Input
                          error={!!localStore.passwordError}
                          onChange={(e) => {
                            localStore.updateNewPassword = e.target.value;
                          }}
                          placeholder={
                            localStore.passwordError ? localStore.passwordError : 'If Desired - Type in a New Password'
                          }
                          value={localStore.updateNewPassword}
                          label={'New Password'}
                          labelWidth={labelWidth}
                          border
                        />
                      </FormRow>
                      <FormRow>
                        <Input
                          error={!!localStore.passwordCopyError}
                          onChange={(e) => {
                            localStore.updatePasswordCopy = e.target.value;
                          }}
                          placeholder={
                            localStore.passwordCopyError ? localStore.passwordCopyError : 'Enter The New Password Again'
                          }
                          value={localStore.updatePasswordCopy}
                          label={'Re Enter Password'}
                          labelWidth={labelWidth}
                          border
                        />
                      </FormRow>
                      <br />
                      <div className="btnRow">
                        {localStore.showDisableSubmitWarn && (
                          <div className="selectRoleMsg">All clients must have at lease one role</div>
                        )}
                        <div className="btnContainer">
                          <SubmitButton
                            className="submit"
                            width={'100px'}
                            onClick={() => {
                              if (localStore.disableSubmitBtn.length === 0) {
                                localStore.showDisableSubmitWarn = false;
                                localStore.submitForm();
                              } else {
                                localStore.showDisableSubmitWarn = true;
                              }
                            }}
                          >
                            Submit Form
                          </SubmitButton>
                          <SubmitButton
                            width={'100px'}
                            margin="0 0 0 5px"
                            className="submit"
                            onClick={() => updateUserStore.addClient(undefined)}
                          >
                            Add Client
                          </SubmitButton>
                          <ClearButton
                            margin="0 0 0 5px"
                            width={'100px'}
                            onClick={() => {
                              updateUserStore.resetState();
                              localStore.createPassword = '';
                              localStore.createPasswordCopy = '';
                              localStore.showDisableSubmitWarn = false;
                              localStore.resetErrors();
                            }}
                          >
                            Clear Form
                          </ClearButton>
                        </div>
                      </div>
                      <br />
                    </Fragment>
                  )}
                  {updateUserStore.clients && updateUserStore.clients.length > 0 && (
                    <div style={{ borderTop: '1px solid rgba(255,255,255, .2)' }} />
                  )}
                  {localStore.clientRoleObjects.length > 0 &&
                    !localStore.isLoading &&
                    _map(updateUserStore.clients, (clientRoleObject, i) => {
                      return (
                        <ReportWrapper noBackground margin={'0 0'} key={i}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            {clientRoleObject.id && (
                              <div style={{ width: '100%' }}>
                                {_get(_find(localStore.clientOptions, { value: clientRoleObject.id }), 'text')}
                              </div>
                            )}
                            {!clientRoleObject.id && (
                              <Observer>
                                {() => (
                                  <Dropdown
                                    className={'Dropdown'}
                                    options={localStore.editClientOptions}
                                    onChange={(val) => clientRoleObject.setClientID(val)}
                                    placeholder={'Select a Client'}
                                    value={clientRoleObject.email}
                                    margin={'3px 0 3px 0'}
                                    width={'170px'}
                                    border
                                    tabIndex={7 + i}
                                    search
                                  />
                                )}
                              </Observer>
                            )}

                            {clientRoleObject.id && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '0 5px',
                                  }}
                                >
                                  {localStore.roleOptions.map((opt, index) => {
                                    let checked = _includes(clientRoleObject.roles, opt.value);
                                    return (
                                      <Checkbox
                                        width={'75px'}
                                        onChange={() => localStore.onChangeForRoleObj(opt, clientRoleObject)}
                                        key={index}
                                        label={_upperFirst(opt.text)}
                                        value={checked}
                                        checked={checked}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                type="delete"
                                width="15px"
                                height="15px"
                                style={{ marginLeft: '10px' }}
                                onClick={() => localStore.deleteClientRoleObject(i)}
                              >
                                <ClearIcon id="filterBtn" color="#fff" style={{ width: 15, height: 15 }} />
                              </Button>
                            </div>
                          </div>
                        </ReportWrapper>
                      );
                    })}
                </div>
              </div>
            </Wrapper>
          )}
        </>
      )}
    </Observer>
  );
});

export const SubmitButton = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background-color: #3fa9f5;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;

export const ClearButton = styled.div`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ theme }) => theme.buttonStyles.delete};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  margin: ${({ margin }) => margin};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.buttonStyles.deleteHover};
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: ${({ height }) => height || '100%'};
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
  .innerContainer {
    display: flex;
    width: 100%;
    max-width: 1040px;
  }
  .user-side {
    width: 100%;
    padding: 20px 5px;
    overflow-y: auto;
  }
  .client-side {
    width: 100%;
    padding: 42px 55px 55px 0;
    overflow-y: auto;
    margin-bottom: 50px;
    .title {
      width: 100%;
      text-align: center;
      padding-left: 56px;
      margin-bottom: 5px;
    }
  }
  .generalMessageRow {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    &.success {
      color: ${({ theme }) => theme.baseColors.colorGreen};
    }
    &.error {
      color: ${({ theme }) => theme.baseColors.colorOrange};
    }
  }

  .btnRow {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //padding-left: 145p;
    .selectRoleMsg {
      color: red;
      display: flex;
      margin: 5px 0;
    }
    .btnContainer {
      align-items: center;
      display: flex;
      max-height: 33px;
      //justify-content: space-between;
      .btn {
        border-radius: 5px;
        //box-shadow: 2px 2px 2px #4d4d4d;
        cursor: pointer;
        font-size: 14px;
        margin: 0 5px;
        outline: none;
        padding: 4px 10px;
        transition: color 0.25s, background 0.25s, border 0.25s, box-shadow 0.25s;
        &.primary {
          background-color: ${({ theme }) => theme.baseColors.colorBlue};
          border: ${({ theme }) => `1px solid ${theme.baseColors.colorBlue}`};
          color: #fff;

          &:hover {
            background-color: ${({ theme }) => theme.baseColors.colorBlueHover};
            border-color: ${({ theme }) => `1px solid ${theme.baseColors.colorBlue}`};
          }
        }

        &.warn {
          background-color: ${({ theme }) => theme.baseColors.colorOrange};
          border: ${({ theme }) => `1px solid ${theme.baseColors.colorOrange}`};
          color: #fff;

          &:hover {
            background-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
            border-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
          }
        }
      }
    }
  }

  .selectClientRow {
    align-items: flex-start;
    border-top: ${({ theme }) => `1px solid ${theme.baseColors.colorGreyMedium}`};
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-top: 10px;
  }

  .deleteButton {
    background-color: ${({ theme }) => theme.baseColors.colorOrange};
    border: ${({ theme }) => `1px solid ${theme.baseColors.colorOrange}`};
    border-radius: 5px;
    box-shadow: 2px 2px 2px #4d4d4d;
    color: #fff;
    cursor: pointer;
    font-size: inherit;
    margin: 0 5px;
    outline: none;
    padding: 4px 10px;
    transition: color 0.25s, background 0.25s, border 0.25s, box-shadow 0.25s;

    &:hover {
      background-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
      border-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
    }
  }
`;

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ noBackground, theme }) => {
    if (noBackground && noBackground === true) return 'none';
    else return theme.tableStyles.backgroundColor;
  }};
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  //box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${(props) => {
    if (props.margin && !(props.marginLeft || props.marginRight || props.marginTop || props.marginBottom))
      return `${props.margin}`;
    else if (props.marginLeft || props.marginRight || props.marginTop || props.marginBottom) {
      let marginTop = props.marginTop ? props.marginTop : '0';
      let marginRight = props.marginRight ? props.marginRight : '0';
      let marginBottom = props.marginBottom ? props.marginBottom : '0';
      let marginLeft = props.marginLeft ? props.marginLeft : '0';
      return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
    } else return '0';
  }};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

export default UpdateUser;
