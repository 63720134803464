import React, { useEffect } from 'react';
import { _get, useInjectStores } from 'utils/utils';
import {
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper
} from 'styles/styledComponents';
import { RouteLoadingSpinner } from 'components/UI';
import { Observer, useLocalObservable } from 'mobx-react-lite';

export const Fees = (props) => {
  const { utilsStore } = useInjectStores();
  const toFormatNumber = utilsStore.toFormatNumber;

  const store = useLocalObservable(() => ({
    widgetData: null,
    // get ppmFeesSection() {
    //   return _get(this, "widgetData[0]['stmt']['MERCHANT_STATEMENT_APPLICATION']['CUSTOMER'][0]['PPM_FEES_SECTION'][0]");
    // },
    get feesSection() {
      return _get(this, 'widgetData[\'MERCHANT_STATEMENT_APPLICATION\'][\'CUSTOMER\'][0][\'PPM_FEES_SECTION\'][0]');
    },
    get authFeeTrans() {
      return _get(this.feesSection, '["AUTH_FEES"][0][FEE_TRANS]');
    },
    get authFeeTotal() {
      return this.formatNumber(_get(this, 'feesSection["AUTH_FEES"][0][PPM_TOTAL_AUTH_FEES][0]', '0'));
    },
    get cbFeeTrans() {
      return _get(this.feesSection, '["CB_FEES"][0][FEE_TRANS]');
    },
    get cbFeeTotal() {
      return this.formatNumber(_get(this, 'feesSection["CB_FEES"][0][PPM_TOTAL_CB_FEES][0]'));
    },
    get intChgFeeTrans() {
      return _get(this.feesSection, '["INTER_CHANGE_FEES"][0][FEE_TRANS]');
    },
    get intChgFeeTotal() {
      return this.formatNumber(_get(this, 'feesSection["INTER_CHANGE_FEES"][0][PPM_TOTAL_INTER_FEES][0]'));
    },
    get othFeeTrans() {
      return _get(this.feesSection, '["OTH_FEES"][0][FEE_TRANS]', []);
    },
    get othFeeTotal() {
      return this.formatNumber(_get(this, 'feesSection["OTH_FEES"][0][PPM_TOTAL_OTHER_FEES][0]'));
    },
    get transFeeTrans() {
      return _get(this.feesSection, '["TRANS_FEES"][0]["FEE_TRANS"]');
    },
    get transFeeTotal() {
      return this.formatNumber(_get(this, 'feesSection["TRANS_FEES"][0][PPM_TOTAL_TRANS_FEES][0]'));
    },
    get ppmTotalFeesDue() {
      return _get(this, 'feesSection["PPM_TOTAL_FEES_DUE"][0]');
    },
    formatNumber(total) {
      if (total) {
        return toFormatNumber(parseFloat(total.replace(/,/g, '')), 2);
      }
    },
    setWidgetData(data) {
      this.widgetData = data;
    },
  }));

  useEffect(() => {
    store.setWidgetData(props.store.widgetData);
  }, [props.store.widgetData]);

  // const authFeeTrans = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["AUTH_FEES"][0][FEE_TRANS]', []);
  // let authFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["AUTH_FEES"][0][PPM_TOTAL_AUTH_FEES][0]', '0') || '0';
  // authFeeTotal = parseFloat(authFeeTotal.replace(/,/g, ''));
  // authFeeTotal = toFormatNumber(authFeeTotal, 2);

  // const cbFeeTrans = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["CB_FEES"][0][FEE_TRANS]', []);
  // let cbFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["CB_FEES"][0][PPM_TOTAL_CB_FEES][0]', []);
  // cbFeeTotal = parseFloat(cbFeeTotal.replace(/,/g, ''));
  // cbFeeTotal = toFormatNumber(cbFeeTotal, 2);

  // const intChgFeeTrans = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["INTER_CHANGE_FEES"][0][FEE_TRANS]', []);
  // let intChgFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["INTER_CHANGE_FEES"][0][PPM_TOTAL_INTER_FEES][0]', []);
  // intChgFeeTotal = parseFloat(intChgFeeTotal.replace(/,/g, ''));
  // intChgFeeTotal = toFormatNumber(intChgFeeTotal, 2);

  // const othFeeTrans = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["OTH_FEES"][0][FEE_TRANS]', []);
  // let othFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["OTH_FEES"][0][PPM_TOTAL_OTHER_FEES][0]', []);
  // othFeeTotal = parseFloat(othFeeTotal.replace(/,/g, ''));
  // othFeeTotal = toFormatNumber(othFeeTotal, 2);
  // const ppmFeeTrans = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["PPM_TOTAL_FEES_DUE"][0][FEE_TRANS]', []);
  // let ppmFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["PPM_TOTAL_FEES_DUE"][0][PPM_TOTAL_FEES_DUE][0]', []);
  // let ppmFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["PPM_TOTAL_FEES_DUE"][0][PPM_TOTAL_FEES_DUE][0]', []);
  // authFeeTotal = parseFloat(authFeeTotal.replace(/,/g, ''));
  // authFeeTotal = toFormatNumber(authFeeTotal, 2);
  // const transFeeTrans = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["TRANS_FEES"][0][FEE_TRANS]', []);
  // let transFeeTotal = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["TRANS_FEES"][0][PPM_TOTAL_TRANS_FEES][0]', []);
  // transFeeTotal = parseFloat(transFeeTotal.replace(/,/g, ''));
  // transFeeTotal = toFormatNumber(transFeeTotal, 2);
  // const fee = _get(props, 'widgetData[0]', []);

  // const feeTransTotals = _get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["INTER_CHANGE_FEES"][0]["PPM_TOTAL_INTER_FEES"]', []);

  return (
    <Observer>
      {() => (
        <TableWrapper background="#ffffff" height="100%">
          <TableHeader light={true} backgroundColor="#e9eae9" color="black">
            Fees
          </TableHeader>
          {props.store.isLoading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  <>
                    <TableRow>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Count
                      </HeaderCell>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Amount
                      </HeaderCell>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Rate %
                      </HeaderCell>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Rate Per Item
                      </HeaderCell>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Description
                      </HeaderCell>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Fees Paid
                      </HeaderCell>
                      <HeaderCell borderBottom="1px solid #000000 !important" light={true}>
                        Total
                      </HeaderCell>
                      {/*<HeaderCell light={true}>Settled</HeaderCell>*/}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }} light={true}>
                        Authorization Fees:
                      </TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                    </TableRow>
                    {store.authFeeTrans && store.authFeeTrans.length > 0 && (
                      <>
                        {store.authFeeTrans.map((detail, index) => {
                          let amount = store.formatNumber(_get(detail, '[AMOUNT][0]') || '0');
                          return (
                            <TableRow className={index !== 0 && index % 13 === 0 ? 'html2pdf__page-break' : ''} key={`${index} ${detail['RATE_PERC']}`}>
                              <TableCell light={true}>{detail['COUNT']}</TableCell>
                              <TableCell light={true}>
                                {/*{detail['AMOUNT'] ? formatNumber(parseFloat(detail['AMOUNT'].replace(/,/g, ''))) : ''}*/}
                                {amount}
                              </TableCell>
                              <TableCell light={true}>{detail['RATE_PERC']}</TableCell>
                              <TableCell light={true}>{detail['RATE_PER_ITEM']}</TableCell>
                              <TableCell textAlign="left" light={true}>
                                {detail['DESCRIPTION']}
                              </TableCell>
                              <TableCell light={true}>
                                {detail['FEES_PAID'] === '.00'
                                  ? detail['FEES_PAID']
                                  : '' === '.00'
                                  ? detail['FEES_PAID'] === '.00'
                                    ? detail['FEES_PAID']
                                    : ''
                                  : ''}
                              </TableCell>
                              <TableCell light={true}>{detail['TOTAL']}</TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                    {store.authFeeTotal && (
                      <TableRow>
                        <TableCell light={true} style={{ fontWeight: 'bold' }}>
                          Auth Fees Total
                        </TableCell>
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell style={{ fontWeight: 'bold' }} light={true}>
                          {store.authFeeTotal}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow className='html2pdf__page-break'>
                      <TableCell style={{ fontWeight: 'bold' }} light={true}>
                        INTERCHANGE FEES/
                      </TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                    </TableRow>
                    <TableRow className='html2pdf__page-break'>
                      <TableCell style={{ fontWeight: 'bold' }} light={true}>
                        AMEX PROGRAM FEES:
                      </TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                    </TableRow>
                    {store.intChgFeeTrans && store.intChgFeeTrans.length > 0 && (
                      <>
                        {store.intChgFeeTrans.map((detail, index) => {
                          let amount = store.formatNumber(detail['AMOUNT'][0]);

                          return (
                            <TableRow className={index !== 0 && index % 20 === 0 ? 'html2pdf__page-break' : ''} key={`${index} ${detail['RATE_PERC']}`}>
                              <TableCell light={true}>{detail['COUNT']}</TableCell>
                              <TableCell light={true}>{amount}</TableCell>
                              <TableCell light={true}>{detail['RATE_PERC']}</TableCell>
                              <TableCell light={true}>{detail['RATE_PER_ITEM']}</TableCell>
                              <TableCell textAlign="left" light={true}>
                                {detail['DESCRIPTION']}
                              </TableCell>
                              <TableCell light={true}>
                                {detail['FEES_PAID'] === '.00'
                                  ? detail['FEES_PAID']
                                  : '' === '.00'
                                  ? detail['FEES_PAID'] === '.00'
                                    ? detail['FEES_PAID']
                                    : ''
                                  : ''}
                              </TableCell>
                              <TableCell light={true}>{detail['TOTAL']}</TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                    {store.intChgFeeTrans && (
                      <TableRow>
                        <TableCell light={true} style={{ fontWeight: 'bold' }}>
                          Total Interchange Fees
                        </TableCell>
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell style={{ fontWeight: 'bold' }} light={true}>
                          {store.intChgFeeTotal}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow className='html2pdf__page-break' style={{ position: 'relative' }}>
                      <TableCell
                        light={true}
                        style={{ fontWeight: 'bold' }}
                        // style={{ position: 'absolute', bottom: '5px' }}
                      >
                        TRANSACTION FEES:
                      </TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                    </TableRow>
                    {store.transFeeTrans && store.transFeeTrans.length > 0 && (
                      <>
                        {store?.transFeeTrans?.map((detail, index) => {
                          let amount = store.formatNumber(detail['AMOUNT'][0]);

                          return (
                            <TableRow className={index !== 0 && index % 20 === 0 ? 'html2pdf__page-break' : ''} key={`${index} ${detail['RATE_PERC']}`}>
                              <TableCell light={true}>{detail['COUNT']}</TableCell>
                              <TableCell light={true}>{amount}</TableCell>
                              <TableCell light={true}>{detail['RATE_PERC']}</TableCell>
                              <TableCell light={true}>{detail['RATE_PER_ITEM']}</TableCell>
                              <TableCell textAlign="left" light={true}>
                                {detail['DESCRIPTION']}
                              </TableCell>
                              <TableCell light={true}>{detail['FEES_PAID'] === '.00' ? detail['FEES_PAID'] : ''}</TableCell>
                              <TableCell light={true}>{detail['TOTAL']}</TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                    {store?.transFeeTotal && (
                      <TableRow>
                        <TableCell light={true} style={{ fontWeight: 'bold' }}>
                          Trans Fees Total
                        </TableCell>
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell style={{ fontWeight: 'bold' }} light={true}>
                          {store.transFeeTotal}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow className='html2pdf__page-break' style={{ position: 'relative' }}>
                      <TableCell
                        light={true}
                        style={{ fontWeight: 'bold' }}
                        // style={{ position: 'absolute', bottom: '5px' }}
                      >
                        CARD BRAND FEES:
                      </TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                    </TableRow>
                    {store.cbFeeTrans && store.cbFeeTrans.length > 0 && (
                      <>
                        {store.cbFeeTrans.map((detail, index) => {
                          let amount = store.formatNumber(detail['AMOUNT'][0]);

                          return (
                            <TableRow className={index !== 0 && index % 20 === 0 ? 'html2pdf__page-break' : ''} key={`${index} ${detail['RATE_PERC']}`}>
                              <TableCell light={true}>{detail['COUNT']}</TableCell>
                              <TableCell light={true}>{amount}</TableCell>
                              <TableCell light={true}>{detail['RATE_PERC']}</TableCell>
                              <TableCell light={true}>{detail['RATE_PER_ITEM']}</TableCell>
                              <TableCell textAlign="left" light={true}>
                                {detail['DESCRIPTION']}
                              </TableCell>
                              <TableCell light={true}>{detail['FEES_PAID'] === '.00' ? detail['FEES_PAID'] : ''}</TableCell>
                              <TableCell light={true}>{detail['TOTAL']}</TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                    {store.cbFeeTotal && (
                      <TableRow>
                        <TableCell light={true} style={{ fontWeight: 'bold' }}>
                          Card Brand Fees Total
                        </TableCell>
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell style={{ fontWeight: 'bold' }} light={true}>
                          {store.cbFeeTotal}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow className='html2pdf__page-break' style={{ position: 'relative' }}>
                      <TableCell
                        light={true}
                        style={{ fontWeight: 'bold' }}
                        // style={{ position: 'absolute', bottom: '5px' }}
                      >
                        OTHER FEES:
                      </TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                      <TableCell light={true}></TableCell>
                    </TableRow>
                    {store?.othFeeTrans && store?.othFeeTrans?.length > 0 && (
                      <>
                        {store?.othFeeTrans.map((detail, index) => {
                          let amount = store.formatNumber(detail['AMOUNT'][0]);

                          return (
                            <TableRow className={index !== 0 && index % 20 === 0 ? 'html2pdf__page-break' : ''} key={`${index} ${detail['RATE_PERC']}`}>
                              <TableCell light={true}>{detail['COUNT']}</TableCell>
                              <TableCell light={true}>{amount}</TableCell>
                              <TableCell light={true}>{detail['RATE_PERC']}</TableCell>
                              <TableCell light={true}>{detail['RATE_PER_ITEM']}</TableCell>
                              <TableCell textAlign="left" light={true}>
                                {detail['DESCRIPTION']}
                              </TableCell>
                              <TableCell light={true}>{detail['FEES_PAID'] === '.00' ? detail['FEES_PAID'] : ''}</TableCell>
                              <TableCell light={true}>{detail['TOTAL']}</TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                    {store?.othFeeTotal && (
                      <TableRow>
                        <TableCell light={true} style={{ fontWeight: 'bold' }}>
                          Other Fees Total
                        </TableCell>
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell style={{ fontWeight: 'bold' }} light={true}>
                          {store.othFeeTotal}
                        </TableCell>
                      </TableRow>
                    )}
                    {store?.othFeeTotal && store?.ppmTotalFeesDue && (
                      <TableRow className='html2pdf__page-break'>
                        <TableCell light={true} style={{ fontWeight: 'bold' }}>
                          Total Fees
                        </TableCell>
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell light={true} />
                        <TableCell style={{ fontWeight: 'bold' }} light={true}>
                          {store.ppmTotalFeesDue}
                          {/*{_get(props, 'widgetData[0]["PPM_FEES_SECTION"][0]["PPM_TOTAL_FEES_DUE"][0]')}*/}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TableWrapper>
      )}
    </Observer>
  );
};

export default Fees;
