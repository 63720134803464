import React, { useEffect } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Notes, Table, Dropdown } from 'components/UI';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores, buildColDef } from 'utils/utils';
import { widgetService } from 'services';
import Moment from "moment/moment";
import {getFileName} from 'utils/utils';

const IsoBankRevenue = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel,
    setTxnDetails = dataManagementStore.setTxnDetails,
    toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal,
    paginatorModel = widgetModel.paginatorModel;

	const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
	const toDateFormatter = utilsStore.toDateFormatter;
	const toPercent = utilsStore.toPercent;
	const toPercentFormatter = utilsStore.toPercentFormatter;
	const toFormatNumber = utilsStore.toFormatNumber;

  const orderByOptions = [
    { key: 1, text: 'Cust Name', value: 'custName' },
    { key: 2, text: 'Invoice Num', value: 'invNum' },
    { key: 3, text: 'Txn Type', value: 'txnType' },
    { key: 4, text: 'Txn Status', value: 'txnStatus' },
    { key: 5, text: 'Txn Date', value: 'txnDt' },
  ];

  function cellStyle(params) {
    if (params.value < 0) {
      return { color: '#FF2B49' };
    } else {
      // return { color: 'rgb(69, 232, 133)' };
    }
  }

  const initialData = { txns: [], count: 0 };

  function convertToTitleCase(str) {
	const titleCased = str.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
	// Little formatting tweaks
	return titleCased.replace(/\bmid\b/gi, 'MID')
	.replace(/\bet\b/gi, 'ET')
	.replace(/\bcc\b/gi, 'CC')
	.replace(/\btf\b/gi, 'T/F')
	.replace(/\bic\b/gi, 'I/C')
	.replace(/\bid\b/gi, 'ID')
	.replace(/\bmastercard\b/gi, 'MC')
	.replace(/\bmc\b/gi, 'MC')
	.replace(/\bmcc\b/gi, 'MCC')
	.replace(/\bopportunity\b/gi, 'Opp')
	.replace(/\bamex\b/gi, 'AMEX')
	.replace(/\btif\b/gi, 'TIF')
	.replace(/\tsys\b/gi, 'Proc')
	.replace(/\tsys1\b/gi, 'Proc1')
	.replace(/\tsys2\b/gi, 'Proc2')
	.replace(/\cp\b/gi, 'CP')
	.replace(/\cnp\b/gi, 'CNP')
	.replace(/\cvc\b/gi, 'CVC')
	.replace(/\mti\b/gi, 'MTI')
	.replace(/\tcvv2sys\b/gi, 'CVV2')
	.replace(/\avs\b/gi, 'AVS')
	.replace(/\pos\b/gi, 'POS')
	.replace(/\bDt\b/gi, 'Date');
  };


  const store = useLocalStore(() => ({
    fileName: 'bankRevenue',
    storageKey: 'txn-details-state',
    loading: false,
    selectedOption: widgetModel.date,
    columnDefs: [
      {
        headerName: convertToTitleCase('fee_dt_eom'),
        field: 'fee_dt_eom',
        valueFormatter: toDateFormatter,
        chartDataType: 'series',
        width: 125,
        headerTooltip: convertToTitleCase('fee_dt_eom')
      },
      {
        headerName: convertToTitleCase('et_platfrm_nm'),
        field: 'et_platfrm_nm',
        chartDataType: 'series',
        width: 155,
        headerTooltip: convertToTitleCase('et_platfrm_nm')
      },
      {
        headerName: convertToTitleCase('et_proc_bank_num'),
        field: 'et_proc_bank_num',
        chartDataType: 'series',
        width: 160,
        headerTooltip: convertToTitleCase('et_proc_bank_num')
      },
      {
        headerName: convertToTitleCase('et_acq_bank'),
        field: 'et_acq_bank',
        chartDataType: 'series',
        width: 160,
        headerTooltip: convertToTitleCase('et_acq_bank')
      },
      {
        headerName: convertToTitleCase('bank_rep_desc'),
        field: 'bank_rep_desc',
        chartDataType: 'series',
        width: 240,
        headerTooltip: convertToTitleCase('bank_rep_desc')
      },
	    {
        headerName: convertToTitleCase('monthly_amt'),
        field: 'monthly_amt',
        cellStyle: cellStyle,
        valueFormatter: toCurrencyFormatter,
        type: 'rightAligned',
        chartDataType: 'series',
        width: 130,
        headerTooltip: convertToTitleCase('monthly_amt')
      }
	],
    widgetData: initialData,
    rowData: [],
    txnCount: 0,
    async getData() {
      store.loading = true;
      try {
        widgetModel.setIsHistoric(true);
        const res = await widgetModel.fetchWidgetData(widgetService.isoBankRevenueMonthly);
        // const monthlyRes = await widgetModel.fetchWidgetData(widgetService.isoBankRevenueMonthly);
        store.widgetData = res.data;
        store.fileName = getFileName(userStore, res?.config, 'bankRevenue');
        store.rowData = store.widgetData.values.map((txn, index) => {
            txn['Index'] = paginatorModel.currentStartingIndex + (index + 1);
            return {
                ...txn
            };
        });
      
        store.txnCount = store.widgetData.count || store.widgetData.values.length;
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      widgetModel.setIsHistoric(false);
      store.loading = false;
    },
    orderByOptions,
    dataManagementStore,
    widgetModel,
    paginatorModel,
    api: null,
    columnApi: null,
    saveColumnState() {
      let savedState = store.columnApi.getColumnState();
      savedState = JSON.stringify(savedState);
      if (savedState) {
        window.localStorage.setItem('txn-details-state', savedState);
      }
    },
    setColumnState() {
      let savedState = window.localStorage.getItem('txn-details-state');
      savedState = JSON.parse(savedState);
      if (savedState && store.columnApi) {
        store.columnApi.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    resetColumnState() {
      window.localStorage.removeItem('txn-details-state');
      store.columnApi.resetColumnState();
    },
    get dropdownOptions() {
      if (this.widgetData?.history?.length > 0) {
          return this.widgetData?.history?.map(({fee_dt_eom}, index) => {
              return {
                  key: index,
                  text: `${Moment(fee_dt_eom).format('YYYY')}: ${Moment(fee_dt_eom).format('MMMM')}`,
                  value: fee_dt_eom
              };
          });
      }
      return [];
    },
    selectOption(val) {
      this.selectedOption = val;
      widgetModel.setDate(val);
      this.getData();
    },
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        rowSelection: 'multiple',
        rowHeight: 33,
        headerHeight: 33,
        sideBar: {
          position: 'left',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
        },
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          suppressMenu: true
        },
        columnDefs: store.columnDefs,

        onGridReady(params) {
          store.api = params.api;
          store.columnApi = params.columnApi;
          store.setColumnState();
        },
        onFirstDataRendered(params) {
          setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          paginatorModel.setPageNumber(1);
          await store.getData();
        }
      }),
    []
  );
  
  return (
    <Observer>
      {() => (
        // <TableWrapper>
        //   <Table
        //     store={store}
        //     fetchTableData={store.getData}
        //     rowData={store.rowData}
        //     loading={store.loading}
        //     /**export**/
        //     initiateTxnsExport={store.widgetModel.initiateTxnsExport}
        //     exportInfo={store.widgetModel.exportInfo}
        //     /** Paginator-Specific Functions **/
        //     txnCount={store.txnCount}
        //     paginator={paginatorModel}
        //     enableBrowserTooltips={true}
        //     /** OrderBy Properties **/
        //     addOrderBy={store.widgetModel.addOrderBy}
        //     orderByArray={store.widgetModel.orderByModel}
        //     orderByOptions={store.orderByOptions}
        //     resetOrderBy={store.widgetModel.resetOrderBy}
        //   />
        // </TableWrapper>
        <Wrapper1>
          <div className={'one'} style={{
              width: 'calc(100% - 40px)',
              margin: '3px 0',
              alignItems: 'center',
              display: 'flex'
          }}>
              <Dropdown
                  id="historical"
                  options={store.dropdownOptions}
                  value={widgetModel.date}
                  onChange={store.selectOption}
                  placeholder="Select Month"
                  width={'140px'}
                  height={'20px'}
              />
          </div>
          <div className="two">
          <Table
              store={store}
              fetchTableData={store.getData}
              rowData={store.rowData}
              loading={store.loading}
              /**export**/
              initiateTxnsExport={store.widgetModel.initiateTxnsExport}
              exportInfo={store.widgetModel.exportInfo}
              /** Paginator-Specific Functions **/
              txnCount={store.txnCount}
              paginator={paginatorModel}
              enableBrowserTooltips={true}
              /** OrderBy Properties **/
              addOrderBy={store.widgetModel.addOrderBy}
              orderByArray={store.widgetModel.orderByModel}
              orderByOptions={store.orderByOptions}
              resetOrderBy={store.widgetModel.resetOrderBy}
            />
          </div>
        </Wrapper1>
      )}
    </Observer>
  );
});



export default IsoBankRevenue;

const TableWrapper = styled.div`
height: 98%;
margin-left: 5px;
margin-right: 5px;
`;
const Wrapper1 = styled.div`
  display: grid;

  .one {
    grid-area: one;
  }

  .two {
    grid-area: two;
  }

  grid-template-areas: 
          'one'
          'two';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  //grid-gap: 5px;
  height: 100%;
`;