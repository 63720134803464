import { getParent, types } from 'mobx-state-tree';

const { string, number, optional } = types;

const OrderByField = types
  .model('orderByModel', {
    column: optional(string, ''),
    sort: optional(number, 1)
  })
  .actions(self => ({
    setColumn(column) {
      self.column = column;
    },
    setSort(sort) {
      self.sort = sort;
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  }));

export default OrderByField;
