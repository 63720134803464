import React, { useEffect, useRef } from 'react';
import { CaretDownIcon, CaretUpIcon } from 'styles/styledComponents';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { Input } from 'components/UI';
import { _get, _find } from 'utils/utils';
import styled from 'styled-components';

const CustomDropdown = observer((props) => {
  const node = useRef();
  const store = useLocalObservable(() => ({
    filter: '',
    flipDropdown: false,
    open: false,
    propsOptions: [],
    get options() {
      let options = [];
      let filterText = this.filter.toLowerCase();
      for (let i = 0; i < this.propsOptions.length; i++) {
        let txtValue = this.propsOptions[i].text;
        if (txtValue && txtValue.toLowerCase().indexOf(filterText) > -1) {
          options.push(this.propsOptions[i]);
        }
      }
      return options;
    },
    setFilter(filter) {
      this.filter = filter;
    },
    setOptions(options) {
      this.propsOptions = options;
    },
    setFlipDropdown(flip) {
      this.flipDropdown = flip;
    },
    setOpen(open) {
      this.getChartWidth();
      this.open = open;
    },
    getChartWidth() {
      const posDrop = node.current;
      if (posDrop && store?.options?.length > 0) {
        let distFromBottom = posDrop.getBoundingClientRect().bottom;
        let optionsContainHeight = 35 * store.options.length;
        if (optionsContainHeight >= 260) {
          optionsContainHeight = 260;
        }
        distFromBottom = distFromBottom += optionsContainHeight;
        let bottomViewPort = window.innerHeight;
        store.setFlipDropdown(distFromBottom > bottomViewPort);
      }
    },
  }));

  function handleClickOutside(e) {
    if (node.current.contains(e.target)) {
      return;
    }
    store.setOpen(false);
  }

  function handleChange(selectedValue) {
    props.onChange(selectedValue.value, selectedValue);
    store.setOpen(false);
  }

  useEffect(() => {
    if (props.options) {
      store.setOptions(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    if (store.open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [store.open]);

  return (
    <Observer>
      {() => (
        <FieldWrapper
          maxWidth={props.maxWidth}
          margin={props.margin}
          width={props.width}
          required={props.required}
          labelWidth={props.labelWidth}
          flexDirection={props.flexDirection}
          show={store.open}
          height={props.height}
          flipDropdown={store.flipDropdown}
          search={props.search}
          optionsHeight={store.optionsHeight}
          labelFontSize={props.labelFontSize}
        >
          <div className="field">
            <div className="labelContainer">
              {props.label && (
                <label>
                  {props.required && <span className="required">*</span>}
                  {props.label}
                </label>
              )}
            </div>
            <div style={props.disabled ? { backgroundColor: 'lightgray' } : {}} className="styledWrapper dropdown" ref={node}>
              <div id={props.id} tabIndex={props.tabIndex} className="dropdownToggler" onClick={() => store.setOpen(!store.open)}>
                <span className="selected-text">
                  {_get(_find(store.options, { value: props.value }), 'text') || props.placeholder}
                </span>
                {store.open ? (
                  <CaretUpIcon
                    color="black"
                    style={{
                      width: 16,
                      height: 16,
                      position: 'absolute',
                      right: 5,
                    }}
                  />
                ) : (
                  <CaretDownIcon
                    color="black"
                    style={{
                      width: 16,
                      height: 16,
                      position: 'absolute',
                      right: 5,
                    }}
                  />
                )}
              </div>
              {store.open && props.search && (
                <div className="search">
                  <Input
                    placeholder={props.loading ? '...Loading' : 'Filter Options'}
                    search={props.search}
                    value={store.filter}
                    onChange={(e) => store.setFilter(e.target.value)}
                    autoFocus={true}
                  />
                </div>
              )}
              <Observer>
                {() => (
                  <>
                    {store.open && (
                      <ul className="dropdownMenu">
                        {store.options.map((opt, index) => (
                          <Observer key={`${index}${opt.value}`}>
                            {() => (
                              <li
                                className="dropdownMenuItem"
                                onClick={function change(e) {
                                  if (!opt.disable) {
                                    handleChange(opt);
                                  }
                                }}
                                id={opt.text}
                              >
                                <span
                                  onClick={function change() {
                                    if (!opt.disable) {
                                      handleChange(opt);
                                    }
                                  }}
                                >
                                  {opt.text}
                                </span>
                              </li>
                            )}
                          </Observer>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </Observer>
            </div>
          </div>
        </FieldWrapper>
      )}
    </Observer>
  );
});

export default CustomDropdown;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0 0 3px 0'};
  position: relative;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  .error {
    color: red;
    padding-left: 239px;
  }
  .required {
    color: red;
  }
  .labelContainer {
    width: ${({ labelWidth }) => labelWidth};
    // margin: ${({ required }) => (!required ? '0 0 0 4px' : '0 5px 0 0')};
    display: flex;
    label {
      text-align: end;
      width: 100%;
      padding-right: 5px;
      font-size: ${({ labelFontSize }) => labelFontSize || ''};
    }
  }
  .field {
    display: ${({ display }) => display || 'flex'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: center;
    align-items: ${({ flexDirection }) => (flexDirection ? 'start' : 'center')};
    color: ${({ theme }) => theme.colors.text};

    @media (max-width: 490px) {
      flex-direction: column;
      .labelContainer {
        width: 100%;
        label {
          text-align: start;
        }
      }
    }
    .search {
      width: calc(100% + 2px);
      position: absolute;
      z-index: 1;
      left: -1px;
      bottom: ${({ flipDropdown }) => (flipDropdown ? '33px' : '')};
      top: ${({ flipDropdown }) => (flipDropdown ? '' : '33px')};
    }
    .styledWrapper {
      outline: none;
      background: #fff;
      font-size: 14px;
      position: relative;
      //border: ${({ show, theme }) => (show ? theme.focusInputBorder : theme.dropdownBorder)};
      border: ${({ theme }) => theme.dropdownBorder};
      border-radius: ${({ show, flipDropdown }) => {
        if (!show) return '5px';
        if (flipDropdown) {
          return '0 0 5px 5px';
        }
        return '5px 5px 0 0';
      }};
      cursor: pointer;
      width: ${(props) => (props.width ? props.width : '100%')};
      height: ${(props) => (props.height ? props.height : '35px')};
      .dropdownToggler {
        outline: none;
        width: ${({ width }) => width || '100%'};
        align-items: center;
        display: flex;
        overflow: hidden;
        //padding-left: 9px;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: ${(props) => (props.height ? props.height : '35px')};
        font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
        border-radius: ${({ show }) => (show ? '5px 5px 0px 0px' : '5px 5px 5px 5px')};
        color: ${(props) => (props.error ? 'red' : props.theme.primaryColor)};
        border-radius: ${({ show, flipDropdown }) => {
          if (!show) return '5px';
          if (flipDropdown) {
            return '0 0 5px 5px';
          }
          return '5px 5px 0 0';
        }};
        .selected-text {
          padding-left: 9px;
        }
      }

      .dropdownMenu {
        position: absolute;
        z-index: 5;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        left: -1px;
        border-radius: ${({ show, flipDropdown }) => {
          if (!show) return '5px';
          if (flipDropdown) {
            return '5px 5px 0 0';
          }
          return '0 0 5px 5px';
        }};
        border: ${({ theme }) => theme.dropdownBorder};
        color: ${({ error, theme }) => (error ? '#9f3a38' : theme.primaryColor)};
        max-height: ${({ maxHeight }) => maxHeight || '260px'};
        width: 100%;
        background: #fff;
        bottom: ${({ flipDropdown, height, search }) => {
          if (!flipDropdown) return '';
          if (height) {
            return height;
          }
          if (search) {
            return '72px';
          }
          return '34px';
        }};
        top: ${({ flipDropdown, height, search }) => {
          if (flipDropdown) {
            return '';
          }
          if (height) {
            return height;
          }
          if (search) {
            return '70px';
          }
          return '34px';
        }};

        .dropdownMenuItem {
          align-items: center;
          display: flex;
          cursor: pointer;
          list-style-type: none;
          border-top: 1px solid #fafafa;
          height: 35px;
          span {
            padding: 5px 9px;
          }

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
`;
