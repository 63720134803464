import React from 'react';
import styled from 'styled-components/macro';
import {observer } from 'mobx-react-lite';
import {AvsReasons, ChargebackReasons, DeclineReasons, DowngradesReasons} from './index';

const TopEight = observer(() => {
    return (
        <Wrapper>
            <div css={`grid-area: declines`}>
              <DeclineReasons />
            </div>
            <div css={`grid-area: chargebacks`}>
              <ChargebackReasons/>
            </div>
            <div css={`grid-area: avs`}>
                <AvsReasons/>
            </div>
            <div css={`grid-area: downgrades`}>
              <DowngradesReasons/>
            </div>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  display: grid;
  //margin: 0 5px 0 5px;
  height: calc(100% - 35px);
  margin: 4px 0 0 0;
  grid-template-areas:
    'declines chargebacks'
    'avs downgrades';
  grid-template-columns: 1fr 1fr;
  //grid-template-rows: 100% 100%;
  grid-gap: 5px;
  padding-bottom: 20px;
  color: ${({theme}) => theme.colors.primaryText};

  .reportWrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px 5px;
  }

  .spaceBtwn {
    padding: 4px;
    margin: 0px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }

  .row {
    display: flex;
  }
`;

const GridWrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);

    .ag-header-row {
      height: 30px;
    }

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .topLevel {
      background-color: #001329 !important;
    }

    .secondLevel {
      background-color: #032845 !important;
    }
  }

  grid-area: SsTotalsGraph;
  position: relative;
    // width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .noData {
    margin: auto;
  }
`;

export default TopEight;
