import React from 'react';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { ChevronLeftIcon, ChevronsLeftIcon, ChevronRightIcon, ChevronsRightIcon } from 'styles/styledComponents';
import { _map, _ceil, _debounce } from 'utils/utils';
import styled from 'styled-components';
import { Dropdown } from 'components/UI';

const itemPerPage = [
  { key: 1, text: '50 items/page', value: 50 },
  { key: 2, text: '100 items/page', value: 100 },
  { key: 3, text: '150 items/page', value: 150 },
  { key: 4, text: '1,000 items/page', value: 1000 },
  { key: 5, text: '5,000 items/page', value: 5000 },
  { key: 6, text: '10,000 items/page', value: 10000 },
];

const Paginator = observer((props) => {
  // const props = useAsObservableSource(prop);
  const fetchTableData = props.fetchTableData;
  //   props.store.loading = props.props.store.loading,
  //   props.store.paginatorModel = props.props.store.paginatorModel,
  //   props.store.txnCount = props.props.store.txnCount;
  const debounceFetchData = _debounce(fetchTableData, 1000, { leading: false, trailing: true });

  const localStore = useLocalStore(() => ({
    get totalPages() {
      return _ceil(props.store.txnCount / props.store.paginatorModel.txnLimit) || 0;
    },
    get showUptoFivePages() {
      let currentPage = props.store.paginatorModel.pageNumber;
      let totalPages = localStore.totalPages;
      let pages = [];
      if (totalPages > 5) {
        if (currentPage <= 3) {
          return [1, 2, 3, 4, 5];
        }
        if (currentPage >= totalPages - 3) {
          return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        } else {
          return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
        }
      }
      for (let i = 0; i < totalPages; i++) {
        pages.push(i + 1);
      }
      return pages;
    },

    get canGoLeft() {
      return props.store.paginatorModel.pageNumber > 1;
    },
    get canGoRight() {
      return props.store.paginatorModel.pageNumber < localStore.totalPages;
    },
    get displayStartIndex() {
      if (props.store.txnCount === 0) return 0;
      else return (props.store.paginatorModel.pageNumber - 1) * props.store.paginatorModel.txnLimit + 1;
    },
    get displayEndIndex() {
      if (props.store.txnCount === 0) return 0;
      else {
        let maximumPageEnd = localStore.displayStartIndex + props.store.paginatorModel.txnLimit - 1;
        return maximumPageEnd > props.store.txnCount ? props.store.txnCount : maximumPageEnd;
      }
    },
    get realStartIndex() {
      return (props.store.paginatorModel.pageNumber - 1) * props.store.paginatorModel.txnLimit;
    },
  }));

  /*  async function paginatorIncrementTxnLimit() {
    if (props.store.paginatorModel.txnLimit < 200 && !props.store.loading) {
      props.store.paginatorModel.incrementTxnLimit();
      await debounceFetchData();
    }
  }
  async function paginatorDecrementTxnLimit() {
    if (props.store.paginatorModel.txnLimit > 50 && !props.store.loading) {
      props.store.paginatorModel.decrementTxnLimit();
      await debounceFetchData();
    }
  }*/
  async function dropdownChangeTxnLimit(limit) {
    props.store.paginatorModel.setPageNumber(Math.floor(localStore.realStartIndex / limit) + 1);
    props.store.paginatorModel.setTxnLimit(limit);
    await fetchTableData();
  }
  async function paginatorIncrementPageNumber() {
    if (props.store.paginatorModel.pageNumber < localStore.totalPages) {
      props.store.paginatorModel.incrementPageNumber();
      await fetchTableData();
    }
  }
  async function paginatorDecrementPageNumber() {
    if (props.store.paginatorModel.pageNumber > 1) {
      props.store.paginatorModel.decrementPageNumber();
      await fetchTableData();
    }
  }
  async function paginatorSetFirstPage() {
    if (props.store.paginatorModel.pageNumber > 1 && !props.store.loading) {
      props.store.paginatorModel.setPageNumber(1);
      await fetchTableData();
    }
  }
  async function paginatorSetLastPage() {
    if (props.store.paginatorModel.pageNumber < localStore.totalPages + 1 && !props.store.loading) {
      props.store.paginatorModel.setPageNumber(localStore.totalPages);
      await fetchTableData();
    }
  }
  async function paginatorSetPage(page) {
    if (page < localStore.totalPages + 1 && page > 0) {
      props.store.paginatorModel.setPageNumber(page);
      await fetchTableData();
    }
  }

  return (
    <Observer>
      {() => (
        <PaginatorWrapper>
          <div className="paginatorInnerWrapper">
            <PaginatorArrowLeft onClick={paginatorSetFirstPage} endLeft canGoLeft={localStore.canGoLeft}>
              <ChevronsLeftIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowLeft>

            <PaginatorArrowLeft onClick={paginatorDecrementPageNumber} canGoLeft={localStore.canGoLeft}>
              <ChevronLeftIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowLeft>

            {_map(localStore.showUptoFivePages, (page, index) => {
              return (
                <PaginatorPageNumIcon
                  key={index}
                  currentPage={props.store.paginatorModel.pageNumber === page}
                  onClick={() => {
                    paginatorSetPage(page);
                  }}
                >
                  {page}
                </PaginatorPageNumIcon>
              );
            })}

            <PaginatorArrowRight onClick={paginatorIncrementPageNumber} canGoRight={localStore.canGoRight}>
              <ChevronRightIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowRight>

            <PaginatorArrowRight onClick={paginatorSetLastPage} canGoRight={localStore.canGoRight} endRight>
              <ChevronsRightIcon style={{ height: 25, width: 25 }} color={'rgb(91, 91, 91)'} />
            </PaginatorArrowRight>
          </div>

          <div className="paginatorAddDrop">
            <Dropdown
              margin={'0 0 0 0'}
              height={'32px'}
              caretRight={10}
              placeholder="Select Items/Page"
              options={itemPerPage}
              onChange={dropdownChangeTxnLimit}
              value={props.store.paginatorModel.txnLimit}
              // value={_get(_find(orderByOptions, { value: orderBy.column }), 'text', '')}
              border
            />
            {/*<div>Items/page: {props.store.paginatorModel.txnLimit}</div>*/}
            {/*<div className="paginatorAddSubtract">*/}
            {/*  <Dropdown
            margin={0}
            caretRight={10}
            placeholder="Select Items/Page"
            options={itemPerPage}
            onChange={props.store.paginatorModel.setTxnLimit}
            value={props.store.paginatorModel.txnLimit}
            // value={_get(_find(orderByOptions, { value: orderBy.column }), 'text', '')}
            border
          />*/}
            {/*     <AddIcon
            onClick={paginatorIncrementTxnLimit}
            style={{ height: 11, width: 11 }}
            hoverColor="#45E786"
            color={'rgb(91, 91, 91)'}
          />
          <MinusIcon
            onClick={paginatorDecrementTxnLimit}
            style={{ height: 11, width: 11 }}
            hoverColor="#FF2B49"
            color={'rgb(91, 91, 91)'}
          />*/}
            {/*</div>*/}
          </div>

          <PaginatorCurrentPage>
            {localStore.displayStartIndex} - {localStore.displayEndIndex} of {props.store.txnCount} - pgs: {localStore.totalPages}
          </PaginatorCurrentPage>
        </PaginatorWrapper>
      )}
    </Observer>
  );
});

const PaginatorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  .paginatorInnerWrapper {
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    height: 32px;
    margin: 5px;
    max-width: 425px;
  }
  .paginatorAddDrop {
    width: 160px;
  }
`;

const PaginatorCurrentPage = styled.div`
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0 5px 0 5px;
  margin: auto 5px;
  font-size: 14px;
  .paginatorAddSubtract {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 7px;
    &:hover {
      cursor: pointer;
    }
  }
  color: ${(props) => props.theme.baseColors.colorGreyDark};
`;

const PaginatorPageNumIcon = styled.div`
  align-items: center;
  background-color: ${({ currentPage }) => (currentPage === true ? '#3FA9F5' : '#fff')};
  border: ${({ currentPage }) => (currentPage === true ? '1px solid #A8A8A8' : '1px solid #DBDBDB')};
  color: ${({ currentPage }) => (currentPage === true ? '#fff' : 'rgb(91,91,91)')};
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  height: 32px;
  width: 45px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
    color: ${({ theme }) => theme.colors.primaryText};
  }
`;

const PaginatorArrowLeft = styled.div`
  align-items: center;
  background-color: #f0eff0;
  border-bottom-left-radius: ${({ endLeft }) => (endLeft ? '5px' : 0)};
  border: 1px solid #dbdbdb;
  border-top-left-radius: ${({ endLeft }) => (endLeft ? '5px' : 0)};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 45px;
  &:hover {
    cursor: ${({ canGoLeft }) => (canGoLeft ? 'pointer' : 'not-allowed')};
    background: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
  }
`;

const PaginatorArrowRight = styled.div`
  align-items: center;
  border: 1px solid #dbdbdb;
  border-bottom-right-radius: ${({ endRight }) => (endRight ? '5px' : 0)};
  background-color: #f0eff0;
  border-top-right-radius: ${({ endRight }) => (endRight ? '5px' : 0)};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 45px;
  &:hover {
    cursor: ${({ canGoRight }) => (canGoRight ? 'pointer' : 'not-allowed')};
    background: ${({ theme }) => theme.baseColors.colorGreyMediumLight};
  }
`;

export default Paginator;
