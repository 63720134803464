import React from 'react';
import { Login, ResetPassword } from './pages';
import { Route, Switch } from 'react-router-dom';
function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/reset-password/:hash">
        <ResetPassword />
      </Route>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
}
export default UnauthenticatedApp;
