import React from 'react';
import styled from 'styled-components/macro';


const FinancialKyc = () => {
    return (
		<div style={{height: '100%', width: '100%'}}>
			<StyledWrapper>
				<h2>Financial KYC</h2>
				<p>Page Coming Soon</p>
			</StyledWrapper>
		</div>             
    );
};

const StyledWrapper = styled.div`
    display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export default FinancialKyc;
