import React, { Fragment, useEffect } from 'react';
import {
  ClearIcon,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper
} from 'styles/styledComponents';
import { Button, LoadingSpinner } from 'components/UI';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { autorun } from 'mobx';
import styled from 'styled-components/macro';
import { _get, _includes, _toPairs, useGetWidgetData, useInjectStores } from 'utils/utils';
import { widgetService } from 'services';

const PreventableDeclines = observer((props) => {
  const { dataManagementStore, filterStore, utilsStore } = useInjectStores();
  const formatNumber = utilsStore.formatNumber,
    toCurrency = utilsStore.toCurrency,
    getTotalsPercentage = utilsStore.getTotalsPercentage;
  const declineFilter = filterStore.declineFilter;

  const store = useLocalObservable(() => ({
    isLoading: false,
    widgetData: null,
    error: {},
    currentLocalFilter: '',
    setFilter(denialBkt) {
      this.currentLocalFilter = denialBkt;
      props.store.applyNewFilter({
        denialBkt,
        preventable: 'Preventable',
      });
      this.currentFilterData = filterStore.currentFilterData;
    },
    resetFilter() {
      this.currentLocalFilter = '';
      filterStore.resetState(props.store.history, props.store.location);
      dataManagementStore.setNewQuery();
    },
    get resetCurrentLocalFilter() {
      const denialBktOpts = ['SD', 'FIX', 'REDO', 'ERR', 'DNH', 'HD', 'UNK'];
      const common = _toPairs(_get(filterStore, 'appliedCommonFilters', [])).length;
      const secondary = _toPairs(_get(filterStore, 'appliedSecondaryFilters', [])).length;
      const onlyOne = common + secondary === 2;
      const catIsFromCorrectList = _includes(denialBktOpts, declineFilter.denialBkt);
      const preventable = declineFilter.preventable === 'Preventable';
      return !(onlyOne && catIsFromCorrectList && preventable);
    },
    setG8DeclPreventable(g8DeclSummary) {
      const { status, data, error } = g8DeclSummary;
      if (status === 'loading') this.isLoading = true;
      if (error) this.isLoading = false;
      if (data) {
        this.widgetData = data;
        this.isLoading = false;
      }
    },
  }));

  const g8DeclPreventable = useGetWidgetData(
    { name: 'declinePreventable', query: widgetService.declinePreventable },
    'data',
    dataManagementStore?.newQuery
  );

  useEffect(() => {
    store.setG8DeclPreventable(g8DeclPreventable);
  }, [g8DeclPreventable]);

  useEffect(
    () =>
      autorun(() => {
        if (store.resetCurrentLocalFilter) {
          store.currentLocalFilter = '';
        }
        if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== declineFilter.category) {
          store.currentLocalFilter = declineFilter.denialBkt;
        }
      }),
    []
  );

  return (
    <Observer>
      {() => (
        <TableWrapper height="100%">
          <TableHeader>Preventable Declines</TableHeader>
          {store.isLoading && <LoadingSpinner size={'200px'} />}

          <TableContainer width={'auto'} margin="0 10px">
            <Table>
              <TableBody>
                {!store.isLoading && (
                  <Fragment>
                    <TableRow>
                      <HeaderCell></HeaderCell>
                      <HeaderCell>Type</HeaderCell>
                      <HeaderCell>% of Total</HeaderCell>
                      <HeaderCell>Count</HeaderCell>
                      <HeaderCell>Amount</HeaderCell>
                      <HeaderCell>Fees</HeaderCell>
                    </TableRow>
                    {store.widgetData?.values?.length > 0 && (
                      <Fragment>
                        {store.widgetData.values.map((detail, index) => (
                          <TableRow key={`${detail.prevent_denial_bkt}${index}`}>
                            <TableCell padding="4px 0">
                              {store.currentLocalFilter !== detail.prevent_denial_bkt && (
                                <Button
                                  type="primary"
                                  width="25px"
                                  id="filterBtn"
                                  onClick={() => {
                                    store.setFilter(detail.prevent_denial_bkt);
                                  }}
                                >
                                  <FilterIcon id="filterBtn" style={{ width: 20, height: 19 }} />
                                </Button>
                              )}
                              {store.currentLocalFilter === detail.prevent_denial_bkt && (
                                <Button type="delete" width="26px" onClick={store.resetFilter}>
                                  <ClearIcon id="filterBtn" color style={{ width: 20, height: 19 }} />
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>{detail.prevent_denial_bkt || 0}</TableCell>
                            <TableCell>
                              {getTotalsPercentage(detail.prevent_count, store.widgetData.totals.tot_prevent_count)}
                            </TableCell>
                            <TableCell>{formatNumber(detail.prevent_count || 0)}</TableCell>
                            <TableCell>{toCurrency(detail.prevent_amount || 0)}</TableCell>
                            <TableCell>{toCurrency(detail.prevent_fees || 0)}</TableCell>
                          </TableRow>
                        ))}
                      </Fragment>
                    )}

                    <TableRow>
                      <TableCell showTotalsLine>&nbsp;</TableCell>
                      <TableCell showTotalsLine>{}</TableCell>
                      <TableCell showTotalsLine>{}</TableCell>
                      <TableCell
                        showTotalsLine>{formatNumber(_get(store.widgetData, 'totals.tot_prevent_count', 0))}</TableCell>
                      <TableCell
                        showTotalsLine>{toCurrency(_get(store.widgetData, 'totals.tot_prevent_amount', 0))}</TableCell>
                      <TableCell
                        showTotalsLine>{toCurrency(_get(store.widgetData, 'totals.tot_prevent_fees', 0))}</TableCell>
                    </TableRow>
                  </Fragment>
                )}
              </TableBody>
            </Table>
            <br />
          </TableContainer>
          <StyledNote>
            <span>
              <span className="note">* </span>
              <span className="noteBody">
                Preventable txns are flagged when running a card that has previously been denied and "you" continue to process on
                that card.
              </span>
            </span>
          </StyledNote>

          {/* <div
        style={{
          position: 'absolute',
          left: 5,
          bottom: 18,
          color: '#fff',
          fontSize: '11px',
          height: '10px',
          width: '100%',
          justifyContent: 'flex-end',
          textAlign: 'start',
          alignItems: 'center',
        }}
      >
        <span style={{ color: 'red' }}>* Note:</span> Preventable txns are flagged when running a card that has previously been
        denied and "you" continue to process on that card.
      </div>*/}
        </TableWrapper>
      )}
    </Observer>
  );
});

export default PreventableDeclines;

const StyledNote = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 10px;
  padding: 0 10px;
  .note {
    color: red;
  }
  .noteBody {
    font-style: italic;
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;
