import React from 'react';
import {
    Cell,
    PolarAngleAxis,
    RadialBar,
    RadialBarChart,
    BarChart,
    Bar,
    LabelList,
    YAxis,
    XAxis,
    ResponsiveContainer
} from 'recharts';

import {
    ContentContainer,
    LeftSideContainer,
} from './StyledResources/StyledSsProcLimits';
import {_get} from 'utils/utils';
import {Observer} from 'mobx-react-lite';

const widgetConstants = {name: 'ssProcLimits', id: 8};

const initialData = [
    {
        procSname: '',
        midNum: null,
        percentUsed: 0.3,
        procLimit: [{totalAvl: 1000, startDt: '', endDt: '', midMoLimit: 100}]
    },
];

const CustomizedLegendAxisTick = ({x, y}) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text></text>
        </g>
    );
};

const CustomizedXAxisTick = ({x, y, fill, payload, isDemo}) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={8} fontSize={13} dx={0} textAnchor="middle" fill={fill} transform="rotate(0)">
                {isDemo ? '888800000008888' : payload.value}
            </text>
        </g>
    );
};

const getLabelPosition = (percent, y) => {
    if (percent >= 85) {
        return y + 15
    }
    return y - 10;
}

const renderCustomizedLabel = (props) => {
    const {x, y, width, value} = props;
    return (
        <g>
            <text x={x + width / 2} y='2%' fill="rgba(255,255,255, .8" fontSize={11} textAnchor="middle"
                  dominantBaseline="middle">
                {value.date}
            </text>
            <text x={x + width / 2} y='7%' fill="rgba(255,255,255, .8" fontSize={11} textAnchor="middle"
                  dominantBaseline="middle">
                {`${value.percent}% of ${value.total}`}
            </text>
            <text x={x + width / 2} y={getLabelPosition(value.percent, y)} fill="rgba(255,255,255, .8" fontSize={13}
                  textAnchor="middle" dominantBaseline="end">
                {`${value.actual}`}
            </text>
        </g>
    );
};

const renderCustomizedYAxisLabel = (props) => {
    const {x, y, width, value} = props;
    return (
        <g>
            <text transform={`rotate(-90)`} x={-105} y={30} style={{fontSize: '14px'}} fill={'rgba(255,255,255, .8'}
                  textAnchor="middle">
                Percent Used
            </text>
        </g>
    );
};

function getBarColor(percent) {
    if (percent <= 79.9) {
        return 'url(#colorGreen)';
    }
    if (percent >= 80 && percent <= 89.9) {
        return 'url(#colorYellow)';
    }
    if (percent > 89.9) {
        return 'url(#colorRed)';
    }
}

const RadialBarCharts = ({
                             data,
                             gridItem,
                             onMouseEnter,
                             onMouseLeave,
                             isDemo
                         }) => {
    return (
        <Observer>
            {() => (
                <ResponsiveContainer height="75%" width="99%">
                    <BarChart
                        width="99%"
                        data={data}
                        margin={{
                            top: 25,
                            right: 20,
                            left: 20,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor='#45E885' stopOpacity={0.8}/>
                                <stop offset="95%" stopColor='#45E885' stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="15%" stopColor='#fccc1a' stopOpacity={0.8}/>
                                <stop offset="95%" stopColor='#fccc1a' stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor='#FF2B49' stopOpacity={0.8}/>
                                <stop offset="95%" stopColor='#FF2B49' stopOpacity={0}/>
                            </linearGradient>
                        </defs>

                        <text dx="55%" y="100%" style={{fontSize: '14px'}} fill={gridItem.colors.primaryText}
                              textAnchor="middle">
                            MIDS
                        </text>

                        <Bar
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            cornerRadius={20}
                            background={{fill: 'transparent', stroke: 'rgba(255,255,255, .1)'}}
                            clockWise
                            dataKey="percentUsed"
                            barSize={200}
                        >
                            {data.map((entry, index) => {
                                return (
                                    <Cell key={`${entry.percentUsed}${index}`} stroke={getBarColor(entry.percentUsed)}
                                          fill={getBarColor(entry.percentUsed)}/>
                                )
                            })}

                            <LabelList dataKey={'label'} content={renderCustomizedLabel}/>
                        </Bar>

                        <XAxis stroke={'rgba(255,255,255, .8'} fill={'rgba(255,255,255, .8'} dataKey={'mid'} height={45}
                               tick={<CustomizedXAxisTick isDemo={isDemo}/>}/>
                        <YAxis stroke={'rgba(255,255,255, .8)'} label={renderCustomizedYAxisLabel} type={'number'}
                               domain={[0, 100]}/>
                        
                    </BarChart>
                </ResponsiveContainer>
            )}</Observer>
    );
};

const RadialLegendBarChart = ({
                                  barSize,
                                  barColor,
                                  cx,
                                  cy,
                                  mid,
                                  percentUsedText,
                                  data,
                                  gridItem,
                                  innerRadius,
                                  outerRadius,
                                  onMouseEnter,
                                  onMouseLeave,
                                  ticks,
                              }) => {
    return (
        <Observer>
            {() => (
                <ResponsiveContainer height={130} width="100%">
                    <RadialBarChart
                        startAngle={90}
                        endAngle={450}
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        barSize={barSize}
                        margin={{top: 0, left: 0, right: 0, bottom: 0}}
                        padding={{top: 0, left: 0, right: 0, bottom: 0}}
                        data={data}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <RadialBar stroke={barColor} fill={barColor} cornerRadius={20} background clockWise
                                   dataKey={'percentUsed'}/>
                        <PolarAngleAxis type="number" domain={[0, 100]} tickLine={true}
                                        tick={<CustomizedLegendAxisTick/>}
                                        ticks={ticks || []}/>
                        <text dx="50%" y={30} fill={gridItem.colors.primaryText} textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize={13}>
                            {/*{data[0].midData.procSname}*/}
                            {'Eight'}
                        </text>
                        <text dx="50%" y={48} fill={gridItem.colors.primaryText} textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize={12}>
                            {mid}
                        </text>
                        <text dx="50%" y={96} fill={gridItem.colors.primaryText} textAnchor="middle" fontSize={14}>
                            {percentUsedText}
                        </text>
                    </RadialBarChart>
                </ResponsiveContainer>
            )}</Observer>
    );
};


const RadialBarLegend = (props) => {
        return (
            <Observer>
                {() => (
                    <ContentContainer>
                        <LeftSideContainer>
                            {props.procLimits && props.procLimits.length > 0 ? (
                                <RadialBarCharts
                                    width="99%"
                                    height="100%"
                                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
                                    innerRadius={props.store.innerRadius}
                                    data={props.procLimits}
                                    details={props.showProcDetails}
                                    barSize={100}
                                    showAxis={true}
                                    toCurrency={props.store.utilsStore.toCurrency}
                                    onMouseEnter={props.showProcLimitDetails}
                                    onMouseLeave={props.hideProcLimitDetails}
                                    barColor={props.store.radialBarColor}
                                    textColor={_get(props.store.uiStore, 'currentTheme.primaryTickColor')}
                                    gridItem={_get(props.store.uiStore, 'currentTheme.rgl.gridItem')}
                                    isDemo={props.isDemo}
                                />
                            ) : <div css={``}>There is not yet data for this time period</div>}
                        </LeftSideContainer>
                    </ContentContainer>
                )}
            </Observer>
        );
    }
;

export {initialData, RadialLegendBarChart, RadialBarLegend, widgetConstants};
