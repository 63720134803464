import React from 'react';
import { Observer } from 'mobx-react-lite';
import { Table, TableBody, TableCell, TableContainer, TableHeader, TableRow, TableWrapper } from 'styles/styledComponents';
import { useInjectStores } from 'utils/utils';

const TxnData = ({
  widgetData: {
    auth_cd,
    billto_cust_name,
    eight_cust_id,
    merch_cust_id,
    shipto_co_name,
    shipto_cust_name,
    txn_co_name,
    txn_cust_name,
    txn_funding_status,
    txn_inv_desc,
    txn_inv_num,
    txn_po_num,
    txn_status,
    txn_type,
    et_cust_id,
    cust_id,
    et_cust_name
  },
}) => {
  const { utilsStore } = useInjectStores();
  const toCurrency = utilsStore.toCurrency;
  return (
    <Observer>
      {() => (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '800px', boxShadow: '0 0 4px rgba(91,91,91,.5)', width: '33.33%', margin: '0 0 4px 0' }}>
          <TableWrapper height={''} margin={'2px'} padding="0 10px">
            <TableHeader>TRANSACTION DATA</TableHeader>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell textAlign="left">Authorization Code</TableCell>
                    <TableCell>{auth_cd}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn Type</TableCell>
                    <TableCell>{txn_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn Status</TableCell>
                    <TableCell>{txn_status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn Funding Status</TableCell>
                    <TableCell>{txn_funding_status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper>
          <TableWrapper  height={'100%'} margin={'2px'} padding="0 10px">
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell textAlign="left">Eight Customer ID</TableCell>
                    <TableCell>{et_cust_id ? et_cust_id : eight_cust_id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Merchant Customer ID</TableCell>
                    <TableCell>{cust_id ? cust_id : merch_cust_id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn Customer Name</TableCell>
                    <TableCell>{et_cust_name ? et_cust_name : txn_cust_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Bill to Customer Name</TableCell>
                    <TableCell>{billto_cust_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Ship To Customer Name</TableCell>
                    <TableCell>{shipto_cust_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn Company Name</TableCell>
                    <TableCell>{txn_co_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Ship To Company Name</TableCell>
                    <TableCell>{shipto_co_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn PO Number</TableCell>
                    <TableCell>{txn_po_num}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell textAlign="left">Txn Invoice Number</TableCell>
                    <TableCell>{txn_inv_num}</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell textAlign="left">Txn Invoice Description</TableCell>
                    <TableCell>{txn_inv_desc}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper>
        </div>
      )}
    </Observer>
  );
};

export default TxnData;
