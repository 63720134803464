import React, {Fragment, useEffect} from 'react';
import {
    ClearIcon,
    FilterIcon,
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHeader,
    TableRow,
    TableWrapper
} from 'styles/styledComponents';
import {Button, LoadingSpinner} from 'components/UI';
import {observer, Observer, useLocalStore} from 'mobx-react-lite';
import {_get, _includes, _toPairs, useInjectStores} from 'utils/utils';
import {autorun} from 'mobx';

const Returns = observer(function ReturnedSales(props) {
    const {filterStore, utilsStore} = useInjectStores();
    const toCurrency = utilsStore.toCurrency,
        getTotalsPercentage = utilsStore.getTotalsPercentage,
        formatNumber = utilsStore.formatNumber;
    const txnFilter = filterStore.transactionFilter;
    // const totalCnt = _get(props.widgetData, 'totals.tot_ret_cat_cnt', 0);
    // const totalAmt = _get(props.widgetData, 'totals.tot_ret_cat_amt', 0);

    const store = useLocalStore(() => ({}));

    useEffect(
        () =>
            autorun(async () => {
                if (store.resetCurrentLocalFilter) {
                    store.currentLocalFilter = '';
                }
                if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== txnFilter.category) {
                    store.currentLocalFilter = txnFilter.category;
                }
            }),
        []
    );

    return (
        <Observer>
            {() => (
                <TableWrapper height="100%">
                    <TableHeader>RETURNS</TableHeader>
                    {props.isLoading && <LoadingSpinner size={'200px'}/>}
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {!props.isLoading && (
                                    <Fragment>
                                        <TableRow>
                                            <HeaderCell textAlign={'left'}>Type</HeaderCell>
                                            <HeaderCell textAlign={'left'}>Category</HeaderCell>
                                            <HeaderCell>Count</HeaderCell>
                                            <HeaderCell>Count%</HeaderCell>
                                            <HeaderCell>Amount</HeaderCell>
                                            <HeaderCell>Amount%</HeaderCell>
                                        </TableRow>
                                        {props.succRefunds?.length > 0 && (
                                            <Fragment>
                                                {props.succRefunds.map((detail, index) => (
                                                    <TableRow key={detail.recon_typ}>
                                                        <TableCell textAlign={'left'}>{detail.recon_typ}</TableCell>
                                                        <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                        <TableCell>{formatNumber(detail.ref_cat_vol)}</TableCell>
                                                        <TableCell>{getTotalsPercentage(detail.ref_cat_vol, props.store.returnsTotals.cnt)}</TableCell>
                                                        <TableCell>{toCurrency(detail.succ_ref_amts)}</TableCell>
                                                        <TableCell>{getTotalsPercentage(detail.succ_ref_amts, props.store.returnsTotals.amt)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </Fragment>
                                        )}
                                        {props.succChgbks?.length > 0 && (
                                            <Fragment>
                                                {props.succChgbks.map((detail, index) => (
                                                    <TableRow key={detail.chbgk_cat}>
                                                        <TableCell textAlign={'left'}>{detail.recon_cat === 'Refunds' ? detail.recon_typ : ''}</TableCell>
                                                        <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                        <TableCell>{formatNumber(detail.ref_cat_vol || detail.chgbk_cat_vol)}</TableCell>
                                                        <TableCell>{detail.ref_cat_vol ? getTotalsPercentage(detail.ref_cat_vol, props.store.returnsTotals.cnt) :
                                                            getTotalsPercentage(detail.chgbk_cat_vol, props.store.returnsTotals.cnt)
                                                        }</TableCell>
                                                        <TableCell>{toCurrency(detail.succ_ref_amts || detail.succ_chgbk_amts)}</TableCell>
                                                        <TableCell>{detail.succ_ref_amts? getTotalsPercentage(detail.succ_ref_amts, props.store.returnsTotals.amt)
                                                            : getTotalsPercentage(detail.succ_chgbk_amts, props.store.returnsTotals.amt)
                                                        }</TableCell>
                                                    </TableRow>
                                                ))}
                                            </Fragment>
                                        )}
                                        {props.unSuccDeclErr?.length && (
                                            <Fragment>
                                                {props.unSuccDeclErr.map((detail, index) => (
                                                    <Fragment>
                                                        <TableRow key={detail.chbgk_cat}>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat === 'Declines & Errors' ? detail.recon_typ : ''}</TableCell>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                            <TableCell>{formatNumber(detail.decl_cat_vol)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.decl_cat_vol, props.store.returnsTotals.cnt)}</TableCell>
                                                            <TableCell>{toCurrency(detail.decl_err_amts)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.decl_err_amts, props.store.returnsTotals.amt)}</TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                ))}
                                            </Fragment>
                                        )}
                                        {/* {props.unSuccVoidsRev?.length && (
                                            <Fragment>
                                                {props.unSuccVoidsRev.map((detail, index) => (
                                                    <Fragment>
                                                        <TableRow key={detail.et_txn_pres_cat}>
                                                            <TableCell textAlign={'left'}>{detail.et_txn_pres_cat === 'Declines & Errors' ? detail.recon_typ : ''}</TableCell>
                                                            <TableCell textAlign={'left'}>{detail.recon_cat}</TableCell>
                                                            <TableCell>{formatNumber(detail.void_cat_vol)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.void_cat_vol, props.store.returnsTotals.cnt)}</TableCell>
                                                            <TableCell>{toCurrency(detail.void_revsl_amts)}</TableCell>
                                                            <TableCell>{getTotalsPercentage(detail.void_revsl_amts, props.store.returnsTotals.amt)}</TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                ))}
                                            </Fragment>
                                        )} */}


                                        <TableRow>
                                            <TableCell showTotalsLine textAlign={'left'}/>
                                            <TableCell showTotalsLine textAlign={'left'}/>
                                            <TableCell
                                                showTotalsLine>{formatNumber(props.store.returnsTotals.cnt)}</TableCell>
                                            <TableCell showTotalsLine/>
                                            <TableCell
                                                showTotalsLine>{toCurrency(props.store.returnsTotals.amt)}</TableCell>
                                            <TableCell showTotalsLine/>
                                        </TableRow>
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>
            )}
        </Observer>
    );
});

export default Returns;
