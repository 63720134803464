import styled from 'styled-components/macro';

const WidgetWrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  background: ${({ theme }) => theme.rgl.gridItem.colors.background};
  position: ${({ position }) => position || ''};
  transition: all 500ms;
  color: ${({ theme }) => theme.colors.text};
  overflow: ${({ overflow }) => overflow};

  .divider {
    height: 1px;
    margin: 15px 0 20px 0;
  }
`;

const WidgetBackground = styled.div`
  min-width: 100%;
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  //align-items: center;
  //padding: 0 10px;
  box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  background: ${({ theme }) => theme.rgl.gridItem.colors.background};
  position: ${({ position }) => position || ''};
  transition: all 500ms;
  color: ${({ theme }) => theme.colors.text};
`;

const WidgetBody = styled.div`
  display: ${({ display }) => display || 'inline'};
  //flex-direction: column;
  height: ${(props) => (props.height ? props.height : 'calc(100% - 32px)')};
  padding: ${({ padding }) => padding || ''};
`;

const WidgetHeader = styled.div`
  //color: ${({ theme }) => theme.colors.text};
  position: ${({ position }) => position || ''};
  top: ${({ top }) => top || ''};
  display: flex;
  padding: 6px 0 6px 5px;
  width: calc(100% - 5px);
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: center;
  .warn {
    color: ${({ theme }) => theme.baseColors.colorDanger};
  }
`;

const Divider = styled.div`
  border-top: ${(props) => (props.showTotalsLine ? props.theme.colors.totalsDivider : props.theme.colors.divider)};
`;

export { WidgetWrapper, WidgetBackground, WidgetBody, WidgetHeader, Divider };
