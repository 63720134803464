import { destroy, getSnapshot, types } from 'mobx-state-tree';
const { number, optional } = types;

const PaginatorModel = types
  .model({
    txnLimit: optional(number, 50),
    pageNumber: optional(number, 1)
  })
  .volatile(self => ({
    initialState: {}
  }))
  .views(self => ({
    get currentStartingIndex() {
      return (self.pageNumber - 1) * self.txnLimit;
    }
  }))
  .actions(self => ({
    /** LifeCycle **/
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    getClone() {
      return getSnapshot(self);
    },
    remove(item) {
      destroy(item);
    },
    incrementTxnLimit() {
      self.txnLimit += 1;
    },
    decrementTxnLimit() {
      self.txnLimit -= 1;
    },
    setTxnLimit(limit) {
      self.txnLimit = limit;
    },
    incrementPageNumber() {
      self.pageNumber += 1;
    },
    decrementPageNumber() {
      self.pageNumber -= 1;
    },
    setPageNumber(page) {
      self.pageNumber = page;
    },
    resetPaginator() {
      self.pageNumber = 1;
    }
  }));

export default PaginatorModel;
