import React, { Fragment, useEffect, useState } from 'react';
import { _get, _includes, _toPairs, useGetWidgetData, useInjectStores } from 'utils/utils';
import { autorun } from 'mobx';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { Button, LoadingSpinner } from 'components/UI';
import {
  ClearIcon,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { widgetService } from 'services';
import styled from "styled-components/macro";

const Details = observer((props) => {
  const { dataManagementStore, utilsStore, filterStore } = useInjectStores();
  const [dayTotal, setDayTotal] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);

  const formatNumber = utilsStore.formatNumber,
    toCurrency = utilsStore.toCurrency,
    getTotalsPercentage = utilsStore.getTotalsPercentage;
  const declineFilter = filterStore.declineFilter;

  const store = useLocalObservable(() => ({
    isLoading: false,
    widgetData: null,
    error: {},
    currentLocalFilter: '',
    setFilter(denialBkt) {
      store.currentLocalFilter = denialBkt;
      props.store.applyNewFilter({
        denialBkt,
        preventable: '',
      });
      this.currentFilterData = filterStore.currentFilterData;
    },
    resetFilter() {
      this.currentLocalFilter = '';
      filterStore.resetState(props.store.history, props.store.location);
      dataManagementStore.setNewQuery();
    },
    get resetCurrentLocalFilter() {
      const denialBktOpts = ['SD', 'FIX', 'REDO', 'ERR', 'DNH', 'HD', 'UNK'];
      const common = _toPairs(_get(filterStore, 'appliedCommonFilters', [])).length;
      const secondary = _toPairs(_get(filterStore, 'appliedSecondaryFilters', [])).length;
      const onlyOne = common + secondary === 1;
      const catIsFromCorrectList = _includes(denialBktOpts, declineFilter.denialBkt);
      const notPreventable = declineFilter.preventable !== 'preventable';
      return !(onlyOne && catIsFromCorrectList && notPreventable);
    },
    setNewQueryTest(newQueryTest) {
      const { status, data, error } = newQueryTest;
      if (status === 'loading') this.isLoading = true;
      if (data) {
        this.widgetData = data.values;
        let dailyTotal = 0;
        let monthlyTotal = 0;
        for (const key in this.widgetData[0]) {
          if (key.includes("daily")) {
            dailyTotal += this.widgetData[0][key];
          }else {
            monthlyTotal += this.widgetData[0][key];
          }
        }
        setMonthTotal(monthlyTotal);
        setDayTotal(dailyTotal)
        this.isLoading = false;
      }
      if (error) this.isLoading = false;
    },
    formatNumber,
    toCurrency,
    getTotalsPercentage,
  }));

  const newQueryTest = useGetWidgetData(
    { name: 'newQueryTest', query: widgetService.fees},
    'data',
    dataManagementStore?.newQuery
  );

  useEffect(() => {
    store.setNewQueryTest(newQueryTest);
  }, [newQueryTest]);

  return (
    <Observer>
      {() => (
        <TableWrapper height={'100%'}>
          <TableHeader>FEES SUMMARY</TableHeader>
          {store.isLoading && <LoadingSpinner size={'200px'} />}
          <TableContainer width={'auto'} margin="0 10px">
            <Table>
              <TableBody>
                {!store.isLoading && (
                  <>
                    <TableRow style={{borderBottom: '2px solid rgba(255,255,255,.3)'}}>
                      <HeaderCell style={{textAlign: 'left', fontWeight: 'bold' }}>Fee Timing</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Gateway</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Auth & Settlement</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Risk & Reserves</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Chgbk Penalties</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Monthly*</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Equipment</HeaderCell>
                      <HeaderCell style={{textAlign: 'right', fontWeight: 'bold' }}>Totals</HeaderCell>
                    </TableRow>
                    {store.widgetData?.length > 0 && (
                      <>
                        {store.widgetData.map((detail, index) => {
                          return <>
                            <TableRow key={`1`}>
                              <TableCell style={{textAlign: 'left'}}>{'Daily'}</TableCell>
                              <TableCell>{_get(detail, 'gateway_daily', 0) > 0 ? toCurrency(_get(detail, 'gateway_daily', 0)) : 'Unavailable'}</TableCell>
                              <TableCell>{toCurrency(_get(detail, 'auth_sttl_daily'))}</TableCell>
                              <TableCell>{toCurrency(_get(detail,'risk_daily', 0))}</TableCell>
                              <TableCell>{toCurrency(_get(detail,'chgbk_pnlty_daily', 0))}</TableCell>
                              <TableCell>{'N/A'}</TableCell>
                              <TableCell>{toCurrency(_get(detail, 'equipment_daily', 0))}</TableCell>
                              <TableCell>{toCurrency(dayTotal)}</TableCell>
                            </TableRow>
                            <TableRow key={`2`}>
                              <TableCell style={{textAlign: 'left'}}>{'Monthly'}</TableCell>
                              <TableCell>{_get(detail, 'gateway_eom', 0) > 0 ? toCurrency(_get(detail, 'gateway_eom', 0)) : 'Unavailable'}</TableCell>
                              <TableCell>{toCurrency(_get(detail, 'auth_sttl_eom'))}</TableCell>
                              <TableCell>{toCurrency(_get(detail, 'risk_eom', 0))}</TableCell>
                              <TableCell>{toCurrency(_get(detail, 'chgbk_pnlty_eom'))}</TableCell>
                              <TableCell>{toCurrency(detail.mo_stmt_eom)}</TableCell>
                              <TableCell>{toCurrency(_get(detail, 'equipment_eom', 0))}</TableCell>
                              <TableCell>{toCurrency(monthTotal)}</TableCell>
                            </TableRow>
                            <TableRow key={`3`}>
                              <TableCell style={{textAlign: 'left'}} showTotalsLine>{'Category Totals'}</TableCell>
                              <TableCell showTotalsLine>{_get(detail, 'gateway_eom', 0) > 0 || _get(detail, 'gateway_daily', 0) > 0 ? toCurrency(_get(detail, 'gateway_eom', 0) + _get(detail, 'gateway_daily', 0)) : 'Unavailable'}</TableCell>
                              <TableCell showTotalsLine>{toCurrency(_get(detail, 'auth_sttl_eom', 0) + _get(detail, 'auth_sttl_daily', 0))}</TableCell>
                              <TableCell showTotalsLine>{toCurrency(_get(detail, 'risk_daily', 0) + _get(detail, 'risk_eom', 0))}</TableCell>
                              <TableCell showTotalsLine>{toCurrency(_get(detail, 'chgbk_pnlty_eom') + _get(detail,'chgbk_pnlty_daily', 0))}</TableCell>
                              <TableCell showTotalsLine>{toCurrency(detail.mo_stmt_eom)}</TableCell>
                              <TableCell showTotalsLine>{toCurrency(_get(detail, 'equipment_eom', 0) + _get(detail, 'equipment_daily', 0))}</TableCell>
                              <TableCell showTotalsLine>{toCurrency(monthTotal + dayTotal)}</TableCell>
                            </TableRow>
                          </>
                        })}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            <div css={`font-size: 12px; padding: 0 10px; margin-top: 200px`}>
              We report fees once the industry assesses and calculates them. The industry calculates fees in batches and NOT by individual transaction!
            </div>
            <br/>
            <div css={`font-size: 12px; padding: 0 10px;`}>
              {`When attempting to calculate the rate of merchanting fees one should exclude penalties and fixed fees (such as statement fees, PCI compliance fees, and equipment fees). Also, fees should be divided by the gross total of all transactions (sales, refunds, and declines) and not by net deposits; because all transactions have fees.`}
            </div>
            <br/>
            <div css={`font-size: 12px; padding: 0 10px;`}>
              {`Fee ratios will vary on a monthly basis depending on your mix of debit card vs credit card transactions.`}
            </div>
            <br/>

            <StyledNote>
              <span className="note">*</span>
              <span className="noteBody"> See the "Fee Notes" section for important facts and information about fees.</span>
            </StyledNote>
          </TableContainer>
        </TableWrapper>
      )}
    </Observer>
  );
});

export default Details;
const StyledNote = styled.div`
  //display: flex;
  //flex-direction: column;
  height: 100%;
  //justify-content: flex-end;
  align-items: center;
  //font-size: 10px;
  padding: 0 10px;
  padding-bottom: 2rem;
 font-size: 12px;
  .note {
    font-size: 12px;
    color: red;
  }

  .noteBody {
    font-size: 12px;
    //font-style: italic;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;