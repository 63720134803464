import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { RouteLoadingSpinner, Input } from 'components/UI';
import { useCheckAuthorizedRoute, useInjectStores, _get } from 'utils/utils';
import {adminStore} from 'stores/AdminStore';

const AbbreviationPage = observer(() => {
  const {userStore} = useInjectStores();
  const [sortColumn, setSortColumn] = useState(null);
	const [sortDirection, setSortDirection] = useState(null);
  const [abbreviations, setAbbreviations] = useState([]);
  const [selected, setSelected] = useState(null);
  const [unaltered, setUnaltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const currentClientId = _get(userStore, 'user.currentClient.id');

  useEffect(() => {
    const getAbbreviations = async () => {
      const response = await adminStore.getAbbreviations(currentClientId);
      setAbbreviations(response);
      setUnaltered(response);
    };

    getAbbreviations();
  }, []);

  const toggleSort = (columnName) => {
		if (sortColumn === columnName) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(columnName);
			setSortDirection('asc');
		}
	};

  useEffect(() => {
    if(selected) {
      const tmp = [...unaltered];
      const filtered = tmp.filter(abbr => abbr.abbr_name[0].toLowerCase() === selected.toLowerCase());
      setAbbreviations(filtered);
    }else {
      setAbbreviations(unaltered);
    }
  }, [selected]);

  useEffect(() => {
    if(searchTerm) {
      const tmp = [...unaltered];
      const filtered = tmp.filter(abbr => abbr.abbr_name.toLowerCase().includes(searchTerm.toLowerCase()) || abbr.abbr_description.toLowerCase().includes(searchTerm.toLowerCase()));
      setAbbreviations(filtered);
    }else {
      setAbbreviations(unaltered);
    }
  }, [searchTerm]);

  let sortedAbbs = [];
	if(abbreviations) {
		const tmp = [...abbreviations];
		sortedAbbs = tmp.sort((a, b) => {
			if (sortColumn === 'name') {
				const aValue = a.abbr_name.toLowerCase();
				const bValue = b.abbr_name.toLowerCase();
				return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
			} else if (sortColumn === 'description') {
				const aValue = a.abbr_description?.toLowerCase();
				const bValue = b.abbr_description?.toLowerCase();
				return sortDirection === 'asc' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
			} else {
				return 0;
			}
		});
	}

  const { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              <div className="wrapper">
                <div style={{padding: '1rem 3rem', display: 'flex', alignItems: 'center'}}>
                  <Input
                    width="20rem"
                    tabIndex={1}
                    placeholder={'Search...'}
                    value={searchTerm}
                    border
                    onChange={(event) => setSearchTerm(event.target.value)}
                  />
                  <div style={{display: 'flex', paddingLeft: '0.5rem', paddingBottom: '1rem', paddingTop: '1rem'}}>
                    {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter, idx) => {
                      return (
                        <span style={{fontSize: '1.5rem', padding: '0 0.5rem'}} className={selected === letter ? 'selected' : ''} key={idx} onClick={() => setSelected(selected !== letter ? letter : null)}>{letter}</span>
                      );
                    })}
                  </div>
                </div>
                <table style={{paddingLeft: '3rem'}}>
                  <thead>
                      <tr>
                          <th style={{textAlign:'start'}} onClick={() => toggleSort('name')}><span style={{borderBottom: 'solid 1px white', paddingRight: '1rem'}}>Name</span></th>
                          <th style={{textAlign:'start'}} onClick={() => toggleSort('description')}><span style={{borderBottom: 'solid 1px white', paddingRight: '1rem'}}>Description</span></th>
                      </tr>
                  </thead>
                  <tbody style={{paddingLeft: '1rem'}}>
                      {sortedAbbs.map((abbr, idx) => (
                          <tr key={idx} style={{paddingTop: '1rem'}}>
                              <td>{abbr.abbr_name}</td>
                              <td>{abbr.abbr_description}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
              </div>
            </Wrapper>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  .wrapper {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    color:  ${({ theme }) => theme.colors.primaryText};
    justify-content: center;
    flex-direction: column;
    h2 {
      padding-left: 1rem;
    }
    .selected {
      border-bottom: solid 1px white;
      font-weight: bold;
    }
  }
`;

export default AbbreviationPage;
