import React, { Fragment, useEffect } from 'react';
import { _get, _includes, _toPairs, useGetWidgetData, useInjectStores } from 'utils/utils';
import { autorun } from 'mobx';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { Button, LoadingSpinner } from 'components/UI';
import {
  ClearIcon,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableWrapper
} from 'styles/styledComponents';
import { widgetService } from 'services';

const TotalDeclines = observer((props) => {
  const { dataManagementStore, utilsStore, filterStore } = useInjectStores();

  const formatNumber = utilsStore.formatNumber,
    toCurrency = utilsStore.toCurrency,
    getTotalsPercentage = utilsStore.getTotalsPercentage;
  const declineFilter = filterStore.declineFilter;

  const store = useLocalObservable(() => ({
    isLoading: false,
    widgetData: null,
    error: {},
    currentLocalFilter: '',
    setFilter(denialBkt) {
      store.currentLocalFilter = denialBkt;
      props.store.applyNewFilter({
        denialBkt,
        preventable: '',
      });
      this.currentFilterData = filterStore.currentFilterData;
    },
    resetFilter() {
      this.currentLocalFilter = '';
      filterStore.resetState(props.store.history, props.store.location);
      dataManagementStore.setNewQuery();
    },
    get resetCurrentLocalFilter() {
      const denialBktOpts = ['SD', 'FIX', 'REDO', 'ERR', 'DNH', 'HD', 'UNK'];
      const common = _toPairs(_get(filterStore, 'appliedCommonFilters', [])).length;
      const secondary = _toPairs(_get(filterStore, 'appliedSecondaryFilters', [])).length;
      const onlyOne = common + secondary === 1;
      const catIsFromCorrectList = _includes(denialBktOpts, declineFilter.denialBkt);
      const notPreventable = declineFilter.preventable !== 'preventable';
      return !(onlyOne && catIsFromCorrectList && notPreventable);
    },
    setG8DeclSummary(g8DeclSummary) {
      const { status, data, error } = g8DeclSummary;
      if (status === 'loading') this.isLoading = true;
      if (error) this.isLoading = false;
      if (data) {
        this.widgetData = data;
        this.isLoading = false;
      }
    },
    formatNumber,
    toCurrency,
    getTotalsPercentage,
  }));

  useEffect(
    () =>
      autorun(() => {
        if (store.resetCurrentLocalFilter) {
          store.currentLocalFilter = '';
        }
        if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== declineFilter.category) {
          store.currentLocalFilter = declineFilter.denialBkt;
        }
      }),
    []
  );

  const g8DeclSummary = useGetWidgetData(
    { name: 'declineSummary', query: widgetService.declineSummary },
    'data',
    dataManagementStore?.newQuery
  );

  useEffect(() => {
    store.setG8DeclSummary(g8DeclSummary);
  }, [g8DeclSummary]);

  return (
    <Observer>
      {() => (
        <TableWrapper height={'100%'}>
          <TableHeader>Total Declines</TableHeader>
          {store.isLoading && <LoadingSpinner size={'200px'} />}
          <TableContainer width={'auto'} margin="0 10px">
            <Table>
              <TableBody>
                {!store.isLoading && (
                  <Fragment>
                    <TableRow>
                      <HeaderCell></HeaderCell>
                      <HeaderCell></HeaderCell>
                      <HeaderCell>Type</HeaderCell>
                      <HeaderCell>% of Total</HeaderCell>
                      <HeaderCell>Count</HeaderCell>
                      <HeaderCell>Amount</HeaderCell>
                      <HeaderCell>Fees</HeaderCell>
                    </TableRow>
                    {store.widgetData?.values?.length > 0 && (
                      <Fragment>
                        {store.widgetData.values.map((detail, index) => (
                          <TableRow key={`${detail.cat}${index}`}>
                            <TableCell padding="4px 0">
                              {store.currentLocalFilter !== detail.denial_bkt && (
                                <Button
                                  type="primary"
                                  width="25px"
                                  id="filterBtn"
                                  onClick={() => store.setFilter(detail.denial_bkt)}
                                >
                                  <FilterIcon id="filterBtn" style={{ width: 20, height: 19 }}/>
                                </Button>
                              )}
                              {store.currentLocalFilter === detail.denial_bkt && (
                                <Button type="delete" width="26px" onClick={store.resetFilter}>
                                  <ClearIcon id="filterBtn" style={{ width: 20, height: 19 }}/>
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>{detail.cat}</TableCell>
                            <TableCell>{detail.denial_bkt}</TableCell>
                            {detail.count > 0 && store.widgetData.totals.tot_count > 0 ? (
                              <TableCell>{getTotalsPercentage(detail.count, store.widgetData.totals.tot_count)}</TableCell>
                            ) : (
                              <TableCell>0</TableCell>
                            )}
                            <TableCell>{formatNumber(detail.count)}</TableCell>
                            <TableCell>{toCurrency(detail.amount || 0)}</TableCell>
                            <TableCell>{toCurrency(detail.fees || 0)}</TableCell>
                          </TableRow>
                        ))}
                      </Fragment>
                    )}
                    <TableRow>
                      <TableCell showTotalsLine>&nbsp;</TableCell>
                      <TableCell showTotalsLine>{}</TableCell>
                      <TableCell showTotalsLine>{}</TableCell>
                      <TableCell showTotalsLine>{}</TableCell>
                      <TableCell
                        showTotalsLine>{formatNumber(_get(store.widgetData, 'totals.tot_count', 0))}</TableCell>
                      <TableCell showTotalsLine>{toCurrency(_get(store.widgetData, 'totals.tot_amount', 0))}</TableCell>
                      <TableCell showTotalsLine>{toCurrency(_get(store.widgetData, 'totals.tot_fees', 0))}</TableCell>
                    </TableRow>
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      )}
    </Observer>
  );
});

export default TotalDeclines;
