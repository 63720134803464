import React from 'react';
import { CreditCardContainer, CreditCardIcon, WidgetWrapper } from 'styles/styledComponents';
import styled from 'styled-components/macro';
import { Observer } from 'mobx-react-lite';
import { LoadingSpinner } from 'components/UI';

const ReturnedSalesTotal = (props) => {
  return (
    <WidgetWrapper>
      <Observer>
        {() => (
          <>
            {props.store.loading ? (
              <LoadingSpinner size={'80px'}/>
            ) : props.store.error ? (
              <div>Error: {props.store.error.message}</div>
            ) : (
              <TotalWrapper>
                <CreditCardContainer>
                  <CreditCardIcon color="white" style={{ width: 25, height: 25 }}/>
                </CreditCardContainer>
                <div className={'singleValueContent'}>
                  <div>{props.store.toCurrency(props.store.widgetData?.returns_tot)}</div>
                  <div className="title">Refunds, Voids & Chargebacks</div>
                </div>
              </TotalWrapper>
            )}
          </>
        )}
      </Observer>
    </WidgetWrapper>
  );
};

const TotalWrapper = styled.div`
  box-shadow: 0 0 4px rgba(91, 91, 91, 0.5);
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 50ms;

  .singleValueContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }
  .title {
    margin-top: 5px;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default ReturnedSalesTotal;
