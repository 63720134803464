import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { LoadingSpinner, Modal } from 'components/UI';
import { TableHeader } from 'styles/styledComponents';
import ActualForecastData from './ActualForecastData/ActualForecastData';
import { useLocalStore, Observer } from 'mobx-react-lite';
import { StoreContext } from 'stores/RootStore';
import { _get } from 'utils/utils';

const RptReconForecast = (props) => {
  const rootStore = React.useContext(StoreContext);

  const actualDataStore = useLocalStore(() => ({
    loading: props.store.loading,
    widgetData: props.store.widgetData.data.actualData,
    get toCurrency() {
      return rootStore.utilsStore.toCurrency;
    },
    get getTotalsPercentage() {
      return rootStore.utilsStore.getTotalsPercentage;
    },
    get formatNumber() {
      return rootStore.utilsStore.formatNumber;
    },
    get actualData() {
      return _get(props.widgetData, 'data.actualData');
    },
    get newQuery() {
      return _get(rootStore, 'dataManagementStore.newQuery');
    },
  }));
  const forecastDataStore = useLocalStore(() => ({
    loading: props.store.loading,
    widgetData: props.store.widgetData.data.forecastData,
    get toCurrency() {
      return rootStore.utilsStore.toCurrency;
    },
    get getTotalsPercentage() {
      return rootStore.utilsStore.getTotalsPercentage;
    },
    get formatNumber() {
      return rootStore.utilsStore.formatNumber;
    },
    get forecastData() {
      return _get(props.widgetData, 'data.forecastData');
    },
    get newQuery() {
      return _get(rootStore, 'dataManagementStore.newQuery');
    },
  }));

  return (
    <Observer>
      {() => (
        <Fragment>
          <Modal height={props.height} toggleModal={props.toggleReconForecastModal} isOpen={props.showReconForecastModal}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: '1 1 auto',
                maxHeight: '94vh',
                overflow: 'scroll',
                width: '90vw',
              }}
            >
              {props.store.loading && (
                <div
                  style={{
                    height: '94vh',
                    width: '90vw',
                  }}
                >
                  <LoadingSpinner size={'200px'} />
                </div>
              )}
              {!props.store.loading && (
                <Wrapper>
                  <div className="recon">
                    <div className="columns">
                      <TableHeader padding={'5px 0'} margin={'3px 0'}>
                        Actual
                      </TableHeader>
                      <ActualForecastData store={actualDataStore} />
                    </div>
                    <div className="columns">
                      <TableHeader padding={'5px 0'} margin={'3px 0'}>
                        Estimated Forecasted
                      </TableHeader>
                      <ActualForecastData store={forecastDataStore} />
                    </div>
                  </div>
                </Wrapper>
              )}
            </div>
          </Modal>
          )}
        </Fragment>
      )}
    </Observer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  .recon {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .columns {
      margin: 0 3px;
      //width: auto;
      flex-grow: 1;
      min-width: 450px;
    }
  }
`;

export default RptReconForecast;
