import React, { useEffect } from 'react';
import SsResTotals from '../../components/Widgets/SsResTotals/SsResTotals';
import ResTxnTable from '../../components/Widgets/SsResTxns/SsResTxns';
import styled from 'styled-components/macro';
import { useInjectStores } from 'utils/utils';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const Reserves = observer(() => {
  // const location = useLocation();

  const { filterStore, dataManagementStore } = useInjectStores();
  // const setCurrentFilter = filterStore.setCurrentFilter;
  // const path = location.pathname;

  useEffect(() => {
    // setCurrentFilter(path);
    dataManagementStore.setNewQuery();
  }, []);
  return (
    <Wrapper>
      <SsResTotals />
      <div className="tableWrapper">
        <ResTxnTable />
      </div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  .tableWrapper {
    padding: 5px 5px 0 5px;
    height: 100%;
  }
`;
export default Reserves;
