import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { observer, useLocalStore, Observer } from 'mobx-react-lite';
import { RouteLoadingSpinner, Checkbox, Button, LoadingSpinner } from 'components/UI';
import { CheckIcon } from 'styles/styledComponents';
import { useCheckAuthorizedRoute, _get } from 'utils/utils';
import Modal from '../admin/ManageUsersPage/UserManager/Modal';
import {useInjectStores, formatPackageData} from 'utils/utils';
import {adminStore} from 'stores/AdminStore';


const CurrentPlan = () => {
  return (
    <h2 style={{backgroundColor: 'white', color: '#3fa9f5', textAlign: 'center', marginBottom: '-3rem'}}>Current Plan</h2>
  );
};


const PricingPage = observer(() => {
  const [packages, setPackageData] = useState([]);
  const [reportData, setReportData] = useState({});
  const {userStore, uiStore} = useInjectStores();
  const clientId = _get(userStore.user, 'currentClient.id');
  const userPackages = _get(userStore.user, 'pricing');
  const canUpgrade = _get(userStore.user, 'pricing.values[0].user_upg_permsn') === "Checked";
  const canAddReports = _get(userStore.user, 'pricing.values[0].user_add_reps') === "Checked";
  const [isModalOpen, setModalOpen] = useState(false);
  const [pricingModel, setPricingModel] = useState('');
  const [hasButton, setHasButton] = useState({Basic: false, Standard: false, Advanced: false, Professional: false});
  const keys = ['Basic', 'Standard', 'Advanced', 'Professional'];
  const [isConfirmPackageOpen, setIsConfirmPackageOpen] = useState(false);
  const [planToUpgrade, setPlanToUpgrade] = useState(null);
  const packagePrices = {'Basic': 9.95, 'Standard': 24.95, 'Advanced': 64.95, 'Professional': 79.95};
  const reportTiers = {'Basic': 1, 'Standard': 3, 'Advanced': 4, 'Professional': 5};
  const [packageLoading, setPackageLoading] = useState(false);
  const notify = uiStore.notify;
  const [selectedReports, setSelectedReports] = useState([]);
  const [reportsRemaining, setReportsRemaining] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);
  const [upgrades, setUpgrades] = useState([]);
  const [isConfirmUpgradeOpen, setIsConfirmUpgradeOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    const price = event.target.value.split('-')[0];
    const name = event.target.value.split('-')[1];
    const upgradeObj = {price, name}
    if (selectedOption === event.target.value) {
      setSelectedOption('');
      setUpgrades(upgrades.filter(upgrade => upgrade.name !== name));
    } else {
      setSelectedOption(event.target.value);
      setUpgrades([...upgrades, upgradeObj]);
    }
  };

  const handleUpgradeChange = (upgrade) => {
    if(upgrade.price === '19.95/mo & 0.01/txn') {
      upgrade.price = '19.95';
    }
    if(upgrades.some(up => up.name === upgrade.name)) {
      setUpgrades(upgrades.filter(up => up.name !== upgrade.name));
    }else {
      setUpgrades([...upgrades, {name: upgrade.name, price: upgrade.price}]);
    }
  };

  const updateButton = (key) => {
    const index = keys.indexOf(key);
    let newStatus = {};
    keys.forEach((k, i) => {
      newStatus[k] = i > index;
    });
    setHasButton(newStatus);
  };

  const getPricingData = async () =>  {
    const data = await userStore.fetchPricingData();
    setReportData(data);
    setSelectedReports(data.current_reports.map(rep => rep.rep_full_nm));
    setPackageData(formatPackageData(data.values));
  };

  const getRemainingReports = (data) => {
    const additional1 = data.values[0].ala_crt_reps_1_tf;
    const additional3 = data.values[0].ala_crt_reps_3_tf;
    const additional5 = data.values[0].ala_crt_reps_5_tf;
    const additionalAll = data.values[0].ala_crt_reps_all_tf;
    let key = data?.values[0]?.mid_prc_package?.charAt(0)?.toUpperCase() + data?.values[0]?.mid_prc_package?.slice(1)?.toLowerCase();
    let count = key ? reportTiers[key] : undefined;
    if(additional1) {
      count += 1;
    }
    if(additional3) {
      count += 3;
    }
    if(additional5) {
      count += 5;
    }
    if(additionalAll) {
      count = data.reports.length;
    }
    const available = count - selectedReports.length;
    setReportsRemaining(available >= 0 ? available : 0);
  };

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleCloseModal = () => {
    setSelectedReports([...reportData.current_reports.map(rep => rep.rep_full_nm)]);
		setModalOpen(false);
	};

  const handleCloseConfirmUpgrade = () => {
		setIsConfirmUpgradeOpen(false);
	};

  const confirmUpgrade = (selectedPlan) => {
    setPlanToUpgrade(selectedPlan);
    setIsConfirmPackageOpen(true);
  };

  const handleCloseConfirmPackage = () => {
    setPlanToUpgrade(null);
    setIsConfirmPackageOpen(false);
  };

  const submitPackageUpgrade = async () => {
    setPackageLoading(true);
    await adminStore.updatePricing({newPackage: planToUpgrade, clientId: clientId, newUpgrades: [], reports: selectedReports});
    handleCloseConfirmPackage();
    setPackageLoading(false);
    notify({
      duration: 1500,
      group: 'topRight',
      text: `Successfully Upgraded Package`,
    });
  };

  const handleReportChange = (report) => {
    if(reportData.current_reports.some(rep => rep.rep_full_nm === report.rep_full_nm)){
      return;
    }
    if(selectedReports.includes(report.rep_full_nm)) {
      setSelectedReports(selectedReports.filter(rep => rep !== report.rep_full_nm));
    }else {
      if(reportsRemaining > 0) {
        setSelectedReports([...selectedReports, report.rep_full_nm]);
      }
    }
  };

  const upgradeReports = async () => {
    setModalOpen(true);
    await adminStore.updatePricing({newPackage: pricingModel, clientId: clientId, newUpgrades: [], reports: selectedReports});
    handleCloseConfirmPackage();
    setModalOpen(false);
    notify({
      duration: 1500,
      group: 'topRight',
      text: `Successfully Added Reports`
    });
  };

  const submitUpgrades = async () => {
    await adminStore.updatePricing({newPackage: pricingModel, clientId: clientId, newUpgrades: upgrades, reports: selectedReports});
    setIsConfirmUpgradeOpen(false);
    notify({
      duration: 1500,
      group: 'topRight',
      text: `Successfully Added Upgrades`
    });
  };

  useEffect(() => {
    setPricingModel(userStore.user.pricingModel);
    updateButton(userStore.user.pricingModel);

  },[userStore.user.pricingModel]);

  useEffect(() => {
    if(!userPackages) {
      getPricingData();
    }else {
      setReportData(userPackages);
      setSelectedReports(userPackages.current_reports.map(rep => rep.rep_full_nm));
      setPackageData(formatPackageData(userPackages.values));
    }
  }, []);
  
  useEffect(() => {
    if(packages.length) {
      if(Object.values(userPackages).length) {
        getRemainingReports(userPackages);
      }else {
        getRemainingReports(reportData);
      }
    }
  }, [packages, selectedReports]);

  let { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => (
        <>
          {loading || !packages.length ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              <div className="wrapper">
                <div className="header">
                  <h2>Package Levels and Upgrades</h2>
                </div>
                {reportsRemaining && canAddReports ? (
                  <div style={{color: 'white', width: '100%'}}>
                    <h4 style={{width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem'}}>*You have additional Reports to select<span>
                      <Button
                        style={{padding: '1rem'}}
                        type="primary"
                        className="reports-btn"
                        onClick={handleOpenModal}
                      >
                      Select Reports
                    </Button></span>
                    </h4>
                    
                  </div>
                  ) : null}
                <div className="table-wrapper">
                  <div className="feature-column">
                    <div className="feature-header"></div>
                    {packages.map((pack, index) => {
                      if(pack.type === 'header'){
                        return <h3 key={'feature_' + index}>{pack.name}</h3>
                      }else {
                        return <div key={'feature_' + index} className="table-data"><p>{pack.name}</p></div>
                      }
                    })}
                  </div>
                  <div className={`plan-column ${pricingModel === 'Basic' ? 'current-plan' : ''}`}>
                    {pricingModel === 'Basic' ? <CurrentPlan /> : null}
                    <div className="plan-header">
                      <h3>8 Basic</h3>
                      <h3>{`$${packagePrices['Basic']}`}</h3>
                      {hasButton.Basic && canUpgrade ? 
                        <Button
                          type="primary"
                          className="package-btn"
                          onClick={() => confirmUpgrade('Basic')}
                        >
                          Upgrade
                        </Button> : null }
                    </div>
                    {packages.map((pack, index) => {
                      if(pack.type === 'header'){
                        return <div key={'basic_' + index} className="header-gap"></div>
                      } else {
                        if(pack.basic === 'TRUE'){
                          return <div key={'basic_' + index} className="table-data">
                            <CheckIcon color={`${pricingModel === 'Basic' ? 'white' : 'limegreen'}`} style={{ width: 20, height: 20 }}/></div>
                        }else if (pack.basic === 'CARTE'){
                          return <div key={'basic_' + index} className="table-data">*</div>
                        }else if(pack.basic === 'FALSE'){
                          return <div key={'basic_' + index} className="table-data"></div>
                        }else {
                          return <div key={'basic_' + index} className="table-data">{pack.basic}</div>
                        }
                      }
                    })}
                  </div>
                  <div className={`plan-column ${pricingModel === 'Standard' ? 'current-plan' : ''}`}>
                    {pricingModel === 'Standard' ? <CurrentPlan /> : null}
                    <div className="plan-header">
                      <h3>8 Standard</h3>
                      <h3>{`$${packagePrices['Standard']}`}</h3>
                      {hasButton.Standard && canUpgrade ? 
                        <Button
                          type="primary"
                          className="package-btn"
                          onClick={() => confirmUpgrade('Standard')}
                        >
                          Upgrade
                        </Button> : null }
                    </div>
                    {packages.map((pack, index) => {
                      if(pack.type === 'header'){
                        return <div key={'standard_' + index} className="header-gap"></div>
                      } else {
                        if(pack.standard === 'TRUE'){
                          return <div key={'standard_' + index} className="table-data">
                            <CheckIcon color={`${pricingModel === 'Standard' ? 'white' : 'limegreen'}`} style={{ width: 20, height: 20 }}/></div>
                        }else if (pack.standard === 'CARTE'){
                          return <div key={'standard_' + index} className="table-data">*</div>
                        }else if(pack.standard === 'FALSE'){
                          return <div key={'standard_' + index} className="table-data"></div>
                        }else {
                          return <div key={'standard_' + index} className="table-data">{pack.standard}</div>
                        }
                      }
                    })}
                  </div>
                  <div className={`plan-column ${pricingModel === 'Advanced' ? 'current-plan' : ''}`}>
                    {pricingModel === 'Advanced' ? <CurrentPlan /> : null}
                    <div className="plan-header">
                      <h3>8 Advanced</h3>
                      <h3>{`$${packagePrices['Advanced']}`}</h3>
                      {hasButton.Advanced && canUpgrade? 
                        <Button
                          type="primary"
                          className="package-btn"
                          onClick={() => confirmUpgrade('Advanced')}
                        >
                          Upgrade
                        </Button> : null }
                      
                    </div>
                    {packages.map((pack, index) => {
                      if(pack.type === 'header'){
                        return <div key={'advanced_' + index} className="header-gap"></div>
                      } else {
                        if(pack.advanced === 'TRUE'){
                          return <div key={'advanced_' + index} className="table-data">
                            <CheckIcon color={`${pricingModel === 'Advanced' ? 'white' : 'limegreen'}`} style={{ width: 20, height: 20 }}/></div>
                        }else if (pack.advanced === 'CARTE'){
                          return <div key={'advanced_' + index} className="table-data">*</div>
                        }else if(pack.advanced === 'FALSE'){
                          return <div key={'advanced_' + index} className="table-data"></div>
                        }else {
                          return <div key={'advanced_' + index} className="table-data">{pack.advanced}</div>
                        }
                      }
                    })}
                  </div>
                  <div className={`plan-column ${pricingModel === 'Professional' ? 'current-plan' : ''}`}>
                    {pricingModel === 'Professional' ? <CurrentPlan /> : null}
                    <div className="plan-header">
                      <h3>8 Professional</h3>
                      <h3>{`$${packagePrices['Professional']}`}</h3>
                      {hasButton.Professional && canUpgrade ? 
                        <Button
                          type="primary"
                          className="package-btn"
                          onClick={() => confirmUpgrade('Professional')}
                        >
                          Upgrade
                        </Button> : null }
                    </div>
                    {packages.map((pack, index) => {
                      if(pack.type === 'header'){
                        return <div key={'prof_' + index} className="header-gap"></div>
                      } else {
                        if(pack.professional === 'TRUE'){
                          return <div key={'prof_' + index} className="table-data">
                            <CheckIcon color={`${pricingModel === 'Professional' ? 'white' : 'limegreen'}`} style={{ width: 20, height: 20 }}/></div>
                        }else if (pack.professional === 'CARTE'){
                          return <div key={'prof_' + index} className="table-data">*</div>
                        }else if(pack.professional === 'FALSE'){
                          return <div key={'prof_' + index} className="table-data"></div>
                        }else {
                          return <div key={'prof_' + index} className="table-data">{pack.professional}</div>
                        }
                      }
                    })}
                  </div>
                  <div className="carte-column">
                    <div className="plan-header">
                      <h3>A La Carte</h3>
                      {upgrades.length && canUpgrade ? (<><h3>{`Total $${upgrades.reduce((sum, item) => sum + +item.price, 0).toFixed(2)}`}</h3>
                      <Button
                        type="primary"
                        className="package-btn"
                        onClick={() => setIsConfirmUpgradeOpen(true)}
                      >
                        Add Upgrades
                      </Button></>) : null}
                    </div>
                    {packages.map((pack, index) => {
                      if(pack.type === 'header'){
                        return <div key={'carte_' + index} className="header-gap"></div>
                      } else {
                        if(Object.values(pack).includes('CARTE') && pack.price && pack[pricingModel?.toLowerCase()] !== "TRUE" && !pack.purchased){
                          return <div key={'carte_' + index} className="table-data">
                            <input type="checkbox" onChange={() => handleUpgradeChange(pack)}/>
                            <p>${pack.price}</p>
                          </div>
                        }else if(pack.name === 'Additional Reports'){
                          return <div key={'carte_' + index} className="table-data">
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <label style={{fontSize: '12px', display: 'flex', alignItems: 'center'}} >
                                <input
                                  readOnly
                                  type="radio"
                                  value="2.95-ala_crt_prc_reps_1"
                                  checked={selectedOption === '2.95-ala_crt_prc_reps_1'}
                                  onClick={handleChange}
                                />
                                $2.95 - 1 Additional Report
                              </label>

                              <label style={{fontSize: '12px', display: 'flex', alignItems: 'center'}} >
                                <input
                                  readOnly
                                  type="radio"
                                  value="6.95-ala_crt_prc_reps_3"
                                  checked={selectedOption === '6.95-ala_crt_prc_reps_3'}
                                  onClick={handleChange}
                                />
                                $6.95 - 3 Additional Reports
                              </label>

                              <label style={{fontSize: '12px', display: 'flex', alignItems: 'center'}} >
                                <input
                                  readOnly
                                  type="radio"
                                  value="10.95-ala_crt_prc_reps_5"
                                  checked={selectedOption === '10.95-ala_crt_prc_reps_5'}
                                  onClick={handleChange}
                                />
                                $10.95 - 5 Additional Reports
                              </label>

                              <label style={{fontSize: '12px', display: 'flex', alignItems: 'center'}} >
                                <input
                                  readOnly
                                  type="radio"
                                  value="14.95-ala_crt_prc_reps_all"
                                  checked={selectedOption === '14.95-ala_crt_prc_reps_all'}
                                  onClick={handleChange}
                                />
                                $14.95 - All Reports
                              </label>
                            </div>
                          </div>
                        }
                        else {
                          return <div key={'carte_' + index} className="table-data"></div>
                        }
                      }
                    })}
                  </div>
                  
                </div>
              </div>

              <Modal height="800px" width="800px" isOpen={isModalOpen} onClose={handleCloseModal}>
                {reportLoading ? (<LoadingSpinner size={'400px'} />) : (
                <div>
                  <div>
                    <h2 style={{color: 'white', width: '100%'}}>Extra Reports</h2>
                    <h3 style={{color: 'white'}}>{reportsRemaining > 0 ? `${reportsRemaining} Reports remaining on current plan` : 'You have selected all the reports for your current plan'}</h3>
                    {reportData.reports.map((report, index) => {
                      return (
                        <Checkbox
                          width={'100%'}
                          key={index}
                          checked={selectedReports.some((rep) => rep === report.rep_full_nm)}
                          onChange={() => handleReportChange(report)}
                          label={report.rep_full_nm}
                          labelRight={true}
                          labelWidth={'500px'}
                        />
                      );
                    })}
                    <div style={{display: 'flex', width: '100%', justifyContent: 'end', gap: '1rem'}}>
                      <Button type="button" style={{width: '25%', padding: '0.5rem', color: 'black', backgroundColor: 'lightgrey'}} onClick={() => handleCloseModal()}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{width: '25%', backgroundColor: '#3fa9f5', color: 'white', padding: '0.5rem'}} onClick={() => upgradeReports()}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
                )}
              </Modal>
              <Modal isOpen={isConfirmPackageOpen} onClose={handleCloseConfirmPackage}>
                <div style={{backgroundColor: 'white', height :'90%', width: '100%'}}>
                  {packageLoading ? (<LoadingSpinner size={'400px'} />) : (
                  <div style={{padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <h2 style={{color: 'black', textAlign: 'center'}}>Confirm Package Upgrade</h2>
                    <p style={{color: 'black', width: '100%'}}>You are confirming to upgrade your current package</p>
                    <h3 style={{color: 'black', textAlign: 'center'}}>{`${pricingModel} -> ${planToUpgrade}`}</h3>
                    <p style={{color: 'black'}}>{`Doing so will increase your monthly charge by $${(packagePrices[planToUpgrade] - packagePrices[pricingModel]).toFixed(2)}`}</p>
                    <p style={{color: 'black', textAlign: 'center'}}>Are you sure you want to confirm?</p>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'end', gap: '1rem', paddingTop: '2rem'}}>
                      <Button type="button" style={{width: '25%', padding: '1rem', color: 'black', backgroundColor: 'lightgrey'}} onClick={() => handleCloseConfirmPackage()}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{width: '25%', backgroundColor: '#3fa9f5', color: 'white', padding: '1rem'}} onClick={() => submitPackageUpgrade()}>
                        Submit
                      </Button>
                    </div>
                  </div> )}
                </div>
              </Modal>
              <Modal isOpen={isConfirmUpgradeOpen} onClose={handleCloseConfirmUpgrade}>
                <div style={{backgroundColor: 'white', height :'90%', width: '100%'}}>
                  {packageLoading ? (<LoadingSpinner size={'400px'} />) : (
                  <div style={{padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <h2 style={{color: 'black', textAlign: 'center'}}>Confirm Upgrades?</h2>
                    <p style={{color: 'black', width: '100%'}}>I agree to the upgrade charges being added to my monthly subscription payment</p>
                    <h3 style={{color: 'black', textAlign: 'end'}}>{`Total Amount: $${upgrades.reduce((sum, item) => sum + +item.price, 0).toFixed(2)}`}</h3>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'end', gap: '1rem', paddingTop: '2rem'}}>
                      <Button type="button" style={{width: '25%', padding: '1rem', color: 'black', backgroundColor: 'lightgrey'}} onClick={() => handleCloseConfirmUpgrade()}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{width: '25%', backgroundColor: '#3fa9f5', color: 'white', padding: '1rem'}} onClick={() => submitUpgrades()}>
                        Confirm
                      </Button>
                    </div>
                  </div> )}
                </div>
              </Modal>
            </Wrapper>
          ) : (
            <div>Unauthorized</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  .wrapper {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    flex-direction: column;
    .header {
      display: flex;
      justify-content: center;
      h2 {
        margin-bottom: 2rem;
        width: 25%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primaryText};
        border-bottom: ${({ theme }) => theme.baseColors.blueFocusBorder} solid 2px;
      }
    }
    .table-wrapper {
      padding: 1rem;
      display: flex;
      color: ${({ theme }) => theme.colors.primaryText};
      .feature-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #4183C4;
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;
          min-height: 30px;
          padding: 1.5rem 1rem;
          text-align: center;
        }
        .feature-header {
          min-height: 200px;
          text-align: center;
        }
        .table-data {
          display: flex;
          justify-content: start;
          align-items: center;
          min-height: 4.5rem;
          padding-left: 1rem;
          border-bottom: 1px solid #4183C4;
        }
      }
      .carte-column {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        .plan-header {
          min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h3 {
            text-align: center;
          }
          .package-btn {
            width: 80%;
            padding: 1rem;
            font-weight: bold;
          }
        }
        .table-data {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 4.5rem;
          border-bottom: 1px solid #4183C4;
          p {
            margin: 0;
            text-align: center;
          }
        }
        .reports-btn {
          width: 75%;
          padding: 0.5rem 0;
        }
        .header-gap {
          min-height: 30px;
          padding: 1.5rem 1rem;
        }
      }
      .plan-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #4183C4;
        .plan-header {
          min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h3 {
            text-align: center;
          }
          .package-btn {
            width: 80%;
            padding: 1rem;
            font-weight: bold;
          }
        }
        .table-data {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 4.5rem;
          padding-left: 1rem;
          border-bottom: 1px solid #4183C4;
        }
        .header-gap {
          min-height: 30px;
          padding: 1.5rem 1rem;
        }
      }
      .table-data:last-child {
        border: none;
      }
      .current-plan {
        background-color: #3fa9f5;
        border-radius: 5px;
      }
      .submit-btn {
        background-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
        color: white;
        padding: 1rem 0rem;
        width: 25%
      }
    }
  }
`;

export default PricingPage;
