import React, {Fragment, useEffect} from 'react';
import {
    ClearIcon,
    Divider,
    FilterIcon,
    HeaderCell,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    TableWrapper,
} from 'styles/styledComponents';
import {Button, LoadingSpinner} from 'components/UI';
import styled from 'styled-components/macro';
import {Observer, observer, useLocalStore} from 'mobx-react-lite';
import {autorun} from 'mobx';
import {_get, _includes, _round, _toNumber, _toPairs, useInjectStores} from 'utils/utils';

const Collections = observer((props) => {
    const toCurrency = props.store.toCurrency;
    const formatNumber = props.store.formatNumber;
    const {filterStore} = useInjectStores();
    const oppFilter = filterStore.opportunityFilter;
    // const store = useLocalStore(() => ({}));

    /*    useEffect(
            () =>
                autorun(async () => {
                    if (store.resetCurrentLocalFilter) {
                        store.currentLocalFilter = '';
                    }
                    if (!store.resetCurrentLocalFilter && store.currentLocalFilter !== oppFilter.category) {
                        store.currentLocalFilter = oppFilter.category;
                    }
                }),
            []
        );*/

    return (
        <Observer>
            {() => (
                <Wrapper>

                    <TableWrapper height="100%">
                        <TableHeader>FUNDS THAT EIGHT HAS COLLECTED FOR YOU FROM INDUSTRY REJECTS AND/OR
                            SUSPENSIONS</TableHeader>

                        <Divider/>
                        {props.store.isLoadingOppB && <LoadingSpinner size={'200px'}/>}
                        {
                            <Table>
                                <TableBody>
                                    {!props.store.isLoadingOppB && (
                                        <Fragment>
                                            <TableRow>
                                                <HeaderCell textAlign={'left'}>Category</HeaderCell>
                                                <HeaderCell textAlign={'left'}>Count</HeaderCell>
                                                <HeaderCell textAlign={'left'}>Amount</HeaderCell>
                                            </TableRow>
                                            {props.store.oppB?.length > 0 && (
                                                <Fragment>
                                                    {_get(props.store, 'oppB').map(
                                                        // ({ opp_cat, opp_txn_cnt, opp_gross_amt, opp_succ_ratio, opp_amt_likely }, index) => (
                                                        (grossValue, index) => (
                                                            // {
                                                            //     collected_amt: 0,
                                                            // collected_count: 0,
                                                            // et_opp_succ_ratio: "70%",
                                                            // likely_collectible: 210458.07,
                                                            // opp_category: "Insufficient Funds",
                                                            // opp_count: 8213,
                                                            // orig_amt: 300675.51,
                                                            // priority: 4
                                                            // }
                                                            <TableRow key={`${grossValue.opp_category}${index}`}>
                                                                <TableCell textAlign={'left'}>{grossValue.et_opp_cat
                                                                }</TableCell>
                                                                <TableCell
                                                                    textAlign={'left'}>{formatNumber(_toNumber(grossValue.opp_count))}</TableCell>
                                                                <TableCell
                                                                    textAlign={'left'}>{toCurrency(grossValue.opp_orig_amt)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        }
                        <StyledNote margin={'100px 0 0 0'}>
                            {/*<span  className="note">NOTE</span>*/}
                            <span className="noteBody">* Avg Success Ratio is the average of all industries and transaction types.  Thus, your results may vary.
                            </span>
                        </StyledNote>
                        <div css={`font-size: 12px;
                          padding: 0 10px;
                          margin-top: 10px`}>* Please research "Partial Sales" as it is not possible for us to know if
                            your customer paid
                            you on a short-paid order via cash or some other payment type besides credit card.
                        </div>
                        <div css={`font-size: 12px;
                          padding: 0 10px 0 30px;`}>Thus, we do not track collection on this opportunity type.
                        </div>
                        <StyledNote margin={'5px 0 0 0'}>
                            <span className="note2">NOTICE</span>
                            <span className="noteBody">: Most businesses we consult are unaware that they are getting short-paid
                            transactions; thus, this is usually a great area to bring in addition funds to your company.
                            </span>
                        </StyledNote>
                        <br/>
                        <StyledNote >
                            <span className="note">NOTE</span>
                            <span className="noteBody">: It is your responsibility to only try to collect when products and/or services have
                            been provided and delivered.
                            </span>
                        </StyledNote>
                    </TableWrapper>
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 250px;
  height: 100%;

  .center {
    color: ${({theme}) => theme.colors.text};
    margin: auto;
  }
`;
const StyledNote = styled.div`
  //display: flex;
  //flex-direction: column;
  //height: 100%;
  //justify-content: flex-end;
  align-items: center;
  //font-size: 10px;
  margin: ${({margin}) => margin};
  padding: 0 10px;
  font-size: 12px;

  .note {
    font-size: 12px;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
    text-decoration: underline;
      // text-underline: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
  .note2 {
    padding-left: 20px;
    font-size: 12px;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
    text-decoration: underline;
      // text-underline: ${({theme}) => theme.baseColors.whiteAlpha8};
  }

  .noteBody {
    font-size: 12px;
    //font-style: italic;
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;
export default Collections;
