import styled from 'styled-components';

const Popup = styled.div`
  background-color: #fff;
  // background: ${({ theme }) => theme.background};
  //background-color: rgba(255, 255, 255, 0.3);
  bottom: ${(props) => (props.bottom ? props.bottom : '')};
  box-shadow: rgba(91, 91, 91, 0.5) 0px 2px 4px;
  display: flex;
  //height: auto;
  height: ${({ height }) => height || 'auto'};
  z-index: 1;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  justify-content: flex-start;
  left: ${(props) => (props.left ? props.left : '')};
  padding: ${(props) => (props.padding ? props.padding : '8px 12px 10px 8px')};
  position: absolute;
  right: ${(props) => (props.right ? props.right : '')};
  top: ${(props) => (props.top ? props.top : '')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  div.innerPopup {
    overflow-y: auto;
    max-height: 495px;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export { Popup };
