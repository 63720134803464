import React, { useEffect } from 'react';
/*state management*/
import { autorun } from 'mobx';
import { useLocalStore, Observer, observer } from 'mobx-react-lite';
/*partials & styles*/
import { Input, Dropdown, Checkbox, LoadingSpinner, Button } from 'components/UI';
import styled from 'styled-components/macro';
import { ClearIcon, ReportWrapper } from 'styles/styledComponents';
import { FormRow } from './StyledCreateUser';
import { cssError } from 'styles/styledComponents/GlobalStyle';
/*utils & resources*/
import { useInjectStores, _upperFirst, _get, _map, _includes, _find } from 'utils/utils';

const CreateUser = observer((props) => {
  const { rootStore, userStore, uiStore } = useInjectStores();
  const createUserStore = userStore.createUserStore;

  useEffect(() => {
    if (createUserStore.clients.length === 0) {
      createUserStore.addClient(undefined);
    }
  }, []);

  const store = useLocalStore(() => ({
    loading: false,
    initClientOptions: [],
    /** General Use Variables **/
    generalErrorMessage: '',
    generalSuccessMessage: '',
    showClientRoleObjects: false,
    selectedClients: [],
    showDisableSubmitWarn: false,

    /** Form Error Values and Placeholders **/
    error: null,
    activeError: '',
    emailError: '',
    emailTypeIDError: '',
    firstNameError: '',
    lastNameError: '',
    passwordError: '',
    passwordCopyError: '',
    passwordsDontMatchError: '',
    createPassword: '',
    createPasswordCopy: '',
    userClientsError: '',

    /** Getters for the Form Values **/
    get disabledClientOptions() {
      return _map(store.clientRoleObjects, (client) => {
        return client.id;
      });
    },
    get disableSubmitBtn() {
      let clientsWithNoRoles = [];
      if (rootStore.userStore.createUserStore.clients.length > 0) {
        _map(rootStore.userStore.createUserStore.clients, (value, index) => {
          if (value.id && value.roles && value.roles.length === 0) {
            clientsWithNoRoles.push(index);
          }
        });
      }
      return clientsWithNoRoles;
    },
    get clientOptions() {
      return store.initClientOptions.map((option) => {
        return {
          ...option,
          disable: _includes(store.disabledClientOptions, option.value),
        };
      });
    },
    get roleOptions() {
      return [
        { key: 1, text: 'Admin', value: 'admin' },
        { key: 2, text: 'User', value: 'user' },
        // { key: 5, text: 'Demo', value: 5 },
        // { key: 7, text: 'Api', value: 7 },
      ];
    },
    get activePlaceholder() {
      return store.activeError ? store.activeError : 'Is this user active?';
    },
    get deleteClientRoleObject() {
      return _get(createUserStore, 'deleteClientRoleObject');
    },
    get clientRoleObjects() {
      return _get(createUserStore, 'clients', []);
    },
    resetErrors() {
      store.generalErrorMessage = '';
      store.userClientsError = '';
      store.error = null;
      store.emailError = '';
      store.emailTypeIDError = '';
      store.firstNameError = '';
      store.lastNameError = '';
      store.passwordError = '';
      store.passwordCopyError = '';
      store.passwordsDontMatchError = '';
    },
    /** Setters for the Form Values **/
    setCreateFirstName(name) {
      store.firstNameError = '';
      createUserStore.setCreateFirstName(name.target.value);
    },
    setCreateLastName(last) {
      store.lastNameError = '';
      createUserStore.setCreateLastName(last.target.value);
    },
    setCreateEmail(email) {
      store.emailError = '';
      createUserStore.setCreateEmail(email.target.value);
    },
    setCreateEmailType(type) {
      store.emailTypeIDError = '';
      createUserStore.setCreateEmailType(type);
    },
    setCreatePassword(e) {
      store.resetErrors();
      store.generalErrorMessage = '';
      store.createPassword = e.target.value;
      createUserStore.setCreatePassword(e.target.value);
    },
    setCreatePasswordCopy(e) {
      store.resetErrors();
      store.createPasswordCopy = e.target.value;
      createUserStore.setCreatePassword(e.target.value);
    },
    setCreateActive() {
      store.activeError = '';
      let bool = createUserStore.active;
      bool = !bool;
      createUserStore.setCreateActive(bool);
    },
    /** Form Action Functions **/
    addClient(id) {
      createUserStore.setCreateClients(id);
    },
    onChangeForRoleObj(passed, clientRoleObject) {
      store.showDisableSubmitWarn = false;
      let test = clientRoleObject.roles.findIndex((roleID) => roleID === passed.value) === -1;
      if (test) clientRoleObject.addRole(passed.value);
      else clientRoleObject.deleteRole(passed.value);
    },
    validateFields() {
      store.error = null;
      store.emailError = '';
      store.emailTypeIDError = '';
      store.firstNameError = '';
      store.lastNameError = '';
      store.passwordError = '';
      store.passwordCopyError = '';
      store.passwordsDontMatchError = '';
      store.generalErrorMessage = '';
      store.userClientsError = '';
    },
    validateEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let ok = re.test(String(createUserStore.email).toLowerCase());
      if (!ok) this.emailError = 'Please enter a valid email';
      return ok;
    },
    validatePassword() {
      let valid = true;
      if (
        !(
          createUserStore.password.match(/[a-z]/) &&
          createUserStore.password.match(/[A-Z]/) &&
          createUserStore.password.match(/[0-9]/) &&
          createUserStore.password.match(/[^(A-Z|a-z|0-9|\n|\r|\t|\v|\f)]/) &&
          createUserStore.password.length >= 10 &&
          createUserStore.password.length <= 100
        )
      ) {
        store.valid = false;
        store.generalErrorMessage =
          'invalid password: password must contain one upper case letter, one lower case letter, one number, one special character, and needs to be from 10 to 100 characters in length';
        store.passwordError = 'Please enter a valid password';
        if (store.createPassword !== store.createPasswordCopy) {
          store.passwordCopyError = 'passwords do not match';
        }
        return false;
      }
      return valid;
    },
    validateOtherFields() {
      let valid = true;
      if (createUserStore.firstName.length === 0) {
        store.firstNameError = 'Please enter a first name';
        valid = true;
      }
      if (createUserStore.lastName.length === 0) {
        store.lastNameError = 'Please enter a last name';
        valid = true;
      }
      return valid;
    },
    validateUserClient() {
      let valid = true;
      let clientsToFind = createUserStore.clients;
      let completeClientData = [];
      for (let i = 0; i < clientsToFind.length; i++) {
        let currentClientToFind = clientsToFind[i];
        if (_get(clientsToFind[i], 'id')) {
          let foundClient = createUserStore.clients.find((client) => client.id === currentClientToFind.id);
          if (foundClient?.roles?.length) {
            completeClientData.push(foundClient);
          }
        }
      }
      if (completeClientData.length === 0) {
        valid = false;
        store.userClientsError = 'Must select at lease one client and role';
      }
      return valid;
    },
    async createUser() {
      this.isLoading = true;
      try {

        const newClient = await createUserStore.submitNewUser();
        let newUserToClients;
        let newUserClientRoles;
        if (newClient.status === 200) {
          newUserToClients = await createUserStore.addUserToClients(props.store.clients);
        }
        if (newUserToClients.status === 200) {
          newUserClientRoles = await createUserStore.addUserClientRoles(props.store.clients, props.store.adminData.roles);
        }
        if (newUserClientRoles.status === 200) {
          uiStore.notify({
            duration: 2000,
            text: `${createUserStore.firstName} ${createUserStore.lastName} has been created successfully`,
            group: 'topRight',
            type: 'success',
          });
        }
        this.isLoading = false;
        createUserStore.resetState();
      } catch (error) {
        console.log('error', error);
        console.log('error.data', error.data);
        this.isLoading = false;
      }
    },
    async submitForm(event) {
      event.preventDefault();
      store.resetErrors();
      const validEmail = this.validateEmail();
      const validPassword = this.validatePassword();
      const validFirstLastName = this.validateOtherFields();
      const validUserClient = this.validateUserClient();
      if (validEmail && validFirstLastName && validUserClient && validPassword) {
        await this.createUser();
      }
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        store.initClientOptions = _map(props.store.clients, (client) => {
          return {
            value: client.id,
            text: `${client.clientName}`,
            key: client.id,
            disable: false,
          };
        });
      }),
    []
  );

  const labelWidth = '180px';

  return (
    <Observer>
      {() => (
        <>
          {props.store.isLoading && <LoadingSpinner size={'200px'} />}
          {!store.isLoading && (
            <Wrapper height="100%">
              <div className="innerContainer">
                <div className="user-side">
                  <br />
                  <div style={{ paddingLeft: '63px', width: '100%', textAlign: 'center' }}> New User</div>
                  <br />
                  {store.generalSuccessMessage && (
                    <div className="generalMessageRow success">
                      <h3>{store.generalSuccessMessage}</h3>
                    </div>
                  )}
                  <FormRow>
                    <Input
                      error={store.firstNameError}
                      onChange={store.setCreateFirstName}
                      placeholder={'First Name'}
                      value={createUserStore.firstName}
                      border
                      label="First Name"
                      labelWidth={labelWidth}
                      tabIndex={1}
                    />
                  </FormRow>
                  <FormRow>
                    <Input
                      error={store.lastNameError}
                      onChange={store.setCreateLastName}
                      placeholder={'Last Name'}
                      value={createUserStore.lastName}
                      border
                      label="Last Name"
                      labelWidth={labelWidth}
                      tabIndex={2}
                    />
                  </FormRow>
                  <FormRow>
                    <Input
                      error={store.emailError}
                      onChange={store.setCreateEmail}
                      placeholder={'Email'}
                      value={createUserStore.email}
                      label={'User Email'}
                      labelWidth={labelWidth}
                      border
                      tabIndex={3}
                    />
                  </FormRow>
                  <FormRow noMargin={true}>
                    {store.passwordsDontMatchError && (
                      <div
                        style={{
                          alignItems: 'center',
                          color: 'red',
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '5px',
                          width: '100%',
                        }}
                      >
                        <h4>Passwords Don't Match</h4>
                      </div>
                    )}
                  </FormRow>
                  <FormRow>
                    <Input
                      error={store.passwordError}
                      onChange={store.setCreatePassword}
                      placeholder={'Enter a Password'}
                      value={store.createPassword}
                      label={'Password'}
                      labelWidth={labelWidth}
                      border
                      tabIndex={5}
                    />
                  </FormRow>
                  <FormRow>
                    <Input
                      error={store.passwordCopyError}
                      onChange={store.setCreatePasswordCopy}
                      placeholder={'Enter The Password Again'}
                      value={store.createPasswordCopy}
                      label={'Re Enter Password'}
                      labelWidth={labelWidth}
                      border
                      tabIndex={6}
                    />
                  </FormRow>
                  {/*<form>
              <FormRow>
                <Checkbox
                  onChange={(active) => store.setCreateActive(active)}
                  placeholder={store.activePlaceholder}
                  value={createUserStore.active}
                  checked={createUserStore.active}
                  labelWidth={'128px'}
                  label="Active"
                  tabIndex={7}
                />
              </FormRow>
              <br />
            </form>*/}

                  {store.generalErrorMessage && (
                    <span
                      css={`
                        ${cssError};
                        padding: 0 10px 0 20px;
                      `}
                    >
                      {store.generalErrorMessage}
                    </span>
                  )}
                  {/*{store.error && <Error error={store.error} />}*/}

                  <div className="btnRow">
                    {store.showDisableSubmitWarn && <div className="selectRoleMsg">All clients must have at lease one role</div>}
                    <div className="btnContainer" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <div style={{ width: '125px' }} />
                      <SubmitButton className="submit" width={'100px'} onClick={store.submitForm}>
                        Submit Form
                      </SubmitButton>
                    </div>
                  </div>
                </div>
                <div className="client-side">
                  <div className="btnRow">
                    {store.showDisableSubmitWarn && <div className="selectRoleMsg">All clients must have at lease one role</div>}
                    <div className="btnContainer">
                      <SubmitButton className="submit" onClick={() => createUserStore.addClient(undefined)}>
                        Add Client/Roles
                      </SubmitButton>
                      <ClearButton
                        width="100px"
                        margin="0 0 0 5px"
                        onClick={() => {
                          createUserStore.resetState();
                          store.createPassword = '';
                          store.createPasswordCopy = '';
                          store.showDisableSubmitWarn = false;
                          store.resetErrors();
                        }}
                      >
                        Clear Form
                      </ClearButton>
                    </div>
                  </div>

                  {store.userClientsError && (
                    <span
                      css={`
                        ${cssError};
                        padding: 0 10px 0 20px;
                      `}
                    >
                      {store.userClientsError}
                    </span>
                  )}

                  <div style={{ borderBottom: '1px solid rgba(255,255,255, .2)' }} />
                  {store.clientRoleObjects.length > 0 &&
                    _map(createUserStore.clients, (clientRoleObject, i) => {
                      return (
                        <ReportWrapper noShadow noBackground margin={'0 0'} key={i}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            {clientRoleObject.id && (
                              <div style={{ width: '100%' }}>
                                {_get(_find(store.clientOptions, { value: clientRoleObject.id }), 'text')}
                              </div>
                            )}
                            {!clientRoleObject.id && (
                              <Observer>
                                {() => (
                                  <Dropdown
                                    className={'Dropdown'}
                                    loading={props.store.isLoading}
                                    options={store.clientOptions}
                                    onChange={(val) => clientRoleObject.setClientID(val)}
                                    placeholder={'Select a Client'}
                                    value={clientRoleObject.id}
                                    margin={'3px 0 3px 0'}
                                    width={'170px'}
                                    border
                                    tabIndex={7 + i}
                                    search
                                  />
                                )}
                              </Observer>
                            )}

                            {clientRoleObject.id && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '0 5px',
                                  }}
                                >
                                  {store.roleOptions.map((opt, index) => {
                                    let checked = _includes(clientRoleObject.roles, opt.value);
                                    return (
                                      <Checkbox
                                        width={'75px'}
                                        onChange={() => store.onChangeForRoleObj(opt, clientRoleObject)}
                                        key={index}
                                        label={_upperFirst(opt.text)}
                                        value={checked}
                                        checked={checked}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                type="delete"
                                width="15px"
                                height="15px"
                                style={{ marginLeft: '10px' }}
                                onClick={() => store.deleteClientRoleObject(i)}
                              >
                                <ClearIcon id="filterBtn" color="#fff" style={{ width: 15, height: 15 }} />
                              </Button>
                            </div>
                          </div>
                          <div style={{ borderTop: '1px solid rgba(255,255,255, .2)' }} />
                        </ReportWrapper>
                      );
                    })}
                </div>
              </div>
            </Wrapper>
          )}
        </>
      )}
    </Observer>
  );
});

export const SubmitButton = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background-color: #3fa9f5;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.baseColors.colorBlueHover};
  }
`;

export const ClearButton = styled.div`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ theme }) => theme.buttonStyles.delete};
  box-shadow: 0 2px 4px rgba(91, 91, 91, 0.5);
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  margin: ${({ margin }) => margin};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.buttonStyles.deleteHover};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height || '100%'};
  color: ${({ theme }) => theme.colors.primaryText};
  @media (max-width: 1040px) {
    max-width: 1040px;
  }
  .innerContainer {
    display: flex;
    width: 100%;
    max-width: 1040px;
    @media (max-width: 1040px) {
      flex-direction: column;
    }
  }
  .user-side {
    width: 100%;
    height: 594px;
    padding: 5px 5px 0;
    overflow-y: auto;
    @media (max-width: 1040px) {
      padding: 0 40px;
      width: auto;
    }
  }
  .client-side {
    width: 100%;
    height: 100%;
    padding: 5px 5px 0;
    overflow-y: auto;
    margin-bottom: 50px;
    @media (max-width: 1040px) {
      padding: 0 40px;
      width: auto;
    }
  }
  .generalMessageRow {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    &.success {
      color: ${({ theme }) => theme.baseColors.colorGreen};
    }
    &.error {
      color: ${({ theme }) => theme.baseColors.colorOrange};
    }
  }
  .btnRow {
    margin-top: 18px;
    margin-bottom: 1px;
    align-items: center;
    display: flex;
    flex-direction: column;
    .selectRoleMsg {
      color: red;
      display: flex;
      margin: 5px 0;
    }
    .btnContainer {
      margin-bottom: 8px;
      display: flex;
      max-height: 33px;
      .btn {
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin: 0 5px;
        outline: none;
        padding: 4px 10px;
        transition: color 0.25s, background 0.25s, border 0.25s, box-shadow 0.25s;
        &.primary {
          background-color: ${({ theme }) => theme.baseColors.colorBlue};
          border: ${({ theme }) => `1px solid ${theme.baseColors.colorBlue}`};
          color: #fff;

          &:hover {
            background-color: ${({ theme }) => theme.baseColors.colorBlueHover};
            border-color: ${({ theme }) => `1px solid ${theme.baseColors.colorBlue}`};
          }
        }

        &.warn {
          background-color: ${({ theme }) => theme.baseColors.colorOrange};
          border: ${({ theme }) => `1px solid ${theme.baseColors.colorOrange}`};
          color: #fff;

          &:hover {
            background-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
            border-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
          }
        }
      }
    }
  }

  .selectClientRow {
    align-items: flex-start;
    border-top: ${({ theme }) => `1px solid ${theme.baseColors.colorGreyMedium}`};
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-top: 10px;
  }
  .deleteButton {
    background-color: ${({ theme }) => theme.baseColors.colorOrange};
    border: ${({ theme }) => `1px solid ${theme.baseColors.colorOrange}`};
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: inherit;
    margin: 0 5px;
    outline: none;
    padding: 4px 10px;
    transition: color 0.25s, background 0.25s, border 0.25s, box-shadow 0.25s;

    &:hover {
      background-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
      border-color: ${({ theme }) => theme.baseColors.colorOrangeHover};
    }
  }
`;

export default CreateUser;
