import styled from 'styled-components/macro';

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ noBackground, theme }) => {
    if (noBackground && noBackground === true) return 'none';
    else return theme.tableStyles.backgroundColor;
  }};
  box-shadow: ${({ noShadow }) => (noShadow ? '' : '0 0 4px rgba(91, 91, 91, 0.5)')};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '0px'};
  margin: ${(props) => {
    if (props.margin && !(props.marginLeft || props.marginRight || props.marginTop || props.marginBottom))
      return `${props.margin}`;
    else if (props.marginLeft || props.marginRight || props.marginTop || props.marginBottom) {
      let marginTop = props.marginTop ? props.marginTop : '0';
      let marginRight = props.marginRight ? props.marginRight : '0';
      let marginBottom = props.marginBottom ? props.marginBottom : '0';
      let marginLeft = props.marginLeft ? props.marginLeft : '0';
      return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
    } else return '0';
  }};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

const ReportBody = styled.div`
  height: ${({ height }) => height || '100%'};
  padding: ${({ padding }) => padding || '5px'};
  margin: ${({ margin }) => margin};
`;

const RowContainer = styled.div`
  padding: ${(props) => props.padding || 0};
  display: flex;
`;

export { ReportWrapper, ReportBody, RowContainer };
