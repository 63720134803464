import React, {useEffect} from 'react';
import {WidgetHeader, WidgetWrapper} from 'styles/styledComponents';
import {Dropdown, Error, LoadingSpinner, Notes} from 'components/UI';
import styled from 'styled-components/macro';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {_get, _has, _set, _toNumber, useGetWidgetData, useInjectStores} from 'utils/utils';
import {AgGridReact} from 'ag-grid-react';
import {gridOptsColumnDefs} from './resources';
import {widgetService} from '../../../services';

const TopEight = observer(() => {
    const {dataManagementStore} = useInjectStores();
    const store = useLocalObservable(() => ({
        selectedTopicDowngrades: 1,
        selectTopicDowngrades(val) {
            this.selectedTopicDowngrades = val;
        },
        topicsDowngrades: [
            { key: 1, text: 'Bump Fee Reasons', value: 1 },
            { key: 2, text: 'Rejected Funding Rsns', value: 2 },
        ],

        dashBumpFeeRsn: [],
        dashRejRsn: [],
        fetchDashBumpFeeRsn: false,
        fetchDashRejRsn: false,

        setDashBumpFeeRsn(data) {
            this.fetchDashBumpFeeRsn = false;
            this.dashBumpFeeRsn = data;
        },
        setDashRejRsn(data) {
            this.fetchDashRejRsn = false;
            this.dashRejRsn = data;
        },
        error: null,
        api: null,
        gridColumnApi: null,
        gridOptions: {
            rowHeight: 33,
            headerHeight: 33,
            animateRows: true,
            enableBrowserTooltips: true,
            tooltipShowDelay: 0,
            reactNext: true,
            enableCharts: true,
            enableRangeSelection: true,
            groupMultiAutoColumn: true,
            groupSuppressAutoColumn: true,
            treeData: true,
            rowClassRules: {
                secondLevel: function (params) {
                    return params.data.secondLevel;
                },
                topLevel: function (params) {
                    return params.data.topLevel;
                },
            },
            groupRemoveSingleChildren: true,
            getDataPath: function (data) {
                return data.keyPath;
            },
            getRowNodeId: function (data) {
                return data.id;
            },
            defaultColDef: {
                sortable: false,
                resizable: true,
            },
        },
        onGridReady(params) {
            this.api = params.api;
            this.gridColumnApi = params.columnApi;
        },
        detailRowHeight: 70,

        get treeDashBumpFeeRsn() {
            const dictionary = {};
            if (this.dashBumpFeeRsn?.length) {
                let id_index = 1;
                let treeData = this.dashBumpFeeRsn || [];

                for (let i = 0; i < treeData.length; i++) {
                    let currentBumpRsn = treeData[i];
                    let newBumpRsnCheck = `${currentBumpRsn.bump1_rsn}${currentBumpRsn.bump2_rsn}`;
                    currentBumpRsn.bump_cat_cnt = _toNumber(currentBumpRsn.bump_cat_cnt);
                    if (!_has(dictionary, newBumpRsnCheck)) {
                        _set(dictionary, newBumpRsnCheck, {
                            topLevel: {
                                ...treeData[i],
                                topLevel: true,
                                id: id_index,
                                keyPath: [newBumpRsnCheck],
                            },
                            bumpRsn: [],
                        });
                        id_index += 1;
                    } else {
                        // first time getting a duplicate topLevel and push the top level into the bumpRsn array.
                        if (dictionary[newBumpRsnCheck].bumpRsn.length === 0) {
                            dictionary[newBumpRsnCheck].bumpRsn.push({
                                ...dictionary[newBumpRsnCheck].topLevel,
                                secondLevel: true,
                                keyPath: [newBumpRsnCheck, `bump1${currentBumpRsn.bump3_rsn}`],
                                id: id_index,
                            });
                            id_index += 1;
                        }
                        ///////// add count to toplvl
                        dictionary[newBumpRsnCheck].topLevel = {
                            ...dictionary[newBumpRsnCheck].topLevel,
                            bump3_rsn: `Multiple (${dictionary[newBumpRsnCheck].bumpRsn.length + 1})`,
                            bump_cat_cnt: _toNumber((dictionary[newBumpRsnCheck].topLevel.bump_cat_cnt += currentBumpRsn.bump_cat_cnt)),
                        };
                        ///////////
                        dictionary[newBumpRsnCheck].bumpRsn.push({
                            ...treeData[i],
                            secondLevel: true,
                            id: id_index,
                            keyPath: [newBumpRsnCheck, `bump${dictionary[newBumpRsnCheck].bumpRsn.length + 1}${currentBumpRsn.bump3_rsn}`],
                        });
                        id_index += 1;
                    }
                }
            }

            let toReturn = [];
            for (const key in dictionary) {
                toReturn = [...toReturn, dictionary[key].topLevel, ...dictionary[key].bumpRsn];
            }
            return toReturn;
        },
        get treeDataDashRejRsn() {
            let rowData = [];
            if (this.dashRejRsn?.length) {
                let data = this.dashRejRsn;
                for (let i = 0; i < data.length; i++) {
                    const current = data[i];
                    rowData.push({
                        ...current,
                        keyPath: [`${current.txn_typ}${current.dep_rej_desc}${current.ref_risk_desc}`],
                        id: i + 1,
                    });
                }
            }
            return rowData;
        },
        get colDefsDowngradesRowData() {
            switch (this.selectedTopicDowngrades) {
                case 1:
                    return {
                        rowData: this.treeDashBumpFeeRsn,
                        columnDefs: gridOptsColumnDefs.dashBumpFeeRsn.columnDefs,
                    };
                case 2:
                    return {
                        rowData: this.treeDataDashRejRsn,
                        columnDefs: gridOptsColumnDefs.dashRejRsn.columnDefs,
                    };
                default:
                    return {
                        rowData: [],
                        columnDefs: {},
                    };
            }
        },
        setFetchDashBumpFeeRsn(fetch) {
            this.fetchDashBumpFeeRsn = fetch;
        },
        setFetchDashRejRsn(fetch) {
            this.fetchDashRejRsn = fetch;
        },
    }));

    const dashBumpFeeRsn = useGetWidgetData(
        {name: 'dashboardBumpFeeReason', query: widgetService.dashboardBumpFeeReason},
        'data',
        store.fetchDashBumpFeeRsn
    );
    const dashRejRsn = useGetWidgetData(
        {name: 'dashboardRejectReason', query: widgetService.dashboardRejectReason},
        'data',
        store.fetchDashRejRsn
    );

    useEffect(() => {
        store.setDashBumpFeeRsn(dashBumpFeeRsn.data);
    }, [dashBumpFeeRsn.data]);
    useEffect(() => {
        store.setDashRejRsn(dashRejRsn.data);
    }, [dashRejRsn.data]);

    useEffect(() => {
        store.setFetchDashBumpFeeRsn(true);
        store.setFetchDashRejRsn(true);
    }, [dataManagementStore?.newQuery]);

    return (
        <WidgetWrapper position='relative' height="100%">
          <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>
            <div style={{ position: 'absolute', left: 5 }}>
              <Dropdown
                  height={'25px'}
                  options={store.topicsDowngrades}
                  onChange={store.selectTopicDowngrades}
                  placeholder="Granularity"
                  toggleDisable={store.toggleDisable}
                  value={store.selectedTopicDowngrades}
                  labelWidth={'0px'}
                  width={'175px'}
                  margin={'0 2px 0 6px'}
                  border
              />
            </div>
            <WidgetHeader>Downgrades</WidgetHeader>
          </div>
          <div></div>

          {/*<Divider />*/}
          <GridWrapper>
              {dashBumpFeeRsn.status === 'loading' || dashRejRsn.status === 'loading' ? (
                  <LoadingSpinner size={'200px'}/>
              ) : dashBumpFeeRsn.status === 'error' ? (
                  <Error error={dashBumpFeeRsn.error}/>
              ) : dashRejRsn.status === 'error' ? (
                  <Error error={dashRejRsn.error}/>
              ) : (
            <div
                id="myGrid"
                className={'ag-theme-alpine-dark'}
                style={{
                  height: '100%',
                  minHeight: '300px',
                  width: '100%',
                  position: 'relative',
                  borderRadius: '5px',
                }}
            >
              <AgGridReact
                  onGridReady={store.onGridReady}
                  rowData={store.colDefsDowngradesRowData.rowData}
                  columnDefs={store.colDefsDowngradesRowData.columnDefs}
                  gridOptions={store.gridOptions}
                  popupParent={document.getElementById('myGrid')}
              />
            </div>)}
          </GridWrapper>
        </WidgetWrapper>
    );
});

const GridWrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);

    .ag-header-row {
      height: 30px;
    }

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .topLevel {
      background-color: #001329 !important;
    }

    .secondLevel {
      background-color: #032845 !important;
    }
  }

  grid-area: SsTotalsGraph;
  position: relative;
    // width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .noData {
    margin: auto;
  }
`;

export default TopEight;
