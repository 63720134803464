import { types } from 'mobx-state-tree';
const { string, boolean, optional } = types;

const ExportInfo = types
  .model({
    exportData: optional(boolean, true),
    exportType: optional(string, 'excel'),
  })
  .actions((self) => ({
    setExportData(setExport) {
      self.exportData = setExport;
    },
    setExportType(setType) {
      self.exportType = setType;
    },
  }));

export default ExportInfo;
