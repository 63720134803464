/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#046a99+12,004966+100 */
// const logo = './../../images/GoldenAit/goldenAitLogo.svg';

import { ReactComponent as Logo } from '../../images/GoldenAit/eightLogoSilver.svg';

const baseColors = {
  white: '#fff',
  whiteDarker: '(255, 255, 255)',
  whiteBorder: 'rgba(255, 255, 255, 0.3)',
  whiteAlpha1: 'rgba(255, 255, 255, 0.1)',
  whiteAlpha2: 'rgba(255, 255, 255, 0.2)',
  whiteAlpha8: 'rgba(255, 255, 255, 0.8)',
  whiteAlpha9: 'rgba(255, 255, 255, 0.9)',
  backgroundLightMode: '#23292d',
  colorBlue: '#3fa9f5',
  colorBlueHover: '#3586c8',
  blueFocusBorder: '#4183C4',
  colorDanger: '#FF2B49',
  colorDangerHover: '#d2081e',
  colorGreen: 'rgb(69, 232, 133)',
  colorGreenHover: 'rgb(19, 182, 83)',
  colorGrey: '#4d4d4d',
  colorGreyDark: 'rgb(91,91,91)',
  colorGreyLight: '#f0f0f0',
  colorGreyMedium: '#5b5b5b',
  colorGreyMediumLight: '#dbdbdb',
  colorOrange: '#f79514',
  colorOrangeHover: '#db7914',
  colorYellow: '#F6EC4C',
  greyLightBorder: '#e2e2e2',
  newYellow: '#fccc1a',
  colorYellowHover: '#ded021',
  opaque: 'rgba(0, 0, 0, 0.1)',
  transparent: 'transparent',
};

/*const bordersShadow = {
  boxShadow: 'rgba(91, 91, 91, 0.5) 0px 2px 4px;',
  inputBorder: `1px solid ${baseColors.greyLightBorder}`,
  dropdownBorder: `1px solid ${baseColors.greyLightBorder}`,
  focusDropdownBorder: `1px solid ${baseColors.blueFocusBorder}`,
  focusInputBorder: `1px solid ${baseColors.blueFocusBorder}`,
};*/

const buttonStyles = {
  /** Background Colors **/
  secondary: baseColors.colorGreen,
  secondaryHover: baseColors.colorGreenHover,
  delete: baseColors.colorDanger,
  deleteHover: baseColors.colorDangerHover,
  primary: baseColors.colorBlue,
  primaryHover: baseColors.colorBlueHover,
  clear: baseColors.colorOrange,
  clearHover: baseColors.colorOrangeHover,
};

/*const colors = {
  background: '#F0F0F0',
  errorWarning: '#ff2b49',
  negative: '#ff2b49',
  border: '#D2D2D2',
  text: '#5B5B5B',
  inputText: '#5B5B5B',
  primaryText: '#5B5B5B',
  primaryBtn: '#3EA8F5' || 'rgb(62, 168, 245)',
  primaryBtnText: '#FFF',
  tableHeaderText: '#5b5b5b',
  secondaryBtn: '#F79514' || 'rgb(247, 149, 20)',
  spinner: '#000',
  divider: '1px solid rgba(91, 91, 91, .1)',
  totalsDivider: '1px solid rgba(91, 91, 91, .3)',
  filterBorderBottom: '1px solid rgba(91, 91, 91, .3)',
};*/

/*const fonts = {
  baseFontsize: 12,
  baseFonts: 'Futura PT, sans-serif',
};*/

const goldenAitTheme = {
  darkTheme: {
    theme: 'dark',
    background: 'linear-gradient(135deg, #047899 0%, #04618c 19%, #003c6d 56%, #003360 100%)',
    boxShadow: 'rgba(91, 91, 91, 0.5) 0px 2px 4px;',
    buttonStyles,
    inputBorder: '1px solid #e2e2e2',
    focusInputBorder: '1px solid #4183C4',
    dropdownBorder: '1px solid #e2e2e2',
    focusDropdownBorder: '1px solid #4183C4',
    primaryTickColor: 'rgba(255,255,255, .8)',
    calendar: {
      colors: {
        selectedStartEndDates: '#43BBDD',
        selectedRange: '#CAE5ED',
        preselectedBtnText: '#FFF',
        preselectedBtnBackground: '#3EA8F5',
        cancelBtn: '#F79514' || 'rgb(247, 149, 20)',
      },
    },
    colors: {
      background: '#F0F0F0',
      errorWarning: '#ff2b49',
      negative: '#ff2b49',
      border: '#D2D2D2',
      text: 'rgb(255, 255, 255, .8) !important',
      inputText: '#5B5B5B',
      primaryTextLight: '#5B5B5B',
      primaryText: 'rgba(255,255,255, .8)',
      primaryBtn: '#3EA8F5' || 'rgb(62, 168, 245)',
      primaryBtnText: '#FFF',
      secondaryBtn: '#F79514' || 'rgb(247, 149, 20)',
      spinner: '#FFF',
      divider: '1px solid rgba(255,255,255, .1)',
      totalsDivider: '1px solid rgba(255,255,255, .3)',
      filterBorderBottom: '1px solid rgba(255,255,255, .15)',
    },
    fontFamily: 'Futura PT, sans-serif',
    fontSize: '',
    header: {
      logoPath: '../../images/GoldenAit/goldenAitLogo.svg',
      colors: {
        logoBackground: '',
        background: '#F0F0F0',
        viewName: '#FFF',
        logout: '#FFF',
      },
    },
    icons: {
      colors: {
        filterBtnBackground: 'rgb(69, 232, 133)',
        filterBtnHoverBackground: 'rgb(19, 182, 83)',
        filterBtnColor: '#FFF',
        sortAlpha: '#3EA8F5',
        university: '#FF2B49',
        creditCard: '#F6EC4B',
        creditCardAlpha: 'radial-gradient(circle, rgb(252, 204, 26) 30%, rgba(255,255,255, .4) 200%)',
        warning: '#45E786',
        warningAlpha: 'radial-gradient(circle, rgba(69,231,134, 1) 30%, rgba(255,255,255, .3) 200%)',
        cart: '#3EA8F5',
        cartAlpha: 'radial-gradient(circle, rgba(62, 168, 245, 1) 30%, rgba(255,255,255, .3) 200%)',
        user: '',
        lock: '',
        txnTotalsIcon: '#fff',
      },
    },
    loginLogo: {
      logoPath: Logo,
      height: '103px',
      width: '215px',
    },
    paginator: {},
    primaryColor: 'rgb(91, 91, 91)',
    rgl: {
      colors: {
        background: 'transparent',
      },
      gridItem: {
        colors: {
          primaryText: 'rgba(255,255,255, .8)',
          secondaryText: '#5B5B5B',
          background: 'rgba(0,0,0,0.6) !important',
          toolTipBackground: '#041c2f',
          grid: '#263743',
          gridPanelBackground: 'rgb(32, 113, 138)',
          startOpacity: 0.8,
          stopOpacity: 0,
          totalsBackground: 'transparent',
        },
      },
    },
    sidebar: {
      colors: {
        menuBtn: '#FFF',
        hoverMenuBtn: '#FFF',
        linkText: '#FFF',
        linkIcon: '#FFF',
        hoverLinkIconText: '#3EA8F5',
        linkBackground: '#FFF',
        hoverBackground: '',
        sortHandle: '#445f64',
      },
    },
    ssTotalsGraph: {
      colors: {
        reserves: '#FF2B49',
        returnedSales: '#F6EC4B',
        declines: '#45E786',
        sales: '#3EA8F5',
      },
    },
    ssLoadingSpinner: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    tableStyles: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      hoverBackgroundColor: 'rgba(0,0,0, 0.3)',
      tableBottomBorder: 'rgba(0,0,0, 0.2)',
      categoryTRBackgroundColor: '#5b5b5b',
      color: '#fff',
      manageUserPanelBackgroundColor: 'rgba(0,0,0,0)',
      reasonTRBackgroundColor: '#a1a1a1',
      tableHeaderText: '#FFF',
      txnTypeTRBackgroundColor: '#3ea8f5',
    },
    SsProcLimitsStyles: {
      colors: {
        border: '#DDD',
        background: '#FFF',
        color: '#5B5B5B',
        headerBackground: '#3FA9F5',
        headerText: '#FFF',
      },
      borders: {
        midLimitBorder: '1px solid #ddd',
      },
      CardBack: {
        boxShadow: '0 0px 4px rgba(0, 0, 0, 0.3)',
      },
    },
    AdminStyles: {
      clientManagement: {
        color: 'white',
        midInfoBorder: '1px solid white',
        panelBackgroundColor: 'rgba(0,0,0,0.4)',
      },
      demoIntegrations: {
        backgroundColor: 'rgba(0,0,0,0.4)',
      },
      ssFormsManagement: {},
      manageUsers: {},
      reports: {},
    },
    reportsStyles: {
      marketing: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: 'white',
      },
      fulfillment: {},
      customerService: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: 'white',
      },
      accounting: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: 'white',
      },
      execs: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: 'white',
      },
      customReports: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: 'white',
      },
    },
    AGGridStyles: {
      borderStyles: {
        outsideTableBorder: '1px solid rgba(255,255,255, .13)',
        defaultBorder: '1px solid #ddd',
      },
      rowStyles: {
        selectedColor: 'rgba(255, 255, 255, 0.15) !important',
        even: {
          evenBackground: '#173146',
          color: 'rgba(255,255,255, .8)',
        },
        odd: {
          oddBackground: '#042037',
          color: 'rgba(255,255,255, .8)',
        },
        hover: {
          hoverBackground: 'rgba(255,255,255, .15)',
          color: 'rgba(255,255,255,.8)',
        },
        header: {
          borderStyle: '1px solid rgba(255, 255, 255, 0.1)',
          headerBackground: '#042037',
          color: 'rgba(255,255,255,.8)',
        },
      },
      textColors: {
        rowColor: '#FFFFFF',
        headerColor: '#FFFFFF',
      },
      tableStyles: {
        backgroundColor: 'rgba(3, 26, 42, 1)',
      },
    },
    actionListStyles: {
      border: 'rgba(255, 255, 255, 0.3)',
      text: '#5B5B5B',
    },
    notesEditorStyles: {
      border: 'rgba(255, 255, 255, 0.3)',
      text: '#5B5B5B',
    },
    baseColors: { ...baseColors },
  },
  /*  lightTheme: {
    headerBackground: 'rgba(128, 128, 128, .8)',
    background: baseColors.backgroundLightMode,
    tableStylesFile: 'eyghtTableStyles.js',
    fontFamily: fonts.baseFonts,
    fontSize: '',
    primaryTickColor: baseColors.colorGreyMedium,
    primaryColor: baseColors.colorGreyDark,
    boxShadow: 'rgba(91, 91, 91, 0.5) 0px 2px 4px;',
    ...bordersShadow,
    buttonStyles,
    colors: { ...colors },
    rgl: {
      colors: {
        background: 'transparent',
      },
      gridItem: {
        colors: {
          primaryText: '#5B5B5B',
          secondaryText: '#5B5B5B',
          background: '#fff',
          toolTipBackground: '#fff',
          grid: '#E5E5E5',
          gridPanelBackground: 'rgb(32, 113, 138)',
          startOpacity: 0.4,
          stopOpacity: 0.4,
          totalsBackground: '#fff',
        },
      },
    },
    header: {
      logoPath: '../images/GoldenAit/goldenAitLogo.svg',
      colors: {
        logoBackground: '',
        background: '#F0F0F0',
        viewName: '#FFF',
        logout: '#FFF',
      },
    },
    sidebar: {
      colors: {
        menuBtn: '#000',
        hoverMenuBtn: '#FFF',
        linkText: '#FFF',
        linkIcon: '#FFF',
        hoverLinkIconText: '#3EA8F5',
        linkBackground: '#FFF',
        hoverBackground: '',
        sortHandle: '#445f64',
      },
    },
    icons: {
      colors: {
        filterBtnBackground: 'rgb(82,232,166)',
        filterBtnHoverBackground: 'rgb(42, 192, 126)',
        filterBtnColor: '#FFF',
        sortAplpha: '#3EA8F5',
        university: '#FF2B49',
        creditCard: '#F6EC4B',
        warning: '#45E786',
        cart: '#3EA8F5',
        user: '',
        lock: '',
        txnTotalsIcon: '#fff',
      },
    },
    loginLogo: {
      logoPath: Logo,
      height: '52px',
      width: '200px',
    },
    ssTotalsGraph: {
      colors: {
        reserves: '#FF2B49',
        returnedSales: '#F6EC4B',
        declines: '#45E786',
        sales: '#3EA8F5',
      },
    },
    tableStyles: {
      backgroundColor: '#fff',
      hoverBackgroundColor: 'rgb(194, 194, 194, 5)',
      categoryTRBackgroundColor: '#5b5b5b',
      color: '#5b5b5b',
      eyght: 'noDirectory',
      manageUserPanelBackgroundColor: '#dbdbdb',
      reasonTRBackgroundColor: '#a1a1a1',
      tableHeaderBackgroundColor: '#F0EFF0',
      tableHeaderText: '#5b5b5b',
      txnTypeTRBackgroundColor: '#3ea8f5',
    },
    calendar: {
      colors: {
        selectedStartEndDates: '#43BBDD',
        selectedRange: '#CAE5ED',
        preselectedBtnText: '#FFF',
        preselectedBtnBackground: '#3EA8F5',
        cancelBtn: '#F79514' || 'rgb(247, 149, 20)',
      },
    },
    SsProcLimitsStyles: {
      colors: {
        border: '#DDD',
        background: '#FFF',
        color: '#5B5B5B',
        headerBackground: '#3FA9F5',
        headerText: '#FFF',
      },
      borders: {
        midLimitBorder: '1px solid #ddd',
      },
      CardBack: {
        boxShadow: '0 0px 4px rgba(0, 0, 0, 0.3)',
      },
    },
    AdminStyles: {
      clientManagement: {
        midInfoBorder: '1px solid #5b5b5b',
        color: '#5b5b5b',
        panelBackgroundColor: '#FFFFFF',
      },
      demoIntegrations: {
        backgroundColor: 'rgba(0,0,0,0)',
      },
      ssFormsManagement: {},
      manageUsers: {},
      reports: {},
    },
    reportsStyles: {
      marketing: {
        backgroundColor: 'white',
        color: '#5b5b5b',
      },
      fulfillment: {
        backgroundColor: 'white',
        color: '#5b5b5b',
      },
      customerService: {
        backgroundColor: 'white',
        color: '#5b5b5b',
      },
      accounting: {
        backgroundColor: 'white',
        color: '#5b5b5b',
      },
      execs: {
        backgroundColor: 'white',
        color: '#5b5b5b',
      },
      customReports: {
        backgroundColor: 'white',
        color: '#5b5b5b',
      },
    },
    AGGridStyles: {
      borderStyles: {
        outsideTableBorder: '1px solid #ddd',
        defaultBorder: '1px solid white',
      },
      rowStyles: {
        selectedColor: '#dcdcdc !important',
        even: {
          evenBackground: '#fff',
          color: '#5b5b5b',
        },
        odd: {
          oddBackground: '#F0F0F0',
          color: '#5b5b5b',
        },
        hover: {
          hoverBackground: '#3fa9f5',
          color: '#fff',
        },
        header: {
          borderStyle: 'none',
          headerBackground: '#F0EFF0',
          tableHeaderBackgroundColor: '#',
          color: '#5b5b5b',
        },
      },
      tableStyles: {
        backgroundColor: 'transparent',
      },
    },
    actionListStyles: {
      border: 'rgba(255, 255, 255, 0.3)',
      text: '#5B5B5B',
    },
    notesEditorStyles: {
      border: '1px solid rgba(255, 255, 255, 0.3)',
      text: '#5B5B5B',
    },
    baseColors: { ...baseColors },*/
};
export default goldenAitTheme;
