import React, { useEffect, useRef } from 'react';
import { Popup } from './StyledPopupMenu';
import { autorun } from 'mobx';
import { useLocalStore, Observer, observer } from 'mobx-react-lite';

const PopupMenu = observer((props) => {
  const node = useRef();

  const store = useLocalStore(() => ({
    get show() {
      return props.show;
    },
  }));

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (
      props.show &&
      e.target.id !== 'filterBtn' &&
      e.target.parentElement.id !== 'filterBtn' &&
      e.target.parentElement.id !== 'Button' &&
      e.target.id !== 'logout' &&
      e.target.id !== 'Button'
    ) {
      props.hidePopup();
    }
  };

  useEffect(() => {
    const disposer = autorun(async () => {
      if (store.show) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      disposer();
      if (props.cleanup) {
        props.cleanup();
      }
    };
  }, []);

  return (
    <Observer>
      {() => (
        <Popup height={props.height} ref={node} {...props}>
          {props.children}
        </Popup>
      )}
    </Observer>
  );
});

export default PopupMenu;
