import React, { useEffect, useRef, useState } from 'react';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
/* Styled Components */
import styled from 'styled-components/macro';
/* utils */
import { _get, useInjectStores } from 'utils/utils';
/* partials */
import { Adjustments, Chargebacks, Deposit, DueBox, Fees, PlanCodes, PlanSummary, ResFund, StmtHeader, TableStmtHeader } from './Partials';
import { LoadingSpinner } from 'components/UI';
/* resources */
import { downloadPDF } from './resources';
import xml2js from 'xml2js';

const Statement = observer((props) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { userStore } = useInjectStores();
  const clientName = userStore.user.currentClient.clientName;
  const pageRef = useRef(null);
  const tableRef = useRef(null);
  const store = useLocalObservable(() => ({
    isLoadingTransaction: false,
    widgetData: null,
    printed: false,
    isLoadingDeposit: false,
    clientName: '',
    reportMonth: '',
    setPrinted(printed) {
      this.printed = printed;
    },
    get isDemo() {
      return _get(userStore,'user.demo', false);
    },
    get customer() {
      return _get(this.widgetData, '["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]');
    },
    get adjustmentTotals() {
      return _get(this.customer, "['TRANSACTION_SECTION'][0]['ADJUSTMENT_TOTALS']");
    },
    get chargebackTotals() {
      return _get(this.customer, "['TRANSACTION_SECTION'][0]['CHARGEBACK_TOTALS']");
    },
    get resFundTotals() {
      return _get(this.customer, "['TRANSACTION_SECTION'][0]['RESFUND_TOTALS']");
    },
    get depositTran() {},
    setWidgetData(data) {
      xml2js.parseString(data['stmt'], (err, result) => {
        if (err) {
          throw err;
        }
        this.widgetData = result;
      });
    },
    setClientName(name) {
      this.clientName = name;
    },
    setReportMonth(month) {
      this.reportMonth = month;
    }
  }));

  useEffect(() => {
    store.setClientName(clientName);
  }, [userStore.user]);

  useEffect(() => {
    store.setWidgetData(props.store.widgetData);
    store.setReportMonth(props.store.widgetData.prc_mnth);
  }, [props]);

  useEffect(() => {
    if (props.store.showDownloadModal && !props.store.isLoading && store.customer && !store.printed) {
      store.setPrinted(true);
      setTimeout(() => downloadPDF(store, pageRef, tableRef, setIsDownloading, props.downloadId), 500);
      props.store.setDownloadId = '';
    }
  }, [props.store.showDownloadModal, props.store.isLoading]);

  return (
    <Observer>
      {() => (
        <Wrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 30px',
            }}
          >
            <TableStmtHeader store={store} pageRef={pageRef} tableRef={tableRef} />
            <StmtHeader store={store} />
            <div
              ref={pageRef}
              id="pdf-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 30px 0 30px',
                padding: '5px 0 20px 0',
              }}
            >
              <div className="plan-summary">
                {props.store.isLoading ? (
                  <LoadingSpinner size={'200px'} />
                ) : (
                  <PlanSummary isLoading={props.store.isLoading} store={store} />
                )}
              </div>
              {/* <div
                className="deposits"
                style={{
                  margin: '20px 0',
                }}
              >
                {props.store.isLoading ? (
                  <LoadingSpinner size={'200px'} />
                ) : (
                  <Deposit tableRef={tableRef} isLoading={store.isLoading} store={store} />
                )}
                <PlanCodes />
              </div> */}
              {store.adjustmentTotals && (
                <div
                  className="adjustments"
                  style={{
                    margin: '20px 0',
                  }}
                >
                  {props.store.isLoading ? <LoadingSpinner /> : <Adjustments isLoading={store.isLoading} store={store} />}
                  <PlanCodes />
                </div>
              )}
              {store.chargebackTotals && (
                <div
                  className="chargebacks"
                  style={{
                    margin: '20px 0',
                  }}
                >
                  <Chargebacks isLoading={store.isLoading} store={store} />
                </div>
              )}
              {store.resFundTotals && (
                  <div
                      className="resFund"
                      style={{
                        margin: '20px 0',
                      }}
                  >
                    <ResFund isLoading={store.isLoading} store={store} />
                  </div>
              )}
              <div
                className="fees"
                style={{
                  margin: '20px 0',
                }}
              >
                <Fees isLoading={store.isLoading} store={store} />
                <PlanCodes />
              </div>
              <DueBox store={store} />
              <div
                className="card-brands"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <p
                  style={{
                    minWidth: '300px',
                    maxWidth: '800px',
                    fontSize: '14px',
                  }}
                >
                  {_get(store, 'widgetData[0].stmt["MERCHANT_STATEMENT_APPLICATION"]["CUSTOMER"][0]["MSG_SECTION"][0]["MSG"]')}
                </p>
              </div>
            </div>
          </div>
        </Wrapper>
      )}
    </Observer>
  );
});

export default Statement;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  .pdf-body {
  }
  .deposits {
  }
  .fees {
    margin: 20px 0;
  }
  .header {
    .top-side,
    .bottom-side {
      .left-side,
      .right-side {
      }
      .right-side {
      }
    }
    .label {
    }
    .value {
    }
  }
  .card-brands {
    p {
    }
  }
  .due {
    .items {
      .item {
      }
    }
  }
`;
