import React, {useEffect} from 'react';
import {WidgetHeader, WidgetWrapper} from 'styles/styledComponents';
import {Dropdown, Error, LoadingSpinner, Notes} from 'components/UI';
import styled from 'styled-components/macro';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {_get, _has, _set, _toNumber, useGetWidgetData, useInjectStores} from 'utils/utils';
import {AgGridReact} from 'ag-grid-react';
import {gridOptsColumnDefs} from './resources';
import {widgetService} from '../../../services';

const DeclineReasons = observer(() => {
    const {dataManagementStore} = useInjectStores();
    const store = useLocalObservable(() => ({
        selectedTopicDeclines: 1,
        selectTopicDeclines(val) {
            this.selectedTopicDeclines = val;
        },
        topicsDeclines: [
            {key: 1, text: 'Decline Reasons', value: 1},
            {key: 2, text: 'Skimmed Cards', value: 2},
        ],
        dashDecl: [],
        dashSkm: [],
        fetchDashDecl: false,
        fetchDashSkm: false,
        setDashDecl(data) {
            this.fetchDashDecl = false;
            this.dashDecl = data;
        },
        setDashSkm(data) {
            this.fetchDashSkm = false;
            this.dashSkm = data;
        },
        error: null,
        api: null,
        gridColumnApi: null,
        gridOptions: {
            rowHeight: 33,
            headerHeight: 33,
            animateRows: true,
            enableBrowserTooltips: true,
            tooltipShowDelay: 0,
            reactNext: true,
            enableCharts: true,
            enableRangeSelection: true,
            groupMultiAutoColumn: true,
            groupSuppressAutoColumn: true,
            treeData: true,
            rowClassRules: {
                secondLevel: function (params) {
                    return params.data.secondLevel;
                },
                topLevel: function (params) {
                    return params.data.topLevel;
                },
            },
            groupRemoveSingleChildren: true,
            getDataPath: function (data) {
                return data.keyPath;
            },
            getRowNodeId: function (data) {
                return data.id;
            },
            defaultColDef: {
                sortable: false,
                resizable: true,
            },
        },
        onGridReady(params) {
            this.api = params.api;
            this.gridColumnApi = params.columnApi;
        },
        // detailCellRenderer: 'myDetailCellRenderer',
        // frameworkComponents: {myDetailCellRenderer: Notes},
        detailRowHeight: 70,

        get treeDataDashDecl() {
            let rowData = [];
            if (this.dashDecl?.length) {
                let data = this.dashDecl;
                for (let i = 0; i < data.length; i++) {
                    const current = data[i];
                    rowData.push({
                        ...current,
                        keyPath: [`${current.decl_cat_cnt}${current.decl_resp}`],
                        id: i + 1,
                    });
                }
            }
            return rowData;
        },
        get treeDataDashSkm() {
            if (this.api) {
                this.api.showLoadingOverlay();
            }
            let rowData = [];
            if (this.dashSkm?.length) {
                let data = this.dashSkm;
                for (let i = 0; i < data.length; i++) {
                    const current = data[i];
                    rowData.push({
                        ...current,
                        keyPath: [`${current.skim_crd_ind}`],
                        id: i + 1,
                    });
                }
            }
            if (this.api && rowData.length === 0) {
                this.api.showNoRowsOverlay();
            }
            return rowData;
        },

        get colDefsDeclinesRowData() {
            switch (this.selectedTopicDeclines) {
                case 1:
                    return {
                        rowData: this.treeDataDashDecl,
                        columnDefs: gridOptsColumnDefs.dashDecl.columnDefs,
                    };
                case 2:
                    return {
                        rowData: this.treeDataDashSkm,
                        columnDefs: gridOptsColumnDefs.dashSkm.columnDefs,
                    };
                default:
                    return {
                        rowData: [],
                        columnDefs: {},
                    };
            }
        },
        setFetchDashDecl(fetch) {
            this.fetchDashDecl = fetch;
        },
        setFetchDashSkm(fetch) {
            this.fetchDashSkm = fetch;
        }
    }));

    const dashDecl = useGetWidgetData(
        {name: 'dashboardDecline', query: widgetService.dashboardDecline},
        'data',
        store.fetchDashDecl
    );
    const dashSkm = useGetWidgetData(
        {name: 'dashboardSkimmedCards', query: widgetService.dashboardSkimmedCards},
        'data',
        store.fetchDashSkm
    );


    useEffect(() => {
        store.setDashDecl(dashDecl.data);
    }, [dashDecl.data]);

    useEffect(() => {
        store.setDashSkm(dashSkm.data);
    }, [dashSkm.data]);

    useEffect(() => {
        store.setFetchDashDecl(true);
        store.setFetchDashSkm(true);
    }, [dataManagementStore?.newQuery]);

    return (
                <WidgetWrapper height="100%">
                    <div style={{display: 'flex', alignItems: 'center', height: 40}}>
                        <div style={{position: 'absolute', left: 5}}>
                            <Dropdown
                                height={'25px'}
                                options={store.topicsDeclines}
                                onChange={store.selectTopicDeclines}
                                placeholder=""
                                toggleDisable={store.toggleDisable}
                                value={store.selectedTopicDeclines}
                                labelWidth={'0px'}
                                width={'175px'}
                                margin={'0 2px 0 6px'}
                                border
                            />
                        </div>
                        <WidgetHeader>Declines</WidgetHeader>
                    </div>
                    <div/>

                    {/*<Divider />*/}
                    <GridWrapper>
                        {dashDecl.status === 'loading' || dashSkm.status === 'loading' ? (
                            <LoadingSpinner size={'200px'}/>
                        ) : dashDecl.status === 'error' ? (
                            <Error error={dashDecl.error}/>
                        ) : dashSkm.status === 'error' ? (
                            <Error error={dashSkm.error}/>
                        ) : (
                        <div
                            id="myGrid"
                            className={'ag-theme-alpine-dark'}
                            style={{
                                height: '100%',
                                minHeight: '300px',
                                width: '100%',
                                position: 'relative',
                                borderRadius: '5px',
                            }}
                        >
                            <AgGridReact
                                onGridReady={store.onGridReady}
                                rowData={store.colDefsDeclinesRowData.rowData}
                                columnDefs={store.colDefsDeclinesRowData.columnDefs}
                                gridOptions={store.gridOptions}
                                popupParent={document.getElementById('myGrid')}
                            />
                        </div>
                            )}
                    </GridWrapper>
                </WidgetWrapper>
    );
});

const Wrapper = styled.div`
  display: grid;
  //margin: 0 5px 0 5px;
  margin: 4px 0 0 0;
  grid-template-areas:
    'declines chargebacks'
    'avs downgrades';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 300px 300px;
  grid-gap: 35px 5px;
  padding-bottom: 20px;
  color: ${({theme}) => theme.colors.primaryText};

  .reportWrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px 5px;
  }

  .spaceBtwn {
    padding: 4px;
    margin: 0px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }

  .row {
    display: flex;
  }
`;

const GridWrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({theme}) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);

    .ag-header-row {
      height: 30px;
    }

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }

    .topLevel {
      background-color: #001329 !important;
    }

    .secondLevel {
      background-color: #032845 !important;
    }
  }

  grid-area: SsTotalsGraph;
  position: relative;
    // width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};
  .checkIcon {
    display: flex;
    position: relative;
    align-items: center;
  }

  .my-tooltip > .tooltip-inner {
    background-color: red;
  }

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

  .noData {
    margin: auto;
  }
`;

export default DeclineReasons;
