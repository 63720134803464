import React, { useEffect } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
// import VoidsInquiries from 'components/Widgets/VoidsInquiries/VoidsInquiries';
import Notes from 'components/Widgets/FeesNotes/FeesNotes';
import { useCheckAuthorizedRoute, useInjectStores } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';
// import ProcTabs from 'components/UI/Tabs/procTabs';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

const feesNotes = observer(() => {
  const { loading, authorized, error } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => (
        <>
          {/*{loading ? (*/}
          {/*  <RouteLoadingSpinner size={'400px'} />*/}
          {/*) : authorized ? (*/}
            <div style={{ height: '100%', width: '100%' }}>
              <Wrapper>
                   <Notes />
              </Wrapper>
            </div>
          {/*) : (*/}
          {/*  <div>error</div>*/}
          {/*)}*/}
        </>
      )}
    </Observer>
  );
});

export default feesNotes;

const Wrapper = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  padding-bottom: 20px;
  margin: 0 5px;
  .widget {
    margin: 0 3px;
  }

  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  
`;
