import React, { useState } from 'react';
import { Tabs } from './StatementPartials/Partials/Tabs';
/** General Styled Components **/
import { DailyBatches } from './StatementPartials/Partials/DailyBatches';
import { DailyDeposits } from './StatementPartials/Partials/DailyDeposits';
import { TsysStatement } from './StatementPartials/Partials/TsysStatement';
/** Styled Components Specific to Client Data Mgmt **/
import { Observer, observer } from 'mobx-react-lite';
import { _get, useCheckAuthorizedRoute, useInjectStores } from 'utils/utils';
import styled from 'styled-components/macro';
import { Route, useRouteMatch } from 'react-router-dom';
import { RouteLoadingSpinner } from 'components/UI';

const Statement = observer(() => {
  const { userStore } = useInjectStores();
  const { path } = useRouteMatch();
  // const [tab, setTab] = useState('transactions');
  const modules = _get(userStore, 'user.currentClient.modules', []),
    accounting = _get(modules, 'accounting');

  // const topSideNavModules = _get(userStore, 'user.views.topSideNav.modules', []);
  // const acct = _find(topSideNavModules, { collapsedName: 'Ac' });
  const hasTsysRoute = accounting.views?.tsysStatement;
  // accounting.views.tsysStatement
  const { loading, authorized } = useCheckAuthorizedRoute();

  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              {/*<Tabs setTab={setTab} tab={tab} />*/}
              <Route path={`${path}/batches`}>
                <DailyBatches />
              </Route>
              <Route path={`${path}/deposits`}>
                <DailyDeposits />
              </Route>
              {hasTsysRoute && (
                <Route path={`${path}/tsys`}>
                  <TsysStatement />
                </Route>
              )}
            </Wrapper>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

export default Statement;

const Wrapper = styled.div`
  height: 100%;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 2px;
  width: calc(100% - 8px);
  .body {
    display: flex;
    .bodyTab {
      flex: 1 1 auto;
      display: none;
      padding-left: 2px;

      &.active {
        display: block;
      }
    }
    .tabNameWrapper {
      .tabName {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        color: #fff;
        letter-spacing: 3px;
        margin-bottom: -3px;
        padding: 10px 4px;
        text-align: right;
        text-orientation: mixed;
        text-transform: uppercase;
        transform: rotate(180deg);
        writing-mode: vertical-rl;
      }
    }
  }
  .tabWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2px 5px;
    margin-bottom: 2px;
    grid-template-areas:
      'one one two two three three four four'
      'c1 e1 c2 e2 c3 e3 c4 e4';
    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }
    .three {
      grid-area: three;
    }
    .btn {
      cursor: pointer;
    }
    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }
    .tab {
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 6px 4px 4px 4px;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
`;
