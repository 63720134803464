import styled from 'styled-components';

/** ModalContainer Styles **/
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`;

/** Header Styles **/
const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Header = styled.div`
  color: white;
  font-size: 1.2em;
  text-align: center;
  width: 100%;
`;

const HeaderTitle = styled.div`
  padding: 10px;
  font-size: 24px;
`;

const HeaderInfo = styled.div`
  padding: 0 10%;
  font-size: 16px;
  text-wrap: normal;
  margin-bottom: 10px;
  span {
    text-decoration: underline;
  }
`;

/** ModalContent Container and Sides Container/Styles **/

const ModalContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Side = styled.div`
  display: flex;
  min-width: 500px;
  flex-grow: 1;
  width: 50%;
  flex-direction: column;
`;

const TotalsWrapper = styled.div`
  height: 25vh;
  margin: 3px 4px;
`;

export { ModalContainer, HeaderWrapper, Header, HeaderTitle, HeaderInfo, ModalContentContainer, Side, TotalsWrapper };
