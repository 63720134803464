import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  outline: none;
  background-color: #fff;
  align-items: center;
  overflow-x: hidden;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  margin: ${({ margin }) => margin || ''};
  width: ${(props) => props.height || '100%'};
  height: ${(props) => props.height || 'auto'};
  color: ${({ error, theme }) => (error ? 'red' : theme.primaryColor)};
  input {
    border: none;
    font-size: 14px;
    height: 33px;
    outline: none;
    border-radius: 5px;
    text-indent: 9px;
    color: ${({ theme }) => theme.primaryColor || 'black'};
    &:focus {
      //outline: ${({ border, theme }) => (border ? theme.focusInputBorder : '')};
    }

    width: ${(props) => {
      if (props.type !== 'checkbox') {
        return props.width ? `calc(${props.width} - 4px)` : 'calc(100% - 4px)';
      } else return '10px';
    }};

    ::placeholder {
      color: ${(props) => (props.error ? 'red' : props.theme.primaryColor)};
    }
  }
`;

const IconInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  outline: none;
  background-color: #fff;
  align-items: center;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: ${({ boxShadow }) => boxShadow};
  &:hover {
    ${({ theme }) => theme.focusInputBorder};
  }
  border: ${({ focus }) => (focus ? '1px solid #4183C4' : '1px solid #e2e2e2;')};
  margin: ${(props) => {
    if (props.margin && !(props.marginLeft || props.marginRight || props.marginTop || props.marginBottom))
      return `${props.margin}`;
    else if (props.marginLeft || props.marginRight || props.marginTop || props.marginBottom) {
      let marginTop = props.marginTop || '0';
      let marginRight = props.marginRight || '0';
      let marginBottom = props.marginBottom || '0';
      let marginLeft = props.marginLeft || '0';
      return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
    } else return '0';
  }};
  width: ${(props) => {
    if (props.type !== 'checkbox') {
      return props.width || '100%';
    } else return '10px';
  }};
  color: ${({ error, theme }) => (error ? 'red' : theme.primaryColor)};
  &:hover {
    cursor: pointer;
  }

  input {
    border: none;
    font-size: 14px;
    //padding-left: 9px;
    height: 33px;
    outline: none;
    color: ${({ theme }) => theme.primaryColor};
    border-radius: 5px;
    text-indent: 9px;
    width: ${(props) => {
      if (props.type !== 'checkbox') {
        return props.width ? `calc(${props.width} - 4px)` : 'calc(100% - 4px)';
      } else return '10px';
    }};
    &:hover {
      ${({ theme }) => theme.focusInputBorder};
    }
    ::placeholder {
      color: ${(props) => (props.error ? 'red' : props.theme.primaryColor)};
    }
  }
`;

export { IconInputContainer, InputContainer };
