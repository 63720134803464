import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
const { array, number, maybe } = types;

const ChargebackFilter = types
  .model({
    txnStatusIDL: maybe(number),
    chgbkCodeID: maybe(array(number))
  })
  .volatile(self => ({
    initalState: {},
    txnStatusIDLError: false,
    chgbkCodeIDError: false
  }))
  .actions(self => ({
    afterCreate() {
      self.initialState = getSnapshot(self);
    },
    setModel(name, value) {
      self[`${name}Error`] = false;
      self[name] = value;
    },
    reset(name, value) {
      self[name] = value;
    },
    setError(name) {
      self[name] = true;
    },
    resetErrors() {
      self.txnStatusIDLError = false;
      self.chgbkCodeIDError = false;
    },
    resetState() {
      applySnapshot(self, self.initialState);
    }
  }));

export default ChargebackFilter;
