import React from 'react';
import styled from 'styled-components/macro';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

const Tabs = () => {
    const { url } = useRouteMatch();
    const location = useLocation();
    return (
        <div className="tabWrapper">
            <Link className={`tab one ${location.pathname.includes('journals') && !location.pathname.includes('cash') ? 'active' : ''}`}  to={`${url}`}>
                Accrual
            </Link>
            <Link className={`tab two ${location.pathname.includes('cash') ? 'active' : ''}`}  to={`${url}/cash`}>
               Cash
            </Link>
        </div>
    );
};

export default Tabs;

const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  padding: 0 10px;
  .note {
    color: red;
  }
  .noteBody {
    font-style: italic;
    color: ${({ theme }) => theme.baseColors.whiteAlpha8};
  }
`;
