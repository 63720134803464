import React from 'react';
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {Route} from "react-router-dom";
import AllReports from '../AllReports/AllReports';
import MyReports from '../MyReports/MyReports';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

const Report = observer(() => {
	const { url } = useRouteMatch();
    const location = useLocation();
    return (
        <Observer>
            {() => (
                <Wrapper>
                    {/* <div className="tabWrapper">
                      <Link className={`tab one ${location.pathname.includes('my-reports') ? 'active' : ''}`}  to={`${url}`}>
                        My Reports
                      </Link>
                      <Link className={`tab two ${location.pathname.includes('all-reports') ? 'active' : ''}`}  to={`${url}/all-reports`}>
                        Available Reports
                      </Link>
                    </div> */}
                    <Route exact path={`/report/my-reports`}>
                        <MyReports />
                    </Route>
                    {/* <Route exact path={`/report/all-reports`}>
						          <AllReports />
                    </Route> */}
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .middle {
    grid-area: middle
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas: 
          'tabs'
          'middle'
          'note';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  //grid-gap: 5px;
  height: 100%;

  
  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    //height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1245px) {
    grid-template-areas:
      'tabs'
      'txn'
      'fees'
      'deposits'
      'note';
    grid-template-columns: 1fr;
  }
`;


const Wrapper1 = styled.div`
  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .cash {
    grid-area: cash;
    height: 100%;
  }

  .deposits {
    grid-area: deposits;
    height: 100%;
  }

  .txn {
    grid-area: txn;
    height: 100%;
  }

  .fees {
    grid-area: fees;
    height: 100%;
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas: 
          'tabs tabs tabs'
          'txn fees deposits'
          'note note note';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 5px;
  height: 100%;

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    //height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1245px) {
    grid-template-areas:
      'tabs'
      'txn'
      'fees'
      'deposits'
      'note';
    grid-template-columns: 1fr;
  }
`;

const Wrapper2 = styled.div`
  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .cash {
    grid-area: cash;
    height: 100%;
  }

  .deposits {
    grid-area: deposits;
    height: 100%;
  }

  .txn {
    grid-area: txn;
    height: 100%;
  }

  .fees {
    grid-area: fees;
    height: 100%;
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas: 
          'tabs tabs tabs'
          'txn fees fees'
          'note note note';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 5px;
  height: 100%;

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    //height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1245px) {
    grid-template-areas:
      'tabs'
      'txn'
      'fees'
      'deposits'
      'note';
    grid-template-columns: 1fr;
  }
`;

export default Report;
