import React from 'react';
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {useGetWidgetData, useInjectStores} from 'utils/utils';
import {widgetService} from 'services';
import {Route} from "react-router-dom";
import AccrualTxns from './Accrual';
import AccrualFees from './AccrualFees';
import Deposits from './Deposits';
import CashWidget from './Cash';
import CashFees from './CashNotes';
import PleaseNote from "../WidgetComponentsOther/PleaseNote/PleaseNote";
import Tabs from "./Tabs";

const JournalWidget = observer(() => {
    const {utilsStore, dataManagementStore} = useInjectStores();
    const store = useLocalObservable(() => ({
        exportOptions: [
            {key: 1, text: 'EXCEL', value: 'excel'},
            {key: 2, text: 'CSV', value: 'csv'},
        ],
        toCurrency: utilsStore.toCurrency,
        toCurrencyFormatter: utilsStore.toCurrencyFormatter,
        toCurrencyFormatterWithUndefined: utilsStore.toCurrencyFormatterWithUndefined,
        cellStyleNeg: (params) => {
            let style = {}

            if (params.value && params.value < 0) {
                style['color'] = '#FF2B49';
            }
            if (params.node.lastChild) {
                return {
                    ...style,
                    fontWeight: 'bold',
                    borderTop: `1px solid rgba(255,255,255,.5)`,
                    backgroundColor: ' #041c2f'
                }
            }
        }
    }));

    const {status: statusTxns, data: dataTxns, error: errorTxns} = useGetWidgetData(
        {name: 'acctTxns', query: widgetService.acctTxns},
        'data',
        dataManagementStore?.newQuery
    );

    const {status: statusTxnFees, data: dataTxnFees, error: errorTxnFees} = useGetWidgetData(
        {name: 'acctTxnFees', query: widgetService.acctTxnsFees},
        'data',
        dataManagementStore?.newQuery
    );

    const {status: statusDeposits, data: dataDeposits, error: errorDeposits} = useGetWidgetData(
        {name: 'acctDeposits', query: widgetService.acctDeposits},
        'data',
        dataManagementStore?.newQuery
    );

    const {status: statusCash, data: dataCash, error: errorCash} = useGetWidgetData(
        {name: 'acctCash', query: widgetService.acctCash},
        'data',
        dataManagementStore?.newQuery
    );

    return (
        <Observer>
            {() => (
                <Wrapper>
                    <div className="tabs">
                        <Tabs/>
                    </div>
                    <Route exact path={`/accounting/journals`}>
                        <Wrapper1>
                            <div className="txn">
                                <AccrualTxns status={statusTxns} data={dataTxns} error={errorTxns} store={store}/>
                            </div>
                            <div className="fees">
                                <AccrualFees status={statusTxnFees} data={dataTxnFees} error={errorTxnFees}
                                             store={store}/>
                            </div>
                            <div className="deposits">
                                <Deposits status={statusDeposits} data={dataDeposits} error={errorDeposits}
                                          store={store}/>
                            </div>

                        </Wrapper1>
                    </Route>
                    <Route exact path={`/accounting/journals/cash`}>
                        <Wrapper2>
                            <div className="txn">
                                <CashWidget status={statusCash} data={dataCash} error={errorCash} store={store}/>
                            </div>
                            <div className="fees">
                                <CashFees status={statusTxns} data={dataTxns} error={errorTxns} store={store}/>
                            </div>
                        </Wrapper2>
                    </Route>
                    <div className="note">
                        <PleaseNote/>
                    </div>
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .middle {
    grid-area: middle
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas: 
          'tabs'
          'middle'
          'note';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  //grid-gap: 5px;
  height: 100%;

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    //height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1245px) {
    grid-template-areas:
      'tabs'
      'txn'
      'fees'
      'deposits'
      'note';
    grid-template-columns: 1fr;
  }
`;


const Wrapper1 = styled.div`
  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .cash {
    grid-area: cash;
    height: 100%;
  }

  .deposits {
    grid-area: deposits;
    height: 100%;
  }

  .txn {
    grid-area: txn;
    height: 100%;
  }

  .fees {
    grid-area: fees;
    height: 100%;
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas: 
          'tabs tabs tabs'
          'txn fees deposits'
          'note note note';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 5px;
  height: 100%;

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    //height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1245px) {
    grid-template-areas:
      'tabs'
      'txn'
      'fees'
      'deposits'
      'note';
    grid-template-columns: 1fr;
  }
`;

const Wrapper2 = styled.div`
  .tabs {
    grid-area: tabs;
    height: 100%;
  }

  .cash {
    grid-area: cash;
    height: 100%;
  }

  .deposits {
    grid-area: deposits;
    height: 100%;
  }

  .txn {
    grid-area: txn;
    height: 100%;
  }

  .fees {
    grid-area: fees;
    height: 100%;
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas: 
          'tabs tabs tabs'
          'txn fees fees'
          'note note note';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 5px;
  height: 100%;

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    //height: 100%;

    grid-template-areas: 'one two three';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &:hover {
          // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }

  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 1245px) {
    grid-template-areas:
      'tabs'
      'txn'
      'fees'
      'deposits'
      'note';
    grid-template-columns: 1fr;
  }
`;

export default JournalWidget;
