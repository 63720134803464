import { makeAutoObservable } from "mobx";
import { adminService } from "services";
import UserData from '../pages/admin/ManageUsersPage/UserManager/UserData';
import UserView from '../pages/admin/ManageUsersPage/UserManager/UserView';

class AdminStore {
	users = [];
	error = null;
	userToEdit = {};
	userObj = {};
	merchToEdit = null;
	authenticatedUser = {}

	constructor() {
		makeAutoObservable(this);
	}

	setMerchToEdit = (merchId) => {
		this.merchToEdit = merchId;
	};

	setAuthenticatedUser = (user) => {
		this.authenticatedUser = user;
	}

	fetchUsers = async (clientID) => {
		try {
			const response = await adminService.adminUserSettings({clientID});
			this.users = response.data.values;
		} catch (error) {
			this.error = error;
		}
	}

	validateEmail = async ({clientID, checkEmail}) => {
		try {
			const response = await adminService.adminUserForm({clientID, checkEmail: checkEmail.toLowerCase()});
			if(!response.data.values[0].users_name) {
				return true;
			}else {
				return false;
			}
		} catch (error) {
			this.error = error;
		}
	}

	getUserTemplate = async ({clientID, checkEmail}) => {
		try {
			const response = await adminService.adminUserForm({clientID, checkEmail});
			const pricingResponse = await adminService.adminGetPricing({clientID});
			const user = response.data.values[0];
			user.users_name = '';
			user.user_role = '';
			user.user_cell_ph = '';
			user.password = '';
			user.user_email = '';
			user.currentReports = pricingResponse.data.current_reports;
			user.allReports = pricingResponse.data.reports;
			this.userObj = new UserView(user, this.authenticatedUser);
			this.userObj.clientID = clientID;
		} catch (error) {
			this.error = error;
		}
	}

	getUserByID = async ({clientID, userEmail}) => {
		try {
			const response = await adminService.adminUserEdit({clientID, userEmail});	
			const pricingResponse = await adminService.adminGetPricing({clientID});
			const user = response.data.values[0];
			user.currentReports = pricingResponse.data.current_reports;	
			user.allReports = pricingResponse.data.reports;
			this.userObj = new UserView(user, this.authenticatedUser);
			this.userObj.clientID = clientID;
		} catch (error) {
			this.error = error;
		}
	}

	setUserToEdit = (username) => {
		this.userToEdit = this.users.find(user => user.username === username);
	};

	updateUserPermissions = (permission, checked, isParent) => {
		let groupUpdated = null;
		this.userObj.permissions = this.userObj.permissions.map((permissionObj, index) => {
			if (isParent) {
				return {
					...permissionObj,
					parent: permissionObj.parent.name === permission 
						? {...permissionObj.parent, checked: checked} 
						: permissionObj.parent,
					group: permissionObj.parent.name === permission 
					? permissionObj.group.map(groupObj => {
						return {...groupObj, checked: checked};
					})
					: permissionObj.group
				};
			} else {
				return {
					...permissionObj,
					group: permissionObj.group.map(groupObj => {
						if(groupObj.name === permission){
							groupUpdated = index;
							return {...groupObj, checked: checked};
						}else {
							return groupObj;
						}
					})
				};
			}
		});

		if(groupUpdated !== null) {
			// Check if the all children are unchecked or checked marking the parent accordingly
			const allUnchecked = this.userObj.permissions[groupUpdated].group.every(groupObj => groupObj.checked === false);
			const allChecked = this.userObj.permissions[groupUpdated].group.every(groupObj => groupObj.checked === true);
			
			if(allUnchecked) {
				this.userObj.permissions[groupUpdated].parent.checked = false;
			}

			if(allChecked) {
				this.userObj.permissions[groupUpdated].parent.checked = true;
			}
		}

	}

	updateUserValues = (key, value) => {
		this.userObj = {...this.userObj, [key]: value};
	}

	updateUser = async () => { 
		let flatArr = [];
		this.userObj.permissions.forEach(permissionObj => {
			flatArr.push(permissionObj.parent);
			flatArr = flatArr.concat(permissionObj.group);
		});

		const tmp = {...this.userObj};

		tmp.permissions = flatArr;

		const formattedUser = new UserData(tmp);
		
		const params = new URLSearchParams(formattedUser).toString();

		try {
			const response = await adminService.adminUserUpdateInfo(params);
			await this.getUserByID({clientID: formattedUser.clientID, userEmail: formattedUser.usersEmail});
			return response;
		} catch (error) {
			this.error = error;
			return error;
		}
	}

	deleteUser = async (clientID, userEmail) => { 
		try {
			const params = new URLSearchParams({clientID,  userEmail}).toString();
			const response = await adminService.adminDeactivateUser(params);
			return response;
		} catch (error) {
			this.error = error;
			return error;
		}
	}

	createUser = async () => { 
		let flatArr = [];
		this.userObj.permissions.forEach(permissionObj => {
			flatArr.push(permissionObj.parent);
			flatArr = flatArr.concat(permissionObj.group);
		});

		const tmp = {...this.userObj};
		tmp.permissions = flatArr;
		const formattedUser = new UserData(tmp);
		formattedUser.userEmail = formattedUser.usersEmail;
		formattedUser.userPassword = tmp.password;
		formattedUser.passwordLastUpdDt = new Date().toISOString().slice(0, 10);
		formattedUser.userAdminTf = tmp.adminRights;
		formattedUser.userCreatedBy = this.authenticatedUser.email;

		const params = new URLSearchParams(formattedUser).toString();
		
		try {
			const response = await adminService.adminUserCreate(params);
			return response;
		} catch (error) {
			this.error = error;
			return error;
		}
	}

	updatePricing = async (packageData) => {
		try {
			const pricingObj = {package: packageData.newPackage, clientID: packageData.clientId, reports: packageData.reports}
			if(packageData.newUpgrades.length) {
				packageData.newUpgrades.forEach(upgrade => {
					switch(upgrade.name) {
						case 'Averages':
							pricingObj.averages = '';
							break;
						case 'Trends':
							pricingObj.trends = '';
							break;
						case 'Journals: Accrual':
							pricingObj.accrual = '';
							break;
						case 'Journals: Cash':
							pricingObj.cash = '';
							break;
						case 'Daily Batches':
							pricingObj.batches = '';
							break;
						case 'Daily Deposits':
							pricingObj.deposits = '';
							break;
						case 'Prepaid Billing':
							pricingObj.prepaid = '';
							break;
						case '1099-K':
							pricingObj.tax = '';
							break;
						case 'Fees by Category':
							pricingObj.fees = '';
							break;
						case 'Opportunity Details':
							pricingObj.opportunityDetails = '';
							break;
						case 'ala_crt_prc_reps_1':
							pricingObj.report1 = '';
							break;
						case 'ala_crt_prc_reps_3':
							pricingObj.report3 = '';
							break;
						case 'ala_crt_prc_reps_5':
							pricingObj.report5 = '';
							break;
						case 'ala_crt_prc_reps_all':
							pricingObj.reportAll = '';
							break;
						case '(Customer info & analytics)':
							pricingObj.fkyc = '';
							break;
						case '(to stay complaint with federal and state laws.)':
							pricingObj.archive = '';
							break;
					}
					
				});
			}
			const params = new URLSearchParams(pricingObj).toString();
			const response = await adminService.adminUpdatePricing(params);
			return [];
		}catch(e) {
			this.error = e;
			return e;
		}
	}

	getMerchantSettings = async (clientID) => {
		try {
			const response = await adminService.adminGetMerchSettings({clientID})
			return response.data.values;
		}catch(e) {
			this.error = e;
			return e;
		}
	}

	getMerchantAdd = async (clientID) => {
		try {
			const response = await adminService.adminGetMerchAdd({clientID})
			return response.data.values;
		}catch(e) {
			this.error = e;
			return e;
		}
	}

	getMerchEdit = async (clientID, mid) => {
		try {
			const response = await adminService.adminGetMerchEdit({mid, clientID});
			return response.data.values[0];
		}catch(e) {
			this.error = e;
			return e;
		}
	}

	editMerchant = async (formattedData) => {
		try {
			const params = new URLSearchParams(formattedData).toString();
			const response = await adminService.adminMerchUpdate(params);
			if(response.status === 200) {
				return true;
			}else {
				return false;
			}
		}catch (e) {
			this.error = e;
			return false;
		}
	}

	createMerchant = async (formattedData) => {
		try {
			const params = new URLSearchParams(formattedData).toString();
			const response = await adminService.adminMerchCreate(params);
			if(response.status === 200) {
				return true;
			}else {
				return false;
			}
		}catch (e) {
			this.error = e;
			return false;
		}
	}

	getAbbreviations = async (clientID) => {
		try {
			const response = await adminService.adminGetAbbreviations({clientID});
			return response.data.values;
		}catch(e) {
			this.error = e;
			return e;
		}
	}

	updateUserPassword = async (clientID, userEmail, password) => {
		try {
			const response = await adminService.adminUpdateUserPassword({clientID, userEmail, password});
			return response;
		}catch(e) {
			this.error = e;
			return e;
		}
	}

}

export const adminStore = new AdminStore();