import styled, { css } from 'styled-components';

const blue = '#3fa9f5';
const green = '#45e885';
const greyLight = '#f0f0f0';

const headerStyles = css`
  align-items: center;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  padding: 6px 0px;
  text-align: center;
  width: 100%;
`;

const ModalTable = styled.table`
  width: 100%;
`;

const ModalTableContainer = styled.div`
  background-color: white;
  height: 100%;
  overflow-y: scroll;
  padding: 5px 5px 0px 5px;
`;

const NoShipTableHeader = styled.div`
  ${headerStyles};
  color: ${({ theme }) => theme.colors.errorWarning};
  .close {
    cursor: pointer;
  }
`;

const ShipTableHeader = styled.div`
  ${headerStyles};
  color: ${green};
`;

const ModalTHead = styled.thead`
  background-color: #f0f0f0;
`;

const ChevronTH = styled.th`
  width: 20px;
`;

const ModalTBody = styled.tbody``;

const TxnTypeTR = styled.tr`
  td {
    background-color: ${blue};
  }
`;

const CategoryTR = styled.tr`
  td {
    background-color: ${greyLight};
  }
`;

const ReasonTR = styled.tr`
  &:hover {
    cursor: pointer;
  }
  td {
    background-color: white;
  }
`;

const TableTotalsTR = styled.tr`
  td {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

export {
  ModalTable,
  ModalTableContainer,
  NoShipTableHeader,
  ShipTableHeader,
  ModalTHead,
  ChevronTH,
  TxnTypeTR,
  CategoryTR,
  ReasonTR,
  TableTotalsTR,
};
