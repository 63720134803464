import styled from 'styled-components/macro';

const DropdownWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin: ${({ margin }) => margin};
  border-right: ${({ show, theme }) => (show ? theme.focusInputBorder : theme.dropdownBorder)};
  border-left: ${({ show, theme }) => (show ? theme.focusInputBorder : theme.dropdownBorder)};
  border-bottom: ${({ show, theme }) => {
    return show ? theme.focusInputBorder : theme.dropdownBorder;
  }};
  border-top: ${({ show, theme }) => {
    return show ? theme.focusInputBorder : theme.dropdownBorder;
  }};
  height: ${({ height }) => height || 'auto'};
  border-bottom-left-radius: ${({ show }) => (show ? '0px' : '5px')};
  border-bottom-right-radius: ${({ show }) => (show ? '0px' : '5px')};
  background: #fff;
  tab-index: ${({ tabIndex }) => tabIndex};
  outline: none;
  &:focus {
    border: ${({ theme }) => theme.focusInputBorder};
  }
  &:hover {
    border-color: ${({ theme }) => theme.baseColors.blueFocusBorder};
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%'};
  flex-direction: column;
  height: auto;
  border-top-left-radius: ${({ show, flipDropdown }) => {
    if (!show) return '5px';
    else return flipDropdown ? '0px' : '5px';
  }};
  border-top-right-radius: ${({ show, flipDropdown }) => {
    if (!show) return '5px';
    else return flipDropdown ? '0px' : '5px';
  }};
  border-bottom-left-radius: ${({ show, flipDropdown }) => {
    if (!show) return '5px';
    else return flipDropdown ? '5px' : '0px';
  }};
  border-bottom-right-radius: ${({ show, flipDropdown }) => {
    if (!show) return '5px';
    else return flipDropdown ? '5px' : '0px';
  }};

  background-color: #fff;
  overflow-x: hidden;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  i {
    border: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  align-items: center;
  background-color: white;
  color: ${(props) => (props.error ? 'red' : props.theme.primaryColor)};
  display: flex;
  height: ${(props) => (props.height ? props.height : '35px')};
  overflow: hidden;
  padding-left: 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
`;

const OptionsContainer = styled.div`
  position: absolute;
  width: ${({ width, border }) => (border ? width || '100%' : width ? `calc(${width} - 2px)` : `calc(100% - 2px)`)};

  left: ${({ left, border }) => (border ? left || ' -1px' : left || '0px')};
  top: ${({ flipDropdown, height }) => (flipDropdown ? '' : height || '25px')};
  bottom: ${({ flipDropdown, height }) => (flipDropdown ? height || '25px' : '')};

  color: ${({ error, theme }) => (error ? '#9f3a38' : theme.baseColors.colorGreyDark)};
  z-index: 5;
  overflow-y: auto;

  border-top-left-radius: ${({ flipDropdown }) => (flipDropdown ? '5px' : '0px')};
  border-top-right-radius: ${({ flipDropdown }) => (flipDropdown ? '5px' : '0px')};
  border-bottom-left-radius: ${({ flipDropdown }) => (flipDropdown ? '0px' : '5px')};
  border-bottom-right-radius: ${({ flipDropdown }) => (flipDropdown ? '0px' : '5px')};

  border-right: ${({ show, theme }) => (show ? theme.focusInputBorder : theme.dropdownBorder)};
  border-left: ${({ show, theme }) => (show ? theme.focusInputBorder : theme.dropdownBorder)};
  border-bottom: ${({ flipDropdown, show, theme }) => {
    if (show && flipDropdown) {
      return theme.dropdownBorder;
    }
    return theme.focusInputBorder;
  }};
  border-top: ${({ flipDropdown, show, theme }) => {
    if (show && flipDropdown) {
      return theme.focusInputBorder;
    }
    return theme.dropdownBorder;
  }};
  max-height: ${({ maxHeight }) => maxHeight || '260px'};
  cursor: ${({ cursor }) => cursor || 'pointer'};
`;

const DropdownOption = styled.div`
  height: 35px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  background: white;
  text-align: center;
  align-items: center;
  border-top: 1px solid #fafafa;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  padding: ${(props) => (props.padding ? props.padding : '9px')};

  div {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    height: auto;
    min-height: 35px;
    background-color: ${(props) => (props.disabled ? 'white' : '#f8f8f8;')};
    div {
      white-space: normal;
    }
  }
`;

export { DropdownWrapper, DropdownContainer, DropdownOption, PlaceholderContainer, OptionsContainer, Placeholder };
