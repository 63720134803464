import React, { useEffect, useState, useCallback } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import TableHeader from '../../UI/Table/TableHeader/TableHeader';
import { Notes, Table, Error, LoadingSpinner, Dropdown } from 'components/UI';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores, buildColDef } from 'utils/utils';
import { widgetService } from 'services';
import {AgGridReact} from 'ag-grid-react';
import Moment from "moment/moment";
import {getFileName} from 'utils/utils';

const IsoProfitLoss = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel;
  const paginatorModel = widgetModel.paginatorModel;

	const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
	const toDateFormatter = utilsStore.toDateFormatter;

  function cellStyle(params) {
    if (params.value < 0) {
      return { color: '#FF2B49' };
    }
  }

  const initialData = { txns: [], count: 0 };

  function transformAndCombineData(summary) {
    const initialRowData = [];
    let idCounter = 1;
  
    const merchants = [...new Set(summary.map(item => item.merchant))];
    
    merchants.forEach(merchant => {
      const zTotals = summary.find(item => item.fee_cat_desc === "Z-TOTALS" && item.merchant === merchant);
      if (zTotals) {
        const feeCategories = [...new Set(summary.filter(item => item.merchant === merchant).map(item => item.fee_cat_desc).filter(desc => desc !== "Z-TOTALS"))];
        const rootId = idCounter;
        initialRowData.push({
          id: idCounter++,
          level: 0,
          parentId: null,
          isExpanded: false,
          hasChildren: feeCategories.length,
          isVisible: true,
          ...zTotals,
          fee_cat_desc: "TOTALS",
          card_brnd: ""
        });       
  
        feeCategories.forEach(feeCategory => {
          const categoryTotalId = idCounter;
          const categoryEntries = summary.filter(item => item.merchant === merchant && item.fee_cat_desc === feeCategory);
          let categoryTotal = {
            id: idCounter++,
            level: 1,
            parentId: rootId,
            isExpanded: false,
            isVisible: false,
            fee_cat_desc: feeCategory,
            card_brnd: "",
            hasChildren: categoryEntries[0].card_brnd !== "",
            monthly_income: 0,
            monthly_expense: 0,
            monthly_profit: 0
          };

          categoryEntries.forEach(entry => {
            categoryTotal.monthly_income += parseFloat(entry.monthly_income);
            categoryTotal.monthly_expense += parseFloat(entry.monthly_expense);
            categoryTotal.monthly_profit += parseFloat(entry.monthly_profit);
  
            initialRowData.push({
              id: idCounter++,
              level: 2,
              parentId: categoryTotalId,
              isExpanded: false,
              isVisible: false,
              card_brnd: entry.card_brnd,
              monthly_income: entry.monthly_income,
              monthly_expense: entry.monthly_expense,
              monthly_profit: entry.monthly_profit,
              surchg_model: entry.surchg_model,
              flat_rt_model: entry.flat_rt_model,
              tier_rt_model: entry.tier_rt_model,
              passthru_crd_brnd: entry.passthru_crd_brnd,
              passthru_intchg: entry.passthru_intchg
            });
          });
  
          categoryTotal.monthly_income = categoryTotal.monthly_income.toFixed(2);
          categoryTotal.monthly_expense = categoryTotal.monthly_expense.toFixed(2);
          categoryTotal.monthly_profit = categoryTotal.monthly_profit.toFixed(2);
  
          initialRowData.push(categoryTotal);
        });
      }
    });
  
    return initialRowData.sort((a, b) => a.id - b.id);
  }

  const store = useLocalStore(() => ({
    fileName: 'profitLoss',
    storageKey: 'profit-loss-report',
    loading: false,
    error: null,
    columnDefs: [
      { field: 'eom_date', headerName: 'EOM Date', valueFormatter: toDateFormatter, width: 110, headerTooltip: 'EOM Date' },
      { field: 'procg_platform', headerName: 'Processing Platform', width: 170, headerTooltip: 'Processing Platform' },
      { field: 'sponsor_bank', headerName: 'Sponsor Bank', width: 155, headerTooltip: 'Sponsor Bank' },
      { 
        field: 'mid', 
        headerName: 'MID', 
        width: 190, 
        headerTooltip: 'MID',  
        cellRenderer: (params) => {
          const isExpanded = params.data.isExpanded;
          if(params.data.level > 0) return params.value;
          return `
          <span class="ag-cell-wrapper ag-row-group ag-row-group-indent-0">
              <span class="ag-group-expanded ${isExpanded ? '' : 'ag-hidden'}" ref="eExpanded"><span class="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span></span>
              <span class="ag-group-contracted ${isExpanded ? 'ag-hidden' : ''}" ref="eContracted"><span class="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span></span>
              <span class="ag-group-checkbox ag-invisible" ref="eCheckbox"></span>
              <span class="ag-group-value" ref="eValue">${params.value}</span>
              <span class="ag-group-child-count" ref="eChildCount"></span>
          </span>`;
        }
      },
      { field: 'merchant', headerName: 'Merchant', width: 215, headerTooltip: 'Merchant'},
      { field: 'fee_cat_desc', 
        headerName: 'Fee Category', 
        width: 185,
        headerTooltip: 'Fee Category Description',
        cellRenderer: (params) => {
          const isExpanded = params.data.isExpanded;
          if(!params.value) return '';
          if(params.data.level === 0 || !params.data.hasChildren) return `<span style="padding-left: 27px;">${params.value}</span>`;
          return `
          <span class="ag-cell-wrapper ag-row-group">
              <span class="ag-group-expanded ${isExpanded ? '' : 'ag-hidden'}" ref="eExpanded"><span class="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span></span>
              <span class="ag-group-contracted ${isExpanded ? 'ag-hidden' : ''}" ref="eContracted"><span class="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span></span>
              <span class="ag-group-checkbox ag-invisible" ref="eCheckbox"></span>
              <span class="ag-group-value" ref="eValue">${params.value}</span>
              <span class="ag-group-child-count" ref="eChildCount"></span>
          </span>`;
        }
      },
      { field: 'card_brnd', headerName: 'Card Brand', width: 110, cellStyle: {'text-align': 'center'}, headerTooltip: 'Card Brand' },
      { field: 'monthly_income', headerName: 'Monthly Income', width: 150, headerTooltip: 'Monthly Income', valueFormatter: toCurrencyFormatter, type: 'rightAligned', cellStyle: cellStyle },
      { field: 'monthly_expense', headerName: 'Monthly Expense', width: 150, headerTooltip: 'Monthly Expense', valueFormatter: toCurrencyFormatter, type: 'rightAligned', cellStyle: cellStyle },
      { field: 'monthly_profit', headerName: 'Monthly Profit', width: 150, headerTooltip: 'Monthly Profit', valueFormatter: toCurrencyFormatter, type: 'rightAligned', cellStyle: cellStyle },
      { field: 'surchg_model', headerName: 'Surcharge Model T/F', width: 150, headerTooltip: 'Surcharge Model T/F', cellStyle: {'text-align': 'center'} },
      { field: 'flat_rt_model', headerName: 'Flat Rate Model T/F', width: 150, headerTooltip: 'Flat Rate Model T/F', cellStyle: {'text-align': 'center'} },
      { field: 'tier_rt_model', headerName: 'Tier Rate Model T/F', width: 150, headerTooltip: 'Tier Rate Model T/F', cellStyle: {'text-align': 'center'} },
      { field: 'passthru_crd_brnd', headerName: 'Passthru Card Brand T/F', width: 150, headerTooltip: 'Passthru Card Brand T/F', cellStyle: {'text-align': 'center'} },
      { field: 'passthru_intchg', headerName: 'Passthru Intchg', width: 150, headerTooltip: 'Passthru Intchg T/F', cellStyle: {'text-align': 'center'} }
    ],
    widgetData: initialData,
    rowData: [],
    txnCount: 0,
    async getData() {
      store.loading = true;
      widgetModel.setIsHistoric(true);
      try {
        const profitLossSummary = await widgetModel.fetchWidgetData(widgetService.isoProfitLossMonthly);
        
        store.widgetData = profitLossSummary.data;

        const transformedData = transformAndCombineData(profitLossSummary.data.values);
        store.fileName = getFileName(userStore, profitLossSummary?.config, 'profitLoss');
        store.rowData = transformedData;
        store.txnCount = store.widgetData.count || store.widgetData.values.length;
      } catch (error) {
        if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
          userStore.logout(history, location);
        }
      }
      store.loading = false;
      widgetModel.setIsHistoric(false);
    },
    dataManagementStore,
    widgetModel,
    paginatorModel,
    api: null,
    columnApi: null,
    saveColumnState() {
      let savedState = store.columnApi.getColumnState();
      savedState = JSON.stringify(savedState);
      if (savedState) {
        window.localStorage.setItem('txn-details-state', savedState);
      }
    },
    setColumnState() {
      let savedState = window.localStorage.getItem('txn-details-state');
      savedState = JSON.parse(savedState);
      if (savedState && store.columnApi) {
        store.columnApi.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    resetColumnState() {
      window.localStorage.removeItem('txn-details-state');
      store.columnApi.resetColumnState();
    },
    get dropdownOptions() {
      if (this.widgetData?.history?.length > 0) {
          return this.widgetData?.history?.map(({fee_dt_eom}, index) => {
              return {
                  key: index,
                  text: `${Moment(fee_dt_eom).format('YYYY')}: ${Moment(fee_dt_eom).format('MMMM')}`,
                  value: fee_dt_eom
              };
          });
      }
      return [];
    },
    selectOption(val) {
      this.selectedOption = val;
      widgetModel.setDate(val);
      this.getData();
    },
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        rowSelection: 'multiple',
        rowHeight: 33,
        headerHeight: 33,
        sideBar: {
          position: 'left',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
        },
        defaultColDef: {
          filter: true,
          resizable: true,
          suppressMenu: true
        },
        columnDefs: store.columnDefs,
        onGridReady(params) {
          store.api = params.api;
          store.columnApi = params.columnApi;
          store.setColumnState();
        },
        onFirstDataRendered(params) {
          setTimeout(params.columnApi.autoSizeColumns(['details', 'Index']));
        },
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
  }));

  useEffect(
    () =>
      autorun(async () => {
        if (dataManagementStore.newQuery) {
          paginatorModel.setPageNumber(1);
          paginatorModel.setTxnLimit(20);
          await store.getData();
        }
      }),
    []
  );

  const onCellClicked = useCallback((params) => {
    if (params.data.level < 2 && params.data.hasChildren) {
      const isExpanded = !params.data.isExpanded;
      const updateVisibility = (data, parentId, isVisible) => {
        return data.map(row => {
          if (row.parentId === parentId) {
            row.isVisible = isVisible;
            if (!isVisible) {
              row.isExpanded = false;
              updateVisibility(data, row.id, false);
            }
          }
          return row;
        });
      };

      const expandedRows = store.rowData.map(row => {
        if (row.id === params.data.id) {
          row.isExpanded = isExpanded;
        }
        return row;
      });
      store.rowData = updateVisibility(expandedRows, params.data.id, isExpanded);
    }
  }, []);
  const getRowStyle = params => {
    if (params.data.parentId) {
      return { background: '#032845' };
    }else {
      return { background: '#041c2f' };
    }
  };
  
  return (
    <Observer>
      {() => (
        <>
        <div style={{
          width: 'calc(100% - 40px)',
          margin: '3px 0',
          alignItems: 'center',
          display: 'flex'
        }}>
            <Dropdown
                id="historical"
                options={store.dropdownOptions}
                value={widgetModel.date}
                onChange={store.selectOption}
                placeholder="Select Month"
                width={'140px'}
                height={'20px'}
            />
        </div>
          <TableWrapper id="header" width="100%">
            <>
              {store.loading ? (
                <LoadingSpinner size={'200px'} />
              ) : store.error ? (
                <Error error={store.error} />
              ) : store.rowData ? (
                <>
                  <TableHeader
                    store={store}
                    initiateTxnsExport={store.widgetModel.initiateTxnsExport}
                    exportInfo={store.widgetModel.exportInfo}
                    /** OrderBy **/
                    addOrderBy={store.widgetModel.addOrderBy}
                    orderByArray={store.widgetModel.orderByModel}
                    orderByOptions={store.orderByOptions}
                    resetOrderBy={store.widgetModel.resetOrderBy}
                    loading={store.loading}
                    fetchTableData={store.getData}
                    /*Paginator*/
                    paginator={paginatorModel}
                    txnCount={store.txnCount}
                  />
                    
                  <GridWrapper>
                    <div
                      id="myGrid"
                      className={'ag-theme-alpine-dark'}
                      style={{
                        height: '99%',
                        minHeight: '600px',
                        position: 'relative',
                        borderRadius: '5px',
                      }}
                    >
                      {/**react next is for react16 use**/}

                      <AgGridReact
                        react="next"
                        animateRows
                        masterDetail={true}
                        onCellClicked={onCellClicked}
                        rowData={store.rowData.filter(row => row.isVisible)}
                        gridOptions={store.gridOptions}
                        getRowStyle={getRowStyle}
                      />
                    </div>
                  </GridWrapper>
                </>
              ) : null}
            </>
          </TableWrapper>
          </>
      )}
    </Observer>
  );
});



export default IsoProfitLoss;

const TableWrapper = styled.div`
  height: 94%;
  background-color: #012439;
`;

const GridWrapper = styled.div`
  height: 98%;
  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    --ag-control-panel-background-color: #041c2f;
    --ag-subheader-background-color: #032845;

    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }
    .ag-header-row {
      height: 30px;
    }
    .topLevel {
      background-color: #001329 !important;
    }
    .secondLevel {
      background-color: #032845 !important;
    }
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 100%;
    }
    .apply {
      background-color: #3fa9f5;
      color: rgba(255, 255, 255);
      border-radius: 3px;
      border: none;
      width: 80px;
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.baseColors.colorBlueHover};
      }
    }
  }
`;